import React from "react";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { DisplayLabels } from "src/components/survey/AccessControl/section/util";
import styled from "styled-components";
import { Box } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/fields";

const StyledBox = styled(Box)(({ children }: { children: string }): string => {
    if (children.length > 100) {
        return `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
            overflow: scroll;
            white-space: pre-wrap;
            height: 150px;
        }`;
    }
    return "";
});

export const explanation = (application: SurveyResponse): Pair => {
    const explanationValue = application.appInfo.review.definedExplanation;
    return {
        label: DisplayLabels.EXPLANATION,
        testId: TEST_IDS.ACCESS_CONTROL_SECTION.EXPLANATION,
        value: explanationValue ? (
            <StyledBox display={"block"}>{explanationValue}</StyledBox>
        ) : (
            <Box variant="span">&mdash;</Box>
        ),
    };
};
