import { int } from "aws-sdk/clients/datapipeline";
import { ReactNode } from "react";
import { DataStoreQuestion, RetentionJsonContentType, ReviewQuestion } from "src/answers_legacy";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { KaleApplication, SizeMinimalKaleApplication } from "src/components/survey/IndexPage";
import {
    AppInfoResponse,
    ApplicationStatus,
    ApprovalType,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import { Decision } from "src/components/TAF/TAFDetails/TAFDecisionTable";
import { FeatureToggleConfig, KaleConfig } from "src/Config";
import { UserRole } from "src/permissions";
import AbstractKaleService, { OperationResult, ReviewGroup } from "src/services/AbstractKaleService";
import { QuestionColorOverrides, QuestionTag } from "src/services/dynamic-questions";
import { appendRequestID } from "src/services/AppendRequestID";
import { TokenGetter } from "src/services/CognitoService";
import { UnauthorizedError } from "src/util/CustomErrors";
import {
    BYPASS_TAF_DEL_OBLIG_RESP,
    BYPASS_TAF_ECS_REVIEW_GROUP,
    DELETION_OBLIGATIONS_SHORT_ID,
} from "src/components/TAF/TAFDetails/constants";
import {
    FetchSummaryResponseOptions,
    PERSONAL_DATA_SUMMARY_NAME,
    PRIVACY_ALERTS_SUMMARY_NAME,
    SummaryResponse,
} from "src/services/summaries/model/summaries";
import {
    DEFAULT_CAMPAIGN_SAVE_REVIEW_ID_SLUG,
    deserializeIncrementalSaveResponse,
    ERROR_SAVING_VALUE_DICTIONARY,
    IncrementalSaveInput,
    IncrementalSaveResponse,
    SerializedIncrementalSaveRequest,
    SerializedIncrementalSaveResponse,
    serializeIncrementalSaveRequest,
} from "src/services/IncrementalSave";
import { CampaignSaveFacadeInput } from "src/components/survey/campaign/types";
import { ResponseCategory } from "./NodeGenericResponse";
import {
    BindleConnection,
    BindleConnectionType,
    LineageGlossary,
    LineageNode,
    MultiNodeConnections,
} from "src/services/external-lineage/types";
import { GET_NODE_BY_ID_ERR_MESSAGE } from "src/components/TAF/FinancialGRE/constants";
import { escape } from "lodash";
import { ListAAAApplicationsResponse, ListRelatedBindlesResponse } from "src/services/veritas/model";

export interface CreateApplicationRequestBody {
    appName: string;
    controlBindleName: string;
    isConfidential: boolean;
}

export interface ServerErrorMessageSource {
    parameterName: string;
    pointer: string;
}

export interface ServerErrorMessage {
    status: string;
    message: ReactNode;
    source: ServerErrorMessageSource;
}

export interface IncrementalSaveThrownError {
    category: ResponseCategory.BadRequest | ResponseCategory.Unknown;
    messages?: ServerErrorMessage[];
    message: string;
    type?: string;
}

export interface DataElementSchema {
    name: string;
    description: string;
    key: string;
    id: string;
    version: string;
    isCategory: boolean;
    updater: string;
    updateDate: string;
    creator: string;
    createDate: string;
    status: string;
    categories: string[];
    examples: string[];
    sources: string[];
}

export const FAILED_TO_FETCH_UNMATCHED_APPLICATIONS_ERROR = "Failed to fetch unmatched applications";
export const FAILED_REFRESH_AFTER_RECALL_ERROR =
    `Failed to refresh Application state after recall. ` +
    `Please reload the page to ensure sure your change was applied correctly`;
export const FAILED_TO_FETCH_BINDLE_MEMBERS = "Failed to fetch bindle members";

export const FAILED_TO_RECALL_APPLICATION_ERROR = "Failed to recall Application.";

export interface BindleInfo {
    id: string;
    name: string;
}

export interface OwningTeam {
    teamId: string; // example team id: "amzn1.abacus.team.qklzurxf37yng36u3j4q"
    teamName: string;
}

export interface DescbribeResourceResponse {
    readonly parentBindle: BindleInfo;
    readonly owningTeam: OwningTeam;
    readonly cti?: string;
    readonly resolverGroup?: string;
    readonly contactEmail?: string;
}

export interface KaleAnvilResponse {
    applicationName: string;
    version: string;
    anvilId: string;
    taskId: string;
}

export interface OwnerResponse {
    applicationOwner: string;
}

export interface TaskIdResponse {
    taskId: string;
    anvilAppName: string;
}

export interface AnvilApplication {
    anvilAppName: string;
    anvilId: string;
}

export interface KaleUnmatchedApplicationResponse {
    applicationName: string;
    metaDataLastUpdate: string;
    legalStatus: ApplicationStatus;
}

export interface ReviewerGroupResponse {
    groupName: string;
}

export interface BindleResource {
    awsAccountId: string;
    awsAccountName: string;
    awsRegion: string;
    bindleId: string;
    bindleName: string;
    resourceType: string;
    resourceArn: string;
    userFriendlyIdentifier: string;
    creationDate: string;
    resourceSpecificDetails: Record<string, string>;
}

export interface AAAResource {
    resourceId: string;
    resourceName: string;
    resourceTypeId: string;
}

export enum TAFNotificationType {
    tafRequest = "taf-request",
}

export interface FinancialFeedbackBody {
    dataStoreDecisions: Decision[];
}

export const HAS_PERSONAL_DATA_QUESTION_SHORT_ID = "contains_personal_data";

export interface Choice {
    value: string;
    label?: string | ReactNode;
    description?: string;
    colors?: QuestionColorOverrides;
    infoContent?: string;
    privacyAlertContent?: string;
}

export interface QuestionGroupInfo {
    title: string;
    description: string;
    groupId: string;
}

// Maps a Question's GroupId to the corresponding group info object
export interface QuestionGroupInfoMap {
    [groupId: string]: QuestionGroupInfo;
}

export interface KaleQuestion {
    reviews: ReviewQuestion[];
    dataStores: DataStoreQuestion[];
    questionGroupInfoMap: QuestionGroupInfoMap;
}

interface ReviewGroupsResponse {
    groupType: string;
    reviewGroups: string[];
}

export interface ReviewGroupsMap {
    [groupType: string]: string[];
}

interface ReviewGroupPromiseMap {
    [groupType: string]: Promise<Response>;
}

export interface AEDUExternalIdMetaData {
    enabling_date?: string;
}

export interface SandfireExternalIdMetaData {
    app_name?: string;
}

export interface ExternalId {
    serviceName: string;
    serviceID: string;
    onboardingStatus: string;
    category: string;
    type: string;
    item: string;
    metaData?: AEDUExternalIdMetaData & SandfireExternalIdMetaData;
}

export const DEFAULT_KALE_QUESTIONS: KaleQuestion = {
    reviews: [],
    dataStores: [],
    questionGroupInfoMap: {},
};

export interface Answer {
    id?: number;
    questionId: number;
    questionShortId: string;
    dataStoreId?: number;
    textContent?: string;
    arrayContent?: string[];
    dateContent?: string;
    jsonContent?: RetentionJsonContentType;
}

export interface KaleError {
    errorMsg: string;
}

interface LegalRecall {
    applicationName: string;
    type: ApprovalType;
}

const addDataStoreIdToAnswers = (dataStores: DataStoreResponse[]): DataStoreResponse[] => {
    return (
        dataStores?.map((dataStore): DataStoreResponse => {
            return {
                ...dataStore,
                tables: dataStore.tables ?? [],
                dataStoreAnswers: dataStore.dataStoreAnswers.map((answer): Answer => {
                    return { ...answer, dataStoreId: dataStore.id };
                }),
            };
        }) ?? []
    );
};

interface ListAppDataStoreResponse {
    id: int;
    name: string;
    technology: string;
}

export const selectGroupTypesByRole = (userRole: UserRole): ReviewGroup[] => {
    switch (userRole) {
        case UserRole.accountingReviewer: {
            return [ReviewGroup.accounting];
        }
        case UserRole.fraudReviewer: {
            return [ReviewGroup.fraud];
        }
        case UserRole.admin: {
            return [ReviewGroup.accounting, ReviewGroup.fraud];
        }
    }

    return [];
};

export class KaleApplicationService extends AbstractKaleService {
    // https://gsam.corp.amazon.com/account/ldap/svcdaswebsiteintegtest
    private readonly hydra_test_alias = "svcdaswebsiteintegtest";

    public constructor(kaleConfig: KaleConfig & FeatureToggleConfig, accessTokenGetter: TokenGetter) {
        super(kaleConfig, accessTokenGetter);

        this.fetchBindleMembers = this.fetchBindleMembers.bind(this);
        this.index = this.index.bind(this);
        this.view = this.view.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.deleteApp = this.deleteApp.bind(this);
        this.notify = this.notify.bind(this);
        this.fetchUserRole = this.fetchUserRole.bind(this);
        this.fetchAAAIds = this.fetchAAAIds.bind(this);
        this.linkApplication = this.linkApplication.bind(this);
        this.fetchAnvilApplications = this.fetchAnvilApplications.bind(this);
        this.fetchBindlesConnections = this.fetchBindlesConnections.bind(this);
        this.fetchCampaign = this.fetchCampaign.bind(this);
        this.incrementalSave = this.incrementalSave.bind(this);
        this.fetchDataElements = this.fetchDataElements.bind(this);
        this.fetchReviewGroupsByRole = this.fetchReviewGroupsByRole.bind(this);
        this.fetchReviewerGroup = this.fetchReviewerGroup.bind(this);
        this.fetchBindleResources = this.fetchBindleResources.bind(this);
        this.fetchMultiNodeConnections = this.fetchMultiNodeConnections.bind(this);
        this.fetchLineageGlossary = this.fetchLineageGlossary.bind(this);
        this.bulkFetchBindleResources = this.bulkFetchBindleResources.bind(this);
        this.fetchOwner = this.fetchOwner.bind(this);
        this.fetchTaskId = this.fetchTaskId.bind(this);
        this.fetchQuestions = this.fetchQuestions.bind(this);
        this.listAppDataStores = this.listAppDataStores.bind(this);
        this.listExternalOnboardingStatuses = this.listExternalOnboardingStatuses.bind(this);
        this.recallInReviewApp = this.recallInReviewApp.bind(this);
        this.recallApprovedApp = this.recallApprovedApp.bind(this);
        this.fetchAppWithLatestTaskId = this.fetchAppWithLatestTaskId.bind(this);
        this.approveLegal = this.approveLegal.bind(this);
        this.reject = this.reject.bind(this);
        this.submit = this.submit.bind(this);
        this.fetchSummaryResponse = this.fetchSummaryResponse.bind(this);
        this.fetchPersonalDataSummary = this.fetchPersonalDataSummary.bind(this);
        this.fetchPrivacyAlertsSummary = this.fetchPrivacyAlertsSummary.bind(this);
        this.createApplication = this.createApplication.bind(this);
        this.getLatestReviewID = this.getLatestReviewID.bind(this);
        this.fetchAppByReviewId = this.fetchAppByReviewId.bind(this);
        this.listRelatedBindles = this.listRelatedBindles.bind(this);
        this.listAAAApplications = this.listAAAApplications.bind(this);
    }

    public async createApplication(body: CreateApplicationRequestBody): Promise<void> {
        const { appName } = body;
        console.info(`Creating Kale application: ${appName}`);
        const endpoint = `${this.baseNodeApiEndpoint}/applications`;
        return this.signedFetch("POST", endpoint, JSON.stringify(body)).then(
            async (response: Response): Promise<void | never> => {
                const messages = (await response.json()).messages;
                if (response.status != 201) {
                    let err = `Error creating attestation: "${appName}"` + "\n";
                    messages.messages.forEach((data: ServerErrorMessage): void => {
                        err = err + "\t   " + data.source.parameterName + ": " + data.message + "\n";
                    });
                    console.error(appendRequestID(err, response));
                    throw Error(JSON.stringify(messages));
                }
            },
            this.buildDefaultRejection()
        );
    }

    public async fetchBindleMembers(applicationName: string): Promise<string[]> {
        return this.signedFetch(
            "GET",
            `${this.baseNodeApiEndpoint}/applications/${encodeURIComponent(applicationName)}/listBindleMembers`
        ).then(async (res: Response): Promise<any> => {
            if (res.status !== 200) {
                throw Error(appendRequestID(FAILED_TO_FETCH_BINDLE_MEMBERS, res));
            } else {
                return await res.json();
            }
        }, this.buildDefaultRejection());
    }

    public fetchUserRole(applicationName: string): Promise<UserRole> {
        return this.signedFetch(
            "GET",
            `${this.baseApiEndpoint}/authorization/${encodeURIComponent(applicationName)}`
        ).then((authResponse: Response): Promise<UserRole> => {
            if (authResponse.status === 200) {
                return authResponse.json().then((personas: UserRole[]): UserRole => {
                    if (personas.includes(UserRole.admin)) {
                        // Admin has highest level access, return first
                        return UserRole.admin;
                    } else if (personas.includes(UserRole.serviceOwner)) {
                        // It is rare, yet possible for an individual to be a serviceOwner and also a
                        // reviewer (any kind) on a given Kale App. [1]
                        // For that reason, we return serviceOwner before any reviewer types.
                        // serviceOwners should not be allowed to approve/review their own apps.
                        // [1] This happens for CTPS Reviews which grant CTPS Reviewer permissions to all CTPS Reviewers
                        // in the system. Sometimes, some of the Developers in the CTPS Org are CTPS Reviewers for other
                        // apps around their Org or in other Orgs. When these Developers submit their Kale Apps, the
                        // permissioning model returns both Service owner and CTPS Reviewer Roles for them.
                        // Notably, Acct is not affected by this since Acct Reviewers do not themselves own Kale Apps,
                        // as their role is similar to Accountants and not Developers.
                        // In the case of legal reviews, this is rarer as the permissioning system is targetted for
                        // legal reviews, but can still happen if a PBR or another technical person is a reviewer.
                        return UserRole.serviceOwner;
                    } else if (personas.includes(UserRole.reviewer)) {
                        return UserRole.reviewer;
                    } else if (personas.includes(UserRole.accountingReviewer)) {
                        return UserRole.accountingReviewer;
                    } else if (personas.includes(UserRole.fraudReviewer)) {
                        return UserRole.fraudReviewer;
                    } else {
                        // The requests for data in Kale check for access automatically and will deny access so
                        // an appropriate default least privileged access would be read-only
                        return UserRole.readOnly;
                    }
                }, this.buildDefaultRejection());
            } else {
                return Promise.resolve(UserRole.readOnly);
            }
        }, this.buildDefaultRejection());
    }

    public incrementalSave(input: IncrementalSaveInput): Promise<OperationResult<string>> {
        const endpoint = `${this.baseApiEndpoint}/applications/save-facade`;
        const serializedSaveRequest: SerializedIncrementalSaveRequest = serializeIncrementalSaveRequest(input);
        return this.signedFetch("POST", endpoint, JSON.stringify(serializedSaveRequest), input.abortController).then(
            async (response: Response): Promise<OperationResult<string>> => {
                const awaitedResponse = await response.json();
                if (response.status != 200) {
                    if (
                        awaitedResponse.messages?.category === ResponseCategory.BadRequest &&
                        awaitedResponse.messages.messages
                    ) {
                        throw {
                            ...awaitedResponse.messages,
                            message: awaitedResponse.messages.messages.reduce(
                                (prev: string, next: ServerErrorMessage) => {
                                    return `${prev}\t  ${next.source.parameterName}: ${next.message}\n`;
                                },
                                `${ERROR_SAVING_VALUE_DICTIONARY} "${input.appName}"\n`
                            ),
                        };
                    } else {
                        // API is currently responding with {errorMsg: string}...
                        // instead of the above format for validation/unmarshal issues
                        // This is a catchall in case the correct format is not being sent.
                        throw {
                            category: ResponseCategory.Unknown,
                            message: awaitedResponse?.errorMsg || ResponseCategory.Unknown,
                        };
                    }
                }
                return awaitedResponse;
            },
            this.buildDefaultRejection()
        );
    }

    public fetchCampaign(input: CampaignSaveFacadeInput): Promise<IncrementalSaveResponse> {
        console.info(`Fetching campaign: ${input}`);
        const endpoint = `${this.baseApiEndpoint}/campaigns/${input.type.toString()}/${input.appName}/${
            input.reviewId ?? DEFAULT_CAMPAIGN_SAVE_REVIEW_ID_SLUG
        }`;
        return this.signedFetch("GET", endpoint).then(
            (campaignResponse: Response): Promise<IncrementalSaveResponse> => {
                if (campaignResponse.status !== 200) {
                    throw Error(appendRequestID("Failed to fetch campaign.", campaignResponse));
                }
                return campaignResponse
                    .json()
                    .then((body: SerializedIncrementalSaveResponse): IncrementalSaveResponse => {
                        return deserializeIncrementalSaveResponse(body);
                    });
            }
        );
    }

    /**
     * @param questionTag - Display questions for: Primary(legal/privacy review), or TAF Review
     * @param appReviewerGroup - (Optional) Only used for TAFView, hides an additional option on a TAF Question if
     * the review group is not equal to a special review group
     */
    public fetchQuestions(questionTag: QuestionTag, appReviewerGroup?: string): Promise<KaleQuestion> {
        console.log("fetechQuestions --> ", `${this.baseApiEndpoint}/questions/${questionTag}`);
        return this.signedFetch("GET", `${this.baseApiEndpoint}/questions/${questionTag}`)
            .then((res: Response): any => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw Error(appendRequestID("Failed to load Kale questions. Please refresh the page.", res));
                }
            })
            .then((questions: KaleQuestion): KaleQuestion => {
                if (questionTag == QuestionTag.tafView) {
                    // In relation to data store question DELETION_OBLIGATIONS_SHORT_ID:
                    // Only display the option BYPASS_TAF_DEL_OBLIG_RESP for BYPASS_TAF_ECS_REVIEW_GROUP
                    questions.dataStores.forEach((question: DataStoreQuestion): void => {
                        if (question.shortId == DELETION_OBLIGATIONS_SHORT_ID) {
                            question.choices = question.choices.filter((choice): boolean => {
                                if (choice.value == BYPASS_TAF_DEL_OBLIG_RESP) {
                                    return appReviewerGroup == BYPASS_TAF_ECS_REVIEW_GROUP;
                                }
                                // Always display options that are not equal to BYPASS_TAF_DEL_OBLIG_RESP
                                return true;
                            });
                        }
                    });
                }
                return questions;
            })
            .catch((err: Error): KaleQuestion => {
                throw Error("Sorry, we got an error: " + JSON.stringify(err.message));
            });
    }

    public updateAssignedReviewGroups(appName: string, reviewGroups: ReviewGroupsMap): Promise<SurveyResponse> {
        console.info(`Updating review groups for application: ${appName}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/assign-review-groups`,
            JSON.stringify({ reviewGroups })
        ).then((response: Response): Response => {
            console.info(`Updated review groups for application: ${appName}`);
            return response;
        }, this.buildDefaultRejection());
    }

    public fetchReviewGroupsByRole(userRole: UserRole): Promise<ReviewGroupsMap> {
        const groupTypes = selectGroupTypesByRole(userRole);

        if (!Boolean(groupTypes.length)) {
            console.warn(`Failed to select Kale review group type by user role ${userRole}.`);
            return Promise.resolve({});
        }

        const promiseMap: ReviewGroupPromiseMap = {};

        groupTypes.forEach((groupType): void => {
            promiseMap[groupType] = this.signedFetch("GET", `${this.baseApiEndpoint}/review-groups/${groupType}`);
        });

        const promises = Object.keys(promiseMap).map((key): Promise<Response> => promiseMap[key]);

        return Promise.all(promises)
            .then((responses: Response[]): Promise<ReviewGroupsResponse>[] => {
                return responses.map(async (res: any, index: number): Promise<ReviewGroupsResponse> => {
                    const groupType = Object.keys(promiseMap)[index];

                    if (res.status === 200) {
                        const { reviewGroups = [] } = await res.json();
                        return { groupType, reviewGroups };
                    } else {
                        throw Error(
                            appendRequestID("Failed to load Kale review groups. Please refresh the page.", res)
                        );
                    }
                });
            })
            .then((responses: Promise<ReviewGroupsResponse>[]): Promise<ReviewGroupsMap> => {
                return Promise.all(responses).then((reviewGroupResponses): ReviewGroupsMap => {
                    const result: ReviewGroupsMap = {};

                    reviewGroupResponses.forEach((reviewGroupResponse): void => {
                        result[reviewGroupResponse.groupType] = reviewGroupResponse.reviewGroups;
                    });

                    return result;
                });
            })
            .catch((err: Error): ReviewGroupsMap => {
                throw Error("Sorry, we got an error: " + JSON.stringify(err.message));
            });
    }

    public fetchBindlesConnections(bindleIds: string[]): Promise<BindleConnection[]> {
        console.info(`Fetching bindles connections for bindles: ${bindleIds}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/external/dgr/get-bindles-connections`,
            JSON.stringify(bindleIds)
        ).then(async (response: Response): Promise<BindleConnection[]> => {
            if (response.status === 200) {
                console.info(`Fetched bindles connections for bindles: ${bindleIds}`);
                const connections = (await response.json()) as unknown as BindleConnection[];
                // Include only supported connection types
                return connections.filter((connection): boolean =>
                    Object.values(BindleConnectionType).includes(connection.connectionType as BindleConnectionType)
                );
            } else {
                throw new Error("Failed to fetch bindles connections");
            }
        }, this.buildDefaultRejection());
    }

    public fetchMultiNodeConnections(nodeIds: string[]): Promise<MultiNodeConnections[]> {
        console.info(`Fetching multi connections for IDs: ${nodeIds}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/external/dgr/get-multi-node-connections`,
            JSON.stringify(nodeIds)
        ).then(async (response: Response): Promise<MultiNodeConnections[]> => {
            switch (response.status) {
                case 200:
                    return (await response.json()) as unknown as MultiNodeConnections[];
                default: {
                    throw new Error(appendRequestID("Failed to fetch Lineage Findings", response));
                }
            }
        }, this.buildDefaultRejection());
    }

    public fetchLineageGlossary(): Promise<LineageGlossary> {
        console.info(`Fetching glossary`);
        return this.signedFetch("POST", `${this.baseApiEndpoint}/external/dgr/get-lineage-glossary`).then(
            async (response: Response): Promise<LineageGlossary> => {
                switch (response.status) {
                    case 200:
                        return (await response.json()) as unknown as LineageGlossary;
                    default: {
                        throw new Error(appendRequestID("Failed to fetch Lineage Glossary", response));
                    }
                }
            },
            this.buildDefaultRejection()
        );
    }

    public fetchNodesById(nodeIds: string[]): Promise<LineageNode[]> {
        console.info(`Fetching nodes: ${nodeIds}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/external/dgr/get-nodes-by-id`,
            JSON.stringify(nodeIds)
        ).then(async (response: Response): Promise<LineageNode[]> => {
            switch (response.status) {
                case 200:
                    return (await response.json()) as unknown as LineageNode[];
                default: {
                    throw new Error(appendRequestID(GET_NODE_BY_ID_ERR_MESSAGE, response));
                }
            }
        }, this.buildDefaultRejection());
    }

    public listExternalOnboardingStatuses(appName: string): Promise<ExternalId[]> {
        console.info(`Fetching onboarding statuses for application: ${appName}`);
        return this.signedFetch(
            "GET",
            `${this.baseNodeApiEndpoint}/applications/${encodeURIComponent(appName)}/ExternalIDs`
        ).then(async (res: Response): Promise<ExternalId[]> => {
            if (res.status === 200) {
                console.info(`Fetched onboarding statuses for application: ${appName}`);
                return (await res.json()) as unknown as ExternalId[];
            } else {
                throw Error(appendRequestID("Failed to load onboarding statuses.", res));
            }
        }, this.buildDefaultRejection());
    }

    public index(): Promise<KaleApplication[]> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/applications`).then(
            (res: Response): Promise<KaleApplication[]> => {
                if (res.status === 200) {
                    // SizeMinimalKaleApplication is the data type sent by the backend.
                    // It needs to be converted to KaleApplication.
                    // Read CR description for more info: https://code.amazon.com/reviews/CR-97520650
                    return res.json().then((minimalApps: SizeMinimalKaleApplication[]): KaleApplication[] => {
                        return minimalApps.map((minimalApp: SizeMinimalKaleApplication): KaleApplication => {
                            return {
                                applicationName: minimalApp.a,
                                reviewGroup: minimalApp.rg,
                                reviewId: minimalApp.ri,
                                controlBindle: minimalApp.b,
                                metaDataCreationDate: minimalApp.c,
                                metaDataLastUpdate: minimalApp.u,
                                legalStatus: minimalApp.ls,
                                accountingStatus: minimalApp.as,
                                fraudStatus: minimalApp.fs,
                            };
                        });
                    });
                } else {
                    throw Error(appendRequestID("Failed to load Kale records. Please refresh the page.", res));
                }
            },
            this.buildDefaultRejection()
        );
    }

    public view(name: string): Promise<SurveyResponse> {
        if (!name) {
            console.error(`Fetching application Error: Invalid name received`);
            return Promise.reject(Error("Failed to fetch application, invalid parameters"));
        }
        const viewUrl = `${this.baseApiEndpoint}/applications/${encodeURIComponent(name)}`;

        console.info(`Fetching application: ${name}`);
        return this.signedFetch("GET", viewUrl).then((applicationRes: Response): Promise<SurveyResponse> => {
            if (applicationRes.status === 200) {
                console.info(`Fetching application: ${name}: Success`);
                return applicationRes.json().then((response: AppInfoResponse): SurveyResponse => {
                    response.review.dataStores = addDataStoreIdToAnswers(response.review.dataStores);
                    return { appInfo: response };
                }, this.buildDefaultRejection());
            } else if (applicationRes.status === 401) {
                console.error(`Fetching application: ${name}: Error: ${applicationRes.statusText}`);
                throw new UnauthorizedError("Unauthorized");
            } else {
                console.error(`Fetching application: ${name}: Error: ${applicationRes.statusText}`);
                throw Error(appendRequestID("Failed to fetch application.", applicationRes));
            }
        }, this.buildDefaultRejection());
    }

    public create(data: SurveyResponse): Promise<SurveyResponse> {
        const appName = data.appInfo.applicationName;
        console.info(`Creating application: ${appName}`);
        return this.signedFetch("POST", `${this.baseApiEndpoint}/applications`, JSON.stringify(data.appInfo)).then(
            async (response: Response): Promise<SurveyResponse> => {
                const msgPrefix = `Creating application: ${appName}`;
                const surveyResponse = await this.handleKaleResponse<AppInfoResponse>(response, 201, msgPrefix);
                console.log(`${msgPrefix}: Success`);
                surveyResponse.review.dataStores = addDataStoreIdToAnswers(surveyResponse.review.dataStores);
                return { appInfo: surveyResponse };
            },
            this.buildDefaultRejection()
        );
    }

    public update(data: SurveyResponse): Promise<SurveyResponse> {
        const encodedApplicationName = encodeURIComponent(data.appInfo.applicationName);
        const appName = data.appInfo.applicationName;
        console.info(`Updating application: ${appName}`);
        return this.signedFetch(
            "PUT",
            `${this.baseApiEndpoint}/applications/${encodedApplicationName}`,
            JSON.stringify(data.appInfo)
        ).then(async (response: Response): Promise<SurveyResponse> => {
            const msgPrefix = `Updating application: ${appName}`;
            const surveyResponse = await this.handleKaleResponse<AppInfoResponse>(response, 200, msgPrefix);
            console.log(`${msgPrefix}: Success`);
            surveyResponse.review.dataStores = addDataStoreIdToAnswers(surveyResponse.review.dataStores);
            return { appInfo: surveyResponse };
        }, this.buildDefaultRejection());
    }

    public deleteApp(applicationName: string): any {
        console.info(`Deleting application: ${applicationName}`);
        return this.signedFetch(
            "DELETE",
            `${this.baseNodeApiEndpoint}/applications/${encodeURIComponent(applicationName)}`
        ).then(async (res: Response): Promise<void> => {
            if (res.status === 204) {
                return Promise.resolve();
            } else {
                throw Error(appendRequestID("Failed to delete application.", res));
            }
        }, this.buildDefaultRejection());
    }

    public submitFeedback(appName: string, data: FinancialFeedbackBody): Promise<void> {
        const encodedApplicationName = encodeURIComponent(appName);
        console.info(`submitting financial feedback: ${appName}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${encodedApplicationName}/financial/submit-feedback`,
            JSON.stringify(data)
        ).then(async (res: Response): Promise<void> => {
            if (res.status === 200) {
                return Promise.resolve();
            } else {
                throw Error(appendRequestID("An error has occurred while submitting feedback.", res));
            }
        }, this.buildDefaultRejection());
    }

    public notify(params: { name: string; type: TAFNotificationType }): Promise<void> {
        const applicationName = encodeURIComponent(params.name);

        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${applicationName}/notify`,
            JSON.stringify({ notificationType: params.type })
        ).then((res: Response): Promise<void> => {
            if (res.status === 200) {
                return Promise.resolve();
            } else {
                throw Error(appendRequestID("An error has occurred while attempting to send notification.", res));
            }
        }, this.buildDefaultRejection());
    }

    public linkApplication(
        applicationName: string,
        applicationOwner: string,
        taskId: string,
        anvilId: string
    ): Promise<void> {
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/anvil/link-application`,
            JSON.stringify({ applicationName, applicationOwner, taskId, anvilId })
        ).then((res: Response): Promise<void> => {
            if (res.status === 200) {
                return Promise.resolve();
            } else {
                throw Error(appendRequestID("Failed to link application.", res));
            }
        }, this.buildDefaultRejection());
    }

    public fetchOwner(anvilId: string): Promise<string> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/anvil/${anvilId}/owner`).then(
            (res: Response): Promise<string> => {
                if (res.status === 200) {
                    return res.json().then((owner: OwnerResponse): Promise<string> => {
                        return Promise.resolve(owner.applicationOwner);
                    }, this.buildDefaultRejection());
                }
                if (res.status === 400) {
                    throw Error(appendRequestID(`"${anvilId}" is not a valid Anvil Application ID.`, res));
                }
                if (res.status === 404) {
                    throw Error(appendRequestID(`Anvil Application with ID "${anvilId}" could not be found.`, res));
                }
                throw Error(appendRequestID("Failed to find Anvil Application Owner.", res));
            },
            this.buildDefaultRejection()
        );
    }

    public fetchTaskId(anvilId: string): Promise<TaskIdResponse> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/anvil/${anvilId}/task`).then(
            (res: Response): Promise<TaskIdResponse> => {
                if (res.status === 200) {
                    return res.json().then((task: TaskIdResponse): Promise<TaskIdResponse> => {
                        return Promise.resolve(task);
                    }, this.buildDefaultRejection());
                }
                console.error(`Failed to fetch Anvil taskId for Anvil Application with ID "${anvilId}"`);
                throw Error(
                    appendRequestID(
                        `Failed to link Privacy Readiness Review under Anvil Application with ID "${anvilId}".`,
                        res
                    )
                );
            },
            this.buildDefaultRejection()
        );
    }

    public fetchAnvilApplications(): Promise<AnvilApplication[]> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/anvil/applications`).then(
            (res: Response): Promise<AnvilApplication[]> => {
                if (res.status === 200) {
                    return res.json().then((applications: AnvilApplication[]): Promise<AnvilApplication[]> => {
                        return Promise.resolve(applications);
                    }, this.buildDefaultRejection());
                }
                throw Error("Failed to fetch anvil applications.");
            },
            this.buildDefaultRejection()
        );
    }

    public fetchMatchingAnvilApplications(anvilId: string, taskId: string): Promise<KaleAnvilResponse> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/anvil/${anvilId}/task/${taskId}`).then(
            (res: Response): Promise<KaleAnvilResponse> => {
                if (res.status === 200) {
                    return res.json().then((applicationData: KaleAnvilResponse): Promise<KaleAnvilResponse> => {
                        return Promise.resolve(applicationData);
                    }, this.buildDefaultRejection());
                }
                throw Error("Failed to fetch matching anvil applications.");
            },
            this.buildDefaultRejection()
        );
    }

    public fetchReviewerGroup(bindleId: string): Promise<ReviewerGroupResponse> {
        console.info(`Fetching Reviewer Group: ${bindleId}`);
        return this.signedFetch("GET", `${this.baseApiEndpoint}/reviewer/bindle/${bindleId}`).then(
            (response: Response): Promise<any> => {
                if (response.status === 200) {
                    console.info(`Fetching Reviewer Group: ${bindleId}: Success`);
                    return response.json().then((reviewGroup: string): Promise<ReviewerGroupResponse> => {
                        return Promise.resolve({ groupName: reviewGroup });
                    }, this.buildDefaultRejection());
                }
                console.error(`Fetching Reviewer Group: ${bindleId}: ${response.status} ${response.statusText}`);
                throw Error(appendRequestID("Failed to fetch a reviewer group.", response));
            },
            this.buildDefaultRejection()
        );
    }

    public fetchUnmatchedApplications(): Promise<KaleUnmatchedApplicationResponse[]> {
        return this.signedFetch("GET", `${this.baseApiEndpoint}/unmatched-applications`).then(
            (res: Response): Promise<KaleUnmatchedApplicationResponse[]> => {
                if (res.status === 200) {
                    return res
                        .json()
                        .then(
                            (
                                unmatchedApplications: KaleUnmatchedApplicationResponse[]
                            ): KaleUnmatchedApplicationResponse[] => {
                                return unmatchedApplications;
                            },
                            this.buildDefaultRejection()
                        );
                } else {
                    throw Error(FAILED_TO_FETCH_UNMATCHED_APPLICATIONS_ERROR);
                }
            },
            this.buildDefaultRejection()
        );
    }

    public fetchBindleResources(bindleId: string): Promise<BindleResource[]> {
        console.log(`Fetching bindle resources: ${bindleId}`);
        return this.signedFetch("GET", `${this.baseApiEndpoint}/bindle-resources/${bindleId}`)
            .catch(this.buildDefaultRejection())
            .then((response: Response): Promise<any[]> => {
                if (response.status !== 200) {
                    const errorMsg = `Fetching bindle resources: ${bindleId}: Error: ${response.statusText}`;
                    console.error(errorMsg);
                    return Promise.reject(errorMsg);
                }

                console.log(`Fetching bindle resources: ${bindleId}: Success`);
                return response.json();
            })
            .then((bindleResources: any[]): BindleResource[] => {
                return bindleResources.map(KaleApplicationService.buildBindleResource);
            });
    }

    public bulkFetchBindleResources(bindleIds: Set<string>): Promise<BindleResource[]>[] {
        console.log("Bulk fetching bindle resources: ", bindleIds);
        return Array.from(bindleIds).map((bindleId: string): Promise<BindleResource[]> => {
            return this.fetchBindleResources(bindleId);
        });
    }

    public fetchAAAIds(bindleIds: Set<string>): Promise<AAAResource[]> {
        console.info(`Fetching AAA ids for bindles: ${Array.from(bindleIds).join(",")}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/bindles/list-aaa-applications`,
            JSON.stringify({ bindles: Array.from(bindleIds) })
        )
            .catch(this.buildDefaultRejection())
            .then((response: Response): Promise<AAAResource[]> => {
                const msgPrefix = `Fetching AAA ids: ${Array.from(bindleIds).join(",")}`;
                if (response.status !== 200) {
                    const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                    console.error(errorMsg);
                    return Promise.reject(errorMsg);
                }
                console.info(`${msgPrefix}: Success`);
                return response.json();
            });
    }

    public describeResourceById(resourceId: string): Promise<DescbribeResourceResponse> {
        const endpoint = `${this.baseApiEndpoint}/bindles/resource/${resourceId}`;
        console.log(`Fetching Bindle Info: ${resourceId}`);
        return this.signedFetch("GET", endpoint)
            .catch(this.buildDefaultRejection())
            .then((response: Response): Promise<DescbribeResourceResponse> => {
                if (response.status !== 200) {
                    const errMsg = `Fetching Bindle Info: ${resourceId}: ${response.status} ${response.statusText}`;
                    return Promise.reject(errMsg);
                }
                console.log(`Fetching Bindle Info: ${resourceId}: Success`);
                return response.json().then((jsonData: any): Promise<DescbribeResourceResponse> => {
                    const contactInfo: {
                        email: string;
                        CTI: {
                            category: string;
                            type: string;
                            item: string;
                            resolverGroup: string;
                        };
                    } = jsonData.contact;
                    return Promise.resolve({
                        owningTeam: {
                            teamName: jsonData.owningTeam.name,
                            teamId: jsonData.owningTeam.id,
                        },
                        parentBindle: {
                            id: jsonData.bindle.id,
                            name: jsonData.bindle.name,
                        },
                        cti: `${contactInfo.CTI.category}/${contactInfo.CTI.type}/${contactInfo.CTI.item}`,
                        resolverGroup: contactInfo.CTI.resolverGroup,
                        contactEmail: contactInfo.email,
                    });
                });
            });
    }

    public async listAppDataStores(appName: string): Promise<ListAppDataStoreResponse[]> {
        return this.signedFetch(
            "GET",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/datastores`
        ).then((response: Response): Promise<ListAppDataStoreResponse[]> => {
            const msgPrefix = `List application data stores: appName: ` + `${appName}`;
            if (response.status === 200) {
                return response.json();
            } else {
                const errorMsg = appendRequestID(`${msgPrefix}: Error: ${response.statusText}`, response);
                console.error(errorMsg);
                throw Error(errorMsg);
            }
        }, this.buildDefaultRejection());
    }

    private static buildBindleResource(resource: any): BindleResource {
        return {
            awsAccountId: resource.aws_account_id,
            awsAccountName: resource.aws_account_name,
            awsRegion: resource.aws_region,
            bindleId: resource.bindle_id,
            bindleName: resource.bindle_name,
            resourceType: resource.resource_type,
            resourceArn: resource.resource_arn,
            userFriendlyIdentifier: resource.user_friendly_identifier,
            creationDate: resource.creation_date,
            resourceSpecificDetails: resource.resource_specific_details,
        };
    }

    public fetchDataElements(): Promise<DataElementSchema[]> {
        console.info("Fetching data elements: ");
        return this.signedFetch("GET", `${this.baseApiEndpoint}/data-elements`)
            .catch(this.buildDefaultRejection())
            .then((response: Response): Promise<any[]> => {
                if (response.status !== 200) {
                    const errorMsg = appendRequestID(
                        `Fetching data elements: Error: ${response.status} ${response.statusText}`,
                        response
                    );
                    console.error(errorMsg);
                    throw Error(
                        appendRequestID("Please save your unsaved changes and reload the page to try again.", response)
                    );
                }

                console.info("Fetching data elements: Success");
                return response.json();
            })
            .then((dataElements: DataElementSchema[]): DataElementSchema[] => {
                return dataElements.map(KaleApplicationService.buildDataElements);
            });
    }

    // Recall backend API doesn't return a network response with the latest application state for the UI to use to
    // refresh itself after a successful recall. This recall method is an intermediary that serves as a composite
    // of the recallImpl(), view(), fetchTaskId() methods and calls each directly in order to recall an application
    // and provide the UI with the updated legal survey data to refresh itself with after the recall has been completed
    public async recallInReviewApp(applicationName: string, applicationAnvilId: string): Promise<SurveyResponse> {
        // The rest of the operations in the method will only only execute if the recall is successful
        await this.signedFetch("GET", `${this.baseApiEndpoint}/applications/${applicationName}/recall`).then(
            (res: Response): any => {
                if (res.status !== 200) {
                    throw Error(appendRequestID(FAILED_TO_RECALL_APPLICATION_ERROR, res));
                }
            },
            this.buildDefaultRejection()
        );

        return this.fetchAppWithLatestTaskId(applicationName, applicationAnvilId);
    }

    // We want to make sure that a user has the most recent privacy taskId associated with their Anvil Application
    // in Anvil because its a common use case for users to recall and then immediately re-submit their application
    // for Anvil recertification. If for some reason the anvil task id and anvil owner stored in the UI is out of sync
    // with the anvilTaskId and anvilApplicationOwner of record in Anvil respectively, when a Kale app is submitted
    // and auto approved, this would fail to mark a customer's Anvil privacy readiness task as complete.
    public async fetchAppWithLatestTaskId(
        applicationName: string,
        applicationAnvilId: string
    ): Promise<SurveyResponse> {
        const applicationDetailsPromise = this.view(applicationName);
        // We can only fetch the taskId if the application has an anvilId
        const hasAnvilId = Boolean(applicationAnvilId);
        const anvilTaskPromise: Promise<TaskIdResponse | null> = hasAnvilId
            ? this.fetchTaskId(applicationAnvilId)
            : Promise.resolve(null);
        const anvilOwnerPromise: Promise<string | null> = hasAnvilId
            ? this.fetchOwner(applicationAnvilId)
            : Promise.resolve(null);

        try {
            /* fetch the application's legalSurvey data, anvil taskId, and anvil ownerId. */
            const [anvilTask, anvilOwner, legalSurveyData] = await Promise.all([
                anvilTaskPromise,
                anvilOwnerPromise,
                applicationDetailsPromise,
            ]);

            // add the new taskId into the legalSurvey data and return it to the UI
            const taskId = anvilTask?.taskId ?? legalSurveyData.appInfo.review.taskId;
            // add the new anvil owner into the legalSurvey data and return it to the UI
            const applicationOwner = anvilOwner ?? legalSurveyData.appInfo.review.applicationOwner;

            return {
                ...legalSurveyData,
                appInfo: {
                    ...legalSurveyData.appInfo,
                    review: { ...legalSurveyData.appInfo.review, taskId, applicationOwner },
                },
            };
        } catch {
            // If either view() or fetchAnvilTask() failed, ask the user to refresh their
            // application to make sure those values are properly re-fetched before they
            // take any further action in the UI.
            throw Error(FAILED_REFRESH_AFTER_RECALL_ERROR);
        }
    }

    public async recallApprovedApp(applicationName: string): Promise<void> {
        const body: LegalRecall = { applicationName, type: ApprovalType.PrivacyApproval };
        return this.signedFetch("POST", `${this.baseNodeApiEndpoint}/applications/recall`, JSON.stringify(body)).then(
            (res: Response): any => {
                if (res.status !== 202) {
                    throw Error(appendRequestID("Failed to recall Application.", res));
                }
            },
            this.buildDefaultRejection()
        );
    }

    public async approveLegal(applicationName: string): Promise<void> {
        console.info(`Approving Legal App: ${applicationName}`);
        return this.signedFetch(
            "PUT",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(applicationName)}/approve`
        ).then(async (res: Response): Promise<void> => {
            if (res.status === 200) {
                return Promise.resolve();
            } else {
                throw Error(appendRequestID("Failed to approve legal application.", res));
            }
        }, this.buildDefaultRejection());
    }

    public reject(applicationName: string): Promise<void> {
        return this.signedFetch("POST", `${this.baseApiEndpoint}/applications/${applicationName}/reject`).then(
            (res: Response): any => {
                if (res.status !== 200) {
                    throw Error(appendRequestID("Failed to reject Application.", res));
                }
            },
            this.buildDefaultRejection()
        );
    }

    public rejectFinancial(applicationName: string): Promise<void> {
        console.info(`Rejecting Financial App: ${applicationName}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${applicationName}/financial/reject`
        ).then((res: Response): any => {
            if (res.status !== 200) {
                const errorMsg = appendRequestID("Failed to reject Financial Application.", res);
                console.error(errorMsg);
                throw Error(errorMsg);
            }
        }, this.buildDefaultRejection());
    }

    public recallFinancial(applicationName: string): Promise<void> {
        console.info(`Recalling Financial App: ${applicationName}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${applicationName}/financial/recall`
        ).then((res: Response): any => {
            if (res.status !== 200) {
                const errorMsg = appendRequestID("Failed to recall Financial Application.", res);
                console.error(errorMsg);
                throw Error(errorMsg);
            }
        }, this.buildDefaultRejection());
    }

    public submit(appName: string): Promise<void> {
        console.info(`Submitting application: ${appName}`);
        return this.signedFetch(
            "POST",
            `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/submit`
        ).then(async (res: Response): Promise<any> => {
            if (res.status !== 200) {
                const internalErrorMsg = appendRequestID("Failed to submit application", res);
                console.error(internalErrorMsg);
                const { errorMsg = "" } = await res.json();
                throw Error(errorMsg);
            }
        }, this.buildDefaultRejection());
    }

    private static buildDataElements(resource: any): DataElementSchema {
        return {
            name: resource.name,
            description: resource.description,
            key: resource.key,
            id: resource.id,
            version: resource.version,
            isCategory: resource.is_category,
            updater: resource.updater,
            updateDate: resource.update_date,
            creator: resource.creator,
            createDate: resource.create_date,
            status: resource.status,
            categories: resource.categories,
            examples: resource.examples,
            sources: resource.sources,
        };
    }

    /*
     * A function to fetch generic SummaryResponses from the backend. This function is responsible for logging console
     * info /errors and handling the error/success cases when a response status of 200 is the only criteria considered
     * for success and everything else is considered an error.
     */
    public fetchSummaryResponse({
        resourceName,
        appName,
        apiEndpoint,
    }: FetchSummaryResponseOptions): Promise<SummaryResponse> {
        console.info(`Fetching ${resourceName} for application: ${appName}`);
        return this.signedFetch("GET", apiEndpoint).then((response: Response): Promise<SummaryResponse> => {
            if (response.status != 200) {
                const errMsg = `Error fetching latest ${resourceName} for application "${appName}" `;
                console.error(errMsg, `Status Code: ${response.status} ${response.statusText}`);
                throw Error(appendRequestID(errMsg, response));
            } else {
                return response.json().then((jsonResponse): SummaryResponse => {
                    return jsonResponse;
                }, this.buildDefaultRejection());
            }
        }, this.buildDefaultRejection());
    }
    /**
     * Function responsible for fetching the Personal Data SummaryResponse
     */
    public fetchPersonalDataSummary(appName: string): Promise<SummaryResponse> {
        const options: FetchSummaryResponseOptions = {
            apiEndpoint: `${this.baseNodeApiEndpoint}/applications/${appName}/personalDataSummary`,
            appName,
            resourceName: PERSONAL_DATA_SUMMARY_NAME,
        };
        return this.fetchSummaryResponse(options);
    }
    /**
     * Function responsible for fetching the Privacy Alerts SummaryResponse
     */
    public fetchPrivacyAlertsSummary(appName: string): Promise<SummaryResponse> {
        const options: FetchSummaryResponseOptions = {
            apiEndpoint: `${this.baseNodeApiEndpoint}/applications/${appName}/risksSummary`,
            appName,
            resourceName: PRIVACY_ALERTS_SUMMARY_NAME,
        };
        return this.fetchSummaryResponse(options);
    }

    /*
     * Function used to retrieve most recent review ID for redirection when going from
     * /edit/<appName> to /appName/reviews/<LatestReviewID>/edit
     */
    public getLatestReviewID(appName: string): Promise<string | number> {
        const endpoint = `${this.baseApiEndpoint}/applications/get-latest-review-id/${encodeURIComponent(appName)}`;
        return this.signedFetch("GET", endpoint).then(async (response) => {
            if (response.status != 200) {
                const errorMsg = appendRequestID(
                    `Error getting Latest Review ID for Application: "${escape(appName)}"`,
                    response
                );
                console.error(errorMsg);
                throw Error(errorMsg);
            } else {
                return Promise.resolve(await response.json());
            }
        }, this.buildDefaultRejection());
    }

    public fetchAppByReviewId(appName: string, reviewId: string): Promise<SurveyResponse> {
        const err = Error("Failed to fetch application by review, invalid parameters");
        if (!appName) {
            console.error(`Fetching application ${appName} by review ${reviewId} Error: Invalid App Name`);
            throw err;
        }
        if (!reviewId) {
            console.error(`Fetching application ${appName} by review ${reviewId} Error: Invalid Review ID`);
            throw err;
        }
        const fetchAppURL = `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/review/${reviewId}`;

        console.info(`Fetching application by review ${appName} by review ${reviewId}`);
        return this.signedFetch("GET", fetchAppURL).then((applicationRes: Response): Promise<SurveyResponse> => {
            if (applicationRes.status === 200) {
                console.info(`Fetching application ${appName} by review ${reviewId}: Success`);
                return applicationRes.json().then((response: AppInfoResponse): SurveyResponse => {
                    response.review.dataStores = addDataStoreIdToAnswers(response.review.dataStores);
                    return { appInfo: response };
                }, this.buildDefaultRejection());
            } else if (applicationRes.status === 401) {
                console.error(
                    `Fetching application ${appName} by review ${reviewId} Error: ${applicationRes.statusText}`
                );
                throw new UnauthorizedError("Unauthorized");
            } else {
                console.error(
                    `Fetching application ${appName} by review ${reviewId} Error: ${applicationRes.statusText}`
                );
                throw Error(appendRequestID("Failed to fetch app by review.", applicationRes));
            }
        }, this.buildDefaultRejection());
    }

    public async listRelatedBindles(appName: string): Promise<ListRelatedBindlesResponse> {
        if (!appName) {
            throw Error("List related bindles: Invalid App Name");
        }
        const url = `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/list-related-bindles`;
        const res = await this.signedFetch("GET", url);
        const resBody = await res.json();
        if (res.status === 200) {
            return resBody;
        } else {
            throw Error(appendRequestID("Failed to list related bindles", res));
        }
    }

    public async listAAAApplications(appName: string): Promise<ListAAAApplicationsResponse> {
        if (!appName) {
            throw Error("List AAA applications: Invalid App Name");
        }
        const url = `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/list-aaa-apps`;
        const res = await this.signedFetch("GET", url);
        const resBody = await res.json();
        if (res.status === 200) {
            return resBody;
        } else {
            throw Error(appendRequestID("Failed to list AAA applications", res));
        }
    }
}
