import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { IncrementalSaveResponse, DEFAULT_INCREMENT_SAVE } from "src/services/IncrementalSave";
import { CampaignSaveFacadeInput } from "src/components/survey/campaign/types";

export const useFetchCampaignOnMount = (
    campaignInput: CampaignSaveFacadeInput
): {
    isLoadingCampaign: boolean;
    campaign: IncrementalSaveResponse;
    campaignError: React.ReactNode;
} => {
    const { kaleAppService } = useContext(KaleContext).service;
    const deps = {
        kaleAppService,
        campaignInput,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isLoadingCampaign, setIsLoadingQuestions] = useState<boolean>(false);
    const [campaign, setCampaign] = useState<IncrementalSaveResponse>(DEFAULT_INCREMENT_SAVE);
    const [campaignError, setCampaignError] = useState<React.ReactNode>(null);

    useEffect((): void => {
        const {
            kaleAppService: { fetchCampaign },
            campaignInput,
        } = dependenciesRef.current;
        setIsLoadingQuestions(true);
        fetchCampaign(campaignInput)
            .then((response): void => {
                setCampaign(response);
            })
            .catch((err: Error): void => {
                setCampaignError(err.message);
            })
            .finally((): void => {
                setIsLoadingQuestions(false);
            });
    }, []);

    return { isLoadingCampaign, campaign, campaignError };
};
