import { Can, UserAction, UserRole } from "src/permissions";
import { Button, ButtonProps, Container, Header, Select, SelectProps } from "@amzn/awsui-components-react-v3";
import { default as React, useState } from "react";
import styled from "styled-components";

export interface RoleOverrideModel {
    setUserRole: (overrideRole: UserRole) => void;
}

export const StyledOverrideButton = styled.span`
    display: flex;
    justify-content: flex-end;
`;

export const RoleOverride = (model: RoleOverrideModel): JSX.Element => {
    const roleOptions = (): SelectProps.Option[] => {
        return Object.keys(UserRole).map(
            (item: string): SelectProps.Option => ({
                value: item,
                label: item,
            })
        );
    };
    const [overrideRoleSelection, setOverrideRoleSelection] = useState<SelectProps.Option | null>(null);
    const onOverrideRoleClick = (event: CustomEvent<ButtonProps.ClickDetail>): void => {
        event.preventDefault();
        if (overrideRoleSelection) {
            model.setUserRole(UserRole[overrideRoleSelection.value as keyof typeof UserRole]);
        }
    };

    return (
        <Can
            perform={UserAction.canOverrideRole}
            yes={(): JSX.Element => (
                <Container header={<Header variant={"h3"}>Act As Role (Admin) - Role Override</Header>}>
                    <Select
                        id={"roleOverride"}
                        placeholder="Choose an option"
                        options={roleOptions()}
                        onChange={(event): void => {
                            setOverrideRoleSelection(event.detail.selectedOption);
                        }}
                        selectedOption={overrideRoleSelection}
                    />
                    <hr />
                    <StyledOverrideButton>
                        <Button id={"overrideRoleButton"} variant="primary" onClick={onOverrideRoleClick}>
                            Override Role
                        </Button>
                    </StyledOverrideButton>
                </Container>
            )}
        />
    );
};
