// @ts-ignore ignored because the JS library does not include types
import stringify from "querystringify";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loader from "src/components/fields/Loader";
import KaleContext from "src/components/KaleContext";
import { KaleRoutesProps, MessageType } from "src/components/survey/KaleRoutes";
import { Bindle } from "src/services/BrandleService";
import {
    KaleAnvilResponse,
    KaleUnmatchedApplicationResponse,
    ReviewerGroupResponse,
} from "src/services/KaleApplicationService";
import {
    AppLayout,
    Box,
    Button,
    ContentLayout,
    Header,
    InputProps,
    Link,
    NonCancelableCustomEvent,
    RadioGroup,
    SpaceBetween,
    TableProps,
} from "@amzn/awsui-components-react-v3";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import { ApprovalType } from "src/components/survey/SurveyFormModel";
import { ApprovalStatusBadge } from "src/components/ApprovalStatuses/ApprovalStatusBadge";

export interface AnvilPageState {
    isLoading: boolean;
    unmatchedApplications: KaleUnmatchedApplicationResponse[];
    selectedApp?: KaleUnmatchedApplicationResponse;
    showModal: boolean;
}

export interface AnvilFetchUnmatchedResponse {
    hasUnmatchedApplications: boolean;
    applications: KaleUnmatchedApplicationResponse[];
}

export interface AnvilFetchMatchedResponse {
    isApplicationFound: boolean;
    applicationName?: string;
}

enum ColumnIds {
    applicationName = "applicationName",
    applicationStatus = "applicationStatus",
    metaDataLastUpdate = "metaDataLastUpdate",
}

const filterKeys = [ColumnIds.applicationName];

export default class Anvil extends Component<KaleRoutesProps & RouteComponentProps, AnvilPageState> {
    public static contextType = KaleContext;

    public constructor(props: KaleRoutesProps & RouteComponentProps) {
        super(props);

        this.state = {
            isLoading: true,
            unmatchedApplications: [],
            showModal: false,
        };

        this.createNew = this.createNew.bind(this);
        this.linkApplication = this.linkApplication.bind(this);
    }

    public componentDidMount(): void {
        const rawParams = stringify.parse(this.props.location.search);

        this.shouldRedirectToExisting(rawParams)
            .then((matchingAnvilResponse: AnvilFetchMatchedResponse): void => {
                if (matchingAnvilResponse && matchingAnvilResponse.isApplicationFound) {
                    Anvil.processLinkParams(rawParams).then((result: Record<string, any>): void => {
                        this.props.history.push({
                            pathname: `/edit/${matchingAnvilResponse.applicationName}`,
                            state: { anvilProps: { params: result } },
                        });
                    });
                } else {
                    this.shouldRedirectToNew().then((unmatchedApplicationResp: AnvilFetchUnmatchedResponse): void => {
                        if (unmatchedApplicationResp && unmatchedApplicationResp.hasUnmatchedApplications) {
                            this.setState({
                                isLoading: false,
                                unmatchedApplications: unmatchedApplicationResp.applications,
                            });
                        } else {
                            this.processAndRedirectToNew(rawParams);
                        }
                    });
                }
            })
            .catch((): void => {
                this.processAndRedirectToNew(rawParams);
            });
    }

    public render(): JSX.Element {
        return (
            <AppLayout
                headerSelector="#top-nav"
                navigationHide
                toolsHide
                content={
                    <ContentLayout
                        header={
                            <Header variant="h3">
                                Would you like to link this Anvil review to an existing application from the following
                                list?
                            </Header>
                        }
                    >
                        {this.state.isLoading && <Loader />}
                        {!this.state.isLoading && (
                            <SpaceBetween id={"anvil-radio-container"} size="xl">
                                <DASTable<KaleUnmatchedApplicationResponse>
                                    columnDefinitions={this.anvilColumnDefinitions()}
                                    filterProps={{
                                        type: DASTableFilterType.propertyFilter,
                                        filterKeys,
                                        placeholder: "Find Applications",
                                    }}
                                    id={"survey-index"}
                                    isLoading={this.state.isLoading}
                                    isResizable={true}
                                    rowItems={this.state.unmatchedApplications}
                                    tableName={"Applications"}
                                />
                                <Box textAlign="center">
                                    {this.state.selectedApp && (
                                        <Button
                                            id={"linkApp"}
                                            variant="primary"
                                            onClick={(): void => {
                                                if (this.state.selectedApp) {
                                                    this.linkApplication(this.state.selectedApp);
                                                }
                                            }}
                                        >
                                            Link Application
                                        </Button>
                                    )}
                                </Box>
                                <Box textAlign="center">
                                    <Box variant="h2" margin={{ bottom: "l" }}>
                                        OR
                                    </Box>
                                    <Button id={"create-new"} variant="primary" onClick={this.createNew}>
                                        Create New Application
                                    </Button>
                                </Box>
                            </SpaceBetween>
                        )}
                    </ContentLayout>
                }
            />
        );
    }

    public shouldRedirectToExisting(rawParams: Record<string, string>): Promise<AnvilFetchMatchedResponse> {
        if (
            Object.prototype.hasOwnProperty.call(rawParams, "applicationId") &&
            Object.prototype.hasOwnProperty.call(rawParams, "taskId")
        ) {
            return this.context.service.kaleAppService
                .fetchMatchingAnvilApplications(rawParams["applicationId"], rawParams["taskId"])
                .then((response: KaleAnvilResponse): AnvilFetchMatchedResponse => {
                    return { isApplicationFound: true, applicationName: response.applicationName };
                })
                .catch((): AnvilFetchMatchedResponse => {
                    return { isApplicationFound: false };
                });
        }
        return Promise.resolve({ isApplicationFound: false });
    }

    public shouldRedirectToNew(): Promise<AnvilFetchUnmatchedResponse> {
        return this.context.service.kaleAppService
            .fetchUnmatchedApplications()
            .then((response: KaleUnmatchedApplicationResponse[]): AnvilFetchUnmatchedResponse => {
                return { hasUnmatchedApplications: response.length > 0, applications: response };
            })
            .catch((): AnvilFetchUnmatchedResponse => {
                return { hasUnmatchedApplications: false, applications: [] };
            });
    }

    public createNew(): void {
        this.processAndRedirectToNew(stringify.parse(this.props.location.search));
    }

    public linkApplication(selectedApp: KaleUnmatchedApplicationResponse): void {
        Anvil.processLinkParams(stringify.parse(this.props.location.search)).then(
            (result: Record<string, any>): void => {
                this.context.service.kaleAppService
                    .linkApplication(
                        selectedApp.applicationName,
                        result.applicationOwner,
                        result.taskId,
                        result.anvilId
                    )
                    .then((): void => {
                        this.props.history.push({
                            pathname: `/edit/${selectedApp.applicationName}`,
                        });
                    })
                    .catch((error: Error): void => {
                        this.props.displayMessage(MessageType.error, error.message);
                    });
            }
        );
    }

    public anvilColumnDefinitions(): TableProps.ColumnDefinition<KaleUnmatchedApplicationResponse>[] {
        return [
            {
                id: ColumnIds.applicationName,
                header: "Application Name",
                cell: (response): JSX.Element => {
                    return this.getRadioButton(response);
                },
                sortingField: ColumnIds.applicationName,
            },
            {
                id: ColumnIds.applicationStatus,
                header: "Application Status",
                cell: (response): JSX.Element => {
                    return ApprovalStatusBadge({ status: response.legalStatus, type: ApprovalType.PrivacyApproval });
                },
                sortingField: ColumnIds.applicationStatus,
            },
            {
                id: ColumnIds.metaDataLastUpdate,
                header: "Last Modified",
                cell: (response): string => {
                    return new Date(response.metaDataLastUpdate).toUTCString();
                },
                sortingField: ColumnIds.metaDataLastUpdate,
            },
        ];
    }

    public getRadioButton(response: KaleUnmatchedApplicationResponse): JSX.Element {
        return (
            <RadioGroup
                key={response.applicationName}
                value={
                    this.state.selectedApp?.applicationName === response.applicationName
                        ? response.applicationName
                        : null
                }
                items={[
                    {
                        value: response.applicationName,
                        label: (
                            <Link
                                id={response.applicationName}
                                href={`/edit/${encodeURIComponent(response.applicationName)}`}
                                external
                            >
                                {response.applicationName}
                            </Link>
                        ),
                    },
                ]}
                onChange={(e: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
                    const selectedApp = this.state.unmatchedApplications.filter(
                        (response: KaleUnmatchedApplicationResponse): boolean => {
                            return response.applicationName === e.detail.value;
                        }
                    )[0];
                    this.setState({ selectedApp });
                }}
            />
        );
    }

    public processAndRedirectToNew(rawParams: Record<string, any>): void {
        this.processParams(rawParams).then((result): void => {
            this.props.history.push({
                pathname: "/new",
                state: { anvilProps: { params: result } },
            });
        });
    }

    private processParams(rawParams: Record<string, any>): Promise<Record<string, any>> {
        if (Object.prototype.hasOwnProperty.call(rawParams, "applicationId")) {
            rawParams["anvilId"] = rawParams["applicationId"];
            delete rawParams.applicationId;
        }

        if (Object.prototype.hasOwnProperty.call(rawParams, "bindleIds")) {
            const bindleIdList = rawParams["bindleIds"].split(",");

            this.context.service.brandleService
                .findBindlesByOwner(this.context.user.userId, false)
                .then((response: Bindle[]): void => {
                    const bindleNames = response
                        .filter((result: Bindle): boolean => {
                            return bindleIdList.includes(result.bindle.id);
                        })
                        .map((bindle: Bindle): string => {
                            return bindle.bindle.name;
                        });

                    if (bindleNames.length > 0) {
                        rawParams["controlBindle"] = [bindleNames[0]];
                        rawParams["relatedBindles"] = bindleNames;

                        // Fetch reviewer group using control bindle ID
                        this.context.service.kaleAppService
                            .fetchReviewerGroup(`${bindleIdList[0]}`)
                            .then((revResponse: ReviewerGroupResponse): void => {
                                rawParams["reviewGroup"] = revResponse.groupName;
                            })
                            .catch((error: any): void => {
                                console.error(error);
                            });
                    }
                })
                .catch((): object => {
                    return rawParams;
                });
        }
        return Promise.resolve(rawParams);
    }

    public static processLinkParams(rawParams: Record<string, any>): Promise<Record<string, any>> {
        const resultParams = {
            applicationOwner: rawParams.applicationOwner,
            taskId: rawParams.taskId,
            anvilId: rawParams.applicationId,
        };
        return Promise.resolve(resultParams);
    }
}
