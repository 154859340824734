import React, { useContext } from "react";
import styled from "styled-components";
import { TopNavigation, TopNavigationProps } from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens/polaris.js";
import { HeaderMenuContext } from "src/components/layout/Layout";
import { useKaleDispatch, useKaleSelector } from "src/redux/hooks";
import { selectIsCommentsViewSidebarOpen } from "src/components/CommentsView/state/selectors";
import { commentViewSidebarToggled } from "src/components/CommentsView/state/actions";
import { ThemeMode, useThemeMode } from "./useThemeMode";

export interface TopNavProps {
    email: string;
}

const kaleLogoColor = "#40f790";

const TopNavWrapper = styled.div.attrs({ id: "top-nav" })`
    // Values came from Cloudscape "Fixed top navigation" example
    // https://cloudscape.aws.dev/components/top-navigation/?tabId=api#code-examples
    position: sticky;
    top: 0;
    z-index: 1002;

    // Color overrides to keep original navigation color scheme
    // This contradicts Polaris's native styles (AWS blue) but it allows us to keep
    // the original personality of our Kale website (green and Amazon orange).
    a[href="/"] {
        color: ${kaleLogoColor} !important;
    }
    a,
    button[aria-haspopup="true"],
    span[role="menuitem"] {
        color: ${awsui.colorTextTopNavigationTitle} !important;
        &:hover {
            color: ${awsui.colorBackgroundButtonPrimaryDefault} !important;
        }
    }
    span[role="menuitem"][aria-disabled="true"] {
        color: ${awsui.colorTextInputDisabled} !important;
        &:hover {
            color: ${awsui.colorTextInputDisabled} !important;
            cursor: not-allowed;
        }
    }
`;

export const utilityLabels = {
    settings: "Settings",
    faq: "FAQ",
    user: "User",
    comments: "Comments",
};

export const TopNav = React.memo(({ email }: TopNavProps): JSX.Element => {
    const dispatch = useKaleDispatch();
    const { hasCommentsView } = useContext(HeaderMenuContext);
    const isCommentsViewSidebarOpen = useKaleSelector(selectIsCommentsViewSidebarOpen);
    const { compactModeEnabled, darkModeEnabled, onModeChange } = useThemeMode();

    const utilities: TopNavigationProps.Utility[] = [
        {
            ariaLabel: utilityLabels.settings,
            iconName: "settings",
            items: [
                {
                    id: "Theme",
                    items: [
                        {
                            id: ThemeMode.LIGHT_MODE_ID,
                            text: ThemeMode.LIGHT_MODE_ID,
                            disabled: !darkModeEnabled,
                        },
                        {
                            id: ThemeMode.DARK_MODE_ID,
                            text: ThemeMode.DARK_MODE_ID,
                            disabled: darkModeEnabled,
                        },
                    ],
                    text: "Theme",
                },
                {
                    id: "Spacing",
                    items: [
                        {
                            id: ThemeMode.COMFORTABLE_MODE_ID,
                            text: ThemeMode.COMFORTABLE_MODE_ID,
                            disabled: !compactModeEnabled,
                        },
                        {
                            id: ThemeMode.COMPACT_MODE_ID,
                            text: ThemeMode.COMPACT_MODE_ID,
                            disabled: compactModeEnabled,
                        },
                    ],
                    text: "Spacing",
                },
            ],
            onItemClick: ({ detail: { id } }) => onModeChange(id),
            type: "menu-dropdown",
        },
        {
            ariaLabel: utilityLabels.faq,
            external: true,
            externalIconAriaLabel: "Opens in new tab",
            href: "https://w.amazon.com/bin/view/Kale/FAQ/",
            text: utilityLabels.faq,
            type: "button",
        },
        {
            ariaLabel: utilityLabels.user,
            iconName: "user-profile",
            items: [
                {
                    id: "resources",
                    items: [
                        {
                            id: "privacy_engineering",
                            external: true,
                            externalIconAriaLabel: "Opens in new tab",
                            href: "https://w.amazon.com/bin/view/PrivacyEngineering",
                            text: "CDO Privacy Engineering Team",
                        },
                    ],
                    text: "Resources",
                },
            ],
            text: email,
            type: "menu-dropdown",
        },
    ];
    if (hasCommentsView) {
        utilities.push({
            ariaLabel: utilityLabels.comments,
            iconName: isCommentsViewSidebarOpen ? "close" : "contact",
            onClick: () => dispatch(commentViewSidebarToggled()),
            text: utilityLabels.comments,
            type: "button",
        });
    }

    return (
        <TopNavWrapper>
            <TopNavigation
                identity={{
                    href: "/",
                    title: "Kale",
                }}
                utilities={utilities}
            />
        </TopNavWrapper>
    );
});
