import React, { useCallback, useState, useRef, useContext } from "react";
// Polaris Components
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import Form from "@amzn/awsui-components-react-v3/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react-v3/polaris/space-between";
import NewApplicationFieldsPanel, { FieldValuesState } from "src/components/NewApplication/NewApplicationFieldsPanel";
import { FetchedBindlesState } from "src/components/NewApplication/useFetchBindlesOnMount";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import KaleContext, { useKaleServices } from "src/components/KaleContext";
import {
    CreateApplicationRequestBody,
    KaleApplicationService,
    ServerErrorMessage,
} from "src/services/KaleApplicationService";
import { useHistory } from "react-router-dom";
import { AnvilProps } from "src/components/survey/LegalSurveyPage";
import { TEST_IDS } from "shared/new-application";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { useSubscribeToUbergetCacheUpdatedMessage } from "src/websocket/message/UbergetCacheUpdated";
import { kaleUrls } from "src/util/Urls";

const { CREATE_APPLICATION } = TEST_IDS;

type CreateApplicationCb = (fieldValues?: FieldValuesState) => Promise<void>;

// This page receives the anvil data via query params. This anvil data is saved via Uber-Save which requires that anvil
// data be merged into the Uber-Get response. Otherwise, existing data on the app may get deleted.
// This function just merges the anvil data into the response from the Uber-Get
// The output is sent to Uber-Save API
export const mergeAnvilPropsIntoResponse = (response: SurveyResponse, anvilProps?: AnvilProps): SurveyResponse => {
    return {
        ...response,
        appInfo: {
            ...response.appInfo,
            applicationName:
                // Prevent update error in new app flow if response's App Name is different from Anvil's App Name
                anvilProps?.params?.applicationName && !response.appInfo.applicationName.length
                    ? anvilProps.params.applicationName
                    : response.appInfo.applicationName,
            // Prevent update error in new app flow if response's Control Bindle is different from Anvil's Bindle
            controlBindle:
                anvilProps?.params?.controlBindle && !response.appInfo.controlBindle.length
                    ? anvilProps.params.controlBindle
                    : response.appInfo.controlBindle,
            review: {
                ...response.appInfo.review,
                reviewGroup: anvilProps?.params?.reviewGroup || response.appInfo.review.reviewGroup,
                applicationId: anvilProps?.params?.applicationId || response.appInfo.review.applicationId,
                applicationOwner: anvilProps?.params?.applicationOwner || response.appInfo.review.applicationOwner,
                anvilId: anvilProps?.params?.anvilId || response.appInfo.review.anvilId,
                taskId: anvilProps?.params?.taskId || response.appInfo.review.taskId,
                relatedBindles: anvilProps?.params?.relatedBindles || response.appInfo.review.relatedBindles,
                contactList: anvilProps?.params?.applicationOwner
                    ? [anvilProps.params.applicationOwner]
                    : response.appInfo.review.contactList,
            },
        },
    };
};

// useCreateApplicationCb sends the synchronous Create App API request to the Kale Backend. Once getting a successful
// response, it puts the page on an infinite loading spinner
// Future actions on the page will be performed by the websocket-subscribing callback, defined lower in this file.
const useCreateApplicationCb = (setIsCreatingApp: (isCreatingApp: boolean) => void): CreateApplicationCb => {
    const { displayMessage } = useMessageBannerActions();
    const { kaleAppService } = useKaleServices();

    return async (fieldValues): Promise<void> => {
        // If the user hasn't answered the questions we expect backend validation to relay a message
        const { applicationName = "", controlBindleName = "", isConfidential = false } = fieldValues ?? {};

        try {
            // Create the new application and navigate user to legal survey upon success
            const requestBody: CreateApplicationRequestBody = {
                appName: applicationName,
                controlBindleName,
                isConfidential,
            };
            setIsCreatingApp(true);
            await kaleAppService.createApplication(requestBody);
            // The creation above leads to the App being created. In the backend that will lead to an Uber Get Cache
            // file to be written for that app. Once that cache is written, a web socket will be emitted
            // The next set of actions are done by the frontend code which is subscribed to the websocket msg
            // See useSubscribeToUbergetCacheUpdatedMessage() below
        } catch (e) {
            setIsCreatingApp(false);
            const rawErrorMessages: ServerErrorMessage[] = JSON.parse((e as Error).message).messages;
            const renderedListItems = rawErrorMessages.map((data: ServerErrorMessage): JSX.Element => {
                return <li key={data.source.parameterName}>{data.source.parameterName + ": " + data.message}</li>;
            });
            const renderedErrorList = (
                <React.Fragment>
                    Error Creating Attestation
                    <ul> {renderedListItems} </ul>
                </React.Fragment>
            );
            displayMessage(MessageType.error, renderedErrorList);
        }
    };
};

// saveAnvilData is triggered AFTER the creation is completed and verified via websocket message
// It saves the anvil data (from query params inside "anvilProps") into the Kale App by using view() and update()
const saveAnvilData = async (
    displayMessage: DisplayMessageCb,
    kaleAppService: KaleApplicationService,
    applicationName: string,
    anvilProps?: AnvilProps
): Promise<void> => {
    // If user comes from Anvil with Anvil info in the query params,
    // the app is updated behind the scenes to persist Anvil ID and Task ID on the survey response
    if (anvilProps) {
        try {
            const survey = await kaleAppService.view(applicationName);
            const mergedResponse = mergeAnvilPropsIntoResponse(survey, anvilProps);
            await kaleAppService.update(mergedResponse);
        } catch (e) {
            const rawErrorMessages: ServerErrorMessage[] = JSON.parse((e as Error).message).messages;
            const renderedListItems = rawErrorMessages.map((data: ServerErrorMessage): JSX.Element => {
                return <li key={data.source.parameterName}>{data.source.parameterName + ": " + data.message}</li>;
            });
            const renderedErrorList = (
                <React.Fragment>
                    Error Linking Anvil Information
                    <ul> {renderedListItems} </ul>
                </React.Fragment>
            );
            displayMessage(MessageType.error, renderedErrorList);
        }
    }
};

interface NewApplicationFormProps {
    bindlesState: FetchedBindlesState;
    setIsCreatingApp: (isCreatingApp: boolean) => void;
    anvilProps?: AnvilProps;
}

// For complete understanding of the frontend + backend workflow for /new page, see sequence diagrams below
// Non-Anvil Flow: https://tiny.amazon.com/10v1bd7md/plancorpamazplanformencohtml
// Anvil Flow: https://tiny.amazon.com/1jo89m452/plancorpamazplanformencohtml
const NewApplicationForm = ({ bindlesState, setIsCreatingApp, anvilProps }: NewApplicationFormProps): JSX.Element => {
    const [fieldValues, setFieldValues] = useState<FieldValuesState>();

    const { displayMessage } = useMessageBannerActions();
    const { kaleAppService } = useKaleServices();
    const history = useHistory();
    const kaleContext = useContext(KaleContext);
    const {
        features: { isAccessControlPageLive },
    } = kaleContext;

    const createApplicationCb = useCreateApplicationCb(setIsCreatingApp);

    const deps = {
        setIsCreatingApp,
        fieldValues,
        displayMessage,
        kaleAppService,
        history,
        kaleContext,
        anvilProps,
        isAccessControlPageLive,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    // Subscribe to the Websocket messages for cache messages. The frontend has to wait for the WS message before
    // redirecting the user to the /edit page, or for anvil cases, saving anvil data to the App.
    // The App is in a "usable" form only after the cache is written. If a GET request is made on the App before the
    // cache message is received then it will 404 the request.
    // This callback does all the logic after receiving the websocket message, whether it be redirecting to /edit or
    // performing anvil actions before redirecting to /edit.
    useSubscribeToUbergetCacheUpdatedMessage(
        useCallback(async (message): Promise<void> => {
            // anvilProps and fieldValues are intentionally not provided via the dependency array because the
            // callback does not need to be re-created each time those are updated. The re-created callback would
            // just cause that new callback to subscribe to the websocket channel again. But since the old callback
            // would still have the new/correct values of these two objects (via depsRef below), there's no need to
            // resubscribe. In short: these two are understood to be "what deps" rather than "when deps"
            const {
                setIsCreatingApp,
                fieldValues,
                displayMessage,
                kaleAppService,
                history,
                kaleContext,
                anvilProps,
                isAccessControlPageLive,
            } = depsRef.current;

            if (!fieldValues?.applicationName) {
                return;
            }

            // Because Websocket (WS) Subscription is on the admin channel and the Caching "segment"
            // (WS terminology). The /new page on the frontend will receive WS messages for all caches updated in
            // the entire Kale site. This code ignores cache updates for all apps that are not equal to the current
            // app name that the user has typed into the app name textbox.
            if (message.payload.appName !== fieldValues.applicationName) {
                console.info(
                    `Ignoring WS for ${message.payload.appName} as it doesn't match ${fieldValues.applicationName}`
                );
                return;
            }

            // Sleep for a specified time in pre-prod environments to make sure that the bindle gets created
            // before the UI can make any GET request on the app. This length of timeout is configurable per stage.
            // It should always be 0 in prod.
            // There's a detailed comment in StageConfig.ts that explains more deeply about why this is here.
            await delay(kaleContext.config.anvilFlowAppCreationTimeoutInMS);

            // Anvil query param must be saved otherwise it'll be lost after redirection
            await saveAnvilData(displayMessage, kaleAppService, fieldValues.applicationName, anvilProps);

            setIsCreatingApp(false);

            // get latest review when going to edit page
            const appName = fieldValues.applicationName;
            const reviewId = await kaleAppService.getLatestReviewID(appName);

            history.push(
                isAccessControlPageLive
                    ? kaleUrls.editKaleAccessControlRecordUrl(appName, reviewId.toString())
                    : kaleUrls.editKaleRecordUrl(appName, reviewId.toString())
            );
        }, [])
    );

    return (
        <Form
            id={"surveyFormNewApplicationFlow"}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        id={"createApplication"}
                        data-testid={CREATE_APPLICATION}
                        variant="primary"
                        onClick={(): Promise<void> => createApplicationCb(fieldValues)}
                    >
                        Create
                    </Button>
                </SpaceBetween>
            }
        >
            <NewApplicationFieldsPanel
                onFieldValueChange={setFieldValues}
                bindlesState={bindlesState}
                anvilProps={anvilProps}
            />
        </Form>
    );
};
export default NewApplicationForm;

const delay = async (ms: number): Promise<void> => {
    // eslint-disable-next-line
    return new Promise((resolve): number => setTimeout(resolve, ms));
};
