import { FormField } from "@amzn/awsui-components-react-v3";
import React from "react";
import Model from "src/components/constants/Constants";
import { Choice } from "src/services/KaleApplicationService";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";

interface IsConfidentialFieldProps {
    value: boolean;
    onChange: (value: boolean) => void;
}
const IsConfidentialField = (props: IsConfidentialFieldProps): JSX.Element => {
    return (
        <FormField
            label={"Is this application associated with a confidential project?"}
            description={
                `Confidential projects will be redacted when they appear in Kale Analytics and ` +
                `other extracts generated from this data.`
            }
        >
            <KaleRadiobutton
                id={"isConfidentialProject"}
                isFormInReadonlyMode={false}
                choices={Model.getData("isConfidentialProject").options.map((option: any): Choice => {
                    return {
                        value: option.name,
                        label: option.displayName,
                    };
                })}
                expectedAnswer={props.value ? "true" : "false"}
                onChangeCallback={(e): void => props.onChange(e.response === "true")}
            />
        </FormField>
    );
};
export default React.memo(IsConfidentialField);
