import { Cards, CardsProps } from "@amzn/awsui-components-react-v3";
import React, { useMemo } from "react";
import { CardNames } from "../state/model";
import { selectCardsDisplayOrder } from "src/components/survey/ApplicationOverview/state/selectors";
import { CardBody, CardHeader } from "src/components/survey/ApplicationOverview/SummaryCards/Card";
import { useKaleSelector } from "src/redux/hooks";

const CARD_TEMPLATE: CardsProps.CardDefinition<CardNames> = {
    header: (cardName): React.ReactNode => <CardHeader cardName={cardName} />,
    sections: [
        {
            content: (cardName): React.ReactNode => <CardBody cardName={cardName} />,
        },
    ],
};

interface SummaryCardsProps {
    hasReviewerPermissions: boolean;
}

/** Component responsible for defining and rendering the SummaryCards for ApplicationOverview */
export const SummaryCards = React.memo(({ hasReviewerPermissions }: SummaryCardsProps): JSX.Element => {
    const defaultDisplayOrder = useKaleSelector(selectCardsDisplayOrder);

    // Memoize this to ensure a stable array reference when nothing has changed.
    const displayOrder = useMemo((): CardNames[] => {
        if (hasReviewerPermissions) {
            return defaultDisplayOrder;
        } else {
            // Users without reviewer permissions should not see Privacy Alerts information
            return defaultDisplayOrder.filter((cardName): boolean => cardName !== CardNames.privacyAlertsSummary);
        }
    }, [hasReviewerPermissions, defaultDisplayOrder]);

    return (
        <Cards<CardNames>
            cardDefinition={CARD_TEMPLATE}
            cardsPerRow={[{ cards: 1 }, { minWidth: 550, cards: 2 }]}
            items={displayOrder}
        />
    );
});
