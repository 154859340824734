import { useContext, useEffect } from "react";
import { WebSocketMessageBase } from "src/websocket/useOnWebSocketMessage";
import { WebSocketContext } from "src/websocket/WebSocketContext";

export type BulkEditStatus = "started" | "in progress" | "completed";
export interface BulkEditResult {
    savedStatusesCount: number;
    savedHasPersonalDataCount: number;
    savedTableAnswersCount: number;
    savedFieldAnswersCount: number;
}
interface Payload {
    requestId: string;
    status: BulkEditStatus;
    hasPersonalData: string;
    sqlCallsCount: number;
    response: BulkEditResult | null;
    error: string;
}
export interface BulkEditMessage extends WebSocketMessageBase {
    payload: Payload;
}
export type BulkEditMessageHandler = (message: BulkEditMessage) => void;

export const useSubscribeToBulkEditMessage = (callback: BulkEditMessageHandler): void => {
    const { subscribeToBulkEditMessage, unsubscribeToBulkEditMessage } = useContext(WebSocketContext);

    useEffect((): (() => void) => {
        subscribeToBulkEditMessage(callback);
        return (): void => unsubscribeToBulkEditMessage(callback);
    }, [callback, subscribeToBulkEditMessage, unsubscribeToBulkEditMessage]);
};
