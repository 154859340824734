import { RumHandle, initializeRumHandle } from "src/services/RumService/cloud-watch-rum";
import { IRumServiceMethods, RumServiceProps } from "src/services/RumService/types";
export class RumService implements IRumServiceMethods {
    public recordEvent: IRumServiceMethods["recordEvent"];
    public recordPageView: IRumServiceMethods["recordPageView"];

    public constructor(props: RumServiceProps) {
        const rumHandle: RumHandle = initializeRumHandle(props);
        this.recordEvent = ({ name, ...eventDetails }) => {
            return rumHandle?.recordEvent(name, eventDetails);
        };
        this.recordPageView = (...forwardingArgs) => {
            rumHandle?.recordPageView(...forwardingArgs);
        };
    }
}
