import React, { useEffect, useRef, useState } from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemChangeCallback, FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { debounce } from "lodash";
import { SelectProps, Multiselect, SpaceBetween, Box, Link } from "@amzn/awsui-components-react-v3";
import { DescriptionWithInfoLink } from "src/components/survey/ApplicationPermissions/DescriptionWithInfoLink";
import { KaleFormField } from "src/components/fields/KaleFormField";

export interface RelatedBindleProps extends FunctionalSurveyItemProps<string[]> {
    readonly options: SelectProps.Option[];
}

export const ON_CHANGE_DEBOUNCE_TIMEOUT = 1000;

const handleOnChange = (
    onChangeCallback: FunctionalSurveyItemChangeCallback<string[]>,
    id: string,
    value: string[]
): void => {
    onChangeCallback({
        id: id,
        response: value,
    });
};

const RelatedBindle = (props: RelatedBindleProps): JSX.Element => {
    // TODO - this is really hard to read, clean up
    const {
        id,
        options,
        expectedAnswer = [],
        isReadonly = false,
        isFormInReadonlyMode = false,
        isRequired = false,
        isLoading = false,
        onChangeCallback,
    } = props;

    const [localValue, setLocalValue] = useState<string[]>(expectedAnswer);
    const localValueRef = useRef<string[]>(localValue);
    localValueRef.current = localValue;

    useEffect((): void => {
        if (localValueRef.current.length === 0 && expectedAnswer.length !== 0) {
            setLocalValue(expectedAnswer);
        }
    }, [expectedAnswer]);

    const readonly = isReadonly || isFormInReadonlyMode;
    const validOptionsFromResponse = options.filter((option): boolean => {
        return localValue?.includes(option.label ?? "") ?? false;
    });

    const invalidOptionsFromResponse = localValue
        .filter((answer): boolean => {
            return !validOptionsFromResponse.find((validOption): boolean => answer === validOption.label);
        })
        .map((invalidAnswer): SelectProps.Option => {
            return { value: "", label: invalidAnswer };
        });
    const selectedOptions = [...invalidOptionsFromResponse, ...validOptionsFromResponse];

    // Static instance of debounced onChange handling function. Only created during initial render.
    const debouncedHandleOnChange = useRef(debounce(handleOnChange, ON_CHANGE_DEBOUNCE_TIMEOUT)).current;
    const popoverContent = (
        <div>
            <Box variant="p">If no bindles are present, ensure all of the following are correct.</Box>
            <ul>
                <li>
                    You have at least one bindle by visiting{" "}
                    <Link external href="https://bindles.amazon.com/">
                        bindles.amazon.com
                    </Link>
                    , or
                </li>
                <li>You are a member of a team that owns a bindle, and</li>
                <li>You are using a supported browser (FireFox and Chrome), and</li>
                <li>Your browser is not blocking third-party cookies and site data.</li>
            </ul>
        </div>
    );

    const displayText = "Identify all Bindle(s) that are covered by this application.";
    return (
        <SpaceBetween size={"xs"}>
            <KaleFormField
                label={"Related Bindles"}
                description={<DescriptionWithInfoLink displayText={displayText} content={popoverContent} />}
                stretch={false}
                errorText={!isRequired || Boolean(localValue.length) ? "" : "Required"}
            >
                <Multiselect
                    id={id}
                    placeholder="Choose options"
                    options={options}
                    selectedOptions={selectedOptions}
                    disabled={readonly}
                    onChange={(e): void => {
                        const newLocalValue = e.detail.selectedOptions.map(
                            (selectedOption): string => selectedOption.label ?? ""
                        );
                        setLocalValue(newLocalValue);
                        debouncedHandleOnChange(onChangeCallback, id, newLocalValue);
                    }}
                    statusType={isLoading ? "loading" : undefined}
                    filteringType={"auto"}
                />
            </KaleFormField>
        </SpaceBetween>
    );
};

export const WithFunctionalReadonlyRelatedBindle = withFunctionalReadonly(RelatedBindle);
