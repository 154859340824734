import { ApplicationStatus } from "src/components/survey/SurveyFormModel";
import { UserRole } from "src/permissions";

type SelectCanUserEdit = (legalStatus: ApplicationStatus) => boolean;
const USER_ROLE_EDIT_PERMISSIONS: Record<UserRole, SelectCanUserEdit> = {
    [UserRole.admin]: (legalStatus): boolean =>
        [ApplicationStatus.approved, ApplicationStatus.inReview, ApplicationStatus.inProgress].includes(legalStatus),
    [UserRole.accountingReviewer]: (): boolean => false,
    [UserRole.fraudReviewer]: (): boolean => false,
    [UserRole.reviewer]: (status): boolean =>
        [ApplicationStatus.inProgress, ApplicationStatus.inReview].includes(status),
    [UserRole.serviceOwner]: (status): boolean =>
        [ApplicationStatus.inProgress, ApplicationStatus.inReview].includes(status),
    [UserRole.readOnly]: (): boolean => false,
};

/**
 * Selector method to determine whether a user has edit permissions in the TableView based on user role and
 * the application's legal status
 * @param legalStatus
 * @param userRole
 * @return boolean value indicating whether or not a user has edit permissions in the table view
 */
export const selectCanUserEdit = (legalStatus: ApplicationStatus, userRole: UserRole): boolean => {
    if (legalStatus && userRole) {
        return USER_ROLE_EDIT_PERMISSIONS[userRole](legalStatus);
    } else return false;
};
