import { default as React } from "react";
import { Box, ExpandableSection, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react-v3";
import { SummaryCards } from "src/components/survey/ApplicationOverview/SummaryCards";
import { AEDUSandfireTable } from "src/components/survey/AEDUSandfireTable";
import { AlertsBanner } from "src/components/survey/ApplicationOverview/AlertsBanner";
import TwoStageRender from "src/components/decorators/TwoStageRender";
import {
    useContainerExpandedState,
    useHasReviewerPermissions,
    useLoadCardsDataOnMount,
} from "src/components/survey/ApplicationOverview/hooks";
import { TEST_IDS } from "shared/survey";
const { APPLICATION_OVERVIEW } = TEST_IDS;

interface ApplicationOverviewProps {
    applicationName: string;
}

/**
 * Primary Container Component for the "Guided Reviewer Experience" feature.
 * @param applicationName - the name of the application to fetch data for.
 */
export const ApplicationOverview = React.memo(({ applicationName }: ApplicationOverviewProps): JSX.Element => {
    useLoadCardsDataOnMount(applicationName);
    const { isContainerExpanded, hasContainerEverExpanded, toggleIsExpanded } = useContainerExpandedState();
    const hasReviewerPermissions = useHasReviewerPermissions();

    return (
        <div data-testid={APPLICATION_OVERVIEW.ROOT}>
            {hasReviewerPermissions && (
                <AlertsBanner
                    onButtonClick={toggleIsExpanded}
                    buttonText={`${isContainerExpanded ? "Hide" : "View"} Alerts`}
                    hasReviewerPermissions={hasReviewerPermissions}
                />
            )}
            <ExpandableSection
                expanded={isContainerExpanded}
                variant={"container"}
                header={
                    <Header
                        variant={"h3"}
                        description={
                            `Data depicts a snapshot of recent history and is not rendered in real time. ` +
                            `To check for updates, please save your work and refresh the page.`
                        }
                    >
                        Application Overview
                    </Header>
                }
                onChange={toggleIsExpanded}
            >
                {
                    // Delay rendering contents until the first time the container is expanded in order to save on
                    // overall render time in the UI thread during page load
                    hasContainerEverExpanded && (
                        <TwoStageRender
                            renderInProgress={(): React.ReactNode => (
                                <Box variant={"div"} textAlign={"center"}>
                                    <Spinner size={"big"} />
                                </Box>
                            )}
                            renderComplete={(): React.ReactNode => (
                                <div data-testid={APPLICATION_OVERVIEW.MAIN_CONTENT}>
                                    <SpaceBetween size={"l"}>
                                        <SummaryCards hasReviewerPermissions={hasReviewerPermissions} />
                                        {<AEDUSandfireTable />}
                                    </SpaceBetween>
                                </div>
                            )}
                        />
                    )
                }
            </ExpandableSection>
        </div>
    );
});
