/* istanbul ignore file */
// Skipping this file since testing with Cognito is difficult

import { CognitoAuth, CognitoAuthSession, CognitoIdToken } from "amazon-cognito-auth-js";
import { CognitoConfig } from "src/Config";

export interface User {
    email: string;
    id: string;
    firstName: string;
    lastName: string;
}

export type TokenGetter = () => Promise<string>;

interface Auth {
    cognito: CognitoAuth;
    sessionPromise: Promise<CognitoAuthSession>;
}

export class CognitoService {
    private readonly cognitoClientId: string;
    private readonly cognitoDomain: string;
    private readonly cognitoUserPoolId: string;

    private refreshToken: string;
    private accessToken: string;
    private accessTokenExp: Date;

    public constructor({ cognitoClientId, cognitoDomain, cognitoUserPoolId }: CognitoConfig) {
        this.cognitoClientId = cognitoClientId;
        this.cognitoDomain = cognitoDomain;
        this.cognitoUserPoolId = cognitoUserPoolId;

        this.refreshToken = "";
        this.accessToken = "";
        this.accessTokenExp = new Date();

        this.getAccessToken = this.getAccessToken.bind(this);
        this.fetchUser = this.fetchUser.bind(this);
    }

    private setupAuth(): Auth {
        const baseUrl = "https://" + window.location.host;
        const auth = new CognitoAuth({
            AppWebDomain: this.cognitoDomain,
            ClientId: this.cognitoClientId,
            RedirectUriSignIn: baseUrl,
            RedirectUriSignOut: baseUrl,
            // Each of the below must be configured in Cognito to work. All 3 below, currently are configured.
            // "profile" is the one that's needed so that firstName and lastName are included in the token.
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: this.cognitoUserPoolId,
        });

        // Make user you turn on OAuth2 Authorization Code Grant flow
        auth.useCodeGrantFlow();
        // Have to double encode it since it'll be decoded twice
        const state = `redirect=${encodeURIComponent(encodeURIComponent(window.location.href))}`;
        const previousState = new URLSearchParams(window.location.search).get("state");
        auth.setState(previousState || state);

        const authSessionPromise = new Promise<CognitoAuthSession>((resolve, reject): void => {
            auth.userhandler = {
                onFailure: (err: any): void => {
                    auth.clearCachedTokensScopes();
                    auth.signOut();
                    reject(err);
                },
                onSuccess: (authSession: CognitoAuthSession): void => {
                    const hasCode = !!new URLSearchParams(window.location.search).get("code");
                    const state = new URLSearchParams(window.location.search).get("state");
                    const redirectTo = state ? new URLSearchParams(state).get("redirect") : "";
                    const urlWithoutParameters = location.protocol + "//" + location.host + location.pathname;
                    const shouldRedirect = redirectTo !== urlWithoutParameters;

                    // We have redirections in Kale because when a user auth's with Midway and comes back to the
                    // dashboard, we want to redirect the user to the deep link into Kale that they had originally
                    // intended to visit before the midway redirection. (If they happened to have used a deep link)
                    //
                    // There's a security exploit where a malicious user can provide a malicious link as a "redirect"
                    // param in the state param. Notably, before Kale redirects, we need to examine the value in the
                    // redirect to make sure that it's a kale deep link and not some other arbitrary link.
                    // This logic prevents those malicious redirects from happening in Kale.
                    //
                    // Example "malicious link" that redirects to amazon wiki instead of Kale:
                    // https://kale.amazon.dev/?state=redirect%3Dhttps%253A%252F%252Fw.amazon.com/
                    //
                    // More context on the security issue: https://sim.amazon.com/issues/ACAT-7790
                    if (redirectTo && shouldRedirect && !redirectTo.startsWith(`${location.origin}/`)) {
                        // Below removes all the params and keeps the user where they currently are
                        console.warn(`Unknown redirection detected: cancelling redirection to ${redirectTo}`);
                        window.history.replaceState({}, document.title, urlWithoutParameters);
                    }
                    if (redirectTo && shouldRedirect) {
                        // eslint-disable-next-line
                        location.href = redirectTo;
                    } else if (hasCode) {
                        // But we still need to remove the code and state params from the URL bar
                        window.history.replaceState({}, document.title, urlWithoutParameters);
                    }
                    resolve(authSession);
                },
            };
        });

        return {
            cognito: auth,
            sessionPromise: authSessionPromise,
        };
    }

    private getAuthSession(): Promise<CognitoAuthSession> {
        const { cognito, sessionPromise } = this.setupAuth();
        const hasCode = !!new URLSearchParams(window.location.search).get("code");

        if (hasCode) {
            cognito.parseCognitoWebResponse(window.location.href);
        } else if (!cognito.isUserSignedIn()) {
            cognito.getSession(); // this may redirect to an external URL
        } else {
            cognito.userhandler.onSuccess(cognito.getSignInUserSession());
        }

        return sessionPromise;
    }

    private setTokens(authSession: CognitoAuthSession): void {
        this.accessToken = authSession.getIdToken().getJwtToken();
        // The expiration is given in seconds, but Date requires time in milliseconds
        this.accessTokenExp = new Date(authSession.getIdToken().getExpiration() * 1000);
        this.refreshToken = authSession.getRefreshToken().getToken();
    }

    public getAccessToken(): Promise<string> {
        const now = new Date();

        // If later than one minute before expiration time, get a new access token.
        // There is a contrived race condition if the network request takes more than a minute
        // to get from here to our backend auth check that would cause the request to be return as unauthorized,
        // but I think this is good enough for now
        if (this.accessTokenExp.getTime() - now.getTime() < 1000 * 60 /* one minute in milliseconds */) {
            const { cognito, sessionPromise } = this.setupAuth();

            cognito.refreshSession(this.refreshToken);

            return sessionPromise.then((authSession): string => {
                this.setTokens(authSession);

                return this.accessToken;
            });
        }

        return Promise.resolve(this.accessToken);
    }

    public fetchUser(): Promise<User> {
        return this.getAuthSession().then((authSession): User => {
            this.setTokens(authSession);
            return extractUser(authSession.getIdToken());
        });
    }
}

function extractUser(idToken: CognitoIdToken): User {
    const claims: any = idToken.decodePayload();
    const identities = claims.identities;
    let userId = "";
    if (identities.length > 0) {
        userId = identities[0].userId;
    }

    // These two should always be present but adding some safety logic just in case
    const firstName = claims?.given_name ?? "";
    const lastName = claims?.family_name ?? userId;

    if (firstName == "") {
        console.error(`Cognito Config Error: Token missing first and last name: ${claims}`);
    }

    return {
        email: claims.email,
        id: userId,
        firstName,
        lastName,
    };
}
