import { SelectProps } from "@amzn/awsui-components-react-v3";
import { ANVIL_OPTION, defaultAnvilOptions } from "src/components/survey/ApplicationPermissions/utils";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from "src/util/usePrevious";
import { AnvilIdInputProps } from "src/components/survey/ApplicationPermissions/AnvilIdInput";
import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";

export interface AnvilUpdatedResult {
    options: SelectProps.Option[];
    selectedOption: OptionDefinition;
    setSelectedOption: (value: ((prevState: OptionDefinition) => OptionDefinition) | OptionDefinition) => void;
    isCustom: boolean;
    customValue: string;
    setCustomValue: (value: ((prevState: string) => string) | string) => void;
}

//TODO - let's design / replace most of this functionality.  By incorporating the option set into the anvil response,
// we can simplify the flow drastically.
// idea:
//      GET /anvil/aptions {currentID:"12345"} =>
//      {values:[{"na","12345","other"}],selected:12345} ( or some equivalent)

/**
 * takes the input from anvil response, splices in the "N/A" and "Other options",
 * then handles state for the other visibility and the other value clear out.
 */
export const useBindAnvil = (props: AnvilIdInputProps): AnvilUpdatedResult => {
    //state / defaults for all anvil options
    const options: SelectProps.Option[] = props.anvilIds.concat(defaultAnvilOptions);
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option>(options[options.length - 2]);
    const prevSelectedOption = usePrevious<SelectProps.Option>(selectedOption);
    const [customValue, setCustomValue] = useState<string>("");
    const prevCustomValue = usePrevious<string>(customValue);

    const selectedOptionIdRef = useRef<string>(selectedOption.value ?? "");
    selectedOptionIdRef.current = selectedOption.value ?? "";

    useEffect(
        // updates the values based on "n/a", "other", "anvilId from response"
        function bindSelectedAnswer(): void {
            if (!props.expectedAnswer) {
                // if we've changed on a render, and it's notequal to other,
                // but the value was null / empty, it should be "N/A"
                if (selectedOptionIdRef.current !== ANVIL_OPTION.OTHER.value) {
                    setSelectedOption(defaultAnvilOptions[0]);
                }
                return;
            }
            if (props.expectedAnswer !== selectedOptionIdRef.current) {
                const optionsFromResponse = props.anvilIds.filter((option): boolean => {
                    return props.expectedAnswer === option.label;
                });

                if (optionsFromResponse.length === 0) {
                    // The person looking at the form is not allowed retrieve the anvilID
                    // set the value to Custom, and then show the custom input
                    setSelectedOption(defaultAnvilOptions[1]);
                    setCustomValue(props.expectedAnswer);
                } else {
                    setSelectedOption(optionsFromResponse[0]);
                }
            }
        },
        [props.expectedAnswer, props.anvilIds, selectedOptionIdRef]
    );

    const dependencies = {
        prevSelectedOption,
        prevCustomValue,
        onChangeCallback: props.onChangeCallback,
        id: props.id,
    };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    // this is hacky, but it does the job - sorts out whether the state needs
    // to be cleared for custom values based on what the selected option is.
    // with the above recommendation, we can eliminate this method.
    useEffect(
        function rebindOnCustomValueChanged(): void {
            const { prevSelectedOption, prevCustomValue, onChangeCallback, id } = depsRef.current;
            if (prevSelectedOption?.value !== selectedOption.value || prevCustomValue !== customValue) {
                //needs to be undefined because the default case of selectedOption.label can be undefine as well
                let response: string | undefined;
                if (selectedOption.value === ANVIL_OPTION.NA.value) {
                    // since NA is not a real value, set the response to ""
                    response = "";
                } else if (selectedOption.value === ANVIL_OPTION.OTHER.value) {
                    // because we're getting hte value from the textbox instead of the dropdown,
                    // make the anvilId the text input
                    response = customValue;
                } else {
                    //pull the selected input.
                    response = selectedOption.label;
                }
                //if we had a changed value AND the current != previous values, call back to the parent.
                if (response != undefined && prevSelectedOption && prevSelectedOption?.value !== response) {
                    onChangeCallback?.call(null, {
                        id: id,
                        response: response,
                    });
                }
            }
        },
        [selectedOption, customValue]
    );

    const isCustom = selectedOption?.value === ANVIL_OPTION.OTHER.value;

    return { options, selectedOption, setSelectedOption, isCustom, customValue, setCustomValue };
};
