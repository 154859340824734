import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { SurveyContext } from "src/components/survey/SurveyFormModel";
import { BatchDescribeResourcesResult, Bindle, BrandleService, OwningTeam } from "src/services/BrandleService";
import { SelectProps } from "@amzn/awsui-components-react-v3/polaris";

export interface FetchBindlesResponse {
    isLoadingBindles: boolean;
    bindleInfo: BindleInfo;
    controlBindle: SelectProps.Option | null;
    relatedBindles: SelectProps.Option[];
    bindlesError: React.ReactNode;
}

interface BindleInfo {
    bindles: SelectProps.Option[];
    bindleToOwningTeam: KeyValuePairs<OwningTeam>;
}

export const useFetchBindles = (appControlBindle: string[], appRelatedBindles: string[]): FetchBindlesResponse => {
    const kaleContext = useContext(KaleContext);
    const { userId } = kaleContext.user;
    const { brandleService } = kaleContext.service;
    const brandleServiceRef = useRef<BrandleService>(brandleService);
    brandleServiceRef.current = brandleService;
    const surveyContext = useContext(SurveyContext);
    const { setControlBindle: setControlBindleInContext } = surveyContext;

    const [isLoadingBindles, setIsLoadingBindles] = useState<boolean>(false);
    const [bindles, setBindles] = useState<BindleInfo>({ bindles: [], bindleToOwningTeam: {} });
    const [controlBindle, setControlBindle] = useState<SelectProps.Option | null>(null);
    const [relatedBindles, setRelatedBindles] = useState<SelectProps.Option[]>([]);
    const [bindlesError, setBindlesError] = useState<React.ReactNode>(null);

    const fetchBindles = useCallback(
        async (userID: string): Promise<BindleInfo> => {
            const { findBindlesByOwner, fetchBindleDetails } = brandleServiceRef.current;
            let localBindles: SelectProps.Option[] = [];
            let bindleToOwningTeam: KeyValuePairs<OwningTeam> = {};
            setBindlesError(null);
            try {
                const bindleSet: Bindle[] = await findBindlesByOwner(userID, false);

                // Kale DB stores just the Bindles' Names for the bindles selected on the Kale App. For certain
                // processing, like PDC or Lineage, in the frontend, the Bindle IDs and Team Names/IDs are also needed.
                // When we rely on "findBindlesByOwner" with a user alias, only the bindles at the time that the API
                // call is made are returned (where the alias is an owner or member). So, if the user alias has changed
                // teams, the Kale frontend will have the name of the bindle (coming from Kale DB) but not the
                // corresponding bindle ID and team details (supposed to come from the "findBindlesByOwner" call).
                // So, this creates chain reactions for PDC/Lineage and other features like displaying the Submitting
                // team. The below logic mitigates all these issues by fetching the missing details deterministically.
                // Learn More: https://quip-amazon.com/7p2IALlrGbWB/Tactical-Outdated-Respondent-Alias-impacts-on-PDC
                const kaleDBBindleNames: string[] = [...appControlBindle, ...appRelatedBindles];

                const namesOfMissingBindles = kaleDBBindleNames.filter((bindleName): boolean => {
                    const alreadyHasBindle = bindleSet.find((bindle: Bindle): boolean => {
                        return bindle.bindle.name === bindleName;
                    });
                    return !alreadyHasBindle;
                });

                if (namesOfMissingBindles.length > 0) {
                    // Fetch the missing bindles and push them into bindleSet since we know these names are guaranteed
                    // to not be in the bindleSet yet
                    const bindleDetails: BatchDescribeResourcesResult = await fetchBindleDetails(namesOfMissingBindles);
                    bindleSet.push(...bindleDetails.resources);
                }

                localBindles =
                    bindleSet?.map((bindle: Bindle): SelectProps.Option => {
                        const { id, name } = bindle.bindle;
                        return { value: id, label: name };
                    }) ?? [];
                bindleToOwningTeam =
                    bindleSet?.reduce((obj: KeyValuePairs<OwningTeam>, bindle: Bindle): KeyValuePairs<OwningTeam> => {
                        return { ...obj, [bindle.bindle.name]: bindle.owningTeam };
                    }, {}) ?? {};
            } catch (err) {
                console.log(err);
                setBindlesError((err as Error).message);
            }
            return Promise.resolve({ bindles: localBindles, bindleToOwningTeam });
        },
        [appControlBindle, appRelatedBindles]
    );

    useEffect((): void => {
        setIsLoadingBindles(true);
        fetchBindles(userId).then((bindleInfo): void => {
            if (bindleInfo.bindles.length > 0) {
                setBindles(bindleInfo);
            }
            setIsLoadingBindles(false);
        });
    }, [fetchBindles, userId]);

    useEffect((): void => {
        if (bindles.bindles.length > 0) {
            const relatedBindles = appRelatedBindles
                .map((label: string): SelectProps.Option | undefined =>
                    bindles.bindles.find((bindle): boolean => bindle.label === label)
                )
                .filter((bindle): boolean => !!bindle) as SelectProps.Option[];
            const controlBindle = bindles.bindles.find(
                (bindle): boolean => bindle.label === appControlBindle?.[0]
            ) as SelectProps.Option | null;
            setControlBindleInContext(controlBindle ?? null);
            setControlBindle(controlBindle ?? null);
            setRelatedBindles(relatedBindles);
        }
    }, [appControlBindle, appRelatedBindles, setControlBindleInContext, bindles.bindles]);

    return { isLoadingBindles, bindleInfo: bindles, controlBindle, relatedBindles, bindlesError };
};
