import { BreadcrumbGroup, BreadcrumbGroupProps } from "@amzn/awsui-components-react-v3";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

export type KaleBreadcrumbGroupProps = Omit<BreadcrumbGroupProps, "onFollow">;
/**
 * A component which wraps the Polaris 3 BreadcrumbGroup component and supplies our own custom
 * onFollow handler to prevent default browser navigation and use react router navigation instead.
 */
const KaleBreadcrumbGroup = React.memo((props: KaleBreadcrumbGroupProps): JSX.Element => {
    const history = useHistory();

    const onFollow: BreadcrumbGroupProps["onFollow"] = useCallback(
        (e): void => {
            e.preventDefault();
            history.push(e.detail.href);
        },
        [history]
    );

    return <BreadcrumbGroup {...props} onFollow={onFollow} />;
});

export default KaleBreadcrumbGroup;
