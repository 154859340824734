export const TEST_IDS = {
    TAF_DETAILS_PAGE: {
        ROOT: "taf-details-page--root",
        LOADING_SPINNER: "taf-details-page--loading-spinner",
        LOADED_CONTENT: "taf-details-page--content",
    },
    FINANCIAL_GRE: {
        ROOT: "financial-gre--root",
        MAIN_CONTENT: "financial-gre--main-content",
    },
    PREDICTIONS_TABLE: {
        TABLE: "lineage-predictions-table",
    },
};
