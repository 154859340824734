import { Button, SpaceBetween, ButtonProps } from "@amzn/awsui-components-react-v3";
import { default as React, useContext, useState } from "react";
import { Can, UserAction } from "src/permissions";
import {
    Approve,
    DeleteApplication,
    Recall,
    Reject,
    SaveApplication,
    SubmitApplication,
} from "src/components/survey/hooks/useApplicationServiceCallbacks";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import {
    ApplicationStatus,
    selectLegalApprovalStatus,
    SurveyContext,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import styled from "styled-components";
import { APPROVE_NOTICE, SUBMIT_NOTICE } from "src/components/survey/LegalSurveyActionButtons/constants";
import { ConfirmActionModalButton } from "src/components/ConfirmActions";
import { TEST_IDS } from "shared/survey";
import { DeleteNotice } from "src/components/survey/LegalSurveyActionButtons/DeleteNotice";
import { RecallNotice } from "src/components/survey/LegalSurveyActionButtons/RecallNotice";
import safeHTMLReactParser from "src/util/safeHTMLReactParser";

const { FORM_ACTIONS } = TEST_IDS;

const ActionsContainer = styled(SpaceBetween)`
    margin-top: 30px;
`;

interface Props {
    displayMessage: DisplayMessageCb;
    application: SurveyResponse;
    submit: SubmitApplication;
    save: SaveApplication;
    deleteApp: DeleteApplication;
    approve: Approve;
    onShowErrorCb: () => void;
    recall: Recall;
    reject: Reject;
    onSubmitConfirmed: () => void;
}

export const LegalSurveyActionButtons = (props: Props): JSX.Element => {
    const { displayMessage, application, save, submit, deleteApp, approve, recall, reject, onShowErrorCb } = props;
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isApproveLoading, setIsApproveLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isRecallLoading, setIsRecallLoading] = useState(false);
    const { saveStatus, submitStatus } = useContext(SurveyContext).validation;
    const privacyStatus = selectLegalApprovalStatus(application.appInfo)?.status || ApplicationStatus.inProgress;

    const onDeleteClick = async (event: CustomEvent<ButtonProps.ClickDetail>): Promise<void> => {
        setIsDeleteLoading(true);
        event.preventDefault();

        await deleteApp(application.appInfo.applicationName).finally((): void => {
            setIsDeleteLoading(false);
        });
    };

    const onSaveClick = async (event: CustomEvent<ButtonProps.ClickDetail>): Promise<void> => {
        event.preventDefault();
        onShowErrorCb();
        if (!saveStatus.isValid) {
            displayFormValidationMessage(saveStatus.errorMessage);
            return;
        }
        await save(application, null);
    };

    const onSubmitClick = async (event: CustomEvent<ButtonProps.ClickDetail>): Promise<void> => {
        setIsSubmitLoading(true);
        event.preventDefault();
        onShowErrorCb();
        if (!submitStatus.isValid) {
            displayFormValidationMessage(submitStatus.errorMessage);
            setIsSubmitLoading(false);
            return;
        }
        return submit(application)
            .then((): void => props.onSubmitConfirmed())
            .catch((error) => console.error(error))
            .finally((): void => {
                setIsSubmitLoading(false);
            });
    };

    const onApproveClick = async (event: CustomEvent<ButtonProps.ClickDetail>): Promise<void> => {
        setIsApproveLoading(true);
        event.preventDefault();
        await approve(application.appInfo.applicationName);
        setIsApproveLoading(false);
    };

    const onRejectClick = async (event: CustomEvent<ButtonProps.ClickDetail>): Promise<void> => {
        event.preventDefault();
        await reject(application);
    };

    const onRecallClick = async () => {
        setIsRecallLoading(true);
        await recall();
        setIsRecallLoading(false);
    };

    const displayFormValidationMessage = (message: string): void => {
        displayMessage(MessageType.error, safeHTMLReactParser(message));
    };

    const saveEditButton = (
        <Button id={"saveButton"} variant="normal" onClick={onSaveClick}>
            Save Draft
        </Button>
    );

    return (
        <React.Fragment>
            <ActionsContainer direction={"horizontal"} size={"xs"}>
                <Can
                    perform={UserAction.deleteApplication}
                    yes={(): JSX.Element => {
                        return (
                            <ConfirmActionModalButton
                                actionLabel={"Delete"}
                                loading={isDeleteLoading}
                                triggerButtonOverrides={{
                                    variant: "normal",
                                }}
                                modalProps={{
                                    header: "Delete application",
                                    onConfirm: onDeleteClick,
                                    content: <DeleteNotice appName={props.application.appInfo.applicationName} />,
                                }}
                                testIds={{
                                    triggerButton: FORM_ACTIONS.DELETE.MODAL_TRIGGER_BUTTON,
                                    cancelButton: FORM_ACTIONS.DELETE.MODAL_CANCEL_BUTTON,
                                    confirmButton: FORM_ACTIONS.DELETE.MODAL_CONFIRM_BUTTON,
                                    modalRoot: FORM_ACTIONS.DELETE.MODAL_ROOT,
                                }}
                            />
                        );
                    }}
                />
                <Can perform={UserAction.editApplication} yes={(): JSX.Element => saveEditButton} />
                <Can
                    perform={UserAction.submitApplication}
                    yes={(): JSX.Element => {
                        return (
                            <ConfirmActionModalButton
                                testIds={{
                                    triggerButton: FORM_ACTIONS.SUBMIT.MODAL_TRIGGER_BUTTON,
                                    cancelButton: FORM_ACTIONS.SUBMIT.MODAL_CANCEL_BUTTON,
                                    confirmButton: FORM_ACTIONS.SUBMIT.MODAL_CONFIRM_BUTTON,
                                    modalRoot: FORM_ACTIONS.SUBMIT.MODAL_ROOT,
                                }}
                                actionLabel={"Submit for Privacy Review"}
                                loading={isSubmitLoading}
                                modalProps={{
                                    header: "Submit for Privacy Review",
                                    content: SUBMIT_NOTICE,
                                    onConfirm: onSubmitClick,
                                }}
                            />
                        );
                    }}
                />
                <Can
                    perform={UserAction.recallApplication}
                    yes={(): JSX.Element => {
                        return (
                            <ConfirmActionModalButton
                                testIds={{
                                    triggerButton: FORM_ACTIONS.RECALL.MODAL_TRIGGER_BUTTON,
                                    cancelButton: FORM_ACTIONS.RECALL.MODAL_CANCEL_BUTTON,
                                    confirmButton: FORM_ACTIONS.RECALL.MODAL_CONFIRM_BUTTON,
                                    modalRoot: FORM_ACTIONS.RECALL.MODAL_ROOT,
                                }}
                                loading={isRecallLoading}
                                actionLabel={"Recall Your Application"}
                                modalProps={{
                                    header: "Recall Your Application",
                                    content: <RecallNotice privacyStatus={privacyStatus} />,
                                    onConfirm: onRecallClick,
                                }}
                            />
                        );
                    }}
                />
                <Can
                    perform={UserAction.approveApplication}
                    yes={(): JSX.Element => (
                        <ConfirmActionModalButton
                            testIds={{
                                triggerButton: FORM_ACTIONS.APPROVE.MODAL_TRIGGER_BUTTON,
                                cancelButton: FORM_ACTIONS.APPROVE.MODAL_CANCEL_BUTTON,
                                confirmButton: FORM_ACTIONS.APPROVE.MODAL_CONFIRM_BUTTON,
                                modalRoot: FORM_ACTIONS.APPROVE.MODAL_ROOT,
                            }}
                            actionLabel={"Approve"}
                            modalProps={{
                                header: "You are about to submit your approval",
                                content: APPROVE_NOTICE,
                                onConfirm: onApproveClick,
                            }}
                            loading={isApproveLoading}
                        />
                    )}
                />
                <Can
                    perform={UserAction.rejectApplication}
                    yes={(): JSX.Element => (
                        <Button id={"rejectButton"} onClick={onRejectClick}>
                            Reject
                        </Button>
                    )}
                />
            </ActionsContainer>
        </React.Fragment>
    );
};
