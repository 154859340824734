import * as React from "react";
import { useCallback, useRef, useState } from "react";
import {
    Button,
    FormField,
    SelectProps,
    SpaceBetween,
    Input,
    InputProps,
    NonCancelableCustomEvent,
    Grid,
    TokenGroupProps,
} from "@amzn/awsui-components-react-v3/polaris";
import { FunctionalSurveyItemProps, FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { Tags } from "src/components/fields/Tags";
import NewDropdown from "src/components/fields/dropdown/NewDropdown";
import { TEST_IDS } from "shared/fields";
import { QuestionOption } from "src/services/dynamic-questions";

interface DropdownWithTagsProps extends FunctionalSurveyItemProps<string[]> {
    readonly options: QuestionOption[];
    readonly filteringType?: SelectProps["filteringType"];
    readonly allowOther?: boolean;
    readonly otherOption?: string;
    readonly showErrors?: boolean;
}

export const OTHER_OPTION = "Other";

export const DropdownWithTags = (props: DropdownWithTagsProps): JSX.Element => {
    const {
        id,
        isReadonly,
        allowOther = true,
        isFormInReadonlyMode = false,
        otherOption = OTHER_OPTION,
        expectedAnswer = [],
        showErrors = null,
        onChangeCallback,
    } = props;

    const isDisabled = isReadonly ?? isFormInReadonlyMode;

    const [isTextBoxVisible, setIsTextBoxVisible] = useState<boolean>(false);
    const [customValue, setCustomValue] = useState<string>("");

    const deps = { onChangeCallback };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const hasOther: boolean = props.options.some((option: SelectProps.Option): boolean => {
        return option.value === OTHER_OPTION;
    });
    let options = props.options;
    if (allowOther && !hasOther) {
        options = options.concat({ value: OTHER_OPTION, label: otherOption });
    }
    const idDescriptionMap = options.reduce(
        (m: Map<string, string | undefined>, option: SelectProps.Option): Map<string, string | undefined> => {
            if (option.value) {
                m.set(option.value, option.description);
            }
            return m;
        },
        new Map()
    );

    const onValueChange = useCallback(
        (result: FunctionalSurveyItemResult<string>): void => {
            const { onChangeCallback } = depsRef.current;
            if (result.response === OTHER_OPTION) {
                setIsTextBoxVisible(true);
            } else if (!expectedAnswer.includes(result.response)) {
                onChangeCallback({
                    id: id,
                    response: [...expectedAnswer, result.response],
                });
                setCustomValue("");
                setIsTextBoxVisible(false);
            }
        },
        [id, expectedAnswer]
    );

    const handleOnAdd = useCallback((): void => {
        const { onChangeCallback } = depsRef.current;
        if (customValue !== "" && !expectedAnswer.includes(customValue)) {
            onChangeCallback({
                id: id,
                response: [...expectedAnswer, customValue],
            });
        }
        setCustomValue("");
        setIsTextBoxVisible(false);
    }, [customValue, expectedAnswer, id]);

    const handleOnRemove = useCallback(
        (items: string[]): void => {
            const { onChangeCallback } = depsRef.current;
            onChangeCallback({
                id: id,
                response: items,
            });
        },
        [id]
    );

    const onCustomValueChange = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setCustomValue(event.detail.value);
    }, []);

    // Show error only when last updated timestamp is set. Backwards compatible.
    const hasChanged = showErrors === null || showErrors;

    return (
        <FormField
            data-testid={TEST_IDS.DROPDOWN_WITH_TAGS.FORMFIELD}
            stretch={true}
            errorText={hasChanged && props.isRequired && !Boolean(props.expectedAnswer?.length) ? "Required" : ""}
        >
            <Grid gridDefinition={[{ colspan: 6 }]}>
                <SpaceBetween direction={"vertical"} size={"m"}>
                    <NewDropdown
                        id={props.id}
                        filteringType={props.filteringType}
                        options={options}
                        isReadonly={isReadonly}
                        placeholder={"Choose a tag"}
                        onChangeCallback={onValueChange}
                    />
                    {isTextBoxVisible && (
                        <SpaceBetween size={"s"} direction={"horizontal"}>
                            <Input
                                value={customValue}
                                onChange={onCustomValueChange}
                                placeholder={"Add a custom tag"}
                            />
                            <Button
                                data-testid={TEST_IDS.DROPDOWN_WITH_TAGS.BUTTON}
                                variant={"normal"}
                                disabled={isDisabled}
                                onClick={handleOnAdd}
                            >
                                Add
                            </Button>
                        </SpaceBetween>
                    )}
                    <Tags
                        id={"data-storage-tags"}
                        items={expectedAnswer.map(
                            (item: string): TokenGroupProps.Item => ({
                                label: item,
                                disabled: isDisabled,
                                description: idDescriptionMap.get(item),
                            })
                        )}
                        onRemoveCallback={handleOnRemove}
                    />
                </SpaceBetween>
            </Grid>
        </FormField>
    );
};

export const DropdownWithTagsFunctionalReadonly = withFunctionalReadonly(DropdownWithTags);
