import {
    SerializedAnswerViewModel,
    SerializedIncrementalSaveRequest,
    SerializedIncrementalSaveResponse,
    DisplayType,
    Field,
    IncrementalSaveInput,
    IncrementalSaveResponse,
    SerializedValueDictionary,
    ValueDictionary,
    SingleInputValue,
} from "src/services/IncrementalSave/types";
import {
    DEFAULT_INCREMENTAL_SAVE_REVIEW_ID_BODY,
    UNSUPPORTED_DISPLAY_TYPE,
    VALUE_DICTIONARY_NULL_VALUE_WITH_NO_FIELD,
} from "src/services/IncrementalSave/constants";

/**
 * The IncrementalSave utils houses the contract of deserializing and serializing the expected type values between
 * the frontend and the backend. In order to understand the full scope, please read the quip:
 * https://quip-amazon.com/aci2AoZbKvJh/Typing-Contract-Between-Frontend-Backend
 * */

export const serializeIncrementalSaveRequest = (input: IncrementalSaveInput): SerializedIncrementalSaveRequest => {
    const valueDictionary = serializeDictionary(input.valueDictionary);
    return {
        appName: input.appName ?? "",
        reviewID: input.reviewID ?? DEFAULT_INCREMENTAL_SAVE_REVIEW_ID_BODY,
        answers: Object.entries(valueDictionary).map(([key, value]): SerializedAnswerViewModel => {
            return {
                key,
                entityId: input.reviewID ?? DEFAULT_INCREMENTAL_SAVE_REVIEW_ID_BODY,
                value: value ?? null,
            };
        }),
    };
};

export const deserializeIncrementalSaveResponse = (
    backendModel: SerializedIncrementalSaveResponse
): IncrementalSaveResponse => {
    return {
        valueDictionary: deserializeDictionary(backendModel),
        fields: backendModel.fields.map((field): Field => {
            return {
                ...field,
                displayOptions: JSON.parse(field.displayOptions),
            };
        }),
    };
};

/**
 * Converts all values of the ValueDictionary to string/json so that the IncrementalSave endpoint can be called to save.
 * This is required to be used prior to calling the "save-facade" endpoint because the purpose of this method
 * is to remove all responsibility from the FE service code of converting every new value to JSON/string.
 * This has a contract and assumes that all FE ValueDictionary values are string|string[] and never null.
 */
export const serializeDictionary = (valueDict: ValueDictionary): SerializedValueDictionary => {
    const serializedDict: SerializedValueDictionary = {};
    for (const [key, value] of Object.entries(valueDict)) {
        // only if the value is neither an empty string nor empty array
        if (value && value.length > 0) {
            serializedDict[key] = Array.isArray(value) ? JSON.stringify(value) : value;
        } else {
            serializedDict[key] = null;
        }
    }
    return serializedDict;
};

/*
 * Converts the IncrementalSave response from the backend to a ValueDictionary to be used throughout the frontend.
 * If it's coming from the backend, then all dictionary values are strings because of serialization.
 */
export const deserializeDictionary = (backendModel: SerializedIncrementalSaveResponse): ValueDictionary => {
    const serializedDict: SerializedValueDictionary = Object.assign({}, backendModel.valueDictionary);
    const valueDict: ValueDictionary = {};
    backendModel.fields.map((field): void => {
        const value = serializedDict[field.key];
        if (value === null) {
            // null value indicates an empty answer.
            valueDict[field.key] = deserializeNullToEmpty(field.displayType);
        } else {
            // value represents string or string[]
            valueDict[field.key] = isValidJson(value) ? JSON.parse(value) : value;
        }

        // If the dictionary pair has already been found, then remove it from the serialized dict copy to prevent
        // duplicate analysis on the rest of the keys without field pairings
        delete serializedDict[field.key];
    });

    /** For the rest of the data dictionary pairs, we are going to assign the values as their deserialized value
     * as string or string array if present. As of right now, here we only expect that the values are not null,
     * so rework is needed to account for that if dictionary pairs without fields contains null are supported.
     * TODO: rework to figure out how to handle dictionary pair values that are null
     */
    for (const [key, value] of Object.entries(serializedDict)) {
        if (value) {
            valueDict[key] = isValidJson(value) ? JSON.parse(value) : value;
        } else {
            throw Error(VALUE_DICTIONARY_NULL_VALUE_WITH_NO_FIELD);
        }
    }
    return valueDict;
};

export const getReviewIdFromValueDictionary = (valueDict: ValueDictionary): string => {
    const reviewID = valueDict["Review-d-ReviewId"];
    if (reviewID) {
        return typeof reviewID === "string" ? reviewID : reviewID.toString();
    }
    return DEFAULT_INCREMENTAL_SAVE_REVIEW_ID_BODY;
};

// Used during deserialization to determine if a non-empty value is an array or string to assign to ValueDictionary.
export const isValidJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

/**
 * Used during deserialization to remove the responsibility of the frontend handling null values by converting to either
 * empty string or empty array using the display types.
 */
export const deserializeNullToEmpty = (displayType: DisplayType | string): string | string[] => {
    switch (displayType) {
        case DisplayType.text:
        case DisplayType.textArea:
        case DisplayType.autoSuggest:
        case DisplayType.checkbox:
        case DisplayType.radiogroup:
        case DisplayType.singleSelect:
        case DisplayType.date:
            return "";
        case DisplayType.multiselect:
        case DisplayType.textTags:
        case DisplayType.checkboxGroup:
            return [];
        default:
            throw Error(UNSUPPORTED_DISPLAY_TYPE);
    }
};

/**
 * Get base YYYY-MM-DD format to align with incremental save API param requirements.
 * (FYI, return format includes the complete ISO-8601 value e.g. 2023-10-23T00:00:00.000Z )
 */
export const formatIncrementalSaveDate = (date = new Date()): SingleInputValue =>
    new Date(date).toISOString().split("T")[0];
