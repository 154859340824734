import { Route, RouteComponentProps, Switch } from "react-router";
import TableDetailsPage from "src/components/TableDetails/TableDetailsPage";
import AndesImporter from "src/components/schema_table/importers/andes_importer/AndesImporter";
import BumblebeeImporter from "src/components/schema_table/importers/bumblebee_importer/BumblebeeImporter";
import SchemaImporter from "src/components/schema_table/importers/schema_importer/SchemaImporter";
import TAFDetailsPage from "src/components/TAF/TAFDetails/TAFDetailsPage";
import { CampaignSurveyForm } from "src/components/survey/campaign/CampaignSurveyForm";
import { LegalSurveyPage } from "src/components/survey/LegalSurveyPage";
import React from "react";
import PageNotFound from "src/components/survey/PageNotFound";
import { KaleRoutesProps } from "src/components/survey/KaleRoutes";
import { CSVJobsActivityPage } from "src/components/survey/CSVJobsActivityPage";
import AuditHistory from "src/components/survey/AuditHistory";

export interface KaleApplicationRouteParams {
    applicationName: string;
    reviewId: string;
}
export type KaleApplicationRouterProps = RouteComponentProps<KaleApplicationRouteParams>;
export type KaleApplicationRoutesProps = KaleRoutesProps & KaleApplicationRouterProps;

/**
 * Component responsible for rendering all routes in Kale that referring to an individual application.
 * @param props.match.path: the part of the url matching the Kale application pattern:
 *      "/:applicationName/reviews/:reviewId/edit"
 */
const KaleApplicationRoutes = ({ match: { path }, ...props }: KaleApplicationRoutesProps): JSX.Element => {
    return (
        <Switch>
            <Route
                path={`${path}/datastore/:dataStoreId/table/:tableId`}
                render={(): JSX.Element => {
                    return <TableDetailsPage {...props} />;
                }}
            />
            <Route
                path={`${path}/datastore/:dataStoreId/import/andes`}
                render={(): JSX.Element => <AndesImporter {...props} />}
            />
            <Route
                path={`${path}/datastore/:dataStoreId/import/bumblebee`}
                render={(): JSX.Element => <BumblebeeImporter {...props} />}
            />
            <Route
                path={`${path}/datastore/:dataStoreId/import/schemas`}
                render={(): JSX.Element => <SchemaImporter {...props} />}
            />
            <Route path={`${path}/csv/jobs_activity`} render={(): JSX.Element => <CSVJobsActivityPage />} />
            <Route path={`${path}/TAF`} render={(): JSX.Element => <TAFDetailsPage {...props} />} />
            <Route
                path={`${path}/campaign/:campaignType`}
                render={(): JSX.Element => <CampaignSurveyForm {...props} />}
            />
            <Route path={`${path}/audit-history`} render={(): JSX.Element => <AuditHistory />} />
            <Route
                exact
                path={`${path}`}
                render={({ match }: KaleApplicationRouterProps): JSX.Element => {
                    // Set a key to force LegalSurveyPage to remount whenever we switch from the
                    // `/new` path to the  `/:name/reviews/:id/edit` path and vice versa after
                    // the user saves their application for the first time. This will make sure
                    // that all initialization behaviors are rerun correctly and deterministically
                    return <LegalSurveyPage key={match.url} {...props} />;
                }}
            />
            <Route render={(): JSX.Element => <PageNotFound />} />
        </Switch>
    );
};

export default KaleApplicationRoutes;
