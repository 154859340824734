export const TEST_IDS = {
    WIZARD: {
        ROOT: "personal-data-wizard",
        NEXT_BUTTON: "inline-wizard-next-button",
        CONFIRM_AND_SUBMIT_BUTTON: "inline-wizard-submit-button",
        LINEAGE_STEP: {
            LINEAGE_TABLE: "pdw--lineage-table",
        },
        PDC_STEP: {
            CONTENT_ROOT: "pwd--pdc-step-content-root",
            PDC_TABLE: {
                ROOT: "pwd--pdc-table-root",
                ENTITY_TYPE: {
                    ROOT: "pwd--pdc-table--entity-type-root",
                    LABEL: "pwd--pdc-table--entity-type--label",
                },
            },
        },
        PERSONAL_DATA_STEP: {
            CONTENT_ROOT: "pdw--personal-data-step--content-root",
            STATEMENT_FORM_FIELD: "pdw--personal-data-step--statement-form-field",
        },
        SUMMARY_STEP: {
            RESULT: "pdw--summary--result",
            PERSONAL_DATA_QUESTION_EDIT_BUTTON: "pdw--summary--pdq-edit-button",
            ANALYZER_SUMMARIES: {
                FINDINGS_COUNT: "pdw--summary--analyzer-summary--findings-count",
                PDC_FINDINGS: "pdw--summary--analyzer-summary--pdc-findings",
                LINEAGE_FINDINGS: "pdw--summary--analyzer-summary--lineage-findings",
            },
        },
        PDC_WARNING_MODAL: {
            ROOT: "pdw--pdc-warning-modal--root",
            CONTINUE_BUTTON: "pwd--pdc-warning-modal--continue-button",
            GO_BACK_BUTTON: "pwd--pdc-warning-modal--go-back-button",
        },
    },
};
