import { FormField, Textarea } from "@amzn/awsui-components-react-v3";
import React, { useState } from "react";
import { BaseInputProps } from "@amzn/awsui-components-react-v3/uxdg/input/interfaces";

interface DataStoreInputProps extends Omit<BaseInputProps, "onBlur"> {
    onBlur: (newValue: string) => void;
    value: string;
    disabled?: boolean;
}

const DataStoreDescription = (props: DataStoreInputProps): JSX.Element => {
    const { value: initialValue, disabled = false, onBlur: onBlurCb, ...rest } = props;

    // The current local value of the data store instance name input.
    // The data store instance input updates parent components with its local value during its Blur event.
    const [localDataStoreDescriptionText, setLocalDataStoreDescriptionText] = useState<string>(initialValue);

    return (
        <FormField id={"data-store-description-label"} label={"Description"}>
            <Textarea
                {...rest}
                value={localDataStoreDescriptionText}
                onBlur={(): void => onBlurCb(localDataStoreDescriptionText)}
                disabled={disabled}
                onChange={(e): void => {
                    setLocalDataStoreDescriptionText(e.detail.value);
                }}
            />
        </FormField>
    );
};

export default DataStoreDescription;
