import { AutosuggestProps } from "@amzn/awsui-components-react-v3";
import { HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";

export interface OptionModel {
    name: string;
    displayName?: string;
    tooltip?: string;
    dateText?: string;
    description?: string;
    links?: string[];
    autoSuggestOptions?: AutosuggestProps.Option[];
    autoSuggestData?: AutoSuggestData;
}

export interface AutoSuggestData {
    label: string;
    placeholder: string;
}

interface QuestionColumn {
    columnTitle?: string;
}

export interface QuestionModel extends QuestionColumn {
    name: string;
    question: string;
    description: string;
    options: OptionModel[];
    templateValues: string[];
}

const popularDataStoreTechnologies: string[] = [
    "Andes",
    "Athena",
    "Aurora",
    "DynamoDB",
    "RDS",
    "Redshift",
    "S3",
    "Sable",
];

const otherDataStoreTechnologies: string[] = [
    "Alexandria",
    "Apache Cassandra Service",
    "Beejak",
    "Clickstream",
    "CloudDirectory",
    "CloudWatch Logs",
    "ColdTier",
    "DataWave",
    "DocumentDB",
    "EC2 Instance (Run-Your-Own)",
    "ECS (Run-Your-Own)",
    "EDX",
    "ElastiCache",
    "Elasticsearch",
    "Embu",
    "EMR",
    "Fargate (Run-Your-Own)",
    "G2S2",
    "Glacier",
    "Hoverboard",
    "IPDocumentDataStorageService",
    "Kinesis",
    "Managed Blockchain",
    "MatrixStorage",
    "Neptune",
    "ORCA",
    "Platform Search",
    "PPDataService",
    "QLDB",
    "Safe Dynamic Config",
    "Sembu",
    "SimpleDB",
    "SQS",
    "Timber",
    "Timestream",
    "Tokenator",
    "Tortuga",
    "Whispersync",
];

export default class SurveyConstants {
    public static readonly data: any = {
        // Any change in ID below this should be in sync with backend.
        // Currently backend is not directly processing these ID's but it might in future.

        applicationPermissionsFields: ["aaaIdentifiers", "relatedBindles", "definedExplanation"],
        appValidationFieldForDraft: ["applicationName", "controlBindle", "contactList"],
        appValidationFieldForSubmit: [
            "applicationName",
            "applicationDescription",
            "controlBindle",
            "respondentAlias",
            "contactList",
            "legalPocAlias",
            "hasPersonalData",
            "willingToProvideInfo",
            "isFoundationalApp",
        ],
        dataStoreTechnologies: [...popularDataStoreTechnologies, ...otherDataStoreTechnologies],
        createdDate: {
            name: "Created On",
            question: "",
        },
        lastUpdateDate: {
            name: "Last Modified",
            question: "",
        },
        applicationName: {
            name: "Application Name",
            question:
                "Enter a unique name to identify your application. You should use the Anvil application name, " +
                "if one exists. This&nbsp;<bold>cannot</bold>&nbsp;be changed afterwards.",
        },
        isConfidential: {
            name: "Confidential",
            question: "Is this application associated with a confidential project?",
            description:
                "Confidential projects will be redacted when they appear in Kale Analytics and other\n" +
                "extracts generated from this data.",
        },
        isFoundationalApp: {
            question: "Are you a Foundational Application?",
            description:
                "A foundational application is a data warehouse, data lake, BI store, or other service that does " +
                "not own or create all of the data they store. These applications typically consume more than 1000 " +
                "fields and may not hold data in a tabular format. They may also be referred to as Data Aggregators",
        },
        surveyRespondentAlias: {
            name: "Survey respondent alias",
            question: "Your alias",
        },
        applicationDescription: {
            name: "Application Description",
            question:
                "Describe the application's business use and context. How would you explain the business purpose " +
                "of the application to a reviewer who has no background on it?",
            example: "Here's an example!",
        },
        respondentAlias: {
            name: "Survey respondent alias",
            question: "Your alias",
        },
        contactList: {
            name: "Contact List",
            question: "Contacts for this application, the Control Bindle owner will always be a contact by default",
        },
        taskId: {
            name: "Anvil Task Id",
            question: "",
        },
        applicationOwner: {
            name: "Anvil Application Owner",
            question: "",
        },
        controlBindle: {
            name: "Access Control Bindle",
            question:
                "Choose the Bindle that will control access to this Kale application. " +
                "Go to the Bindles&nbsp;<link>site</link>&nbsp;to create a new Bindle.",
            templateValues: ["https://bindles.amazon.com/"],
        },
        relatedBindles: {
            name: "Related Bindle(s)",
            question: "Identify all Bindle(s) that are covered by this application.",
        },
        legalPocAlias: {
            name: "Legal POC alias",
            question:
                "If you are not sure who your lawyer is, please contact your manager to identify the " +
                "appropriate legal POC.",
            placeholder: "Type an alias here (do not include @amazon.com)",
        },
        anvilId: {
            name: "Anvil Application ID",
            question:
                "<link>Anvil Application</link> ID (ID is contained with the Anvil Application URL, " +
                "e.g. - https://anvil.amazon.com/applications/<bold>90130</bold>). If you have come from ASR, " +
                "or do not otherwise have an Anvil requirement, please select N/A",
            links: ["https://anvil.amazon.com/applications"],
        },
        aaaIdentifiers: {
            name: "AAA Application Ids",
            question: "<link>AAA Application</link>&nbsp;Id(s) related to your application",
            templateValues: ["https://aaa.amazon.com/dashboard"],
        },
        definedExplanation: {
            name: "Explanation",
            question:
                "If you are unable to provide Anvil ID, AAA Application Id(s) or Bindle(s) for your application, " +
                "please explain how your application is defined, and how permissions and access controls are enforced:",
        },
        reviewGroup: {
            name: "Reviewer Group Name",
            question:
                "The members of the Reviewer Group are the business-line lawyers and Privacy Bar Raisers who will " +
                "review the application. This group is also an LDAP alias that can be contacted for guidance at any " +
                "point during your privacy review.",
        },
        submittingTeam: {
            name: "Submitting Team",
            question: "",
        },
        approverAlias: {
            name: "Approver Alias",
            question: "",
        },
        approvedOn: {
            name: "Approved On",
            question: "",
        },
        dataStoreHeader: "Data stores in this application",
        dataStoreDescription: "Add all data stores used by your application.",
        TeamTooltip: 'If the team is not listed, select "Other" and specify the team.',
        containsPersonalData: {
            question: "Does this data store contain <link>personal data elements</link>?",
            columnTitle: "Has personal data?",
            options: [
                {
                    name: "Yes",
                },
                {
                    name: "No",
                },
            ],
            templateValues: ["https://w.amazon.com/bin/view/PrivacyEngineering/Product/DER"],
        },
        hasPersonalData: {
            options: [
                {
                    displayName:
                        "Yes, personal data is processed and stored (If you are unsure you should select this option)",
                    name: HasPersonalDataResponse.Store,
                },
                {
                    displayName: "Yes, personal data is processed, but is not stored",
                    name: HasPersonalDataResponse.Process,
                },
                {
                    displayName:
                        "No, I confirm that this application does not process or store any personal " +
                        "data of any kind, and any data that is processed is fully anonymized",
                    name: HasPersonalDataResponse.No,
                },
            ],
        },
        isConfidentialProject: {
            options: [
                { name: "true", displayName: "Yes" },
                { name: "false", displayName: "No" },
            ],
        },
        willingToProvideInfo: {
            question: "Would you be willing to provide information about data stores used by your application?",
            options: [
                {
                    name: "Yes",
                },
                {
                    name: "No",
                },
            ],
        },
        review: {
            question: "Reviewed with Legal and/or Privacy Leads?",
            options: [
                {
                    name: "Yes, this information has been reviewed with our Privacy Lead",
                },
            ],
        },
        deleteCampaignWarning:
            "Even after deletion, your Kale application may continue to be flagged for current and future " +
            "campaigns. Campaign owners determine scope based on their own defined criteria, and questions should " +
            "be directed to those campaign owners to learn more.",
        deleteAnvilWarning:
            "Please also note that deleting your Kale application does NOT delete or deprecate your Anvil application" +
            ". Any changes required for deletion or deprecation in Anvil must go through Anvil directly.",
    };
}
