import CopyToClipboardButton from "src/components/buttons/CopyToClipboardButton";
import { copyToClipboard } from "src/util/clipboard";
import React from "react";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { TEST_IDS } from "shared/survey";

export function appName(model: Model): Pair {
    const onclick = async (): Promise<void> => {
        await copyToClipboard(model.application.applicationName);
    };

    return {
        label: DisplayLabels.AppName,
        value: (
            <CopyToClipboardButton data-testid={"appNameCopy"} successMessage={"copied"} onClick={onclick}>
                <span data-testid={TEST_IDS.APPLICATION_IDENTITY.APPLICATION_NAME}>
                    {model.application.applicationName}
                </span>
            </CopyToClipboardButton>
        ),
    };
}
