import { Box, ColumnLayout, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { ApplicationStatus, ApprovalType } from "src/components/survey/SurveyFormModel";
import React from "react";
import { ApprovalStatusBadge } from "src/components/ApprovalStatuses/ApprovalStatusBadge";

interface ApprovalStatusesProps {
    privacyStatus: ApplicationStatus;
    accountingStatus: ApplicationStatus;
    fraudStatus: ApplicationStatus;
}
const ApprovalStatuses = React.memo(
    ({ privacyStatus, accountingStatus, fraudStatus }: ApprovalStatusesProps): JSX.Element => {
        return (
            <Box margin={{ bottom: "s" }}>
                <ColumnLayout borders="all" columns={3}>
                    <SpaceBetween size="xxxs">
                        <Box margin={{ bottom: "xxxs" }} color="text-label">
                            Privacy Status:
                        </Box>
                        <Box margin={{ bottom: "s" }}>
                            {ApprovalStatusBadge({ status: privacyStatus, type: ApprovalType.PrivacyApproval })}
                        </Box>
                    </SpaceBetween>
                    <SpaceBetween size="xxxs">
                        <Box margin={{ bottom: "xxxs" }} color="text-label">
                            Accounting Status:
                        </Box>
                        <Box margin={{ bottom: "s" }}>
                            {ApprovalStatusBadge({ status: accountingStatus, type: ApprovalType.AccountingApproval })}
                        </Box>
                    </SpaceBetween>
                    <SpaceBetween size="xxxs">
                        <Box margin={{ bottom: "xxxs" }} color="text-label">
                            CTPS Status:
                        </Box>
                        <Box margin={{ bottom: "s" }}>
                            {ApprovalStatusBadge({ status: fraudStatus, type: ApprovalType.FraudApproval })}
                        </Box>
                    </SpaceBetween>
                </ColumnLayout>
            </Box>
        );
    }
);

export default ApprovalStatuses;
