import { Form, SelectProps, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { default as React, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DataStoreAnswer, DataStoreAnswerKey, useDataStoresPayloads } from "src/answers_legacy";
import { DataStoresPayload } from "src/answers_legacy/hooks/dataStores";
import { CampaignWorkflow, UserRole } from "src/permissions";
import { ApplicationInfo } from "src/components/survey/ApplicationInfo/ApplicationInfo";
import { DataStoreInfo, DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useApplicationServiceCallbacks } from "src/components/survey/hooks/useApplicationServiceCallbacks";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";
import { LegalSurveyActionButtons } from "src/components/survey/LegalSurveyActionButtons";
import {
    AppInfoResponse,
    ApplicationStatus,
    HasPersonalDataResponse,
    selectLegalApprovalStatus,
    SurveyConsumer,
    SurveyProvider,
    SurveyResponse,
    UpdateBulkEditPanelCb,
} from "src/components/survey/SurveyFormModel";
import { useApplicationValidation } from "src/components/survey/hooks/useApplicationValidation";
import { KaleQuestion } from "src/services/KaleApplicationService";
import { RecallModal } from "src/components/survey/RecallModal";
import { SubmitModal } from "src/components/survey/Submit/SubmitModal";
import { ApplicationOverview } from "src/components/survey/ApplicationOverview";
import { ApplicationIdentity } from "src/components/survey/ApplicationIdentity";
import { useFetchBindles } from "src/components/survey/hooks/useFetchBindles";
import { ApplicationPermissions } from "src/components/survey/ApplicationPermissions/ApplicationPermissions";
import { PersonalDataWizard } from "src/components/survey/PersonalDataWizard";
import { ProvideInfo } from "src/components/survey/ProvideInfo/ProvideInfo";
import { RoleOverride } from "src/components/survey/RoleOverride";
import { ControlBindleInfoProvider } from "src/components/controlBindleInfoContext";
import { PreSubmitNotice } from "src/components/survey/PreSubmitNotice";
import ReviewQuestions from "src/components/survey/ReviewQuestions";
import { useFetchReviewerGroup } from "src/components/survey/hooks/useFetchReviewerGroup";
import KaleContext from "src/components/KaleContext";
import { AccessControlSection } from "src/components/survey/AccessControl/section/AccessControlSection";
import { PDWStepToIndexMap } from "./PersonalDataWizard/steps";
import { useHistory } from "react-router-dom";
import { ACCESS_CONTROL_DATE_THRESHOLD_MONTHS, shouldShowCampaign } from "src/util/shouldShowCampaign";
import { kaleUrls } from "src/util/Urls";

export const findPayload = (
    payloads: DataStoresPayload[],
    dataStoreId?: number,
    unsavedUUID?: string
): DataStoresPayload | null => {
    return (
        payloads.find((payload): boolean => {
            const savedDataStoreId = payload?.[0]?.dataStoreId;
            const unsavedDataStoreUuid = payload?.[0]?.userDataStoreId;

            return (
                (Boolean(savedDataStoreId) && dataStoreId === savedDataStoreId) ||
                (Boolean(unsavedDataStoreUuid) && unsavedUUID === unsavedDataStoreUuid)
            );
        }) ?? null
    );
};

export const INITIAL_STEP_INDEX = 0;

interface LegalSurveyFormProps {
    application: SurveyResponse;
    setApplication: (application: SurveyResponse) => void;
    questions: KaleQuestion;
    originalDataStores: DataStoreResponse[];
    displayMessage: DisplayMessageCb;
    userRole: UserRole;
    setUserRole: (overrideRole: UserRole) => void;
    setIsSavingApplication: (isSavingApplication: boolean) => void;
    onUpdateBulkEditPanel: UpdateBulkEditPanelCb;
}

export const LegalSurveyForm = ({
    application,
    displayMessage,
    setApplication,
    questions,
    originalDataStores,
    userRole,
    setUserRole,
    setIsSavingApplication,
    onUpdateBulkEditPanel,
}: LegalSurveyFormProps): JSX.Element => {
    const history = useHistory();
    const {
        features: { isAccessControlPageLive },
    } = useContext(KaleContext);
    const dataStoresPayload = useDataStoresPayloads();
    const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(false);
    const [skipValidation, setSkipValidation] = useState<boolean>(false);
    const [levelOfDetailActiveTab, setLevelOfDetailActiveTab] = useState<string>("dataStores");
    const [controlBindle, setControlBindle] = useState<SelectProps.Option | null>(null);
    const hasPersonalDataResponse = application.appInfo.review.hasPersonalData !== HasPersonalDataResponse.Empty;
    const [pdwStepIndex, setPdwStepIndex] = useState<number>(
        hasPersonalDataResponse ? PDWStepToIndexMap.summary : INITIAL_STEP_INDEX
    );
    const [isPDWComplete, setIsPDWComplete] = useState<boolean>(hasPersonalDataResponse);

    const [isSubmitModalVisible, setIsSubmitModalVisible] = useState<boolean>(false);
    const onSubmitConfirmed = useCallback((): void => {
        setIsSubmitModalVisible(true);
    }, []);

    const { save, submit, approve, recall, reject, deleteApp } = useApplicationServiceCallbacks(
        displayMessage,
        application,
        setApplication,
        questions,
        originalDataStores,
        setIsSavingApplication
    );

    const validation = useApplicationValidation(application.appInfo);
    const saveDraft = async (status?: ApplicationStatus): Promise<SurveyResponse> => {
        setShouldShowErrors(true);
        if (validation.saveStatus.isValid) {
            setSkipValidation(true);
            return save(application, status ?? ApplicationStatus.inProgress);
        }
        return Promise.reject(new Error(validation.saveStatus.errorMessage));
    };

    useEffect((): void => {
        const hasPersonalDataResponse = application.appInfo.review.hasPersonalData;
        const willingToProvideInfoResponse = application.appInfo.review.willingToProvideInfo;
        switch (hasPersonalDataResponse) {
            case "Process":
            case "No": {
                if (levelOfDetailActiveTab !== "dataStores" && willingToProvideInfoResponse !== "Yes") {
                    setLevelOfDetailActiveTab("dataStores");
                }
                break;
            }
        }
    }, [
        application.appInfo.review.hasPersonalData,
        application.appInfo.review.willingToProvideInfo,
        levelOfDetailActiveTab,
    ]);

    const legalStatus = selectLegalApprovalStatus(application.appInfo);
    const status = legalStatus.status ?? ApplicationStatus.inProgress;
    const onShowErrorCb = (): void => {
        setShouldShowErrors(true);
    };

    const applicationOverview = useMemo(
        function renderApplicationOverview(): JSX.Element | null {
            return <ApplicationOverview applicationName={application.appInfo.applicationName} />;
        },
        [application.appInfo.applicationName]
    );
    const bindles = useFetchBindles(application.appInfo.controlBindle, application.appInfo.review.relatedBindles);

    // Fetching the current review group. Org changes might cause the review group stored in the DB to go out of sync.
    const { reviewerGroup } = useFetchReviewerGroup(application.appInfo.review.reviewGroup, bindles.controlBindle);
    const deps = { setApplication, application, history, isAccessControlPageLive };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect((): void => {
        const { application, setApplication } = depsRef.current;

        setApplication({
            ...application,
            appInfo: {
                ...application.appInfo,
                review: {
                    ...application.appInfo.review,
                    reviewGroup: reviewerGroup,
                },
            },
        });
    }, [reviewerGroup]);

    useEffect(() => {
        const { application, history, isAccessControlPageLive } = depsRef.current;
        const legalStatus = selectLegalApprovalStatus(application.appInfo).status;
        if (
            isAccessControlPageLive &&
            shouldShowCampaign(CampaignWorkflow.AccessControl, {
                dateShownAt: application.appInfo.review.accessControlShownAt,
                dateThresholdMonths: ACCESS_CONTROL_DATE_THRESHOLD_MONTHS,
                legalStatus,
                userRole,
            })
        ) {
            history.push(
                kaleUrls.editKaleAccessControlRecordUrl(
                    application.appInfo.applicationName,
                    application.appInfo.review.id.toString()
                ),
                { isRedirect: true }
            );
        }
    }, [userRole]);

    const clearDataStore = (): void => {
        setApplication({
            ...application,
            appInfo: {
                ...application.appInfo,
                review: {
                    ...application.appInfo.review,
                    dataStores: [],
                },
            },
        });
    };

    const getDSCallback = (dataStoreResponses: DataStoreResponse[]): void => {
        setApplication({
            ...application,
            appInfo: {
                ...application.appInfo,
                review: {
                    ...application.appInfo.review,
                    dataStores: dataStoreResponses.map((dataStore): DataStoreResponse => {
                        // TODO: Find another way to persist TAF answers
                        const payload = findPayload(dataStoresPayload, dataStore.id, dataStore.unsavedUUID);
                        if (!payload) {
                            return { ...dataStore };
                        }
                        const dataStoreAnswers = payload.map((dataStoreAnswer): DataStoreAnswer => {
                            dataStoreAnswer[DataStoreAnswerKey.dataStoreId] = dataStore.id;
                            return dataStoreAnswer;
                        });

                        return {
                            ...dataStore,
                            dataStoreAnswers,
                        };
                    }),
                },
            },
        });
    };

    function showDataStoreTable(): boolean {
        return (
            isPDWComplete &&
            !(
                application.appInfo.review.hasPersonalData === "No" &&
                application.appInfo.review.willingToProvideInfo === "No" &&
                application.appInfo.review.dataStores.length === 0
            )
        );
    }

    const onChangeCallback = (response: AppInfoResponse): void => {
        setApplication({ ...application, appInfo: response });
    };

    return (
        <SurveyProvider
            value={{
                application,
                role: userRole,
                controlBindle,
                setControlBindle,
                saveDraft,
                validation,
                updateBulkEditPanel: onUpdateBulkEditPanel,
            }}
        >
            <SurveyConsumer>
                {() => (
                    <Form
                        id={"surveyForm"}
                        actions={
                            <LegalSurveyActionButtons
                                application={application}
                                approve={approve}
                                deleteApp={deleteApp}
                                displayMessage={displayMessage}
                                onShowErrorCb={onShowErrorCb}
                                onSubmitConfirmed={onSubmitConfirmed}
                                recall={recall}
                                reject={reject}
                                save={save}
                                submit={submit}
                            />
                        }
                    >
                        <RecallModal application={application} displayMessage={displayMessage} />
                        <SubmitModal
                            application={application}
                            displayMessage={displayMessage}
                            setIsSubmitModalVisible={setIsSubmitModalVisible}
                            isSubmitModalVisible={isSubmitModalVisible}
                        />
                        <SpaceBetween size={"l"}>
                            {applicationOverview}
                            <ApplicationIdentity application={application.appInfo} bindles={bindles} />
                            {isAccessControlPageLive && (
                                <AccessControlSection application={application} shouldShowErrors={shouldShowErrors} />
                            )}
                            <ApplicationInfo
                                shouldShowErrors={shouldShowErrors}
                                skipValidation={skipValidation}
                                response={application.appInfo}
                                bindles={bindles}
                                onChangeCallback={onChangeCallback}
                                onClearDataStore={clearDataStore}
                            />

                            {!isAccessControlPageLive && (
                                <ApplicationPermissions
                                    bindles={bindles}
                                    response={application.appInfo}
                                    shouldShowErrors={shouldShowErrors}
                                    onChangeCallback={onChangeCallback}
                                />
                            )}

                            <PersonalDataWizard
                                pdwStepIndex={pdwStepIndex}
                                setPdwStepIndex={setPdwStepIndex}
                                isPDWComplete={isPDWComplete}
                                response={application.appInfo}
                                bindlesError={bindles.bindlesError}
                                controlBindle={bindles.controlBindle}
                                relatedBindles={bindles.relatedBindles}
                                setIsPDWComplete={setIsPDWComplete}
                                onClearDataStore={clearDataStore}
                                onChangeCallback={onChangeCallback}
                                skipValidation={skipValidation}
                            />
                            <ProvideInfo
                                isPDWComplete={isPDWComplete}
                                response={application.appInfo}
                                onClearDataStore={clearDataStore}
                                onChangeCallback={onChangeCallback}
                                skipValidation={skipValidation}
                                shouldShowErrors={shouldShowErrors}
                            />

                            {isPDWComplete && (
                                <ReviewQuestions
                                    shouldShowErrors={shouldShowErrors}
                                    hasPersonalData={application.appInfo.review.hasPersonalData}
                                />
                            )}

                            {showDataStoreTable() && (
                                <ControlBindleInfoProvider controlBindleID={controlBindle?.value ?? ""}>
                                    <DataStoreInfo
                                        appName={application.appInfo.applicationName}
                                        isReadOnly={status !== ApplicationStatus.inProgress}
                                        isRequired={!skipValidation && shouldShowErrors}
                                        atLeastOneDataStoreIsRequired={
                                            application.appInfo.review.hasPersonalData === "Store" ||
                                            application.appInfo.review.willingToProvideInfo === "Yes"
                                        }
                                        levelOfDetailActiveTab={levelOfDetailActiveTab}
                                        toggleLevelOfDetailTab={(levelOfDetailActiveTab: string): void => {
                                            setLevelOfDetailActiveTab(levelOfDetailActiveTab);
                                        }}
                                        status={status}
                                        dgrResources={application.appInfo.review.dgrResources ?? []}
                                        questions={questions}
                                        committedDataStores={application.appInfo.review.dataStores}
                                        setNewDataStoreResponsesCb={getDSCallback}
                                        displayMessage={displayMessage}
                                    />
                                </ControlBindleInfoProvider>
                            )}
                            <PreSubmitNotice />
                            <RoleOverride setUserRole={setUserRole}></RoleOverride>
                        </SpaceBetween>
                    </Form>
                )}
            </SurveyConsumer>
        </SurveyProvider>
    );
};
