import { CardNames } from "src/components/survey/ApplicationOverview/state/model";
import { SummaryResponse } from "src/services/summaries/model/summaries";

export enum ActionType {
    SummaryCardRequestStarted = "SUMMARY_CARD_REQUEST_STARTED",
    SummaryCardRequestSucceeded = "SUMMARY_CARD_REQUEST_SUCCEEDED",
    SummaryCardRequestFailed = "SUMMARY_CARD_REQUEST_FAILED",
}

export interface SummaryCardRequestStartedAction {
    type: ActionType.SummaryCardRequestStarted;
    payload: { cardName: CardNames };
}

export interface SummaryCardRequestFailedAction {
    type: ActionType.SummaryCardRequestFailed;
    payload: { cardName: CardNames; message: string };
}

export interface SummaryCardRequestSucceededAction {
    type: ActionType.SummaryCardRequestSucceeded;
    payload: { cardName: CardNames; response: SummaryResponse };
}

export type ApplicationOverviewAction =
    | SummaryCardRequestStartedAction
    | SummaryCardRequestFailedAction
    | SummaryCardRequestSucceededAction;

export const actionCreators = {
    summaryCardRequestStarted: (cardName: CardNames): SummaryCardRequestStartedAction => ({
        type: ActionType.SummaryCardRequestStarted,
        payload: { cardName },
    }),

    summaryCardRequestFailed: (cardName: CardNames, error: Error): SummaryCardRequestFailedAction => ({
        type: ActionType.SummaryCardRequestFailed,
        payload: { cardName, message: error.message },
    }),
    summaryCardRequestSucceeded: (
        cardName: CardNames,
        response: SummaryResponse
    ): SummaryCardRequestSucceededAction => ({
        type: ActionType.SummaryCardRequestSucceeded,
        payload: { cardName, response },
    }),
};
