import React, { useContext, useEffect, useState } from "react";
import { AppLayout, ContentLayout, Button, Link, SpaceBetween, TableProps } from "@amzn/awsui-components-react-v3";
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";
import Introduction from "src/components/fields/Introduction";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import KaleContext, { KaleContextProps } from "src/components/KaleContext";
import { KaleRoutesProps, MessageType } from "src/components/survey/KaleRoutes";
import { ApplicationStatus, ApprovalType } from "src/components/survey/SurveyFormModel";
import { ApprovalStatusBadge } from "src/components/ApprovalStatuses/ApprovalStatusBadge";
import { kaleUrls } from "src/util/Urls";

export interface KaleApplication {
    applicationName: string;
    reviewGroup: string;
    reviewId: number;
    controlBindle: string;
    metaDataCreationDate: string;
    metaDataLastUpdate: string;
    legalStatus: ApplicationStatus;
    accountingStatus: ApplicationStatus;
    fraudStatus: ApplicationStatus;
}

// SizeMinimalKaleApplication has smaller length json tags to minimize the json payload size from DB.
// Read CR description for more info: https://code.amazon.com/reviews/CR-97520650
export interface SizeMinimalKaleApplication {
    // App Name
    a: string;
    // Review Group
    rg: string;
    // Review ID
    ri: number;
    // Control Bindle
    b: string;
    // Create Date
    c: string;
    // Update Date
    u: string;
    // Legal Status
    ls: ApplicationStatus;
    // Accounting Status
    as: ApplicationStatus;
    // Fraud (CTPS) Status
    fs: ApplicationStatus;
}

enum ColumnIds {
    applicationName = "applicationName",
    legalStatus = "legalStatus",
    accountingStatus = "accountingStatus",
    fraudStatus = "fraudStatus",
    reviewGroup = "reviewGroup",
    controlBindle = "controlBindle",
    metaDataCreationDate = "metaDataCreationDate",
    metaDataLastUpdate = "metaDataLastUpdate",
}

const getColumnDefinition = (context?: KaleContextProps): TableProps.ColumnDefinition<KaleApplication>[] => {
    const baseColumns: TableProps.ColumnDefinition<KaleApplication>[] = [
        {
            id: ColumnIds.applicationName,
            header: "Application Name",
            cell: (app): JSX.Element => {
                const url = kaleUrls.editKaleRecordUrl(encodeURIComponent(app.applicationName), `${app.reviewId}`);
                return (
                    <Link id={app.applicationName} href={url}>
                        {app.applicationName}
                    </Link>
                );
            },
            sortingField: ColumnIds.applicationName,
        },
        {
            id: ColumnIds.legalStatus,
            header: "Privacy Status",
            cell: (app): JSX.Element => {
                return ApprovalStatusBadge({ status: app.legalStatus, type: ApprovalType.PrivacyApproval });
            },
            sortingField: ColumnIds.legalStatus,
        },
    ];

    const TAFStatusColumns: TableProps.ColumnDefinition<KaleApplication>[] = [
        {
            id: ColumnIds.accountingStatus,
            header: "Accounting Status",
            cell: (app): JSX.Element => {
                return ApprovalStatusBadge({ status: app.accountingStatus, type: ApprovalType.AccountingApproval });
            },
            sortingField: ColumnIds.accountingStatus,
        },
        {
            id: ColumnIds.fraudStatus,
            header: "CTPS Status",
            cell: (app): JSX.Element => {
                return ApprovalStatusBadge({ status: app.fraudStatus, type: ApprovalType.FraudApproval });
            },
            sortingField: ColumnIds.fraudStatus,
        },
    ];
    const metaDataColumns: TableProps.ColumnDefinition<KaleApplication>[] = [
        {
            id: ColumnIds.reviewGroup,
            header: "Reviewer Group",
            cell: (app): JSX.Element => {
                return (
                    <span>
                        <Link
                            target={"_blank"}
                            href={`https://permissions.amazon.com/group.mhtml?group=${app.reviewGroup}&group_type=ldap`}
                        >
                            {app.reviewGroup}
                        </Link>
                    </span>
                );
            },
            sortingField: ColumnIds.reviewGroup,
        },
        {
            id: ColumnIds.controlBindle,
            header: "Control Bindle",
            cell: (app): JSX.Element => {
                return (
                    <span>
                        {context ? (
                            <Link
                                target={"_blank"}
                                href={`${context.config.bindleEndpoint}/software_app/${app.controlBindle}`}
                            >
                                {app.controlBindle}
                            </Link>
                        ) : (
                            "-"
                        )}
                    </span>
                );
            },
            sortingField: ColumnIds.controlBindle,
        },
        {
            id: ColumnIds.metaDataCreationDate,
            header: "Created On",
            cell: (app): string => {
                return new Date(app.metaDataCreationDate).toUTCString();
            },
            sortingField: ColumnIds.metaDataCreationDate,
        },
        {
            id: ColumnIds.metaDataLastUpdate,
            header: "Last Modified",
            cell: (app): string => {
                return new Date(app.metaDataLastUpdate).toUTCString();
            },
            sortingField: ColumnIds.metaDataLastUpdate,
        },
    ];
    return [...baseColumns, ...TAFStatusColumns, ...metaDataColumns];
};

const getFilterKeys = (): string[] => {
    return new Array<string>(
        ColumnIds.applicationName,
        ColumnIds.legalStatus,
        ColumnIds.accountingStatus,
        ColumnIds.fraudStatus,
        ColumnIds.reviewGroup,
        ColumnIds.controlBindle
    );
};

const renderActionStripe = (): JSX.Element => {
    return (
        <RouterLink to="/new">
            <Button id={"AddNewApplication"} variant="primary">
                Add New Application
            </Button>
        </RouterLink>
    );
};

const IndexPage = (props: KaleRoutesProps & RouteComponentProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<KaleApplication>[]>(
        getColumnDefinition()
    );
    const [rowItems, setRowItems] = useState<KaleApplication[]>([]);
    const context = useContext(KaleContext);

    useEffect((): void => {
        (async function (): Promise<void> {
            try {
                const applications = await context.service.kaleAppService.index();
                setRowItems(applications);
            } catch (err) {
                console.error(err);
                props.displayMessage(MessageType.error, (err as Error).message);
            } finally {
                setColumnDefinitions(getColumnDefinition(context));
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLayout
            navigationHide
            toolsHide
            headerSelector="#top-nav"
            content={
                <ContentLayout>
                    <SpaceBetween size="xl">
                        <Introduction />
                        <DASTable<KaleApplication>
                            columnDefinitions={columnDefinitions}
                            filterProps={{
                                type: DASTableFilterType.propertyFilter,
                                filterKeys: getFilterKeys(),
                                placeholder: "Find Applications",
                            }}
                            id={"survey-index"}
                            isLoading={isLoading}
                            isResizable={true}
                            isStickyHeader={true}
                            renderActionStripe={renderActionStripe}
                            rowItems={rowItems}
                            tableName={"Applications"}
                        />
                        {rowItems.length > 0 && <div className={"center-align"}>{renderActionStripe()}</div>}
                    </SpaceBetween>
                </ContentLayout>
            }
        />
    );
};

export default IndexPage;
