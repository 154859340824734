/* eslint-disable max-len */
import { TableCellProps } from "src/components/TableDetails/FieldsTable/TableCell/types";
import { Button } from "@amzn/awsui-components-react-v3";
import React, { useMemo } from "react";
import { TextareaQuestion } from "src/components/TableDetails/FieldsTable/TableCell/TableCellQuestions/TextareaQuestion";
import Error from "src/util/Error";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { REQUIRED_ERROR } from "src/components/TableDetails/FieldsTable/TableCell/constants";
import { ShowModalOptions, useMultiPurposeModal } from "src/components/TableDetails/MultiPurposeModal";
import styled from "styled-components";
import {
    lookupExampleValuesContentTypeAnswer,
    formatExampleValuesAsContentType,
    FormattedExampleValues,
} from "src/components/TableDetails/TableDetailsPage/synthetic-questions/example-values-question-helpers";
/* eslint-enable max-len */

/**
 * Custom styles and CSS overrides for this question cell are used to ensure that the button and the textarea can render
 * side by side onto a single row.
 */
const Flexbox = styled.span`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
`;
const FlexibleTextAreaQuestion = styled(TextareaQuestion)`
    flex: 1;
`;

// Important we make this a span in order to anchor the button to the top of the cell if the
// height of the cell changes due to vertical resize of the accompanying textarea element
const ButtonRegion = styled.span`
    flex: none;
`;

const ErrorButton = styled(Button)`
    &,
    * {
        color: ${awsui.colorTextStatusError} !important;
    }
    &,
    &:active:hover {
        opacity: 0.8 !important;
    }
    &:hover {
        opacity: 1 !important;
    }
`;

const ExampleValuesQuestion = ({ cellState, rowData }: TableCellProps): JSX.Element => {
    const { isValid: isValidProp } = cellState;
    const exampleValuesAnswer = cellState.value as string;
    const { showModal } = useMultiPurposeModal();

    const contentTypeAnswer = lookupExampleValuesContentTypeAnswer(rowData);
    const { formattedValues, formatError } = useMemo(
        (): FormattedExampleValues => formatExampleValuesAsContentType(exampleValuesAnswer, contentTypeAnswer),
        [exampleValuesAnswer, contentTypeAnswer]
    );

    const showModalCb = (): void => {
        const modalOptions: ShowModalOptions = {
            header: contentTypeAnswer,
            body: formatError || formattedValues,
        };
        showModal(modalOptions);
    };

    const isContentValid = isValidProp && !formatError;

    return (
        <React.Fragment>
            <Flexbox>
                {/*
                 ExpandButton is placed before the textarea in order to work around a quirk in chrome where first
                 shrinking the width of the textarea via its native gripper surface, and then restoring the width of
                 the textarea back again, would cause the button to permanently shift horizontally in unexpected ways,
                 if the button came after the TextareaQuestion in DOM order.
                 */}
                <ButtonRegion>
                    {formatError ? (
                        <span title={"Click For Error Detail"}>
                            <ErrorButton iconName="status-negative" variant="icon" onClick={showModalCb} />
                        </span>
                    ) : (
                        <Button iconName="expand" variant="icon" onClick={showModalCb} />
                    )}
                </ButtonRegion>
                <FlexibleTextAreaQuestion {...cellState} isValid={isContentValid} />
            </Flexbox>
            {!isValidProp && <Error text={REQUIRED_ERROR} />}
        </React.Fragment>
    );
};

export { ExampleValuesQuestion };
