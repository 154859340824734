import {
    HasPersonalDataResponse,
    SurveyContext,
    selectLegalApprovalStatus,
} from "src/components/survey/SurveyFormModel";
import { Alert, Container, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
    useLineageStep,
    usePersonalDataClassifierStep,
    usePersonalDataExplanationStep,
    usePersonalDataQuestionStep,
    useSummaryStep,
} from "src/components/survey/PersonalDataWizard/steps";
import { uniqBy } from "lodash";
import InlineWizard, { InlineWizardProps } from "src/components/InlineWizard";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import { PersonalDataWizardProps } from "src/components/survey/PersonalDataWizard/types";
import { usePDCWarningModal } from "src/components/survey/PersonalDataWizard/usePDCWarningModal";
import { shouldShowCampaign } from "src/util/shouldShowCampaign/shouldShowCampaign";
import { REAFFIRM_DATE_THRESHOLD_MONTHS } from "src/util/shouldShowCampaign";
import { CampaignWorkflow } from "src/permissions";

export const PersonalDataWizard = ({
    bindlesError,
    controlBindle,
    onChangeCallback,
    onClearDataStore,
    pdwStepIndex,
    isPDWComplete,
    relatedBindles,
    response,
    setPdwStepIndex,
    setIsPDWComplete,
    skipValidation,
}: PersonalDataWizardProps): JSX.Element => {
    const farthestStepIndex = useRef<number>(pdwStepIndex);
    const { role } = useContext(SurveyContext);
    const legalStatus = selectLegalApprovalStatus(response).status;

    const personalDataStatement = response.review.hasPersonalData;

    const requestBindles = useMemo(
        // Ensure that the control bindle exists because, depending on user access, some applications
        // may not have carried it across.
        () => uniqBy(relatedBindles.concat(controlBindle ? [controlBindle] : []), "value"),
        [relatedBindles, controlBindle]
    );

    const { step: explanationStep } = usePersonalDataExplanationStep();
    const {
        step: lineageStep,
        lineageFindings,
        error: lineageError,
    } = useLineageStep({
        requestBindles,
    });
    const isShowingReaffirmCampaign = shouldShowCampaign(CampaignWorkflow.Reaffirm, {
        dateShownAt: response.review.reaffirmShownAt,
        dateThresholdMonths: REAFFIRM_DATE_THRESHOLD_MONTHS,
        legalStatus,
        userRole: role,
    });
    const {
        step: pdcStep,
        pdcFindings,
        error: pdcError,
        isHighConfidence,
    } = usePersonalDataClassifierStep({
        requestBindles,
        isShowingReaffirmCampaign,
    });
    const { step: summaryStep } = useSummaryStep({
        lineageFindings,
        isPDWComplete,
        pdcFindings,
        isPDCHighConfidence: isHighConfidence,
        response,
        setPdwStepIndex,
    });
    const { step: questionStep } = usePersonalDataQuestionStep({
        hasPersonalData: personalDataStatement,
        onClearDataStore,
        onChangeCallback,
        response,
        setIsPDWComplete,
        skipValidation,
    });

    const deps = { controlBindle, relatedBindles, response, setPdwStepIndex, setIsPDWComplete };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        if (bindlesError) {
            const { setIsPDWComplete } = dependenciesRef.current;
            setIsPDWComplete(false);
        }
    }, [bindlesError]);

    useEffect(
        function manageFarthestStepIndex() {
            if (pdwStepIndex > farthestStepIndex.current) {
                farthestStepIndex.current = pdwStepIndex;
            }
        },
        [pdwStepIndex]
    );

    const steps = [explanationStep, lineageStep, pdcStep, questionStep, summaryStep];

    const questionStepIndex = steps.indexOf(questionStep);
    // We're using this number to block users onNavigate to the step after questionStep
    // ...if they haven't answered the required questionStep
    const stepIndexAfterQuestionStep = questionStepIndex + 1;

    const { pdcWarningModal, showPDCWarningModalCb } = usePDCWarningModal({
        setIsPDWComplete,
        setPdwStepIndex,
        questionStepIndex,
    });

    const onWizardSubmit: InlineWizardProps["onSubmit"] = (): void => {
        if (personalDataStatement === HasPersonalDataResponse.Empty) {
            return;
        } else if (personalDataStatement === HasPersonalDataResponse.No && isHighConfidence) {
            showPDCWarningModalCb();
        } else {
            setIsPDWComplete(true);
        }
    };

    const onWizardNavigate: InlineWizardProps["onNavigate"] = (event): void => {
        if (
            event.detail.requestedStepIndex === stepIndexAfterQuestionStep &&
            personalDataStatement === HasPersonalDataResponse.Empty
        ) {
            // Block navigation to final summary step if they haven't provided a Personal Data Statement.
            return;
        }
        setPdwStepIndex(event.detail.requestedStepIndex);
    };

    return (
        <SpaceBetween size={"xxl"}>
            {Boolean(bindlesError) && <Alert type={"error"}>{bindlesError}</Alert>}
            {lineageError !== "" && <Alert type={"error"}>{lineageError}</Alert>}
            {pdcError !== "" && <Alert type={"error"}>{pdcError}</Alert>}
            {requestBindles.length > 0 && (
                <Container header={<Header variant={"h3"}>Personal Data</Header>}>
                    <InlineWizard
                        data-testid={TEST_IDS.WIZARD.ROOT}
                        i18nStrings={{
                            stepNumberLabel: (stepNumber): string => `Step ${stepNumber}`,
                            previousButton: "Previous",
                            nextButton: "Next",
                            submitButton: "Confirm and continue",
                            optional: "optional",
                        }}
                        hideSubmitButton={isPDWComplete}
                        onSubmit={onWizardSubmit}
                        onNavigate={onWizardNavigate}
                        activeStepIndex={pdwStepIndex}
                        steps={steps}
                        farthestStepIndex={farthestStepIndex.current}
                    />
                </Container>
            )}
            {pdcWarningModal}
        </SpaceBetween>
    );
};
