import { AnswerWithQuestion, ParentConstraintBase, TableAnswer, TableAnswerQuestion } from "src/answers_legacy";

export type TableFieldQuestion = TableAnswerQuestion & ParentConstraintBase;

export enum TableFieldAnswerKey {
    fieldId = "fieldId",
    userFieldId = "userFieldId",
}

export interface TableFieldAnswer extends TableAnswer {
    [TableFieldAnswerKey.fieldId]?: number;
    [TableFieldAnswerKey.userFieldId]?: string;
}

export function DefaultTableFieldAnswer(
    questionId: number,
    questionShortId: string,
    fieldId?: number,
    userFieldId?: string
): TableFieldAnswer {
    return {
        questionId,
        questionShortId,
        fieldId,
        userFieldId,
    };
}

export type TableFieldAnswerWithQuestion = AnswerWithQuestion<TableFieldQuestion, TableFieldAnswer>;
