/* eslint-disable max-len */
import {
    CradleMetadataStatus,
    FetchCradleMetadataRequestOptions,
    FetchCradleMetadataResponse,
    CradleService,
} from "src/services/CradleService";
import { CradleMetadata } from "src/components/TableDetails/TableDetailsForm/types";
import { AndesPolicyTypes } from "src/components/TableDetails/TableDetailsForm/hooks/useCradleMetadataForAndes/constants";
/* eslint-enable max-len */

const DEFAULT_INTERVAL = 10000; // Poll the API every 10 seconds
const MAX_ATTEMPTS = 60; // 60 attempts at 10 seconds per attempt will Poll for roughly 10 minutes before giving up.

export enum PollingErrorCode {
    DSAR_CREATION_FAILED = "DSAR_CREATION_FAILED",
    OD3_CREATION_FAILED = "OD3_CREATION_FAILED",
}

export interface CancellablePollingPromise {
    pollingPromise: Promise<CradleMetadata>;
    cancelPolling: () => void;
}

/**
 * The public interface for the method to poll the Cradle Service fetchMetadata API until Cradle metadata is available.
 * This method will first call the CradleService fetch API to check if metadata already exists for the specified
 * resource. If the metadata exists, it will be yielded by the returned pollingPromise. If it does not exist, this
 * method will call the Cradle Service createMetadata API and then poll the fetchMetadata API until the metadata has
 * been created. BDT team has said that creation of OD3 metadata in particular, could have a worse case run time of 600
 * seconds, or 10 minutes
 * @return CancellablePollingPromise.pollingPromise - The promise which represent the async work to poll the cradle
 * fetch API until the cradle metadata for the requested policy type is returned.
 * @return CancellablePollingPromise.cancelPolling - A method to cancel the polling operation prematurely. Once
 * cancelled, the polling promise will never resolve nor reject.
 */
const pollForCradleMetaData = (
    cradleService: CradleService,
    requestOptions: FetchCradleMetadataRequestOptions
): CancellablePollingPromise =>
    _pollForCradleMetaDataImpl({
        cradleService,
        requestOptions,
        timeToWait: DEFAULT_INTERVAL,
        maxAttempts: MAX_ATTEMPTS,
    });

interface PollingOptions {
    cradleService: CradleService;
    requestOptions: FetchCradleMetadataRequestOptions;
    timeToWait: number;
    maxAttempts: number;
}

/**
 * The private interface for the method to poll the Cradle Service GET API until Cradle metadata is available.
 * This private interface exists to make unit testing simpler.
 */
const _pollForCradleMetaDataImpl = ({
    cradleService,
    requestOptions,
    timeToWait,
    maxAttempts,
}: PollingOptions): CancellablePollingPromise => {
    let numAttempts = 0;
    const { policyType } = requestOptions;

    let isPollingCancelled = false;
    const cancelPolling = (): void => {
        isPollingCancelled = true;
    };

    const pollingPromise = new Promise<CradleMetadata>((resolve, reject): void => {
        const pollForCradleMetadata = (): void => {
            if (isPollingCancelled) {
                // If the caller has cancelled polling we return early without scheduling another poll
                // and the promise should never resolve nor reject.
                return;
            }
            cradleService.fetchCradleMetadata(requestOptions).then(handleFetchApiResponse, reject);
        };

        // eslint-disable-next-line
        const scheduleNextPoll = (): number => setTimeout(pollForCradleMetadata, timeToWait);

        const handleFetchApiResponse = ({ status, ...cradleMetadata }: FetchCradleMetadataResponse): void => {
            numAttempts++;
            const isFirstAttempt = 1 === numAttempts;

            const policyMessagePrefix = `${policyType} Cradle Metadata`;

            const pollingStatusMessage = `${policyMessagePrefix} poll attempt: ${numAttempts}, Status: ${status}`;
            console.info(pollingStatusMessage);

            if (status === CradleMetadataStatus.Completed) {
                resolve(cradleMetadata);
            } else if (numAttempts >= maxAttempts) {
                // Timeout error
                reject(new Error(`${policyMessagePrefix} API polling exceeded max attempts`));
            } else {
                switch (status) {
                    case CradleMetadataStatus.Failed: {
                        if (isFirstAttempt) {
                            // Treat a Failed status on the first attempt the same as a NotStarted status.
                            // A Failure reported by the fetch API on a first attempt is actually a failure
                            // associated with a previous attempt to create the Cradle metadata for this
                            // resource on a previous load. Go ahead and try a fresh attempt to create
                            // the metadata now. (make one attempt per page load)
                            cradleService.createCradleMetadata(requestOptions).then(scheduleNextPoll, reject);
                        } else {
                            // Network connection and API call were successful but the backend service could not create
                            // the expected resource. Indicate which policy type the failed resource creation was for
                            // in our promise rejection

                            switch (policyType) {
                                case AndesPolicyTypes.DSAR: {
                                    reject(new Error(PollingErrorCode.DSAR_CREATION_FAILED));
                                    break;
                                }
                                case AndesPolicyTypes.OD3: {
                                    reject(new Error(PollingErrorCode.OD3_CREATION_FAILED));
                                    break;
                                }
                                default: {
                                    // Generic error to display in the UI for any future types that we don't support
                                    // today
                                    reject(
                                        new Error(`${policyMessagePrefix} API has reported an internal failure status.`)
                                    );
                                }
                            }
                        }
                        break;
                    }
                    case CradleMetadataStatus.NotStarted: {
                        cradleService.createCradleMetadata(requestOptions).then(scheduleNextPoll, reject);
                        break;
                    }
                    case CradleMetadataStatus.Started:
                    case CradleMetadataStatus.CradleAccountCreated:
                    case CradleMetadataStatus.CradleProfileCreated: {
                        scheduleNextPoll();
                        break;
                    }
                }
            }
        };

        pollForCradleMetadata();
    });

    return { pollingPromise, cancelPolling };
};

export { pollForCradleMetaData, _pollForCradleMetaDataImpl };
