import AbstractKaleService, { OperationResult } from "src/services/AbstractKaleService";
import { TokenGetter } from "src/services/CognitoService";
import { FeatureToggleConfig, KaleConfig } from "src/Config";

export interface BindleReference {
    id: string;
    name: string;
}

export interface OwningTeam {
    id: string;
    name: string;
}

export interface Bindle {
    bindle: BindleReference;
    owningTeam: OwningTeam;
}

export interface BatchDescribeResourcesResult {
    resources: Bindle[];
}

// Class used to interact with backend Bindle and Brass service
export class BrandleService extends AbstractKaleService {
    // https://gsam.corp.amazon.com/account/ldap/svcdaswebsiteintegtest
    private readonly hydra_test_alias = "svcdaswebsiteintegtest";

    public constructor(kaleConfig: KaleConfig & FeatureToggleConfig, accessTokenGetter: TokenGetter) {
        super(kaleConfig, accessTokenGetter);

        this.findBindlesByOwner = this.findBindlesByOwner.bind(this);
        this.fetchBindleDetails = this.fetchBindleDetails.bind(this);
    }

    public findBindlesByOwner(ownerAlias: string, includePersonal: boolean): Promise<Bindle[]> {
        // Due to the LDAP system account not being compatible with bindles, doing this gives us valid results
        if (ownerAlias == this.hydra_test_alias)
            return new Promise((resolve): void => {
                return resolve([
                    {
                        bindle: { id: "fake_id1", name: "DGI-DAS" },
                        owningTeam: { id: "", name: "" },
                    },
                    {
                        bindle: { id: "fake_id2", name: "DGI-Kale-HydraTestsOnly" },
                        owningTeam: { id: "", name: "" },
                    },
                ]);
            });

        const apiEndpoint = new URL(`${this.baseApiEndpoint}/bindles/fetch-by-owner/${ownerAlias}`);
        apiEndpoint.searchParams.append("include_personal_bindles", String(includePersonal));

        return this.signedFetch("GET", apiEndpoint.toString()).then((res: Response): Promise<Bindle[]> => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw Error("Non-200 response");
            }
        }, this.buildDefaultRejection());
    }

    public fetchBindleDetails(bindleNames: string[]): Promise<BatchDescribeResourcesResult> {
        const apiEndpoint = new URL(`${this.baseNodeApiEndpoint}/bindles/batch-describe-resources`);
        const requestBody = {
            bindleNames,
        };

        return this.signedFetch("POST", apiEndpoint.toString(), JSON.stringify(requestBody))
            .then((res: Response): Promise<OperationResult<BatchDescribeResourcesResult>> => {
                if (res.status === 201) {
                    return res.json();
                } else {
                    throw Error(`Fetch Bindle Details: ${res.status} response for ${JSON.stringify(requestBody)}`);
                }
            }, this.buildDefaultRejection())
            .then((responseBody): BatchDescribeResourcesResult => {
                return responseBody.result;
            });
    }
}
