/* eslint-disable max-len */
import { DataStoreHookAnswer } from "src/answers_legacy";
import React from "react";
import { TEST_IDS } from "shared/survey";
import ChildDataDecorator, {
    childDataQuestionShortId,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/ChildDataDecorator";
import { Container, Header } from "@amzn/awsui-components-react-v3";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import renderQuestionContentByType from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/render-question-content-by-type";
import LongRetentionQuestion from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/LongRetentionQuestion";
/* eslint-enable max-len */

const {
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: {
            DYNAMIC_QUESTION_STEP: { STAND_ALONE_QUESTION },
        },
    },
} = TEST_IDS;

const renderHasChildDataQuestion = (item: DataStoreHookAnswer): JSX.Element => {
    const questionContent = renderQuestionContentByType(item);
    return (
        <ChildDataDecorator key={item.question.shortId} subtext={item.question.subtext} content={item.question.content}>
            {questionContent}
        </ChildDataDecorator>
    );
};

const renderCommonDataStoreQuestion = (item: DataStoreHookAnswer): JSX.Element => {
    const { shortId, subtext, content } = item.question;

    const questionBody =
        shortId === "deletion_has_long_retention" || shortId === "dsar_has_long_retention" ? (
            <LongRetentionQuestion item={item} />
        ) : (
            renderQuestionContentByType(item)
        );

    return (
        <Container header={<Header description={KaleHTMLParser(subtext)}>{KaleHTMLParser(content)}</Header>}>
            {questionBody}
        </Container>
    );
};

interface StandAloneQuestionProps {
    item: DataStoreHookAnswer;
}

/**
 * StandAloneQuestion components own their container element and use their own "content" and "subtext"
 * properties for the <Container>'s "title" and "description" props, rather than rendering that text
 * their "content" and "subtext" inline next to the question UI input. These are our legacy question
 * components which never needed to have display a <Container> title or description separate from the
 * question's content and subtext properties.
 */
const StandAloneQuestion = ({ item }: StandAloneQuestionProps): JSX.Element => {
    const { shortId, type } = item.question;

    return (
        <div key={shortId} data-testid={STAND_ALONE_QUESTION} data-question-shortid={shortId} data-question-type={type}>
            {shortId === childDataQuestionShortId
                ? renderHasChildDataQuestion(item)
                : renderCommonDataStoreQuestion(item)}
        </div>
    );
};

export default StandAloneQuestion;
