import { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface ApplicationRouteParams {
    applicationName: string;
    reviewId: string;
}

export const useAppParamsFromRoute = (): ApplicationRouteParams => {
    const { applicationName: originalName = "", reviewId: originalId = "" } = useParams<ApplicationRouteParams>();

    // Some circulated URLs include reserved characters such as ampersand,
    // which react router may choose not to decode or encode.
    const applicationName = decodeURIComponent(originalName);
    // should just be a number, but decode out of precaution
    const reviewId = decodeURIComponent(originalId);

    // Just return the same object if the string values haven't changed.
    // otherwise useParams will return a new object every time even if
    // the route hasn't changed.
    return useMemo(
        (): ApplicationRouteParams => ({
            applicationName,
            reviewId,
        }),
        [applicationName, reviewId]
    );
};
