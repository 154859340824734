/* eslint-disable max-len */
import React from "react";
import { Container, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import { DataStoreHookAnswer, QuestionTag } from "src/answers_legacy";
import styled from "styled-components";
import GroupSection from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/GroupSection";
import { TEST_IDS } from "shared/survey";
import ChildDataGatingQuestionGroup, {
    childOrTeenGroupId,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/ChildDataGatingQuestionGroup";
/* eslint-enable max-len */

const {
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: {
            DYNAMIC_QUESTION_STEP: {
                QUESTION_GROUP: { ROOT: QUESTION_GROUP_ROOT, CONTENT: GROUP_CONTENT, SEPARATOR },
            },
        },
    },
} = TEST_IDS;

const Separator = styled.hr``;

type GroupSections = DataStoreHookAnswer[][];

/**
 * Organizes all question within a group into distinct grid sections. The heuristic for determining which
 * questions go into which grid sections is currently based on question tags. We process each question in the group,
 * whenever a question contains the "newGroupSection" tag, that is the trigger to create a new grid section and the
 * current question will be the first question added into the new section. All subsequent questions without their
 * own "newGroupSection" tag are considered to be part of the same newest grid section and will all be placed there,
 * until a new question with the "newGroupSection" tag is encountered
 * @return - A list of question lists. Each list contains the applicable questions for each section.
 */
const arrangeApplicableSections = (questionsList: DataStoreHookAnswer[]): GroupSections => {
    // Process each question and arrange them into distinct sections
    return (
        questionsList
            .reduce<GroupSections>((sections, currItem): GroupSections => {
                if (sections.length === 0 || currItem.question.tags.includes(QuestionTag.newGroupSection)) {
                    // This question is the beginning of a new section
                    const newSection: DataStoreHookAnswer[] = [];
                    sections.push(newSection);
                }

                // If the item is applicable, add it to the current section
                // Else we will not include it.
                if (currItem.isApplicable) {
                    const currentSection = sections[sections.length - 1];
                    currentSection.push(currItem);
                }

                return sections;
            }, [])
            // If a section has no applicable questions, it should be empty and
            // we do not need to render that section. Filter out empty sections
            .filter((section): boolean => Boolean(section.length))
    );
};

/**
 * Helper method to render all group sections and intersperse them with visual separators
 */
const renderGroupContent = (sections: GroupSections): JSX.Element | null => {
    if (sections.length) {
        const renderedContent = sections.reduce<JSX.Element[]>(
            (renderedContent, sectionQuestions, index, sections): JSX.Element[] => {
                renderedContent.push(<GroupSection sectionQuestions={sectionQuestions} />);

                // Render a separator between each group section
                if (index < sections.length - 1) {
                    renderedContent.push(<Separator data-testid={SEPARATOR} />);
                }

                return renderedContent;
            },
            []
        );

        return (
            <SpaceBetween data-testid={GROUP_CONTENT} size={"xl"}>
                {renderedContent}
            </SpaceBetween>
        );
    } else {
        return null;
    }
};

export interface QuestionGroupProps {
    groupId: string;
    groupTitle?: string;
    groupDescription?: string;
    groupQuestions: DataStoreHookAnswer[]; // The list of questions in the group
}

/**
 * Component that represents a group of questions rendered inside of a shared Container
 * with a shared group title and group description that applies to all of the questions
 * in the group.
 */
const QuestionGroup = (props: QuestionGroupProps): JSX.Element | null => {
    const { groupId, groupQuestions, groupTitle = "", groupDescription = "" } = props;

    const applicableSections = arrangeApplicableSections(groupQuestions);

    if (groupId === childOrTeenGroupId) {
        return (
            <ChildDataGatingQuestionGroup key={groupId} groupTitle={groupTitle} groupDescription={groupDescription}>
                {renderGroupContent(applicableSections)}
            </ChildDataGatingQuestionGroup>
        );
    }

    return (
        <Container
            key={groupId}
            data-groupid={groupId}
            data-testid={QUESTION_GROUP_ROOT}
            header={<Header description={KaleHTMLParser(groupDescription)}>{KaleHTMLParser(groupTitle)}</Header>}
        >
            {renderGroupContent(applicableSections)}
        </Container>
    );
};

export default QuestionGroup;
