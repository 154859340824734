import { PDCWarningModalProps } from "./types";
import { useEffect, useRef } from "react";

type UseHandleShowEventProps = Pick<PDCWarningModalProps, "visible"> & { onShow: () => void };
export const useHandleShowEvent = ({ visible, onShow }: UseHandleShowEventProps) => {
    const deps = { onShow };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(
        function handleOnShow() {
            const { onShow } = depsRef.current;
            if (Boolean(visible)) {
                onShow();
            }
        },
        [visible]
    );
};
