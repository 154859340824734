import {
    FetchQuestionsResultState,
    useFetchQuestionsOnMount,
} from "src/components/survey/hooks/useFetchQuestionsOnMount";
import {
    useFetchApplicationOnMount,
    FetchApplicationResultState,
} from "src/components/survey/hooks/useFetchApplicationOnMount";
import {
    FetchApplicationMetadataResultState,
    useFetchApplicationMetadata,
} from "src/components/survey/hooks/useFetchApplicationMetadata";

interface FetchSurveyFormResourcesResultState {
    kaleQuestionsResources: FetchQuestionsResultState;
    applicationResources: FetchApplicationResultState;
    applicationMetadataResources: FetchApplicationMetadataResultState;
}
/**
 * Custom hook responsible for fetching all LegalSurvey Page resources on page load
 */

export const useFetchLegalSurveyResources = (): FetchSurveyFormResourcesResultState => {
    const kaleQuestionsResources = useFetchQuestionsOnMount();
    const applicationResources = useFetchApplicationOnMount();
    const applicationMetadataResources = useFetchApplicationMetadata();

    return {
        kaleQuestionsResources,
        applicationResources,
        applicationMetadataResources,
    };
};
