import { createContext, useContext } from "react";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";

export interface MessageBannerActions {
    displayMessage: DisplayMessageCb;
}

const MessageBannerActions = createContext<undefined | MessageBannerActions>(undefined);

/**
 * A custom hook for context consumers to hide/show the modal and configure its header, body and footer contents.
 */
const useMessageBannerActions = (): MessageBannerActions => {
    const actions = useContext(MessageBannerActions);
    if (actions === undefined) {
        throw new Error("useMessageBannerActions must be used within a MessageBannerActions Provider");
    }
    return actions;
};

const MessageBannerActionsProvider = MessageBannerActions.Provider;
export { MessageBannerActionsProvider, useMessageBannerActions };
