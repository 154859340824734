import { useKaleTable, useKaleTableFields } from "src/answers_legacy";

export const useIsFormValid = (): boolean => {
    // Do all Table questions have valid answers?
    const [tableQuestionsState] = useKaleTable();
    const hasValidTableQuestions = tableQuestionsState.isValidSave;

    // Do all Fields questions have valid answers?
    const [fieldsQuestionsStates] = useKaleTableFields();
    const hasAnyInvalidFieldsQuestion = fieldsQuestionsStates.some(({ isValidSave }): boolean => !isValidSave);
    const hasValidFieldsQuestions = !hasAnyInvalidFieldsQuestion;

    return hasValidTableQuestions && hasValidFieldsQuestions;
};
