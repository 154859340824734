import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Restore window scroll position on forward navigation through the app.
// Taken from: https://reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = (): null => {
    const { pathname } = useLocation();

    useEffect((): void => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
