import { difference, isEmpty, omit } from "lodash";
import { AnswerState, Response } from "src/answers_legacy/context";
import { isGenericResponseValid } from "src/answers_legacy/validation";
import {
    AnswerWithQuestion,
    ConstraintLookupKeys,
    ContentKey,
    ContentValue,
    ParentLookupKeys,
    QuestionBase,
    QuestionTag,
    QuestionType,
    RetentionInputType,
    RetentionJsonContentType,
} from "../models/common";
import { Answer, Question, StateKey, StateOptions } from "src/answers_legacy";

function getLookupKeys<QType extends QuestionBase>(question: QType): [ParentLookupKeys, ConstraintLookupKeys] {
    if (question.parentShortId) {
        return [ParentLookupKeys.shortId, ConstraintLookupKeys.parentShortId];
    }

    return [ParentLookupKeys.id, ConstraintLookupKeys.parentId];
}

const selectChildAnswers = <T extends AnswerWithQuestion>(
    answer: Response<T>,
    answers: Response<T>[]
): Response<T>[] => {
    const matcher = (answer: Response<T>, answers: Response<T>[], result: Response<T>[] = []): Response<T>[] => {
        const [lookupKey, parentLookupKey] = getLookupKeys(answer.value.question);
        // mitigates an invalid use-case of a cyclically referenced short id
        if (answer.value.question[parentLookupKey] === answer.value.question.shortId) {
            return result;
        }
        // find and store child answers
        answers.forEach((searchAnswer): void => {
            if (searchAnswer.value.question[parentLookupKey] === answer.value.question[lookupKey]) {
                if (searchAnswer) {
                    result.push(searchAnswer);
                }
                if (searchAnswer.value.question[parentLookupKey]) {
                    matcher(searchAnswer, answers, result);
                }
            }
        });

        return result;
    };

    return matcher(answer, answers);
};

export const reduceAnswerIsValidWithContent = <T extends AnswerWithQuestion>(
    answer: Response<T>,
    answers: Response<T>[],
    content: ContentValue,
    state: AnswerState,
    stateKey: StateKey,
    newStateOptions?: StateOptions<Question, Answer>
): Response<T> => {
    if (answer.value) {
        const [, childLookupKey] = getLookupKeys(answer.value.question);

        // Kale Tables and upcoming Application implementation approach uses a single slice item,
        // so cross-slice look-up is handled with first item (aka "row") in state slice.
        // We may consider updating this to search "any" row in state slice if the use-case becomes valid.
        const entityAnswers =
            // has a cross-slice lookup key
            answer.value.question.parentEntityId &&
            // is not the current answers' context slice, so to avoid an eventual consistency issue
            stateKey !== (answer.value.question.parentEntityId as string) &&
            // set the new answers value
            (state[answer.value.question.parentEntityId][0].value as unknown as Response<T>[]);

        let stateOptions = newStateOptions;

        if (!stateOptions) {
            switch (stateKey) {
                case `${StateKey.review}`: {
                    if (state.reviewOptions) {
                        stateOptions = state.reviewOptions;
                    }
                    break;
                }
                case `${StateKey.dataStores}`: {
                    if (state.dataStoresOptions) {
                        stateOptions = state.dataStoresOptions;
                    }
                    break;
                }
                case `${StateKey.kaleTable}`: {
                    if (state.kaleTableOptions) {
                        stateOptions = state.kaleTableOptions;
                    }
                    break;
                }
                case `${StateKey.kaleTableFields}`: {
                    if (state.kaleTableFieldsOptions) {
                        stateOptions = state.kaleTableFieldsOptions;
                    }
                    break;
                }
                case `${StateKey.kaleTableFieldSidebar}`: {
                    if (state.kaleTableFieldSidebarOptions) {
                        stateOptions = state.kaleTableFieldSidebarOptions;
                    }
                    break;
                }
            }
        }

        const hasParentConstraint = Boolean(answer.value.question[childLookupKey]);
        const [parentAnswer, metParentConstraint] =
            meetsParentConstraint<T>(answer.value, entityAnswers || answers) || !hasParentConstraint;

        const [hasCustomConstraint, metCustomConstraint] = (stateOptions?.constraintHandler &&
            stateOptions?.constraintHandler({
                hasParentConstraint: hasParentConstraint,
                isParentConstraintMet: metParentConstraint,
                parentQuestion: parentAnswer?.value.question as Question,
                parentAnswer: parentAnswer?.value.answer as Answer,
                currentAnswer: answer.value.answer as Answer,
                answers: answers.map((answer): Answer => answer.value.answer),
                question: answer.value.question as Question,
            })) || [hasParentConstraint, metParentConstraint];

        const isNotApplicable = hasCustomConstraint && !metCustomConstraint;

        answer.isApplicable = !isNotApplicable;

        let hasRequiredTag = answer.value.question.tags?.includes(QuestionTag.required);

        const hasRequiredForDraft = answer.value.question.tags?.includes(QuestionTag.requiredForDraft);
        if (hasRequiredForDraft && !hasRequiredTag) {
            hasRequiredTag = true;
        }

        const contentKey = getContentKey(answer.value.question);
        const contentValue = getDefaultContentValue(answer.value.question);
        if (isNotApplicable) {
            if (
                !isEmpty(answer.value.answer[contentKey]) &&
                difference(
                    ([] as string[]).concat(answer.value.answer[contentKey] as string | string[]),
                    ([] as string[]).concat(contentValue as string | string[])
                ).length > 0
            ) {
                answer.userLastUpdated = new Date().toISOString();
            }
            (answer.value.answer[contentKey] as ContentValue) = contentValue;
        }

        const hasConstraint = hasParentConstraint || hasCustomConstraint;

        content = hasConstraint ? (answer.value.answer[contentKey] as ContentValue) : content;

        answer.isValidSubmit = isGenericResponseValid(
            content,
            hasConstraint ? answer.isApplicable && hasRequiredTag : hasRequiredTag
        );
        answer.isValidSave = isGenericResponseValid(
            content,
            hasConstraint ? answer.isApplicable && hasRequiredForDraft : hasRequiredForDraft
        );

        const isInvalid = !answer.isValidSave || !answer.isValidSubmit;
        answer.isValid = !isInvalid;

        if (isNotApplicable) {
            // Re-evaluate child answers. This is a cascading scenario in which we want to clear a child question's
            // answer as soon as it's determined that it's not applicable, but we also want to see if that child
            // question is a parent question, and if so, mark all descendant questions down the chain as inapplicable
            // and clear their answers as well.
            const [answerLookupKey] = getLookupKeys(answer.value.question);
            const newAnswers = (entityAnswers || answers).map((searchAnswer): Response<T> => {
                const [searchLookupKey] = getLookupKeys(searchAnswer.value.question);
                return searchAnswer.value.question[searchLookupKey] === answer.value.question[answerLookupKey]
                    ? answer
                    : searchAnswer;
            });
            selectChildAnswers<T>(answer, newAnswers).forEach((childAnswer): void => {
                const contentKey = getContentKey(childAnswer.value.question);
                reduceAnswerIsValidWithContent(
                    childAnswer,
                    newAnswers,
                    answer.value.answer[contentKey] as ContentValue,
                    state,
                    stateKey,
                    newStateOptions
                );
            });
        }
    }
    return answer;
};

export const getContentKey = (question: QuestionBase): ContentKey => {
    switch (question.type) {
        case QuestionType.multiSelect:
        case QuestionType.checkboxGroup:
        case QuestionType.textTags: {
            return ContentKey.arrayContent;
        }
        case QuestionType.date: {
            return ContentKey.dateContent;
        }
        case QuestionType.retention: {
            return ContentKey.jsonContent;
        }
        default: {
            return ContentKey.textContent;
        }
    }
};

export const isJsonContentEmpty = (value: RetentionJsonContentType): boolean => {
    return (
        value[RetentionInputType.retentionCheckbox] === "" &&
        value[RetentionInputType.retentionSingleSelect] === "" &&
        value[RetentionInputType.retentionText] === ""
    );
};

export const getDefaultContentValue = (question: QuestionBase): ContentValue => {
    switch (question.type) {
        case QuestionType.multiSelect:
        case QuestionType.checkboxGroup:
        case QuestionType.textTags: {
            return [];
        }
        case QuestionType.autoSuggest:
        case QuestionType.checkbox:
        case QuestionType.text:
        case QuestionType.textArea:
        case QuestionType.radio:
        case QuestionType.date: {
            return "";
        }
        case QuestionType.retention: {
            return {
                [RetentionInputType.retentionCheckbox]: "",
                [RetentionInputType.retentionSingleSelect]: "",
                [RetentionInputType.retentionText]: "",
            };
        }
        default: {
            return "";
        }
    }
};

export const meetsParentConstraint = <T extends AnswerWithQuestion>(
    answer: Partial<T>,
    answers: Response<T>[]
): [Response<T> | undefined, boolean] => {
    let meetsParentConstraint = false;
    let parentResponse: Response<T> | undefined;
    if (answer.question) {
        const [parentLookupKey, childLookupKey] = getLookupKeys(answer.question);

        if (answer.question[childLookupKey]) {
            parentResponse = answers.find(
                (searchAnswer): boolean =>
                    searchAnswer.value?.question[parentLookupKey] === answer.question?.[childLookupKey]
            );
            if (parentResponse) {
                switch (parentResponse.value?.question.type) {
                    case QuestionType.multiSelect:
                    case QuestionType.checkboxGroup:
                    case QuestionType.textTags: {
                        if (
                            // These question type choices should always be a list of strings
                            (answer.question.parentChoices as string[])?.every(
                                (v: string): boolean => parentResponse?.value.answer.arrayContent?.includes(v) ?? false
                            )
                        ) {
                            meetsParentConstraint = true;
                        }
                        break;
                    }
                    case QuestionType.checkbox: {
                        if (
                            // Checkbox question type choices should always be a list of strings
                            (answer.question.parentChoices as string[])?.every(
                                (compareChoiceValue: string): boolean => {
                                    const compareChoice = compareChoiceValue.toLocaleLowerCase() === "true";
                                    const parentAnswerChoice =
                                        parentResponse?.value?.answer?.textContent?.toLocaleLowerCase() === "true";

                                    return compareChoice === parentAnswerChoice;
                                }
                            )
                        ) {
                            meetsParentConstraint = true;
                        }
                        break;
                    }
                    case QuestionType.date: {
                        if (
                            // Date question type choices should always be a list of strings
                            (answer.question.parentChoices as string[])?.every(
                                (v: string): boolean => parentResponse?.value.answer.dateContent === v
                            )
                        ) {
                            meetsParentConstraint = true;
                        }
                        break;
                    }
                    case QuestionType.retention: {
                        if (
                            // Retention question type choices should always be a list of RetentionJsonContentTypes
                            (answer.question.parentChoices as RetentionJsonContentType[])?.every(
                                (v: RetentionJsonContentType): boolean => parentResponse?.value.answer.jsonContent === v
                            )
                        ) {
                            meetsParentConstraint = true;
                        }
                        break;
                    }
                    default: {
                        if (
                            answer.question.parentChoices?.every(
                                (v): boolean => parentResponse?.value.answer.textContent === v
                            )
                        ) {
                            meetsParentConstraint = true;
                        }
                        break;
                    }
                }
            }
        }
    }

    return [parentResponse, meetsParentConstraint];
};

interface ReSortResponse<T> extends Response<T> {
    _originalIndex?: number;
}

export const reduceAnswersIsValid = <T extends AnswerWithQuestion>(
    answers: ReSortResponse<T>[],
    state: AnswerState,
    stateKey: StateKey,
    stateOptions?: StateOptions<Question, Answer>
): ReSortResponse<T>[] => {
    answers.forEach((answer, answerIndex): void => {
        if (answer._originalIndex === undefined) {
            answer._originalIndex = answerIndex;
        }
        if (answer?.value) {
            const [, metParentConstraint] = meetsParentConstraint<T>(answer.value, answers);
            if (metParentConstraint) {
                const nextAnswerIndex = answerIndex + 1;
                const nextAnswer = answers[nextAnswerIndex];
                if (nextAnswer) {
                    const removeIndex = answers.indexOf(answer);
                    if (removeIndex > -1) {
                        answers.splice(removeIndex, 1);
                    }
                    answers.push(answer);
                    answer = nextAnswer;
                    if (answer._originalIndex === undefined) {
                        answer._originalIndex = nextAnswerIndex;
                    }
                }
            }
            switch (answer.value?.question.type) {
                // NOTE: add cases here for other types from transport source
                case QuestionType.multiSelect:
                case QuestionType.checkboxGroup:
                case QuestionType.textTags: {
                    answer = reduceAnswerIsValidWithContent<T>(
                        answer,
                        answers,
                        answer.value?.answer.arrayContent as string[],
                        state,
                        stateKey,
                        stateOptions
                    );
                    break;
                }
                case QuestionType.date: {
                    answer = reduceAnswerIsValidWithContent<T>(
                        answer,
                        answers,
                        answer.value?.answer.dateContent as string,
                        state,
                        stateKey,
                        stateOptions
                    );
                    break;
                }
                case QuestionType.autoSuggest:
                case QuestionType.checkbox:
                case QuestionType.radio:
                case QuestionType.singleSelect:
                case QuestionType.textArea:
                case QuestionType.text: {
                    answer = reduceAnswerIsValidWithContent(
                        answer,
                        answers,
                        answer.value.answer.textContent as string,
                        state,
                        stateKey,
                        stateOptions
                    );
                    break;
                }
                case QuestionType.retention: {
                    answer = reduceAnswerIsValidWithContent(
                        answer,
                        answers,
                        answer.value.answer.jsonContent as RetentionJsonContentType,
                        state,
                        stateKey,
                        stateOptions
                    );
                    break;
                }
                default: {
                    throw new Error(`Unsupported question type: "${answer.value?.question.type}"`);
                }
            }
        }
    });

    return answers
        .sort((a, b): number => {
            // @ts-ignore
            return a._originalIndex - b._originalIndex;
        })
        .map((answer): Response<T> => omit(answer, ["_originalIndex"])) as Response<T>[];
};
