import { UserRole } from "src/permissions";
import { MessageType } from "src/components/survey/KaleRoutes";
import KaleContext from "src/components/KaleContext";
import { useTAFIdentifiersFromRoute } from "src/components/TAF/TAFDetails/hooks/useTAFIdentifiersFromRoute";
import { FETCH_TAF_ERROR } from "src/components/TAF/TAFDetails/constants";
import { useContext, useEffect, useRef, useState } from "react";
import { formatPageError } from "../utils";
import { QuestionTag } from "src/services/dynamic-questions";
import { KaleQuestion } from "src/services/KaleApplicationService";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { useMessageBannerActions } from "src/components/MessageBannerActions";

type TAFPageResources = Omit<StaticInfoState, "isFetchingStaticInfo"> &
    Omit<TAFDetailsState, "isFetchingTAFDetails"> & {
        isFetchingPageResources: boolean;
    };
export const useFetchPageResources = (): TAFPageResources => {
    const { isFetchingStaticInfo, ...staticInfo } = useFetchStaticInfoOnMount();
    const { isFetchingTAFDetails, ...tafDetails } = useFetchTAFDetailsOnRouteChange(staticInfo.kaleQuestion);

    const isFetchingPageResources = isFetchingTAFDetails || isFetchingStaticInfo;
    return { isFetchingPageResources, ...staticInfo, ...tafDetails };
};
interface StaticInfoState {
    userRole: UserRole | null;
    kaleQuestion: KaleQuestion;
    isFetchingStaticInfo: boolean;
}
const useFetchStaticInfoOnMount = (): StaticInfoState => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const { displayMessage } = useMessageBannerActions();

    const [userRole, setUserRole] = useState<UserRole | null>(null);

    const [kaleQuestion, setKaleQuestion] = useState<KaleQuestion>({
        reviews: [],
        dataStores: [],
        questionGroupInfoMap: {},
    });

    const { applicationName, reviewId: selectedReviewId } = useTAFIdentifiersFromRoute();

    const deps = { kaleAppService, displayMessage };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isFetchingUserRole, setIsFetchingUserRole] = useState<boolean>(true);
    const [isFetchingKaleQuestion, setIsFetchinKaleQuestion] = useState<boolean>(true);

    /**
     * Determines the appropriate userRole to assign to a user. If a user is viewing a previous revision,
     * they are assigned a readOnly role.
     */
    useEffect((): void => {
        const fetchUserRole = async (): Promise<void> => {
            setIsFetchingUserRole(true);
            const { kaleAppService, displayMessage } = dependenciesRef.current!;
            try {
                const [userRole, latestReviewId] = await Promise.all([
                    kaleAppService.fetchUserRole(applicationName),
                    kaleAppService.getLatestReviewID(applicationName),
                ]);
                if (`${latestReviewId}` !== selectedReviewId) {
                    // user is viewing a previous revision
                    setUserRole(UserRole.readOnly);
                } else {
                    setUserRole(userRole as UserRole);
                }
            } catch (err) {
                setUserRole(UserRole.readOnly);
                const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                displayMessage(MessageType.error, fetchTAFError);
            } finally {
                setIsFetchingUserRole(false);
            }
        };
        fetchUserRole();
    }, [applicationName, selectedReviewId]);

    useEffect((): void => {
        const fetchKaleQuestion = async (): Promise<void> => {
            setIsFetchinKaleQuestion(true);
            const { kaleAppService, displayMessage } = dependenciesRef.current!;
            let kaleQuestion: KaleQuestion;
            try {
                // Temporary duplicate view() call on the frontend until the Node backend does this logic
                // Can't use the actual view() call that happens on the TAF Page because Questions need to be
                // fetched/loaded before the view() call inside useFetchTAFOnRouteChange() can be made
                const appReviewGroup = (await kaleAppService.view(applicationName))?.appInfo?.review?.reviewGroup;
                // Fetch only questions that have the TAF tag on them
                kaleQuestion = await kaleAppService.fetchQuestions(QuestionTag.tafView, appReviewGroup);
            } catch (err) {
                kaleQuestion = { reviews: [], dataStores: [], questionGroupInfoMap: {} };
                const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                displayMessage(MessageType.error, fetchTAFError);
            }

            setKaleQuestion(kaleQuestion);
            setIsFetchinKaleQuestion(false);
        };
        fetchKaleQuestion();
    }, [applicationName]);

    const isFetchingStaticInfo = isFetchingUserRole || isFetchingKaleQuestion;
    return { isFetchingStaticInfo, userRole, kaleQuestion };
};

export type TAFDetailsFromServer = SurveyResponse | null;
interface TAFDetailsState {
    tafDetailsFromServer: TAFDetailsFromServer;
    setTAFDetailsFromServer: (details: TAFDetailsFromServer) => void;
    isFetchingTAFDetails: boolean;
}

const useFetchTAFDetailsOnRouteChange = (questions: KaleQuestion): TAFDetailsState => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);
    const { displayMessage } = useMessageBannerActions();

    const { applicationName, reviewId } = useTAFIdentifiersFromRoute();

    const [tafDetailsFromServer, setTAFDetailsFromServer] = useState<SurveyResponse | null>(null);

    const deps = { kaleAppService, displayMessage };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isFetchingTAFDetails, setIsFetchingTAFDetails] = useState<boolean>(true);

    useEffect((): void => {
        const fetchTAF = async (): Promise<void> => {
            setIsFetchingTAFDetails(true);
            const { kaleAppService, displayMessage } = dependenciesRef.current!;
            let tafDetails: SurveyResponse | null;
            try {
                tafDetails = await kaleAppService.fetchAppByReviewId(applicationName, reviewId);
            } catch (err) {
                tafDetails = null;
                const fetchTAFError = formatPageError(FETCH_TAF_ERROR, (err as Error).message);
                displayMessage(MessageType.error, fetchTAFError);
            }

            setTAFDetailsFromServer(tafDetails);
            setIsFetchingTAFDetails(false);
        };
        if (questions.reviews.length > 0) {
            fetchTAF();
        }
    }, [applicationName, reviewId, questions.reviews.length]);

    return { isFetchingTAFDetails, tafDetailsFromServer, setTAFDetailsFromServer };
};
