import React from "react";
import { ExampleValuesQuestion } from "src/components/TableDetails/FieldsTable/TableCell";
import { TableCellProps } from "src/components/TableDetails/FieldsTable/TableCell/types";
import { MemoizedTableCell } from "src/components/TableDetails/FieldsTable/TableCell/MemoizedTableCell";
import { SyntheticFieldIds } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";

/**
 * Renders a field question as an inline-editable component.
 */
const TableCell = ({ cellState, rowData }: TableCellProps): JSX.Element => {
    const questionShortId = cellState.question.shortId;

    if (questionShortId === SyntheticFieldIds.FieldExampleValues) {
        return <ExampleValuesQuestion cellState={cellState} rowData={rowData} />;
    }

    // Can only memoize if the cell doesn't depend on rowData, as rowData will
    // be new any time ANY cell in the row changes or is updated
    return <MemoizedTableCell {...cellState} />;
};

export { TableCell };
