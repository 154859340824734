import { AnswerBase, AnswerWithQuestion, ParentConstraintBase, QuestionBase } from "src/answers_legacy";

export type TableAnswerQuestion = QuestionBase & {
    complianceTypeId: number;
    complianceType: string;
    groupType?: string;
};

export type TableQuestion = TableAnswerQuestion & ParentConstraintBase;

interface TableAnswerBase extends AnswerBase {
    complianceType?: string;
}

export enum TableAnswerKey {
    tableId = "tableId",
    userTableId = "userTableId",
    groupType = "groupType",
}

export interface TableAnswer extends TableAnswerBase {
    [TableAnswerKey.groupType]?: string;
    [TableAnswerKey.tableId]?: number;
    [TableAnswerKey.userTableId]?: string;
}

export type TableAnswerWithQuestion = AnswerWithQuestion<TableQuestion, TableAnswer>;

export function DefaultTableAnswer(
    questionId: number,
    questionShortId: string,
    tableId?: number,
    userTableId?: string
): TableAnswer {
    return {
        questionId,
        questionShortId,
        tableId,
        userTableId,
    };
}
