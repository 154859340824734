export enum OperationStatus {
    Success = "Success",
    Warning = "Warning",
    Error = "Error",
    Exception = "Exception",
}

export enum ResponseCategory {
    Success = "Success",
    Rejected = "Rejected",
    Unauthorized = "Unauthorized",
    NotFound = "NotFound",
    BadRequest = "BadRequest",
    Unknown = "Unknown",
    Accepted = "Accepted",
}

export interface ValidationIdentifier {
    pointer: string;
    parameterName: string;
}

export interface ValidationMessage {
    category: ResponseCategory;
    message: string;
    source?: ValidationIdentifier;
}

export interface ValidationMessages {
    messages: ValidationMessage[];
    category: ResponseCategory;
}

export interface GenericResponse<T> {
    result: T;
    status: OperationStatus;
    messages: ValidationMessages;
    error?: Error | null;
}
