import React from "react";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { DisplayLabels } from "src/components/survey/AccessControl/section/util";
import { TokenGroup, TokenGroupProps } from "@amzn/awsui-components-react-v3/polaris";
import { TEST_IDS } from "shared/fields";
import { Box } from "@amzn/awsui-components-react-v3";

export const relatedBindle = (application: SurveyResponse): Pair => {
    const items = application.appInfo.review.relatedBindles.map((bindle): TokenGroupProps.Item => {
        return { label: bindle, disabled: true };
    });
    return {
        label: DisplayLabels.RELATED_BINDLES,
        testId: TEST_IDS.ACCESS_CONTROL_SECTION.RELATED_BINDLES,
        value:
            items.length > 0 ? (
                <TokenGroup
                    limit={3}
                    items={items}
                    i18nStrings={{ limitShowMore: "Show more", limitShowFewer: "Show less" }}
                />
            ) : (
                <Box variant="span">&mdash;</Box>
            ),
    };
};
