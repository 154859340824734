import { INCOMPLETE_WIZARD_FIELD_ERROR } from "src/components/survey/DataStoreInfo/DataStoreWizard/constants";
import { FormField, Input } from "@amzn/awsui-components-react-v3";
import React, { useState } from "react";
import { CollectDataStoreInfoProps } from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo";

type DataStoreNameProps = Pick<CollectDataStoreInfoProps, "isRequired"> & {
    onBlur: (newValue: string) => void;
    value: string;
    disabled?: boolean;
    "data-testid": string;
};

const DataStoreName = (props: DataStoreNameProps): JSX.Element => {
    const { value: initialValue, disabled = false, isRequired, "data-testid": testId, onBlur: onBlurCb } = props;
    // The current local value of the data store name input.
    // The data store name input updates parent components with its local value during its Blur event.
    const [localDataStoreNameValue, setLocalDataStoreNameValue] = useState<string>(initialValue);

    return (
        <div>
            <FormField
                id={"data-store-name-label"}
                label={"Name"}
                errorText={isRequired && !Boolean(localDataStoreNameValue) ? INCOMPLETE_WIZARD_FIELD_ERROR : ""}
            >
                <Input
                    data-testid={testId}
                    value={localDataStoreNameValue}
                    onBlur={(): void => {
                        // eslint-disable-next-line
                        setTimeout((): void => onBlurCb(localDataStoreNameValue), 0);
                    }}
                    disabled={disabled}
                    onChange={(e): void => {
                        setLocalDataStoreNameValue(e.detail.value);
                    }}
                />
            </FormField>
        </div>
    );
};

export default DataStoreName;
