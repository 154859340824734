import { TokenGetter } from "src/services/CognitoService";
import { FeatureToggleConfig, KaleConfig } from "src/Config";
import { KaleError } from "src/services/KaleApplicationService";
import { appendRequestID } from "src/services/AppendRequestID";
import { FeatureToggles } from "src/services/FeatureToggleService";

export enum ReviewGroup {
    accounting = "Accounting",
    fraud = "CTPS",
}

interface ValidationSource {
    pointer: string;
    parameterName: string;
}

interface ValidationMessage {
    status: string;
    message: string;
    source?: ValidationSource;
}

export interface ValidationResult {
    messages: ValidationMessage[];
    category: string;
}

/**
 * This is a generic response structure followed by Node server.
 * All operation except some GET endpoints follow this generic structure.
 * Since we cannot directly consume generated typescript client from smithy model we need to mimic the structure here.
 */
export interface OperationResult<T> {
    result: T;
    status: string;
    messages: ValidationResult;
    error?: Error | null;
    unwrapResult?: boolean;
}

// This is a base class, not meant to be instantiated
class AbstractKaleService {
    protected readonly baseApiEndpoint: string;
    protected readonly baseNodeApiEndpoint: string;
    protected readonly features: FeatureToggles;
    private readonly accessTokenGetter: TokenGetter;

    protected constructor(
        { apiEndpoint, nodeAPIEndpoint, features }: KaleConfig & FeatureToggleConfig,
        accessTokenGetter: TokenGetter
    ) {
        if (new.target === AbstractKaleService) {
            throw new TypeError("Cannot construct AbstractKaleService instances directly");
        }

        this.baseApiEndpoint = apiEndpoint;
        this.baseNodeApiEndpoint = nodeAPIEndpoint;
        this.accessTokenGetter = accessTokenGetter;
        this.features = features;

        this.signedFetch = this.signedFetch.bind(this);
    }

    protected signedFetch(
        method: string,
        apiEndPoint: string,
        body?: string | FormData,
        abortController?: AbortController
    ): Promise<Response> {
        return this.accessTokenGetter().then((accessToken): Promise<Response> => {
            return fetch(apiEndPoint, {
                mode: "cors",
                method,
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                    authorization: accessToken,
                },
                body,
                signal: abortController?.signal,
            });
        });
    }

    protected signedFetchFileUpload(method: string, apiEndPoint: string, body?: FormData): Promise<Response> {
        return this.accessTokenGetter().then((accessToken): Promise<Response> => {
            return fetch(apiEndPoint, {
                mode: "cors",
                method,
                headers: {
                    accept: "application/json",
                    authorization: accessToken,
                },
                body,
            });
        });
    }

    protected handleKaleResponse<T>(response: Response, statusCode: number, errPrefix: string): Promise<T> {
        if (response.status === statusCode) {
            return response?.json().then((result: T): T => {
                return result;
            }, this.buildDefaultRejection());
        } else {
            return response?.json().then((error: KaleError): T => {
                throw Error(appendRequestID(`${errPrefix}: Error: ${error.errorMsg}`, response));
            }, this.buildDefaultRejection());
        }
    }

    protected buildDefaultRejection(): (error: any) => any {
        return (error: any): any => {
            throw Error("Sorry, we got an error: " + JSON.stringify(error, Object.getOwnPropertyNames(error)));
        };
    }
}

export default AbstractKaleService;
