import { Box, HelpPanel, Link } from "@amzn/awsui-components-react-v3";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import React from "react";
import SpaceBetween from "@amzn/awsui-components-react-v3/polaris/space-between";
import { LINKS } from "src/components/ExternalLinks";

export const AAAApplicationHelpContent = () => (
    <HelpPanel
        footer={
            <SpaceBetween size={"s"}>
                <Link external={true} href={LINKS.PRIVACY_WIKI}>
                    Kale Wiki
                </Link>
            </SpaceBetween>
        }
        header={<Header variant={"h2"}>AAA Applications</Header>}
    >
        <React.Fragment>
            <Header variant={"h3"}>
                If no AAA Id(s) are present, ensure all of the following requirements are met:
            </Header>
            <Box variant={"p"}>
                <ul>
                    <li>You have selected a control or related bindle(s) in the dropdowns above, and</li>
                    <li>You are a member of a team that owns a bindle, and</li>
                    <li>You are using a supported browser (FireFox and Chrome), and</li>
                    <li>Your browser is not blocking third-party cookies and site data.</li>
                </ul>
            </Box>
            <Header variant={"h3"}>Why are only certain AAA IDs auto-selected and disabled?</Header>
            <Box variant={"p"}>
                <ul>
                    <li>
                        Based on your selected control and related bindle(s), Kale will auto-select associated AAA IDs
                        <i>
                            {" "}
                            (This selection cannot be modified as it is directly related to your bindle selection(s) in
                            the previous steps)
                        </i>
                    </li>
                    <li>
                        These AAA IDs can be identified using the <b>&quot;Recommended&quot;</b> tag in dropdown options
                    </li>
                </ul>
            </Box>
        </React.Fragment>
    </HelpPanel>
);
