import { Link, MultiselectProps, WizardProps } from "@amzn/awsui-components-react-v3";
import { WithFunctionalReadonlyRelatedBindle } from "src/components/survey/ApplicationPermissions";
import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { AccessControlWizardStep } from "src/components/survey/AccessControl/AccessControlWizard";
import { ValueDictionary } from "src/services/IncrementalSave";
import { AccessControlContext } from "src/components/survey/AccessControl/AccessControlContext";
import { RelatedBindleHelpContent } from "src/components/survey/AccessControl/helpPanel/RelatedBindleHelpContent";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { MessageType } from "src/components/survey/KaleRoutes";
import { TEST_IDS } from "shared/survey/accessControlWizrad";
import { REVIEW } from "src/components/DataDictionary";

export const useRelatedBindleStep = (): AccessControlWizardStep => {
    const {
        service: {
            kaleAppService: { listRelatedBindles, incrementalSave },
        },
    } = useContext(KaleContext);
    const { application, isReadOnly, setIsHelpPanelOpen } = useContext(AccessControlContext);
    const { displayMessage } = useMessageBannerActions();
    const helpPanelContent = React.useMemo(() => <RelatedBindleHelpContent />, []);
    const deps = { listRelatedBindles, incrementalSave, application, displayMessage };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const [bindles, setBindles] = useState<MultiselectProps.Option[]>([]);
    const [selectedBindles, setSelectedBindles] = useState<string[]>([]);
    const [valueDictionary, setValueDictionary] = useState<ValueDictionary>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        (async function IIFE(): Promise<void> {
            const { listRelatedBindles, application, displayMessage } = depsRef.current;
            try {
                setIsLoading(true);
                const appName = decodeURIComponent(application.appInfo.applicationName);
                const bindles = await listRelatedBindles(appName);
                const options = bindles.map(({ bindle: { name, recommended } }): MultiselectProps.Option => {
                    const selected = application.appInfo.review.relatedBindles.includes(name);
                    const tags = [];

                    if (recommended) tags.push("Recommended");
                    if (selected) tags.push("Previously selected");

                    return { value: name, label: name, tags: tags };
                });
                setBindles(options);
                const recommendedFromVeritas = bindles
                    .filter((bindleWithOwningTeam): boolean => bindleWithOwningTeam.bindle.recommended)
                    .map((bindleWithOwningTeam): string => bindleWithOwningTeam.bindle.name);
                // Auto select recommended bindles and any bindles previously selected by users
                setSelectedBindles(
                    Array.from(new Set(recommendedFromVeritas.concat(application.appInfo.review.relatedBindles)))
                );
            } catch (e) {
                console.error(e);
                displayMessage(MessageType.error, (e as Error).message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        setValueDictionary({ [REVIEW.RELATED_BINDLES]: selectedBindles });
    }, [selectedBindles]);

    const logMetrics = (userSelectedBindles: string[]): void => {
        const added: string[] = [];
        const removed: string[] = [];
        bindles.forEach((bindle): void => {
            if (bindle.label && bindle.tags?.length === 0 && userSelectedBindles.includes(bindle.label)) {
                added.push(bindle.label);
            }
            if (bindle.label && bindle.tags?.includes("Recommended") && !userSelectedBindles.includes(bindle.label)) {
                removed.push(bindle.label);
            }
        });
        const appName = application.appInfo.applicationName;
        if (added.length > 0) {
            console.info(`Added bindle(s) that are not recommended by Veritas for ${appName}: ${added}`);
        }
        if (removed.length > 0) {
            console.info(`Removed bindle(s) that are recommended by Veritas for ${appName}: ${removed}`);
        }
    };

    const step: WizardProps.Step = {
        title: "Confirm your related bindles",
        info: <Link onFollow={() => setIsHelpPanelOpen(true)}>info</Link>,
        content: (
            <WithFunctionalReadonlyRelatedBindle
                id={"relatedBindles"}
                data-testid={TEST_IDS.WIZARD.STEPS.RELATED_BINDLE.ROOT}
                isReadonly={isReadOnly}
                options={bindles}
                expectedAnswer={selectedBindles}
                isLoading={isLoading}
                onChangeCallback={(result) => {
                    setSelectedBindles(result.response);
                    logMetrics(result.response);
                }}
            />
        ),
    };

    return { step, isLoading, valueDictionary, helpPanelContent };
};
