import * as React from "react";
import { useEffect, useState } from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { FormField, Textarea } from "@amzn/awsui-components-react-v3/polaris";

interface Props extends FunctionalSurveyItemProps<string> {
    showErrors?: boolean;
}

const NewTextArea = (props: Props): JSX.Element => {
    const {
        id,
        isRequired,
        isFormInReadonlyMode = false,
        expectedAnswer = "",
        onChangeCallback,
        showErrors = null,
    } = props;

    const [localValue, setLocalValue] = useState<string>(expectedAnswer);

    useEffect((): void => {
        // Always overwrite local state when prop changes.
        setLocalValue(expectedAnswer);
    }, [expectedAnswer]);

    // Show error only when last updated timestamp is set. Backwards compatible.
    const hasChanged = showErrors === null || showErrors;

    return (
        <FormField stretch={true} errorText={hasChanged && isRequired && !Boolean(expectedAnswer) ? "Required" : ""}>
            <div>
                <Textarea
                    id={id}
                    disabled={props.isReadonly ?? isFormInReadonlyMode}
                    value={localValue}
                    onChange={(e): void => {
                        setLocalValue(e.detail.value);
                    }}
                    onBlur={(): void => {
                        if (expectedAnswer !== localValue) {
                            // Only trigger an expensive global state update
                            // if the user changed the value
                            onChangeCallback?.call(null, {
                                id: id,
                                response: localValue,
                            });
                        }
                    }}
                />
            </div>
        </FormField>
    );
};

export default withFunctionalReadonly(NewTextArea);
