/**
 * Lifted from https://code.amazon.com/packages/AWS-UI-Components/blobs/mainline-3.0/--/src/internal
 * These are useful and referred internally by polaris components but not exposed externally.
 */
export type NonCancelableEventHandler<Detail> = (event: NonCancelableCustomEvent<Detail>) => void;
export type NonCancelableCustomEvent<DetailType> = Omit<CustomEvent<DetailType>, "preventDefault">;

class CustomEventStub<T> {
    public defaultPrevented: boolean;
    public cancelBubble: boolean;
    public cancelable: boolean;
    public detail: T | null;

    constructor(cancelable = false, detail = null) {
        this.cancelable = cancelable;
        this.detail = detail;
        this.defaultPrevented = false;
        this.cancelBubble = false;
    }

    preventDefault() {
        this.defaultPrevented = true;
    }

    stopPropagation() {
        this.cancelBubble = true;
    }
}

export function createCustomEvent<T>({ cancelable, detail }: CustomEventInit<T>): CustomEvent<T> {
    return new CustomEventStub(cancelable, detail as any) as CustomEvent;
}

export function fireNonCancelableEvent<T = null>(handler: NonCancelableEventHandler<T> | undefined, detail?: T) {
    if (!handler) {
        return;
    }
    const event = createCustomEvent({ cancelable: false, detail });
    handler(event);
}
