import React, { useContext } from "react";
import ClearableSelect, {
    ClearableSelectChangeEvent,
    ClearableSelectOption,
} from "src/components/fields/select/ClearableSelect";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/index";
import { QuestionTag, QuestionColorOverrides } from "src/services/dynamic-questions";
import { AnswerBase, HookAnswer, QuestionBase, QuestionChoice } from "src/answers_legacy";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { PLACEHOLDER_TEXT } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/constants";

type SelectQuestionOptionWithServerAttributes = ClearableSelectOption & {
    colors?: QuestionColorOverrides;
};

type SelectQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
} & FormQuestionForwardingProps;

export const SelectQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    props: SelectQuestionProps<QType, AType>
): JSX.Element => {
    const {
        "data-testid": dataTestId,
        questionAnswer: {
            value,
            setValue,
            question: { choices, tags },
        },
    } = props;

    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;

    const makeSelectOptionFromChoice = ({
        value: optionValue,
        description,
        label,
        ...serverAttributes
    }: QuestionChoice): SelectQuestionOptionWithServerAttributes => ({
        // Superset of Polaris v3 option shape.
        value: optionValue,
        description,
        label: label || optionValue,
        ...serverAttributes,
    });

    const selectedChoice = choices.find((choice: any): boolean => choice.value === value) ?? null;
    const selectedOption = selectedChoice ? makeSelectOptionFromChoice(selectedChoice) : null;
    const options: SelectQuestionOptionWithServerAttributes[] = choices.map(makeSelectOptionFromChoice);

    const isClearable = !tags.includes(QuestionTag.selectIsNotClearable);

    return (
        <ClearableSelect
            data-testid={dataTestId}
            colors={selectedOption?.colors}
            isClearable={isClearable}
            disabled={isReadonly}
            selectedOption={selectedOption}
            options={options}
            placeholder={PLACEHOLDER_TEXT}
            onChange={({ detail: { selectedOption } }: ClearableSelectChangeEvent): void => {
                // The event will contain null selectedOption if selection was cleared by the user.
                // Translate a null selectedOption to an empty string value before calling setValue
                setValue(selectedOption?.value ?? "");
            }}
            filteringType={"auto"}
        />
    );
};
