import { Container, Header, TabsProps } from "@amzn/awsui-components-react-v3";
import { default as React, useMemo } from "react";
import { groupBy } from "lodash";
import { FormQuestionsPane } from "src/components/TableDetails/FormQuestionsPane/FormQuestionsPane";
import { useFetchQuestionsOnMount } from "src/components/survey/BulkEdit/hooks/useFetchQuestionsOnMount";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useBulkEditKaleTable } from "src/answers_legacy/hooks/bulkEditKaleTable";
import { useBulkEditKaleTableFields } from "src/answers_legacy/hooks/bulkEditKaleTableFields";
import { useWindowDimensions } from "src/components/survey/hooks/useWindowDimensions";

interface Props {
    dataStore: DataStoreResponse | null;
}

export const useMakeQuestionTabs = (
    props: Props
): { isLoading: boolean; tabs: TabsProps.Tab[]; error: React.ReactNode } => {
    const { dataStore } = props;
    const [tableAccessors] = useBulkEditKaleTable();
    const [fieldAccessors] = useBulkEditKaleTableFields();
    const { width } = useWindowDimensions();
    const { isLoading, questions, error } = useFetchQuestionsOnMount(dataStore);

    const tabs = useMemo((): TabsProps.Tab[] => {
        if (questions.table.length === 0 || questions.field.length === 0) {
            return [];
        }
        const combinedQuestions = [
            ...questions.table.filter((question): boolean => question.id !== -1),
            ...questions.field,
        ];
        const groupedQuestions = groupBy(combinedQuestions, "complianceType");
        return Object.keys(groupedQuestions).map((complianceType): TabsProps.Tab => {
            const tableQuestions = tableAccessors.answers.filter((accessor): boolean => {
                return accessor.question.complianceType === complianceType;
            });
            const fieldQuestions = fieldAccessors.answers.filter((accessor): boolean => {
                return accessor.question.complianceType === complianceType;
            });

            return {
                id: complianceType,
                label: complianceType,
                content: (
                    <React.Fragment>
                        {tableQuestions.length > 0 && (
                            <Container header={<Header>Common table questions</Header>}>
                                <FormQuestionsPane
                                    questionAnswers={tableQuestions}
                                    numColumns={Math.round((width * 0.5) / 500)}
                                />
                            </Container>
                        )}
                        {fieldQuestions.length > 0 && (
                            <Container header={<Header>Common field questions</Header>}>
                                <FormQuestionsPane
                                    questionAnswers={fieldQuestions}
                                    numColumns={Math.round((width * 0.5) / 500)}
                                />
                            </Container>
                        )}
                    </React.Fragment>
                ),
            };
        });
    }, [fieldAccessors.answers, questions.field, questions.table, tableAccessors.answers, width]);

    return { isLoading, tabs, error };
};
