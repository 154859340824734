import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import KaleContext from "src/components/KaleContext";
import { useLocation } from "react-router-dom";
import {
    Alert,
    AppLayout,
    BreadcrumbGroupProps,
    Button,
    Container,
    DatePicker,
    FormField,
    Header,
    SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import { AuditLogResponse, AuditLogService, LogItem, LogItemKey } from "src/services/AuditLogService";
import { kaleUrls } from "src/util/Urls";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";

export interface EntityIdentifiers {
    entityName: string;
    entityId: string;
}

export const useEntityIdentifiersFromRoute = (): EntityIdentifiers => {
    const { search } = useLocation();

    return useMemo((): EntityIdentifiers => {
        const queryParams = new URLSearchParams(search);
        return {
            entityName: queryParams.get("entityName") || "",
            entityId: queryParams.get("entityId") || "",
        };
    }, [search]);
};

const BreadcrumbContainer = styled.div`
    margin-bottom: 1em;
    display: block;
`;

const TableContainer = styled.div`
    margin-top: 1em;
`;

const AlertContent = styled.div`
    padding-bottom: 1em;
`;

const StyledButton = styled(Button)`
    margin: 1em 0 5em;
    float: right;
`;

const renderBreadCrumbs = (entityIdentifiers: EntityIdentifiers, reviewId: string): JSX.Element => {
    const entityBreadcrumbItem: BreadcrumbGroupProps.Item = {
        text: entityIdentifiers.entityId,
        href: kaleUrls.editKaleRecordUrl(entityIdentifiers.entityId, reviewId),
    };
    const currentBreadcrumbItem: BreadcrumbGroupProps.Item = {
        text: "Audit History",
        href: "#",
    };
    const items = [BreadcrumbItems.KALE_LANDING_PAGE, entityBreadcrumbItem, currentBreadcrumbItem];

    return (
        <BreadcrumbContainer>
            <KaleBreadcrumbGroup ariaLabel="Breadcrumbs" items={items} />
        </BreadcrumbContainer>
    );
};

interface AuditLogTableProps {
    result: AuditLogResponse;
    isLoading: boolean;
    startDate: Date;
    endDate: Date;
    onStartDateChange: (e: any) => void;
    onEndDateChange: (e: any) => void;
}

export const getDateInputFormat = (date: Date): string => {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
};

export const startOfDate = (date: Date): Date => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

const AuditLogTable = (props: AuditLogTableProps): JSX.Element => {
    const { onStartDateChange, onEndDateChange, startDate, endDate, isLoading } = props;
    const columnDefinitions = [
        {
            id: LogItemKey.logType,
            header: "Log type",
            cell: (item: LogItem): string => item[LogItemKey.logType],
            sortingField: LogItemKey.logType,
        },
        {
            id: LogItemKey.entityType,
            header: "Entity type",
            cell: (item: LogItem): string => item[LogItemKey.entityType],
            sortingField: LogItemKey.entityType,
        },
        {
            id: LogItemKey.fieldName,
            header: "Field name",
            cell: (item: LogItem): string => item[LogItemKey.fieldName],
            sortingField: LogItemKey.fieldName,
        },
        {
            id: LogItemKey.oldValue,
            header: "Old value",
            cell: (item: LogItem): string => item[LogItemKey.oldValue],
        },
        {
            id: LogItemKey.newValue,
            header: "New value",
            cell: (item: LogItem): string => item[LogItemKey.newValue],
        },
        {
            id: LogItemKey.isFinanciallyRelevant,
            header: "Financially relevant?",
            cell: (item: LogItem): string => item[LogItemKey.isFinanciallyRelevant],
            sortingField: LogItemKey.isFinanciallyRelevant,
        },
        {
            id: LogItemKey.updatedAt,
            header: "Updated at",
            cell: (item: LogItem): string => (item.updatedAt && new Date(item.updatedAt).toLocaleString()) || "",
            sortingField: LogItemKey.updatedAt,
        },
        {
            id: LogItemKey.updatedBy,
            header: "Updated by",
            cell: (item: LogItem): string => item[LogItemKey.updatedBy],
            sortingField: LogItemKey.updatedBy,
        },
    ];

    const rowItems = props.result.logs || [];

    const filterKeys = new Array<string>(
        LogItemKey.logType,
        LogItemKey.entityType,
        LogItemKey.fieldName,
        LogItemKey.isFinanciallyRelevant,
        LogItemKey.updatedBy
    );

    return (
        <div data-testid={"audit-history"}>
            <Container header={<Header variant="h2">Audit Dates</Header>}>
                <SpaceBetween size={"xxl"} direction={"horizontal"}>
                    <FormField stretch={true} className={"justify"} label={"Start Date"}>
                        <DatePicker
                            data-testid={"audit-history-start-date"}
                            nextMonthAriaLabel={"Next month"}
                            previousMonthAriaLabel={"Previous month"}
                            todayAriaLabel={"Today"}
                            placeholder="YYYY/MM/DD"
                            value={getDateInputFormat(startDate)}
                            onChange={(e): void => onStartDateChange(e)}
                        />
                    </FormField>
                    <FormField stretch={true} className={"justify"} label={"End Date"}>
                        <DatePicker
                            data-testid={"audit-history-end-date"}
                            nextMonthAriaLabel={"Next month"}
                            previousMonthAriaLabel={"Previous month"}
                            todayAriaLabel={"Today"}
                            value={getDateInputFormat(endDate)}
                            onChange={(e): void => onEndDateChange(e)}
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
            <TableContainer>
                <DASTable<LogItem>
                    id={"audit-history-table"}
                    tableName={"Audit History"}
                    filterProps={{
                        type: DASTableFilterType.propertyFilter,
                        filterKeys,
                        placeholder: "Filter log history",
                    }}
                    isLoading={isLoading}
                    isResizable={true}
                    isStickyHeader={true}
                    columnDefinitions={columnDefinitions}
                    rowItems={rowItems}
                />
            </TableContainer>
        </div>
    );
};

const MONTH_IN_DAYS = 30;
const DEFAULT_RESULT: AuditLogResponse = { entityId: "", entityType: "", logs: [] };

const AuditHistory = (): JSX.Element => {
    const {
        service: { auditLogService },
    } = useContext(KaleContext);

    const { reviewId } = useAppParamsFromRoute();

    const [result, setResult] = useState<AuditLogResponse>(DEFAULT_RESULT);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const now = new Date();

    const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(now.getDate() - MONTH_IN_DAYS)));
    const [endDate, setEndDate] = useState<Date>(now);

    const { entityName, entityId } = useEntityIdentifiersFromRoute();

    const auditLogServiceRef = useRef<AuditLogService>();
    auditLogServiceRef.current = auditLogService;

    useEffect((): void => {
        if (startDate > endDate) {
            return;
        }
        setIsLoading(true);
        (async function (): Promise<void> {
            try {
                const result = await auditLogServiceRef.current?.getEntity(entityName, encodeURIComponent(entityId), {
                    from: startOfDate(startDate),
                    to: endDate,
                    recursive: true,
                });
                setResult(result || DEFAULT_RESULT);
            } catch (err) {
                console.error(err);
                setHasError(true);
            }
            setIsLoading(false);
        })();
    }, [startDate, endDate, entityName, entityId]);

    return (
        <AppLayout
            headerSelector="#top-nav"
            breadcrumbs={renderBreadCrumbs({ entityName: result.entityType, entityId: result.entityId }, reviewId)}
            navigationHide={true}
            toolsHide={true}
            toolsOpen={false}
            content={
                <React.Fragment>
                    {!hasError && startDate > endDate && (
                        <AlertContent>
                            <Alert header="Invalid date range" type="error">
                                Start date cannot be greater than the end date.
                            </Alert>
                        </AlertContent>
                    )}
                    {hasError && (
                        <AlertContent>
                            <Alert header="Failed to load audit history" type="error">
                                Please try again later.
                            </Alert>
                        </AlertContent>
                    )}
                    <AuditLogTable
                        result={result}
                        isLoading={isLoading}
                        startDate={startDate}
                        endDate={endDate}
                        onStartDateChange={(e): void => setStartDate(new Date(e.detail.value))}
                        onEndDateChange={(e): void => setEndDate(new Date(e.detail.value))}
                    />
                    <StyledButton variant={"link"} href={kaleUrls.editKaleRecordUrl(entityId, reviewId)}>
                        Return to Application
                    </StyledButton>
                </React.Fragment>
            }
        />
    );
};

export default AuditHistory;
