import {
    FieldAnswer,
    FieldRecord,
    KaleTablesService,
    NewField,
    TableAnswer,
    TableDetails,
    TableIdentifiers,
    UpdateTableRequestBody,
    UpdateTableResponseBody,
} from "src/services/KaleTablesService";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { OnSaveCb } from "src/components/TableDetails/TableDetailsForm/types";
import {
    KaleTableFieldPayload,
    KaleTablePayload,
    TableFieldAnswerKey,
    useKaleTableFieldsPayloads,
    useKaleTablePayload,
} from "src/answers_legacy";
import React, { useCallback, useContext, useRef } from "react";
import KaleContext from "src/components/KaleContext";
import { useTableIdentifiersFromRoute } from "src/components/TableDetails/TableDetailsPage/hooks";
import { formatPageError } from "src/components/TableDetails/utils";
import { UPDATE_TABLE_ERROR } from "src/components/TableDetails/constants";
import {
    reduceTableAnswers,
    reduceFieldAnswers,
} from "src/components/TableDetails/TableDetailsPage/synthetic-questions";

export type SetTableDetailsCb = (tableDetails: TableDetails | null) => void;

const makeRequestBodyFromState = (
    fieldsStatePayloads: KaleTableFieldPayload[],
    tableStatePayload: KaleTablePayload,
    tableDetailsFromServer: TableDetails
): UpdateTableRequestBody => {
    const { table, fields } = tableDetailsFromServer;

    // Prepare Table & Table Answers
    const syntheticTableRecord = reduceTableAnswers(tableStatePayload as TableAnswer[]);
    const newTable = {
        ...table,
        ...syntheticTableRecord,
    };

    // Fixing a bug where deleting a middle field would make it so that all the fields after that middle field will
    // cascade down and take over the fieldID of the previous field to them. It should instead fully remove any
    // occurrence of the middle field, and let the fields after the deleted-middle field, to keep their fieldIDs the
    // same due to field deletions, "fieldsStatePayloads" may have N-1 when "fields" may have N. And in order to easily
    // support multiple field deletions (not a feature yet). Will keep a separate index for "fieldsStatePayloads" so
    // that any number of deleted fields can be skipped while saving, while still pointing to the correct non-deleted
    // field within the "fieldsStatePayloads"
    let fieldStateIndex = 0;
    // Prepare Fields and Fields' Answers
    const newFields = fields
        .map((fieldRecord): FieldRecord | undefined => {
            if (!fieldsStatePayloads[fieldStateIndex]) {
                return;
            }
            const fieldPayload = fieldsStatePayloads[fieldStateIndex];
            const syntheticFieldRecord = reduceFieldAnswers(fieldPayload as FieldAnswer[]);
            // The 0th index is field name which is required to be answered, so it will always be present
            if (fieldPayload && fieldPayload[0] && fieldPayload[0][TableFieldAnswerKey.fieldId] !== fieldRecord.id) {
                return;
            }
            fieldStateIndex++;
            const newField = {
                ...fieldRecord,
                ...syntheticFieldRecord,
            };
            return newField;
        })
        .filter((field): boolean => !!field) as FieldRecord[];

    return {
        table: newTable,
        fields: newFields,
    };
};

interface UpdateTableResult {
    responseBody: UpdateTableResponseBody | null;
    updateError: React.ReactNode;
}
export const updateTable = async (
    tableIdentifiers: TableIdentifiers,
    requestBody: UpdateTableRequestBody,
    kaleTablesService: KaleTablesService
): Promise<UpdateTableResult> => {
    let responseBody: UpdateTableResponseBody | null;
    let updateError: React.ReactNode;

    try {
        responseBody = await kaleTablesService.updateTable(tableIdentifiers, requestBody);
        updateError = "";
    } catch (err) {
        responseBody = null;
        updateError = formatPageError(UPDATE_TABLE_ERROR, (err as Error).message);
    }
    return { responseBody, updateError };
};

/**
 * Builds the updateTable API request body, calls the updateTable API, and stores the new TableDetails response.
 */
export const saveTableDetails = async (
    tableDetailsFromServer: TableDetails | null,
    setTableDetailsCb: SetTableDetailsCb,
    tableStatePayload: KaleTablePayload,
    fieldsStatePayloads: KaleTableFieldPayload[],
    tableIdentifiers: TableIdentifiers,
    kaleTablesService: KaleTablesService,
    displayMessage: DisplayMessageCb,
    newFields: NewField[] = []
): Promise<void> => {
    if (tableDetailsFromServer) {
        // Adopt data from Global State
        const requestBodyFromState = makeRequestBodyFromState(
            fieldsStatePayloads,
            tableStatePayload,
            tableDetailsFromServer
        );
        // Adopt new fields to be created
        const requestBody = {
            ...requestBodyFromState,
            fields: [...requestBodyFromState.fields, ...newFields],
        };

        const { updateError, responseBody } = await updateTable(tableIdentifiers, requestBody, kaleTablesService);

        if (updateError) {
            displayMessage(MessageType.error, updateError);
        } else if (responseBody) {
            const newTableDetailsFromServer: TableDetails = {
                ...tableDetailsFromServer,
                ...responseBody,
            };
            setTableDetailsCb(newTableDetailsFromServer);
        }
    }
};

export const useMakeOnSaveCb = (
    tableDetailsFromServer: TableDetails | null,
    setTableDetailsCb: SetTableDetailsCb,
    displayMessage: DisplayMessageCb
): OnSaveCb => {
    // Read payloads from global state
    const tableStatePayload = useKaleTablePayload();
    const fieldsStatePayloads = useKaleTableFieldsPayloads();
    // Read route params
    const tableIdentifiers = useTableIdentifiersFromRoute();
    const {
        service: { kaleTablesService },
    } = useContext(KaleContext);

    const deps = {
        displayMessage,
        kaleTablesService,
        tableDetailsFromServer,
        setTableDetailsCb,
        tableStatePayload,
        fieldsStatePayloads,
        tableIdentifiers,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useCallback(async (newFields?: NewField[]): Promise<void> => {
        const {
            displayMessage,
            kaleTablesService,
            tableDetailsFromServer,
            setTableDetailsCb,
            tableStatePayload,
            fieldsStatePayloads,
            tableIdentifiers,
        } = dependenciesRef.current;

        return saveTableDetails(
            tableDetailsFromServer,
            setTableDetailsCb,
            tableStatePayload,
            fieldsStatePayloads,
            tableIdentifiers,
            kaleTablesService,
            displayMessage,
            newFields
        );
    }, []);
};
