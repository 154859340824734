import { combineReducers } from "@reduxjs/toolkit";
import commentsViewReducer from "src/components/CommentsView/state/reducer";
import applicationOverviewReducer from "src/components/survey/ApplicationOverview/state/reducer/reducer";
import applicationInfoReducer from "src/components/survey/state/reducer/reducer";

/**
 * Kale root reducer
 */
export const rootReducer = combineReducers({
    applicationInfo: applicationInfoReducer,
    applicationOverview: applicationOverviewReducer,
    commentsView: commentsViewReducer,
});
