import { useLayoutEffect, useRef, useState } from "react";
import { INITIAL_WIZARD_ACTIVE_STEP_INDEX } from "src/components/survey/DataStoreInfo/contants";

// Depending on if this is Legal Survey or TAF survey, the wizard will have a different number of steps.
// If we choose a step index that exceeds the bounds of the steps array, Polaris has a defined behavior of just
// activating the furthest step in the current list.
export const FURTHEST_STEP = Number.MAX_VALUE;

enum WizardRenderedStage {
    Closed = "Closed",
    Opening = "Opening",
    Opened = "Opened",
}

interface UseHandleWizardOnOpenOptions {
    showModal: boolean;
    isExistingDataStore: boolean;
    hasTafLayout: boolean;
    setActiveStepIndex: (activeStepIndex: number) => void;
}
export const useHandleOnWizardOpen = ({
    showModal,
    isExistingDataStore,
    hasTafLayout,
    setActiveStepIndex,
}: UseHandleWizardOnOpenOptions): void => {
    // Local state for render stage is always initialized as `closed` because we have a 2 stage open operation
    // that may not be skipped.
    const [renderedStage, setRenderedStage] = useState<WizardRenderedStage>(WizardRenderedStage.Closed);

    // NOTES ON CHOOSING A CORRECT INITIAL STEP INDEX
    // If this is for an existing data store in the LegalSurvey, we want to open the Wizard up to the
    // Summary-review step (the furthest step) instead of the Collect-Data-Store-Info step. This view will
    // better communicate to the user which steps still have missing required questions. This same logic
    // cannot be as easily applied to TAF DataStore wizard for the following reasons:
    //    1. All data stores in TAF view are pre-existing, so there is no easy flag to determine when a user
    //       has opened it for the first time or if they're coming back to re-edit it.
    //    2. Currently we cannot easily select the correct index for the Summary review step at the time
    //       that that data store wizard is being opened in the TAF view. For some reason not all TAF Steps
    //       are generated by that time, so we would end up choosing the wrong index if we tried to derive it from the
    //       length of the current steps in the wizard. In the future we would like to remove the cause of this race
    //       condition.
    const initialStepIndex = isExistingDataStore && !hasTafLayout ? FURTHEST_STEP : INITIAL_WIZARD_ACTIVE_STEP_INDEX;
    const deps = { initialStepIndex, setActiveStepIndex };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useLayoutEffect(
        // NOTES ON 2-STAGE WIZARD "OPEN OPERATION" AND SETTING 2 DIFFERENT ACTIVE INDICES IN SERIAL
        // We have a requirement in Kale that a user should be able to jump to any step of the datastore
        // wizard via the wizard nav ui at any time. This was because we have wanted them to be able to preview
        // any/all of the questions that would be asked of them to give them better confidence about how long
        // the process of answering data store questions was likely to take them.
        //
        // However, the Polaris datastore wizard has a prescriptive behavior which will ONLY allow a user to
        // use the wizard nav to jump to steps that are either equal-to, or previous-to, the furthest step they
        // have visited so far. This Polaris behavior is NON-CONTROLLABLE in Polaris 2 and 3.
        //
        // In order to indirectly work around this behavior of the Wizard API for all of our use cases, while
        // the wizard is opening, we first programmatically visit the *furthest* step in the wizard, which
        // causes the Polaris Wizard's internal state to track that as the furthest step index the user has
        // visited, and then we immediately again programmatically visit the intended initial step. This should
        // result in the user only ever seeing the intended initial step, but with the Wizard also having
        // recorded that the user has already visited the furthest step, therefore allowing all steps to be
        // accessed immediately through the Wizard navigation menu.
        //
        // We have to place both activation operations inside of useLayoutEffect because of a known race condition
        // where all of the wizard steps will not be created and loaded into the wizard by the time showModal is first
        // changed to true. In order to successfully activate the correct *furthest* step, all of the steps have to be
        // loaded. If less than all the steps are loaded then we cannot activate the correct *furthest* step because
        // Polaris will always clamp any step activation index to be within the bounds of the currently loaded list of
        // steps. Having this code run inside of useLayoutEffect ensures that it will run asynchronously after the final
        // steps have been set.
        function handleWizardOnOpen(): void {
            // When showModal changes to false, set render stage back to closed. This allows us to effectively
            // cancel our 2 stage open operation should this value becomes false.
            if (!showModal) {
                setRenderedStage(WizardRenderedStage.Closed);
            } else {
                // Else modal is somewhere in the process of opening
                const { initialStepIndex, setActiveStepIndex } = depsRef.current;

                // If render stage was closed, we need to perform our 'opening' operation.
                if (renderedStage === WizardRenderedStage.Closed) {
                    // Activate *furthest* step to enable navigation for all steps through the Wizard nav
                    setActiveStepIndex(FURTHEST_STEP);
                    // Advance the rendered stage
                    setRenderedStage(WizardRenderedStage.Opening);
                }

                // If render stage was `opening`, we need to perform our 'opened' operation.
                if (renderedStage === WizardRenderedStage.Opening) {
                    // Render the intended initial step.
                    setActiveStepIndex(initialStepIndex);
                    // Advance the rendered stage
                    setRenderedStage(WizardRenderedStage.Opened);
                }
            }
        },
        [showModal, renderedStage]
    );
};
