import { KaleApplicationEvent } from "src/services/RumService/rum-events/base-events";
import { RumEventName } from "src/services/RumService/rum-events/constants";

interface PDCWarningModalShownEvent extends KaleApplicationEvent {
    name: RumEventName.Kale_PDC_Warning_Modal_Shown;
}

const eventName = RumEventName.Kale_PDC_Warning_Modal_Shown;

type PDCFiltersLoadedOptions = Pick<PDCWarningModalShownEvent, "metadata">;
const pdcWarningModalShown = ({ metadata }: PDCFiltersLoadedOptions): PDCWarningModalShownEvent => ({
    name: eventName,
    metadata,
});

export const event = pdcWarningModalShown;
export const name = eventName;
export type Options = PDCFiltersLoadedOptions;
export type Event = PDCWarningModalShownEvent;
