import { useCallback, useMemo, useState } from "react";
import { MultiselectProps } from "@amzn/awsui-components-react-v3";
import { QuestionOption } from "src/services/dynamic-questions";

type ExclusiveOptionsRecord = Map<string, boolean>;
type FindExclusiveOption = (options: string[]) => string | undefined;

/**
 * @return a map to perform quick lookup about whether an option is configured with the
 * isExclusive property set to true.
 */
const makeExclusiveOptionsMap = (options: QuestionOption[]): Map<string, boolean> =>
    options.reduce<ExclusiveOptionsRecord>((exclusiveOptions, option): ExclusiveOptionsRecord => {
        if (option.isExclusive) {
            exclusiveOptions.set(option.value, true);
        }
        return exclusiveOptions;
    }, new Map());

/**
 * @param options - The list of QuestionOptions belonging to the question.
 * @return a callback function to look through a list of option values and return the first value that is configured
 * with the `isExclusive` property set to true.
 */
const useFindExclusiveOptionCb = (options: QuestionOption[]): FindExclusiveOption => {
    // Static state value created only during initial render
    const [exclusiveOptionsMap] = useState<ExclusiveOptionsRecord>(
        // Lazy init function
        (): ExclusiveOptionsRecord => makeExclusiveOptionsMap(options)
    );

    return useCallback<FindExclusiveOption>(
        (options: string[]): string | undefined => options.find((value): boolean => exclusiveOptionsMap.has(value)),
        [exclusiveOptionsMap]
    );
};

/**
 * @param options - A list of QuestionOptions belonging to the question.
 * @param selectedExclusiveOption - Determines whether the exclusive option was selected.
 * @return a list of option definitions after calculating the logic of whether an exclusive option is selected.
 *         If an exclusive option is selected, it will return an options list with all other options disabled.
 */
const useMakeEnhancedOptions = (
    options: QuestionOption[],
    selectedExclusiveOption: string | undefined
): QuestionOption[] => {
    return useMemo(
        // Recreate options only if the Memo is invalidated
        (): QuestionOption[] =>
            options.map(
                (option): QuestionOption => ({
                    ...option,
                    // While an option marked `isExclusive: true` is selected, disable every other
                    // option in the dropdown
                    disabled: Boolean(selectedExclusiveOption) && selectedExclusiveOption !== option.value,
                })
            ),
        [options, selectedExclusiveOption]
    );
};

/**
 * @param options - The list of QuestionOptions belonging to the question.
 * @param values - The list of values of selected option values.
 * @return.enhancedOptions - the list of options after applying the logic of whether an exclusive option was selected.
 *                           If an exclusive option was selected, all other options are disabled.
 * @return.onChange - an `onChange` function which processes the selected options after a user changes their selection
 *                    and produces an enhanced set of selectedOptions supporting the isExclusiveBehavior.
 * @summary This function is a hook which uses the available options and the values of the selected ones to determine
 *          whether the exclusive option was selected. Then it defines the actions needed to be taken depending on that.
 */
export const useMultiSelectExclusiveOptionBehaviors = (
    options: QuestionOption[],
    values: string[]
): {
    enhancedOptions: QuestionOption[];
    onChange: (selectedOptions: readonly MultiselectProps.Option[]) => string[];
} => {
    // Function to check if the exclusive option was selected
    const findExclusiveOption = useFindExclusiveOptionCb(options);
    // Using a hook to find the state which contains a selected option with the `isExclusive` tag and
    // a function to change the state
    const [selectedExclusiveOption, setSelectedExclusiveOption] = useState<string | undefined>((): string | undefined =>
        // Lazy init function
        findExclusiveOption(values)
    );

    const enhancedOptions = useMakeEnhancedOptions(options, selectedExclusiveOption);

    const onChange = (selectedOptions: MultiselectProps["selectedOptions"]): string[] => {
        const currentSelectedValues = selectedOptions.map((option): string => option.value!);
        // Detect if an option that had the "isExclusive" property was chosen. Upon detection,
        // unselect all other options besides the exclusive one.
        const selectedExclusiveOption = findExclusiveOption(currentSelectedValues);
        setSelectedExclusiveOption(selectedExclusiveOption);
        return selectedExclusiveOption ? [selectedExclusiveOption] : currentSelectedValues;
    };

    return {
        enhancedOptions,
        onChange,
    };
};
