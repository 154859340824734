import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import { ExternalId } from "src/services/KaleApplicationService";
import KaleContext from "src/components/KaleContext";

export const USE_CONTEXT_UNDEF_ERR = "useAEDUSandfireContext must be used within a AEDUSandfireContextProvider";

export interface AEDUSandfireState {
    // Return from the AEDU/Sandfire Backend API Call; null if errorMsg is non empty OR isLoading is true
    externalIDs: ExternalId[] | null;
    // AEDU/Sandfire API Call error string. Empty = Call successful OR the call not made yet OR call in progress
    errorMsg: string;
    // Indicates the AEDU/Sandfire API Call has not been made OR is in progress OR will not be made at all (due to app
    // not being created yet)
    isLoading: boolean;
}

/**
 * useFetchAEDUSandfireInfo calls the API that backs the AEDU and Sandfire Table in Kale. It is calculated within the
 * context and hooks can be used to retrieve the data if your component is covered by the associated Provider.
 *
 * listExternalOnboardingStatuses is the API being called.
 *
 * The reason why this is in a separate context is so that duplicative calls don't have to be made since there are
 * atleast 2 consumers of this data in different parts of the Kale Survey. The actual AEDUSandfire Table and also the
 * DataStore Table "Actions" Button
 *
 * @param applicationName Kale App Name of the currently opened Kale App, if any. If appName is invalid, default context
 * will be provided to consumers.
 */
const useFetchAEDUSandfireInfo = (applicationName?: string): AEDUSandfireState => {
    const [externalIDs, setExternalIDs] = useState<AEDUSandfireState["externalIDs"]>([]);
    const [errorMsg, setErrorMsg] = useState<AEDUSandfireState["errorMsg"]>("");
    const [isLoading, setLoading] = useState<AEDUSandfireState["isLoading"]>(true);

    const {
        service: { kaleAppService },
    } = useContext(KaleContext);
    const dependencies = { kaleAppService };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    useEffect((): void => {
        // https://developer.mozilla.org/en-US/docs/Glossary/IIFE
        (async function IIFE(): Promise<void> {
            setLoading(true);
            const { kaleAppService } = depsRef.current;
            try {
                if (!applicationName) {
                    setErrorMsg(`App Name ${applicationName} is invalid`);
                    return;
                }
                const result = await kaleAppService.listExternalOnboardingStatuses(applicationName);

                setErrorMsg("");
                setExternalIDs(result);
            } catch (err) {
                setErrorMsg((err as Error).message);
            } finally {
                setLoading(false);
            }
        })();
    }, [applicationName]);

    return { externalIDs, errorMsg, isLoading };
};

const AEDUSandfireContext = createContext<undefined | AEDUSandfireState>(undefined);

interface AEDUSandfireContextProviderProps {
    children: React.ReactNode;
    applicationName?: string;
}

/**
 * Component is responsible for fetching AEDU and Sandfire Data for the App Name prop that it receives and
 * will then publish that data to its children via a Context object. Callers should use this component
 * to fetch and provide AEDU/Sandfire data to their react subtree if their subtree does not already have
 * this component as an ancestor.
 * @param applicationName Kale App Name, if any
 * @param children components to render within the Provider
 * @constructor
 */
const AEDUSandfireContextProvider = ({ applicationName, children }: AEDUSandfireContextProviderProps): JSX.Element => {
    const { externalIDs, errorMsg, isLoading } = useFetchAEDUSandfireInfo(applicationName);
    const { Provider } = AEDUSandfireContext;
    return (
        <Provider value={{ externalIDs: externalIDs, errorMsg: errorMsg, isLoading: isLoading }}>{children}</Provider>
    );
};

/**
 * A custom hook that reads from the AEDUSandfire Context object and returns the data to the calling component.
 * Callers should use this hook to receive the AEDU and Sandfire data into their components
 */
const useAEDUSandfireContext = (): AEDUSandfireState => {
    const aeduSandfireContext = useContext(AEDUSandfireContext);
    if (aeduSandfireContext === undefined) {
        throw new Error(USE_CONTEXT_UNDEF_ERR);
    }

    return aeduSandfireContext;
};

export { AEDUSandfireContextProvider, useAEDUSandfireContext };
