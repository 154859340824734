import { PDCFilterChanged } from "src/services/RumService/rum-events";
import { useKaleServices } from "src/components/KaleContext";
import { FilterDetails } from "src/services/RumService/rum-events/pdc-filter-events/types";
import { useKaleApplicationEventMetadata } from "src/components/survey/KaleApplication/useKaleApplicationEventMetadata";

type EventData = PDCFilterChanged.Options["data"];
type RecordFilterChangedEventProps = FilterDetails;

export const useRecordFilterChangedEventCb = () => {
    const { rumService } = useKaleServices();
    const metadata = useKaleApplicationEventMetadata();

    return function recordPDCFilterChangedEvent({ filterName, filteredItemsCount }: RecordFilterChangedEventProps) {
        const data: EventData = { filterName, filteredItemsCount };
        const event = PDCFilterChanged.event({ data, metadata });
        rumService?.recordEvent(event);
    };
};
