import { SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import TableNavigationDropdown from "src/components/TableDetails/TableDetailsPage/TableNavigationDropdown";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import styled from "styled-components";
import { TableIdentifiers, TableLink, TableRecord } from "src/services/KaleTablesService";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";

// The Polaris BreadcrumbsGroup component API takes an array of BreadCrumbItems and then prescribes
// a behavior where it automatically renders all items as links AND automatically makes the last item
// a disabled link.  For our scenario we want to replace the final disabled breadcrumb link with our
// TableNavigationDropdown component. Our current strategy to achieve this is use a CSS override
// to remove the final disabled BreadCrumbItem from the flow of the page.
const StyledBreadCrumbGroup = styled(KaleBreadcrumbGroup)`
    li:last-of-type {
        display: none !important;
    }
`;

// <SpaceBetween> is implemented as a CSS flexbox
// need to override align-items to vertically center
// the Dropdown with the Breadcrumbs
const StyledSpaceBetween = styled(SpaceBetween)`
    align-items: center !important;
`;

interface TableDetailsBreadCrumbsProps {
    tableIdentifiers: TableIdentifiers;
    kaleTable: TableRecord | undefined;
    linkedTables: TableLink[] | undefined;
}

const TableDetailsBreadCrumbs = (props: TableDetailsBreadCrumbsProps): JSX.Element => {
    const {
        kaleTable,
        linkedTables,
        tableIdentifiers: { applicationName, reviewId, dataStoreId, tableId },
    } = props;

    let dataStoreNameWithTech = "";
    if (kaleTable?.dataStoreName && kaleTable?.dataStoreTech) {
        dataStoreNameWithTech = `${kaleTable?.dataStoreName ?? ""} (${kaleTable?.dataStoreTech ?? ""})`;
    }
    const items = [
        BreadcrumbItems.KALE_LANDING_PAGE,
        BreadcrumbItems.editKaleRecord(applicationName, reviewId),
        BreadcrumbItems.kaleDataStore(applicationName, reviewId, dataStoreNameWithTech),
        BreadcrumbItems.kaleTable(
            applicationName,
            props.tableIdentifiers.reviewId,
            Number(dataStoreId),
            tableId,
            kaleTable?.name ?? ""
        ),
    ];

    return (
        <StyledSpaceBetween direction={"horizontal"} size={"xxxs"}>
            <StyledBreadCrumbGroup ariaLabel="Breadcrumbs" items={items} />
            <TableNavigationDropdown
                applicationName={applicationName}
                reviewId={reviewId}
                kaleTable={kaleTable}
                linkedTables={linkedTables}
            />
        </StyledSpaceBetween>
    );
};

export default TableDetailsBreadCrumbs;
