import React, { useEffect, useState } from "react";

enum RenderStage {
    InProgress,
    Complete,
}

export interface TwoStageRenderProps {
    renderInProgress: () => React.ReactNode;
    renderComplete: () => React.ReactNode;
}

/**
 * A type of Decorator component which enforces a two stage render process in order to paint a loading spinner, or some
 * other type of progress indicator, to the screen before beginning a lengthier synchronous render process that might
 * block the ui thread. The intent is to provide feedback to the user that something is happening after they've
 * performed an Action in the UI that would cause a new subtree to render when rendering that subtree might take a few
 * seconds. E.g. Rendering a Polaris Table with 900+ rows of data, even with UI pagination turned on, typically takes a
 * few seconds.
 * @param renderInProgress - a callback to generate the ReactNode that will indicate that a long something render
 * operation is in progress. Usually this is a Polaris <Spinner> component.
 * @param renderComplete - a callback to generate the desired final ReactNode which we want to begin rendering once the
 * inProgress node has been painted to the screen
 */
const TwoStageRender = ({ renderInProgress, renderComplete }: TwoStageRenderProps): JSX.Element => {
    // Begin at the 'InProgress' stage
    const [renderStage, setRenderStage] = useState<RenderStage>(RenderStage.InProgress);

    // Update to 'Complete' stage after allowing a brief period of time for React to commit
    // this component's in-progress node to the DOM
    useEffect((): void => {
        if (renderStage === RenderStage.InProgress) {
            // eslint-disable-next-line
            setTimeout((): void => setRenderStage(RenderStage.Complete), 0);
        }
    });

    if (renderStage === RenderStage.InProgress) {
        return <div>{renderInProgress()}</div>;
    } else {
        return <div>{renderComplete()}</div>;
    }
};

export default TwoStageRender;
