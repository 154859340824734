import {
    AppLayout,
    Autosuggest,
    BreadcrumbGroupProps,
    ColumnLayout,
    Container,
    FormField,
    Select,
    Popover,
    Link,
} from "@amzn/awsui-components-react-v3/polaris";
import { Box } from "@amzn/awsui-components-react-v3";
import { InputProps } from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "src/components/fields/Loader";
import {
    useFetchTablesOnMount,
    useFetchSchema,
    useFetchVersions,
} from "src/components/schema_table/importers/andes_importer/hooks";
import ImportForm, { ImporterRouteParams } from "src/components/schema_table/importers/ImportForm";
import SchemaPreview from "src/components/schema_table/importers/SchemaPreview";
import { TableDefinition } from "src/components/schema_table/SchemaTable";
import { KaleRoutesProps } from "src/components/survey/KaleRoutes";
import { makeDefaultDataStoreResponse } from "src/components/survey/LegalSurveyPage";
import { TableSources } from "src/services/KaleTablesService";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import { useLocationState } from "src/hooks/useLocationState";

export const TEST_IDS = {
    SPINNER: "andes-importer-spinner",
    POPOVER: "andes-importer-popover",
    PROVIDER_CHOICE: "andes-importer-provider-choice",
    TABLE_CHOICE: "andes-importer-table-choice",
    VERSION_CHOICE: "andes-importer-version-choice",
};

export interface AndesMetadata {
    provider: string;
    table: string;
    schemaVersion: string;
}

const AndesImporter = (props: KaleRoutesProps): JSX.Element => {
    const { displayMessage } = props;
    const locationState = useLocationState();
    const dataStore = locationState.dataStore || makeDefaultDataStoreResponse();
    const params = useParams<ImporterRouteParams>();
    const { isLoadingTables, tableOptionGroups, tableDistinctCount, tableError } = useFetchTablesOnMount();
    const [table, setTable] = useState<string>("");
    const [providerId, setProviderId] = useState<string>("");
    const [tableOption, setTableOption] = useState<OptionDefinition | null>(null);
    const [version, setVersion] = useState<string>("");
    const { isLoadingVersion, versionOptions, versionError } = useFetchVersions(table, providerId);
    const { isLoadingSchema, tableDefinitions, schemaError } = useFetchSchema(providerId, table, version);
    const isLoading = isLoadingTables || isLoadingVersion || isLoadingSchema;

    const onTableChoiceChange = useCallback(({ detail: { selectedOption } }): void => {
        setTableOption(selectedOption);
        setTable(selectedOption.label);
        setProviderId(selectedOption.labelTag);
        setVersion("");
    }, []);
    const onFetchVersionChoices = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setVersion(event.detail.value);
    }, []);

    const isSubmitEnabled = (tableDefinitions: TableDefinition[]): boolean => {
        return (
            tableDefinitions.length > 0 &&
            tableDefinitions.every((td): boolean => Boolean(td && td.name && td.name.length > 0))
        );
    };
    const renderBreadcrumbs = (): JSX.Element => {
        const dsName =
            Boolean(dataStore?.name) && Boolean(dataStore?.technology)
                ? `${dataStore.name} (${dataStore.technology})`
                : "";
        const items: BreadcrumbGroupProps.Item[] = [
            BreadcrumbItems.KALE_LANDING_PAGE,
            BreadcrumbItems.editKaleRecord(params.applicationName, params.reviewId),
            BreadcrumbItems.kaleDataStore(params.applicationName, params.reviewId, dsName),
            BreadcrumbItems.kaleAndesImporter(params.applicationName, params.reviewId, Number(params.dataStoreId)),
        ];
        return <KaleBreadcrumbGroup items={items} />;
    };
    const renderContent = (): JSX.Element => {
        return (
            <ImportForm
                header="Import Andes Schema to Kale"
                isDisabled={!isSubmitEnabled(tableDefinitions)}
                tableDefinitions={tableDefinitions}
                dataStore={dataStore}
                source={TableSources.Andes}
                andesSourceMetadata={{ provider: providerId, table, schemaVersion: version }}
                displayMessage={displayMessage}
            >
                <Container header={"Import Settings"}>
                    <ColumnLayout>
                        <FormField label={"Table Name"} errorText={tableError}>
                            <Select
                                selectedOption={tableOption ?? null}
                                filteringType={"auto"}
                                data-testid={TEST_IDS.TABLE_CHOICE}
                                options={tableOptionGroups}
                                disabled={!Boolean(tableOptionGroups.length)}
                                placeholder={"Select the table"}
                                empty={"No matching table found"}
                                onChange={onTableChoiceChange}
                                // Performance optimization provided by polaris
                                // recommended to be used when there are 500 or more options
                                virtualScroll={tableDistinctCount > 499}
                            />
                        </FormField>
                        <Popover
                            data-testid={TEST_IDS.POPOVER}
                            dismissButton={false}
                            content={
                                <div>
                                    <p>
                                        You must have <b>&apos;consume&apos;</b> permissions on an Andes Table in order
                                        to import its schema in Kale.
                                    </p>
                                </div>
                            }
                        >
                            Not seeing your Table?
                        </Popover>
                        <FormField label={"Schema Version"} errorText={versionError}>
                            <Autosuggest
                                data-testid={TEST_IDS.VERSION_CHOICE}
                                enteredTextLabel={(value): string => `Use: "${value}"`}
                                options={versionOptions}
                                value={version}
                                disabled={!Boolean(versionOptions.length)}
                                placeholder={"Select the schema version"}
                                empty={"No matching schema version found"}
                                disableBrowserAutocorrect={true}
                                onChange={onFetchVersionChoices}
                            />
                        </FormField>
                        <Box variant="p">
                            If you need help finding your Andes dataset:{" "}
                            <Link external href="https://hoot.corp.amazon.com/">
                                Hoot Dashboard
                            </Link>
                        </Box>
                    </ColumnLayout>
                </Container>
                <FormField errorText={schemaError} stretch={true}>
                    <SchemaPreview tableDefinitions={tableDefinitions} />
                </FormField>
            </ImportForm>
        );
    };

    return (
        <React.Fragment>
            {isLoading && <Loader data-testid={TEST_IDS.SPINNER} />}
            <AppLayout
                headerSelector="#top-nav"
                breadcrumbs={renderBreadcrumbs()}
                navigationHide={true}
                toolsHide={true}
                content={renderContent()}
            />
        </React.Fragment>
    );
};

export default AndesImporter;
