import styled from "styled-components";

export const InstanceIdContainer = styled.div`
    margin-bottom: 5px !important;
`;

export const StyledDataStoreInfoContent = styled.div`
    padding-bottom: 0 !important;
`;

export const InstanceValue = styled.div`
    font-family: Courier, Courier New, monospace;
`;

export const AlertContainer = styled.div`
    margin-bottom: 10px !important;
`;
