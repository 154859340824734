import styled from "styled-components";
import * as awsui from "@amzn/awsui-design-tokens";

export const EmptyTableContainer = styled.div`
    margin: 10px;
    padding: 10px;
`;

export const HeaderTitle = styled.h3`
    font-weight: 700 !important;
`;

export const RowNumber = styled.div`
    margin-left: -14px;
    display: inline-block;
`;

export const ActionBar = styled.div`
    float: right;
`;

export const ColumnGroupHeader = styled.div``;
export const ColumnHeader = styled.div``;

// Use this with a Polaris <Table> as its child.
export const TableStyles = styled.div`
    // Styles for an extra Group header above the standard table column headers
    ${ColumnGroupHeader} {
        min-height: 20px;
        margin: 0px -21px !important;
        padding-left: 21px;
        font-size: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // Styles for column table headers
    ${ColumnHeader} {
        max-height: 80px; // allows a maximum of 4 rows of wrapping text in the column header
        overflow: hidden;
        margin-top: 5px;
    }

    // Styles to pull the $ColumnHeaders and $ColumnGroupHeaders to the top of the column header row
    thead {
        vertical-align: top;

        th {
            padding-top: 0;
        }
    }

    // By setting a min height on the <table> we allow the Polaris <Select>
    // component's dropdown to be taller than the height of a single option.
    // The awsui-table-container element is the nearest positioned ancestor
    // of the table cell and Polaris is also setting the overflow css property
    // on this element. <Select> component's do not render well inside of a
    // positioned ancestor element that also has its overflow style set.
    tbody {
        min-height: 440px;

        td {
            overflow: visible !important;
        }
    }

    // This block contains styles to support making the first 3 columns of the Polaris Table Left-Sticky.
    // The 3 intended columns are:
    //  (1) item-selection column. This column is rendered by Polaris for Tables that opt into Selection behaviors
    //  (2) row-numbers column
    //  (3) table-name column
     {
        // Styles to make the first three columns and column headers of the Polaris Table Left-Sticky
         {
            td:first-child,
            th:first-child {
                position: sticky !important;
                left: 0;
                z-index: 3;
            }
            td:nth-child(2),
            th:nth-child(2) {
                position: sticky !important;
                left: 50px;
                z-index: 2;
            }
            th:nth-child(2) {
                padding: 0.4rem 0 0.4rem 0 !important;
            }

            td:nth-child(3),
            th:nth-child(3) {
                position: sticky !important;
                left: 100px;
                z-index: 1;
            }

            td:first-child,
            td:nth-child(2),
            td:nth-child(3) {
                background-color: ${awsui.colorBackgroundContainerContent};
            }
        }

        // Styles to disable manual resizing of the row-numbers column to ensure our left sticky column headers work
        // correctly. If we allow users to resize this column it breaks our Left-Sticky implementation.
        th:nth-child(2) {
            [class^="awsui_resizer"] {
                pointer-events: none;
            }
        }
    }
`;
