import React, { useEffect, useState } from "react";
import { Select, SelectProps } from "@amzn/awsui-components-react-v3";
import SurveyConstants from "src/components/constants/Constants";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { KaleFormField } from "src/components/fields/KaleFormField";
export const TEST_IDS = {
    IS_CONFIDENTIAL_SELECT: "is-confidential-select",
};

const IsConfidential = ({
    id,
    expectedAnswer,
    isFormInReadonlyMode = false,
    onChangeCallback,
}: FunctionalSurveyItemProps<boolean>): JSX.Element => {
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option>(boolToOption(expectedAnswer ?? false));

    const data = SurveyConstants.getData("isConfidential");
    const label = data.question;
    const description = data.description;

    useEffect((): void => {
        setSelectedOption(boolToOption(expectedAnswer ?? false));
    }, [expectedAnswer]);

    return (
        <KaleFormField label={label} description={description}>
            <Select
                id={"isConfidentialSelect"}
                disabled={isFormInReadonlyMode}
                data-testid={TEST_IDS.IS_CONFIDENTIAL_SELECT}
                selectedOption={selectedOption}
                placeholder={"Choose an option"}
                onChange={({ detail }): void => {
                    onChangeCallback?.call(null, {
                        id: id,
                        response: answerToBool(detail.selectedOption.label ?? ""),
                    });
                }}
                options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                ]}
            />
        </KaleFormField>
    );
};

const answerToBool = (answer: string): boolean => {
    return answer === "Yes";
};

const boolToOption = (boolAnswer: boolean): SelectProps.Option => {
    if (boolAnswer) {
        return { label: "Yes", value: "Yes" };
    }
    return { label: "No", value: "No" };
};

export const IsConfidentialFunctionalReadOnly = withFunctionalReadonly(IsConfidential);
