import { useContext, useMemo, useRef } from "react";
import { answerActionsContext, Answers, AnswerState, answerStateContext, Response } from "../context";
import { StateKey } from "../actions";
import {
    DefaultTableFieldAnswer,
    TableAnswer,
    TableFieldAnswer,
    TableFieldAnswerWithQuestion,
    TableFieldQuestion,
    TableQuestion,
} from "../models/services";
import { getAnswerResponseState, HookAnswer } from "./common";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";
import { KaleTableFieldPayload, KaleTableFieldsAccessor, TableFieldsAnswerActions } from "src/answers_legacy";

export function getBulkEditKaleTableField(
    state: AnswerState,
    fieldId?: number,
    userFieldId?: string
): Answers<TableFieldAnswerWithQuestion> | undefined {
    return fieldId || userFieldId
        ? state[StateKey.bulkEditKaleTableFields].find(
              (item): boolean =>
                  item.value[0] &&
                  (fieldId
                      ? item.value[0].value.answer.fieldId === fieldId
                      : item.value[0].value.answer.userFieldId === userFieldId)
          )
        : state[StateKey.bulkEditKaleTableFields][0];
}

export function getBulkEditKaleTableFieldAnswerById(
    state: AnswerState,
    actions: TableFieldsAnswerActions,
    questionShortId?: string,
    fieldId?: number,
    userFieldId?: string
): HookAnswer<TableFieldQuestion, TableFieldAnswer> | void {
    const kaleTableFieldState = getBulkEditKaleTableField(state, fieldId, userFieldId);

    const questionWithAnswer = kaleTableFieldState?.value.find(
        (answer: Response<TableFieldAnswerWithQuestion>): boolean => {
            const id = answer.value.answer.questionShortId;
            return id === questionShortId;
        }
    );

    if (!questionWithAnswer) {
        console.error(`Invalid requested field answer by short id "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<TableFieldQuestion, TableFieldAnswer>(questionWithAnswer, actions);
}

export const useBulkEditKaleTableFields = (): [KaleTableFieldsAccessor, TableFieldsAnswerActions] => {
    const actions = useContext(answerActionsContext)<TableFieldQuestion, TableFieldAnswer>(
        StateKey.bulkEditKaleTableFields
    );
    const state = useContext(answerStateContext);
    const fieldsState = state[StateKey.bulkEditKaleTableFields];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [KaleTableFieldsAccessor, TableFieldsAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            fieldsState.map((field): KaleTableFieldsAccessor => {
                return {
                    answers: field.value
                        .map((questionWithAnswer): HookAnswer<TableFieldQuestion, TableFieldAnswer> | void =>
                            getBulkEditKaleTableFieldAnswerById(
                                state,
                                actions,
                                questionWithAnswer.value.answer.questionShortId
                            )
                        )
                        .filter((v): boolean => !!v) as HookAnswer<TableQuestion, TableAnswer>[],
                    isValid: field.isValid,
                    isValidSave: field.isValidSave,
                    isValidSubmit: field.isValidSubmit,
                    hasChanged: field.hasChanged,
                    userLastUpdated: field.userLastUpdated,
                };
            })[0], // Return first field only, more not needed
            actions,
        ];
    }, [fieldsState]);
};

interface ComplianceTypeMap {
    [k: string]: string;
}

export const useBulkEditKaleTableFieldsPayloads = (): KaleTableFieldPayload => {
    const state = useContext(answerStateContext);
    const fieldsState = state[StateKey.bulkEditKaleTableFields];

    return useMemo((): KaleTableFieldPayload => {
        const complianceTypeMap: ComplianceTypeMap = {};
        return fieldsState
            .map((tableField): TableFieldAnswer[] =>
                tableField.value.map((questionWithAnswer): TableFieldAnswer => {
                    complianceTypeMap[questionWithAnswer.value.question.id] =
                        questionWithAnswer.value.question.complianceType;
                    return {
                        ...questionWithAnswer.value.answer,
                        groupType: questionWithAnswer.value.question?.groupType,
                    };
                })
            )[0]
            .map((answer): TableFieldAnswer => {
                const base = DefaultTableFieldAnswer(
                    answer.questionId,
                    answer.questionShortId,
                    answer.fieldId,
                    answer.userFieldId
                );

                return {
                    ...base,
                    ...answer,
                    complianceType: complianceTypeMap[answer.questionId],
                };
            })
            .filter((answer): boolean => meetsServerAnswerConstraint(answer));
    }, [fieldsState]);
};
