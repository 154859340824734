import { DataStoreHookAnswer, QuestionTag } from "src/answers_legacy";
import React, { useState } from "react";
import { PrefilledAnswerValue, QuestionChoice } from "src/services/dynamic-questions";
import KaleCheckboxGroup from "src/components/fields/select/KaleCheckboxGroup";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";

// We will use this hidden choice to give the question an answer even if it hasn't been "answered" by the user.
// The benefit is that if a user had previously answered any child questions of this question, before this question
// was later added in as a parent to those questions, the GSM won't mark those questions as not applicable due to
// an unanswered question and won't throw their answers to those questions away before they have a change to pick a
// "real" answer to this question. Once we have official versioning support for Kale questions we can move away from
// strategies like this
export const HIDDEN_NA_CHOICE = PrefilledAnswerValue.NA;
export const CUSTOMER_CHOICE = "Customers";

/**
 * A CheckBoxGroup Question Component with some extra custom behaviors based on hard coded assumptions around
 * the specific choices that the HasLongRetentions Question are expecting to receive from the back end
 */
const LongRetentionQuestion = ({ item }: { item: DataStoreHookAnswer }): JSX.Element => {
    // The HIDDEN_NA_CHOICE option is expected to be set as the initial answer for this question by the backend in
    // order to try and preserve any of its child question's answer since the child questions existed in previous
    // versions of our app and did not originally have any parent constraints. If this question as the parent, were
    // to be initialized with no answer, then the children questions could be marked as not applicable by the GSM
    // and have all previous customer answers to them erased.
    //
    // To facilitate this experience, all of this question's child are expected to have been configured by the
    // backend to have CUSTOMER_CHOICE and HIDDEN_NA_CHOICE listed as their parent constraints.
    // NOTE: Only the child questions which list CUSTOMER_CHOICE as a parent constraint have the possibility
    // of containing pre-existing answers.
    //
    // Additionally, it is expected that a custom constraint handler for the GSM datastore questions exists
    // elsewhere in the front end to properly configure these questions to use the "ANY" parent constraint handling
    // heuristics rather than the default GSM heuristic which would require that "ALL" parent constraints were met
    // before a question would be considered applicable

    const [visibleChoices] = useState<QuestionChoice[]>((): QuestionChoice[] =>
        item.question.choices.filter((value): boolean => {
            // Exclude the HIDDEN_NA_CHOICE choice from the list of choices visible to the user. The NA choice
            // is only meant to be a temporary stand in until a customer can manually answer the question with a
            // real answer
            return value.value !== HIDDEN_NA_CHOICE;
        })
    );

    return (
        <KaleCheckboxGroup
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            choices={visibleChoices}
            expectedAnswer={(item.value as string[]) ?? []}
            onChangeCallback={({ response }: FunctionalSurveyItemResult<string[]>): void => {
                // If the CUSTOMER_CHOICE option is ever selected manually by the user, we want to deselect the hidden
                // HIDDEN_NA_CHOICE option in order to make sure that the children questions that have
                // CUSTOMER_CHOICE as their parent constraint will correctly be marked as not applicable and be hidden
                // from view, should the user ever manually select and then later deselect the CUSTOMER_CHOICE option.
                const result = response.includes(CUSTOMER_CHOICE)
                    ? response.filter((value): boolean => value !== HIDDEN_NA_CHOICE)
                    : response;
                item.setValue(result);
            }}
        />
    );
};

export default LongRetentionQuestion;
