import styled from "styled-components";
import * as awsui from "@amzn/awsui-design-tokens/polaris";
import { Icon } from "@amzn/awsui-components-react-v3";
import React from "react";

/* Error styles modeled after polaris <FormField> errorText prop styling. */
export const ErrorText = styled.span`
    &,
    * {
        color: ${awsui.colorTextStatusError} !important;
        // preserve line breaks in text
        white-space: pre-wrap;
    }
`;

const ErrorContainer = styled.p`
    & ${ErrorText} {
        margin-top: 0.3rem !important;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
`;

const Error = (props: { text: string; "data-testid"?: string }): JSX.Element => {
    return (
        <ErrorContainer data-testid={props["data-testid"]}>
            <Icon name={"status-warning"} variant={"error"} /> <ErrorText> {props.text} </ErrorText>
        </ErrorContainer>
    );
};

export default Error;
