import { Input, FormField } from "@amzn/awsui-components-react-v3/polaris";
import { InputProps } from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/interfaces";
import React, { useCallback, useState } from "react";
import { ConfirmActionModal } from "src/components/ConfirmActions";
import { TEST_IDS as SURVEY_TEST_IDS } from "shared/survey";

const { CREATE_TABLE } = SURVEY_TEST_IDS.DATA_STORE_INFO.SCHEMA_TABLE.ACTIONS.ADD_TABLE;

export const TEST_IDS = {
    TABLE_NAME: "manual-importer--schema-table--name",
    TABLE_DESCRIPTION: "manual-importer--schema-table--description",
    DATASTORE_NAME: "manual-importer--schema-table--data-store-name",
};

interface Props {
    dataStoreName: string;
    isModalVisible: boolean;
    onCreate: (tableName: string, description: string) => void;
    onCancel: () => void;
}

const ManualImporter = (props: Props): JSX.Element => {
    const { dataStoreName, isModalVisible, onCreate, onCancel: onCancelCb } = props;
    const [tableName, setTableName] = useState<string>("");
    const [tableDescription, setTableDescription] = useState<string>("");
    const clearValues = (): void => {
        setTableName("");
        setTableDescription("");
    };
    const onConfirm = useCallback((): void => {
        onCreate(tableName, tableDescription);
        clearValues();
    }, [onCreate, tableDescription, tableName]);
    const onCancel = useCallback((): void => {
        onCancelCb();
        clearValues();
    }, [onCancelCb]);
    const onSetTableName = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setTableName(event.detail.value);
    }, []);
    const onSetTableDescription = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setTableDescription(event.detail.value);
    }, []);

    const content = (
        <React.Fragment>
            <FormField label={"Table Name"} description={"Suggested format - schemaName.tableName"}>
                <Input data-testid={TEST_IDS.TABLE_NAME} value={tableName} onChange={onSetTableName} />
            </FormField>
            <FormField label={"Table Description"}>
                <Input
                    data-testid={TEST_IDS.TABLE_DESCRIPTION}
                    value={tableDescription}
                    onChange={onSetTableDescription}
                />
            </FormField>
            <FormField label={"Data Store Name"}>
                <Input data-testid={TEST_IDS.DATASTORE_NAME} value={dataStoreName} disabled={true} />
            </FormField>
        </React.Fragment>
    );

    return (
        <ConfirmActionModal
            testIds={{
                cancelButton: CREATE_TABLE.MODAL_CANCEL_BUTTON,
                confirmButton: CREATE_TABLE.MODAL_CONFIRM_BUTTON,
                modalRoot: CREATE_TABLE.MODAL_ROOT,
            }}
            header={"Create Schema Table"}
            confirmButtonOverrides={{ label: "Create" }}
            onConfirm={onConfirm}
            onCancel={onCancel}
            visible={isModalVisible}
        >
            {content}
        </ConfirmActionModal>
    );
};

export default ManualImporter;
