import {
    CommentsViewState,
    CommentSlice,
    CommentThreadSlice,
    CommentThreadGroupSlice,
} from "src/components/CommentsView/state/reducer/reducer";
import { CommentsLoadedAction } from "src/components/CommentsView/state/actions";
import { CommentThread, CommentThreadGroup, ConnectedComment } from "src/components/CommentsView/state/model";
import { initializeNewCommentThread, createCommentThreadGroup } from "src/components/CommentsView/state/reducer/utils";

/**
 * Builds entire maps for our CommentsSlice and CommentThreadsSlice state objects
 * @param sortedComments - a list of comments that are expected to be presorted in descending order based on each
 * comments `createdAt`timestamp
 * @return commentsByFrontEndId - A map of all Comments indexed by their `frontEndId` property
 * @return commentThreadsByHeadCommentId - A map of all CommentThreads indexed by their `headComment` property
 */
const reduceCommentsIntoCommentThreads = (
    sortedComments: ConnectedComment[]
): {
    commentsByFrontEndId: CommentSlice["byFrontEndId"];
    commentThreadsByHeadCommentId: CommentThreadSlice["byHeadCommentId"];
} => {
    const commentsByFrontEndId: CommentSlice["byFrontEndId"] = {};
    const commentThreadsByHeadCommentId: CommentThreadSlice["byHeadCommentId"] = {};

    // Build out our commentsById and commentThreadsByHeadCommentId state objects.
    sortedComments.forEach((comment: ConnectedComment): void => {
        /* Step 1. Store each comment byFrontEndId */
        commentsByFrontEndId[comment.frontEndId] = comment;

        /* Step 2. Create comment threads amd add references to all of the comments they contain. */
        // Find or Create the comment thread that each comment belongs to. Because comments are already
        // sorted in ascending order during a LoadComments action, the head comment of each thread will be
        // encountered in the list before any of its reply comments, and therefore we can expect each reply
        // comment will already have a comment thread to belong to by the time we encounter it in the
        // traversal.
        if (comment.frontEndParentId === null) {
            // comment is a head comment
            // Create a band new comment thread and store it in our map
            commentThreadsByHeadCommentId[comment.frontEndId] = initializeNewCommentThread(comment);
        } else {
            // comment is a reply comment
            // Add this comment to the correct comment thread
            const commentThread = commentThreadsByHeadCommentId[comment.frontEndParentId];
            commentThread.commentIds.push(comment.frontEndId);
            commentThread.lastUpdated = comment.createdAt;
        }
    });

    /* Step 3. Return maps */
    return {
        commentsByFrontEndId,
        commentThreadsByHeadCommentId,
    };
};

/**
 * Create and sort CommentThreadGroups.
 * @param sortedCommentThreads - An array of all the CommentThreads we want to group. These are expected to be presorted
 * in descending order based on each CommentThread's `lastUpdated` property.
 * @return a complete CommentThreadGroupsSlice state object.
 */
function reduceCommentThreadsIntoCommentThreadGroups(sortedCommentThreads: CommentThread[]): CommentThreadGroupSlice {
    // Maintain a map pf CommentThreadGroups indexed by their `groupHeader` property
    const commentThreadGroupsByGroupHeader: { [groupHeader: string]: CommentThreadGroup } = {};
    // Maintain a list of references to all Groups in descending sort order
    const sortedGroups: string[] = [];

    if (sortedCommentThreads.length) {
        // Walk through all of the sorted threads and and add them to our collections in a single traversal. Because
        // the CommentThreads are already sorted in descending order, the resultant sortedGroups list of
        // CommentThreadGroups we are building will also be in descending sort order based on year and month
        {
            // Create an initial CommentThreadGroup that will be compatible with the first CommentThread based on that
            // CommentThreads month and year.
            const initialCommentThread = sortedCommentThreads[0];
            let compatibleGroup = createCommentThreadGroup(initialCommentThread.lastUpdated);
            // Add the initial group to both collections
            commentThreadGroupsByGroupHeader[compatibleGroup.groupHeader] = compatibleGroup;
            sortedGroups.push(compatibleGroup.groupHeader);

            sortedCommentThreads.forEach((currThread): void => {
                // If the current CommentThread is not within the date range with the current group, create a new group
                // that is compatible with the current thread's month and year.

                const lastUpdatedDate = new Date(currThread.lastUpdated);

                const threadMonth = lastUpdatedDate.getMonth();
                const threadYear = lastUpdatedDate.getFullYear();
                if (compatibleGroup.month !== threadMonth || compatibleGroup.year !== threadYear) {
                    compatibleGroup = createCommentThreadGroup(lastUpdatedDate);
                    commentThreadGroupsByGroupHeader[compatibleGroup.groupHeader] = compatibleGroup;
                    sortedGroups.push(compatibleGroup.groupHeader);
                }

                // Add store reference the the current CommentThread inside of the compatible group
                compatibleGroup.headCommentIds.push(currThread.headCommentId);
            });
        }
    }

    return { byGroupHeader: commentThreadGroupsByGroupHeader, displayOrder: sortedGroups };
}

export const commentsLoadedReducer = (
    prevState: CommentsViewState,
    action: CommentsLoadedAction
): CommentsViewState => {
    const { comments, user, applicationName } = action.payload;

    // Build maps for comments and commentThreads in a single traversal
    const { commentsByFrontEndId, commentThreadsByHeadCommentId } = reduceCommentsIntoCommentThreads(comments);

    // Sort comment threads in descending order based on each CommentThread's 'lastUpdated' timestamp
    const sortedCommentThreads = Object.values(commentThreadsByHeadCommentId).sort((a, b): number =>
        a.lastUpdated > b.lastUpdated ? -1 : 1
    );

    // Keep a list of references to the commentThreads after they've been sorted
    const displayOrder = sortedCommentThreads.map((thread): number => thread.headCommentId);

    // Create all of the CommentThreadGroups
    const commentThreadGroups = reduceCommentThreadsIntoCommentThreadGroups(sortedCommentThreads);

    return {
        ...prevState,
        applicationName,
        user,
        comments: { byFrontEndId: commentsByFrontEndId },
        commentThreads: {
            byHeadCommentId: commentThreadsByHeadCommentId,
            displayOrder,
        },
        commentThreadGroups,
    };
};
