import { Textarea, TextContent } from "@amzn/awsui-components-react-v3";
import { Box } from "@amzn/awsui-components-react-v3";
import { commentMessageChanged } from "src/components/CommentsView/state/actions";
import React, { useState } from "react";
import { useKaleDispatch } from "src/redux/hooks";
import { FrontEndComment, FrontEndCommentType } from "src/components/CommentsView/state/model";
import styled from "styled-components";
import { TEST_IDS } from "shared/comments-view";

const {
    COMMENT: { COMMENT_BODY },
} = TEST_IDS.COMMENTS_VIEW;

export const CommentBodyWrapper = styled.div`
    margin-left: 50px;
`;

interface CommentBodyProps {
    commentData: FrontEndComment;
}

const CommentBody = React.memo(
    ({ commentData: { message, commentType, frontEndId } }: CommentBodyProps): JSX.Element => {
        const dispatch = useKaleDispatch();

        const [localMessageValue, setLocalMessageValue] = useState<string>(message);

        const isDraftComment = commentType === FrontEndCommentType.DraftComment;

        return (
            <CommentBodyWrapper data-testid={COMMENT_BODY}>
                {isDraftComment ? (
                    <Textarea
                        autoFocus={true}
                        onChange={(e): void => setLocalMessageValue(e.detail.value)}
                        value={localMessageValue}
                        onBlur={(): void => {
                            dispatch(commentMessageChanged(frontEndId, localMessageValue));
                        }}
                    />
                ) : (
                    <TextContent>
                        <Box variant="p">{message}</Box>
                    </TextContent>
                )}
            </CommentBodyWrapper>
        );
    }
);

export default CommentBody;
