import { KaleTableFieldsAccessor } from "src/answers_legacy";

export const getNextTableFieldIndex = (
    tableFields: KaleTableFieldsAccessor[],
    tableField?: KaleTableFieldsAccessor
): number => {
    const index = tableField ? tableFields.indexOf(tableField) : -1;

    if (index > -1) {
        return tableFields.indexOf(tableFields[index + 1]);
    }

    return index;
};

export const getPreviousTableFieldIndex = (
    tableFields: KaleTableFieldsAccessor[],
    tableField?: KaleTableFieldsAccessor
): number => {
    const index = tableField ? tableFields.indexOf(tableField) : -1;

    if (index > -1) {
        return tableFields.indexOf(tableFields[index - 1]);
    }

    return index;
};

export const hasNextTableField = (
    tableFields: KaleTableFieldsAccessor[],
    tableField?: KaleTableFieldsAccessor
): boolean => {
    const index = getNextTableFieldIndex(tableFields, tableField);

    return Boolean(tableFields[index]);
};

export const hasPreviousTableField = (
    tableFields: KaleTableFieldsAccessor[],
    tableField?: KaleTableFieldsAccessor
): boolean => {
    const index = getPreviousTableFieldIndex(tableFields, tableField);

    return Boolean(tableFields[index]);
};

export const isLastTableField = (
    tableFields: KaleTableFieldsAccessor[],
    tableField?: KaleTableFieldsAccessor
): boolean => {
    const index = tableField ? tableFields.indexOf(tableField) : -1;

    if (index > -1) {
        return Boolean(index === tableFields.length - 1);
    }

    return false;
};

interface GetNavAnswersCallback {
    (currentTableField?: KaleTableFieldsAccessor): KaleTableFieldsAccessor | undefined;
}

interface GetNavBooleanCallback {
    (currentTableField?: KaleTableFieldsAccessor): boolean;
}

interface KaleTableFieldsNavApi {
    getNext: GetNavAnswersCallback;
    getPrevious: GetNavAnswersCallback;
    hasNext: GetNavBooleanCallback;
    hasPrevious: GetNavBooleanCallback;
    isLast: GetNavBooleanCallback;
}

export const useKaleTableFieldsNav = (accessors: KaleTableFieldsAccessor[]): KaleTableFieldsNavApi => {
    return {
        getNext: (currentTableField?: KaleTableFieldsAccessor): KaleTableFieldsAccessor | undefined =>
            accessors[getNextTableFieldIndex(accessors, currentTableField)],
        getPrevious: (currentTableField?: KaleTableFieldsAccessor): KaleTableFieldsAccessor | undefined =>
            accessors[getPreviousTableFieldIndex(accessors, currentTableField)],
        hasNext: (currentTableField?: KaleTableFieldsAccessor): boolean =>
            hasNextTableField(accessors, currentTableField),
        hasPrevious: (currentTableField?: KaleTableFieldsAccessor): boolean =>
            hasPreviousTableField(accessors, currentTableField),
        isLast: (currentTableField?: KaleTableFieldsAccessor): boolean =>
            isLastTableField(accessors, currentTableField),
    };
};
