import { KaleApplicationEvent } from "src/services/RumService/rum-events/base-events";
import { RumEventName } from "src/services/RumService/rum-events/constants";
import { FilterDetails } from "src/services/RumService/rum-events/pdc-filter-events/types";

interface PDCFilterChangedEvent extends KaleApplicationEvent {
    name: RumEventName.Kale_PDC_Filter_Changed;
    data: FilterDetails;
}

const eventName = RumEventName.Kale_PDC_Filter_Changed;

type PDCFilterChangedOptions = Pick<PDCFilterChangedEvent, "data" | "metadata">;
const pdcFilterChanged = ({ data, metadata }: PDCFilterChangedOptions): PDCFilterChangedEvent => ({
    name: eventName,
    metadata,
    data,
});

export const event = pdcFilterChanged;
export const name = eventName;
export type Options = PDCFilterChangedOptions;
export type Event = PDCFilterChangedEvent;
