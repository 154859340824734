import { Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { Box, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React from "react";
import { HelpIcon } from "src/components/HelpIcon";

export const KeyValuePair = ({ label, value, info, testId = "" }: Pair): JSX.Element => {
    const renderedLabel = (
        <SpaceBetween direction={"horizontal"} size={"xxs"}>
            <Box color="text-label">{label}</Box>
            {info && <HelpIcon content={info}></HelpIcon>}
        </SpaceBetween>
    );

    return (
        <SpaceBetween size="xxxs">
            {renderedLabel}
            <Box data-testid={testId}>{value}</Box>
        </SpaceBetween>
    );
};
