import { useState, useContext, useRef, useCallback } from "react";
import {
    KaleTableFieldPayload,
    TableFieldAnswer,
    useKaleTableFieldsPayloads,
    useKaleTablePayload,
} from "src/answers_legacy";
import KaleContext from "src/components/KaleContext";
import {
    saveTableDetails,
    SetTableDetailsCb,
} from "src/components/TableDetails/TableDetailsPage/hooks/useMakeOnSaveCb";
import { TableDetails, TableIdentifiers } from "src/services/KaleTablesService";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";

/**
 * In order to support cross-slice state manager updates, useMakeOnSaveSidebarAnswersCb accepts a list
 * of TableFieldAnswers rather than standard the combined answers with questions data structure.
 * @return onSaveSidebarAnswersCb a callback that will take the sidebar answers and use them to override the answers of
 * the corresponding table row in the table fields state slice and then trigger a page wide save.
 * @return sidebarSaveLoading a boolean flag
 * @return sidebarSaveError a boolean flag
 */
export const useMakeOnSaveSidebarAnswersCb = (
    tableDetailsFromServer: TableDetails | null,
    setTableDetailsFromServer: SetTableDetailsCb,
    tableIdentifiers: TableIdentifiers,
    displayMessage: DisplayMessageCb
): {
    onSaveSidebarAnswersCb: (fieldId: number | null, answers: TableFieldAnswer[] | null) => void;
    sidebarSaveLoading: boolean;
    sidebarSaveError: boolean;
} => {
    const [sidebarSaveLoading, setSidebarSaveLoading] = useState(false);
    const [sidebarSaveError, setSidebarSaveError] = useState(false);

    const tableStatePayload = useKaleTablePayload();
    const fieldsStatePayloads = useKaleTableFieldsPayloads();

    const {
        service: { kaleTablesService },
    } = useContext(KaleContext);

    const deps = {
        displayMessage,
        kaleTablesService,
        tableDetailsFromServer,
        tableStatePayload,
        fieldsStatePayloads,
        setTableDetailsFromServer,
        tableIdentifiers,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const onSaveSidebarAnswersCb = useCallback((fieldId: number | null, answers: TableFieldAnswer[] | null): void => {
        const {
            displayMessage,
            kaleTablesService,
            tableDetailsFromServer,
            tableStatePayload,
            fieldsStatePayloads,
            setTableDetailsFromServer,
            tableIdentifiers,
        } = dependenciesRef.current;

        if (answers !== null) {
            // Persist the user's Sidebar answers into the corresponding table field state before saving
            const newFieldsStatePayloads = [...fieldsStatePayloads].map((tableFieldPayload): KaleTableFieldPayload => {
                const [answer] = tableFieldPayload;
                if (answer.fieldId === fieldId) {
                    return [...answers];
                }
                return tableFieldPayload;
            });

            const updateTable = async (): Promise<void> => {
                try {
                    setSidebarSaveLoading(true);
                    await saveTableDetails(
                        tableDetailsFromServer,
                        setTableDetailsFromServer,
                        tableStatePayload,
                        newFieldsStatePayloads,
                        tableIdentifiers,
                        kaleTablesService,
                        displayMessage
                    );
                    setSidebarSaveLoading(false);
                } catch (e) {
                    setSidebarSaveLoading(false);
                    setSidebarSaveError(true);
                }
            };

            updateTable().catch((e): void => console.error(e));
        }
    }, []);

    return { onSaveSidebarAnswersCb, sidebarSaveLoading, sidebarSaveError };
};
