import React from "react";
import { Flashbar } from "@amzn/awsui-components-react-v3";
import safeHTMLReactParser from "src/util/safeHTMLReactParser";

const initialState = {
    isItDownMessage: {
        level: 0,
        message: "",
    },
};

export interface Props {
    isItDownUrl: string;
    componentAlias: string; // identifies which customer facing product we're getting the health for
}

function initState(): State {
    return { ...initialState };
}

type State = Readonly<typeof initialState>;

export class IsItDownBanner extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = initState();

        this.onIsItDownClick = this.onIsItDownClick.bind(this);
    }

    public state: State = initialState;

    public componentDidMount(): void {
        this.fetchIsItDownStatus();
    }

    private fetchIsItDownStatus(): void {
        fetch(`${this.props.isItDownUrl}/components/${this.props.componentAlias}.js`)
            .then((results): Promise<any> => results.json())
            .then((data): void => {
                this.setState({ isItDownMessage: data });
            })
            .catch((): void => {
                this.setState({
                    isItDownMessage: {
                        level: 0,
                        message: "",
                    },
                });
            });
    }

    private getFlashBannerType(): "error" | "warning" | "info" {
        switch (this.state.isItDownMessage.level) {
            case 2:
                return "warning";
            case 3:
                return "error";
            default:
                return "info";
        }
    }

    private onIsItDownClick(): void {
        // eslint-disable-next-line
        window.location.href = this.props.isItDownUrl;
    }

    public render(): JSX.Element {
        return (
            <div id="is-it-down">
                {this.state.isItDownMessage.level != 0 && (
                    <Flashbar
                        id={this.getFlashBannerType() + "Banner"}
                        items={[
                            {
                                type: this.getFlashBannerType(),
                                content: safeHTMLReactParser(this.state.isItDownMessage.message),
                                dismissible: true,
                                buttonText: "Is-It-Down",
                                onButtonClick: this.onIsItDownClick,
                            },
                        ]}
                    />
                )}
            </div>
        );
    }
}

export default IsItDownBanner;
