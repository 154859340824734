import React from "react";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { DisplayLabels } from "src/components/survey/AccessControl/section/util";
import { TEST_IDS } from "shared/fields";
import { Box } from "@amzn/awsui-components-react-v3";

export const appOwner = (application: SurveyResponse): Pair => ({
    label: DisplayLabels.APP_OWNER,
    testId: TEST_IDS.ACCESS_CONTROL_SECTION.APP_OWNER,
    value: application.appInfo.review.applicationOwner || <Box variant="span">&mdash;</Box>,
});
