import React from "react";
import {
    PERSONAL_DATA_STATEMENT_TITLE,
    renderDataRegistryExampleElements,
} from "src/components/survey/PersonalDataWizard/content";
import { Container, Header, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";
import Model from "src/components/constants/Constants";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";
import { AppInfoResponse, HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import { OptionModel } from "src/components/constants/SurveyConstants";
import { Choice } from "src/services/KaleApplicationService";
import { PersonalDataWizardStep } from "src/components/survey/PersonalDataWizard/steps/index";
import { WizardProps } from "src/components/InlineWizard/interfaces";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import { LINKS } from "src/components/ExternalLinks";

interface UsePersonalDataQuestionStepProps {
    hasPersonalData: HasPersonalDataResponse;
    onChangeCallback: OnChangeCb;
    onClearDataStore: () => void;
    response: AppInfoResponse;
    setIsPDWComplete: (isPDWComplete: boolean) => void;
    skipValidation: boolean;
}

export const usePersonalDataQuestionStep = ({
    hasPersonalData,
    onChangeCallback,
    onClearDataStore,
    response,
    setIsPDWComplete,
    skipValidation,
}: UsePersonalDataQuestionStepProps): PersonalDataWizardStep => {
    const step: WizardProps.Step = {
        title: PERSONAL_DATA_STATEMENT_TITLE,
        infoCount: !Boolean(hasPersonalData) ? 1 : 0,
        description: undefined,
        content: (
            <Container data-testid={TEST_IDS.WIZARD.PERSONAL_DATA_STEP.CONTENT_ROOT} disableHeaderPaddings={true}>
                <SpaceBetween direction="vertical" size="xxl">
                    {
                        <div>
                            <Header
                                variant="h3"
                                description={
                                    <span>
                                        The following are examples of potential personal data identifiers. For a full
                                        list of personal data identifiers, refer to the{" "}
                                        <Link external href={LINKS.DATA_ELEMENT_REGISTRY}>
                                            Data Element Registry
                                        </Link>
                                        .
                                    </span>
                                }
                            >
                                Personal data identifiers
                            </Header>
                            {renderDataRegistryExampleElements()}
                        </div>
                    }
                    <div>
                        <Header
                            variant="h3"
                            description={
                                "Select the first option if the application persists personal data in any storage " +
                                "format. Choose the second option if the application does not store the personal " +
                                "data it processes."
                            }
                        >
                            Does your application process any personal data?
                        </Header>
                        <KaleRadiobutton
                            id={"hasPersonalData"}
                            formFieldDataTestId={TEST_IDS.WIZARD.PERSONAL_DATA_STEP.STATEMENT_FORM_FIELD}
                            isRequired={!skipValidation}
                            choices={Model.getData("hasPersonalData").options.map((option: OptionModel): Choice => {
                                return {
                                    value: option.name,
                                    label: option.displayName,
                                };
                            })}
                            expectedAnswer={hasPersonalData}
                            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                                onClearDataStore();
                                onChangeCallback({
                                    ...response,
                                    review: {
                                        ...response.review,
                                        // eslint-disable-next-line max-len
                                        hasPersonalData: result.response as HasPersonalDataResponse,
                                        willingToProvideInfo: "",
                                    },
                                });
                                setIsPDWComplete(false);
                            }}
                        />
                    </div>
                </SpaceBetween>
            </Container>
        ),
    };
    return { isLoading: false, step, error: "" };
};
