import * as React from "react";
import { useEffect, useState } from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { FormField, Input } from "@amzn/awsui-components-react-v3/polaris";

interface Props extends FunctionalSurveyItemProps<string> {
    showErrors?: boolean;
    placeholder?: string;
    isValid?: boolean;
    validationMsg?: string;
}

const NewTextInput = (props: Props): JSX.Element => {
    const {
        id,
        isRequired,
        isFormInReadonlyMode = false,
        isValid = true,
        validationMsg = "",
        expectedAnswer = "",
        onChangeCallback,
        showErrors = null,
        placeholder = "",
    } = props;

    const [localValue, setLocalValue] = useState<string>(expectedAnswer);

    useEffect((): void => {
        // Always overwrite local state when prop changes.
        setLocalValue(expectedAnswer);
    }, [expectedAnswer]);

    // Show error only when last updated timestamp is set. Backwards compatible.
    const hasChanged = showErrors === null || showErrors;

    const validationText = (localValue && !isValid && validationMsg) || "";
    const requiredText = hasChanged && isRequired && !Boolean(expectedAnswer) ? "Required" : "";

    return (
        <FormField stretch={true} errorText={validationText || requiredText}>
            <div>
                <Input
                    id={id}
                    disabled={isFormInReadonlyMode}
                    value={localValue}
                    placeholder={placeholder}
                    onChange={(e): void => {
                        setLocalValue(e.detail.value);
                    }}
                    onBlur={(): void => {
                        if (expectedAnswer !== localValue) {
                            // Only trigger an expensive global state update
                            // if the user changed the value
                            onChangeCallback?.call(null, {
                                id: id,
                                response: localValue,
                            });
                        }
                    }}
                />
            </div>
        </FormField>
    );
};

export default withFunctionalReadonly(NewTextInput);
