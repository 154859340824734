import { CardNames, SummaryCard } from "src/components/survey/ApplicationOverview/state/model";
import { Box, Icon, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { default as React } from "react";
import { useKaleSelector } from "src/redux/hooks";
import { selectSummaryCardByName } from "src/components/survey/ApplicationOverview/state/selectors";

interface CardHeaderProps {
    cardName: CardNames;
}
export const CardHeader = React.memo(({ cardName }: CardHeaderProps): JSX.Element | null => {
    const { response, ui } = useKaleSelector((state): SummaryCard => selectSummaryCardByName(state, cardName));

    if (!response) {
        // Nothing to display in header while card data is still initializing
        return null;
    } else {
        const creationTimestamp = new Date(response.createdAt).toLocaleString();
        return (
            <React.Fragment>
                <SpaceBetween size={"s"} direction={"horizontal"}>
                    <Icon {...ui.icon} />
                    {response.summary.title ?? null}
                </SpaceBetween>
                <Box variant="div" textAlign={"left"} fontSize="body-s">
                    Updated on {creationTimestamp}
                </Box>
            </React.Fragment>
        );
    }
});
