import { OptionDefinition } from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";

export enum ActionType {
    ApplicationInfoUpdate = "APPLICATION_INFO_UPDATE_LOADED",
}

export interface ApplicationInfoUpdateAction {
    type: ActionType.ApplicationInfoUpdate;
    payload: {
        control_bindle: OptionDefinition;
        related_bindles: OptionDefinition[];
        aaaIds: OptionDefinition[];
    };
}

export type ApplicationInfoAction = ApplicationInfoUpdateAction;
