// eslint-disable-next-line max-len
import { useTableIdentifiersFromRoute } from "src/components/TableDetails/TableDetailsPage/hooks/useTableIdentifiersFromRoute";
import { useKaleTableFieldSidebar } from "src/answers_legacy";
import { useEffect, useRef } from "react";

export const useResetSidebarOnRouteChange = (): void => {
    const tableIdentifiers = useTableIdentifiersFromRoute();
    const [, { resetAnswers: resetSidebarAnswers }] = useKaleTableFieldSidebar();

    const resetSidebarAnswersRef = useRef(resetSidebarAnswers);
    resetSidebarAnswersRef.current = resetSidebarAnswers;

    useEffect((): void => {
        resetSidebarAnswersRef.current();
    }, [tableIdentifiers]);
};
