import { useState } from "react";
import { localStorageConstants } from "src/util/localStorageConstants";

export enum ThemeMode {
    COMFORTABLE_MODE_ID = "Comfortable mode",
    COMPACT_MODE_ID = "Compact mode",
    LIGHT_MODE_ID = "Light mode",
    DARK_MODE_ID = "Dark mode",
}

// Polaris class names to be added to body
export const COMPACT_MODE_CLASS_NAME = "awsui-polaris-compact-mode";
export const DARK_MODE_CLASS_NAME = "awsui-polaris-dark-mode";

/** Hook to keep track of localStorage selected theme modes and handle mode change. */
export const useThemeMode = () => {
    // Disabling scan-js: localStorage can be unsafe.
    // This warning states values can be stolen or manipulated, but these are harmless values without business logic
    /* eslint-disable */
    const [compactModeEnabled, setCompactModeEnabled] = useState(
        Boolean(localStorage.getItem(localStorageConstants.theme.compactModeEnabled))
    );
    const [darkModeEnabled, setDarkModeEnabled] = useState(
        Boolean(localStorage.getItem(localStorageConstants.theme.darkModeEnabled))
    );
    const onModeChange = (id: string) => {
        switch (id) {
            case ThemeMode.COMFORTABLE_MODE_ID:
                document.body.classList.remove(COMPACT_MODE_CLASS_NAME);
                localStorage.removeItem(localStorageConstants.theme.compactModeEnabled);
                setCompactModeEnabled(false);
                break;
            case ThemeMode.COMPACT_MODE_ID:
                document.body.classList.add(COMPACT_MODE_CLASS_NAME);
                localStorage.setItem(localStorageConstants.theme.compactModeEnabled, "true");
                setCompactModeEnabled(true);
                break;
            case ThemeMode.DARK_MODE_ID:
                document.body.classList.add(DARK_MODE_CLASS_NAME);
                localStorage.setItem(localStorageConstants.theme.darkModeEnabled, "true");
                setDarkModeEnabled(true);
                break;
            case ThemeMode.LIGHT_MODE_ID:
                document.body.classList.remove(DARK_MODE_CLASS_NAME);
                localStorage.removeItem(localStorageConstants.theme.darkModeEnabled);
                setDarkModeEnabled(false);
                break;
        }
    };
    /* eslint-enable */

    return { compactModeEnabled, darkModeEnabled, onModeChange };
};
