import { ApplicationStatus, ApprovalType } from "src/components/survey/SurveyFormModel";
import React from "react";
import { Popover } from "@amzn/awsui-components-react-v3";
import { colorMap, StyledBadge, StyledSpan } from "src/components/ApprovalStatuses/utils";
import { getBadgeHelpContent } from "src/components/ApprovalStatuses/help";

// Named this way because it's currently used in multiple controls - the import / usage
// will be more explicit this way
export interface ApprovalStatusBadgeProps {
    status: ApplicationStatus;
    type: ApprovalType;
}

export const ApprovalStatusBadge = (props: ApprovalStatusBadgeProps): JSX.Element => {
    let badge: React.ReactNode;
    const shouldShowBadge = colorMap.has(props.status);
    if (shouldShowBadge) {
        badge = <StyledBadge color={colorMap.get(props.status)}>{props.status}</StyledBadge>;
    } else {
        badge = badge = <StyledSpan>&mdash;</StyledSpan>;
    }
    return (
        <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="custom"
            content={getBadgeHelpContent(props.status, props.type)}
        >
            {badge}
        </Popover>
    );
};
