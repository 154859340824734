import React, { useMemo, useState } from "react";
import { SpaceBetween, Tabs, TabsProps } from "@amzn/awsui-components-react-v3";
import { FormQuestionsPane } from "src/components/TableDetails/FormQuestionsPane/FormQuestionsPane";
import { TableAnswer, TableFieldSidebarHookAnswer, TableQuestion, useKaleTableFieldSidebar } from "src/answers_legacy";
import { SYNTH_UNIVERSAL_COMPLIANCE_TYPE } from "src/components/TableDetails/constants";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";

type GroupedSidebarAnswers = Map<string, TableFieldSidebarHookAnswer[]>;
const groupsideBarAnswersByComplianceType = (answers: TableFieldSidebarHookAnswer[]): GroupedSidebarAnswers => {
    // Separate answers into distinct lists of answers that are grouped by the complianceType of each question
    const groupedAnswers = new Map<string, typeof answers>();
    answers.forEach((answer): void => {
        const { complianceType } = answer.question;
        const group = groupedAnswers.get(complianceType) ?? [];
        group.push(answer);
        groupedAnswers.set(complianceType, group);
    });
    return groupedAnswers;
};

export const TEST_IDS = {
    UNIVERSAL_COMPLIANCE_GROUP: "sidebar-content--universal-compliance-group",
    COMPLIANCE_TAB_GROUPS: "sidebar-content--compliance-tab-groups",
};

export interface SidebarContentProps {
    complianceTypeVisibility: ComplianceTypeVisibility;
    shouldAutoSizeTextareas: boolean;
}

const NUM_COLUMNS_IN_SIDEBAR = 2;

const SidebarContent = (props: SidebarContentProps): JSX.Element => {
    const { complianceTypeVisibility, shouldAutoSizeTextareas } = props;

    const [{ answers: sidebarAnswers }] = useKaleTableFieldSidebar();

    const [activeTabId, setActiveTabId] = useState<string>("");
    const groupedSidebarQuestionData = useMemo(
        (): GroupedSidebarAnswers => groupsideBarAnswersByComplianceType(sidebarAnswers),
        [sidebarAnswers]
    );

    const universalComplianceGroup = groupedSidebarQuestionData.get(SYNTH_UNIVERSAL_COMPLIANCE_TYPE) || [];
    const complianceTypes = Object.keys(complianceTypeVisibility);
    const visibleComplianceTypes = complianceTypes.filter((type: string): boolean =>
        Boolean(complianceTypeVisibility[type])
    );
    const visibleTabs = visibleComplianceTypes.map((complianceType: string): TabsProps.Tab => {
        const answerGroup = groupedSidebarQuestionData.get(complianceType) || [];
        return {
            label: complianceType,
            id: complianceType,
            content: (
                <FormQuestionsPane<TableQuestion, TableAnswer>
                    questionAnswers={answerGroup}
                    numColumns={NUM_COLUMNS_IN_SIDEBAR}
                    shouldAutoSizeTextareas={shouldAutoSizeTextareas}
                />
            ),
        };
    });
    const activeTab = visibleTabs.find((tab): boolean => tab.id === activeTabId);
    if (!activeTab && visibleTabs.length > 0) {
        let nextAvailableTabId = "";
        visibleTabs.forEach((tab): void => {
            if (!nextAvailableTabId) {
                nextAvailableTabId = tab.id;
            }
        });
        setActiveTabId(nextAvailableTabId);
    }

    return (
        <SpaceBetween size={"xxl"}>
            <div data-testid={TEST_IDS.UNIVERSAL_COMPLIANCE_GROUP}>
                <FormQuestionsPane<TableQuestion, TableAnswer>
                    questionAnswers={universalComplianceGroup}
                    numColumns={NUM_COLUMNS_IN_SIDEBAR}
                    shouldAutoSizeTextareas={shouldAutoSizeTextareas}
                />
            </div>
            {visibleTabs.length ? (
                <Tabs
                    data-testid={TEST_IDS.COMPLIANCE_TAB_GROUPS}
                    tabs={visibleTabs}
                    activeTabId={activeTabId}
                    variant={"container"}
                    onChange={(e): void => {
                        setActiveTabId(e.detail.activeTabId);
                    }}
                />
            ) : null}
        </SpaceBetween>
    );
};

export default SidebarContent;
