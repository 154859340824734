import styled from "styled-components";
import { AutoSizingTextareaProps } from "./types";

const AutoSizingTextAreaStyles = `& {

        overflow: hidden !important;

        // We allow manual vertical resize on the textarea, to allow customer to manually reduce the height of textarea
        // with a lot of content. Manual horizontal resize is disabled because its too easy to accidentally change the
        // width of the textarea when you are trying to adjust the height, and any time there is a width change, the
        // textarea is going to run JS to relayout its height, fighting the manual height resize that the customer was
        // trying to perform
        resize: vertical !important;
    }`;

// Styles copied from Polaris 3.0 Textarea
const POLARIS_3_STYLES = {
    normalTextarea: `& {
            background-color: #ffffff;
            border-collapse: separate;
            border-radius: 2px;
            border-spacing: 0;
            border: 1px solid #aab7b8;
            box-sizing: border-box;
            color: #16191f);
            cursor: text;
            direction: ltr;
            display: block;
            font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
            font-feature-settings: normal;
            font-size: 1.4rem;
            font-stretch: normal;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            hyphens: none;
            letter-spacing: normal;
            line-height: 2.2rem;
            margin: 0;
            max-width: 100%;
            padding: 0.4rem 0.8rem;
            tab-size: 8;
            text-align-last: auto;
            text-align: left;
            text-indent: 0;
            text-shadow: none;
            text-transform: none;
            white-space: pre-wrap;
            widows: 2;
            width: 100%;
            word-break: break-word;
            word-spacing: normal;
        }

        &:focus {
            outline: 2px dotted transparent;
            border: 1px solid #00a1c9;
            box-shadow: 0 0 0 1px #00a1c9;
        }`,
    invalidTextarea: `
            &,
            &:focus {
              border-color: #d13212;
              border-left-width: 4px;
              color: #d13212;
              padding-left: calc(0.8rem - (4px - 1px));
              padding: 0.4rem;
            }
            `,
};

type AutoSizingTextareaStylingProps = Pick<AutoSizingTextareaProps, "invalid">;
export const StyledAutoSizingTextarea = styled.textarea<AutoSizingTextareaStylingProps>(
    ({ invalid }): string => `
      ${AutoSizingTextAreaStyles}  
      ${POLARIS_3_STYLES.normalTextarea}   
      ${invalid && POLARIS_3_STYLES.invalidTextarea}
`
);
