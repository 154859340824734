import React, { useEffect, useState, useContext } from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { Multiselect, MultiselectProps } from "@amzn/awsui-components-react-v3";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import Model from "src/components/constants/Constants";
import KaleContext from "src/components/KaleContext";
import { TEST_IDS } from "shared/fields";
import { KaleFormField } from "src/components/fields/KaleFormField";

export type ContactListProps = FunctionalSurveyItemProps<string[]> & {
    applicationName: string;
    expectedAnswer: string[];
};

export const VALIDATION_ERROR_MESSAGE = "At least one contact is required";

const { CONTACT_LIST } = TEST_IDS;

/** This component renders the contact list field in the legal review survey using polaris v3 multiselect.
 * It will take in an existing application name as well so it can return the members that have access to the application
 * once the application flow is changed in the near future.
 */
const ContactList = ({
    id,
    expectedAnswer, // value
    isFormInReadonlyMode = false,
    isRequired,
    isReadonly,
    applicationName,
    onChangeCallback, // set value
}: ContactListProps): JSX.Element => {
    const selectedOptions = expectedAnswer.map(
        (userAlias): MultiselectProps.Option => ({
            value: userAlias,
            label: userAlias,
        })
    );

    const isValid = !isRequired || Boolean(selectedOptions.length > 0);
    const kaleContext = useContext(KaleContext);

    const [multiselectStatusType, setMultiselectStatusType] = useState<MultiselectProps["statusType"]>("loading");
    const [options, setOptions] = useState<MultiselectProps.Option[]>([]);
    const [optionFetchingErrorMsg, setOptionFetchingErrorMsg] = useState<string>("");

    const fetchContactListOptions = async (applicationName: string): Promise<void> => {
        try {
            setMultiselectStatusType("loading");
            const result = await kaleContext.service.kaleAppService.fetchBindleMembers(applicationName);
            const loadedOptions = result.map(
                (userAlias: string): MultiselectProps.Option => ({
                    value: userAlias,
                    label: userAlias,
                })
            );
            setOptionFetchingErrorMsg("");
            setOptions(loadedOptions);
            setMultiselectStatusType("finished");
        } catch (e) {
            setMultiselectStatusType("error");
            setOptionFetchingErrorMsg((e as Error).message);
        }
    };

    useEffect((): void => {
        fetchContactListOptions(applicationName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readonly = isReadonly || isFormInReadonlyMode;
    const data = Model.getData(id);
    return (
        <KaleFormField
            label={data.name}
            description={data.question}
            errorText={isValid ? "" : VALIDATION_ERROR_MESSAGE}
        >
            <Multiselect
                selectedOptions={selectedOptions}
                data-testid={CONTACT_LIST}
                onChange={(e): void => {
                    onChangeCallback({
                        id: id,
                        response: e.detail.selectedOptions.map(
                            (option: MultiselectProps.Option): string => option.value ?? ""
                        ),
                    });
                }}
                disabled={readonly}
                options={options}
                placeholder="Choose options"
                filteringType="auto"
                errorText={optionFetchingErrorMsg}
                statusType={multiselectStatusType}
                loadingText={"Fetching Bindle Members"}
            />
        </KaleFormField>
    );
};

export const ContactListFunctionalReadOnly = withFunctionalReadonly(ContactList);
