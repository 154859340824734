import fetch from "@amzn/sentry-fetch";

/* GetTableIdentifiers API */
export interface TableIdentifier {
    team_name: string;
    host_endpoint: string;
    database_name: string;
    schema_name: string;
}

/* GetTables API */
export interface Table extends TableIdentifier {
    table_name: string;
    table_description: string;
    table_type: string;
}

/* GetTableDetails API  */
interface TableDetailsRequest extends TableIdentifier {
    table_name: string;
}

export interface ColumnDetail {
    column_name: string;
    data_type: string;
    description: string;
}
export interface TableDetails extends TableIdentifier {
    table_name: string;
    columns: ColumnDetail[];
}

const BASE_API_URL = "https://bumblebee.aka.amazon.com/api/v2/redshift";
const API_URLS = {
    // Get all available team names
    GET_TEAM_NAMES: `${BASE_API_URL}/teams`,
    // Get identifiers to provide all available team names, cluster endpoints, database names and schema names
    getTableIdentifiers: (teamName: string): string => `${BASE_API_URL}/team/${teamName}/identifiers`,
    // Get all Tables (names & descriptions) for the given set  of Team(s), Cluster(s), Database(s) and Schema(s)
    GET_TABLES: `${BASE_API_URL}/tables`,
    //  Get all Table details (all metadata) for the given array  of (table, team, cluster, database, schema)
    GET_TABLE_DETAILS: `${BASE_API_URL}/table-details`,
};

const getTeamNames = async (): Promise<string[]> => {
    const url = API_URLS.GET_TEAM_NAMES;
    const resp = await fetch(url, {
        mode: "cors",
        method: "GET",
    });
    if (resp.ok) {
        return resp.json() as unknown as string[];
    } else {
        throw Error(resp.statusText);
    }
};

const getTableIdentifiers = async (teamName: string): Promise<TableIdentifier[]> => {
    const url = API_URLS.getTableIdentifiers(teamName);
    const resp = await fetch(url, {
        mode: "cors",
        method: "GET",
    });
    if (resp.ok) {
        return resp.json() as unknown as TableIdentifier[];
    } else {
        throw Error(resp.statusText);
    }
};

const getTables = async (requestBody: TableIdentifier[]): Promise<Table[]> => {
    if (requestBody.length) {
        const url = API_URLS.GET_TABLES;
        const resp = await fetch(url, {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        });
        if (resp.ok) {
            return resp.json() as unknown as Table[];
        } else {
            throw Error(resp.statusText);
        }
    } else {
        return [];
    }
};

const getTableDetails = async (requestBody: TableDetailsRequest[]): Promise<TableDetails[]> => {
    if (requestBody.length) {
        const url = API_URLS.GET_TABLE_DETAILS;
        const resp = await fetch(url, {
            mode: "cors",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        });
        if (resp.ok) {
            return resp.json() as unknown as TableDetails[];
        } else {
            throw Error(resp.statusText);
        }
    } else {
        return [];
    }
};

const BumbleApi = {
    getTeamNames,
    getTableIdentifiers,
    getTables,
    getTableDetails,
};
export default BumbleApi;
