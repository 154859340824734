import SidebarLayout from "src/components/layout/SidebarLayout";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Header, Icon, Spinner } from "@amzn/awsui-components-react-v3";
import CommentsView from "src/components/CommentsView/CommentsView";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { CommentFromServer, NodeKaleApplicationService } from "src/services/NodeKaleApplicationService";
import { TEST_IDS } from "shared/comments-view";
import KaleContext from "src/components/KaleContext";
import Error from "src/util/Error";
import styled from "styled-components";

const { COMMENTS_VIEW_SIDEBAR } = TEST_IDS;

const HeaderContainer = styled.div`
    height: 40px;
`;

const IconContainer = styled.div`
    display: inline-block;
    top: -6px;
    position: relative;
`;

const useFetchCommentsForApplication = (
    applicationName: string,
    nodeKaleAppService: NodeKaleApplicationService
): { commentsFromServer: CommentFromServer[]; errorMsg: string; isLoading: boolean } => {
    const [commentsFromServer, setCommentsFromServer] = useState<CommentFromServer[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const deps = { nodeKaleAppService };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        // fetch new comments whenever the app name changes
        const fetchComments = async (): Promise<void> => {
            setIsLoading(true);
            const { nodeKaleAppService } = dependenciesRef.current;
            try {
                const commentsFromServer = await nodeKaleAppService.fetchComments(applicationName);
                setCommentsFromServer(commentsFromServer);
                setErrorMsg("");
            } catch (e) {
                setErrorMsg(`${(e as Error).message}` + " \n\n Please try refreshing the page");
                setCommentsFromServer([]);
            } finally {
                setIsLoading(false);
            }
        };
        fetchComments();
    }, [applicationName]);

    return { commentsFromServer, isLoading, errorMsg };
};

const SidebarBody = styled.div`
    padding: 8px 5px;
`;

const CommentsSidebar = (): JSX.Element => {
    const { applicationName } = useAppParamsFromRoute();
    const {
        user,
        service: { nodeKaleAppService },
    } = useContext(KaleContext);

    const { commentsFromServer, isLoading, errorMsg } = useFetchCommentsForApplication(
        applicationName,
        nodeKaleAppService
    );

    return (
        <div data-testid={COMMENTS_VIEW_SIDEBAR}>
            <SidebarLayout
                header={
                    <HeaderContainer>
                        <Header variant="h3">
                            <IconContainer>
                                <Icon name="contact" size="big" variant="normal" />
                            </IconContainer>
                            {` Comments`}
                        </Header>
                    </HeaderContainer>
                }
                footer={null}
                itemKey={applicationName || null}
            >
                <SidebarBody>
                    {isLoading ? (
                        <React.Fragment>
                            <Spinner size={"normal"} />
                            <span>Loading Comments ...</span>
                        </React.Fragment>
                    ) : errorMsg ? (
                        <Error text={errorMsg} />
                    ) : (
                        <CommentsView
                            applicationName={applicationName}
                            user={user}
                            fetchedComments={commentsFromServer}
                        />
                    )}
                </SidebarBody>
            </SidebarLayout>
        </div>
    );
};

export default CommentsSidebar;
