import { ApplicationStatus, ApprovalType } from "src/components/survey/SurveyFormModel";
import { Box, Link } from "@amzn/awsui-components-react-v3";
import React from "react";

export const getBadgeHelpContent = (status: ApplicationStatus, type: ApprovalType): React.ReactNode => {
    switch (type) {
        case ApprovalType.PrivacyApproval:
            return getlegalHelpStatus(status);
        case ApprovalType.AccountingApproval:
        case ApprovalType.TaxApproval:
        case ApprovalType.FraudApproval:
            return getTAFStatus(status);
    }
};

export const getlegalHelpStatus = (status: ApplicationStatus): JSX.Element | null => {
    const wiki = (
        <Link href={"https://w.amazon.com/bin/view/Kale#HReviewStatuses"} external>
            Click here
        </Link>
    );

    switch (status) {
        case ApplicationStatus.inProgress:
            return (
                <Box variant="p">
                    Kale will apply this label to the Privacy Status if the Kale Application has been started but not
                    yet been submitted for Privacy Review (or was recalled for editing). {wiki} for more information on
                    the Review Statuses.
                </Box>
            );
        case ApplicationStatus.inReview:
            return (
                <Box variant="p">
                    Kale will apply this label to the Privacy Status if the Kale Application has been submitted for
                    Privacy Review. For an update of the status of your review, please follow-up with members of your
                    reviewer group directly. To find your reviewer group, return to your completed Kale application, and
                    click on the &quot;Reviewer Group Name&quot; hyperlink. {wiki} for more information on the Review
                    Statuses.
                </Box>
            );
        case ApplicationStatus.approved:
            return (
                <Box variant="p">
                    Kale will apply this label to the Privacy Status if the Privacy Review has been completed by the
                    Privacy Reviewer Group. Please follow-up with members of the LDAP reviewer group (attached to your
                    application) directly if you have further questions after your review has been approved. {wiki} for
                    more information on the Review Statuses.
                </Box>
            );
        default:
            return null;
    }
};

export const getTAFStatus = (status: ApplicationStatus): JSX.Element => {
    const wiki = (
        <Link href={"https://w.amazon.com/bin/view/Kale#HReviewStatuses"} external>
            Click here
        </Link>
    );

    switch (status) {
        case ApplicationStatus.inProgress:
            return (
                <Box variant={"p"}>
                    Kale will apply this label to the applicable statuses that require Financial Reviews and have not
                    yet been submitted for Financial Review through Kale. {wiki} for more information on the Review
                    Statuses.
                    <br />
                    <Box variant="strong">Note:</Box> Your Financial Review is listed as &quot;In Progress&quot; if you
                    have not yet submitted your application to the Financial teams. {wiki} for more information on the
                    Review Statuses.
                </Box>
            );
        case ApplicationStatus.inReview:
            return (
                <Box variant={"p"}>
                    Kale will apply this label to the applicable statuses that have been submitted for Financial Review
                    through Kale. {wiki} for more information on the Review Statuses.
                </Box>
            );
        case ApplicationStatus.approved:
            return (
                <Box variant={"p"}>
                    Kale will apply this label to the applicable statuses after the Financial Review teams complete
                    their review in Kale. If you have an Approved status, please scroll to the bottom of your Financial
                    Review to see the recommended deletion decision from Accounting and/or CTPS. {wiki} for more
                    information on the Review Statuses.
                </Box>
            );
        default:
            return (
                <Box variant={"p"}>
                    Based on the information provided in the Kale application, Kale will apply this label to the
                    applicable statuses where we have not yet determined a need for Financial Reviews. To have Kale
                    automatically re-assess the need for financial reviews based on your data usage: please recall your
                    application, fill in the remaining required questions, and resubmit. {wiki} for more information on
                    the Review Statuses.
                </Box>
            );
    }
};
