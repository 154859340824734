import { CommentThread, CommentThreadGroup, FrontEndComment } from "src/components/CommentsView/state/model";

export const initializeNewCommentThread = (headComment: FrontEndComment): CommentThread => ({
    headCommentId: headComment.frontEndId,
    lastUpdated: headComment.createdAt,
    commentIds: [headComment.frontEndId],
});

/**
 * @param dateOfInterest is expected to be either a fully instantiated Javascript date object or a date ISO string.
 * @return a string formatted into the shape of our CommentThreadGroup headers => `${MonthName} ${Year}` which
 * corresponds to the name of the CommentThreadGroup that `dateOfInterest` would belong to.
 */
export const createGroupHeader = (dateOfInterest: string | Date): string => {
    const safeDate: Date = typeof dateOfInterest === "string" ? new Date(dateOfInterest) : dateOfInterest;

    return `${safeDate.toLocaleString("default", {
        month: "long",
    })} ${safeDate.toLocaleString("default", { year: "numeric" })}`;
};

/**
 * Initialize a new CommentThreadGroup to a specific Year & Month.
 * @param seedDate - The Date that the new CommentThread group should extract its Year and Month from
 */
export const createCommentThreadGroup = (seedDate: string | Date): CommentThreadGroup => {
    const safeDate = typeof seedDate === "string" ? new Date(seedDate) : seedDate;

    return {
        groupHeader: createGroupHeader(safeDate),
        month: safeDate.getMonth(),
        year: safeDate.getFullYear(),
        headCommentIds: [],
    };
};
