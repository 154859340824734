import { TEST_IDS } from "./test-ids";
import { tidSelector } from "../../util/selectors";

const { WIZARD } = TEST_IDS;

export const SELECTORS = {
    WIZARD: {
        ROOT: tidSelector(WIZARD.ROOT),
        NEXT_BUTTON: tidSelector(WIZARD.NEXT_BUTTON),
        CONFIRM_AND_SUBMIT_BUTTON: tidSelector(WIZARD.CONFIRM_AND_SUBMIT_BUTTON),
        EDIT_BUTTON: tidSelector(WIZARD.EDIT_BUTTON),
        STEPS: {
            RELATED_BINDLE: {
                ROOT: tidSelector(WIZARD.STEPS.RELATED_BINDLE.ROOT),
            },
            AAA_ID: {
                ROOT: tidSelector(WIZARD.STEPS.AAA_ID.ROOT),
            },
            ANVIL_ID: {
                ROOT: tidSelector(WIZARD.STEPS.ANVIL_ID.ROOT),
            },
            EXPLANATION: {
                ROOT: tidSelector(WIZARD.STEPS.EXPLANATION.ROOT),
            },
        },
    },
};
