import { PDCFiltersLoaded } from "src/services/RumService/rum-events";
import { useKaleServices } from "src/components/KaleContext";
import { useEffect, useMemo, useRef } from "react";
import { PDCFiltersProps } from "./types";
import { useKaleApplicationEventMetadata } from "src/components/survey/KaleApplication/useKaleApplicationEventMetadata";

type EventData = PDCFiltersLoaded.Options["data"];
type UseRecordFiltersLoadedEventProps = Pick<PDCFiltersProps, "filterGroups" | "selectedFilter">;

/** Custom hook is responsible for logging a custom Rum event when the PDCFilters are loaded into the UI **/
export const useRecordFiltersLoadedEvent = ({ selectedFilter, filterGroups }: UseRecordFiltersLoadedEventProps) => {
    const { rumService } = useKaleServices();

    const eventData = useEventData({ selectedFilter, filterGroups });
    const eventMetadata = useKaleApplicationEventMetadata();

    const deps = { rumService, eventData, eventMetadata };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(
        function onFiltersLoaded() {
            const { rumService, eventData, eventMetadata } = depsRef.current;
            const event = PDCFiltersLoaded.event({ data: eventData, metadata: eventMetadata });
            rumService?.recordEvent(event);
        },
        // Want to re-fire the event if any of the filter counts that we present to the user have changed
        [filterGroups]
    );
};

type UseEventDataProps = Pick<PDCFiltersProps, "filterGroups" | "selectedFilter">;
const useEventData = ({ filterGroups, selectedFilter }: UseEventDataProps): EventData => {
    const filtersData = useMemo(
        () =>
            Object.entries(filterGroups).reduce<EventData["filtersData"]>(
                (filtersData, [filterName, filteredItems]) => {
                    filtersData[filterName] = { filterName, filteredItemsCount: filteredItems.length };
                    return filtersData;
                },
                {}
            ),
        [filterGroups]
    );
    return { initialFilter: selectedFilter, filtersData: filtersData };
};
