import { WebSocketMessageBase } from "src/websocket/useOnWebSocketMessage";
import { useContext, useEffect } from "react";
import { WebSocketContext } from "src/websocket/WebSocketContext";

export interface UbergetCachePayload {
    requestId: string;
    reviewId: string;
    appName: string;
}

export interface UbergetCacheUpdatedMessage extends WebSocketMessageBase {
    payload: UbergetCachePayload;
}

export type UbergetCacheUpdatedMessageHandler = (message: UbergetCacheUpdatedMessage) => void;

export const useSubscribeToUbergetCacheUpdatedMessage = (callback: UbergetCacheUpdatedMessageHandler): void => {
    const { subscribeToUbergetCacheUpdatedMessage, unsubscribeToUbergetCacheUpdatedMessage } =
        useContext(WebSocketContext);

    useEffect((): (() => void) => {
        subscribeToUbergetCacheUpdatedMessage(callback);
        return (): void => unsubscribeToUbergetCacheUpdatedMessage(callback);
    }, [callback, subscribeToUbergetCacheUpdatedMessage, unsubscribeToUbergetCacheUpdatedMessage]);
};
