// All properties of lineage nodes
export const NAME_PROP = "name";
export const BINDLE_PROP = "bindleName";
export const FINANCIAL_RANK_PROP = "financialRank";
export const ACCOUNTING_RETENTION_PROP = "accountingRetentionDecision";
export const SOX_MONITORED_PROP = "isSoxRelevant";
export const SOX_UPSTREAM_PROP = "isSoxUpstream";
export const SOX_DOWNSTREAM_PROP = "isSoxDownstream";
export const SOX_SIBLING_PROP = "isSoxSibling";
export const SOX_OWNER_PROP = "isSoxOwner";
export const TAX_RELEVANT_PROP = "isTaxRelevant";
export const TAX_UPSTREAM_PROP = "isTaxUpstream";
export const TAX_DOWNSTREAM_PROP = "isTaxDownstream";
export const TAX_SIBLING_PROP = "isTaxSibling";
export const TAX_OWNER_PROP = "isTaxOwner";
export const CTI_PROP = "cti";
export const TYPE_PROP = "type";
export const LINEAGE_LINK_PROP = "lineageExplorerLink";
export const LINEAGE_BINDLE_LINK_PROP = "lineageExplorerBindleLink";
export const ID_PROP = "id";
export const ACC_RETENT_BOOL_PROP = "_accountingRetentionDecisionBoolean";
export const PREDICTION_VALUE_PROP = "value";
export const PREDICTION_CONFIDENCE_PROP = "confidence";

// Display value for properties that are not present on a node, defaulting to "-"
export const MISSING_PROP_VALUE = "-";

// Properties to display in Lineage Predictions table
export const ACC_RETENT_VAL_NAME = "Accounting Retention Decision Prediction Value";
export const ACC_RETENT_CONF_NAME = "Accounting Retention Decision Prediction Confidence";

export const RETAIN_DECISION = "Retain";
export const NO_RETAIN_DECISION = "Do not retain";

// Expected headers of the Lineage Predictions table
export const LINEAGE_PREDICTIONS_TABLE_KEYS: string[] = [
    NAME_PROP,
    TYPE_PROP,
    ACC_RETENT_VAL_NAME,
    ACC_RETENT_CONF_NAME,
];

// Lineage Explorer's prod url
export const LINEAGE_EXPLORER_BASE_URL = "https://lineageexplorer.data-governance.privacy.a2z.com/?id=";

// Lineage API error messages
export const GET_NODE_BY_ID_ERR_MESSAGE = "Failed to fetch nodes";
