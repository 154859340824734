import { AutosuggestProps } from "@amzn/awsui-components-react-v3";
import { useContext, useEffect, useState } from "react";
import { TableDefinition } from "src/components/schema_table/SchemaTable";
import { fetchAllTablesPages, fetchAndesV2, fetchSchema, ParsedSchemaResult } from "src/services/AndesService";
import KaleContext from "src/components/KaleContext";
import { SelectProps } from "@amzn/awsui-components-react-v3/polaris";

export const useFetchTablesOnMount = (): {
    isLoadingTables: boolean;
    tableOptionGroups: SelectProps.OptionGroup[];
    tableDistinctCount: number;
    tableError: string;
} => {
    const [isLoadingTables, setIsLoadingTables] = useState<boolean>(false);
    const [tableOptions, setTableOptions] = useState<SelectProps.OptionGroup[]>([]);
    const [tableDistinctCount, setTableDistinctCount] = useState<number>(0);
    const [tableError, setTableError] = useState<string>("");
    const kaleContext = useContext(KaleContext);

    useEffect((): void => {
        setIsLoadingTables(true);
        setTableError("");
        let distinctOptionsCount = 0;
        const groupMap = new Map<string, SelectProps.Option[]>();
        fetchAllTablesPages(kaleContext.user.userId)
            .then((res): void => {
                const tables: AutosuggestProps.OptionGroup[] = [];
                res.forEach((page): void => {
                    page.data?.forEach((item): void => {
                        const groupMapArray = groupMap.get(item.providerId);
                        const newArray = [
                            ...(groupMapArray ?? []),
                            {
                                label: item.tableName,
                                // without value field there is a visual bug that
                                // does not highlight the selected value
                                value: `${item.tableName}-${item.providerId}`,
                                labelTag: item.providerId,
                            },
                        ];
                        groupMap.set(item.providerId, newArray);
                        distinctOptionsCount++;
                    });
                });
                groupMap.forEach((options, providerID): void => {
                    tables?.push({
                        label: `Provider ID: ${providerID}`,
                        // without value field there is a visual bug that
                        // does not highlight the selected value
                        value: providerID,
                        options,
                    });
                });
                if (tables.length === 0) {
                    setTableError("No tables associated with your user alias.");
                    return;
                }
                setTableDistinctCount(distinctOptionsCount);
                setTableOptions(tables);
            })
            .catch((err: Error): void => {
                setTableError(err.message);
            })
            .then((): void => {
                setIsLoadingTables(false);
            });
    }, [kaleContext.user.userId]);

    return { isLoadingTables, tableOptionGroups: tableOptions, tableError, tableDistinctCount };
};

export const useFetchVersions = (
    tableChoice: string,
    providerId: string
): {
    isLoadingVersion: boolean;
    versionOptions: AutosuggestProps.Options;
    versionError: string;
} => {
    const [isLoadingVersion, setIsLoadingVersion] = useState<boolean>(false);
    const [versionOptions, setVersionOptions] = useState<AutosuggestProps.Options>([]);
    const [versionError, setVersionError] = useState<string>("");

    useEffect((): void => {
        setVersionOptions([]);
        setVersionError("");
        if (!Boolean(tableChoice) || !Boolean(providerId)) {
            return;
        }
        setIsLoadingVersion(true);
        fetchAndesV2(`/providers/${providerId}/tables/${tableChoice}/versions?limit=200`)
            .then((res): void => {
                if (!Boolean(res.data?.length)) {
                    setVersionError("version list has no data");
                    return;
                }
                setVersionOptions(
                    res.data?.map((item): AutosuggestProps.Option => {
                        return {
                            value: item.versionNumber.toString(),
                            labelTag: item.audit.timestamp,
                        };
                    }) ?? []
                );
            })
            .catch((err: Error): void => {
                setVersionError(err.message);
            })
            .then((): void => {
                setIsLoadingVersion(false);
            });
    }, [providerId, tableChoice]);

    return { isLoadingVersion, versionOptions, versionError };
};

export const useFetchSchema = (
    providerId: string,
    tableName: string,
    version: string
): {
    isLoadingSchema: boolean;
    tableDefinitions: TableDefinition[];
    schemaError: string;
} => {
    const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(false);
    const [tableDefinitions, setTableDefinitions] = useState<TableDefinition[]>([]);
    const [schemaError, setSchemaError] = useState<string>("");

    useEffect((): void => {
        setTableDefinitions([]);
        setSchemaError("");
        if (!providerId || !tableName || !version) {
            return;
        }
        setIsLoadingSchema(true);
        fetchSchema(providerId, tableName, version)
            .then((res: ParsedSchemaResult): void => {
                setTableDefinitions([
                    {
                        name: tableName,
                        source: providerId,
                        andesProvider: providerId,
                        schemaVersion: version,
                        rawSchema: res.rawSchema,
                        schema: res.parsedSchema,
                    },
                ]);
            })
            .catch((error: Error): void => {
                setSchemaError(error.message);
            })
            .then((): void => {
                setIsLoadingSchema(false);
            });
    }, [providerId, tableName, version]);

    return { isLoadingSchema, tableDefinitions, schemaError };
};
