export const TEST_IDS = {
    WIZARD: {
        ROOT: "access-control-wizard",
        NEXT_BUTTON: "inline-wizard-next-button",
        CONFIRM_AND_SUBMIT_BUTTON: "inline-wizard-submit-button",
        EDIT_BUTTON: "survey-form-edit-access-control",
        STEPS: {
            RELATED_BINDLE: {
                ROOT: "acw-related-bindles",
            },
            AAA_ID: {
                ROOT: "acw-aaa-id",
            },
            ANVIL_ID: {
                ROOT: "acw-anvil-id",
            },
            EXPLANATION: {
                ROOT: "acw-explanation",
            },
        },
    },
};
