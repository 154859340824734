import { Answer } from "src/answers_legacy";
import { ContentKeyMap } from "src/services/dynamic-questions";
import {
    ReviewAttributeShortId,
    DataStoreAttributeShortId,
    syntheticReviewQuestionMap,
    syntheticDataStoreQuestionMap,
} from "src/components/TAF/TAFDetails/constants";

type DataStoreAttributeShortIdKey = keyof typeof DataStoreAttributeShortId;

const DataStoreAttributeShortIds = new Map<DataStoreAttributeShortIdKey, DataStoreAttributeShortId>(
    Object.entries(DataStoreAttributeShortId).map(
        ([key, value]): [DataStoreAttributeShortIdKey, DataStoreAttributeShortId] => [
            key as DataStoreAttributeShortIdKey,
            value,
        ]
    )
);

const DataStoreAttributeKeys = new Map<DataStoreAttributeShortId, DataStoreAttributeShortIdKey>(
    Object.entries(DataStoreAttributeShortId).map(
        ([key, value]): [DataStoreAttributeShortId, DataStoreAttributeShortIdKey] => [
            value,
            key as DataStoreAttributeShortIdKey,
        ]
    )
);

type ReviewAttributeShortIdKey = keyof typeof ReviewAttributeShortId;

const ReviewAttributeShortIds = new Map<ReviewAttributeShortIdKey, ReviewAttributeShortId>(
    Object.entries(ReviewAttributeShortId).map(([key, value]): [ReviewAttributeShortIdKey, ReviewAttributeShortId] => [
        key as ReviewAttributeShortIdKey,
        value,
    ])
);

const ReviewAttributeKeys = new Map<ReviewAttributeShortId, ReviewAttributeShortIdKey>(
    Object.entries(ReviewAttributeShortId).map(([key, value]): [ReviewAttributeShortId, ReviewAttributeShortIdKey] => [
        value,
        key as ReviewAttributeShortIdKey,
    ])
);

const attributesToAnswers = <PType extends object>(
    attributes: PType,
    lookupKey: string,
    lookupId: number | string
): Answer[] => {
    return Object.keys(attributes)
        .map((attribute: string): Answer | null => {
            const reviewShortId = ReviewAttributeShortIds.get(attribute as ReviewAttributeShortIdKey);
            const dataStoreShortId = DataStoreAttributeShortIds.get(attribute as DataStoreAttributeShortIdKey);
            const shortId = reviewShortId || dataStoreShortId;

            const questionMap = {
                ...syntheticReviewQuestionMap,
                ...syntheticDataStoreQuestionMap,
            };

            const {
                id: questionId,
                shortId: questionShortId,
                type,
            } = questionMap[shortId as ReviewAttributeShortId & DataStoreAttributeShortId] ?? {};

            return questionShortId
                ? {
                      [lookupKey]: lookupId,
                      questionId,
                      questionShortId,
                      [ContentKeyMap[type]]: attributes[attribute as keyof PType],
                  }
                : null;
        })
        .filter((answer): boolean => !!answer) as Answer[];
};

const answersToAttributes = <PType>(answers: Answer[]): PType => {
    const payload: PType | object = {};

    answers.forEach((answer: Answer): void => {
        const reviewAttribute = ReviewAttributeKeys.get(
            answer.questionShortId as ReviewAttributeShortId
        ) as keyof PType;
        const dataStoreAttribute = DataStoreAttributeKeys.get(
            answer.questionShortId as DataStoreAttributeShortId
        ) as keyof PType;

        const attribute = reviewAttribute || dataStoreAttribute;

        const questionMap = {
            ...syntheticReviewQuestionMap,
            ...syntheticDataStoreQuestionMap,
        };

        const question = questionMap[answer.questionShortId as ReviewAttributeShortId & DataStoreAttributeShortId];

        if (question) {
            const contentValue = answer[ContentKeyMap[question.type]];

            if (attribute) {
                // Inferring any so we can add non-union content values
                (payload as unknown as any)[attribute] = contentValue;
            } else {
                console.warn(`Unknown attribute: ${String(attribute)}`);
            }
        } else {
            console.warn(`Unknown question: ${answer.questionShortId}`);
        }
    });

    return payload as PType;
};

export { answersToAttributes, attributesToAnswers };
