import {
    ApplicationUserRules,
    PermissionMapping,
    UserAction,
    UserRole,
    UserRoleApplicationStatuses,
} from "src/permissions";
import { ApplicationStatusCallback } from "src/components/fields/hoc/withReadonly";
import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";

interface DynamicRuleCallback {
    (applicationStatusCallback: ApplicationStatusCallback): boolean;
}

const selectAnyApplicationStatusByRole =
    (role: UserRole, roleApplicationStatuses: UserRoleApplicationStatuses): DynamicRuleCallback =>
    (applicationStatusCallback: ApplicationStatusCallback): boolean => {
        const applicationStatus = applicationStatusCallback(roleApplicationStatuses[role]);
        return (applicationStatus && roleApplicationStatuses[role].includes(applicationStatus)) ?? false;
    };

export const createUserRules = (canPermissionMapping: PermissionMapping): KeyValuePairs<ApplicationUserRules> => {
    const result: KeyValuePairs<ApplicationUserRules> = {};

    Object.keys(UserRole).forEach((roleKey: string): void => {
        const role = roleKey as UserRole;

        result[role] = {
            value: {},
        };

        Object.keys(UserAction)
            .filter((userActionKey: string): boolean => !isNaN(Number(UserAction[userActionKey as unknown as number])))
            .forEach((userActionKey: string): void => {
                const userAction = UserAction[userActionKey as unknown as number];
                const roleApplicationStatuses = canPermissionMapping[userAction as unknown as UserAction];

                if (roleApplicationStatuses) {
                    result[role].value[userAction] = selectAnyApplicationStatusByRole(role, roleApplicationStatuses);
                }
            });
    });

    return result;
};
