import { Textarea } from "@amzn/awsui-components-react-v3/polaris";
import React, { useContext, useEffect, useState } from "react";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/index";
import { AnswerBase, HookAnswer, QuestionBase } from "src/answers_legacy";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import AutoSizingTextarea from "src/components/TableDetails/AutoSizingTextArea/AutoSizingTextarea";

const NUM_ROWS = 2;

type TextAreaQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
    shouldFitHeightToContent: boolean;
} & FormQuestionForwardingProps;

export const TextareaQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    props: TextAreaQuestionProps<QType, AType>
): JSX.Element => {
    const {
        "data-testid": dataTestId,
        questionAnswer: { isValid, setValue },
        shouldFitHeightToContent = false,
    } = props;

    const value = props.questionAnswer.value as string;
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;
    const [localValue, setLocalValue] = useState<string>(value);

    useEffect((): void => {
        // Always overwrite local state when prop changes.
        setLocalValue(value);
    }, [value]);

    // A generic change event shape that needs to match both Polaris' Textarea
    // change event and React's HTMLTextareaElement change event
    interface GenericChangeEvent {
        detail: { value: string };
    }
    // Throttle how often we update global state by only calling the setter from the onBlur handler
    const onChangeCb = (e: GenericChangeEvent): void => setLocalValue(e.detail.value);
    const onBlurCb = (): void => {
        if (value !== localValue) {
            // Only trigger an expensive global state update
            // if the user changed the value
            setValue(localValue);
        }
    };

    return shouldFitHeightToContent ? (
        <AutoSizingTextarea
            data-testid={dataTestId}
            disabled={isReadonly}
            value={localValue}
            onChange={onChangeCb}
            onBlur={onBlurCb}
            minRows={NUM_ROWS}
            invalid={!isValid}
        />
    ) : (
        <Textarea
            data-testid={dataTestId}
            disabled={isReadonly}
            value={localValue}
            onChange={onChangeCb}
            onBlur={onBlurCb}
            rows={NUM_ROWS}
            invalid={!isValid}
        />
    );
};
