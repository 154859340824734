import {
    Alert,
    Box,
    NonCancelableCustomEvent,
    Popover,
    StatusIndicator,
    TableProps,
} from "@amzn/awsui-components-react-v3";
import React from "react";
import {
    DataStoreQuestion,
    DataStoresAccessorReadonly,
    DataStoresPayload,
    findDataStoreAccessor,
    QuestionTag,
} from "src/answers_legacy";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import DataStoreTableActionStripe from "src/components/survey/DataStoreInfo/DataStoreTable/DataStoreTableActionStripe";
import {
    HeaderItem,
    NewRowErrorIconContainer,
    PopoverIcon,
} from "src/components/survey/DataStoreInfo/DataStoreTable/styled";
import {
    getAnswerAsString,
    getQuestionsInStepOrder,
    isAndes,
    isDataStoresInfoStepValid,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { findPayload } from "src/components/survey/LegalSurveyForm";
import {
    hasChildDataShortId,
    hasTeenDataShortId,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/ChildDataGatingQuestionGroup";
import KaleContext from "src/components/KaleContext";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import { chooseColumnWidthByTitle, COL_SIZES } from "src/components/survey/DataStoreInfo/DataStoreTable/table-utils";
import { getTextContent, KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";

export const DELETE_WITH_TABLES_ERROR =
    "Unable to delete the data store. Please transfer or delete all tables " +
    "that exist in the data store before attempting to delete it.";

type DataStoreTableColumn = TableProps.ColumnDefinition<DataStoreResponse>;

interface DataStoreTableProps {
    onAddDataStoreClick: () => void;
    onDeleteDataStoreClick: (dataStoreIndex: number) => void;
    onEditDataStoreClick: (dataStoreIndex: number) => void;
    isReadOnly: boolean;
    /** A list of data stores that a user has confirmed their intent to create. These include all data stores that are
     * already saved on the backend record but also any local unsaved datastores which the user has created by going all
     * the way through to the final step of the wizard and clicking the button to apply the new datastore to the unsaved
     * draft, rather then exiting the wizard by cancelling while a user was in the processs of creating a brand new
     * datastore. */
    committedDataStores: DataStoreResponse[];
    payload: DataStoresPayload[];
    questions: DataStoreQuestion[];
    accessors: DataStoresAccessorReadonly[] | null;
    displayMessage: DisplayMessageCb;
    atLeastOneDataStoreIsRequired: boolean;
}

interface DataStoreTableState {
    committedDataStores: DataStoreResponse[];
    selectedItem: DataStoreResponse | null;
    columnDefinitions: DataStoreTableColumn[] | null;
}

class DataStoreTable extends React.PureComponent<DataStoreTableProps, DataStoreTableState> {
    public static contextType = KaleContext;

    public constructor(props: DataStoreTableProps) {
        super(props);

        this.state = {
            selectedItem: null,
            committedDataStores: props.committedDataStores,
            columnDefinitions: this.makeColumnDefinitions(props.questions),
        };

        this.getSelectedIndex = this.getSelectedIndex.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.renderActionStripe = this.renderActionStripe.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<DataStoreTableProps>): void {
        if (prevProps.committedDataStores !== this.props.committedDataStores) {
            // Always synchronize GSM with local data store state as currently there is no
            // callback from GSM that enables us to know when it completes an operation.
            // This can be problematic when we override stashedAccessor when user cancels wizard
            const committedDataStores = this.props.committedDataStores.map((dataStore): DataStoreResponse => {
                const dsAnswers = findPayload(this.props.payload, dataStore.id, dataStore.unsavedUUID);
                return { ...dataStore, dataStoreAnswers: dsAnswers ?? [] };
            });
            this.setState({
                committedDataStores,
                // When a data store is edited and saved, a new list of data stores
                // is received from props. We need to clear our internal selectedItem
                // state to make sure we match the appearance of the polaris table which
                // re-renders itself with no items in the table selected.
                selectedItem: null,
            });
        }
        if (prevProps.questions !== this.props.questions) {
            // Making the column definitions is an expensive operation, only perform it when the questions have changed
            this.setState({
                columnDefinitions: this.makeColumnDefinitions(this.props.questions),
            });
        }
    }

    private getSelectedIndex(): number {
        return this.state.committedDataStores.findIndex((response): boolean => {
            return (
                (Boolean(response.id) && response.id === this.state.selectedItem?.id) ||
                (Boolean(response.unsavedUUID) && response.unsavedUUID === this.state.selectedItem?.unsavedUUID)
            );
        });
    }

    private onSelectionChange(event: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<any>>): void {
        this.setState({ selectedItem: event.detail.selectedItems[0] as DataStoreResponse });
    }

    private getFilterKeys(): string[] {
        return ["name", "description", "identifier", "technology"];
    }

    private makeColumnDefinitions(questions: DataStoreQuestion[]): DataStoreTableColumn[] {
        const columnDefinitions: DataStoreTableColumn[] = [];

        columnDefinitions.push(
            ...[
                {
                    id: "name",
                    header: "Name",
                    width: COL_SIZES.SMALL,
                    cell: (response: DataStoreResponse): JSX.Element => {
                        const accessorCounterpart = findDataStoreAccessor(
                            this.props.accessors ?? [],
                            response.id,
                            response.unsavedUUID
                        );
                        const isCollectInfoStepValid = isDataStoresInfoStepValid([response]);
                        const areDynamicQuestionStepsValid = accessorCounterpart?.isValidSubmit;
                        const isValid = isCollectInfoStepValid && areDynamicQuestionStepsValid;
                        return (
                            <React.Fragment>
                                {!isValid && (
                                    <NewRowErrorIconContainer>
                                        <Popover
                                            dismissButton={false}
                                            position="top"
                                            size="medium"
                                            triggerType="custom"
                                            content={
                                                <StatusIndicator type={"warning"}>
                                                    Please fill out the mandatory datastore fields before submitting
                                                </StatusIndicator>
                                            }
                                        >
                                            <PopoverIcon name="status-warning" size="normal" variant="warning" />
                                        </Popover>
                                    </NewRowErrorIconContainer>
                                )}
                                {response.name}
                            </React.Fragment>
                        );
                    },
                },
                {
                    id: "description",
                    header: "Description",
                    width: COL_SIZES.SMALL,
                    cell: (response: DataStoreResponse): string => response.description,
                },
                {
                    id: "identifier",
                    header: "Identifier",
                    width: COL_SIZES.SMALL,
                    cell: (response: DataStoreResponse): string =>
                        isAndes(response.technology)
                            ? ""
                            : response.instances
                                  .map((instance): string =>
                                      instance.identifiers
                                          .map((id: any): string => [id.type, id.value].join(" = "))
                                          .join(", ")
                                  )
                                  .join("\n"),
                },
                {
                    id: "technology",
                    header: "Technology",
                    width: COL_SIZES.SMALL,
                    cell: (response: DataStoreResponse): string => response.technology,
                },
            ]
        );

        // Sort the questions into the same order used by the DataStoreWizard
        const sortedQuestions = getQuestionsInStepOrder(questions);

        columnDefinitions.push(
            ...sortedQuestions
                .filter((question): boolean => {
                    return !question.tags.includes(QuestionTag.legacyHidden);
                })
                .map((question): DataStoreTableColumn => {
                    let title = question.title || question.content;
                    if (question.shortId === hasChildDataShortId) {
                        title = "Child Data";
                    } else if (question.shortId === hasTeenDataShortId) {
                        title = "Teen Data";
                    }
                    const header = KaleHTMLParser(title);
                    return {
                        id: question.shortId,
                        header: (
                            <HeaderItem className={"dataStoreTableHeader"} title={getTextContent(header)}>
                                {header}
                            </HeaderItem>
                        ),
                        width: chooseColumnWidthByTitle(title),
                        cell: (response: DataStoreResponse): string =>
                            getAnswerAsString(question, response.dataStoreAnswers) ?? "",
                    };
                })
        );

        return columnDefinitions;
    }

    private renderActionStripe(): JSX.Element {
        const { isReadOnly, onAddDataStoreClick, onDeleteDataStoreClick, onEditDataStoreClick, committedDataStores } =
            this.props;
        const { selectedItem } = this.state;

        return (
            <DataStoreTableActionStripe
                onAddDataStoreClick={onAddDataStoreClick}
                onDeleteDataStoreClick={(): void => {
                    const selectedRowIndex = this.getSelectedIndex();
                    if (committedDataStores?.[selectedRowIndex]?.tables.length > 0) {
                        this.props.displayMessage(MessageType.error, DELETE_WITH_TABLES_ERROR);
                    } else {
                        onDeleteDataStoreClick(selectedRowIndex);
                        this.setState({ selectedItem: null });
                    }
                }}
                onEditDataStoreClick={(): void => {
                    const selectedRowIndex = this.getSelectedIndex();
                    onEditDataStoreClick(selectedRowIndex);
                }}
                isReadOnly={isReadOnly}
                responses={committedDataStores}
                selectedItem={selectedItem}
            />
        );
    }

    public render(): JSX.Element | null {
        const { committedDataStores, columnDefinitions } = this.state;

        if (columnDefinitions === null) {
            return null;
        }

        return (
            <DASTable<DataStoreResponse>
                data-testid={"dataStoreTable"}
                id={"dataStoreTable"}
                tableName={"Data stores in this application"}
                tableDescription={<Box variant="p">Add all data stores used by your application</Box>}
                isLoading={false}
                columnDefinitions={columnDefinitions}
                filterProps={{ type: DASTableFilterType.propertyFilter, filterKeys: this.getFilterKeys() }}
                onSelectionChange={this.onSelectionChange}
                rowItems={committedDataStores}
                isResizable={true}
                selectionType={"single"}
                renderActionStripe={this.renderActionStripe}
                emptyTextPrefix={"No data stores"}
                emptyTemplateElement={
                    this.props.atLeastOneDataStoreIsRequired && (
                        <Alert type={"warning"} header={"Required"}>
                            At least one data store is required to submit your application for review
                        </Alert>
                    )
                }
            />
        );
    }
}

export default DataStoreTable;
