import { default as React } from "react";
import { ContentPane } from "src/components/survey/ApplicationOverview/ContentPane/ContentPane";
import { Box, SpaceBetween, Spinner, StatusIndicator } from "@amzn/awsui-components-react-v3";
import { CardNames, SummaryCard } from "src/components/survey/ApplicationOverview/state/model";
import { useKaleSelector } from "src/redux/hooks";
import {
    selectSummaryCardByName,
    selectSummaryCardItemsCount,
} from "src/components/survey/ApplicationOverview/state/selectors";
import styled from "styled-components";
import { TEST_IDS } from "shared/survey";

const { CARD_BODY } = TEST_IDS.APPLICATION_OVERVIEW.SUMMARY_CARDS;

const DataStatus = styled.div`
    text-align: center !important;
    margin-top: 4px;
    padding: 3px 8px;
`;

const noItemsMessage: React.ReactNode = (
    <Box data-testid={CARD_BODY.NO_SUMMARY_ITEMS_MESSAGE} margin={{ top: "m" }} fontSize={"heading-s"}>
        No items to display
    </Box>
);

const hasNotInitialized: React.ReactNode = <React.Fragment />;

interface CardBodyProps {
    cardName: CardNames;
}

/**
 * A Component responsible for rendering card summary content and any data status messages
 */
export const CardBody = React.memo(({ cardName }: CardBodyProps): JSX.Element => {
    const itemsCount = useKaleSelector((state): number => selectSummaryCardItemsCount(state, cardName));
    const { response, ui } = useKaleSelector((state): SummaryCard => selectSummaryCardByName(state, cardName));
    const { isLoading, fetchError } = ui;

    const doesSummaryHaveItems = itemsCount > 0;
    const summaryContent = (function IIFE(): React.ReactNode {
        if (!response) {
            return hasNotInitialized;
        } else if (doesSummaryHaveItems) {
            return <ContentPane data-testid={CARD_BODY.SUMMARY_ITEMS_CONTENT} descriptors={response.summary.content} />;
        } else {
            return noItemsMessage;
        }
    })();

    const dataStatusMessage = (function IIFE(): React.ReactNode {
        if (isLoading) {
            return (
                <DataStatus>
                    <Spinner />
                    {"   "}
                    <Box variant="span" fontSize="body-m">
                        Retrieving latest data
                    </Box>
                </DataStatus>
            );
        } else if (fetchError) {
            return (
                <DataStatus data-testid={CARD_BODY.FETCH_ERROR_MESSAGE}>
                    <StatusIndicator type="error">{fetchError}</StatusIndicator>
                </DataStatus>
            );
        } else {
            return null;
        }
    })();

    return (
        <SpaceBetween data-testid={CARD_BODY.ROOT} data-card-name={cardName} size={"s"}>
            {dataStatusMessage}
            {summaryContent}
        </SpaceBetween>
    );
});
