import { FormField, Input } from "@amzn/awsui-components-react-v3";
import React from "react";
import { TEST_IDS } from "shared/new-application";

const { APPLICATION_NAME_FIELD } = TEST_IDS;

interface ApplicationNameFieldProps {
    onChange: (value: string) => void;
    value: string;
}
const ApplicationNameField = ({ onChange, value }: ApplicationNameFieldProps): JSX.Element => (
    <FormField
        label="Application Name"
        description={
            <span>
                Enter a unique name to identify your application. You should use the Anvil application name, if one
                exists. This <b>cannot</b> be changed afterwards.
            </span>
        }
    >
        <Input
            id={"applicationNameField"}
            data-testid={APPLICATION_NAME_FIELD}
            value={value}
            onChange={({ detail }) => onChange(detail.value)}
            onBlur={() => onChange(value.trim())}
        />
    </FormField>
);

export default React.memo(ApplicationNameField);
