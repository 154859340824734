import { Box, Icon, Link, SpaceBetween, StatusIndicator, TableProps } from "@amzn/awsui-components-react-v3";
import React from "react";
import {
    ACC_RETENT_BOOL_PROP,
    ACC_RETENT_CONF_NAME,
    ACC_RETENT_VAL_NAME,
    ID_PROP,
    LINEAGE_EXPLORER_BASE_URL,
    LINEAGE_PREDICTIONS_TABLE_KEYS,
    MISSING_PROP_VALUE,
    NAME_PROP,
    NO_RETAIN_DECISION,
    PREDICTION_CONFIDENCE_PROP,
    PREDICTION_VALUE_PROP,
    RETAIN_DECISION,
    TYPE_PROP,
} from "src/components/TAF/FinancialGRE/constants";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import { useLineagePredictionsContext } from "src/components/TAF/FinancialGRE/components/LineagePredictionsContext";
import { TEST_IDS } from "shared/taf-view";
import { LineageGlossary, PredictionsTableFlattenedNodes } from "src/services/external-lineage/types";

const wikiLink = (
    <Link href={"https://w.amazon.com/bin/view/Consumer_Privacy/Accounting/DataLineage"} external>
        wiki
    </Link>
);
const desc = (
    <p>
        These data lineage predictions should be used to help you identify if your application needs to retain data. The
        table shows the predicted retention decision at the bindle/AAA level (Not for a datastore), made by
        Lineage&apos;s machine learning model. Learn more about our data lineage technology by visiting our {wikiLink}
    </p>
);

const generateTooltip = (property: string, glossary: LineageGlossary): JSX.Element => {
    // Lookup display names and descriptions from the glossary
    const propDisplayName = glossary[property]?.display_name ?? property;
    const propDesc = glossary[property]?.description ?? "";
    return <div title={propDesc}>{propDisplayName}</div>;
};

const generatePredictionCell = (predictionVal: string): JSX.Element | string => {
    // Accounting requested to make "Retain" predictions more visible, so we're adding an icon here
    if (predictionVal === "true") {
        return (
            <SpaceBetween size={"xs"} direction={"horizontal"}>
                <Icon name="status-warning" variant="error" />
                {RETAIN_DECISION}
            </SpaceBetween>
        );
    }
    return NO_RETAIN_DECISION;
};

const generateColumns = (glossary: LineageGlossary): TableProps.ColumnDefinition<any>[] => {
    // Each column needs specific work to format the cell, so just statically define the column definitions
    return [
        {
            id: NAME_PROP,
            header: generateTooltip(NAME_PROP, glossary),
            sortingField: NAME_PROP,
            cell: (item): JSX.Element | string | number | null =>
                item[NAME_PROP] ? (
                    // To link to Lineage Explorer we need the ID, but in the table we want to display the name
                    <Link href={LINEAGE_EXPLORER_BASE_URL + item[ID_PROP]} external={true}>
                        {item[NAME_PROP]}
                    </Link>
                ) : (
                    MISSING_PROP_VALUE
                ),
        },
        {
            id: TYPE_PROP,
            header: generateTooltip(TYPE_PROP, glossary),
            sortingField: TYPE_PROP,
            cell: (item): string | number | null => (item[TYPE_PROP] ? item[TYPE_PROP] : MISSING_PROP_VALUE),
        },
        {
            id: ACC_RETENT_VAL_NAME,
            header: generateTooltip(ACC_RETENT_VAL_NAME, glossary),
            sortingField: ACC_RETENT_VAL_NAME,
            cell: (item): JSX.Element | string | null => generatePredictionCell(item[ACC_RETENT_VAL_NAME]),
        },
        {
            id: ACC_RETENT_CONF_NAME,
            header: generateTooltip(ACC_RETENT_CONF_NAME, glossary),
            sortingField: ACC_RETENT_CONF_NAME,
            cell: (item): number | null =>
                item[ACC_RETENT_CONF_NAME] ? item[ACC_RETENT_CONF_NAME] : MISSING_PROP_VALUE,
        },
    ];
};

/**
 * This component is responsible for displaying the predictions received from the GetNodesById API in a
 * table. It fetches this data from the LineagePredictionsContext hook.
 *
 * Note: Kale's alpha/beta environments use the preprod Bindles API to get IDs. Lineage uses prod bindles API for every
 * stage so Kale's alpha/beta environments will always have deeplinks to Lineage that will result in "no node found" as
 * the IDs differ.
 */
export const LineagePredictionsTable = (): JSX.Element => {
    const { nodes, glossary, isLoading, error } = useLineagePredictionsContext();
    // If an error is returned from the data fetching, leverage the empty table props to display the error in the table
    const emptyTableMessage = error ? (
        <StatusIndicator type="error">{error.toString()}</StatusIndicator>
    ) : (
        <Box variant="p">No Resources Found</Box>
    );
    /*
     * Default collection hook can't deal with nested properties in our nodes, so we have two options:
     *   1. Flatten the nodes from the API response
     *   2. Have custom filtering/sorting logic using DASTable's filtering internal override
     * Going with 1 as it's much easier to implement and as noted in comments, the internal override is not recommended
     */
    let flattenedNodes: PredictionsTableFlattenedNodes[] = [];
    if (nodes) {
        flattenedNodes = Object.values(nodes).map(({ id, properties, predictions }) => {
            return {
                id,
                [NAME_PROP]: properties[NAME_PROP],
                [TYPE_PROP]: properties[TYPE_PROP],
                [ACC_RETENT_VAL_NAME]: predictions[ACC_RETENT_BOOL_PROP][PREDICTION_VALUE_PROP],
                [ACC_RETENT_CONF_NAME]: predictions[ACC_RETENT_BOOL_PROP][PREDICTION_CONFIDENCE_PROP],
            };
        });
        // Accounting has requested the items in the table be default sorted by "Retain" predictions at the top,
        // then by highest confidence
        flattenedNodes.sort(
            (a, b) =>
                b[ACC_RETENT_VAL_NAME].localeCompare(a[ACC_RETENT_VAL_NAME]) ||
                b[ACC_RETENT_CONF_NAME] - a[ACC_RETENT_CONF_NAME]
        );
    }

    return (
        <DASTable<any>
            id={"lineage-predictions-table"}
            data-testid={TEST_IDS.PREDICTIONS_TABLE.TABLE}
            tableName={"Lineage Predictions"}
            tableDescription={desc}
            isLoading={isLoading}
            columnDefinitions={generateColumns(glossary ?? {})}
            rowItems={flattenedNodes}
            emptyTableMessage={emptyTableMessage}
            filterProps={{
                type: DASTableFilterType.propertyFilter,
                placeholder: "Filter by properties",
                filterKeys: LINEAGE_PREDICTIONS_TABLE_KEYS,
            }}
            isResizable={true}
            isStickyHeader={true}
            stripedRows={true}
        />
    );
};
