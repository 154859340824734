import React, { useReducer } from "react";
import isDeepEqual from "lodash.isequal";
import {
    ADD_ANSWERS,
    CreateAnswersAction,
    REMOVE_ANSWERS,
    RESET_ANSWERS,
    RESET_AND_ADD_ANSWERS,
    ResetAnswersAction,
    StateKey,
    UPDATE_ANSWERS,
    UpdateAnswersAction,
    AddOptionsAction,
    ResetOptionsAction,
    ADD_OPTIONS,
    RESET_OPTIONS,
    StateOptionsKey,
    RESET_CHANGE_DETECTION,
    ResetChangeDetectionAction,
} from "../actions";
import { Answers, AnswerState, initialState, Response } from "../context";
import { AnswerBase, AnswerWithQuestion, ContentKey, QuestionBase, QuestionTag, QuestionType } from "../models/common";
import { answerIsValid, answerIsValidSave, answerIsValidSubmit } from "../validation";
import { reduceAnswersIsValid } from "./helpers";
import {
    DefaultTableAnswer,
    DefaultTableFieldAnswer,
    TableAnswerKey,
    TableFieldAnswerKey,
    TableAnswer,
    DataStoreAnswerKey,
    DefaultDataStoreAnswer,
    Answer,
    ReviewAnswerKey,
    DefaultReviewAnswer,
    AnswerWithQuestionResponse,
    Question,
} from "../models/services";

const ItemKey = {
    [StateKey.review]: ReviewAnswerKey.reviewId,
    [StateKey.dataStores]: DataStoreAnswerKey.dataStoreId,
    [StateKey.kaleTable]: TableAnswerKey.tableId,
    [StateKey.kaleTableFields]: TableFieldAnswerKey.fieldId,
    [StateKey.bulkEditKaleTable]: TableAnswerKey.tableId,
    [StateKey.bulkEditKaleTableFields]: TableFieldAnswerKey.fieldId,
    [StateKey.kaleTableFieldSidebar]: TableFieldAnswerKey.fieldId,
};

const ItemUuidKey = {
    [StateKey.review]: ReviewAnswerKey.userReviewId,
    [StateKey.dataStores]: DataStoreAnswerKey.userDataStoreId,
    [StateKey.kaleTable]: TableAnswerKey.userTableId,
    [StateKey.kaleTableFields]: TableFieldAnswerKey.userFieldId,
    [StateKey.bulkEditKaleTable]: TableAnswerKey.userTableId,
    [StateKey.bulkEditKaleTableFields]: TableFieldAnswerKey.userFieldId,
    [StateKey.kaleTableFieldSidebar]: TableFieldAnswerKey.userFieldId,
};

const ParentRecordKey = {
    [StateKey.review]: ReviewAnswerKey.reviewId,
    [StateKey.dataStores]: DataStoreAnswerKey.dataStoreId,
    [StateKey.kaleTable]: null,
    [StateKey.kaleTableFields]: TableAnswerKey.tableId,
    [StateKey.bulkEditKaleTable]: null,
    [StateKey.bulkEditKaleTableFields]: TableAnswerKey.tableId,
    [StateKey.kaleTableFieldSidebar]: TableAnswerKey.tableId,
};

const ParentUuidRecordKey = {
    [StateKey.review]: ReviewAnswerKey.userReviewId,
    [StateKey.dataStores]: DataStoreAnswerKey.userDataStoreId,
    [StateKey.kaleTable]: TableAnswerKey.userTableId,
    [StateKey.kaleTableFields]: TableFieldAnswerKey.userFieldId,
    [StateKey.bulkEditKaleTable]: TableAnswerKey.userTableId,
    [StateKey.bulkEditKaleTableFields]: TableFieldAnswerKey.userFieldId,
    [StateKey.kaleTableFieldSidebar]: TableFieldAnswerKey.userFieldId,
};

export const mutateAnswerContentKey = (answer: Answer, question: Question): Answer => {
    let answerContentKey: ContentKey;

    switch (question.type) {
        case QuestionType.multiSelect:
        case QuestionType.checkboxGroup:
        case QuestionType.textTags: {
            answerContentKey = ContentKey.arrayContent;
            break;
        }
        case QuestionType.date: {
            answerContentKey = ContentKey.dateContent;
            break;
        }
        case QuestionType.retention: {
            answerContentKey = ContentKey.jsonContent;
            break;
        }
        default: {
            answerContentKey = ContentKey.textContent;
        }
    }

    Object.values(ContentKey).forEach((contentKey): void => {
        if (answer && contentKey !== answerContentKey && (answer[contentKey] === null || answer[contentKey] === "")) {
            delete answer[contentKey];
        }
    });

    return answer;
};

const getActionRecordIdKey = (action: CreateAnswersAction): string | null => {
    return action.uuid ? ItemUuidKey[action.stateKey] : ItemKey[action.stateKey];
};

const getAnswersRecordIdKey = (action: UpdateAnswersAction): string | null => {
    const questionWithAnswer = action.answers[0];

    if (!questionWithAnswer) {
        return null;
    }

    const uuid =
        questionWithAnswer?.answer?.[
            ItemUuidKey[action.stateKey] as keyof (TableAnswer | TableFieldAnswerKey | AnswerBase)
        ];

    return uuid ? ItemUuidKey[action.stateKey] : ItemKey[action.stateKey];
};

const getParentRecordIdKey = (action: CreateAnswersAction): string | null => {
    return action.parentRecordUuid ? ParentUuidRecordKey[action.stateKey] : ParentRecordKey[action.stateKey];
};

const getDefaultAnswer = (
    stateKey: StateKey
): ((
    questionId: number,
    questionShortId: string,
    recordId?: number,
    userRecordId?: string,
    parentRecordId?: number,
    parentUserRecordId?: string
) => AnswerBase | undefined) => {
    switch (stateKey) {
        case StateKey.review: {
            return DefaultReviewAnswer;
        }
        case StateKey.dataStores: {
            return DefaultDataStoreAnswer;
        }
        case StateKey.kaleTable:
        case StateKey.bulkEditKaleTable: {
            return DefaultTableAnswer;
        }
        case StateKey.kaleTableFields:
        case StateKey.kaleTableFieldSidebar:
        case StateKey.bulkEditKaleTableFields: {
            return DefaultTableFieldAnswer;
        }
        default: {
            return (): undefined => undefined;
        }
    }
};

interface AnswerMap<T> {
    [k: string]: T;
}

const selectAnswerCollections = (answers: Answer[], idKey: string | null): Answer[][] | undefined => {
    if (!idKey) {
        return;
    }

    const aMap: Record<string, Answer[]> = {};

    answers.forEach((answer: Answer): void => {
        const a = answer[idKey as keyof Omit<Answer, ContentKey>];

        if (a && !aMap[a]) {
            aMap[a] = [];
        }

        if (a) {
            aMap[a].push(answer);
        }
    });

    return Object.keys(aMap).map((idKey): Answer[] => aMap[idKey]);
};

function addAction<T extends AnswerWithQuestion>(
    state: AnswerState,
    action: CreateAnswersAction,
    hasReset = false
): AnswerState {
    if (hasReset) {
        state = {
            ...state,
            [action.stateKey]: [...initialState[action.stateKey]],
        };
    }

    const DefaultAnswer = getDefaultAnswer(action.stateKey);
    const idKey = getActionRecordIdKey(action);

    const parentRecordIdKey = getParentRecordIdKey(action);
    const initialStateSlice = initialState[action.stateKey];

    const targetId = (action.answers[0] && action.answers[0][idKey as keyof AnswerBase]) || action.id || action.uuid;
    const parentRecordId =
        (action.answers[0] && action.answers[0][parentRecordIdKey as unknown as keyof AnswerBase]) ||
        action.parentRecordId ||
        action.parentRecordUuid;

    if (!targetId) {
        console.error(`Error looking up slice by ${idKey} ${targetId}`);
        return state;
    }

    let answerCollections = selectAnswerCollections(action.answers, idKey);

    // There is no lookup id on new rows, so create an empty row for iterator
    if (answerCollections?.length === 0) {
        answerCollections = [[]];
    }

    const newState = [...state[action.stateKey]];

    answerCollections?.forEach((answers): void => {
        const targetId = (answers[0] && answers[0][idKey as keyof AnswerBase]) || action.id || action.uuid;
        const stateItem =
            newState.find(
                (item): boolean => item.value[0] && item.value[0].value.answer[idKey as keyof AnswerBase] === targetId
            ) ||
            (isDeepEqual(initialStateSlice, newState) && newState[0]) ||
            ((): Response<[]> => {
                const item: Response<[]> = {
                    value: [],
                    isApplicable: false,
                    isValid: false,
                    isValidSave: false,
                    isValidSubmit: false,
                    hasChanged: false,
                    userLastUpdated: "",
                };
                newState.push(item);
                return item;
            })();
        const stateItemIndex = newState.indexOf(stateItem);

        const newAnswers: Response<T>[] = [];

        const aMap: AnswerMap<AnswerBase> = {};
        const { questions } = action;

        if (action.id && !answers.length) {
            answers = Array(questions.length).fill(
                {
                    [idKey as keyof AnswerBase]: action.id,
                },
                0,
                questions.length
            );
        }

        answers.forEach((answer: AnswerBase): void => {
            aMap[answer.questionShortId] = answer;
        });
        questions.forEach((question): void => {
            let answer = aMap[question.shortId];

            answer = mutateAnswerContentKey(answer, question as Question);

            let newAnswer: Response<T>;

            if (answer) {
                newAnswer = {
                    value: { question, answer: answer as AnswerBase },
                    isValid: false,
                    isValidSave: false,
                    isValidSubmit: false,
                    hasChanged: false,
                    userLastUpdated: "",
                } as unknown as Response<T>;
            } else {
                const onlyHasUuid = Boolean(!action.id && action.uuid);
                const onlyHasParentRecordUuid = Boolean(!action.parentRecordId && action.parentRecordUuid);

                const answerRecordId = !onlyHasUuid ? (targetId as number) : undefined;
                const answerUserRecordId = onlyHasUuid ? (targetId as string) : undefined;
                const answerParentRecordId = !onlyHasParentRecordUuid ? (parentRecordId as number) : undefined;
                const answerParentUserRecordId = onlyHasParentRecordUuid ? (parentRecordId as string) : undefined;

                newAnswer = {
                    value: {
                        question,
                        answer: DefaultAnswer(
                            question.id,
                            question.shortId,
                            answerRecordId,
                            answerUserRecordId,
                            answerParentRecordId,
                            answerParentUserRecordId
                        ) as AnswerBase,
                    },
                    isValid: false,
                    isValidSave: false,
                    isValidSubmit: false,
                    hasChanged: false,
                    userLastUpdated: "",
                } as unknown as Response<T>;
            }

            newAnswers.push(newAnswer);
        });
        const updatedValue = reduceAnswersIsValid<T>([...newAnswers], state, action.stateKey);

        const originalValue = [...updatedValue];
        const isValid = answerIsValid<T>(originalValue);
        const isValidSave = answerIsValidSave<T>(originalValue);
        const isValidSubmit = answerIsValidSubmit<T>(originalValue);

        const value = originalValue as unknown as AnswerWithQuestionResponse[];

        newState[stateItemIndex] = {
            value,
            isValid,
            isValidSave,
            isValidSubmit,
            hasChanged: value.some((response): boolean => response.hasChanged),
            userLastUpdated: new Date().toISOString(),
        };
    });

    return {
        ...state,
        [action.stateKey]: [...newState],
    };
}

export const answersReducer = (
    state: AnswerState,
    originalAction:
        | ResetAnswersAction
        | UpdateAnswersAction
        | CreateAnswersAction
        | AddOptionsAction<QuestionBase, AnswerBase>
        | ResetOptionsAction
): AnswerState => {
    const debugState = (state: AnswerState): AnswerState => {
        // @ts-ignore
        window.__KALE_ANSWERS_CONTEXT_DEVTOOLS__ &&
            // @ts-ignore
            window.__KALE_ANSWERS_CONTEXT_DEVTOOLS__.send(`${originalAction.stateKey}:${originalAction.type}`, state);
        return state;
    };
    switch (originalAction.type) {
        case ADD_ANSWERS: {
            const action = originalAction as CreateAnswersAction;
            const newState = addAction<AnswerWithQuestion>(state, action);

            return debugState(newState);
        }
        case RESET_AND_ADD_ANSWERS: {
            const action = originalAction as CreateAnswersAction;
            const newState = addAction<AnswerWithQuestion>(state, action, true);

            return debugState(newState);
        }
        case REMOVE_ANSWERS:
        case UPDATE_ANSWERS: {
            const action = originalAction as UpdateAnswersAction;
            const isRemove = originalAction.type === REMOVE_ANSWERS;
            const stateSlice = state[action.stateKey] as Answers<AnswerWithQuestion>[];

            const idKey = getAnswersRecordIdKey(action);
            const targetId = action.answers[0].answer?.[idKey as keyof AnswerBase] as number;

            if (!targetId) {
                console.error(`Error looking up slice by ${idKey} ${targetId}`);
                return state;
            }

            const stateItem = stateSlice.find(
                (item): boolean => item.value[0] && item.value[0].value.answer[idKey as keyof AnswerBase] === targetId
            );

            if (!stateItem) {
                console.error(`Error looking up slice item by ${idKey} ${targetId}`);
                return state;
            }

            const stateItemIndex = stateSlice.indexOf(stateItem);

            const handler = isRemove ? stateItem.value.filter : stateItem.value.map;
            const newAnswers: Response<AnswerWithQuestion>[] = handler
                .call(
                    stateItem.value,
                    (stateAnswer: Response<AnswerWithQuestion>): Response<AnswerWithQuestion> | boolean => {
                        const updatedAnswers = action.answers.find((answerWithQuestion): boolean => {
                            return (
                                !answerWithQuestion.question?.tags?.includes(QuestionTag.readOnly) &&
                                answerWithQuestion.answer?.questionShortId === stateAnswer?.value.answer.questionShortId
                            );
                        });

                        if (isRemove) {
                            return !updatedAnswers;
                        }

                        return (
                            (updatedAnswers && {
                                ...stateAnswer,
                                value: {
                                    ...stateAnswer.value,
                                    ...updatedAnswers,
                                },
                                hasChanged: true,
                                userLastUpdated: new Date().toISOString(),
                            }) ||
                            stateAnswer
                        );
                    }
                )
                .filter((v): boolean => !!v) as Response<AnswerWithQuestion>[];

            const updatedValue = reduceAnswersIsValid<AnswerWithQuestion>(newAnswers, state, action.stateKey);

            const value = [...updatedValue];
            const isValid = answerIsValid<AnswerWithQuestion>(value);
            const isValidSave = answerIsValidSave<AnswerWithQuestion>(value);
            const isValidSubmit = answerIsValidSubmit<AnswerWithQuestion>(value);

            let newState = [...state[action.stateKey]];

            newState[stateItemIndex] = {
                value: value as unknown as AnswerWithQuestionResponse[],
                isValid,
                isValidSave,
                isValidSubmit,
                hasChanged: true,
                userLastUpdated: new Date().toISOString(),
            };
            if (isRemove && newState[stateItemIndex].value.length === 0) {
                newState = newState.filter((item, index): boolean => index !== stateItemIndex);
            }
            return debugState({
                ...state,
                [action.stateKey]: [...newState],
            });
        }
        case RESET_ANSWERS: {
            const action = originalAction as ResetAnswersAction;
            return debugState({
                ...state,
                [action.stateKey]: [...initialState[action.stateKey]],
            });
        }
        case ADD_OPTIONS: {
            const action = originalAction as AddOptionsAction<QuestionBase, AnswerBase>;
            const stateSlice = state[action.stateKey] as Answers<AnswerWithQuestion>[];

            // Apply updated applicable-ness and validation with the newly provided options
            // so that existing subscribers can consume the latest state.
            const newStateSlice = stateSlice.map((stateItem): Answers<AnswerWithQuestion> => {
                const updatedValue = reduceAnswersIsValid<AnswerWithQuestion>(
                    stateItem.value,
                    state,
                    action.stateKey,
                    action.options
                );

                // Detect whether or not answers have changed so to trigger any re-memoization.
                const notChanged = stateItem.value.every((answer, index): boolean => {
                    return updatedValue[index].userLastUpdated === answer.userLastUpdated;
                });

                const value = [...updatedValue];
                const isValid = answerIsValid<AnswerWithQuestion>(value);
                const isValidSave = answerIsValidSave<AnswerWithQuestion>(value);
                const isValidSubmit = answerIsValidSubmit<AnswerWithQuestion>(value);
                const userLastUpdated = notChanged ? stateItem.userLastUpdated : new Date().toISOString();

                return {
                    value: value as unknown as AnswerWithQuestionResponse[],
                    isValid,
                    isValidSave,
                    isValidSubmit,
                    hasChanged: !notChanged,
                    userLastUpdated,
                };
            });

            return debugState({
                ...state,
                [StateKey[action.stateKey]]: newStateSlice,
                [StateOptionsKey[action.stateKey]]: { ...action.options },
            });
        }
        case RESET_OPTIONS: {
            const action = originalAction as ResetOptionsAction;
            return debugState({
                ...state,
                [StateOptionsKey[action.stateKey]]: {},
            });
        }
        case RESET_CHANGE_DETECTION: {
            const action = originalAction as ResetChangeDetectionAction;
            const stateSlice = state[action.stateKey] as Answers<AnswerWithQuestion>[];

            const newStateSlice = stateSlice.map((stateItem): Answers<AnswerWithQuestion> => {
                stateItem.hasChanged = false;

                stateItem.value.map((response): Response<AnswerWithQuestion> => {
                    response.hasChanged = false;

                    return response;
                });

                return stateItem;
            });

            return debugState({
                ...state,
                [StateKey[action.stateKey]]: newStateSlice,
            });
        }
        default: {
            throw new Error(`Unsupported action type: ${originalAction.type}`);
        }
    }
};

export const useAnswersReducer = (): [
    AnswerState,
    React.Dispatch<CreateAnswersAction | UpdateAnswersAction | ResetAnswersAction>
] => {
    return useReducer(answersReducer, initialState);
};
