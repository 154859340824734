import { SummaryResponse } from "src/services/summaries/model/summaries";
import { IconProps } from "@amzn/awsui-components-react-v3";

// Represents the Data for a SummaryCard inside the ApplicationOverview
export interface SummaryCard {
    response: SummaryResponse | null;
    ui: {
        isLoading: boolean;
        fetchError: string;
        icon: Pick<IconProps, "name" | "variant">;
    };
}

export enum CardNames {
    personalDataSummary = "personalDataSummary",
    privacyAlertsSummary = "privacyAlertsSummary",
}

export interface SummaryCardsSlice {
    /** Record of all SummaryCards, indexed by card name */
    byCardName: Record<CardNames, SummaryCard>;
    /** List of card names in display order */
    displayOrder: CardNames[];
}

export interface ApplicationOverviewState {
    summaryCards: SummaryCardsSlice;
}
