import React, { useCallback, useRef, useState } from "react";
import { Box } from "@amzn/awsui-components-react-v3";
import { ProgressBar, ProgressBarProps } from "@amzn/awsui-components-react-v3";
import { useSubscribeToBulkEditMessage } from "src/websocket/message/BulkEdit";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import styled from "styled-components";
import pluralize from "pluralize";

const StyledProgressBar = styled(ProgressBar)((props: ProgressBarProps): string => {
    let backgroundColor = "var(--awsui-color-background-notification-green)";
    if (props.status === "in-progress") {
        backgroundColor = "var(--awsui-color-background-notification-blue)";
    }
    return `
    color: white !important;
    background-color: ${backgroundColor};
    padding: 1rem;
`;
});

interface Props {
    isSaving: boolean;
    requestId: string;
    onCompleted: (didSucceed: boolean) => void;
    displayMessage: DisplayMessageCb;
}

export const BulkEditProgressBar = (props: Props): JSX.Element => {
    const { isSaving, requestId, onCompleted, displayMessage } = props;
    const deps = { displayMessage, onCompleted };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const [progressBarValue, setProgressBarValue] = useState<number>(0);
    const progressBarTotalSegments = useRef<number>(0);
    const progressBarCurrentSegment = useRef<number>(0);

    useSubscribeToBulkEditMessage(
        useCallback(
            (message): void => {
                if (message.payload.requestId !== requestId) {
                    return;
                }

                const { displayMessage, onCompleted } = depsRef.current;
                switch (message.payload.status) {
                    case "started":
                        setProgressBarValue(0);
                        progressBarTotalSegments.current = message.payload.sqlCallsCount;
                        break;
                    case "in progress":
                        progressBarCurrentSegment.current++;
                        if (progressBarTotalSegments.current !== 0) {
                            setProgressBarValue(
                                (progressBarCurrentSegment.current / progressBarTotalSegments.current) * 100
                            );
                        }
                        break;
                    case "completed":
                        onCompleted(message.payload.error === "");
                        if (message.payload.error !== "") {
                            displayMessage(MessageType.error, message.payload.error);
                        } else {
                            const result = message.payload.response;
                            const successMsg = (
                                <Box variant="p">
                                    Bulk edit process completed
                                    <ul>
                                        <li>{`Number of table ${pluralize(
                                            "status",
                                            result?.savedStatusesCount
                                        )} updated: ${result?.savedStatusesCount}`}</li>
                                        <li>{`Number of table ${pluralize(
                                            "personal data",
                                            result?.savedHasPersonalDataCount
                                        )} updated: ${result?.savedHasPersonalDataCount}`}</li>
                                        <li>{`Number of table ${pluralize(
                                            "answer",
                                            result?.savedTableAnswersCount
                                        )} updated: ${result?.savedTableAnswersCount}`}</li>
                                        <li>{`Number of field ${pluralize(
                                            "answer",
                                            result?.savedFieldAnswersCount
                                        )} updated: ${result?.savedFieldAnswersCount}`}</li>
                                    </ul>
                                </Box>
                            );
                            displayMessage(MessageType.success, successMsg);
                        }
                        progressBarTotalSegments.current = 0;
                        progressBarCurrentSegment.current = 0;
                }
            },
            [requestId]
        )
    );

    return (
        <React.Fragment>
            {isSaving && (
                <StyledProgressBar
                    label={progressBarValue < 100 ? "Applying edits to selected tables" : ""}
                    description={progressBarValue < 100 ? "Please wait while we finish applying edits" : ""}
                    value={progressBarValue}
                    variant={"flash"}
                    status={progressBarValue < 100 ? "in-progress" : "success"}
                    resultText={"Bulk edit completed successfully"}
                />
            )}
        </React.Fragment>
    );
};
