import { FormField, Box, Button, TableProps } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "src/components/fields/Loader";
import DASTable from "src/components/fields/table/DASTable";
import { TableAttribute, TableDefinition } from "src/components/schema_table/SchemaTable";
import BumbleApi, { Table as BumbleTable, TableDetails } from "src/services/BumblebeeService";
import styled from "styled-components";
import {
    BUMBLEBEE_SOURCE,
    FETCH_TABLE_DETAILS_ERROR,
} from "src/components/schema_table/importers/bumblebee_importer/constants";

export const MAX_TABLE_SELECTION = 100;
export const TOO_MANY_SELECTED_TABLES_ERROR = `Table selection must not exceed ${MAX_TABLE_SELECTION} tables`;
export const TEST_IDS = {
    SPINNER: "bumblebee-importer-table-picker-spinner",
    FETCH_SCHEMA: "bumblebee-importer-table-picker-fetch-schema-button",
};

const FormSectionFooter = styled.div`
    text-align: center !important;
    padding: 5px;
`;

export const fetchTableDetails = async (
    selectedTables: BumbleTable[]
): Promise<{ tableDetailsList: TableDetails[]; fetchTableDetailsError: string }> => {
    let tableDetailsList: TableDetails[];
    let fetchTableDetailsError: string;

    const fetchMessagePrefix = "Fetching Table Details from Bumblebee.";
    try {
        console.log(fetchMessagePrefix);
        tableDetailsList = await BumbleApi.getTableDetails(selectedTables);
        fetchTableDetailsError = "";
        console.log(`${fetchMessagePrefix}: Success`);
    } catch (err) {
        console.error(`${fetchMessagePrefix}: Failed`, err);
        tableDetailsList = [];
        fetchTableDetailsError = FETCH_TABLE_DETAILS_ERROR;
    }

    return {
        tableDetailsList,
        fetchTableDetailsError,
    };
};

const columnDefinitions: TableProps.ColumnDefinition<BumbleTable>[] = [
    {
        id: "schemaName",
        header: "Schema Name",
        cell: (item): string => item.schema_name,
    },
    {
        id: "tableName",
        header: "Table Name",
        cell: (item): string => item.table_name,
    },
    {
        id: "tableDescription",
        header: "Table Description",
        cell: (item): string => item.table_description,
    },
    {
        id: "tableType",
        header: "Table Type",
        cell: (item): string => item.table_type,
    },
];

interface Props {
    tables: BumbleTable[];
    onTableDefinitionsChange: (tds: TableDefinition[]) => void;
}

export const convertToTableDefinition = (tableDetails: TableDetails): TableDefinition => {
    const { table_name, columns } = tableDetails;

    const schema = columns.map(
        ({ column_name, data_type, description }): TableAttribute => ({
            name: column_name,
            type: data_type,
            description,
        })
    );

    return {
        name: table_name,
        source: BUMBLEBEE_SOURCE,
        schema,
    };
};

const TablePicker = (props: Props): JSX.Element => {
    const { tables, onTableDefinitionsChange } = props;
    const [selectedItems, setSelectedItems] = useState<BumbleTable[]>([]);
    const [schemaError, setSchemaError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect((): void => {
        setSelectedItems([]);
    }, [tables]);

    const onFetchTableSchema = useCallback(async (): Promise<void> => {
        setSchemaError(selectedItems.length > MAX_TABLE_SELECTION ? TOO_MANY_SELECTED_TABLES_ERROR : "");
        if (!Boolean(selectedItems.length) || selectedItems.length > MAX_TABLE_SELECTION) {
            return;
        }
        setIsLoading(true);
        await fetchTableDetails(selectedItems as BumbleTable[]).then(
            ({ tableDetailsList, fetchTableDetailsError }): void => {
                onTableDefinitionsChange(tableDetailsList.map(convertToTableDefinition));
                setSchemaError(fetchTableDetailsError);
                setIsLoading(false);
            }
        );
    }, [onTableDefinitionsChange, selectedItems]);

    return (
        <React.Fragment>
            {isLoading && <Loader data-testid={TEST_IDS.SPINNER} />}
            <DASTable<BumbleTable>
                id={"bumblebee-importer-table-picker"}
                tableName={"Bumblebee Tables from Sources"}
                tableDescription={
                    <Box variant="p">
                        {`Import up to ${MAX_TABLE_SELECTION} tables. `}
                        <Box variant="strong">{`${selectedItems.length}/${MAX_TABLE_SELECTION} `}</Box>
                        selected
                    </Box>
                }
                emptyTextPrefix={"No table previews"}
                isLoading={false}
                selectionType={"multi"}
                rowItems={tables}
                columnDefinitions={columnDefinitions}
                onSelectionChange={(e): void => setSelectedItems(e.detail.selectedItems)}
            />
            <FormField stretch={true} errorText={schemaError}>
                <FormSectionFooter>
                    <Button
                        data-testid={TEST_IDS.FETCH_SCHEMA}
                        disabled={!Boolean(selectedItems.length)}
                        onClick={onFetchTableSchema}
                    >
                        Fetch Schema
                    </Button>
                </FormSectionFooter>
            </FormField>
        </React.Fragment>
    );
};

export default TablePicker;
