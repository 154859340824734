import React, { useContext } from "react";
import {
    ApplicationUserRules,
    LegalUserRules,
    selectApplicationStatusByUserAction,
    selectSurveyType,
    SurveyType,
    TAFUserRules,
    UserAction,
    UserRole,
} from "src/permissions";
import {
    ApplicationStatus,
    SurveyConsumer,
    SurveyContext,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import { ApplicationStatusCallback } from "src/components/fields/hoc/withReadonly";

interface CanProps {
    perform: UserAction | UserAction[];
    response?: SurveyResponse;
    yes?: () => JSX.Element | null;
    no?: () => JSX.Element | null;
}

const defaultProps = {
    yes: (): null => {
        return null;
    },
    no: (): null => {
        return null;
    },
};

function isAuthorized(
    role: UserRole,
    action: UserAction,
    applicationStatusCallback: ApplicationStatusCallback
): boolean {
    const surveyType = selectSurveyType();

    let roleRules: ApplicationUserRules | null;

    switch (surveyType) {
        case SurveyType.Legal: {
            roleRules = LegalUserRules[role];
            break;
        }
        case SurveyType.TAF: {
            roleRules = TAFUserRules[role];
            break;
        }
        default: {
            roleRules = null;
        }
    }

    if (!roleRules) {
        return false;
    }

    const callback = roleRules.value[action];

    if (!callback) {
        return false;
    }

    return callback(applicationStatusCallback);
}

const useIsAuthorized = (action: UserAction): boolean => {
    const { role, application } = useContext(SurveyContext);
    const applicationStatusCallback = (): ApplicationStatus | undefined =>
        selectApplicationStatusByUserAction(action, application, role);

    return isAuthorized(role, action, applicationStatusCallback);
};

function Can(props: CanProps): JSX.Element {
    const surveyContext = useContext(SurveyContext);

    const {
        perform: performProp,
        response = surveyContext.application,
        yes = defaultProps.yes,
        no = defaultProps.no,
    } = props;
    const performActions = Array.isArray(performProp) ? performProp : [performProp];

    function isUserAuthorized(role: UserRole, perform: UserAction): boolean {
        return isAuthorized(role, perform, (): ApplicationStatus | undefined => {
            return selectApplicationStatusByUserAction(perform, response, role);
        });
    }

    return (
        <SurveyConsumer>
            {({ role }): JSX.Element => {
                const canPerformActions = performActions.some((performAction): boolean =>
                    isUserAuthorized(role, performAction)
                );

                return <React.Fragment>{canPerformActions ? yes() : no()}</React.Fragment>;
            }}
        </SurveyConsumer>
    );
}

export { Can, useIsAuthorized, isAuthorized };
