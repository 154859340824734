import React from "react";

import { Box } from "@amzn/awsui-components-react-v3";

export const formatPageError = (title: string, subtitle: string): React.ReactNode => (
    <React.Fragment>
        <Box variant="p">{title}</Box>
        <Box variant="p">{subtitle}</Box>
    </React.Fragment>
);
