import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { Box, Button, Link, SpaceBetween, Spinner } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { kaleUrls } from "src/util/Urls";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { KaleApplicationRoutesProps } from "src/components/survey/KaleApplication/KaleApplicationRoutes";

const StyledBox = styled(Box)`
    margin-top: 2em;
`;

export const useRedirectToLatestReview = (
    appName: string,
    originalPath: string,
    displayMessage: DisplayMessageCb
): string => {
    const [reviewId, setReviewId] = useState<string>("");

    const {
        service: { kaleAppService },
        user: { userId },
    } = useContext(KaleContext);

    const history = useHistory();

    // To catch the case where we're trying to access a subpage with the old edit URL
    // Ex: /edit/appName/TAF -> /appName/reviews/reviewId/edit/TAF
    const oldURL = kaleUrls.deprecatedEditUrl(appName);
    const remainingPath = originalPath.split(oldURL)[1] ?? "";

    const deps = { kaleAppService, history, displayMessage };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    console.warn(`Old Edit Page URL used for "${appName}" by user: ${userId}`);

    useEffect((): void => {
        const { kaleAppService, history, displayMessage } = depsRef.current;

        const performRedirect = async (appName: string): Promise<void> => {
            try {
                const response = await kaleAppService.getLatestReviewID(decodeURIComponent(appName));
                setReviewId(response.toString());
                history.push(`/${appName}/reviews/${response}/edit${remainingPath}`);
            } catch (err) {
                const errMsg = (err as Error).message;
                console.error("Failed to Get Latest Review ID from backend", errMsg);
                displayMessage(MessageType.error, errMsg);
                setReviewId("");
            }
        };
        performRedirect(appName);
    }, [appName, remainingPath]);

    return reviewId;
};

// This component is used purely for redirecting from the old edit (/edit/:appName)
// to the new edit page URL (/:appName/reviews/:reviewId/edit). Since the old URL
// may still be used in the future (links to Kale from other services, bookmarked
// Kale applications, etc), we will continue to allow routing for the old URL, we
// will just always redirect the user to the new slug with the latest review ID.
const RedirectToNewEditURL = (props: KaleApplicationRoutesProps): JSX.Element => {
    const { displayMessage } = props;
    const appName = props.match.params.applicationName;
    // We are including both the pathname and search to ensure we're preserving the
    // full URL, including query parameters. This is necessary, for example, to make
    // the audit log page load correctly after being redirected to the new Edit URL
    const originalPath = props.location.pathname + props.location.search;
    const reviewId = useRedirectToLatestReview(appName, originalPath, displayMessage);

    return (
        <StyledBox textAlign="center">
            {reviewId ? (
                <SpaceBetween size="xs">
                    <h4>If you are not automatically redirected</h4>
                    <Link id="redirectLink" href={`/${appName}/reviews/${reviewId}/edit`}>
                        <Button variant="primary">Click Here</Button>
                    </Link>
                </SpaceBetween>
            ) : (
                <Spinner size={"large"} />
            )}
        </StyledBox>
    );
};

export default RedirectToNewEditURL;
