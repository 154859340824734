import React from "react";
import { ColumnLayout, FormField } from "@amzn/awsui-components-react-v3/polaris";
import { HookAnswer, QuestionType, QuestionBase, AnswerBase, QuestionTag } from "src/answers_legacy";

import {
    CheckboxQuestion,
    TextQuestion,
    TextareaQuestion,
    MultiselectQuestion,
    SelectQuestion,
    ExampleValuesQuestion,
    DateQuestion,
} from "src/components/TableDetails/FormQuestionsPane/FormQuestions";
import { REQUIRED_MESSAGE } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/constants";
import { SyntheticFieldIds } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";
import { CRADLE_QUESTION_SHORT_IDS } from "src/components/TableDetails/TableDetailsForm/hooks/";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";

export const TEST_IDS = {
    CONTAINER: "form-questions-pane--container",
    TEXT_QUESTION: "form-questions-pane--text-question",
    TEXTAREA_QUESTION: "form-questions-pane--textarea-question",
    CHECKBOX_QUESTION: "form-questions-pane--checkbox-question",
    SELECT_QUESTION: "form-questions-pane--select-question",
    MULTISELECT_QUESTION: "form-questions-pane--multiselect-question",
    DATE_QUESTION: "form-questions-pane--date-question",
};

const renderFormQuestionByType = <QType extends QuestionBase, AType extends AnswerBase>(
    questionAnswer: HookAnswer<QType, AType>,
    useExpandedTextareas: boolean
): JSX.Element | null => {
    const {
        question: { type: questionType, content, subtext },
        isValid,
    } = questionAnswer;

    let questionArchetype: React.ReactNode = null;

    switch (questionType) {
        case QuestionType.checkbox: {
            questionArchetype = (
                <CheckboxQuestion questionAnswer={questionAnswer} data-testid={TEST_IDS.CHECKBOX_QUESTION} />
            );
            break;
        }
        case QuestionType.text: {
            questionArchetype = <TextQuestion questionAnswer={questionAnswer} data-testid={TEST_IDS.TEXT_QUESTION} />;
            break;
        }
        case QuestionType.textArea: {
            questionArchetype = (
                <TextareaQuestion
                    questionAnswer={questionAnswer}
                    shouldFitHeightToContent={useExpandedTextareas}
                    data-testid={TEST_IDS.TEXTAREA_QUESTION}
                />
            );
            break;
        }
        case QuestionType.singleSelect: {
            questionArchetype = (
                <SelectQuestion questionAnswer={questionAnswer} data-testid={TEST_IDS.SELECT_QUESTION} />
            );
            break;
        }
        case QuestionType.multiSelect: {
            questionArchetype = (
                <MultiselectQuestion questionAnswer={questionAnswer} data-testid={TEST_IDS.MULTISELECT_QUESTION} />
            );
            break;
        }
        case QuestionType.date:
            questionArchetype = <DateQuestion questionAnswer={questionAnswer} data-testid={TEST_IDS.DATE_QUESTION} />;
            break;
        default: {
            throw new Error(`Unsupported question type: "${questionType}"`);
        }
    }

    return (
        <FormField label={content} description={KaleHTMLParser(subtext)} errorText={!isValid && REQUIRED_MESSAGE}>
            {questionArchetype}
        </FormField>
    );
};

const renderFormQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    questionAnswer: HookAnswer<QType, AType>,
    index: number,
    siblingQuestionAnswers: HookAnswer<QType, AType>[],
    shouldAutoSizeTextareas: boolean
): JSX.Element | null => {
    const {
        question: { id, shortId },
        isApplicable,
    } = questionAnswer;

    // Don't render any Andes Cradle Questions because they are handled outside of the Form Questions Pane
    const isAndesTableCradleQuestion = CRADLE_QUESTION_SHORT_IDS.includes(shortId);
    if (isAndesTableCradleQuestion) {
        return null;
    }

    // Don't render any question that the server has tagged legacyHidden
    const questionTags = questionAnswer.question.tags;
    if (questionTags.includes(QuestionTag.legacyHidden)) {
        return null;
    }

    if (isApplicable) {
        const key = `${index}-${id}-${shortId}`;

        let formQuestion = null;

        if (shortId === SyntheticFieldIds.FieldExampleValues) {
            formQuestion = (
                <ExampleValuesQuestion
                    questionAnswer={questionAnswer}
                    siblingQuestionAnswers={siblingQuestionAnswers}
                    shouldFitHeightToContent={shouldAutoSizeTextareas}
                />
            );
        } else {
            formQuestion = renderFormQuestionByType<QType, AType>(questionAnswer, shouldAutoSizeTextareas);
        }

        return <React.Fragment key={key}>{formQuestion}</React.Fragment>;
    }
    // Don't render non-applicable questions in the FormQuestionsPane
    return null;
};

interface FormQuestionsPaneProps<QType extends QuestionBase, AType extends AnswerBase> {
    questionAnswers: HookAnswer<QType, AType>[];
    numColumns?: number;
    shouldAutoSizeTextareas?: boolean;
}
/**
 * Renders a list of question/answer data from props into various Form UI inputs/labels based on question type.
 */
const FormQuestionsPane = <QType extends QuestionBase, AType extends AnswerBase>({
    questionAnswers,
    numColumns = 1,
    shouldAutoSizeTextareas = false,
}: FormQuestionsPaneProps<QType, AType>): JSX.Element => {
    const renderedQuestionAnswers = questionAnswers.map(
        (questionAnswer, index, siblingQuestionAnswers): JSX.Element | null =>
            renderFormQuestion<QType, AType>(questionAnswer, index, siblingQuestionAnswers, shouldAutoSizeTextareas)
    );
    return (
        <div data-testid={TEST_IDS.CONTAINER}>
            <ColumnLayout columns={numColumns}>{renderedQuestionAnswers}</ColumnLayout>
        </div>
    );
};

export { FormQuestionsPane };
