/* eslint-disable max-len */
import { DataStoreHookAnswer, QuestionBase, QuestionTag } from "src/answers_legacy";
import { FormField, Grid, GridProps, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React from "react";
import renderQuestionContentByType from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/render-question-content-by-type";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import { TEST_IDS } from "shared/survey";
import ConditionalSectionFooter from "src/components/survey/DataStoreInfo/DataStoreWizard/ConditionalSectionFooter";

const {
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: {
            DYNAMIC_QUESTION_STEP: {
                QUESTION_GROUP: {
                    GROUP_SECTION: { ROOT: GROUP_SECTION_ROOT, GROUP_QUESTION },
                },
            },
        },
    },
} = TEST_IDS;

const getQuestionGridDefinition = (question: QuestionBase): GridProps.ElementDefinition => {
    const { tags } = question;
    if (tags.includes(QuestionTag.colspan33)) {
        return { colspan: 4 };
    } else if (tags.includes(QuestionTag.colspan50)) {
        return { colspan: 6 };
    } else if (tags.includes(QuestionTag.colspan66)) {
        return { colspan: 8 };
    } else if (tags.includes(QuestionTag.colspan100)) {
        return { colspan: 12 };
    } else {
        return { colspan: 4 };
    }
};

const GroupSection = ({ sectionQuestions }: { sectionQuestions: DataStoreHookAnswer[] }): JSX.Element => {
    const gridElements: JSX.Element[] = [];
    const gridDefinitions: GridProps.ElementDefinition[] = [];

    sectionQuestions.forEach((item): void => {
        // Get question colspan
        const gridDefinition = getQuestionGridDefinition(item.question);

        gridDefinitions.push(gridDefinition);

        // Render questions
        const { content, subtext, shortId, type } = item.question;
        gridElements.push(
            <FormField
                key={shortId}
                data-testid={GROUP_QUESTION}
                data-question-shortid={shortId}
                data-question-type={type}
                stretch={true}
                label={KaleHTMLParser(content)}
                description={KaleHTMLParser(subtext)}
            >
                {renderQuestionContentByType(item)}
            </FormField>
        );
    });

    return (
        <React.Fragment>
            <SpaceBetween size={"l"}>
                <Grid data-testid={GROUP_SECTION_ROOT} gridDefinition={gridDefinitions}>
                    {gridElements}
                </Grid>
                <ConditionalSectionFooter sectionQuestions={sectionQuestions} />
            </SpaceBetween>
        </React.Fragment>
    );
};

export default GroupSection;
