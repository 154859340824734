import { useRouteMatch } from "react-router-dom";
import React, { useContext, useEffect, useRef } from "react";
import KaleContext from "src/components/KaleContext";
import { useOnWebSocketMessage } from "src/websocket/useOnWebSocketMessage";
import { WebSocketContextProps, WebSocketProvider } from "src/websocket/WebSocketContext";
import { WebSocketService } from "src/services/WebSocketService";

const WEB_SOCKET_CHANNEL = "application";
const ADMIN_CHANNEL = "admin";

// This segment is on the admin channel and gets websocket msges for cache updates
const UBER_GET_CACHE_SEGMENT = "uberGetCacheUpdated";

interface WebSocketProps {
    children?: React.ReactNode;
}

// Read Original Design Doc from 2022: https://quip-amazon.com/XUV0AHx0wa5a/Web-Socket-for-Kale
export const WebSocketConnection = (props: WebSocketProps): JSX.Element => {
    const { children } = props;
    const { webSocketService } = useContext(KaleContext).service;
    const webSocketServiceRef = useRef<WebSocketService>(webSocketService);
    webSocketServiceRef.current = webSocketService;
    const {
        onWebSocketMessage,
        subscribeToPongMessage,
        unsubscribeToPongMessage,
        subscribeToBulkEditMessage,
        unsubscribeToBulkEditMessage,
        subscribeToRecallMessage,
        unsubscribeToRecallMessage,
        subscribeToSubmitMessage,
        unsubscribeToSubmitMessage,
        subscribeToUbergetCacheUpdatedMessage,
        unsubscribeToUbergetCacheUpdatedMessage,
    } = useOnWebSocketMessage();
    const match = useRouteMatch("/:name/reviews/:id/edit");
    const contextValue: WebSocketContextProps = {
        subscribeToPongMessage,
        unsubscribeToPongMessage,
        subscribeToBulkEditMessage,
        unsubscribeToBulkEditMessage,
        subscribeToRecallMessage,
        unsubscribeToRecallMessage,
        subscribeToSubmitMessage,
        unsubscribeToSubmitMessage,
        subscribeToUbergetCacheUpdatedMessage,
        unsubscribeToUbergetCacheUpdatedMessage,
    };

    useEffect((): (() => void) | void => {
        const params = match?.params as { name: string };
        const { connect, disconnect } = webSocketServiceRef.current;
        if (params?.name) {
            // /{appName}/reviews/{reviewId}/edit page listens to the app channel for the currently open app
            // eslint-disable-next-line
            connect(WEB_SOCKET_CHANNEL, params.name, onWebSocketMessage)
                .then()
                .catch((e) => console.error(e));
        }
        // Notice that the name of the app is not provided below. This is because there's only one admin channel
        // for the entire kale website in any given pipeline stage.
        // Connect to both  admin and application channels when viewing a specific Kale application so that we can
        // keep immediate consistency using the useWaitForUberCacheUpdate hook.
        // eslint-disable-next-line
        connect(ADMIN_CHANNEL, UBER_GET_CACHE_SEGMENT, onWebSocketMessage)
            .then()
            .catch((e) => console.error(e));

        return (): void => disconnect();
    }, [match?.params, onWebSocketMessage]);

    return <WebSocketProvider value={contextValue}>{children}</WebSocketProvider>;
};
