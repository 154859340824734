// Constant to indicate when a brand new data store is being viewed in the Wizard
import { DataStoreQuestion, QuestionTag, QuestionType } from "src/answers_legacy";

export const BRAND_NEW_DATA_STORE__HAS_NO_INDEX = -1;
// The index of the initial WizardStep to open the DataStoreWizard to
export const INITIAL_WIZARD_ACTIVE_STEP_INDEX = 0;

export const DATA_STORE_TECHNOLOGY_DATA_KEY = "dataStoreTechnologies";

export enum DataStoreAttributeShortId {
    name = "name",
    description = "description",
    instances = "instances",
}

export interface DataStoreAttributePayload {
    name?: string;
    description?: string;
    instances?: string[];
}

interface SyntheticDataStoreQuestionMap {
    [k: string]: DataStoreQuestion;
}

export const syntheticDataStoreQuestionMap: SyntheticDataStoreQuestionMap = {
    [DataStoreAttributeShortId.name]: {
        id: -1,
        shortId: DataStoreAttributeShortId.name,
        type: QuestionType.text,
        content: "Name",
        title: "",
        subtext: "",
        groupId: "",
        choices: [],
        tags: [QuestionTag.required],
    },
    [DataStoreAttributeShortId.description]: {
        id: -1,
        shortId: DataStoreAttributeShortId.description,
        type: QuestionType.textArea,
        content: "Description",
        title: "",
        subtext: "",
        groupId: "",
        choices: [],
        tags: [],
    },
    [DataStoreAttributeShortId.instances]: {
        id: -1,
        shortId: DataStoreAttributeShortId.instances,
        // TODO: Create instances question type and input
        type: QuestionType.multiSelect,
        content: "Data store identifier",
        title: "",
        subtext: "AWS ARN, Database name, Sable scope & entity, etc.",
        groupId: "",
        choices: [],
        tags: [QuestionTag.required],
    },
};

export const syntheticDataStoreQuestions: DataStoreQuestion[] = Object.keys(syntheticDataStoreQuestionMap).map(
    (questionKey): DataStoreQuestion => syntheticDataStoreQuestionMap[questionKey]
);
