import React from "react";
import { Form, Header } from "@amzn/awsui-components-react-v3";
import WizardActions from "./wizard-actions";
import { WizardProps } from "./interfaces";

interface WizardFormProps {
    steps: readonly WizardProps.Step[];
    activeStepIndex: number;
    hideSubmitButton?: boolean;
    i18nStrings: WizardProps.I18nStrings;
    isPrimaryLoading: boolean;
    onPreviousClick: () => void;
    onPrimaryClick: () => void;
}

const WizardForm = (props: WizardFormProps): JSX.Element => {
    const activeStep = props.steps[props.activeStepIndex] || {};
    const isLastStep = props.activeStepIndex >= props.steps.length - 1;

    return (
        <Form
            header={
                <Header variant="h1" description={activeStep.description} info={activeStep.info}>
                    {activeStep.title}
                    {activeStep.isOptional && <i>{` - ${props.i18nStrings.optional}`}</i>}
                </Header>
            }
            actions={
                <WizardActions
                    hideSubmitButton={props.hideSubmitButton}
                    isLastStep={isLastStep}
                    primaryButtonText={isLastStep ? props.i18nStrings.submitButton : props.i18nStrings.nextButton}
                    previousButtonText={props.i18nStrings.previousButton}
                    onPreviousClick={props.onPreviousClick}
                    onPrimaryClick={props.onPrimaryClick}
                    showPrevious={props.activeStepIndex !== 0}
                    isPrimaryLoading={props.isPrimaryLoading}
                />
            }
            errorText={activeStep.errorText}
        >
            {activeStep.content}
        </Form>
    );
};

export default WizardForm;
