import { WebSocketMessageBase } from "src/websocket/useOnWebSocketMessage";
import { useContext, useEffect } from "react";
import { WebSocketContext } from "src/websocket/WebSocketContext";

export enum SubmitStatus {
    Started = "started",
    Completed = "completed",
    Failed = "failed",
}
export interface SubmitResult {
    approvalType: string;
    approvalStatus: string;
}

interface Payload {
    appName: string;
    requestId: string;
    status: SubmitStatus;
    response: SubmitResult | null;
    error: Error | null;
}
export interface SubmitMessage extends WebSocketMessageBase {
    payload: Payload;
}
export type SubmitMessageHandler = (message: SubmitMessage) => void;

export const useSubscribeToSubmitMessage = (callback: SubmitMessageHandler): void => {
    const { subscribeToSubmitMessage, unsubscribeToSubmitMessage } = useContext(WebSocketContext);

    useEffect((): (() => void) => {
        subscribeToSubmitMessage(callback);
        return (): void => unsubscribeToSubmitMessage(callback);
    }, [callback, subscribeToSubmitMessage, unsubscribeToSubmitMessage]);
};
