import React, { useCallback, useContext, useRef } from "react";
import { makeDefaultDataStoreResponse } from "src/components/survey/LegalSurveyPage";
import { MessageType } from "src/components/survey/KaleRoutes";
import { TableRecord } from "src/services/KaleTablesService";
import { Alert, Button, ButtonDropdown, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { ConfirmActionModalButton } from "src/components/ConfirmActions";
import { isAuthorized, UserAction } from "src/permissions";
import { ApplicationStatus, SurveyContext } from "src/components/survey/SurveyFormModel";
import { TransferTables } from "src/components/schema_table/transfer_tables";
import { isAndes } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { TEST_IDS } from "shared/schema-table";
import { TEST_IDS as SURVEY_TEST_IDS } from "shared/survey";
import { isFormReadOnly } from "src/components/fields/hoc/withReadonly";
import { SchemaTableProps } from "src/components/schema_table/SchemaTable";
import KaleContext from "src/components/KaleContext";
import { useHistory } from "react-router-dom";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { kaleUrls } from "src/util/Urls";

const { DELETE_TABLES } = SURVEY_TEST_IDS.DATA_STORE_INFO.SCHEMA_TABLE.ACTIONS;

/**
 * Helper method to determine if a list of tables contains a particular table.
 * @param list - The list of table records
 * @param subject - The table record that we want to search the list for.
 */
const isTableInList = (list: TableRecord[], subject: TableRecord): boolean =>
    Boolean(list.find((listTable): boolean => listTable.id === subject.id));

interface TableHeaderActionStripeProps {
    dataStore: SchemaTableProps["dataStore"];
    appName: SchemaTableProps["appName"];
    appNames: SchemaTableProps["appNames"];
    status: SchemaTableProps["status"];
    onChangeCallback: SchemaTableProps["onChangeCallback"];
    onTransferTablesCb: SchemaTableProps["onTransferTablesCb"];
    displayMessage: SchemaTableProps["displayMessage"];
    selectedItems: TableRecord[];
    onCreateTableClick: () => void;
    onImportCSVClick: () => void;
    onExportCSVClick: () => void;
    onAfterDelete: () => void;
}

/**
 * Component representing the Action Stripe for the SchemaTable component's Table Header
 */
export const TableHeaderActionStripe = (props: TableHeaderActionStripeProps): JSX.Element => {
    const {
        dataStore,
        appName,
        appNames,
        status,
        onChangeCallback,
        onTransferTablesCb,
        displayMessage,
        selectedItems,
        onCreateTableClick,
        onImportCSVClick,
        onExportCSVClick,
        onAfterDelete,
    } = props;
    const kaleContext = useContext(KaleContext);
    const { deleteTable, generateTablesCSVTemplate } = kaleContext.service.kaleTablesService;

    const { saveDraft, role, updateBulkEditPanel } = useContext(SurveyContext);
    const isReadOnly = isFormReadOnly(role, (): ApplicationStatus => status);

    const { reviewId } = useAppParamsFromRoute();

    const deps = { dataStore, onCreateTableClick, onAfterDelete, reviewId };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const onDeleteClickEvent = useCallback((): void => {
        const { dataStore, onAfterDelete } = depsRef.current;
        const deletionPromises = selectedItems.map(
            (item): Promise<void> => deleteTable(appName, dataStore?.id ?? 0, item.id)
        );
        Promise.all(deletionPromises)
            .then((): void => {
                // Calculate the final list of tables post-deletion and notify them back to the UI
                const initialTables = dataStore.tables;
                const deletedTables = selectedItems;
                const finalTables = initialTables.filter((table): boolean => !isTableInList(deletedTables, table));
                onChangeCallback({
                    ...dataStore,
                    tables: finalTables,
                });
                onAfterDelete();
            })
            .catch((err: Error): void => {
                displayMessage(MessageType.error, err.message);
            });
    }, [appName, deleteTable, displayMessage, onChangeCallback, selectedItems]);

    const history = useHistory();
    const handleImporterClick = useCallback(
        async (source: string): Promise<void> => {
            let { dataStore } = depsRef.current;
            const { reviewId } = depsRef.current;
            let { id } = dataStore;
            if (!id) {
                try {
                    const response = await saveDraft();
                    dataStore =
                        response.appInfo.review.dataStores.find(
                            (rDatsStore): boolean => rDatsStore.name === dataStore.name
                        ) ?? makeDefaultDataStoreResponse();
                    id = dataStore.id ?? 0;
                } catch (err) {
                    displayMessage(MessageType.error, (err as Error)?.message ?? "");
                    return;
                }
            }
            history.push({
                pathname: kaleUrls.editImportBySourceURL(appName, reviewId, id, source),
                state: { dataStore },
            });
        },
        [appName, displayMessage, history, saveDraft]
    );

    const onAddTableClick = useCallback(
        async (event): Promise<void> => {
            switch (event.detail.id) {
                case "bumblebeeImport":
                    await handleImporterClick("bumblebee");
                    break;
                case "andesImport":
                    await handleImporterClick("andes");
                    break;
                case "manualImport":
                    await handleImporterClick("schemas");
                    break;
                case "createTable": {
                    const { onCreateTableClick } = depsRef.current;
                    onCreateTableClick();
                    break;
                }
            }
        },
        [handleImporterClick]
    );

    const onCSVClick = (event: any): void => {
        switch (event.detail.id) {
            case "exportCSV":
                onExportCSVClick();
                break;
            case "importCSV":
                onImportCSVClick();
                break;
            case "generateTemplate":
                generateTablesCSVTemplate(`${dataStore.name}_template.csv`).catch((err: Error): void =>
                    displayMessage(MessageType.error, err.message)
                );
                break;
        }
    };

    const onBulkEditClick = (): void => {
        const tableIds = selectedItems.map((item): number => (item as TableRecord).id);
        updateBulkEditPanel(tableIds, props.dataStore);
    };

    return (
        <SpaceBetween direction="horizontal" size="xs">
            {selectedItems.length > 0 && (
                <ConfirmActionModalButton
                    testIds={{
                        cancelButton: DELETE_TABLES.MODAL_CANCEL_BUTTON,
                        confirmButton: DELETE_TABLES.MODAL_CONFIRM_BUTTON,
                        triggerButton: DELETE_TABLES.MODAL_TRIGGER_BUTTON,
                        modalRoot: DELETE_TABLES.MODAL_ROOT,
                    }}
                    triggerButtonOverrides={{
                        disabled: isReadOnly,
                        variant: "normal",
                    }}
                    actionLabel={"Delete"}
                    modalProps={{
                        header: "Delete tables",
                        content: (
                            <React.Fragment>
                                <Alert type="warning">
                                    Deleting these table and all the data within the table is irreversible.
                                </Alert>
                                <br />
                                {"Are you sure you want to delete these tables ?"}
                            </React.Fragment>
                        ),
                        onConfirm: onDeleteClickEvent,
                    }}
                />
            )}
            {selectedItems.length > 0 && (
                <Button
                    disabled={!isAuthorized(role, UserAction.bulkEditTableAndFields, (): ApplicationStatus => status)}
                    onClick={onBulkEditClick}
                >
                    Bulk Edit
                </Button>
            )}
            <TransferTables
                currentAppName={appName}
                appNames={appNames}
                currentDSId={dataStore.id ?? 0}
                currentDSName={dataStore.name}
                isAndesTechnology={isAndes(dataStore.technology)}
                tables={dataStore.tables}
                isReadOnly={isReadOnly}
                onTransferTablesCb={onTransferTablesCb}
                displayMessage={displayMessage}
            />
            {!isAndes(dataStore.technology) && (
                <ButtonDropdown
                    data-testid={TEST_IDS.CSV_DROPDOWN}
                    items={[
                        {
                            id: "jobView",
                            text: "View export/import jobs",
                            external: true,
                            href: kaleUrls.csvJobsStatusUrl(encodeURIComponent(props.appName), reviewId),
                        },
                        { id: "exportCSV", text: "Export" },
                        { id: "importCSV", text: "Import", disabled: isReadOnly },
                        { id: "generateTemplate", text: "Generate Template" },
                    ]}
                    onItemClick={onCSVClick}
                >
                    CSV
                </ButtonDropdown>
            )}
            <ButtonDropdown
                data-testid={TEST_IDS.TABLE_CREATION_DROPDOWN}
                disabled={isReadOnly}
                items={
                    isAndes(dataStore.technology)
                        ? [{ id: "andesImport", text: "Import from Andes" }]
                        : [
                              { id: "bumblebeeImport", text: "Import from Bumblebee" },
                              { id: "manualImport", text: "Manual Import" },
                              { id: "createTable", text: "Create Table" },
                          ]
                }
                onItemClick={onAddTableClick}
            >
                Add Table
            </ButtonDropdown>
        </SpaceBetween>
    );
};
