import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { UserRole } from "src/permissions";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";

export interface FetchApplicationMetadataResultState {
    applicationName: string;
    selectedReviewId: string;
    latestReviewId: string | null;
    metadataError: React.ReactNode;
    userRole: UserRole;
}

export const useFetchApplicationMetadata = (): FetchApplicationMetadataResultState => {
    const { kaleAppService } = useContext(KaleContext).service;
    const { reviewId: selectedReviewId, applicationName } = useAppParamsFromRoute();

    const [userRole, setUserRole] = useState<UserRole>(UserRole.readOnly);
    const [latestReviewId, setLatestReviewId] = useState<string | null>(null);
    const [metadataError, setMetadataError] = useState<React.ReactNode>(null);

    const deps = { kaleAppService };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(() => {
        const fetchUserRoleAndLatestReviewId = async () => {
            try {
                const { kaleAppService } = depsRef.current;
                const [userRole, latestReviewId] = await Promise.all([
                    kaleAppService.fetchUserRole(applicationName),
                    kaleAppService.getLatestReviewID(applicationName),
                ]);
                const newUserRole = selectedReviewId === latestReviewId.toString() ? userRole : UserRole.readOnly;

                setUserRole(newUserRole);
                setLatestReviewId(latestReviewId.toString());
                setMetadataError(null);
            } catch (err) {
                setUserRole(UserRole.readOnly);
                setMetadataError((err as Error).message);
            }
        };
        fetchUserRoleAndLatestReviewId();
    }, [applicationName, selectedReviewId]);

    return { applicationName, selectedReviewId, latestReviewId, metadataError, userRole };
};
