import KaleApplicationRoutes, { KaleApplicationRoutesProps } from "./KaleApplicationRoutes";
import React, { useContext, useEffect, useRef } from "react";
import { AppLayout } from "@amzn/awsui-components-react-v3/polaris";
import CommentsSidebar from "src/components/CommentsView/CommentsSidebar";
import KaleContext from "src/components/KaleContext";
import { HeaderMenuContext } from "src/components/layout/Layout";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { selectIsCommentsViewSidebarOpen } from "src/components/CommentsView/state/selectors";
import { useKaleDispatch, useKaleSelector } from "src/redux/hooks";
import { commentViewSidebarToggled } from "src/components/CommentsView/state/actions";

/**
 * Component responsible for rendering all views for any particular Kale Application
 */
const KaleApplication = (props: KaleApplicationRoutesProps): JSX.Element => {
    const { features } = useContext(KaleContext);
    const dispatch = useKaleDispatch();
    const isCommentsViewSidebarOpen = useKaleSelector(selectIsCommentsViewSidebarOpen);
    const { setHasCommentsView } = useContext(HeaderMenuContext);

    const { applicationName } = useAppParamsFromRoute();
    const deps = { setHasCommentsView };
    const depsRef = useRef(deps);

    depsRef.current = deps;

    useEffect((): (() => void) => {
        depsRef.current.setHasCommentsView(Boolean(applicationName));

        return (): void => depsRef.current.setHasCommentsView(false);
    }, [features, applicationName]);

    return (
        <AppLayout
            navigationHide={true}
            headerSelector={"#top-nav"}
            toolsOpen={isCommentsViewSidebarOpen}
            toolsHide={!isCommentsViewSidebarOpen}
            tools={<CommentsSidebar />}
            toolsWidth={420}
            onToolsChange={(): void => {
                dispatch(commentViewSidebarToggled());
            }}
            content={<KaleApplicationRoutes {...props} />}
        />
    );
};
export default KaleApplication;
