import { AutosuggestProps } from "@amzn/awsui-components-react-v3";
import { useEffect, useState } from "react";
import BumbleApi from "src/services/BumblebeeService";
import { TableIdentifier } from "src/services/BumblebeeService";

export const useFetchTeamNamesOnMount = (): {
    isLoadingTeamNames: boolean;
    teamNames: AutosuggestProps.Options;
    teamNamesError: string;
} => {
    // Custom hook to encapsulate stateful logic of fetching team names on component mount
    const [isLoadingTeamNames, setIsLoadingTeamNames] = useState<boolean>(false);
    const [teamNames, setTeamNames] = useState<AutosuggestProps.Options>([]);
    const [teamNamesError, setTeamNamesError] = useState<string>("");

    useEffect((): void => {
        setIsLoadingTeamNames(true);
        setTeamNamesError("");
        BumbleApi.getTeamNames()
            .then((res): void => {
                if (!Boolean(res?.length)) {
                    setTeamNamesError("team name list has no data");
                    return;
                }
                setTeamNames(
                    res?.map((item): AutosuggestProps.Option => {
                        return {
                            value: item,
                        };
                    }) ?? []
                );
            })
            .catch((err: Error): void => {
                setTeamNamesError(err.message);
            })
            .then((): void => {
                setIsLoadingTeamNames(false);
            });
    }, []);

    return { isLoadingTeamNames, teamNames, teamNamesError };
};

export const useFetchTableIdentifiers = (
    teamName: string
): {
    isLoadingTableIdentifiers: boolean;
    tableIdentifiers: TableIdentifier[];
    tableIdentifiersError: string;
} => {
    // Custom hook to encapsulate stateful logic of fetching team names on component mount
    const [isLoadingTableIdentifiers, setIsLoadingTableIdentifiers] = useState<boolean>(false);
    const [tableIdentifiers, setTableIdentifiers] = useState<TableIdentifier[]>([]);
    const [tableIdentifiersError, setTableIdentifiersError] = useState<string>("");

    useEffect((): void => {
        setTableIdentifiers([]);
        setTableIdentifiersError("");
        if (!teamName) {
            return;
        }
        setIsLoadingTableIdentifiers(true);
        BumbleApi.getTableIdentifiers(teamName)
            .then((res): void => {
                if (!Boolean(res?.length)) {
                    setTableIdentifiersError("table identifier list has no data");
                    return;
                }
                setTableIdentifiers(res);
            })
            .catch((err: Error): void => {
                setTableIdentifiersError(err.message);
            })
            .then((): void => {
                setIsLoadingTableIdentifiers(false);
            });
    }, [teamName]);

    return { isLoadingTableIdentifiers, tableIdentifiers, tableIdentifiersError };
};
