import styled from "styled-components";
import { Button } from "@amzn/awsui-components-react-v3";
import { SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";

interface ArrowButtonProps {
    direction?: "next" | "previous";
    visible: boolean;
}
export const ArrowButton = styled.span(
    (props: ArrowButtonProps): string => `
        cursor: ${props.visible ? "pointer" : "inherit"};
        opacity: ${props.visible ? "100" : "0"};
        pointer-events: ${props.visible ? "auto" : "none"};
        position: absolute;
        right: ${props.direction === "next" ? "60px" : "initial"};
    `
);

export const StyledButton = styled(Button)`
    margin-left: 1em;
`;

export const HeaderItem = styled.div`
    font-size: 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 87%;
    white-space: nowrap;
`;

export const TableHeader = styled.span`
    font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
    margin: 0 26px;
`;

export const FieldHeader = styled(TableHeader)`
    font-weight: bold;
`;

export const ActionStripe = styled.div`
    text-align: right;
`;

export const FooterActions = styled(SpaceBetween)`
    display: inline-flex !important;
`;
