import React, { useState } from "react";
import { TableDetails, TableIdentifiers } from "src/services/KaleTablesService";
import { SetTableDetailsCb } from "src/components/TableDetails/TableDetailsPage/hooks/useMakeOnSaveCb";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";
import { AppLayoutProps, NonCancelableCustomEvent, Toggle, ToggleProps } from "@amzn/awsui-components-react-v3";
import { KaleTableFieldSidebarAccessor, TableFieldAnswer, useKaleTableFieldSidebar } from "src/answers_legacy";
import { useMakeOnSaveSidebarAnswersCb } from "src/components/TableDetails/TableDetailsPage/hooks";
import { SyntheticFieldIds } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";
import TableFieldSidebar from "src/components/TableDetails/TableFieldSidebar";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";

const TOOLS_WIDTH = 900;

const useToggleSidebarAutoSizingTextareas = (): { shouldAutoSizeTextareas: boolean; toggle: React.ReactNode } => {
    const [shouldAutoSizeTextareas, setShouldAutoSizeTextareas] = useState<boolean>(false);

    const text = "Autosize text areas";

    const toggle = (
        <Toggle
            checked={shouldAutoSizeTextareas}
            name={text}
            description={text}
            onChange={(e: NonCancelableCustomEvent<ToggleProps.ChangeDetail>): void => {
                const checked = e.detail.checked;
                setShouldAutoSizeTextareas(checked);
            }}
        />
    );

    return { toggle, shouldAutoSizeTextareas };
};

/**
 * The TableView Sidebar is implemented as a Polaris <AppLayout> tools drawer.
 * @return the subset of AppLayoutProps that pertain only to the tools drawer,
 * which have been configured to render our TableView sidebar
 */
export const useMakeSidebarToolsProps = (options: {
    tableDetailsFromServer: TableDetails | null;
    setTableDetailsFromServer: SetTableDetailsCb;
    tableIdentifiers: TableIdentifiers;
    tableName: string;
    displayMessage: DisplayMessageCb;
    showSidebar: boolean;
    complianceTypeVisibility: ComplianceTypeVisibility;
}): Pick<AppLayoutProps, "tools" | "toolsWidth" | "toolsHide" | "toolsOpen" | "onToolsChange"> => {
    const {
        tableDetailsFromServer,
        setTableDetailsFromServer,
        tableIdentifiers,
        tableName,
        displayMessage,
        showSidebar,
        complianceTypeVisibility,
    } = options;

    const { onSaveSidebarAnswersCb, sidebarSaveLoading, sidebarSaveError } = useMakeOnSaveSidebarAnswersCb(
        tableDetailsFromServer,
        setTableDetailsFromServer,
        tableIdentifiers,
        displayMessage
    );

    const autoSizeTextAreas = useToggleSidebarAutoSizingTextareas();

    const selectFieldId = (accessor: KaleTableFieldSidebarAccessor): number | null =>
        (accessor.answers[0]?.answerWithQuestion.answer as TableFieldAnswer)?.fieldId || null;

    const [sidebarField, { resetAnswers: resetSidebarAnswers }] = useKaleTableFieldSidebar();

    const hasSidebarAnswers = sidebarField.answers.length > 0;
    const sidebarFieldId = selectFieldId(sidebarField);

    const nameField = sidebarField.answers.find((answer): boolean => {
        return answer.question.shortId === SyntheticFieldIds.FieldName;
    });

    return {
        toolsHide: !showSidebar,
        toolsOpen: showSidebar,
        toolsWidth: TOOLS_WIDTH,
        onToolsChange: (e): void => {
            if (!e.detail.open) {
                resetSidebarAnswers();
            }
        },
        tools: hasSidebarAnswers && (
            <TableFieldSidebar
                tableName={tableName}
                fieldId={sidebarFieldId}
                fieldName={nameField?.value as string}
                loading={sidebarSaveLoading}
                error={sidebarSaveError}
                complianceTypeVisibility={complianceTypeVisibility}
                autoSizeTextareas={autoSizeTextAreas}
                onSave={(answers: TableFieldAnswer[]): void => {
                    const selectedFieldId = selectFieldId(sidebarField);
                    onSaveSidebarAnswersCb(selectedFieldId, answers);
                }}
                onCancel={(): void => resetSidebarAnswers()}
            />
        ),
    };
};
