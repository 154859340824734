/* eslint-disable max-len */
import React, { ReactNode, useContext, useRef, useState } from "react";
import { Modal, ModalProps } from "@amzn/awsui-components-react-v3";
/* eslint-enable max-len */

interface MultiPurposeModalProps {
    children: React.ReactNode;
}

export type ShowModalOptions = Pick<ModalProps, "header" | "footer"> & { body: ReactNode };
export interface ModalActions {
    showModal: (options: ShowModalOptions) => void;
}

/**
 * Context shape of our shared ModalActions
 */
const ModalActionsContext = React.createContext<undefined | ModalActions>(undefined);

/**
 * A custom hook for context consumers to hide/show the modal and configure its header, body and footer contents.
 */
const useMultiPurposeModal = (): ModalActions => {
    const actions = useContext(ModalActionsContext);
    if (actions === undefined) {
        throw new Error("useMultiPurposeModal must be used within a MultiPurposeModalActionsProvider");
    }
    return actions;
};

export const TEST_IDS = {
    VISIBLE_MODAL: "visible-multi-purpose-modal",
    HIDDEN_MODAL: "hidden-multi-purpose-modal",
};

/**
 * A component creates a Modal and manages its appearance via local state.
 * This component uses React Context to provide actions to update the modal to its entire subtree.
 * @param children: the component subtree to provide the ModalActionsContext to
 */
const ProvideMultiPurposeModal = ({ children }: MultiPurposeModalProps): JSX.Element => {
    const [modalProps, setModalProps] = useState<ModalProps>({ visible: false });

    const visibleModalProps: ModalProps = {
        size: "max",
        visible: true,
        onDismiss: (): void => setModalProps({ visible: false }),
    };

    // Make actions a static ref that is never updated to avoid triggering a rerender in the Provider.
    // If we only provide Context Consumers will a static reference of functions to update the modal then consumers in
    // the Provider subtree can subscribe to our useMultiPurposeModal hook with the piece of mind that it will never
    // cause them to rerender
    const actionsRef = useRef<ModalActions>({
        showModal: ({ header = null, body = null, footer = null }: ShowModalOptions): void => {
            setModalProps({
                header,
                footer,
                children: body,
                ...visibleModalProps,
            });
        },
    });

    const { VISIBLE_MODAL, HIDDEN_MODAL } = TEST_IDS;
    const modalTestId = modalProps.visible ? VISIBLE_MODAL : HIDDEN_MODAL;

    return (
        <React.Fragment>
            <Modal {...modalProps} data-testid={modalTestId} />
            <ModalActionsContext.Provider value={actionsRef.current}>{children}</ModalActionsContext.Provider>
        </React.Fragment>
    );
};

export { ProvideMultiPurposeModal, useMultiPurposeModal };
