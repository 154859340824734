import { omit } from "lodash";
import { useContext, useMemo, useRef } from "react";
import {
    AnswerActions,
    AnswerGetter,
    DataStoreAnswer,
    DataStoreAnswerWithQuestion,
    DataStoreQuestion,
    DataStoresAccessorReadonly,
    DefaultDataStoreAnswer,
    getAnswerResponseState,
    HookAnswer,
    StateKey,
} from "src/answers_legacy";
import { answerActionsContext, AnswerState, answerStateContext, Response, Validation } from "../context";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import {
    containsPersonalDataShortId,
    hasChildDataShortId,
    hasTeenDataShortId,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/ChildDataGatingQuestionGroup";

export type DataStoreHookAnswer = HookAnswer<DataStoreQuestion, DataStoreAnswer>;
export interface DataStoresAccessor extends Validation {
    answers: DataStoreHookAnswer[];
}

export type DataStoreAnswerGetter = AnswerGetter<DataStoreQuestion, DataStoreAnswer>;

type DataStoresAnswerActions = AnswerActions<DataStoreQuestion, DataStoreAnswer>;
export type DataStoreAnswerType = DataStoreHookAnswer | DataStoreAnswerGetter;

export const readonlyDataStoreAccessors = (fieldAccessors: DataStoresAccessor[]): DataStoresAccessorReadonly[] =>
    fieldAccessors.map(
        (accessor: DataStoresAccessor): DataStoresAccessorReadonly => ({
            ...accessor,
            answers: accessor.answers.map(
                (answer): AnswerGetter<DataStoreQuestion, DataStoreAnswer> => omit(answer, ["setValue"])
            ),
        })
    );

function getDataStoreAnswerById(
    state: AnswerState,
    actions: DataStoresAnswerActions,
    questionShortId?: string,
    dataStoreId?: number,
    userDataStoreId?: string
): HookAnswer<DataStoreQuestion, DataStoreAnswer> | void {
    const dataStoreState =
        dataStoreId || userDataStoreId
            ? state[StateKey.dataStores].find(
                  (item): boolean =>
                      item.value[0] &&
                      (dataStoreId
                          ? item.value[0].value.answer.dataStoreId === dataStoreId
                          : item.value[0].value.answer.userDataStoreId === userDataStoreId)
              )
            : state[StateKey.dataStores][0];

    const questionWithAnswer = dataStoreState?.value.find((answer: Response<DataStoreAnswerWithQuestion>): boolean => {
        const id = answer.value.answer.questionShortId;
        return id === questionShortId;
    });

    if (!questionWithAnswer) {
        console.error(`Invalid requested data store answer by short id "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<DataStoreQuestion, DataStoreAnswer>(questionWithAnswer, actions);
}

export const findDataStoreAccessor = <T extends DataStoresAccessorReadonly>(
    accessors: T[],
    dataStoreId?: number,
    unsavedUUID?: string
): T | null => {
    return (
        accessors.find((accessor): boolean => {
            const savedDataStoreId = accessor.answers?.[0].answerWithQuestion.answer.dataStoreId;
            const unsavedDataStoreUuid = accessor.answers?.[0].answerWithQuestion.answer.userDataStoreId;

            return (
                (Boolean(savedDataStoreId) && dataStoreId === savedDataStoreId) ||
                (Boolean(unsavedDataStoreUuid) && unsavedUUID === unsavedDataStoreUuid)
            );
        }) ?? null
    );
};

export const doDataStoresHaveChildOrTeenData = (
    { id, unsavedUUID }: Pick<DataStoreResponse, "id" | "unsavedUUID">,
    dataStoreAccessors: DataStoresAccessor[]
): boolean => {
    const dataStoreAccessor = findDataStoreAccessor(dataStoreAccessors, id, unsavedUUID);
    // filter for the two hook answers
    const hasChildOrTeenDataAnswers =
        dataStoreAccessor?.answers.filter(
            (ans): boolean =>
                ans.question.shortId === hasChildDataShortId || ans.question.shortId === hasTeenDataShortId
        ) ?? [];

    // return true if either answer's value (array of string) has an element beginning with "Yes"
    return hasChildOrTeenDataAnswers.some((ans): boolean =>
        ((ans?.value ?? []) as string[]).some((str): boolean => str.startsWith("Yes"))
    );
};

export const dataStoreHasPersonalData = (
    { id, unsavedUUID }: Pick<DataStoreResponse, "id" | "unsavedUUID">,
    dataStoreAccessors: DataStoresAccessor[]
): boolean => {
    const dataStoreAccessor = findDataStoreAccessor(dataStoreAccessors, id, unsavedUUID);
    const hasPersonalData =
        dataStoreAccessor?.answers.filter((ans): boolean => ans.question.shortId === containsPersonalDataShortId) ?? [];

    return hasPersonalData.some((ans): boolean => (ans.value as string) === "Yes");
};

export const useDataStores = (): [DataStoresAccessor[], DataStoresAnswerActions] => {
    const actions = useContext(answerActionsContext)<DataStoreQuestion, DataStoreAnswer>(StateKey.dataStores);
    const state = useContext(answerStateContext);
    const dataStoresState = state[StateKey.dataStores];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [DataStoresAccessor[], DataStoresAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            dataStoresState
                .map((dataStore): DataStoresAccessor => {
                    return {
                        answers: dataStore.value
                            .map(
                                (
                                    questionWithAnswer: Response<DataStoreAnswerWithQuestion>
                                ): HookAnswer<DataStoreQuestion, DataStoreAnswer> | void => {
                                    const { dataStoreId, userDataStoreId, questionShortId } =
                                        questionWithAnswer.value.answer;

                                    return getDataStoreAnswerById(
                                        state,
                                        actions,
                                        questionShortId,
                                        dataStoreId,
                                        userDataStoreId
                                    );
                                }
                            )
                            .filter((v): boolean => !!v) as HookAnswer<DataStoreQuestion, DataStoreAnswer>[],
                        isValid: dataStore.isValid,
                        isValidSave: dataStore.isValidSave,
                        isValidSubmit: dataStore.isValidSubmit,
                        hasChanged: dataStore.hasChanged,
                        userLastUpdated: dataStore.userLastUpdated,
                    };
                })
                .filter((accessor): boolean => accessor.answers.length > 0),
            actions,
        ];
    }, [dataStoresState]);
};

export type DataStoresPayload = DataStoreAnswer[];
export const useDataStoresPayloads = (): DataStoresPayload[] => {
    const state = useContext(answerStateContext);
    const dataStoresState = state[StateKey.dataStores];

    return useMemo(
        (): DataStoresPayload[] =>
            dataStoresState
                .map((dataStore): DataStoreAnswer[] =>
                    dataStore.value.map((questionWithAnswer): DataStoreAnswer => {
                        return questionWithAnswer.value.answer;
                    })
                )
                .map((answers): DataStoreAnswer[] =>
                    answers
                        .map((answer): DataStoreAnswer => {
                            const base = DefaultDataStoreAnswer(
                                answer.questionId,
                                answer.questionShortId,
                                answer.dataStoreId,
                                answer.userDataStoreId
                            );

                            return { ...base, ...answer };
                        })
                        .filter((answer): boolean => meetsServerAnswerConstraint(answer))
                ),
        [dataStoresState]
    );
};
