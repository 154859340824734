import React, { useContext, useEffect, useState } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { Input } from "@amzn/awsui-components-react-v3";
import { FieldCellState } from "src/components/TableDetails/FieldsTable/types";

export const TextQuestion = (props: FieldCellState): JSX.Element => {
    const { value: originalValue, setValue, isValid, isApplicable } = props;

    const value = originalValue as string;
    const [localValue, setLocalValue] = useState<string>(value);
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly || !isApplicable;
    useEffect((): void => {
        // Always overwrite local state when prop changes.
        setLocalValue(value);
    }, [value]);

    return (
        // Throttle how often we update global state by only calling the setter from the onBlur handler
        <span title={value}>
            <Input
                disabled={isReadonly}
                value={localValue}
                onChange={(e): void => setLocalValue(e.detail.value)}
                onBlur={(): void => {
                    if (value !== localValue) {
                        // Only trigger an expensive global state update
                        // if the user changed the value
                        setValue(localValue);
                    }
                }}
                invalid={!isValid}
            />
        </span>
    );
};
