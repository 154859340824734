import { AnswerBase, AnswerWithQuestion, ParentConstraintBase, QuestionBase } from "src/answers_legacy";

export type DataStoreQuestion = QuestionBase & ParentConstraintBase;

export enum DataStoreAnswerKey {
    dataStoreId = "dataStoreId",
    userDataStoreId = "userDataStoreId",
}

export interface DataStoreAnswer extends AnswerBase {
    [DataStoreAnswerKey.dataStoreId]?: number;
    [DataStoreAnswerKey.userDataStoreId]?: string;
}
export function DefaultDataStoreAnswer(
    questionId: number,
    questionShortId: string,
    dataStoreId?: number,
    userDataStoreId?: string
): DataStoreAnswer {
    return {
        questionId,
        questionShortId,
        dataStoreId,
        userDataStoreId,
    };
}

export type DataStoreAnswerWithQuestion = AnswerWithQuestion<DataStoreQuestion, DataStoreAnswer>;
