import { QuestionType } from "src/services/dynamic-questions";
import { FieldCellState } from "src/components/TableDetails/FieldsTable/types";
import { SyntheticFieldIds } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";

const QUESTION_COLUMNS_DEFAULT_WIDTHS: Record<string, number> = {
    [QuestionType.checkbox]: 140,
    [QuestionType.text]: 140,
    [QuestionType.textArea]: 180,
    [QuestionType.singleSelect]: 220,
    [QuestionType.multiSelect]: 330,
};
const QUESTION_COLUMN_MIN_WIDTHS: Record<string, number> = {
    [QuestionType.checkbox]: 50,
    [QuestionType.text]: 50,
    [QuestionType.textArea]: 50,
    [QuestionType.singleSelect]: 120,
    [QuestionType.multiSelect]: 120,
};

// Track the width of the expansion button set by Polaris.
const EXPANSION_BUTTON_WIDTH_BUFFER = 65;
// Example Values question is a custom question that is based on a regular
// TextArea question, but also adds an expansion button to invoke a modal.
const EXAMPLE_VALUES_QUESTION_COLUMN = {
    DEFAULT_WIDTH: QUESTION_COLUMNS_DEFAULT_WIDTHS[QuestionType.textArea] + EXPANSION_BUTTON_WIDTH_BUFFER,
    MIN_WIDTH: QUESTION_COLUMN_MIN_WIDTHS[QuestionType.textArea],
};

export const QuestionColumnSizing = {
    getColumnDefaultWidth: (question: FieldCellState["question"]): number => {
        const { shortId, type } = question;

        if (shortId === SyntheticFieldIds.FieldExampleValues) {
            return EXAMPLE_VALUES_QUESTION_COLUMN.DEFAULT_WIDTH;
        }
        return QUESTION_COLUMNS_DEFAULT_WIDTHS[type];
    },
    getColumnMinWidth: (question: FieldCellState["question"]): number => {
        const { shortId, type } = question;

        if (shortId === SyntheticFieldIds.FieldExampleValues) {
            return EXAMPLE_VALUES_QUESTION_COLUMN.MIN_WIDTH;
        }
        return QUESTION_COLUMN_MIN_WIDTHS[type];
    },
};
