import styled from "styled-components";
import { Icon } from "@amzn/awsui-components-react-v3";

export const NewRowErrorIconContainer = styled.span`
    padding-right: 20px;
`;

export const HeaderItem = styled.p(
    (props: { title: string }): string => `
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 100%;
     title: ${props.title}
 `
);

export const PopoverIcon = styled(Icon)`
    cursor: pointer;
`;

export const DeleteModalButton = styled.span`
    padding-right: 10px;
`;

export const ActionsDropdownPadding = styled.span`
    padding-right: 10px;
`;
