import styled from "styled-components";
import { FormField, FormFieldProps } from "@amzn/awsui-components-react-v3";
import React from "react";

/**
 * For a longer description the text would exceed dropdown width resulting in bad visual appearance.
 * Hence, we wanted to restrict description to consume only 75% width of its total allocated width.
 * 75% is in sync with FormField's children (rendered content) width.
 */
const FormFieldDescriptionWrapper = styled.div`
    width: 75%;
`;

interface Props extends FormFieldProps {
    children: React.ReactNode;
}

/**
 * KaleFormField is a wrapper for Polaris FormField. It sets description's width using custom CSS.
 * This field is used in ApplicationInfo and ApplicationPermission section of legal survey page.
 */
export const KaleFormField = (props: Props): JSX.Element => {
    const { children, description, ...formFieldProps } = props;
    return (
        <FormField
            description={<FormFieldDescriptionWrapper>{description}</FormFieldDescriptionWrapper>}
            {...formFieldProps}
        >
            {children}
        </FormField>
    );
};
