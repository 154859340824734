import React from "react";

import { Box } from "@amzn/awsui-components-react-v3";

export const formatPageError = (title: string, subtitle: string): React.ReactNode => (
    <React.Fragment>
        <Box variant="p">{title}</Box>
        <Box variant="p">{subtitle}</Box>
    </React.Fragment>
);

interface RebaseLocalDataWithServerOptions<ItemType> {
    originalList: ItemType[];
    updatedList: ItemType[];
    localList: ItemType[];
    itemCompareKeysProps?: {
        idKey: keyof ItemType;
        valueKey: keyof ItemType;
    };
}

/**
 * rebaseLocalDataWithServer rebases a user's local list of data on top of updates to that list of data from the server.
 * @param options.originalList - the original data from the server
 * @param options.updatedList - the latest version of the data from the server. May include edits/updates made by
 * other concurrent users.
 * @param options.localList - the current list of data, based off the originalList. May include edits/updates made
 * by the local user.
 * @param options.itemCompareKeysProps - map of key names belonging to the items that should be used to check for
 * equality. If this option is not provided, a default compare-by-value operation will be used to check for item
 * equality
 * */
export function rebaseLocalDataWithServer<ItemType>(options: RebaseLocalDataWithServerOptions<ItemType>): ItemType[] {
    const {
        originalList,
        updatedList,
        localList,
        // idKey/valueKey allows targeting specific objects via ItemType.
        itemCompareKeysProps: { idKey, valueKey } = {},
    } = options;

    let mergedResult = [...updatedList];

    // Ensures any items that were added in the user's list are added based on the compare list.
    localList.forEach((listItem): void => {
        if (!idKey) {
            if (!originalList.includes(listItem) && !mergedResult.includes(listItem)) {
                mergedResult.push(listItem);
            }
        } else if (idKey && valueKey) {
            if (
                !originalList.find(
                    (origListItem): boolean =>
                        origListItem[idKey] === listItem[idKey] && origListItem[valueKey] === listItem[valueKey]
                ) &&
                !mergedResult.find(
                    (mergedListItem): boolean =>
                        mergedListItem[idKey] === listItem[idKey] && mergedListItem[valueKey] === listItem[valueKey]
                )
            ) {
                if (!mergedResult.find((mergedListItem): boolean => mergedListItem[idKey] === listItem[idKey])) {
                    mergedResult.push(listItem);
                } else {
                    mergedResult.forEach((mergedListItem, index): void => {
                        if (mergedListItem[idKey] === listItem[idKey]) {
                            mergedResult[index][valueKey] = listItem[valueKey];
                        }
                    });
                }
            }
        }
    });

    // Ensures any items that were removed in the user's list are removed based on the compare list.
    originalList.forEach((listItem): void => {
        if (!idKey) {
            if (!localList.includes(listItem)) {
                mergedResult = mergedResult.filter((resultItem): boolean => resultItem !== listItem);
            }
        } else if (idKey && valueKey) {
            if (
                !localList.find(
                    (userListItem): boolean =>
                        userListItem[idKey] === listItem[idKey] && userListItem[valueKey] === listItem[valueKey]
                )
            ) {
                mergedResult = mergedResult.filter(
                    (mergedListItem): boolean => mergedListItem[idKey] !== listItem[idKey]
                );
            }
        }
    });

    return mergedResult;
}
