/* eslint-disable max-len */
import React from "react";
import { DataStoreQuestion } from "src/answers_legacy";
import { DataStoreAnswerGetter, DataStoreHookAnswer, DataStoresAccessor } from "src/answers_legacy/hooks/dataStores";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { DATA_STORE_TECHNOLOGY_DATA_KEY } from "src/components/survey/DataStoreInfo/contants";
import CollectDataStoreInfo from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo";
import { isAndes, QuestionCollections } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import WizardStepQuestionsBuilder from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder";
import SummaryBuilder from "src/components/survey/DataStoreInfo/DataStoreWizard/SummaryBuilder";
import { QuestionTag } from "src/services/dynamic-questions";
import { DataStoreWizardStep, MakeWizardStepsOptions } from "./types";
import { DELETION_OBLIGATIONS_SHORT_ID } from "src/components/TAF/TAFDetails/constants";
import { Link } from "@amzn/awsui-components-react-v3";
/* eslint-enable max-len */

const makeDataStoreInfoStep = (
    options: MakeWizardStepsOptions,
    collections: QuestionCollections<DataStoreAnswerGetter>,
    accessor: DataStoresAccessor | null
): DataStoreWizardStep => {
    const {
        committedDataStores,
        activeDataStore,
        activeDataStoreIndex,
        dgrResources,
        setActiveDataStore,
        setIsActiveStepAnswerValid,
        shouldRenderErrors,
    } = options;
    const { childrenIdMap } = collections;

    const stepTitle = "Data store information";
    const stepContent = (
        <CollectDataStoreInfo
            activeDataStoreIndex={activeDataStoreIndex}
            dataStores={committedDataStores}
            dgrResources={dgrResources}
            expectedAnswer={activeDataStore}
            id={DATA_STORE_TECHNOLOGY_DATA_KEY}
            isRequired={true}
            activeDataStoreUUID={activeDataStore?.uuid ?? ""}
            setIsAnswerValidCallback={setIsActiveStepAnswerValid}
            shouldRenderErrors={shouldRenderErrors}
            onChangeCallback={(result): void => {
                setActiveDataStore({
                    ...activeDataStore,
                    name: result.response.name,
                    description: result.response.description,
                    technology: result.response.technology,
                    instances: result.response.instances,
                });
            }}
            onTechnologyChangeCallback={(result): void => {
                let filteredAnswers = (activeDataStore as DataStoreResponse).dataStoreAnswers;
                const questions = accessor?.answers.map((item): DataStoreQuestion => item.question) ?? [];
                if (isAndes(result.response.technology)) {
                    const ignoredQuestions = questions.filter((question: DataStoreQuestion): boolean => {
                        return question.tags.includes(QuestionTag.ignoredForAndes);
                    });
                    for (const question of ignoredQuestions) {
                        filteredAnswers = filteredAnswers.filter((response): boolean => {
                            return (
                                response.questionId !== question.id &&
                                (!(question.id in childrenIdMap) ||
                                    !childrenIdMap[question.id].includes(response.questionId))
                            );
                        });
                    }
                }
                setActiveDataStore({
                    ...activeDataStore,
                    name: result.response.name,
                    description: result.response.description,
                    technology: result.response.technology,
                    instances: result.response.instances,
                    dataStoreAnswers: filteredAnswers,
                });
            }}
        />
    );

    return {
        title: stepTitle,
        content: stepContent,
        shouldRender: true,
    };
};

interface DescriptionContentMap {
    [k: string]: JSX.Element | string;
}

const WizardStepDescriptionByTitleMap: DescriptionContentMap = {
    "Data Store Contents": (
        <span>
            Please make sure you have provided all personal data elements in the first page of your Kale Application. We
            ask that Application Owners link as many fields as possible to the{" "}
            <Link external href="https://w.amazon.com/bin/view/PrivacyEngineering/Product/DER">
                Data Element Registry
            </Link>{" "}
            the best you can.
            <b> A lack of thoroughness may cause breakages to financial and fraud systems.</b> If you have not provided
            al personal data elements on the first page of your Kale Application, please go back and provide all
            relevant information before continuing.
        </span>
    ),
    Dependencies: (
        <span>
            If dependencies were previously listed in the previous page, we brought those through here as well. Please
            confirm for completeness and accuracy.
        </span>
    ),
};

/**
 * Return a flattened list of all datastore questions belonging to the parent question's wizard step.
 */
const getQuestionsForWizardStep = (
    parentQuestion: DataStoreHookAnswer,
    collections: QuestionCollections<DataStoreHookAnswer>,
    hasTafLayout: boolean
): DataStoreHookAnswer[] => {
    const { childrenIdMap, questionsIdMap } = collections;
    const questionsList: DataStoreHookAnswer[] = [];

    // Skip the TAF deletion_obligation question because it should be handled outside of the wizard
    // Skip LegacyHidden questions because they should always be kept hidden.
    const isTafDeletionObligationQuestion =
        hasTafLayout && parentQuestion.question.shortId === DELETION_OBLIGATIONS_SHORT_ID;
    const isLegacyHiddenQuestion = parentQuestion.question.tags.includes(QuestionTag.legacyHidden);
    const shouldIncludeQuestionFamily = !isTafDeletionObligationQuestion && !isLegacyHiddenQuestion;

    if (shouldIncludeQuestionFamily) {
        // include this parent question and all applicable descendant questions in the list
        questionsList.push(parentQuestion);

        // Identify any child questions.
        const wizardStepChildIds = childrenIdMap[parentQuestion.question.id];
        const wizardStepChildQuestions = wizardStepChildIds
            .map((childId): DataStoreHookAnswer => questionsIdMap[childId])
            .filter(({ question: childQuestion }): boolean => {
                // Only include a child question in a Wizard step
                // if that child question has no title. Child questions with
                // titles should get rendered as the heads of their own separate
                // Wizard steps.
                return childQuestion.title.length === 0;
            });

        // Order the list of questions. The parent question will assume the first position, but certain
        // child question tags may indicate that a child question's family needs to be rendered first in the list.
        // This construct for reordering exists because of one or more scenarios involving synthetic
        // data store questions that want to be rendered first in a step
        wizardStepChildQuestions.forEach((childQuestion): void => {
            // Recursive Step: Get flattened list of all descendant questions in this child question's family.
            const childQuestionDescendantQuestionsList = getQuestionsForWizardStep(
                childQuestion,
                collections,
                hasTafLayout
            );

            const insertAtBeginning = childQuestion.question.tags.includes(QuestionTag.wizardStepOrderFirst);

            // Merge the list of descendant questions
            if (insertAtBeginning) {
                questionsList.unshift(...childQuestionDescendantQuestionsList);
            } else {
                questionsList.push(...childQuestionDescendantQuestionsList);
            }
        });
    }

    return questionsList;
};

/**
 * Renders several contiguous Wizard Steps from Questions in the GSM
 */
const makeDynamicQuestionSteps = (
    options: MakeWizardStepsOptions,
    collections: QuestionCollections<DataStoreHookAnswer>,
    hasTafLayout: boolean
): DataStoreWizardStep[] => {
    const { activeDataStore, questionGroupInfoMap } = options;
    const { titledQuestions } = collections;

    // Create a distinct Wizard step for every titled question. Every titled question marks the head to a list of
    // questions for a single  Wizard step.
    return titledQuestions.map((titledQuestion: DataStoreHookAnswer): DataStoreWizardStep => {
        const wizardStepQuestionsList = getQuestionsForWizardStep(titledQuestion, collections, hasTafLayout);
        const description = WizardStepDescriptionByTitleMap[titledQuestion.question.title] ?? "";
        const content = (
            <WizardStepQuestionsBuilder
                key={titledQuestion.question.shortId}
                description={description}
                technology={activeDataStore.technology}
                questionsList={wizardStepQuestionsList}
                questionGroupInfoMap={questionGroupInfoMap}
            />
        );

        return {
            content,
            title: titledQuestion.answerWithQuestion.question.title,
            isOptional: !titledQuestion.isApplicable,
            shouldRender: titledQuestion.isApplicable,
        };
    });
};

const makeDataStoreSummaryAndReviewStep = (
    options: MakeWizardStepsOptions,
    collections: QuestionCollections<DataStoreHookAnswer>,
    accessor: DataStoresAccessor | null,
    hasTafLayout: boolean
): DataStoreWizardStep => {
    const { activeDataStore, setActiveStepIndex } = options;
    const { childrenIdMap, questionsIdMap, titledQuestions } = collections;

    return {
        title: "Summary review",
        content: (
            <SummaryBuilder
                hasTafLayout={hasTafLayout}
                stepQuestions={Object.values(titledQuestions)}
                response={activeDataStore}
                questionsIdMap={questionsIdMap}
                childIdMap={childrenIdMap}
                isValidSubmit={accessor?.isValidSubmit ?? false}
                onEditButtonClicked={(index): void => setActiveStepIndex(index)}
            />
        ),
        shouldRender: true,
    };
};

export { makeDataStoreInfoStep, makeDynamicQuestionSteps, makeDataStoreSummaryAndReviewStep };
