import { ItemDescriptor } from "src/services/summaries/model/content-descriptors";
import React from "react";
import { ItemPopover } from "src/components/survey/ApplicationOverview/ContentPane/Item/ItemPopover";
import { TEST_IDS } from "shared/content-panes";
const { ITEM } = TEST_IDS.CONTENT_PANE;

interface ItemProps {
    item: ItemDescriptor;
}
export const Item = ({ item }: ItemProps): JSX.Element => {
    const itemContent =
        item.descriptors.length > 0 ? (
            <ItemPopover descriptors={item.descriptors}>{item.value}</ItemPopover>
        ) : (
            item.value
        );

    return (
        <p key={item.id} data-testid={ITEM.ROOT}>
            {itemContent}
        </p>
    );
};
