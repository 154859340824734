import React from "react";
import { isFormReadOnly } from "src/components/fields/hoc/withReadonly";
import { ApplicationStatus, SurveyConsumer } from "src/components/survey/SurveyFormModel";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { selectApplicationStatusByUserAction, UserAction } from "src/permissions";

/**
 * Derives "isFormInReadOnlyMode" prop from SurveyContext and adds it to the passed in component.
 * @param WrappedComponent: the component to set the isFormInReadOnlyMode prop on.
 */
export const withFunctionalReadonly =
    <P extends FunctionalSurveyItemProps<any>>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
    (props): JSX.Element => {
        return (
            <SurveyConsumer>
                {({ application, role }): JSX.Element => {
                    let isFormInReadonlyMode: boolean;

                    if (typeof props.isReadonly === "boolean" || typeof props.isFormInReadonlyMode === "boolean") {
                        // Allows read-only handling override by input component props
                        isFormInReadonlyMode = !!props.isReadonly || !!props.isFormInReadonlyMode;
                    } else {
                        // Read-only handling based on application status
                        isFormInReadonlyMode = isFormReadOnly(role, (): ApplicationStatus | undefined =>
                            selectApplicationStatusByUserAction(UserAction.editApplication, application, role)
                        );
                    }

                    return <WrappedComponent {...props} isFormInReadonlyMode={isFormInReadonlyMode} />;
                }}
            </SurveyConsumer>
        );
    };
