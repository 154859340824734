import * as React from "react";
import { FormField, NonCancelableCustomEvent, Select, SelectProps } from "@amzn/awsui-components-react-v3/polaris";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { GroupedQuestionOptions, groupQuestionOptions, QuestionOption } from "src/services/dynamic-questions";

const StyledSelect = styled(Select)((props: SelectProps & { width?: number }): string => {
    if (props?.width) {
        return `width: ${props.width}px`;
    }
    return "";
});

interface Props extends FunctionalSurveyItemProps<string> {
    options: QuestionOption[];
    filteringType?: SelectProps["filteringType"];
    placeholder?: string;
    showErrors?: boolean;
    width?: number;
}

const NewDropdown = (props: Props): JSX.Element => {
    const {
        id,
        isRequired,
        isReadonly,
        isFormInReadonlyMode = false,
        options,
        expectedAnswer = "",
        width,
        onChangeCallback,
        placeholder = "Choose an option",
        showErrors = null,
    } = props;

    const handleOnChange = useCallback(
        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
            const value = event.detail.selectedOption.value;
            onChangeCallback?.call(null, {
                id: id,
                response: value ?? "",
            });
        },
        [onChangeCallback, id]
    );

    // Show error only when last updated timestamp is set. Backwards compatible.
    const hasChanged = showErrors === null || showErrors;

    const groupedOptions = useMemo((): GroupedQuestionOptions => groupQuestionOptions(options), [options]);

    return (
        <FormField stretch={true} errorText={hasChanged && isRequired && !Boolean(expectedAnswer) ? "Required" : ""}>
            <div>
                <StyledSelect
                    data-testid={id}
                    filteringType={props.filteringType}
                    disabled={isReadonly ?? isFormInReadonlyMode}
                    options={groupedOptions}
                    placeholder={placeholder}
                    width={width}
                    selectedOption={expectedAnswer ? { value: expectedAnswer } : null}
                    onChange={(event): void => handleOnChange(event)}
                />
            </div>
        </FormField>
    );
};

export default withFunctionalReadonly(NewDropdown);
