import React from "react";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { TEST_IDS } from "shared/survey";
import { AuditHistoryLink } from "src/components/fields/textbox/AuditHistoryLink";

export function auditHistory(model: Model): Pair {
    return {
        label: DisplayLabels.AuditHistory,
        value: (
            <AuditHistoryLink
                appName={model.application.applicationName}
                data-testid={TEST_IDS.APPLICATION_IDENTITY.AUDIT_HISTORY}
            />
        ),
    };
}
