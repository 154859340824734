import React from "react";
import { ListDescriptor, ListStyle, ItemDescriptor } from "src/services/summaries/model/content-descriptors";
import { Item } from "src/components/survey/ApplicationOverview/ContentPane/Item";
import { TEST_IDS } from "shared/content-panes";
const { ORDERED_LIST, UN_ORDERED_LIST } = TEST_IDS.CONTENT_PANE;

interface ItemListProps {
    list: ListDescriptor;
}

const renderListItems = (items: ListDescriptor["items"]): JSX.Element[] =>
    items.map((item): JSX.Element => <ListItem key={item.id} item={item} />);

/**
 * Renders a list of contiguous items, decorated as a list
 * @param items - the data for each list item
 * @param listStyle - the list decoration style
 */
export const ItemList = ({ list: { items, listStyle } }: ItemListProps): JSX.Element | null => {
    if (items.length < 1) {
        return null;
    } else {
        const listItems = renderListItems(items);

        // Render either an <ol> or <ul> tag, based on listStyle
        const DynamicListElement = listStyle === ListStyle.Ordered ? "ol" : "ul";
        const dynamicTestId = listStyle === ListStyle.Ordered ? ORDERED_LIST : UN_ORDERED_LIST;
        return <DynamicListElement data-testid={dynamicTestId}>{listItems}</DynamicListElement>;
    }
};

/**
 * Renders an Item as a ListItem
 */
const ListItem = ({ item }: { item: ItemDescriptor }): JSX.Element => {
    return <li>{<Item item={item} />}</li>;
};
