import {
    Autosuggest,
    AutosuggestProps,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Multiselect,
} from "@amzn/awsui-components-react-v3";
import { InputProps } from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { MultiselectProps } from "@amzn/awsui-components-react-v3/polaris/multiselect/interfaces";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "src/components/fields/Loader";
import {
    useFetchTableIdentifiers,
    useFetchTeamNamesOnMount,
} from "src/components/schema_table/importers/bumblebee_importer/hooks";
import BumbleApi, { Table, TableIdentifier } from "src/services/BumblebeeService";
import { groupBy } from "lodash";
import styled from "styled-components";
import { FETCH_TABLES_ERROR } from "src/components/schema_table/importers/bumblebee_importer/constants";

export const TEST_IDS = {
    SPINNER: "bumblebee-importer-input-panel-spinner",
    TEAM_NAMES: "bumblebee-importer-input-panel-team-names",
    HOST_ENDPOINT: "bumblebee-importer-input-panel-host-endpoint",
    DATABASE_NAME: "bumblebee-importer-input-panel-database-name",
    SCHEMA_NAME: "bumblebee-importer-input-panel-schema-name",
    FETCH_TABLES_BUTTON: "bumblebee-importer-input-panel-fetch-tables-button",
};

const FormSectionFooter = styled.div`
    text-align: center !important;
    padding: 5px;
`;

export const fetchTables = async (
    selectedTableIdentifiers: TableIdentifier[]
): Promise<{ tables: Table[]; fetchTablesError: string }> => {
    const fetchMessagePrefix = "Fetching Tables from Bumblebee";
    let tables: Table[];
    let fetchTablesError: string;

    try {
        console.log(fetchMessagePrefix);
        tables = await BumbleApi.getTables(selectedTableIdentifiers);
        fetchTablesError = "";
        console.log(`${fetchMessagePrefix}: Success`);
    } catch (err) {
        console.error(`${fetchMessagePrefix}: Failed`, err);
        tables = [];
        fetchTablesError = FETCH_TABLES_ERROR;
    }

    return { tables, fetchTablesError };
};

interface Props {
    onTableIdentifiersChange: (
        teamName: string,
        hostEndpoint: string,
        databaseName: string,
        schemaNames: string[],
        table: Table[]
    ) => void;
}

const InputPanel = (props: Props): JSX.Element => {
    const { onTableIdentifiersChange } = props;
    const { isLoadingTeamNames, teamNames, teamNamesError } = useFetchTeamNamesOnMount();
    const [teamNameChoice, setTeamNameChoice] = useState<string>("");
    const { isLoadingTableIdentifiers, tableIdentifiers, tableIdentifiersError } =
        useFetchTableIdentifiers(teamNameChoice);
    const [hostEndpoints, setHostEndpoints] = useState<AutosuggestProps.Options>([]);
    const [hostEndpointChoice, setHostEndpointChoice] = useState<string>("");
    const [databaseNames, setDatabaseNames] = useState<AutosuggestProps.Options>([]);
    const [databaseNameChoice, setDatabaseNameChoice] = useState<string>("");
    const [schemaNames, setSchemaNames] = useState<AutosuggestProps.Options>([]);
    const [schemaNameChoices, setSchemaNameChoices] = useState<MultiselectProps.Options>([]);
    const [selectedTableIdentifiers, setSelectedTableIdentifiers] = useState<TableIdentifier[]>([]);
    const [fetchTablesError, setFetchTablesError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect((): void => {
        setIsLoading(isLoadingTeamNames || isLoadingTableIdentifiers);
    }, [isLoadingTableIdentifiers, isLoadingTeamNames]);
    useEffect((): void => {
        const filteredHostEndpointGroups = groupBy(tableIdentifiers, "host_endpoint");
        setHostEndpoints(
            Object.keys(filteredHostEndpointGroups).map((hostEndpoint): AutosuggestProps.Option => {
                return { value: hostEndpoint };
            })
        );
        const dbNameGroup = groupBy(
            Object.values(filteredHostEndpointGroups[hostEndpointChoice] ?? []),
            "database_name"
        );
        setDatabaseNames(
            Object.keys(dbNameGroup).map((databaseName): AutosuggestProps.Option => {
                return { value: databaseName };
            })
        );
        const schemaNameGroups = groupBy(Object.values(dbNameGroup[databaseNameChoice] ?? []), "schema_name");
        setSchemaNames(
            Object.keys(schemaNameGroups).map((schemaName): MultiselectProps.Option => {
                return { label: schemaName, value: schemaName };
            })
        );
        setSelectedTableIdentifiers(
            schemaNameChoices.reduce<TableIdentifier[]>((tableIdentifiers, schemaChoice): TableIdentifier[] => {
                return tableIdentifiers.concat(schemaNameGroups[schemaChoice.label ?? -1] ?? []);
            }, [])
        );
    }, [databaseNameChoice, hostEndpointChoice, schemaNameChoices, tableIdentifiers]);
    const onTeamNameChange = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setTeamNameChoice(event.detail.value);
        setHostEndpointChoice("");
        setDatabaseNameChoice("");
        setSchemaNameChoices([]);
    }, []);
    const onHostEndpointChange = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setHostEndpointChoice(event.detail.value);
        setDatabaseNameChoice("");
        setSchemaNameChoices([]);
    }, []);
    const onDatabaseNameChange = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setDatabaseNameChoice(event.detail.value);
        setSchemaNameChoices([]);
    }, []);
    const onSchemaNameChange = useCallback(
        (event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>): void => {
            setSchemaNameChoices(event.detail.selectedOptions);
        },
        []
    );
    const onFetchTables = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        await fetchTables(selectedTableIdentifiers).then(({ tables, fetchTablesError }): void => {
            setIsLoading(false);
            onTableIdentifiersChange(
                teamNameChoice,
                hostEndpointChoice,
                databaseNameChoice,
                schemaNameChoices.map((choice): string => choice.label ?? ""),
                tables
            );
            setFetchTablesError(fetchTablesError);
        });
    }, [
        databaseNameChoice,
        hostEndpointChoice,
        onTableIdentifiersChange,
        schemaNameChoices,
        selectedTableIdentifiers,
        teamNameChoice,
    ]);

    return (
        <React.Fragment>
            {isLoading && <Loader data-testid={TEST_IDS.SPINNER} />}
            <Container
                header={<Header variant={"h2"}>Import Settings</Header>}
                footer={
                    <FormField errorText={fetchTablesError} stretch={true}>
                        <FormSectionFooter>
                            <Button
                                data-testid={TEST_IDS.FETCH_TABLES_BUTTON}
                                loading={isLoading}
                                disabled={!Boolean(selectedTableIdentifiers.length)}
                                formAction={"none"}
                                onClick={onFetchTables}
                            >
                                Fetch Tables
                            </Button>
                        </FormSectionFooter>
                    </FormField>
                }
            >
                <ColumnLayout>
                    <FormField label={"Bumblebee Team Name"} errorText={teamNamesError}>
                        <Autosuggest
                            data-testid={TEST_IDS.TEAM_NAMES}
                            enteredTextLabel={(value): string => `Use: "${value}"`}
                            options={teamNames}
                            value={teamNameChoice}
                            disabled={!Boolean(teamNames.length)}
                            placeholder={"Select the team name"}
                            empty={"No matching team names found"}
                            disableBrowserAutocorrect={true}
                            onChange={onTeamNameChange}
                        />
                    </FormField>
                    <FormField label={"Host Endpoint"} errorText={tableIdentifiersError}>
                        <Autosuggest
                            data-testid={TEST_IDS.HOST_ENDPOINT}
                            enteredTextLabel={(value): string => `Use: "${value}"`}
                            options={hostEndpoints}
                            value={hostEndpointChoice}
                            disabled={!Boolean(hostEndpoints.length)}
                            placeholder={"Select the host endpoint"}
                            empty={"No matching host endpoints found"}
                            disableBrowserAutocorrect={true}
                            onChange={onHostEndpointChange}
                        />
                    </FormField>
                    <FormField label={"Database Name"}>
                        <Autosuggest
                            data-testid={TEST_IDS.DATABASE_NAME}
                            enteredTextLabel={(value): string => `Use: "${value}"`}
                            options={databaseNames}
                            value={databaseNameChoice}
                            disabled={!Boolean(databaseNames.length)}
                            placeholder={"Select the database name"}
                            empty={"No matching database names found"}
                            disableBrowserAutocorrect={true}
                            onChange={onDatabaseNameChange}
                        />
                    </FormField>
                    <FormField label={"Schema Names"}>
                        <Multiselect
                            data-testid={TEST_IDS.SCHEMA_NAME}
                            selectedOptions={schemaNameChoices}
                            placeholder="Select one or more Schema Names"
                            empty="No matching schema names found."
                            disabled={!Boolean(schemaNames.length)}
                            options={schemaNames}
                            onChange={onSchemaNameChange}
                        />
                    </FormField>
                </ColumnLayout>
            </Container>
        </React.Fragment>
    );
};

export default InputPanel;
