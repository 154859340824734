import { createGroupHeader } from "src/components/CommentsView/state/reducer/utils";
import { CommentThread, CommentThreadGroup, FrontEndComment } from "src/components/CommentsView/state/model";
import {
    CommentSlice,
    CommentsViewState,
    CommentThreadGroupSlice,
    CommentThreadSlice,
} from "src/components/CommentsView/state/reducer/reducer";
import { CommentCancelledAction } from "src/components/CommentsView/state/actions";

const commentSliceReducer = (
    prevState: CommentsViewState,
    cancelledFrontEndId: number
): { newCommentSlice: CommentSlice; cancelledComment: FrontEndComment } => {
    // Remove the cancelled comment from the CommentSlice frontEndId map
    const { comments: prevCommentSlice } = prevState;
    const {
        // use Object destructure to omit the cancelled comment
        [cancelledFrontEndId]: cancelledComment, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...newFrontEndIdMap
    } = prevCommentSlice.byFrontEndId;

    const newCommentSlice = {
        ...prevCommentSlice,
        byFrontEndId: newFrontEndIdMap,
    };

    return {
        newCommentSlice,
        cancelledComment,
    };
};
const commentThreadSliceReducer = (
    prevState: CommentsViewState,
    cancelledComment: FrontEndComment
): { newThreadSlice: CommentThreadSlice; cancelledThread?: CommentThread } => {
    const { comments: prevCommentSlice, commentThreads: prevThreadSlice } = prevState;

    if (cancelledComment.frontEndParentId !== null) {
        // Comment is a reply comment, we want to update to the containing thread to remove all references to
        // the cancelled comment. Look up containing thread by the cancelled comment's frontEndParentId
        const containingThreadLookupKey = cancelledComment.frontEndParentId;

        // Create a new list of commentIds in the thread that excludes the cancelled comment
        const newThreadCommentIds = prevThreadSlice.byHeadCommentId[containingThreadLookupKey].commentIds.filter(
            (frontEndId): boolean => frontEndId !== cancelledComment.frontEndId
        );

        // Determine the new lastUpdated value for the containing thread.
        const newThreadTailComment = newThreadCommentIds[newThreadCommentIds.length - 1];
        const newThreadLastUpdated = prevCommentSlice.byFrontEndId[newThreadTailComment].createdAt;

        const newThreadSlice = {
            ...prevThreadSlice,
            byHeadCommentId: {
                ...prevThreadSlice.byHeadCommentId,
                [containingThreadLookupKey]: {
                    ...prevThreadSlice.byHeadCommentId[containingThreadLookupKey],
                    commentIds: newThreadCommentIds,
                    lastUpdated: newThreadLastUpdated,
                },
            },
        };

        return { newThreadSlice };
    } else {
        // Comment is a head comment, therefore the containing thread is no longer needed.
        // Lookup the containing thread by the cancelled comment's frontEndId
        const containingThreadLookupKey = cancelledComment.frontEndId;

        const {
            // use Object destructure to omit the cancelled thread
            [containingThreadLookupKey]: cancelledThread,
            ...newHeadCommentIdMap
        } = prevThreadSlice.byHeadCommentId;

        // Exclude the thread from threads `displayOrder`
        const newThreadsDisplayOrder = prevThreadSlice.displayOrder.filter(
            (headCommentId): boolean => headCommentId !== cancelledThread.headCommentId
        );

        const newThreadSlice = {
            ...prevThreadSlice,
            byHeadCommentId: newHeadCommentIdMap,
            displayOrder: newThreadsDisplayOrder,
        };

        return { newThreadSlice, cancelledThread };
    }
};

const commentThreadGroupSliceReducer = (
    prevState: CommentsViewState,
    cancelledComment: FrontEndComment,
    cancelledThread?: CommentThread
): CommentThreadGroupSlice => {
    const { commentThreadGroups: prevGroupSlice } = prevState;

    if (cancelledThread) {
        // If cancelling a comment resulted in a comment thread being removed, then we also need to update or delete
        // the comment thread group that hosted the cancelled comment thread

        const cancelledThreadGroupHeader = createGroupHeader(cancelledComment.createdAt);
        const hostingGroup: CommentThreadGroup = prevGroupSlice.byGroupHeader[cancelledThreadGroupHeader];

        if (hostingGroup.headCommentIds.length > 1) {
            // If the group hosts more than one thread then we want to keep it. Just update the group to remove
            // references to the cancelled thread

            const newHeadCommentIds = prevGroupSlice.byGroupHeader[cancelledThreadGroupHeader].headCommentIds.filter(
                (headCommentId): boolean => headCommentId !== cancelledThread.headCommentId
            );

            return {
                ...prevGroupSlice,
                byGroupHeader: {
                    ...prevGroupSlice.byGroupHeader,
                    [cancelledThreadGroupHeader]: {
                        ...prevGroupSlice.byGroupHeader[cancelledThreadGroupHeader],
                        headCommentIds: newHeadCommentIds,
                    },
                },
            };
        } else {
            // The group only existed to host the cancelled CommentThread, therefore the group is no longer needed.
            // Remove the cancelled group from our state.

            const {
                // use Object destructure to omit the cancelled group
                [cancelledThreadGroupHeader]: cancelledGroup, // eslint-disable-line @typescript-eslint/no-unused-vars
                ...newGroupHeaderMap
            } = prevGroupSlice.byGroupHeader;

            const newDisplayOrder = prevGroupSlice.displayOrder.filter(
                (groupHeader): boolean => groupHeader !== cancelledThreadGroupHeader
            );

            return {
                ...prevGroupSlice,
                byGroupHeader: newGroupHeaderMap,
                displayOrder: newDisplayOrder,
            };
        }
    } else {
        // No work to do
        return prevGroupSlice;
    }
};

export const commentCancelledReducer = (
    prevState: CommentsViewState,
    action: CommentCancelledAction
): CommentsViewState => {
    const { cancelledFrontEndId } = action.payload;

    const { newCommentSlice, cancelledComment } = commentSliceReducer(prevState, cancelledFrontEndId);

    const { newThreadSlice, cancelledThread } = commentThreadSliceReducer(prevState, cancelledComment);

    const newGroupSlice = commentThreadGroupSliceReducer(prevState, cancelledComment, cancelledThread);

    return {
        ...prevState,
        comments: newCommentSlice,
        commentThreads: newThreadSlice,
        commentThreadGroups: newGroupSlice,
    };
};
