import { ConnectedComment, HeadComment, ReplyComment } from "src/components/CommentsView/state/model";
import {
    createDraftHeadComment,
    createDraftReplyComment,
    DraftHeadCommentOptions,
    DraftReplyCommentOptions,
} from "src/components/CommentsView/state/front-end-comments";
import { UserProps } from "src/components/KaleContext";

export enum ActionType {
    CommentsLoaded = "COMMENTS_LOADED",
    CreatingNewCommentThread = "CREATING_NEW_COMMENT_THREAD",
    AddingNewReplyComment = "ADDING_NEW_REPLY_COMMENT",
    CommentSaved = "COMMENT_SAVED",
    CommentCanceled = "COMMENT_CANCELED",
    CommentMessageChange = "COMMENT_MESSAGE_CHANGE",
    CommentViewSidebarToggled = "COMMENT_VIEW_SIDEBAR_TOGGLED",
}

export interface CommentsLoadedAction {
    type: ActionType.CommentsLoaded;
    payload: { comments: ConnectedComment[]; user: UserProps; applicationName: string };
}
export interface CreatingNewCommentThreadAction {
    type: ActionType.CreatingNewCommentThread;
    payload: { headComment: HeadComment };
}
export interface AddingNewReplyCommentAction {
    type: ActionType.AddingNewReplyComment;
    payload: { replyComment: ReplyComment };
}
export interface CommentSavedAction {
    type: ActionType.CommentSaved;
    payload: { savedComment: ConnectedComment };
}
export interface CommentCancelledAction {
    type: ActionType.CommentCanceled;
    payload: { cancelledFrontEndId: number };
}

export interface CommentMessageChangeAction {
    type: ActionType.CommentMessageChange;
    payload: { frontEndId: number; message: string };
}

export interface CommentViewSidebarToggledAction {
    type: ActionType.CommentViewSidebarToggled;
}

export type CommentsViewAction =
    | CommentsLoadedAction
    | CreatingNewCommentThreadAction
    | AddingNewReplyCommentAction
    | CommentSavedAction
    | CommentCancelledAction
    | CommentMessageChangeAction
    | CommentViewSidebarToggledAction;

export interface CommentsLoadedOptions {
    connectedComments: ConnectedComment[];
    user: UserProps;
    applicationName: string;
}
const commentsLoaded = ({ connectedComments, user, applicationName }: CommentsLoadedOptions): CommentsLoadedAction => ({
    type: ActionType.CommentsLoaded,
    payload: { comments: connectedComments, user, applicationName },
});

const creatingNewCommentThread = (options: DraftHeadCommentOptions): CreatingNewCommentThreadAction => ({
    type: ActionType.CreatingNewCommentThread,
    payload: { headComment: createDraftHeadComment(options) },
});

const addingNewReplyComment = (options: DraftReplyCommentOptions): AddingNewReplyCommentAction => ({
    type: ActionType.AddingNewReplyComment,
    payload: { replyComment: createDraftReplyComment(options) },
});

const commentCancelled = (cancelledFrontEndId: number): CommentCancelledAction => ({
    type: ActionType.CommentCanceled,
    payload: { cancelledFrontEndId },
});

const commentMessageChanged = (frontEndId: number, message: string): CommentMessageChangeAction => ({
    type: ActionType.CommentMessageChange,
    payload: { frontEndId, message },
});

const commentSaved = (savedComment: ConnectedComment): CommentSavedAction => ({
    type: ActionType.CommentSaved,
    payload: { savedComment },
});

const commentViewSidebarToggled = (): CommentViewSidebarToggledAction => ({
    type: ActionType.CommentViewSidebarToggled,
});

export {
    commentsLoaded,
    creatingNewCommentThread,
    addingNewReplyComment,
    commentCancelled,
    commentMessageChanged,
    commentSaved,
    commentViewSidebarToggled,
};
