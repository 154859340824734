import { getPairs, Model } from "src/components/survey/ApplicationIdentity/model/utils";
import React, { useMemo } from "react";
import { ColumnLayout } from "@amzn/awsui-components-react-v3";
import { KeyValuePair } from "src/components/survey/ApplicationIdentity/KeyValuePair";

export const KeyValuePairs = (model: Model): JSX.Element => {
    const elements = useMemo(
        (): JSX.Element[] =>
            getPairs(model).map((pair): JSX.Element => {
                return <KeyValuePair key={pair.label} {...pair} />;
            }),
        [model]
    );
    return (
        <ColumnLayout variant={"text-grid"} columns={3}>
            {elements}
        </ColumnLayout>
    );
};
