import { useContext, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Bindle } from "src/services/BrandleService";
import KaleContext from "src/components/KaleContext";

export interface FetchedBindlesState {
    bindles: Bindle[];
    isLoading: boolean;
    errorMsg: string;
}

export interface UseFetchedBindlesState {
    bindlesState: FetchedBindlesState;
}
const useFetchedBindlesState = (): UseFetchedBindlesState => {
    const [isLoading, setIsLoading] = useState<FetchedBindlesState["isLoading"]>(false);
    const [bindles, setBindles] = useState<FetchedBindlesState["bindles"]>([]);
    const [errorMsg, setErrorMsg] = useState<FetchedBindlesState["errorMsg"]>("");

    const {
        user: { userId },
        service: { brandleService },
    } = useContext(KaleContext);
    const dependencies = { brandleService, userId };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    // Function to fetch bindles and update local state values
    const fetchBindles = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        const { userId, brandleService } = depsRef.current;
        const { findBindlesByOwner } = brandleService;
        try {
            const bindles = await findBindlesByOwner(userId, false);
            setBindles(bindles);
            setErrorMsg("");
        } catch (err) {
            console.log(err);
            setErrorMsg((err as Error).message);
            setBindles([]);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Fetch bindles on Mount
    useEffect((): void => {
        fetchBindles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bindlesState = useMemo(
        (): FetchedBindlesState => ({
            bindles,
            isLoading,
            errorMsg,
        }),
        [bindles, isLoading, errorMsg]
    );

    return { bindlesState };
};

export default useFetchedBindlesState;
