import React, { useEffect, useRef, useState } from "react";
import { UserRole } from "src/permissions";
import Introduction from "src/components/fields/Introduction";
import Loader from "src/components/fields/Loader";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { DataStoreInstance } from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo";
import { DisplayMessageCb, KaleRoutesProps, MessageType } from "src/components/survey/KaleRoutes";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import uuid from "uuid";
import { useFetchLegalSurveyResources } from "src/components/survey/hooks/useFetchLegalSurveyResources";
import { useInitializeLegalSurveyGSM } from "src/components/survey/hooks/useInitializeLegalSurveyGSM";
import { useBulkEditPanel } from "src/components/survey/hooks/useBulkEditPanel";
import { LegalSurveyForm } from "src/components/survey/LegalSurveyForm";
import { AppLayout, SpaceBetween } from "@amzn/awsui-components-react-v3";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import { AEDUSandfireContextProvider } from "src/components/survey/AEDUSandfireContext";

export interface KeyValuePairs<T> {
    [key: string]: T;
}

export interface IntKeyValuePairs<T> {
    [key: number]: T;
}

export const DefaultDataStoreInstances: DataStoreInstance[] = [{ identifiers: [{ type: "Identifier", value: "" }] }];
export const makeDefaultDataStoreResponse = (): DataStoreResponse => ({
    name: "",
    description: "",
    technology: "",
    accountingRetentionDecision: "",
    fraudRetentionDecision: "",
    taxRetentionDecision: "",
    instances: DefaultDataStoreInstances,
    dataStoreAnswers: [],
    tables: [],
    unsavedUUID: uuid.v4(),
});

export interface AnvilProps {
    params: {
        applicationName: string;
        applicationOwner: string;
        controlBindle: string[];
        relatedBindles: string[];
        reviewGroup: string;
        applicationId: string;
        anvilId: string;
        taskId: string;
    };
}

export const LegalSurveyPage = (props: KaleRoutesProps): JSX.Element => {
    const { displayMessage } = props;
    const [overrideRole, setOverrideRole] = useState<UserRole>();
    const [isSavingApplication, setIsSavingApplication] = useState<boolean>(false);
    const {
        kaleQuestionsResources: { questions, isLoadingQuestions, questionsError },
        applicationResources: {
            application,
            applicationError,
            isLoadingApplication,
            originalDataStores,
            setApplication,
        },
        applicationMetadataResources: { metadataError, userRole },
    } = useFetchLegalSurveyResources();

    useInitializeLegalSurveyGSM(application, questions);

    const displayMessageRef = useRef<DisplayMessageCb>(displayMessage);
    useEffect((): void => {
        if (!Boolean(questionsError) && !Boolean(applicationError) && !Boolean(metadataError)) {
            return;
        }
        displayMessageRef.current(MessageType.error, questionsError || applicationError || metadataError);
    }, [questionsError, applicationError, metadataError]);

    const isLoading = isLoadingQuestions || isLoadingApplication || isSavingApplication;

    const BreadCrumbs = (props: { applicationName: string; reviewId: number }): JSX.Element => {
        const kaleRecordName = props.applicationName;
        const currentBreadcrumbItem = BreadcrumbItems.editKaleRecord(kaleRecordName, `${reviewId}`);
        const items = [BreadcrumbItems.KALE_LANDING_PAGE, currentBreadcrumbItem];
        return <KaleBreadcrumbGroup ariaLabel="Breadcrumbs" items={items} />;
    };

    const applicationName = application?.appInfo?.applicationName ?? "";
    const reviewId = application?.appInfo.review.id ?? 0;
    const displayedUserRole = overrideRole ?? userRole;
    const { bulkEditPanelToolsProps, onUpdateBulkEditPanel, screenOverlay } = useBulkEditPanel({
        application,
        setApplication,
        displayMessage,
    });

    return (
        <AEDUSandfireContextProvider applicationName={applicationName}>
            <AppLayout
                breadcrumbs={<BreadCrumbs applicationName={applicationName} reviewId={reviewId} />}
                {...bulkEditPanelToolsProps}
                navigationHide={true}
                stickyNotifications={true}
                headerSelector="#top-nav"
                content={
                    <SpaceBetween size={"l"}>
                        <Introduction userRole={overrideRole ?? userRole} />
                        {bulkEditPanelToolsProps.toolsOpen && screenOverlay}
                        {isLoading && <Loader />}
                        {application && (
                            <LegalSurveyForm
                                application={application}
                                setApplication={setApplication}
                                questions={questions}
                                displayMessage={displayMessage}
                                originalDataStores={originalDataStores}
                                userRole={displayedUserRole}
                                setUserRole={setOverrideRole}
                                setIsSavingApplication={setIsSavingApplication}
                                onUpdateBulkEditPanel={onUpdateBulkEditPanel}
                            />
                        )}
                    </SpaceBetween>
                }
            />
        </AEDUSandfireContextProvider>
    );
};
