import { Box, ColumnLayout, Container, ExpandableSection, FormField, Link } from "@amzn/awsui-components-react-v3";
import React, { Component } from "react";
import { UserRole } from "src/permissions";
import { PERSONAL_DATA_STATEMENT_TITLE } from "../survey/PersonalDataWizard/content";
import { LINKS } from "src/components/ExternalLinks";

interface IntroProps {
    userRole?: UserRole;
}

export const TEST_IDS = {
    OVERVIEW_ONE: "introduction-overview-one",
    HR_AWS: "introduction-hr-aws",
    SUBS: "introduction-subs",
    B2B_DATA: "introduction-b2b-data",
    OVERVIEW_TWO: "introduction-overview-two",
    OWNERS: "introduction-owners",
    REVIEWERS: "introduction-reviewers",
};

export default class Introduction extends Component<IntroProps> {
    public constructor(props: IntroProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <Container id={"index-header"}>
                <ColumnLayout>
                    <div>
                        <FormField
                            data-testid={"introduction-overview-one"}
                            stretch={true}
                            className={"justify-label"}
                            label={
                                <div>
                                    <Box variant="p">
                                        Any application that processes customer data (including data related to B2B
                                        customers) must complete a Kale attestation. Kale attestations must be completed
                                        by application owners (i.e. business POC(s)) and reviewed and approved by their
                                        business-line lawyer (or the business-line lawyer’s delegate). The completion of
                                        the Kale attestation is necessary for reviewers to identify and advise on
                                        applicable privacy requirements, and should be considered a P0 for any launch.
                                    </Box>
                                    <br />
                                    <Box variant="p">
                                        Before you begin, please visit the organizational specific guidance{" "}
                                        <Link href={LINKS.ORG_SPECIFIC_WIKI} external>
                                            here
                                        </Link>{" "}
                                        to ensure a timely review.
                                    </Box>
                                    <br />
                                    <Box variant="p">
                                        If you have been asked to complete a Kale attestation as part of CDO Privacy’s
                                        goal relating to B2B datastores, please review the goal-specific instructions
                                        <Link href={LINKS.B2B_WIKI} external>
                                            {" "}
                                            here
                                        </Link>{" "}
                                        first.
                                    </Box>
                                </div>
                            }
                        />
                        <ExpandableSection header="Click here for more information">
                            <div>
                                <FormField
                                    data-testid={"introduction-hr-aws"}
                                    stretch={true}
                                    className={"justify-label"}
                                    label={
                                        <Box variant="p">
                                            <u>HR Data and AWS:</u> Do NOT use this tool for any AWS applications unless
                                            you’ve been instructed otherwise by your legal and/or privacy team. If you
                                            ONLY store HR data (i.e. data relating to employees, candidates and types of
                                            workers), you should answer ’No’ to the ’{PERSONAL_DATA_STATEMENT_TITLE}’ in
                                            Kale. You will complete your attestation in approximately 5 minutes and a
                                            review will not be required. For more information on what is required for
                                            applications and teams that process HR data, please see{" "}
                                            <Link href={LINKS.HR_WIKI} external>
                                                this wiki
                                            </Link>
                                            . If an application contains both HR and customer/B2B data, use Kale as well
                                            as the HR data privacy tool.
                                        </Box>
                                    }
                                />
                                <FormField
                                    data-testid={"introduction-subs"}
                                    stretch={true}
                                    className={"justify-label"}
                                    label={
                                        <Box variant="p">
                                            <u>Subs:</u> Subsidiaries may and are encouraged to use this tool for their
                                            applications processing customer data.
                                        </Box>
                                    }
                                />
                                <FormField
                                    data-testid={"introduction-b2b-data"}
                                    stretch={true}
                                    className={"justify-label"}
                                    label={
                                        <Box variant="p">
                                            <u>B2B Data:</u> If you have been asked to complete a Kale attestation as
                                            part of CDO Privacy’s goal relating to B2B datastores, please review the
                                            goal-specific instructions{" "}
                                            <Link href={LINKS.B2B_WIKI} external>
                                                here
                                            </Link>{" "}
                                            first.
                                        </Box>
                                    }
                                />
                                <FormField
                                    data-testid={"introduction-overview-two"}
                                    stretch={true}
                                    className={"justify-label"}
                                    label={
                                        <Box variant="p">
                                            For more information on completing the Kale attestation process, please see
                                            our{" "}
                                            <Link href={LINKS.PRIVACY_WIKI} external>
                                                wiki
                                            </Link>
                                            .
                                        </Box>
                                    }
                                />
                                {!(this.props.userRole && this.props.userRole === UserRole.reviewer) && (
                                    <FormField
                                        data-testid={"introduction-owners"}
                                        stretch={true}
                                        className={"justify-label"}
                                        label={
                                            <div>
                                                <Box variant="p">
                                                    <b>INSTRUCTIONS FOR APPLICATION OWNERS:</b>
                                                    <br />
                                                    Please provide as much detail in your responses as possible. Once
                                                    complete, your responses will be reviewed prior to being approved.
                                                    Depending on your business, the reviewer may be your legal POC or
                                                    your business privacy lead.
                                                </Box>
                                                <Box variant="p">
                                                    You may be contacted with follow-up questions based on your
                                                    responses. In each case, you will need to work with the reviewer to
                                                    resolve any open questions and implement any necessary
                                                    administrative and technical requirements. You may not be permitted
                                                    to launch or publish your application until certain requirements
                                                    have been implemented.
                                                </Box>
                                            </div>
                                        }
                                    />
                                )}
                                {!(this.props.userRole && this.props.userRole === UserRole.serviceOwner) && (
                                    <FormField
                                        data-testid={"introduction-reviewers"}
                                        stretch={true}
                                        className={"justify-label"}
                                        label={
                                            <div>
                                                <Box variant="p">
                                                    <b>INSTRUCTIONS FOR REVIEWERS:</b>
                                                    <br />
                                                    Detailed instructions and guidance on how to conduct legal reviews
                                                    can be found{" "}
                                                    <Link href={LINKS.LEGAL_DATA_MAPPING} external>
                                                        here
                                                    </Link>
                                                    . If this is the first time you’ve been asked to review a Kale
                                                    attestation, or you’ve never seen this guidance before, please make
                                                    sure to read it before proceeding with your review.
                                                </Box>
                                                <Box variant="p">
                                                    {/* eslint-disable-next-line max-len */}
                                                    If you do not have access to the Legal SharePoint, please see{" "}
                                                    <Link href={LINKS.LEGAL_INFO_WIKI} external>
                                                        this wiki
                                                    </Link>{" "}
                                                    to understand our routing process and for information on how to
                                                    conduct reviews.
                                                </Box>
                                            </div>
                                        }
                                    />
                                )}
                            </div>
                        </ExpandableSection>
                    </div>
                </ColumnLayout>
            </Container>
        );
    }
}
