import React, { useEffect, useRef } from "react";
import { creatingNewCommentThread, commentsLoaded } from "src/components/CommentsView/state/actions";
import { selectGroupsDisplayOrder } from "src/components/CommentsView/state/selectors";
import CommentThreadGroup from "src/components/CommentsView/CommentThreadGroup";
import { CommentFromServer } from "src/services/NodeKaleApplicationService";
import { importCommentsFromServer } from "src/components/CommentsView/state/front-end-comments";
import { Button, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import { TEST_IDS } from "shared/comments-view";
import { UserProps } from "src/components/KaleContext";
import { useKaleDispatch, useKaleSelector } from "src/redux/hooks";

const { COMMENTS_VIEW } = TEST_IDS;

export interface CommentsViewProps {
    fetchedComments: CommentFromServer[];
    user: UserProps;
    applicationName: string;
}

interface InitializeStateOptions {
    fetchedComments: CommentFromServer[];
    user: UserProps;
    applicationName: string;
}
const useInitializeState = ({ fetchedComments, user, applicationName }: InitializeStateOptions): void => {
    const dispatch = useKaleDispatch();

    // Load comments into state
    const deps = { dispatch };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { dispatch } = dependenciesRef.current;
        // Convert comments into ConnectedComment shape
        const connectedComments = importCommentsFromServer(fetchedComments);
        dispatch(commentsLoaded({ connectedComments, user, applicationName }));
    }, [fetchedComments, user, applicationName]);
};

const CommentsView = React.memo(({ fetchedComments, user, applicationName }: CommentsViewProps): JSX.Element => {
    const dispatch = useKaleDispatch();

    useInitializeState({ fetchedComments, user, applicationName });

    const commentThreadGroups = useKaleSelector(selectGroupsDisplayOrder);

    const handleCreateNewCommentClick = (): void => {
        const newCommentOptions = { user, applicationName };
        dispatch(creatingNewCommentThread(newCommentOptions));
    };

    return (
        <div data-testid={COMMENTS_VIEW.ROOT}>
            <SpaceBetween direction={"vertical"} size={"s"}>
                <Button
                    data-testid={COMMENTS_VIEW.CREATE_COMMENT_THREAD_BUTTON}
                    variant={"normal"}
                    iconName={"edit"}
                    onClick={handleCreateNewCommentClick}
                >
                    Create Comment
                </Button>
                {commentThreadGroups.map(
                    (groupHeader): JSX.Element => (
                        <CommentThreadGroup key={groupHeader} groupHeader={groupHeader} />
                    )
                )}
            </SpaceBetween>
        </div>
    );
});

export default CommentsView;
