import React, { Component } from "react";
import styled from "styled-components";
import { Box, Link, Spinner } from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens/polaris.js";
import { SIM_TICKET_LINK } from "src/components/layout/Layout";

const StyledContainer = styled.div`
    position: fixed;
    z-index: 5000;
    height: 100%;
    width: 100%;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    background-color: ${awsui.colorBackgroundLayoutMain};
`;

const StyledLoader = styled.div`
    position: fixed;
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: -1rem;
    z-index: 5000;
`;

const MiddleAlign = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
`;

interface LoaderProps {
    hasError?: boolean;
    "data-testid"?: string;
}

export default class Loader extends Component<LoaderProps> {
    public renderContent(): JSX.Element | null {
        const { hasError } = this.props;

        if (!hasError) {
            return null;
        }

        return (
            <Box variant="p">
                Please try again. If the problem persists, please open a{" "}
                <Link external href={SIM_TICKET_LINK}>
                    ticket
                </Link>
                .
            </Box>
        );
    }

    public render(): JSX.Element {
        const { hasError, "data-testid": testId } = this.props;

        return (
            <div id={"formSpinner"} data-testid={testId}>
                <StyledContainer />
                <StyledLoader>
                    <MiddleAlign id={"loader-spinner-container"} className={"center-align"}>
                        <Box>
                            <Spinner variant={(hasError && "disabled") || "normal"} size="large" />
                        </Box>
                        {this.renderContent()}
                    </MiddleAlign>
                </StyledLoader>
            </div>
        );
    }
}
