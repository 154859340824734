import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { ApplicationStatusCallback } from "src/components/fields/hoc/withReadonly";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";

export interface ApplicationUserRules {
    value: KeyValuePairs<(status: ApplicationStatusCallback) => boolean>;
}

export enum UserRole {
    admin = "admin",
    readOnly = "readOnly",
    serviceOwner = "serviceOwner",
    reviewer = "reviewer",
    accountingReviewer = "accountingReviewer",
    fraudReviewer = "fraudReviewer",
}

export enum UserAction {
    viewRestrictedFields,
    editApplication,
    editCampaignApplication,
    submitApplication,
    deleteApplication,
    approveApplication,
    rejectApplication,
    recallApplication,
    reviewRestrictedFields,
    canOverrideStatus,
    canOverrideRole,
    viewAdminOnlyFields,
    submitFinancialFeedback,
    rejectFinancialApp,
    recallFinancialApp,
    viewDecisions,
    editDecisions,
    viewFinalDecisions,
    bulkEditTableAndFields,
    viewReviewerOnlyFields,
}

export enum CampaignWorkflow {
    AccessControl = "ACCESS_CONTROL",
    Reaffirm = "REAFFIRM",
}

export type PermissionAction = UserAction | CampaignWorkflow;

export type UserRoleApplicationStatuses = {
    [key in UserRole]: ApplicationStatus[];
};

export type PermissionMapping = {
    [key in PermissionAction]: UserRoleApplicationStatuses;
};

/** Default mapping to prepopulate each UserAction permission */
export const EMPTY_PERMISSION_ROLE_MAPPING: UserRoleApplicationStatuses = {
    [UserRole.admin]: [],
    [UserRole.serviceOwner]: [],
    [UserRole.reviewer]: [],
    [UserRole.accountingReviewer]: [],
    [UserRole.fraudReviewer]: [],
    [UserRole.readOnly]: [],
};
