import { appendRequestID } from "src/services/AppendRequestID";
import { TokenGetter } from "src/services/CognitoService";
import { FeatureToggles } from "shared/types/FeatureToggles";
export * from "shared/types/FeatureToggles";

const API_URLS = {
    GET_FEATURE_TOGGLES: "/feature-toggles",
};

const getFeatureToggles = async (apiEndpoint: string, accessTokenGetter: TokenGetter): Promise<FeatureToggles> => {
    const url = `${apiEndpoint}${API_URLS.GET_FEATURE_TOGGLES}`;
    const fetchMessagePrefix = "Fetching Feature Toggles";
    console.info(fetchMessagePrefix);
    const accessToken = await accessTokenGetter();
    try {
        const resp = await fetch(url, {
            mode: "cors",
            headers: {
                accept: "application/json",
                "content-type": "application/json",
                authorization: accessToken,
            },
            method: "GET",
            cache: "no-cache",
        });
        if (resp.ok) {
            console.info(`${fetchMessagePrefix}: Success`);
            return (await resp.json()) as unknown as FeatureToggles;
        } else {
            const errorMsg = appendRequestID(resp.statusText, resp);
            throw Error(errorMsg);
        }
    } catch (err) {
        console.error(`${fetchMessagePrefix}: Failed. `, err);
        throw err;
    }
};

export { getFeatureToggles };
