import React, { useContext } from "react";
import { KaleApplicationService } from "src/services/KaleApplicationService";
import { BrandleService, Bindle } from "src/services/BrandleService";
import { KaleTablesService } from "src/services/KaleTablesService";
import { CradleService } from "src/services/CradleService";
import { AuditLogService } from "src/services/AuditLogService";
import { FeatureToggles } from "src/services/FeatureToggleService";
import { WebSocketService } from "src/services/WebSocketService";
import { NodeKaleApplicationService } from "src/services/NodeKaleApplicationService";
import { JobService } from "src/services/JobService";
import { RumService } from "src/services/RumService";

export interface UserProps {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
}

export interface ServiceProps {
    brandleService: BrandleService;
    kaleAppService: KaleApplicationService;
    nodeKaleAppService: NodeKaleApplicationService;
    kaleTablesService: KaleTablesService;
    cradleService: CradleService;
    auditLogService: AuditLogService;
    webSocketService: WebSocketService;
    jobService: JobService;
    rumService?: RumService;
}

export interface ConfigProps {
    isItDownUrl: string;
    bindleEndpoint: string;
    sandfireBaseURL: string;
    personalDataClassifierHost: string;
    anvilFlowAppCreationTimeoutInMS: number;
}

export interface KaleContextProps {
    user: UserProps;
    service: ServiceProps;
    config: ConfigProps;
    features: FeatureToggles;
    controlBindle?: Bindle;
}

const dummyConfig = {
    apiEndpoint: "",
    webSocketEndpoint: "",
    nodeAPIEndpoint: "",
    features: {},
};
const dummyAccessTokenGetter = (): Promise<string> => Promise.resolve("");

export const defaultKaleContextValue: KaleContextProps = {
    user: {
        email: "",
        userId: "",
        firstName: "",
        lastName: "",
    },
    service: {
        brandleService: new BrandleService(dummyConfig, dummyAccessTokenGetter),
        kaleAppService: new KaleApplicationService(dummyConfig, dummyAccessTokenGetter),
        nodeKaleAppService: new NodeKaleApplicationService(dummyConfig, dummyAccessTokenGetter),
        kaleTablesService: new KaleTablesService(dummyConfig, dummyAccessTokenGetter),
        cradleService: new CradleService(dummyConfig, dummyAccessTokenGetter),
        auditLogService: new AuditLogService(dummyConfig, dummyAccessTokenGetter),
        webSocketService: new WebSocketService(dummyConfig, dummyAccessTokenGetter),
        jobService: new JobService(dummyConfig, dummyAccessTokenGetter),
    },
    config: {
        isItDownUrl: "https://is-it-down.amazon.com/dgi",
        bindleEndpoint: "https://bindles-gamma.amazon.com",
        sandfireBaseURL: "https://portal-beta.data-deletion.privacy.a2z.com",
        personalDataClassifierHost: "https://portal.beta.classifier.data-governance.privacy.a2z.com",
        anvilFlowAppCreationTimeoutInMS: 4000,
    },
    features: {},
};

const KaleContext = React.createContext(defaultKaleContextValue);

export const useKaleServices = (): ServiceProps => {
    const kaleContext = useContext(KaleContext);
    if (kaleContext === undefined) {
        throw new Error("useKaleService must be used within a KaleProvider");
    }

    return kaleContext.service;
};

export const useKaleContext = (): KaleContextProps => {
    const kaleContext = useContext(KaleContext);
    if (kaleContext === undefined) {
        throw new Error("useKaleContext must be used within a KaleProvider");
    }
    return kaleContext;
};

export const KaleProvider = KaleContext.Provider;
export const KaleConsumer = KaleContext.Consumer;

export default KaleContext;
