import TextBox from "src/components/fields/textbox/TextBox";
import Model from "src/components/constants/Constants";
import React from "react";
import { AppInfoResponse } from "src/components/survey/SurveyFormModel";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";

interface LegalPoCProps {
    onChangeCallback: OnChangeCb;
    response: AppInfoResponse;
    shouldShowErrors: boolean;
    skipValidation: boolean;
}

export const LegalPoC = ({
    onChangeCallback,
    response,
    shouldShowErrors,
    skipValidation,
}: LegalPoCProps): JSX.Element => {
    const onChange = (event: any): void => {
        onChangeCallback({
            ...response,
            review: {
                ...response.review,
                legalPocAlias: event.response,
            },
        });
    };

    return (
        <TextBox
            id={"legalPocAlias"}
            placeholder={Model.getData("legalPocAlias").placeholder}
            isRequired={!skipValidation && shouldShowErrors}
            expectedAnswer={response.review.legalPocAlias}
            onChangeCallback={onChange}
        />
    );
};
