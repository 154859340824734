import { DataStoreQuestion, QuestionTag, QuestionType, ReviewQuestion } from "src/answers_legacy";

export const FETCH_TAF_ERROR =
    "An unexpected error occurred while loading TAF survey details. Please refresh the page.";

export const DELETION_OBLIGATIONS_SHORT_ID = "deletion_obligations";

// Only BYPASS_TAF_ECS_REVIEW_GROUP should see BYPASS_TAF_DEL_OBLIG_RESP as an option on DELETION_OBLIGATIONS_SHORT_ID
export const BYPASS_TAF_ECS_REVIEW_GROUP = "kale-ecommerce-svcs";
export const BYPASS_TAF_DEL_OBLIG_RESP =
    "Soft delete - I plan to delete this data after 2 years if Tax " +
    "and Accounting's use of the data is not identified";

export enum DataStoreAttributeShortId {
    description = "description",
}

export interface DataStoreAttributePayload {
    description?: string;
}

interface SyntheticDataStoreQuestionMap {
    [k: string]: DataStoreQuestion;
}

export const syntheticDataStoreQuestionMap: SyntheticDataStoreQuestionMap = {
    [DataStoreAttributeShortId.description]: {
        id: -1,
        shortId: DataStoreAttributeShortId.description,
        type: QuestionType.textArea,
        content: "Description",
        title: "",
        subtext:
            "Provide a detailed description of the data stored in this data store. Include, at a minimum, details " +
            "like what the data is, what it does and its purpose, who it is for and what kinds of data it collects. " +
            "The more helpful context and background information you can provide here, the fewer follow-up questions " +
            "you are likely to receive.",
        groupId: "",
        choices: [],
        tags: [
            QuestionTag.required,
            QuestionTag.tafView,
            QuestionTag.tafAlwaysApplicable,
            QuestionTag.wizardStepOrderFirst,
        ],
    },
};

export const syntheticDataStoreQuestions: DataStoreQuestion[] = Object.keys(syntheticDataStoreQuestionMap).map(
    (questionKey): DataStoreQuestion => syntheticDataStoreQuestionMap[questionKey]
);

export enum ReviewAttributeShortId {
    applicationDescription = "application_description",
    dataFlowDiagram = "data_flow_diagram",
    tracksCustomerBehaviors = "customer_behaviors",
    providesCustomerIncentives = "customer_incentives",
    hasCustomerInteractions = "customer_interactions",
    customerInteractions = "customer_interactions_responses",
    assignedReviewGroups = "ui_assigned_review_groups",
}

export interface ReviewAttributePayload {
    applicationDescription?: string;
    assignedReviewGroups?: string[];
    dataFlowDiagram?: string;
    tracksCustomerBehaviors?: string;
    providesCustomerIncentives?: string;
    hasCustomerInteractions?: string;
    customerInteractions?: string[];
}

interface SyntheticReviewQuestionMap {
    [k: string]: ReviewQuestion;
}

export const syntheticReviewQuestionMap: SyntheticReviewQuestionMap = {
    [ReviewAttributeShortId.applicationDescription]: {
        id: -1,
        shortId: ReviewAttributeShortId.applicationDescription,
        type: QuestionType.textArea,
        content: "Application Description",
        title: "Application Description",
        subtext:
            "Describe the application's business use and context. How would you explain the business purpose " +
            "of the application to a reviewer who has no background on it?",
        groupId: "",
        choices: [],
        tags: [QuestionTag.required, QuestionTag.tafView],
    },
    [ReviewAttributeShortId.dataFlowDiagram]: {
        id: -1,
        shortId: ReviewAttributeShortId.dataFlowDiagram,
        type: QuestionType.text,
        content: "Please provide a link to any data flow diagrams relating to your application.",
        title: "Data Flow Diagram",
        subtext: "Please ensure your link is provided on a public wiki or Quip document with link sharing turned on.",
        groupId: "",
        choices: [],
        tags: [QuestionTag.required, QuestionTag.tafView],
    },
    [ReviewAttributeShortId.tracksCustomerBehaviors]: {
        id: -1,
        shortId: ReviewAttributeShortId.tracksCustomerBehaviors,
        type: QuestionType.radio,
        content: "Does your application handle data that tracks customer behaviors and patterns?",
        title: "Customer Behaviors and Patterns",
        subtext:
            "For example, receiving badges for engagement activities within games or health performance activities " +
            "(e.g., miles walked), tracking how long someone spends on a page, number of times a song is played, " +
            "number of times an advertisement hyperlink is clicked.",
        groupId: "",
        choices: [{ value: "Yes" }, { value: "No" }, { value: "I don't know" }],
        tags: [QuestionTag.required, QuestionTag.tafView],
    },
    [ReviewAttributeShortId.providesCustomerIncentives]: {
        id: -1,
        shortId: ReviewAttributeShortId.providesCustomerIncentives,
        type: QuestionType.radio,
        content:
            "Are there any use cases involving incentives being provided to customers as part of this application?",
        title: "Customer Incentives",
        subtext:
            "For example, an individual might be eligible to receive a discount (or another financial incentive) for " +
            "participating in a study or survey.",
        groupId: "",
        choices: [{ value: "Yes" }, { value: "No" }, { value: "I don't know" }],
        tags: [QuestionTag.required, QuestionTag.tafView],
    },
    [ReviewAttributeShortId.hasCustomerInteractions]: {
        id: -1,
        shortId: ReviewAttributeShortId.hasCustomerInteractions,
        type: QuestionType.radio,
        content:
            "Do customers have the ability to access or delete their data associated with the application directly?",
        title: "Customer Interaction",
        subtext:
            "For example, a Customer can access Amazon Photos and delete their photos at any time. Following this " +
            "act Amazon does not retain a copy of that photo; this act deletes all instances of this data.",
        choices: [{ value: "Yes" }, { value: "No" }, { value: "I don't know" }],
        groupId: "",
        tags: [QuestionTag.required, QuestionTag.tafView],
    },
    [ReviewAttributeShortId.customerInteractions]: {
        id: -1,
        shortId: ReviewAttributeShortId.customerInteractions,
        type: QuestionType.multiSelect,
        content: "Please select all that apply:",
        title: "Customer Interaction Options",
        parentShortId: ReviewAttributeShortId.hasCustomerInteractions,
        parentChoices: ["Yes"],
        subtext: "",
        groupId: "",
        choices: [
            {
                value: "Access Data - The customer can access data associated with the application directly.",
            },
            {
                value: "Download Data - The customer can download data associated with the application directly.",
            },
            {
                value:
                    "Temporary Delete - The customer can delete data associated with the application" +
                    " directly the data is not permanently removed.",
            },
            {
                value:
                    "Permanently Delete All Copies - The customer can delete data associated with the " +
                    "application directly, the data is permanently removed in all copies.",
            },
        ],
        tags: [QuestionTag.required, QuestionTag.tafView],
    },
    [ReviewAttributeShortId.assignedReviewGroups]: {
        id: -1,
        shortId: ReviewAttributeShortId.assignedReviewGroups,
        type: QuestionType.multiSelect,
        content: "Who needs to be notified to review this application?",
        title: "",
        subtext: "Please select all that apply:",
        groupId: "",
        choices: [],
        tags: [QuestionTag.tafView],
    },
};

export const syntheticReviewQuestions: ReviewQuestion[] = Object.keys(syntheticReviewQuestionMap).map(
    (questionKey): ReviewQuestion => syntheticReviewQuestionMap[questionKey]
);
