import {
    ACC_RETENT_CONF_NAME,
    ACC_RETENT_VAL_NAME,
    ID_PROP,
    NAME_PROP,
    TYPE_PROP,
} from "src/components/TAF/FinancialGRE/constants";

export enum BindleConnectionType {
    AAA = "aaa",
    AWS_ACCOUNT = "aws_account",
}

export const BindleConnectionDisplayName = {
    [BindleConnectionType.AAA]: "AAA",
    [BindleConnectionType.AWS_ACCOUNT]: "AWS",
};

export interface BindleConnection {
    connectionType: BindleConnectionType;
    otherBindleName: string;
    otherBindleCti: string;
    otherResourceId: string;
}

export interface MultiNodeConnections {
    name: string;
    financialRank: number;
    bindleName: string;
    accountingRetentionDecision: string | null;
    isSoxRelevant: string | null;
    isSoxUpstream: string | null;
    isSoxDownstream: string | null;
    isSoxSibling: string | null;
    isSoxOwner: string | null;
    isTaxRelevant: string | null;
    isTaxUpstream: string | null;
    isTaxDownstream: string | null;
    isTaxSibling: string | null;
    isTaxOwner: string | null;
    cti: string;
    type: string;
    lineageExplorerLink: string;
    lineageExplorerBindleLink: string | null;
}

export interface LineagePropertyMetadata {
    display_name: string;
    description: string;
}

export interface LineageGlossary {
    [propertyKey: string]: LineagePropertyMetadata;
}

export interface LineageNodeProperties {
    [propertyKey: string]: any;
}

export interface LineageNodePrediction {
    value: string;
    confidence: number;
}

export interface LineageNodePredictions {
    [propertyKey: string]: LineageNodePrediction;
}

export interface LineageNode {
    id: string;
    label: string;
    properties: LineageNodeProperties;
    predictions: LineageNodePredictions;

    [propertyKey: string]: any;
}

export interface PredictionsTableFlattenedNodes {
    [ID_PROP]: string;
    [NAME_PROP]: string;
    [TYPE_PROP]: string;
    [ACC_RETENT_VAL_NAME]: string;
    [ACC_RETENT_CONF_NAME]: number;
}
