import { SelectProps } from "@amzn/awsui-components-react-v3";
import { useContext, useEffect, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { isAndes } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";

export const useFetchDataStores = (
    appName: string,
    isAndesTechnology: boolean,
    currentDatsStoreId: number
): {
    isLoadingDataStores: boolean;
    dataStores: SelectProps.Options;
    fetchDataStoresError: string;
} => {
    const [isLoadingDataStores, setIsLoadingDataStores] = useState<boolean>(false);
    const [dataStores, setDataStores] = useState<SelectProps.Options>([]);
    const [fetchDataStoresError, setFetchDataStoresError] = useState<string>("");
    const { listAppDataStores } = useContext(KaleContext).service.kaleAppService;

    useEffect((): void => {
        setDataStores([]);
        setFetchDataStoresError("");
        if (!Boolean(appName)) {
            return;
        }
        setIsLoadingDataStores(true);
        listAppDataStores(appName)
            .then((ds): void => {
                setDataStores(
                    ds
                        .filter((dataStore): boolean =>
                            isAndesTechnology ? isAndes(dataStore.technology) : !isAndes(dataStore.technology)
                        )
                        .filter((dataStore): boolean => dataStore.id !== currentDatsStoreId)
                        .map((dataStore): SelectProps.Option => {
                            return {
                                label: `${dataStore.name} (${dataStore.technology})`,
                                value: dataStore.id.toString(),
                            };
                        })
                );
            })
            .catch((err: Error): void => {
                setFetchDataStoresError(err.message);
            })
            .then((): void => {
                setIsLoadingDataStores(false);
            });
    }, [appName, currentDatsStoreId, isAndesTechnology, listAppDataStores]);

    return { isLoadingDataStores, dataStores, fetchDataStoresError };
};
