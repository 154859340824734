import { AnvilIdInputWithFunctionalReadonly, AnvilIdStatus } from "src/components/survey/ApplicationPermissions";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AccessControlWizardStep } from "src/components/survey/AccessControl/AccessControlWizard";
import { ValueDictionary } from "src/services/IncrementalSave";
import { useAnvilApplications } from "src/components/survey/hooks/useAnvilApplications";
import { WizardProps } from "@amzn/awsui-components-react-v3";
import { AccessControlContext } from "src/components/survey/AccessControl/AccessControlContext";
import { selectLegalApprovalStatus } from "src/components/survey/SurveyFormModel";
import { REVIEW } from "src/components/DataDictionary";

export const useAnvilIdStep = (): AccessControlWizardStep => {
    const { application, isReadOnly } = useContext(AccessControlContext);

    const [anvilId, setAnvilId] = useState<string>(application.appInfo.review.anvilId);
    const [taskId, setTaskId] = useState<string>(application.appInfo.review.taskId);
    const [applicationOwner, setApplicationOwner] = useState<string>(application.appInfo.review.applicationOwner);
    const [valueDictionary, setValueDictionary] = useState<ValueDictionary>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const legalApprovalStatus = selectLegalApprovalStatus(application.appInfo).status;
    const anvil = useAnvilApplications({ anvilId, taskId, applicationOwner, legalApprovalStatus });
    const deps = { application };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(() => {
        setIsLoading(anvil.anvilIdStatus === AnvilIdStatus.loading);
    }, [anvil.anvilIdStatus]);

    useEffect((): void => {
        const { application } = depsRef.current;
        setTaskId(
            anvil.anvilIdStatus === AnvilIdStatus.valid
                ? ""
                : anvil.anvilDetails.taskId || application.appInfo.review.taskId
        );
        setApplicationOwner(anvil.anvilDetails.appOwner || application.appInfo.review.applicationOwner);
    }, [anvil.anvilDetails.appOwner, anvil.anvilDetails.taskId, anvil.anvilIdStatus]);

    useEffect(() => {
        setValueDictionary({
            [REVIEW.ANVIL_ID]: anvilId,
            [REVIEW.ANVIL_TASK_ID]: Boolean(anvilId) ? taskId : "",
            [REVIEW.OWNER]: Boolean(anvilId) ? applicationOwner : "",
        });
    }, [anvilId, applicationOwner, taskId]);

    const step: WizardProps.Step = {
        title: "Confirm your Anvil ID",
        content: (
            <AnvilIdInputWithFunctionalReadonly
                id={"anvilId"}
                isReadonly={isReadOnly}
                expectedAnswer={anvilId}
                anvilIds={anvil.anvilIdOptions}
                errorMessage={anvil.anvilIdError}
                status={anvil.anvilIdStatus}
                onChangeCallback={(result) => {
                    setAnvilId(result.response);
                }}
                owner={anvil.anvilDetails.appOwner}
                taskId={anvil.anvilDetails.taskId}
            />
        ),
        isOptional: true,
    };

    return { step, isLoading, valueDictionary, helpPanelContent: null, isValid: anvilId !== "" };
};
