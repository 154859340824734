import CommentThread from "src/components/CommentsView/CommentThread";
import { Box } from "@amzn/awsui-components-react-v3";
import React from "react";
import { selectCommentThreadGroupByGroupHeader } from "src/components/CommentsView/state/selectors";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/comments-view";
import { CommentThreadGroup as CommentThreadGroupData } from "src/components/CommentsView/state/model";
import { useKaleSelector } from "src/redux/hooks";

const {
    COMMENT_THREAD_GROUP: { ROOT },
} = TEST_IDS.COMMENTS_VIEW;

interface CommentThreadGroupProps {
    groupHeader: string;
}
const CommentThreadGroup = React.memo(({ groupHeader }: CommentThreadGroupProps): JSX.Element => {
    const { headCommentIds } = useKaleSelector(
        (state): CommentThreadGroupData => selectCommentThreadGroupByGroupHeader(state, groupHeader)
    );

    return (
        <div data-testid={ROOT}>
            <SpaceBetween size={"m"}>
                <Box variant="h4">{groupHeader}</Box>
                {headCommentIds.map((headCommentId): JSX.Element => {
                    return <CommentThread key={headCommentId} headCommentId={headCommentId} />;
                })}
            </SpaceBetween>
        </div>
    );
});

export default CommentThreadGroup;
