import { ControlBindleInfoContext } from "src/components/controlBindleInfoContext";
import { AEDUSandfireState } from "src/components/survey/AEDUSandfireContext";
import { kaleUrls } from "src/util/Urls";

/**
 * generateNewAEDUOnboardingSIMLink constructs a custom SIM Link that populates data into the AEDU Template so that the
 * customer doesn't have to populate data that Kale already has.
 *
 * This solution is only for 2-3 months until AEDU completes their website and then Kale will link there instead.
 *
 * Below doc describes the tradeoffs around the implementation of AEDU template data pre-population
 * https://quip-amazon.com/PywDA1rS51Dt/AEDU-Onboarding-SIM-Pre-populated-Data-Implementation-Design
 *
 * This Doc describes the exact data-population structure
 * https://quip-amazon.com/chtSAruwWonr/Exact-AEDU-Onboarding-SIM-Data-PopulationPlacement
 */
export const generateNewAEDUOnboardingSIMLink = (
    applicationName: string,
    reviewId: number,
    sandfireBaseURL: string,
    legalPOCAlias: string,
    controlBindleContext: ControlBindleInfoContext,
    externalIDContext: AEDUSandfireState,
    isChinaOnboarding: boolean
): string => {
    const linkURL = new URL("https://issues.amazon.com/issues/create?");

    // Below SIM template link is what the actual template looks like. We're taking all the data from this template
    // and also including a lot of data from the Kale app into that existing template.
    // https://issues.amazon.com/issues/create?template=17c397e0-f0a5-4029-aec1-63f65c246928
    // https://quip-amazon.com/chtSAruwWonr/Exact-AEDU-Onboarding-SIM-Data-PopulationPlacement

    const titlePrefix = isChinaOnboarding ? "Amazon China" : "Amazon Classic Regions";

    linkURL.searchParams.append("title", `${titlePrefix} Onboarding request: "${applicationName}"`);

    linkURL.searchParams.append(
        "description",
        generateTicketBody(
            applicationName,
            reviewId,
            sandfireBaseURL,
            legalPOCAlias,
            controlBindleContext,
            externalIDContext
        )
    );

    // Including the template as a search param, populates the folder info and uses the template values for any values
    // that we don't explicitly provide.
    linkURL.searchParams.append("template", "17c397e0-f0a5-4029-aec1-63f65c246928");

    // There are 2 "Custom Fields" in the SIM Template. Those require a special search param format to populate.
    // Learn more about the format here: https://sage.amazon.com/questions/1297919
    linkURL.searchParams.append("customFields[string][0][id]", "kale_attestation");
    linkURL.searchParams.append("customFields[string][0][value]", applicationName);

    const sandFireDSOIDs: string[] = [];
    externalIDContext.externalIDs?.forEach((exID): void => {
        if (exID.serviceName === "Sandfire") {
            sandFireDSOIDs.push(`${sandfireBaseURL}/dso/${encodeURIComponent(exID.serviceID)}`);
        }
    });
    const sandFireDSOIDsCommaSeparatedList = sandFireDSOIDs.join(", ");

    linkURL.searchParams.append("customFields[string][1][id]", "sandfire_onboarding");
    linkURL.searchParams.append("customFields[string][1][value]", sandFireDSOIDsCommaSeparatedList);

    return linkURL.toString();
};

const generateTicketBody = (
    applicationName: string,
    reviewId: number,
    sandfireBaseURL: string,
    legalPOCAlias: string,
    controlBindleState: ControlBindleInfoContext,
    aeduSandfireState: AEDUSandfireState
): string => {
    let sandFireDSOIDsBulletedList = "";
    aeduSandfireState.externalIDs?.forEach((exID): void => {
        if (exID.serviceName === "Sandfire") {
            // The "* " is part of markdown format to make a bulleted list
            sandFireDSOIDsBulletedList += `* ${sandfireBaseURL}/dso/${encodeURIComponent(exID.serviceID)}\n`;
        }
    });

    // If any of the data is null, leave that field empty and users are expected to populate it in the SIM template
    const teamName = controlBindleState.controlBindle?.owningTeam.teamName ?? "";
    const teamLink = teamName ? `https://permissions.amazon.com/a/team/${encodeURIComponent(teamName)}` : "";
    const teamEmail = teamName ? `${teamName}@amazon.com` : "";

    let CTILink = "";

    if (controlBindleState.controlBindle?.cti) {
        const [category, type, item] = controlBindleState.controlBindle.cti.split("/");
        CTILink = encodeURI(`https://cti.amazon.com/cti/category/${category}/type/${type}/item/${item}`);
    }

    const resolverGroup = controlBindleState.controlBindle?.resolverGroup
        ? `https://cti.amazon.com/group/${encodeURIComponent(controlBindleState.controlBindle.resolverGroup)}`
        : "";

    // See below quip on why this is being hardcoded.
    // https://quip-amazon.com/PywDA1rS51Dt/AEDU-Onboarding-SIM-Pre-populated-Data-Implementation-Design
    //
    // The "\n"s are used in the manner that they have, so that the spacing/look/feel of the original template matches
    // what we generate here, via our code, so that customers get a standard experience and the AEDU team gets tickets
    // in the expected format.
    /* eslint-disable max-len */
    return (
        "### Data Set Details\n" +
        "___All fields are MANDATORY to start AEDU Onboarding. Failing to provide information needed will result in onboarding delays and operational burden for both sides.___\n" +
        "\n" +
        "___Please make sure you read our [Onboarding wiki](https://w.amazon.com/bin/view/CDOPrivacy/Onboarding/DSAR/AEDU/)___\n" +
        "\n" +
        "### Already onboarded in other regions ?\n" +
        "___Please add a link to your data owner profile in [AEDU portal](link) or previous onboarding request.___\n" +
        "\n" +
        "#### Your product description\n" +
        "_Please add a link to your product description._\n" +
        "\n" +
        "#### Kale review\n" +
        "_Please add the link to the Kale Assessment that resulted into an onboarding with AEDU_\n" +
        `https://kale.amazon.dev${kaleUrls.editKaleRecordUrl(encodeURIComponent(applicationName), `${reviewId}`)}` +
        "\n" +
        "#### Sample DSAR & Data Category\n" +
        "_Please upload DSAR sample approved by legal and provide Data Category supplied by legal._\n" +
        "\n" +
        "#### Sandfire onboarding\n" +
        "_Please add the link to the Sandfire onboarding. If your service does not require Sandfire onboarding have your Legal POC confirm on this onboarding ticket that data has Legal Retention OR that the service is onboarded for Data Deletion through Alexa/ACEMS._\n" +
        "___[Sandfire onboarding SIM link] ___\n" +
        "\n" +
        "#### Sandfire Dataset Owner ID\n" +
        "_Please add the ID allocated in Sandfire for your dataset owner._\n" +
        sandFireDSOIDsBulletedList +
        "\n" +
        "#### Upload frequency\n" +
        "AEDU will notify you as a Data Owner through SNS in near real time. How fast will your logic consume the SNS notification and start data Upload ? [NearRealTime,Daily,other]\n" +
        "\n" +
        "#### Escalation period\n" +
        "Considering your upload frequency, when should AEDU cut a ticket to escalate unanswered notifications? We recommend choosing upload frequency plus 1 day (e.g. if you upload NearRealTime, escalation period should be 1 or 2 days). Our default period is **3 days**.\n" +
        "\n" +
        "#### Design Approach\n" +
        "Please add the link to your design.\n" +
        "\n" +
        "#### PCA Profile Compliance\n" +
        "Answer the following questions with YES or NO:\n" +
        "1. Does your service owns/store/process PCA profile’s personal data? YES or NO\n" +
        "2. Does your service owns/store/process child profile’s personal data? YES or NO\n" +
        "3. Does your service protects profile’s personal data by having PIN protection enabled? YES or NO\n" +
        "4. Does your service uses PIN profile protection vended by Identity Services? YES or NO\n" +
        "5. Do you upload profiles’ personal data as part of your current result to AEDU? YES or NO\n" +
        "\n" +
        "\n" +
        "#### General Information\n" +
        "___[Example; please replace]___\n" +
        `* Team Name: \`${teamName}\`\n` + // The ` are used here to put the value in a "box" in markdown format
        "* Permissions Team Link: **https://permissions.amazon.com/a/team/<_teams_amazon_com_link>** (this will be used to grant permissions to access AEDU portal to the team members)\n" +
        teamLink +
        "\n" +
        "* Data Owner: **<_data_owner_alias>** (Customer facing name. Try to answer to the question: How the customer knows about you (e.g. Alexa, Order History, Search etc.). Don't use your internal team/product/service name.\n" +
        "* CTI: **https://cti.amazon.com/cti/category/<_replace_category>/type/<_replace_type>/item/<_replace_item>** (this will be used to cut escalation tickets when the SLA are not met)\n" +
        CTILink +
        "\n" +
        "* Email List: (this will be used for communication between CSA and data owner)\n" +
        `\`${teamEmail}\`` +
        "\n" +
        "* Resolver Group: **https://cti.amazon.com/group/<_replace_resolver_group>** (this will be used to cut tickets to the team in order to escalate DSAR processing issues)\n" +
        resolverGroup +
        "\n" +
        "* SIM Directory ID: **https://issues.amazon.com/folders/<_folder_id>** - **this is the directory where AEDU and Customer Service Agents will cut escalation tickets - make sure the directory is public, active and able to receive High Severity Issues - i.e. Sev 2.5 / Sev 2**\n" +
        `* Legal POC: **https://phonetool.amazon.com/users/${legalPOCAlias ?? "_legal_poc"}**\n` +
        "\n" +
        "#### ETA\n" +
        "_Please add when you need to have the onboarding completed._\n" +
        "\n" +
        "### AWS Accounts\n" +
        "For a DataOwner to be able to subscribe to the generated SNS Topics, AEDU needs AWS Account IDs where the subscriptions will reside into.\n" +
        "__Please complete integration details for all your applicable usecases. It is mandatory that all stages (Beta, Gamma,Prod) are provided.__\n" +
        "\n" +
        "__Keywords__:\n" +
        "_[AWSPartition_TO_AWSPartition][Region]_ - Integration between Data Owner services deployed in AWS partitions and AEDU endpoints deployed in AWS partition (i.e. us-west-2, eu-west-1, us-east-1)\n" +
        "_[AWS-CNPartition_TO_AWS-CNPartition]_ - Integration between Data Owner services deployed in AWS:CN Partition and AEDU endpoint deployed in AWS:CN.\n" +
        "_[AWSPartiton_TO_AWS-CNPartition]_ - Integration between Data Owner services deployed in AWS partition (i.e. us-west-2) to AEDU endpoint deployed in AWS:CN. Please provide your China account id.\n" +
        "\n" +
        "\n" +
        "#### Beta\n" +
        "___[Example; please replace]___\n" +
        "* Beta AWS Account ID(s) subscribing to AEDU SNS Topic [For AWS:CN partition should be used PDX region in Beta]:\n" +
        "E.g.\n" +
        "[AWSPartition_TO_AWSPartition][*Region*] `123123123123` (Please specify region i.e. [eu-west-1])\n" +
        "[AWS-CNPartition_TO_AWS-CNPartition] `456456456456`\n" +
        "[AWSPartiton_TO_AWS-CNPartition] `789789789789`\n" +
        "\n" +
        "#### Gamma\n" +
        "___[FOLLOW SAME FORMAT AS ABOVE]___\n" +
        "\n" +
        "#### Prod\n" +
        "___[FOLLOW SAME FORMAT AS ABOVE]___\n"
    );
    /* eslint-enable max-len */
};
