import { debounce } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useSubscribeToUbergetCacheUpdatedMessage } from "src/websocket/message/UbergetCacheUpdated";
import { UseWaitForUberCacheUpdateParams, WaitForUberCacheUpdateCb } from "./types";

/** An arbitrary debounce delay aiming to encompass all subsequent write/cache updates during a single workflow. */
const UBER_CACHE_COMPLETE_ACTION_DEBOUNCE_DELAY = 800;

/**
 * The server maintained uber-get-cache is not guaranteed to be immediately updated for consumption by the time the UI
 * receives a 2xx server response or even specific wep-socket "completed" message payloads like "recall completed" or
 * "submit completed". When the UI expects to be able to fetch new server generated data from the cache after the
 * completion of specific asynchronous operation, there is a race condition that the uber-get-cache may still not have
 * been updated by the time the UI received other signals that the operation was complete. This is a known issue with
 * the backend cache infrastructure, but it does not have a quick runway to being resolved in the near term. In order
 * to establish a quasi-deterministic way for the front end to know when it is safe to fetch the new application
 * data it requires from the cache, this hook listens to the admin web socket channel for an "uber get cache updated"
 * web socket message.
 *
 * It is important to note that the "uber get cache updated" web socket messages are fired for any Kale Application
 * that has just had its cache updated. This hook is responsible for knowing how to listen for only the messages
 * pertaining to updates to the specific Kale Application that it cares about.
 *
 * Additionally, the same Kale Application might receive multiple "uber get cache updated" messages in close proximity
 * if the application was updated in various ways by 1 or more actors over a short period of time -- causing the cache
 * for that Application to produce a quick series of updates. This hook is responsible for administering a short
 * debounce duration after confirming receipt of an "uber get cache updated" for the specific application it was
 * listening for in order minimize the chance that it has received a cache update message that was the result of a
 * different update to the application than the one we were waiting for.
 *
 * @return WaitForUberCacheUpdateCb
 */
export const useWaitForUberCacheUpdate = ({
    onCacheUpdateCriteriaMet,
    updateCriteria: { applicationName, minimumReviewId },
}: UseWaitForUberCacheUpdateParams): WaitForUberCacheUpdateCb => {
    const [isListening, setIsListening] = useState<boolean>(false);

    // The uber get cache emitted payload may be sent multiple times for the same review during a single workflow.
    // (e.g. recall approved app) Therefore, we'll need to debounce the onComplete actions
    const handleCacheUpdateCriteriaMet = useHandleCacheUpdatedCriteriaMet({
        callback: () => {
            setIsListening(false);
            onCacheUpdateCriteriaMet();
        },
        duration: UBER_CACHE_COMPLETE_ACTION_DEBOUNCE_DELAY,
    });

    const deps = {
        applicationName,
        handleCacheUpdateCriteriaMet,
        isListening,
        minimumReviewId,
        onCacheUpdateCriteriaMet,
        setIsListening,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useSubscribeToUbergetCacheUpdatedMessage(
        useCallback(async function handleCacheUpdatedMessage({ payload }) {
            const { applicationName, handleCacheUpdateCriteriaMet, isListening, minimumReviewId } = depsRef.current;

            const meetsUpdateCriteria =
                payload.appName === applicationName && Number(payload.reviewId) >= minimumReviewId;

            if (isListening && meetsUpdateCriteria) {
                handleCacheUpdateCriteriaMet();
            }
        }, [])
    );

    return () => {
        setIsListening(true);
    };
};

export type HandleCacheUpdatedCriteriaMet = ReturnType<typeof debounce>;
export type HandleCacheUpdatedCriteriaMetProps = { callback: () => void; duration: number };
/**
 * Custom hook is responsible for initializing and persisting a stable instance of a debounced callback function.
 * @prop callback the callback function to be invoked after the expiration of the debounce timer. When the internal
 * debounce timer expires this hook will invoke the latest version of props.callback() that it has received.
 * @prop duration the duration of the debounce timer in ms. This value is only used when initializing the debounced
 * function during initial render. Any updates to props.duration on subsequent renders of the calling component will be
 * ignored
 */
export const useHandleCacheUpdatedCriteriaMet = ({ callback, duration }: HandleCacheUpdatedCriteriaMetProps) => {
    const deps = { callback, duration };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const debouncedCallback = useRef<HandleCacheUpdatedCriteriaMet>();
    if (!debouncedCallback.current) {
        debouncedCallback.current = debounce(() => {
            // When the debounce timer expires we want to invoke the latest version of props.callback
            depsRef.current.callback();
        }, duration);
    }
    return debouncedCallback.current;
};
