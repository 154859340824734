import { PongMessageHandler } from "src/websocket/message/Pong";
import React from "react";
import { BulkEditMessageHandler } from "src/websocket/message/BulkEdit";
import { RecallMessageHandler } from "src/websocket/message/Recall";
import { SubmitMessageHandler } from "src/websocket/message/Submit";
import { UbergetCacheUpdatedMessageHandler } from "src/websocket/message/UbergetCacheUpdated";

export interface WebSocketContextProps {
    subscribeToPongMessage: (handler: PongMessageHandler) => void;
    unsubscribeToPongMessage: (handler: PongMessageHandler) => void;
    subscribeToBulkEditMessage: (handler: BulkEditMessageHandler) => void;
    unsubscribeToBulkEditMessage: (handler: BulkEditMessageHandler) => void;
    subscribeToRecallMessage: (handler: RecallMessageHandler) => void;
    unsubscribeToRecallMessage: (handler: RecallMessageHandler) => void;
    subscribeToSubmitMessage: (handler: SubmitMessageHandler) => void;
    unsubscribeToSubmitMessage: (handler: SubmitMessageHandler) => void;
    subscribeToUbergetCacheUpdatedMessage: (handler: UbergetCacheUpdatedMessageHandler) => void;
    unsubscribeToUbergetCacheUpdatedMessage: (handler: UbergetCacheUpdatedMessageHandler) => void;
}

const defaultWebSocketContext: WebSocketContextProps = {
    subscribeToPongMessage: (): void => {},
    unsubscribeToPongMessage: (): void => {},
    subscribeToBulkEditMessage: (): void => {},
    unsubscribeToBulkEditMessage: (): void => {},
    subscribeToRecallMessage: (): void => {},
    unsubscribeToRecallMessage: (): void => {},
    subscribeToSubmitMessage: (): void => {},
    unsubscribeToSubmitMessage: (): void => {},
    subscribeToUbergetCacheUpdatedMessage: (): void => {},
    unsubscribeToUbergetCacheUpdatedMessage: (): void => {},
};
export const WebSocketContext = React.createContext(defaultWebSocketContext);
export const WebSocketProvider = WebSocketContext.Provider;
