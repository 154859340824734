import { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { KaleApplicationService, ReviewerGroupResponse } from "src/services/KaleApplicationService";
import { SelectProps } from "@amzn/awsui-components-react-v3";

export const useFetchReviewerGroup = (
    appReviewerGroup: string,
    controlBindle: SelectProps.Option | null
): {
    reviewerGroup: string;
} => {
    const kaleContext = useContext(KaleContext);
    const { kaleAppService } = kaleContext.service;
    const kaleAppServiceRef = useRef<KaleApplicationService>(kaleAppService);
    kaleAppServiceRef.current = kaleAppService;

    const [reviewerGroup, setReviewerGroup] = useState<string>(appReviewerGroup);

    useEffect((): void => {
        const { fetchReviewerGroup } = kaleAppServiceRef.current;
        if (!Boolean(controlBindle)) {
            return;
        }

        fetchReviewerGroup(controlBindle?.value ?? "")
            .then((revResponse: ReviewerGroupResponse): void => {
                setReviewerGroup(revResponse.groupName);
            })
            .catch((error: Error): void => {
                console.error(error);
            });
    }, [controlBindle]);

    return { reviewerGroup };
};
