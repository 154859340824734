// Copied from an official polaris example. Also saw 100+ other Amazon packages using this snippet.
// https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-2.1/--/src/common/clipboard.js
export function copyToClipboard(text: string): Promise<void> | undefined {
    try {
        // Try to use the modern clipboard API.
        // Some browsers only allow this API in response to a user initiated event.
        return navigator.clipboard.writeText(text);
    } catch {
        // Fall back to using a textarea.
        // https://stackoverflow.com/a/30810322/898577
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand("copy");
        } finally {
            document.body.removeChild(textArea);
        }
    }
}
