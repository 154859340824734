import { Box, Button, Link, PropertyFilterProps, SpaceBetween, TableProps } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { identity, uniqBy } from "lodash";
import {
    ContentValue,
    QuestionTag,
    QuestionType,
    TableFieldAnswer,
    TableFieldAnswerWithQuestion,
    TableFieldHookAnswer,
    TableFieldQuestion,
    useKaleTableFields,
    useKaleTableFieldSidebar,
} from "src/answers_legacy";
import { SYNTH_UNIVERSAL_COMPLIANCE_TYPE } from "src/components/TableDetails/constants";
import ConfirmFieldDelete from "src/components/TableDetails/FieldsTable/ConfirmFieldDelete";
import AddNewTableFields from "src/components/TableDetails/FieldsTable/AddNewTableFields";
import {
    ROW_NUMBERS_COLUMN_ID,
    ROW_NUMBERS_COLUMN_INITIAL_WIDTH,
    ROW_NUMBERS_COLUMN_MIN_WIDTH,
} from "src/components/TableDetails/FieldsTable/constants";
import { QuestionColumnSizing } from "src/components/TableDetails/FieldsTable/question-column-sizing";
import { TableCell } from "src/components/TableDetails/FieldsTable/TableCell";
import {
    ColumnGroupHeader,
    ColumnHeader,
    EmptyTableContainer,
    HeaderTitle,
    RowNumber,
    TableStyles,
} from "src/components/TableDetails/FieldsTable/styled";
import { FieldCellState, FieldRow, FieldTableItem } from "src/components/TableDetails/FieldsTable/types";
import { SyntheticFieldIds } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";
import { useDeleteField } from "src/components/TableDetails/TableDetailsPage/hooks";
import { OnSaveCb } from "src/components/TableDetails/TableDetailsForm/types";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import DASTable, {
    DASTableFilterProps,
    DASTableFilterType,
    GetFilteringPropertiesOptions,
    GetFilteringPropertiesResult,
} from "src/components/fields/table/DASTable";
import uuid from "uuid";
import usePrevious from "@rooks/use-previous";
import { HelpIcon } from "src/components/HelpIcon";

const FreeTextQuestions: QuestionType[] = [
    QuestionType.text,
    QuestionType.textArea,
    QuestionType.singleSelect,
    QuestionType.autoSuggest,
    QuestionType.radio,
    QuestionType.date,
];
type ColumnHeaderSectionOptions = Partial<{ columnName: string; groupName: string; subtext: string }>;
const renderColumnSectionHeader = (options: ColumnHeaderSectionOptions = {}): JSX.Element => {
    const { groupName, columnName, subtext } = options;

    // Creates InfoIcon popover if subtext is present
    const subtextInfo = (
        <SpaceBetween direction={"horizontal"} size={"xxxs"}>
            <div
                onClick={(e): void => {
                    // Prevents column header sort from triggering when icon is clicked.
                    e.stopPropagation();
                }}
            >
                <HelpIcon content={subtext}></HelpIcon>
            </div>
        </SpaceBetween>
    );

    return (
        <React.Fragment>
            {groupName && <ColumnGroupHeader>{groupName}</ColumnGroupHeader>}
            {columnName && <ColumnHeader>{columnName}</ColumnHeader>}
            {subtext && subtextInfo}
        </React.Fragment>
    );
};

const makeColumnDefinitions = (
    fieldRow: FieldRow,
    resizedColumnsWidths: number[],
    fieldVisibilityMap: Record<string, boolean | undefined>
): TableProps.ColumnDefinition<FieldTableItem>[] => {
    const resizedRowIndiceColumnWidth = resizedColumnsWidths[0];
    const resizedQuestionColumnsWidths = resizedColumnsWidths.slice(1, resizedColumnsWidths.length);

    const rowIndiceColumn: TableProps.ColumnDefinition<FieldTableItem> = {
        id: ROW_NUMBERS_COLUMN_ID,
        minWidth: ROW_NUMBERS_COLUMN_MIN_WIDTH,
        width: resizedRowIndiceColumnWidth || ROW_NUMBERS_COLUMN_INITIAL_WIDTH,
        header: renderColumnSectionHeader({ columnName: "#", groupName: SYNTH_UNIVERSAL_COMPLIANCE_TYPE }),
        cell: function ({ rowNumber }: FieldTableItem): JSX.Element {
            return <RowNumber>{rowNumber}</RowNumber>;
        },
        sortingField: "rowNumber",
        sortingComparator: (item1: FieldTableItem, item2: FieldTableItem): number => {
            return item1.rowNumber > item2.rowNumber ? 1 : -1;
        },
    };

    const questionColumns: TableProps.ColumnDefinition<FieldTableItem>[] = [];
    fieldRow.forEach(({ question }: FieldCellState, questionIndex: number): void => {
        const isToggledHiddenByUI = false === fieldVisibilityMap[question.complianceType];
        const isHiddenByServer = question.tags.includes(QuestionTag.legacyHidden);
        const shouldRenderColumn = !isToggledHiddenByUI && !isHiddenByServer;

        if (shouldRenderColumn) {
            questionColumns.push({
                id: `${question.shortId}`,
                minWidth: QuestionColumnSizing.getColumnMinWidth(question),
                width:
                    resizedQuestionColumnsWidths.length > 0
                        ? resizedQuestionColumnsWidths[questionIndex]
                        : QuestionColumnSizing.getColumnDefaultWidth(question),
                header: renderColumnSectionHeader({
                    columnName: question.content,
                    groupName: question.complianceType,
                    subtext: question.subtext,
                }),
                cell: function ({ tableRow, rowNumber }: FieldTableItem): JSX.Element {
                    const cellState: FieldCellState = tableRow[questionIndex];
                    const {
                        question: { shortId },
                    } = cellState;
                    const key = `${rowNumber}-${shortId}`;

                    return <TableCell key={key} cellState={cellState} rowData={tableRow} />;
                },
                sortingField: question.shortId,
                sortingComparator: (item1: FieldTableItem, item2: FieldTableItem): number => {
                    const v1 =
                        item1.tableRow.find((row): boolean => {
                            return row.question.shortId === question.shortId;
                        })?.value ?? "";
                    const v2 =
                        item2.tableRow.find((row): boolean => {
                            return row.question.shortId === question.shortId;
                        })?.value ?? "";
                    return v1 > v2 ? 1 : -1;
                },
            });
        }
    });

    return [rowIndiceColumn, ...questionColumns];
};

export const onFilteringChange = (item: FieldTableItem, query: PropertyFilterProps.Query): boolean => {
    const { tokens, operation } = query;

    if (!Boolean(tokens.length)) {
        return true;
    }

    const tokenOperation = (token: PropertyFilterProps.Token, fieldAnswer: TableFieldHookAnswer): boolean => {
        const compare1 = [].concat(token.value as unknown as never[]);
        const compare2 = [].concat(fieldAnswer.value as unknown as never[]);
        switch (token.operator) {
            case "!=": {
                return !compare1.some((val): boolean => compare2.includes(val));
            }
            case "=": {
                return compare1.some((val): boolean => compare2.includes(val));
            }
        }
        return false;
    };
    const shouldFilter = (token: PropertyFilterProps.Token): boolean => {
        const fieldAnswer = item.tableRow.filter((row): boolean => row.question.shortId === token.propertyKey)[0];
        if (!fieldAnswer) {
            return item.tableRow.some((row): boolean => {
                return FreeTextQuestions.includes(row.question.type) && (row.value as string).includes(token.value);
            });
        }
        switch (fieldAnswer.question.type) {
            case QuestionType.checkbox:
            case QuestionType.text:
            case QuestionType.textArea:
            case QuestionType.singleSelect:
            case QuestionType.autoSuggest:
            case QuestionType.radio:
            case QuestionType.date:
            case QuestionType.multiSelect:
                return tokenOperation(token, fieldAnswer);
            default:
                return true;
        }
    };

    switch (operation) {
        case "or":
            return tokens.some((token): boolean => shouldFilter(token));
        case "and":
            return tokens.every((token): boolean => shouldFilter(token));
        default:
            return true;
    }
};

type OnShowSidebar = (visible: boolean) => void;

export interface FieldsTableProps {
    isSaving: boolean;
    isFetchingPageResources: boolean;
    complianceTypeVisibility: Record<string, boolean | undefined>;
    onShowSidebar: OnShowSidebar;
    onSave: OnSaveCb;
    tableId: number;
}

interface QuestionContentValueMap {
    [x: string]: ContentValue[];
}

const FieldsTable = (props: FieldsTableProps): JSX.Element | null => {
    const [fieldRowsState] = useKaleTableFields();
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;
    const { isSaving, isFetchingPageResources, complianceTypeVisibility, onShowSidebar, onSave, tableId } = props;

    const fieldTableItems = useMemo((): FieldTableItem[] => {
        return fieldRowsState.map(
            (data, index): FieldTableItem => ({
                tableRow: data.answers,
                rowNumber: index + 1,
            })
        );
    }, [fieldRowsState]);

    // User can resize their columns. Keep track of any resizes they make
    // in order to preserve their resize changes between renders.
    const [resizedColumnsWidths, setResizedColumnsWidths] = useState<number[]>([]);

    const [{ answers: firstRow = [] } = {}] = fieldRowsState;

    const columnDefinitions: TableProps.ColumnDefinition<FieldTableItem>[] = firstRow?.length
        ? makeColumnDefinitions(firstRow, resizedColumnsWidths, complianceTypeVisibility)
        : [];

    const [sidebarTableField, { resetAndAddAnswers }] = useKaleTableFieldSidebar();
    const removeTableFieldsAnswers = useDeleteField(onSave);

    const [showSidebar, setShowSidebar] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const hasSidebarAnswers = sidebarTableField.answers.length > 0;

    const updateSidebar = (selectedItem: any): void => {
        const { tableRow } = selectedItem;
        const accessors = tableRow.map((accessor: any): TableFieldAnswerWithQuestion => accessor.answerWithQuestion);
        const answers = accessors.map((accessor: any): TableFieldAnswer => accessor.answer);
        const questions = accessors.map((accessor: any): TableFieldQuestion => accessor.question);

        resetAndAddAnswers(answers, questions, answers[0].fieldId);
    };

    useEffect((): void => {
        let showSidebarValue = showSidebar;
        if (hasSidebarAnswers) {
            const sidebarTableFieldId = (sidebarTableField.answers[0].answerWithQuestion.answer as TableFieldAnswer)
                .fieldId;
            setSelectedItemIndex(
                fieldTableItems.findIndex((item): boolean => {
                    const itemFieldId = (item.tableRow[0].answerWithQuestion.answer as TableFieldAnswer).fieldId;
                    return itemFieldId === sidebarTableFieldId;
                })
            );
        } else {
            showSidebarValue = false;
        }
        setShowSidebar(showSidebarValue);
        onShowSidebar(showSidebarValue);

        // NOTE: Polaris table's sticky header, when stickied, is not having its width re-calculated when
        // the sidebar/drawer is expanded. Also, the sticky header appears on top of the sidebar/drawer.
        window.scrollTo(window.scrollX, window.scrollY + 1);
        window.scrollTo(window.scrollX, window.scrollY - 1);
    }, [hasSidebarAnswers, sidebarTableField, fieldTableItems, onShowSidebar, showSidebar]);

    const onFilteringChangeCb = useCallback(onFilteringChange, []);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const filteringOptions = useMemo((): PropertyFilterProps.FilteringOption[] => {
        const uniqueAnswers: QuestionContentValueMap = {};

        fieldTableItems.forEach((fieldTableItem): void => {
            fieldTableItem.tableRow.forEach((row): void => {
                if (row.value) {
                    if (!uniqueAnswers[row.question.shortId]) {
                        uniqueAnswers[row.question.shortId] = [];
                    }
                    uniqueAnswers[row.question.shortId] = uniqBy(
                        uniqueAnswers[row.question.shortId].concat(row.value),
                        identity
                    );
                }
            });
        });

        const filteringOptions: PropertyFilterProps.FilteringOption[] = [
            ...Object.keys(uniqueAnswers)
                .map((propertyKey: string): { propertyKey: string; value: string }[] => {
                    return uniqueAnswers[propertyKey].map((value): { propertyKey: string; value: string } => {
                        return { propertyKey, value: value as string };
                    });
                })
                .flat(),
        ];

        return filteringOptions;
    }, [fieldTableItems]);

    const renderActionStripe = (): JSX.Element => {
        const disabled = isReadonly || !fieldTableItems[selectedItemIndex] || showSidebar;

        const nameField = fieldTableItems[selectedItemIndex]?.tableRow.find((answer): boolean => {
            return answer.question.shortId === SyntheticFieldIds.FieldName;
        });

        return (
            <HeaderTitle>
                <ConfirmFieldDelete
                    fieldName={nameField?.value as string}
                    show={showDeleteModal && !isReadonly}
                    onClose={(): void => {
                        setShowDeleteModal(false);
                    }}
                    onConfirm={(): void => {
                        const answersWithQuestions = fieldTableItems[selectedItemIndex].tableRow.map(
                            (accessor: TableFieldHookAnswer): TableFieldAnswerWithQuestion =>
                                accessor.answerWithQuestion as TableFieldAnswerWithQuestion
                        );
                        removeTableFieldsAnswers(answersWithQuestions);
                        setShowDeleteModal(false);
                    }}
                />
                <AddNewTableFields
                    isSaving={isSaving}
                    isVisible={showAddModal && !isReadonly}
                    onClose={(): void => {
                        setShowAddModal(false);
                    }}
                    onSubmit={onSave}
                    tableId={tableId}
                />
                <Box textAlign={"right"}>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <Button disabled={disabled} onClick={(): void => setShowDeleteModal(true)}>
                            Delete
                        </Button>
                        <Button
                            disabled={disabled}
                            onClick={(): void => {
                                const selectedItem = fieldTableItems[selectedItemIndex];
                                updateSidebar(selectedItem);
                                setShowSidebar(!showSidebar);
                                onShowSidebar(!showSidebar);
                            }}
                        >
                            Edit
                        </Button>
                        <Button disabled={isReadonly} onClick={(): void => setShowAddModal(true)}>
                            Add
                        </Button>
                    </SpaceBetween>
                </Box>
            </HeaderTitle>
        );
    };

    const emptyTableMessage = (
        <EmptyTableContainer>
            <SpaceBetween size={"m"} direction={"vertical"}>
                <Box variant="h3">Add field attestations for your table</Box>
                <Button disabled={isReadonly} onClick={(): void => setShowAddModal(true)}>
                    Add
                </Button>
            </SpaceBetween>
        </EmptyTableContainer>
    );

    function getFilteringProperties({
        columnDefinitions,
        rowItems,
        filterKeys,
    }: GetFilteringPropertiesOptions<FieldTableItem>): GetFilteringPropertiesResult {
        let filteringProperties: GetFilteringPropertiesResult = [];
        if (rowItems.length > 0 && columnDefinitions.length > 0) {
            filteringProperties =
                filterKeys?.map((key: string): PropertyFilterProps.FilteringProperty => {
                    const question = (rowItems[0].tableRow.find((row): boolean => row.question.shortId === key) ?? {})
                        .question as unknown as TableFieldQuestion;
                    const label = `${question.complianceType ? `${question.complianceType} - ` : ""}${
                        question.title || question.content
                    }`;
                    const headerText = key === ROW_NUMBERS_COLUMN_ID ? "Row number" : label;

                    return {
                        key,
                        groupValuesLabel: `${headerText} Values`,
                        operators: ["=", "!="],
                        propertyLabel: headerText,
                    };
                }) ?? [];
        }

        return filteringProperties;
    }

    const filterKeys = uniqBy(
        filteringOptions.map((option): string => option.propertyKey),
        identity
    );

    const [tableKey, setTableKey] = useState(uuid.v4());
    const previousIsSaving = usePrevious(isSaving);
    useEffect((): void => {
        // Update the table key only when it is a completed save operation, so that column headers are re-rendered.
        if (!isSaving && previousIsSaving === true) {
            setTableKey(uuid.v4());
        }
    }, [isSaving, previousIsSaving]);

    const filterProps: DASTableFilterProps<FieldTableItem> = {
        type: DASTableFilterType.propertyFilter,
        filterKeys,
        internalOverrides: {
            filteringOptions,
            filteringFunction: onFilteringChangeCb,
            getFilteringProperties,
        },
    };

    return (
        <TableStyles>
            <DASTable<FieldTableItem>
                key={tableKey}
                id={"fieldsTable"}
                tableName={"Fields"}
                isLoading={isFetchingPageResources}
                isStickyHeader={true}
                columnDefinitions={columnDefinitions}
                selectionType={"single"}
                filterProps={filterProps}
                emptyTextPrefix={"No fields"}
                tableDescription={
                    <Box variant={"p"}>
                        Please add all fields used in this data table. This section is mandatory for teams under Digital
                        Privacy. If this is a financially relevant application, this section is also mandatory for
                        fields of data stores that store data beyond 30 days. Before completing this section, please
                        review the{" "}
                        <Link target={"_blank"} href={"https://w.amazon.com/bin/view/Kale/OrgSpecificGuidance/"}>
                            organization-specific guidance.
                        </Link>
                    </Box>
                }
                emptyTableMessage={emptyTableMessage}
                rowItems={fieldTableItems}
                isResizable={true}
                renderActionStripe={renderActionStripe}
                onSelectionChange={(e): void => {
                    const [selectedItem] = e.detail.selectedItems;
                    if (selectedItem) {
                        updateSidebar(selectedItem);
                    }
                }}
                onColumnWidthsChange={(e): void => {
                    setResizedColumnsWidths([...e.detail.widths]);
                }}
            />
        </TableStyles>
    );
};

export default React.memo(FieldsTable);
