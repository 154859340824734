import { Flashbar } from "@amzn/awsui-components-react-v3";
import { default as React } from "react";
import { useKaleSelector } from "src/redux/hooks";
import { selectSummaryCardItemsCount } from "src/components/survey/ApplicationOverview/state/selectors";
import { CardNames } from "src/components/survey/ApplicationOverview/state/model";
import { TEST_IDS } from "shared/survey";

const { PRIVACY_ALERTS_BANNER } = TEST_IDS.APPLICATION_OVERVIEW;

interface AlertsBannerProps {
    buttonText: string;
    onButtonClick: () => void;
    hasReviewerPermissions: boolean;
}

/**
 * Component to render a Flashbar when privacy alerts are present in an Application
 */
export const AlertsBanner = React.memo(
    ({ buttonText, onButtonClick, hasReviewerPermissions }: AlertsBannerProps): JSX.Element | null => {
        const alertsCount = useKaleSelector((state): number =>
            selectSummaryCardItemsCount(state, CardNames.privacyAlertsSummary)
        );

        const hasAlerts = alertsCount > 0;
        const shouldShowBanner = hasReviewerPermissions && hasAlerts;

        return shouldShowBanner ? (
            <Flashbar
                data-testid={PRIVACY_ALERTS_BANNER.ROOT}
                items={[
                    {
                        header: (
                            <span>
                                <span data-testid={PRIVACY_ALERTS_BANNER.ALERTS_COUNT}>{`${alertsCount}`}</span> Privacy
                                Alerts
                            </span>
                        ),
                        type: "error",
                        dismissible: false,
                        buttonText,
                        onButtonClick,
                    },
                ]}
            />
        ) : null;
    }
);
