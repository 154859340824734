import { AccessControlWizardStep } from "src/components/survey/AccessControl/AccessControlWizard";
import { WizardProps } from "@amzn/awsui-components-react-v3";
import React, { useContext, useEffect, useState } from "react";
import { ValueDictionary } from "src/services/IncrementalSave";
import TextArea from "src/components/fields/textbox/TextArea";
import { AccessControlContext } from "src/components/survey/AccessControl/AccessControlContext";
import { TEST_IDS } from "shared/survey/accessControlWizrad";
import { REVIEW } from "src/components/DataDictionary";

export const useExplanationStep = (): AccessControlWizardStep => {
    const { application, isReadOnly } = useContext(AccessControlContext);

    const [explanation, setExplanation] = useState<string>(application.appInfo.review.definedExplanation);
    const [valueDictionary, setValueDictionary] = useState<ValueDictionary>({});

    useEffect(() => {
        setValueDictionary({ [REVIEW.NO_EXTERNAL_ID_EXPLANATION]: explanation });
    }, [explanation]);

    const step: WizardProps.Step = {
        title: "Explain why your app has no external identifiers",
        content: (
            <TextArea
                id={"definedExplanation"}
                data-testid={TEST_IDS.WIZARD.STEPS.EXPLANATION.ROOT}
                isReadonly={isReadOnly}
                expectedAnswer={explanation}
                onChangeCallback={(event) => setExplanation(event.response)}
            />
        ),
        isOptional: true,
    };

    return { step, isLoading: false, valueDictionary, helpPanelContent: null, isValid: explanation !== "" };
};
