/**
 * Helper function to save you from typing delicate selector strings.
 * @return a CSS attribute selector string.
 * @example
 *  attrSelector("href", "www.amazon.com") => '[href="www.amazon.com"]'
 *      a selector string to match elements like <a href="www.amazon.com">
 *  attrSelector("data-parent", "cars") => '[data-parent="cars"]'
 *       a selector string to match elements like <div data-parent="cars">
 */
export const attrSelector = (attrName: string, attrValue: string): string => `[${attrName}="${attrValue}"]`;

/**
 * Helper function to save you from typing delicate selector strings.
 * @return a CSS "data-testid" attribute selector string.
 * @example
 *  tidSelector("table-footer") => '[data-testid="table-footer"]'
 *     a selector string to match elements like <div data-testid="table-footer">
 */
export const tidSelector = (testId: string): string => attrSelector("data-testid", testId);

/**
 * Helper function to save you from typing delicate selector strings.
 * @return a CSS string that has joined all params together into a single string.
 * @param params: one or more CSS selector strings to join together.
 * @example
 *  compoundSelector("input", attrSelector("type", "checkbox")) => 'input[type="checkbox"]'
 *      a selector string to match elements like <input type="checkbox">
 *  compoundSelector("button", "#primary", tidSelector("toggle-button")) => 'button#primary[data-testid="toggle"]'
 *     a selector string to match elements like <button id="primary" data-testid="toggle">
 */
export const compoundSelector = (...params: string[]): string => params.join("");

/**
 * Helper function to save you from typing delicate selector strings.
 * @return a CSS string that inserts a space character (" ") between all params and joins them together.
 * @param params: one or more CSS selector strings to join around space characters (" ").
 * @example
 *  descendantSelector("h1", tidSelector("left-nav")")) => 'h1 [data-testid="left-nav"]'
 *      a selector string to match elements in HTML trees like:
 *      <h1><div data-testid="left-nav"></div></h1> OR <h1><p><span><ul [data-testid="left-nav"]' /></span></p></h1>
 *  descendantSelector("#one", ".two", tidSelector("three" ) => '#one .two [data-testid="three"]'
 *     a selector string to match elements in HTML trees like:
 *     <p id="one"><p class="two"><p data-testid="three" /></p></p>
 */
export const descendantSelector = (...params: string[]): string => params.join(" ");

/**
 * Helper function to save you from typing delicate selector strings.
 * @return a CSS string that inserts a comma character (",") between all params and joins them together.
 * @param params: one or more CSS selector strings to join around comma characters (",").
 * @example
 *  multiSelector("h1", h2")) => 'h1,h2'
 *      a selector string to any h1 or h2 elements in HTML trees
 *  multiSelector(tidSelector("hidden"), tidSelector("visible")) => '[data-testid="hidden"],[data-testid="visible"]'
 *     a selector string to match elements in HTML trees like:
 *     <div data-testid="hidden"/> or <span data-testid="visible"/>
 */
export const multiSelector = (...params: string[]): string => params.join(",");

/**
 * Helper function to save you from typing delicate selector strings.
 * @return a CSS "class^" begins-with selector.
 * @example
 *  classPrefixSelector("placeholder") => '[class^="placeholder"]'
 *     a selector string to match elements like
 *     <a class="placeholder">,
 *     <p class="placeholder-xyz-123">,
 */
export const classPrefixSelector = (classPrefix: string): string => attrSelector("class^", classPrefix);
