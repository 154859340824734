import styled from "styled-components";
import React, { useState } from "react";
import { Button, ButtonProps } from "@amzn/awsui-components-react-v3";
import ConfirmActionModal, { ConfirmActionModalProps } from "src/components/ConfirmActions/ConfirmActionModal";

// This component style prevents hidden modal component from forcing other components onto a new line
const ModalWrapper = styled.span`
    display: inline-block;
`;

const TRIGGER_BUTTON_DEFAULT_PROPS = {
    variant: "primary" as ButtonProps.Variant,
    disabled: false,
};

/**
 * ConfirmActionModalButton is a shared component used in many places throughout Kale. Rather than having its own
 * hardcoded data-testid values, it expects its parent to claim it by providing unique test ids for it to decorate
 * itself with.
 */
type ConfirmActionModalButtonTestIds = ConfirmActionModalProps["testIds"] & {
    /** The trigger button that will show Modal. */
    triggerButton: string;
};

/**
 * Overrides for some default properties of the trigger button that we support today.
 * This could be expanded to support overriding more Button props as needed
 */
type TriggerButtonOverrides = Pick<ButtonProps, "disabled" | "variant">;

export interface ConfirmActionModalButtonProps {
    testIds: ConfirmActionModalButtonTestIds;
    /** Sets the trigger button and both Modal buttons for cancel & confirm into a loading state when true */
    loading?: boolean;
    /** The label that will be applied to the trigger button and the confirm button that appears inside the modal */
    actionLabel: ButtonProps["children"];
    triggerButtonOverrides?: TriggerButtonOverrides;
    modalProps: Pick<ConfirmActionModalProps, "header" | "size" | "onConfirm" | "onCancel"> & {
        /** Primary content region of the modal */
        content: ConfirmActionModalProps["children"];
    };
}

/**
 * This is a convenience component responsible for rendering a ConfirmActionModal paired with a corresponding
 * trigger Button that is pre-wired to show the modal when clicked. Additionally, it pre-wires both the cancel
 * and confirm buttons within the accompanying ConfirmActionModal to hide the Modal of either is clicked. This
 * component bears sole responsibility for managing the visibility of the Modal at all times. These behaviors are
 * not controllable and this convenience component should only be used by callers who do not require manual control
 * over the visibility of the Modal. Any callers requiring such control should render a standalone
 * <ConfirmActionModal> component instead.
 */
export const ConfirmActionModalButton = ({
    modalProps,
    triggerButtonOverrides,
    loading = false,
    actionLabel,
    testIds,
}: ConfirmActionModalButtonProps): JSX.Element => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);

    // Render Trigger Button
    const onShow = (): void => setIsModalVisible(true);
    const triggerButton = (
        <Button
            {...TRIGGER_BUTTON_DEFAULT_PROPS}
            data-testid={testIds.triggerButton}
            loading={loading}
            onClick={onShow}
            {...triggerButtonOverrides}
        >
            {actionLabel}
        </Button>
    );

    // Render Modal
    const { content, onConfirm: onConfirmCb, onCancel: onCancelCb, ...additionalModalProps } = modalProps;
    const onCancel = (): void => {
        setIsModalVisible(false);
        onCancelCb?.();
    };
    const onConfirm = async (event: CustomEvent<ButtonProps.ClickDetail>): Promise<void> => {
        if (isConfirming) return;
        try {
            setIsConfirming(true);
            await onConfirmCb(event);
        } finally {
            setIsModalVisible(false);
            setIsConfirming(false);
        }
    };
    const confirmActionModal = (
        <ConfirmActionModal
            testIds={testIds}
            loading={loading}
            visible={isModalVisible}
            confirmButtonOverrides={{
                label: actionLabel,
            }}
            onConfirm={onConfirm}
            onCancel={onCancel}
            {...additionalModalProps}
        >
            {content}
        </ConfirmActionModal>
    );

    return (
        <React.Fragment>
            {triggerButton}
            <ModalWrapper>{confirmActionModal}</ModalWrapper>
        </React.Fragment>
    );
};
