import React, { useContext } from "react";
import { TEST_IDS } from "shared/survey";
import DASTable from "src/components/fields/table/DASTable";
import { ExternalId } from "src/services/KaleApplicationService";
import { Alert, Link, SpaceBetween, TableProps } from "@amzn/awsui-components-react-v3";
import { useAEDUSandfireContext } from "src/components/survey/AEDUSandfireContext";
import KaleContext from "src/components/KaleContext";

enum ExternalIdItemKey {
    serviceName = "serviceName",
    serviceID = "serviceID",
    appName = "appName",
    onboardingStatus = "onboardingStatus",
    enabledDate = "enabledDate",
    category = "category",
}

const AEDU_BASE_URL = "https://portal.aedu.privacy.a2z.com";

export const formatDate = (date: Date): string => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const AEDUSandfireTable = (): JSX.Element => {
    const { externalIDs, errorMsg, isLoading } = useAEDUSandfireContext();
    const { sandfireBaseURL } = useContext(KaleContext).config;

    const columnDefinitions: TableProps.ColumnDefinition<ExternalId>[] = [
        {
            id: ExternalIdItemKey.serviceName,
            header: "Onboarding Tool",
            cell: (item): string => item.serviceName,
        },
        {
            id: ExternalIdItemKey.serviceID,
            header: "Dataset Owner Id",
            cell: (item): React.ReactNode => {
                const url =
                    item.serviceName === "AEDU"
                        ? `${AEDU_BASE_URL}/data-owners/${item.serviceID}`
                        : `${sandfireBaseURL}/dso/wizard/step/collect-data/${item.serviceID}`;
                return (
                    <Link external={true} href={url}>
                        {item.serviceID}
                    </Link>
                );
            },
        },
        {
            id: ExternalIdItemKey.appName,
            header: "Onboarding App Name",
            cell: (item): string => item.metaData?.app_name ?? "",
        },
        {
            id: ExternalIdItemKey.onboardingStatus,
            header: "Onboarding Status",
            cell: (item): string => item.onboardingStatus,
        },
        {
            id: ExternalIdItemKey.enabledDate,
            header: "Enabled Date",
            cell: (item): string =>
                item.metaData?.enabling_date ? formatDate(new Date(item.metaData?.enabling_date)) : "",
        },
        {
            id: ExternalIdItemKey.category,
            header: "Dataset Owner CTI",
            cell: (item): string => `${item.category}/${item.type}/${item.item}`,
        },
    ];

    return (
        <SpaceBetween size={"l"}>
            {errorMsg ? (
                <Alert data-testid={TEST_IDS.AEDU_SANDFIRE.ERROR} type={"error"}>
                    {errorMsg}
                </Alert>
            ) : null}
            <DASTable<ExternalId>
                id={"aedu-sandfire-table"}
                data-testid={TEST_IDS.AEDU_SANDFIRE.TABLE}
                tableName={"DSAR and Data Deletion Onboarding Status"}
                isLoading={isLoading}
                columnDefinitions={columnDefinitions}
                rowItems={externalIDs ?? []}
                emptyTextPrefix={"No onboarding statuses"}
            />
        </SpaceBetween>
    );
};

export { AEDUSandfireTable };
