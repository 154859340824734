import { ApprovalType, getApproval } from "src/components/survey/SurveyFormModel";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { ApprovalStatusBadge } from "src/components/ApprovalStatuses/ApprovalStatusBadge";

export function fraudStatus(model: Model): Pair {
    const type = ApprovalType.FraudApproval;
    const approval = getApproval(model.application, type);
    return {
        label: DisplayLabels.FraudStatus,
        value: ApprovalStatusBadge({ status: approval.status, type: type }),
    };
}
