import React, { useEffect, useRef } from "react";
import {
    AnswerActions,
    QuestionTag,
    ResetOptionsCallback,
    ReviewAnswer,
    ReviewQuestion,
    useReview,
} from "src/answers_legacy";
import { isSensitiveData } from "src/components/survey/resolveSpecialReviewQuestions";
import { ReviewQuestionBuilder } from "src/components/survey/ReviewQuestions/ReviewQuestionBuilder";
import { HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";

interface ReviewQuestionsProps {
    hasPersonalData: HasPersonalDataResponse;
    shouldShowErrors: boolean;
}
const ReviewQuestions = (ReviewQuestionsProps: ReviewQuestionsProps): JSX.Element => {
    const { hasPersonalData, shouldShowErrors } = ReviewQuestionsProps;
    const [accessors, reviewActions] = useReview();
    const reviewActionsRef = useRef<AnswerActions<ReviewQuestion, ReviewAnswer>>(reviewActions);
    reviewActionsRef.current = reviewActions;
    useEffect((): ResetOptionsCallback => {
        const { addOptions, resetOptions } = reviewActionsRef.current;
        addOptions({
            constraintHandler: ({
                hasParentConstraint,
                isParentConstraintMet,
                question,
                answers,
            }): [boolean, boolean] => {
                const personalDataResponse = hasPersonalData;
                if (personalDataResponse === HasPersonalDataResponse.Empty) {
                    return [false, false];
                }

                const storePersonalData =
                    personalDataResponse === HasPersonalDataResponse.Store &&
                    question.tags.includes(QuestionTag.storePersonalData);
                const processPersonalData =
                    personalDataResponse === HasPersonalDataResponse.Process &&
                    question.tags.includes(QuestionTag.processPersonalData);

                if (storePersonalData || processPersonalData || isSensitiveData(answers, question)) {
                    return [true, true];
                }
                if (hasParentConstraint) {
                    return [hasParentConstraint, isParentConstraintMet];
                }
                return [true, false];
            },
        });
        return (): void => resetOptions();
    }, [hasPersonalData]);

    const reviewQuestions =
        accessors?.answers
            .filter((item): boolean => !item.question.tags.includes(QuestionTag.campaignOnly))
            .map((item): React.ReactNode => {
                return (
                    <ReviewQuestionBuilder
                        key={item.question.shortId}
                        item={item}
                        shouldShowErrors={shouldShowErrors}
                    />
                );
            }) ?? [];

    return <React.Fragment>{reviewQuestions}</React.Fragment>;
};

export default ReviewQuestions;
