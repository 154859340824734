import { ClassificationScore, PDCFinding } from "src/services/NodeKaleApplicationService";
import { useMemo } from "react";

/** PDCFindings with this classificationScore or higher are considered to be High Confidence findings */
export const HIGH_CONFIDENCE_THRESHOLD: ClassificationScore = 0.91;

export const checkForHighConfidenceFindings = (pdcFindings: PDCFinding[]) =>
    pdcFindings.some(
        (pdcFinding) => pdcFinding.highestClassificationScoreAcrossAllEntities >= HIGH_CONFIDENCE_THRESHOLD
    );

export const useCheckForHighConfidenceFindings = (pdcFindings: PDCFinding[]) => {
    return useMemo(() => checkForHighConfidenceFindings(pdcFindings), [pdcFindings]);
};
