import { Button, ButtonProps, Modal, ModalProps } from "@amzn/awsui-components-react-v3";
import React from "react";
import styled from "styled-components";

const FooterContentWrapper = styled.span`
    float: right;
`;

/**
 * ConfirmActionModal is a shared component used in many places throughout Kale. Rather than having its own
 * Hardcoded data-testid values, it expects its parent to claim it by providing unique test ids for it to
 * decorate itself with.
 */
interface ConfirmActionModalTestIds {
    /** The cancel button inside the Modal */
    cancelButton: string;
    /** The confirm button inside the Modal */
    confirmButton: string;
    /** The root element of the Modal */
    modalRoot: string;
}

/**
 * Overrides for some default properties of the confirm button
 */
interface ConfirmButtonOverrides {
    /** Defaults to false */
    disabled?: ButtonProps["disabled"];
    /** Defaults to "Confirm" */
    label?: ButtonProps["children"];
}
export type ConfirmActionModalProps = Pick<ModalProps, "children" | "header" | "visible" | "size"> & {
    testIds: ConfirmActionModalTestIds;
    /** Renders the modal's cancel and confirm buttons in a loading state when true */
    loading?: boolean;
    onConfirm: (event: CustomEvent<ButtonProps.ClickDetail>) => void;
    /** Called whenever a user clicks the cancel button inside of the ConfirmActionModal or when a user performs any
     * action that would normally cause a Polaris Modal to call its `onDismiss` handler.
     */
    onCancel?: () => void;
    /** Overrides for some default properties of the confirm button */
    confirmButtonOverrides?: ConfirmButtonOverrides;
};

export const ConfirmActionModal = ({
    testIds,
    loading,
    onCancel,
    onConfirm,
    header,
    children,
    size,
    visible,
    confirmButtonOverrides,
}: ConfirmActionModalProps): JSX.Element => {
    // Render cancel button
    const cancelButtonProps: ButtonProps = {
        variant: "link",
        loading,
        onClick: onCancel,
    };
    const cancelButton = (
        <Button data-testid={testIds.cancelButton} {...cancelButtonProps}>
            Cancel
        </Button>
    );

    // Render confirm button
    const isConfirmDisabled = confirmButtonOverrides?.disabled ?? false;
    const confirmLabel = confirmButtonOverrides?.label ?? "Confirm";
    const confirmButtonProps: ButtonProps = {
        loading,
        variant: "primary",
        onClick: onConfirm,
        disabled: isConfirmDisabled,
    };
    const confirmButton = (
        <Button data-testid={testIds.confirmButton} {...confirmButtonProps}>
            {confirmLabel}
        </Button>
    );

    return (
        <Modal
            data-testid={testIds.modalRoot}
            visible={visible}
            header={header}
            onDismiss={onCancel}
            size={size}
            footer={
                <FooterContentWrapper>
                    {cancelButton}
                    {confirmButton}
                </FooterContentWrapper>
            }
        >
            {children}
        </Modal>
    );
};

export default ConfirmActionModal;
