import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDataStores, useReview } from "src/answers_legacy";
import Model from "src/components/constants/Constants";
import { isDataStoresInfoStepValid } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { AppInfoResponse, HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";
import { pick } from "lodash";
import {
    isAppNameValid,
    isWillingToProvideInfoValid,
    isContactListValid,
    isExplanationValid,
} from "src/components/survey/hooks/useApplicationValidation/utils";
import KaleContext from "src/components/KaleContext";

export const ERROR_MESSAGES = {
    MANDATORY_MISSING: "Please fill the mandatory fields before submitting.",
    dataStoresRequiresTableAttestation: (dataStoreNames: string[]): string =>
        `The data store(s) ${dataStoreNames.join(", ")} contain personal data and require at least one table.` +
        " All associated tables must also have at least one field.",
    ALLOWED_SPECIAL_CHARS: "Allowed special characters  & - _ . ( )",
    APPLICATION_PERMISSIONS_SECTION_MISSING: "Please provide at least one of the above three information",
    ANVIL_ID_EXPLANATION_MISSING: "Please provide an explanation if answering N/A for Anvil Application ID",
};

const appValidationFieldForSubmit: string[] = Model.getData("appValidationFieldForSubmit");
const appValidationFieldForDraft: string[] = Model.getData("appValidationFieldForDraft");
const applicationPermissionsFields: string[] = Model.getData("applicationPermissionsFields");

export interface ValidationStatus {
    isValid: boolean;
    errorMessage: string;
}

export interface ValidationResponse {
    submitStatus: ValidationStatus;
    saveStatus: ValidationStatus;
    appNameErrMsg: string;
    applicationPermissionsSectionErrorMsg: string;
}

export const useApplicationValidation = (appInfo: AppInfoResponse): ValidationResponse => {
    const { review, applicationName } = appInfo;

    const kaleContext = useContext(KaleContext);
    const { isAnvilDeprecated } = kaleContext.features;

    const [reviewAnswerState] = useReview();
    const [dataStoreAnswerState] = useDataStores();

    const [isValidSave, setIsValidSave] = useState<boolean>(false);
    const [saveErrorMessage, setSaveErrorMessage] = useState<string>("");
    const [isValidSubmit, setIsValidSubmit] = useState<boolean>(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState<string>("");

    const flatApplication = useMemo(() => ({ ...appInfo, ...review }), [appInfo, review]);

    const hasValidAppName = isAppNameValid(applicationName);
    const hasValidContactList = isContactListValid(flatApplication.contactList);
    const hasValidExplanation = isExplanationValid(review.anvilId, review.definedExplanation);

    const appNameErrMsg = useMemo((): string => {
        if (!applicationName) {
            return "Required";
        }
        if (!hasValidAppName) {
            return ERROR_MESSAGES.ALLOWED_SPECIAL_CHARS;
        }
        return "";
    }, [applicationName, hasValidAppName]);
    const customValidation: KeyValuePairs<boolean> = useMemo<KeyValuePairs<boolean>>((): KeyValuePairs<boolean> => {
        return {
            willingToProvideInfo: isWillingToProvideInfoValid(review.hasPersonalData, review.willingToProvideInfo),
            applicationName: hasValidAppName,
            contactList: hasValidContactList,
        };
    }, [review.hasPersonalData, review.willingToProvideInfo, hasValidAppName, hasValidContactList]);

    const isEveryFieldsValid = useCallback(
        (fields: string[]): boolean => {
            const targetFields = pick(flatApplication, fields);
            return Object.entries(targetFields).every(([key, value]: [string, any]): boolean => {
                return customValidation[key] ?? Boolean(value?.length);
            });
        },
        [customValidation, flatApplication]
    );

    const isSomeFieldsValid = useCallback(
        (fields: string[]): boolean => {
            return Object.entries(pick(flatApplication, fields)).some(([key, value]: [string, any]): boolean => {
                return customValidation[key] ?? Boolean(value?.length);
            });
        },
        [customValidation, flatApplication]
    );

    const isApplicationPermissionsSectionValid = useCallback((): boolean => {
        return isAnvilDeprecated ? isSomeFieldsValid(applicationPermissionsFields) : hasValidExplanation;
    }, [isAnvilDeprecated, isSomeFieldsValid, hasValidExplanation]);

    const applicationPermissionsSectionErrorMsg = useMemo((): string => {
        if (isAnvilDeprecated) {
            return isApplicationPermissionsSectionValid() ? "" : ERROR_MESSAGES.APPLICATION_PERMISSIONS_SECTION_MISSING;
        }
        return isApplicationPermissionsSectionValid() ? "" : ERROR_MESSAGES.ANVIL_ID_EXPLANATION_MISSING;
    }, [isAnvilDeprecated, isApplicationPermissionsSectionValid]);

    const isApplicationValid = useCallback((): boolean => {
        if (!isApplicationPermissionsSectionValid()) {
            return false;
        }
        return isEveryFieldsValid(appValidationFieldForSubmit);
    }, [isApplicationPermissionsSectionValid, isEveryFieldsValid]);

    const isDataStoresRequired = useCallback((): boolean => {
        return review.hasPersonalData === HasPersonalDataResponse.Store || review.willingToProvideInfo === "Yes";
    }, [review.hasPersonalData, review.willingToProvideInfo]);

    const isAppValidForSave = useCallback((): ValidationStatus => {
        const isValid = isEveryFieldsValid(appValidationFieldForDraft);
        return {
            isValid: isValid,
            errorMessage: isValid ? "" : ERROR_MESSAGES.MANDATORY_MISSING,
        };
    }, [isEveryFieldsValid]);

    const isAppValidForSubmit = useCallback((): ValidationStatus => {
        const isReviewAnswersValid = reviewAnswerState.isValidSubmit;
        const dataStoresLength = review.dataStores.length;
        const areDataStoresValidForSubmit =
            dataStoreAnswerState?.every((dataStore): boolean => dataStore.isValidSubmit) ?? false;
        // if any data stores exist then we validate that they are properly filled out.
        // if no data stores exist then we validate whether they should exist or not.
        const isDataStoresValid =
            dataStoresLength > 0
                ? isDataStoresInfoStepValid(review.dataStores) && areDataStoresValidForSubmit
                : !isDataStoresRequired();
        const errorMessages: string[] = [];
        const areMandatoryQuestionsValid = isApplicationValid() && isReviewAnswersValid && isDataStoresValid;
        if (!areMandatoryQuestionsValid) {
            errorMessages.push(ERROR_MESSAGES.MANDATORY_MISSING);
        }
        return {
            isValid: areMandatoryQuestionsValid,
            errorMessage: errorMessages.join("<br />"),
        };
    }, [
        review.dataStores,
        dataStoreAnswerState,
        isApplicationValid,
        isDataStoresRequired,
        reviewAnswerState.isValidSubmit,
    ]);

    useEffect((): void => {
        const saveStatus = isAppValidForSave();
        setIsValidSave(saveStatus.isValid);
        setSaveErrorMessage(saveStatus.errorMessage);
        const submitStatus = isAppValidForSubmit();
        setIsValidSubmit(submitStatus.isValid);
        setSubmitErrorMessage(submitStatus.errorMessage);
    }, [isAppValidForSave, isAppValidForSubmit]);

    return {
        saveStatus: { isValid: isValidSave, errorMessage: saveErrorMessage },
        submitStatus: { isValid: isValidSubmit, errorMessage: submitErrorMessage },
        appNameErrMsg,
        applicationPermissionsSectionErrorMsg: applicationPermissionsSectionErrorMsg,
    };
};
