import { SYNTH_UNIVERSAL_COMPLIANCE_TYPE } from "src/components/TableDetails/constants";
import {
    FieldAnswer,
    FieldQuestion,
    FieldRecord,
    QuestionChoice,
    QuestionTag,
    QuestionType,
} from "src/services/KaleTablesService";

export enum SyntheticFieldIds {
    FieldName = "Field_Name_Synthetic_Id",
    FieldType = "Field_Type_Synthetic_Id",
    FieldDescription = "Field_Description_Synthetic_Id",
    FieldExampleValuesContentType = "Field_Example_Values_Content_Type_Synthetic_Id",
    FieldExampleValues = "Field_Example_Values_Synthetic_Id",
    FieldDerUuid = "Field_DerUuid_Synthetic_Id",
}

// Using this to ensure an exhaustive switch statement in our reduce operation.
const SYNTHETIC_FIELD_ID_RECORD: Record<SyntheticFieldIds, boolean> = {
    [SyntheticFieldIds.FieldName]: true,
    [SyntheticFieldIds.FieldType]: true,
    [SyntheticFieldIds.FieldDescription]: true,
    [SyntheticFieldIds.FieldExampleValuesContentType]: true,
    [SyntheticFieldIds.FieldExampleValues]: true,
    [SyntheticFieldIds.FieldDerUuid]: true,
};

export enum ExampleValuesContentType {
    JSON = "JSON",
    EnumList = "Enum List",
    FreeForm = "Free-form Text",
}

type SyntheticFieldRecord = Pick<
    FieldRecord,
    "answers" | "name" | "type" | "description" | "sampleValuesContentType" | "sampleValues" | "derUuid"
>;
const reduceFieldAnswers = (fieldAnswers: FieldAnswer[]): SyntheticFieldRecord => {
    // Reduce a list of FieldAnswers into a SyntheticFieldRecord.

    const initialState: SyntheticFieldRecord = {
        answers: [],
        name: "",
        type: "",
        description: "",
        sampleValues: "",
        derUuid: "",
    };

    return fieldAnswers.reduce<SyntheticFieldRecord>((fieldRecord, answer): SyntheticFieldRecord => {
        // Extract any Synthetic Field answers contained in the fieldAnswers argument and map their value to the
        // corresponding property name on the resultant field record.
        // Place every organic FieldAnswer into the field record "answers" list.
        const { questionShortId: shortId } = answer;

        const isSyntheticId = Boolean(SYNTHETIC_FIELD_ID_RECORD[shortId as SyntheticFieldIds]);
        if (isSyntheticId) {
            switch (shortId) {
                case SyntheticFieldIds.FieldName: {
                    fieldRecord.name = answer.textContent ?? "";
                    break;
                }
                case SyntheticFieldIds.FieldType: {
                    fieldRecord.type = answer.textContent ?? "";
                    break;
                }
                case SyntheticFieldIds.FieldDescription: {
                    fieldRecord.description = answer.textContent ?? "";
                    break;
                }
                case SyntheticFieldIds.FieldExampleValuesContentType: {
                    fieldRecord.sampleValuesContentType = answer.textContent ?? "";
                    break;
                }
                case SyntheticFieldIds.FieldExampleValues: {
                    fieldRecord.sampleValues = answer.textContent ?? "";
                    break;
                }
                case SyntheticFieldIds.FieldDerUuid: {
                    fieldRecord.derUuid = answer.textContent ?? "";
                    break;
                }
                default: {
                    throw Error(`Missing case for Synthetic Field Id: ${shortId}`);
                }
            }
        } else {
            // Not a Synthetic FieldAnswer. Append to the list of Organic FieldAnswers
            fieldRecord.answers.push(answer);
        }

        return fieldRecord;
    }, initialState);
};

const VESTIGIAL_ID = -1; // Assigned to required numeric ids that Synthetic questions don't care about.

// Currently we want to provide a choice to the end user that allows them to attest that the field does not contain
// personal data. However, it's convenient for our backend to not to distinguish, this choice from no reply at all.
// Therefore we provide an option with a specific user-friendly-label, but whose option value is empty string.
const DER_UUID_NOT_PERSONAL_DATA: QuestionChoice = {
    value: "",
    label: "Not Personal Data Identifier",
    colors: { color: "blue" },
};

type SyntheticFieldQuestion = Omit<FieldQuestion, "parentId"> & { parentShortId?: string };
export const makeSyntheticFieldQuestions = (derUuidQuestionChoices: QuestionChoice[]): SyntheticFieldQuestion[] => {
    const syntheticFieldQuestions: Record<SyntheticFieldIds, SyntheticFieldQuestion> = {
        [SyntheticFieldIds.FieldName]: {
            shortId: SyntheticFieldIds.FieldName,
            type: QuestionType.text,
            content: "Field Name",
            title: "",
            subtext: "",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [],
            tags: [QuestionTag.requiredForDraft],
        },
        [SyntheticFieldIds.FieldType]: {
            shortId: SyntheticFieldIds.FieldType,
            type: QuestionType.text,
            content: "Field Type",
            title: "",
            subtext: "",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [],
            tags: [],
        },
        [SyntheticFieldIds.FieldDescription]: {
            shortId: SyntheticFieldIds.FieldDescription,
            type: QuestionType.textArea,
            content: "Field Description",
            title: "",
            subtext:
                "Please provide a detailed description of the field to help reviewers understand its business " +
                "context and purpose.",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [],
            tags: [],
        },
        [SyntheticFieldIds.FieldExampleValuesContentType]: {
            shortId: SyntheticFieldIds.FieldExampleValuesContentType,
            type: QuestionType.singleSelect,
            content: "Possible Values Content Type",
            title: "",
            subtext: "",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [
                { value: ExampleValuesContentType.FreeForm },
                { value: ExampleValuesContentType.EnumList },
                { value: ExampleValuesContentType.JSON },
            ],
            tags: [QuestionTag.selectIsNotClearable],
        },
        [SyntheticFieldIds.FieldExampleValues]: {
            shortId: SyntheticFieldIds.FieldExampleValues,
            type: QuestionType.textArea,
            content: "Possible Values",
            title: "",
            subtext: "",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [],
            tags: [],
        },
        [SyntheticFieldIds.FieldDerUuid]: {
            shortId: SyntheticFieldIds.FieldDerUuid,
            type: QuestionType.singleSelect,
            content: "Mapping to Identifier in DER",
            title: "",
            subtext: "<a href='https://der.data-governance.privacy.a2z.com/'>Data Element Registry</a>.",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [DER_UUID_NOT_PERSONAL_DATA, ...derUuidQuestionChoices],
            tags: [QuestionTag.selectIsNotClearable],
        },
    };

    return Object.values(syntheticFieldQuestions);
};

type SyntheticFieldAnswer = Omit<FieldAnswer, "fieldAnswerId">;
const makeSyntheticFieldAnswers = ({
    id: fieldId,
    name: fieldNameValue,
    type: fieldTypeValue,
    description: fieldDescriptionValue,
    sampleValuesContentType: fieldSampleValuesContentType,
    sampleValues: fieldSampleValues,
    derUuid: fieldDerUuidValue,
}: FieldRecord): SyntheticFieldAnswer[] => {
    const sharedConfig: Pick<SyntheticFieldAnswer, "fieldId" | "questionId" | "complianceType"> = {
        fieldId,
        questionId: VESTIGIAL_ID,
        complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
    };

    const syntheticFieldAnswers: Record<SyntheticFieldIds, SyntheticFieldAnswer> = {
        [SyntheticFieldIds.FieldName]: {
            ...{ textContent: fieldNameValue, questionShortId: SyntheticFieldIds.FieldName },
            ...sharedConfig,
        },
        [SyntheticFieldIds.FieldType]: {
            ...{ textContent: fieldTypeValue, questionShortId: SyntheticFieldIds.FieldType },
            ...sharedConfig,
        },
        [SyntheticFieldIds.FieldDescription]: {
            ...{
                textContent: fieldDescriptionValue,
                questionShortId: SyntheticFieldIds.FieldDescription,
            },
            ...sharedConfig,
        },
        [SyntheticFieldIds.FieldExampleValuesContentType]: {
            ...{
                // use FreeForm as the default value if the question hasn't been answered yet.
                textContent: fieldSampleValuesContentType || ExampleValuesContentType.FreeForm,
                questionShortId: SyntheticFieldIds.FieldExampleValuesContentType,
            },
            ...sharedConfig,
        },
        [SyntheticFieldIds.FieldExampleValues]: {
            ...{
                textContent: fieldSampleValues,
                questionShortId: SyntheticFieldIds.FieldExampleValues,
            },
            ...sharedConfig,
        },
        [SyntheticFieldIds.FieldDerUuid]: {
            ...{
                // Use not-personal-data as the default option if the question hasn't been answered yet
                textContent: fieldDerUuidValue || DER_UUID_NOT_PERSONAL_DATA.value,
                questionShortId: SyntheticFieldIds.FieldDerUuid,
            },
            ...sharedConfig,
        },
    };

    return Object.values(syntheticFieldAnswers);
};

interface SyntheticFieldQuestionsAndAnswers {
    syntheticFieldQuestions: FieldQuestion[];
    syntheticFieldAnswers: FieldAnswer[];
}
const makeSyntheticFieldQuestionsAndAnswers = (
    field: FieldRecord | undefined,
    derUuidQuestionChoices: QuestionChoice[]
): SyntheticFieldQuestionsAndAnswers => {
    // Convert specific FieldRecord properties into Synthetic FieldQuestions and FieldAnswers
    let syntheticFieldAnswers: SyntheticFieldAnswer[] = [];
    let syntheticFieldQuestions: SyntheticFieldQuestion[] = [];
    if (field) {
        syntheticFieldQuestions = makeSyntheticFieldQuestions(derUuidQuestionChoices);
        syntheticFieldAnswers = makeSyntheticFieldAnswers(field);
    }
    return { syntheticFieldQuestions, syntheticFieldAnswers };
};

export type { SyntheticFieldRecord, SyntheticFieldQuestionsAndAnswers };
export { reduceFieldAnswers, makeSyntheticFieldQuestionsAndAnswers };
