import { KaleApplicationEvent } from "src/services/RumService/rum-events/base-events";
import { selectApprovalStatuses, useSurveyContext } from "src/components/survey/SurveyFormModel";
import { useKaleContext } from "src/components/KaleContext";

/** Custom hook is responsible for assembling the KaleApplicationEventMetadata object that we want to include in any
 * custom Rum events that we log from within a specific Kale Application. Currently, the implementation relies on
 * compiling data from 2 different React Contexts that are only properly set on the LegalSurvey and TAF pages.
 */
export const useKaleApplicationEventMetadata = (): KaleApplicationEvent["metadata"] => {
    const {
        user: { userId },
    } = useKaleContext();

    const {
        role,
        application: { appInfo },
    } = useSurveyContext();

    const { accountingStatus, legalStatus, fraudStatus } = selectApprovalStatuses(appInfo);

    const { applicationName, review } = appInfo;

    return {
        accountingStatus: accountingStatus.status,
        fraudStatus: fraudStatus.status,
        legalStatus: legalStatus.status,
        applicationName,
        userRole: role,
        userId,
        reviewId: review.id,
    };
};
