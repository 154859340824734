import {
    ExampleValuesContentType,
    SyntheticFieldIds,
} from "src/components/TableDetails/TableDetailsPage/synthetic-questions/synthetic-field-questions";
import { Box } from "@amzn/awsui-components-react-v3";
import { Alert } from "@amzn/awsui-components-react-v3";
import React from "react";
import { TableFieldHookAnswer } from "src/answers_legacy";

export interface SiblingQuestionAnswer {
    question: Pick<TableFieldHookAnswer["question"], "shortId">;
    value: TableFieldHookAnswer["value"];
}
/**
 * Helper method to search through a list of all sibling questions of the ExampleValues question and extract the answer
 * to the sibling question Example Values Content Type
 * @param siblingQuestionAnswers the list of questions to search through.
 * @return the answer value of the Example Values Content Type question
 */
export const lookupExampleValuesContentTypeAnswer = (siblingQuestionAnswers: SiblingQuestionAnswer[]): string => {
    const exampleValuesContentTypeAnswer: SiblingQuestionAnswer | undefined = siblingQuestionAnswers.find(
        ({ question }: SiblingQuestionAnswer): boolean => {
            return question.shortId === SyntheticFieldIds.FieldExampleValuesContentType;
        }
    );
    if (!exampleValuesContentTypeAnswer) {
        throw Error("Cannot find sibling `ExampleValuesContentType` question among siblings");
    }

    return exampleValuesContentTypeAnswer.value as string;
};

export const TEST_IDS = {
    ENUM_LIST_ITEM: "examples-values-formatted-enum-list-item",
    FORMATTED_JSON: "examples-values-formatted-enum-list-item",
};

export interface FormattedExampleValues {
    formattedValues: React.ReactNode;
    formatError: React.ReactNode;
}
/**
 *  Helper method for the Example Values synthetic question to parse and format the content of the
 *  exampleValues param based on the chosen content type.
 * @param exampleValues the answer value of the Example Values synthetic question
 * @param contentType the answer value of the Example Values Content Type synthetic question
 * @return FormattedExampleValues
 */
export const formatExampleValuesAsContentType = (
    exampleValues: string,
    contentType: string
): FormattedExampleValues => {
    let formattedValues: React.ReactNode = null;
    let formatError: React.ReactNode = null;
    switch (contentType) {
        case ExampleValuesContentType.FreeForm: {
            formattedValues = exampleValues;
            break;
        }
        case ExampleValuesContentType.EnumList: {
            const listItems = exampleValues
                .split("\n")
                .map((item): string => item.trim())
                .filter((item): boolean => Boolean(item))
                .map(
                    (item: string, i): JSX.Element => (
                        <li key={i + item} data-testid={TEST_IDS.ENUM_LIST_ITEM}>
                            {item}
                        </li>
                    )
                );
            formattedValues = <ol>{listItems}</ol>;
            break;
        }
        case ExampleValuesContentType.JSON: {
            if (!exampleValues) {
                formattedValues = exampleValues;
            } else {
                // Try to parse as JSON
                try {
                    const JSONString = JSON.stringify(JSON.parse(exampleValues), null, 2);
                    formattedValues = (
                        <Box variant="pre" data-testid={TEST_IDS.FORMATTED_JSON}>
                            {JSONString}
                        </Box>
                    );
                } catch (e) {
                    formatError = (
                        <React.Fragment>
                            <Alert type={"error"} header={`Invalid ${ExampleValuesContentType.JSON} Content`}>
                                {(e as Error).message}
                            </Alert>
                        </React.Fragment>
                    );
                }
            }

            break;
        }
    }

    return { formattedValues, formatError };
};
