import { Answer, ContentKey, Question, mutateAnswerContentKey, RetentionJsonContentType } from "src/answers_legacy";
import { deepCopy } from "src/util/KaleUtil";
import { isJsonContentEmpty } from "src/answers_legacy/reducers/helpers";

/**
 * Handles server-side constraints by ensuring content type is non-empty.
 * @param answer
 */
export const meetsServerAnswerConstraint = (answer: Answer): boolean => {
    return Object.keys(answer).some((k): boolean => {
        const anyContent: [
            Answer[ContentKey.textContent] | null,
            Answer[ContentKey.arrayContent] | null,
            Answer[ContentKey.dateContent] | null,
            Answer[ContentKey.jsonContent] | null
        ] = [
            answer[ContentKey.textContent],
            answer[ContentKey.arrayContent],
            answer[ContentKey.dateContent],
            answer[ContentKey.jsonContent],
        ];
        return (
            // Answer key is a content key and...
            Object.keys(ContentKey).includes(k) &&
            // none of the possible content keys is empty
            !anyContent.some(
                (c): boolean =>
                    c === null ||
                    c === "" ||
                    (c as unknown as string[])?.length === 0 ||
                    (!!c && isJsonContentEmpty(c as unknown as RetentionJsonContentType))
            )
        );
    });
};

const sanitizeAnswerContent = (answer: Answer, questions: Question[]): Answer => {
    const question = questions.find(
        (question): boolean => answer.questionId === question.id || answer.questionShortId === question.shortId
    );

    if (question) {
        answer = mutateAnswerContentKey(answer, question);
    }

    return answer;
};

const insertClearedAnswers = (answers: MergeAnswer[], questions: Question[]): void => {
    questions.forEach((question): void => {
        if (
            !answers.find(
                (answer): boolean => answer.questionId === question.id || answer.questionShortId === question.shortId
            )
        ) {
            answers.push({ _cleared: true, questionShortId: question.shortId, questionId: question.id });
        }
    });
};

// For flagging cleared answers, so that merging can remove inapplicable answers from the right
interface MergeAnswer extends Answer {
    _cleared?: boolean;
}

// Merge two lists of answer object. When both lists have an answer object
// for the same question this will keep the answer object from the right list.
export const mergeAnswers = (
    leftAnswers: MergeAnswer[],
    rightAnswers: MergeAnswer[],
    leftQuestions: Question[],
    rightQuestions: Question[]
): Answer[] => {
    let result: Answer[] = [];

    // Copy into state to avoid mutating original answers
    const leftState = deepCopy(leftAnswers);
    const rightState = deepCopy(rightAnswers);

    leftState.forEach((answer): MergeAnswer => sanitizeAnswerContent(answer, leftQuestions));
    rightState.forEach((answer): MergeAnswer => sanitizeAnswerContent(answer, rightQuestions));

    insertClearedAnswers(leftState, leftQuestions);
    insertClearedAnswers(rightState, rightQuestions);

    leftState.forEach((leftAnswer): void => {
        const overlappingAnswer = rightState.find(
            (rightAnswer): boolean =>
                rightAnswer.questionShortId === leftAnswer.questionShortId ||
                rightAnswer.questionId === leftAnswer.questionId
        );

        result.push(overlappingAnswer || leftAnswer);
    });

    result = [...result, ...rightState.filter((rightAnswer): boolean => !result.includes(rightAnswer))];

    result = result.filter(
        (answer: MergeAnswer): boolean => !answer._cleared && meetsServerAnswerConstraint(answer)
    ) as Answer[];

    return result;
};
