import { tidSelector } from "../util/selectors";
import { TEST_IDS } from "./test-ids";

const { TABLE_DETAILS_PAGE } = TEST_IDS;
const { TABLE_DETAILS_FORM } = TABLE_DETAILS_PAGE;
const {
    TABLE_QUESTIONS_PANEL: { ANDES_CRADLE_METADATA },
} = TABLE_DETAILS_FORM;

export const SELECTORS = {
    TABLE_DETAILS_PAGE: {
        ROOT: tidSelector(TABLE_DETAILS_PAGE.ROOT),
        LOADING_SPINNER: tidSelector(TABLE_DETAILS_PAGE.LOADING_SPINNER),
        LOADED_CONTENT: tidSelector(TABLE_DETAILS_PAGE.LOADED_CONTENT),
        APP_LAYOUT: tidSelector(TABLE_DETAILS_PAGE.APP_LAYOUT),
        TABLE_DETAILS_FORM: {
            ROOT: tidSelector(TABLE_DETAILS_FORM.ROOT),
            FORM_TITLE: tidSelector(TABLE_DETAILS_FORM.FORM_TITLE),
            SAVE_BUTTON: tidSelector(TABLE_DETAILS_FORM.SAVE_BUTTON),
            CANCEL_BUTTON: tidSelector(TABLE_DETAILS_FORM.CANCEL_BUTTON),
            TABLE_QUESTIONS_PANEL: {
                ANDES_CRADLE_METADATA: {
                    LOADING_SPINNER: tidSelector(ANDES_CRADLE_METADATA.LOADING_SPINNER),
                    ERROR_MESSAGE: tidSelector(ANDES_CRADLE_METADATA.ERROR_MESSAGE),
                    LOADED_CONTENT: tidSelector(ANDES_CRADLE_METADATA.LOADED_CONTENT),
                },
            },
        },
    },
};
