import { Input } from "@amzn/awsui-components-react-v3/polaris";
import React, { useContext, useEffect, useState } from "react";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/index";
import { AnswerBase, HookAnswer, QuestionBase, QuestionTag } from "src/answers_legacy";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";

type TextQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
} & FormQuestionForwardingProps;

export const TextQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    props: TextQuestionProps<QType, AType>
): JSX.Element => {
    const {
        "data-testid": dataTestId,
        questionAnswer: {
            question: { tags },
        },
    } = props;

    const value = props.questionAnswer.value as string;
    const setValue = props.questionAnswer.setValue as (newValue: string) => void;
    const isReadonly =
        useContext(TableDetailsPageContext).isFormReadonly || tags.includes(QuestionTag.disabledForAndes);
    const [localValue, setLocalValue] = useState<string>(value);

    useEffect((): void => {
        // Always overwrite local state when prop changes.
        setLocalValue(value);
    }, [value]);

    // Throttle how often we update global state by only calling the setter from the onBlur handler
    return (
        <Input
            data-testid={dataTestId}
            disabled={isReadonly}
            value={localValue}
            onChange={(e): void => setLocalValue(e.detail.value)}
            onBlur={(): void => {
                if (value !== localValue) {
                    // Only trigger an expensive global state update
                    // if the user changed the value
                    setValue(localValue);
                }
            }}
        />
    );
};
