import { TEST_IDS } from "./test-ids";
import { attrSelector, compoundSelector, descendantSelector, tidSelector } from "../../util/selectors";

const { WIZARD } = TEST_IDS;
const { PDC_STEP, PDC_WARNING_MODAL, SUMMARY_STEP } = WIZARD;
const { PDC_TABLE } = PDC_STEP;

export const SELECTORS = {
    WIZARD: {
        ROOT: tidSelector(WIZARD.ROOT),
        NEXT_BUTTON: tidSelector(WIZARD.NEXT_BUTTON),
        CONFIRM_AND_SUBMIT_BUTTON: tidSelector(WIZARD.CONFIRM_AND_SUBMIT_BUTTON),
        LINEAGE_STEP: {
            LINEAGE_TABLE: tidSelector(WIZARD.LINEAGE_STEP.LINEAGE_TABLE),
        },
        PDC_STEP: {
            CONTENT_ROOT: tidSelector(PDC_STEP.CONTENT_ROOT),
            PDC_TABLE: {
                ROOT: tidSelector(PDC_TABLE.ROOT),
                ENTITY_TYPE: (function IIFE() {
                    const ROOT = tidSelector(PDC_TABLE.ENTITY_TYPE.ROOT);
                    const entityTypeByDERName = (derName: string) =>
                        compoundSelector(ROOT, attrSelector("data-entity-dername", derName));
                    return {
                        ROOT,
                        LABEL: tidSelector(PDC_TABLE.ENTITY_TYPE.LABEL),
                        entityTypeByDERName,
                    };
                })(),
            },
        },
        PERSONAL_DATA_STEP: {
            CONTENT_ROOT: tidSelector(WIZARD.PERSONAL_DATA_STEP.CONTENT_ROOT),
            STATEMENT_FORM_FIELD: tidSelector(WIZARD.PERSONAL_DATA_STEP.STATEMENT_FORM_FIELD),
        },
        SUMMARY_STEP: {
            RESULT: tidSelector(SUMMARY_STEP.RESULT),
            PERSONAL_DATA_QUESTION_EDIT_BUTTON: tidSelector(SUMMARY_STEP.PERSONAL_DATA_QUESTION_EDIT_BUTTON),
            ANALYZER_SUMMARY: (function IIFE() {
                const { ANALYZER_SUMMARIES } = SUMMARY_STEP;
                const FINDINGS_COUNT = tidSelector(ANALYZER_SUMMARIES.FINDINGS_COUNT);
                const PDC_FINDINGS = tidSelector(ANALYZER_SUMMARIES.PDC_FINDINGS);
                const LINEAGE_FINDINGS = tidSelector(ANALYZER_SUMMARIES.LINEAGE_FINDINGS);

                return {
                    PDC_FINDINGS_COUNT: descendantSelector(PDC_FINDINGS, FINDINGS_COUNT),
                    LINEAGE_FINDINGS_COUNT: descendantSelector(LINEAGE_FINDINGS, FINDINGS_COUNT),
                };
            })(),
        },
        PDC_WARNING_MODAL: {
            ROOT: tidSelector(PDC_WARNING_MODAL.ROOT),
            CONTINUE_BUTTON: tidSelector(PDC_WARNING_MODAL.CONTINUE_BUTTON),
            GO_BACK_BUTTON_BUTTON: tidSelector(PDC_WARNING_MODAL.GO_BACK_BUTTON),
        },
    },
};
