import { ParentConstraintBase, QuestionBase, ReviewAnswer } from "src/answers_legacy";

export const isSensitiveData = (answers: ReviewAnswer[], question: QuestionBase & ParentConstraintBase): boolean => {
    if (question.shortId !== "has_sensitive_personal_data" || !Boolean(answers?.length)) {
        return false;
    }

    const isGlobalMarketplace = answers.some((answer): boolean => {
        return answer.questionShortId === "has_specific_markets" && answer.textContent === "No";
    });
    const isIndiaMarketplace = answers.some((answer): boolean => {
        return (answer.questionShortId === "specific_markets" && answer.arrayContent?.includes("IN")) ?? false;
    });
    const isStoredInIndia = answers.some((answer): boolean => {
        return (answer.questionShortId === "data_locations" && answer.arrayContent?.includes("BOM")) ?? false;
    });
    const isProcessedInIndia = answers.some((answer): boolean => {
        return (answer.questionShortId === "other_data_locations" && answer.arrayContent?.includes("BOM")) ?? false;
    });

    return isGlobalMarketplace || isIndiaMarketplace || isStoredInIndia || isProcessedInIndia;
};
