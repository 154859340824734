import React, { useState } from "react";
import { TableAttribute, TableDefinition } from "src/components/schema_table/SchemaTable";
import { Box, ExpandableSection, Header, SpaceBetween, Spinner, Table } from "@amzn/awsui-components-react-v3";
import TwoStageRender from "src/components/decorators/TwoStageRender";
import { KeyValuePairs } from "src/components/survey/LegalSurveyPage";

interface Props {
    tableDefinitions: TableDefinition[];
}

export const TEST_IDS = {
    SCHEMA_TABLE: "schema-importer-preview-table",
};

/**
 * Schema preview now displays expandable section with source and name as title
 * Table is loaded only when user expands this section which is made possible by <TwoStageRender>
 */
const SchemaPreview = (props: Props): JSX.Element => {
    const { tableDefinitions } = props;
    const [expandedMap, setExpandedMap] = useState<KeyValuePairs<boolean>>({});

    return (
        <React.Fragment>
            {tableDefinitions.map((td, i): JSX.Element => {
                const key = `${td.name}_${i}`;
                const expanded = Boolean(expandedMap[key]);
                return (
                    <ExpandableSection
                        key={key}
                        variant="container"
                        expanded={expanded}
                        onChange={(): void => {
                            setExpandedMap({ ...expandedMap, [key]: !expanded });
                        }}
                        header={
                            <SpaceBetween size={"xs"} direction={"horizontal"}>
                                {td.source && (
                                    <Header
                                        variant="h2"
                                        counter={td.name && `(Table Name: ${td.name})`}
                                    >{`Schema Preview from ${td.source}`}</Header>
                                )}
                            </SpaceBetween>
                        }
                    >
                        {expanded && (
                            <TwoStageRender
                                renderInProgress={(): React.ReactNode => (
                                    <Box variant={"div"} textAlign={"center"}>
                                        <Spinner size={"big"} />
                                    </Box>
                                )}
                                renderComplete={(): React.ReactNode => (
                                    <Table
                                        data-testid={TEST_IDS.SCHEMA_TABLE}
                                        columnDefinitions={[
                                            {
                                                id: "name",
                                                header: "Name",
                                                width: 270,
                                                cell: (item: TableAttribute): JSX.Element => (
                                                    <span title={item.name}>{item.name}</span>
                                                ),
                                            },
                                            {
                                                id: "type",
                                                header: "Type",
                                                width: 170,
                                                cell: (item: TableAttribute): JSX.Element => (
                                                    <span title={item.type}>{item.type}</span>
                                                ),
                                            },
                                            {
                                                id: "samples",
                                                header: "Sample Values",
                                                minWidth: "100px",
                                                cell: (item: TableAttribute): JSX.Element => (
                                                    <span>{(item.samples || []).join(", ")}</span>
                                                ),
                                            },
                                        ]}
                                        items={td.schema}
                                        empty={
                                            <Box textAlign="center">
                                                <Box margin={{ bottom: "xxs" }} padding={{ top: "xs" }}>
                                                    <b>No Schema</b>
                                                </Box>
                                                <Box variant="p" margin={{ bottom: "xs" }}>
                                                    No schema types to display.
                                                </Box>
                                            </Box>
                                        }
                                    />
                                )}
                            />
                        )}
                    </ExpandableSection>
                );
            })}
        </React.Fragment>
    );
};

export default SchemaPreview;
