import { IonType, makeReader, Reader } from "ion-js";

// the number of lines for the text area for entering the schema. This is a reasonable default.
export const DEFAULT_LINES = 10;

export interface Column {
    name: string;
    type: string;
    samples?: string[];
}

interface DetailedColumn extends Column {
    schemaName: string;
    tableName: string;
}

interface InternalSchema {
    name: string;
    source: string;
    schema: Column[];
}

// TO DO: add example command?
export abstract class Importer {
    protected numLines: number;

    public constructor() {
        this.numLines = DEFAULT_LINES;
    }

    abstract import(input: string): InternalSchema[];

    abstract getSource(): string;

    public getNumLines(): number {
        return this.numLines;
    }

    abstract getSamples(): string[];

    public hasDocLink(): boolean {
        return false;
    }

    public getDocLink(): string {
        return "#";
    }

    public getCommand(): string {
        return "";
    }
}

function isValid(internalSchema: InternalSchema): boolean {
    return (
        internalSchema.name !== undefined &&
        internalSchema.name !== null && // string must exist but may be empty
        // (e.g. not in the schema and must be specified)
        Boolean(internalSchema.source) &&
        internalSchema.schema.length > 0 &&
        internalSchema.schema.every((col): boolean => Boolean(col.name && col.type))
    );
}

interface UnvalidatedJsonSchema {
    [key: string]: object;
}

interface GroupedBy<T> {
    [key: string]: T[];
}

function groupBy<T>(arr: T[], groupingFn: (_: T) => string): GroupedBy<T> {
    return arr.reduce((rv: GroupedBy<T>, x: T): GroupedBy<T> => {
        (rv[groupingFn(x)] = rv[groupingFn(x)] || []).push(x);
        return rv;
    }, {});
}

abstract class TabularMultiSchemaImporter extends Importer {
    abstract getSamples(): string[];

    abstract getSource(): string;

    abstract getSchemaKey(): string;

    abstract getColumnKey(): string;

    abstract getTypeKey(): string;

    abstract getTableNameKey(): string;

    protected getSplitRegEx(): RegExp {
        return / +/;
    }

    private rowToObject(fieldNames: string[], row: string): Map<string, string> {
        const rowObj = new Map<string, string>();
        row.trim()
            .split(this.getSplitRegEx())
            .filter((s): boolean => Boolean(s.length))
            .forEach((x, idx): void => {
                const key = fieldNames[idx].trim();
                rowObj.set(key, x.trim());
            });
        return rowObj;
    }

    protected getColumnLine(): number {
        return 0;
    }

    public import(input: string): InternalSchema[] {
        const allLines = input.split("\n");
        this.numLines = allLines.length;
        const fieldNames = allLines[this.getColumnLine()]
            .trim()
            .split(this.getSplitRegEx())
            .filter((s): boolean => Boolean(s.length));
        const rows = allLines.splice(2 + this.getColumnLine(), allLines.length);

        const tableNameKey = this.getTableNameKey();
        const columnKey = this.getColumnKey();
        const typeKey = this.getTypeKey();
        const schemaKey = this.getSchemaKey();
        const rowObjects: DetailedColumn[] = [];
        rows.forEach((row): void => {
            const rowMap: Map<string, string> = this.rowToObject(fieldNames, row);
            if (rowMap.has(columnKey) && rowMap.has(typeKey)) {
                const detailedColumn: DetailedColumn = {
                    name: rowMap.get(columnKey) || "",
                    type: rowMap.get(typeKey) || "",
                    tableName: rowMap.get(tableNameKey) || "",
                    schemaName: rowMap.get(schemaKey) || "",
                };
                rowObjects.push(detailedColumn);
            }
        });

        const tables = groupBy(rowObjects, (c: DetailedColumn): string => c.schemaName.concat(".", c.tableName));
        return Object.entries(tables).map(([tableName, columns]): InternalSchema => {
            return {
                name: tableName,
                source: this.getSource(),
                schema: columns as Column[],
            };
        });
    }

    public getNumLines(): number {
        return this.numLines;
    }
}
interface InternalIonObject {
    type: string;
    samples?: string[];
    codepoint_length?: number;
}

const importers = [
    /*
   Importer for the internal format used by the ManualImporter.
 */
    class InternalJsonImporter extends Importer {
        public getSamples(): string[] {
            return [
                `{
    "name": "Test",
    "schema": [
        {
            "name": "foo",
            "type": "string",
            "samples": ["1"]
        },
        {
            "name": "bar",
            "type": "int",
            "samples": ["33"]
        }
    ]
}`,
            ];
        }

        public getSource(): string {
            return "Internal JSON Format";
        }

        public import(input: string): InternalSchema[] {
            const json = JSON.parse(input);
            // need to parse and pretty print before knowing number of lines
            this.numLines = JSON.stringify(json, null, 2).split("\n").length;
            json["source"] = this.getSource();
            InternalJsonImporter.validateInternalSchema(json);
            return [json];
        }

        private static validateInternalSchema(tableSchema: UnvalidatedJsonSchema): void {
            if (!Object.hasOwnProperty.call(tableSchema, "name")) {
                throw new Error("Missing Table schema name");
            }
            if (!Object.hasOwnProperty.call(tableSchema, "schema")) {
                throw new Error("Fields for table are missing!");
            }

            for (const fields of Object.values(tableSchema["schema"])) {
                const obj = fields as Record<string, any>;
                if (!(Object.hasOwnProperty.call(obj, "name") && Object.hasOwnProperty.call(obj, "type"))) {
                    throw new Error("Each field entry must have a name and type");
                }
            }
        }
    },

    /*
   EDX's native format, ION: https://amzn.github.io/ion-schema/docs/spec.html
 */
    class EDXIonSchemaImporter extends Importer {
        public getSamples(): string[] {
            return [
                `type::{
  name: Address,
  type: struct,
  annotations: ordered::[one, two, three],
  fields: {
    address1: { type: short_string, occurs: required },
    address2: { type: short_string },
    city: { type: string, occurs: required, codepoint_length: range::[min, 20] },
    state: { type: State, occurs: required },
    zipcode: { type: int, valid_values: range::[0, 99999], occurs: required },
  },
}

type::{           // enum
  name: State,
  valid_values: [
    AK, AL, AR, AZ, CA, CO, CT, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY,
    LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NM, NV, NY,
    OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV, WY
  ],
}

type::{
  name: Customer,
  type: struct,
  annotations: [corporate, gold_class, club_member],
  fields: {
    firstName: { type: string, occurs: required },
    middleName: nullable::string,
    lastName: { type: string, occurs: required },
    customerId: {
      type: {
        one_of: [
          { type: string, codepoint_length: 18 },
          { type: int, valid_values: range::[100000, 999999] },
        ],
      },
      occurs: required,
    },
    addresses: {
      type: list,
      element: Address,
      occurs: required,
      container_length: range::[1, 7],
    },
    last_updated: {
      type: timestamp,
      timestamp_precision: range::[second, millisecond],
      occurs: required,
    },
  },
}`,
                `type::{
  name: Person,
  type: struct,
  fields: {
    title: {
      type: symbol,
      valid_values: [Mr, Mrs, Miss, Ms, Mx, Dr],
    },
    firstName: { type: string, occurs: required },
    middleName: string,
    lastName: { type: string, occurs: required },
    age: { type: int, valid_values: range::[0, 130] },
  },
}`,
            ];
        }

        public getSource(): string {
            return "EDX/ION Schema";
        }

        private parseDataType(reader: Reader, item: IonType | null): InternalIonObject {
            const itemObj: InternalIonObject = { type: "" };
            while (item) {
                const fieldName = reader.fieldName();
                const type = reader.type();
                if (fieldName === "type") {
                    if (type && type.isScalar) {
                        itemObj.type = String(reader.value());
                    }
                }

                if (fieldName === "valid_values") {
                    reader.stepIn();
                    if (reader.annotations().length == 0) {
                        itemObj.type = "ENUM";
                    }
                    try {
                        item = reader.next();
                        while (item) {
                            itemObj.samples = itemObj.samples || [];
                            itemObj.samples.push(String(reader.value()));
                            item = reader.next();
                        }
                        return itemObj;
                    } finally {
                        reader.stepOut();
                    }
                }
                if (type && !type.isScalar && item.isContainer) {
                    reader.stepIn();
                    try {
                        reader.next();
                        if (reader.fieldName() === "one_of") {
                            // implies a choice, therefore a union
                            itemObj.type = "UNION";
                            reader.stepIn();
                            try {
                                item = reader.next();
                                if (item && item.isContainer) {
                                    const options: InternalIonObject[] = [];

                                    while (item) {
                                        reader.stepIn();
                                        try {
                                            item = reader.next();
                                            const obj = this.parseDataType(reader, item);
                                            options.push(obj);
                                        } finally {
                                            reader.stepOut();
                                        }
                                        item = reader.next();
                                    }
                                    itemObj.type = options.map((x): string => x.type).join(", ");
                                    let samples: string[] = [];
                                    // Array.flatMap() is still draft, so flatten the map out
                                    options.forEach((option): void => {
                                        const optionSamples: string[] = option.samples || [];
                                        samples = samples.concat(optionSamples);
                                    });

                                    itemObj.samples = samples;
                                    return itemObj;
                                }
                            } finally {
                                reader.stepOut();
                            }
                        }
                        return itemObj;
                    } finally {
                        reader.stepOut();
                    }
                } else if (!itemObj.type) {
                    // not a complex object; just a scalar which is the type
                    itemObj.type = String(reader.value());
                    return itemObj;
                }
                if (itemObj.type === "struct") {
                    return itemObj;
                }
                item = reader.next();
            }
            return itemObj;
        }

        public import(input: string): InternalSchema[] {
            this.numLines = input ? input.split("\n").length : DEFAULT_LINES;
            const reader: Reader = makeReader(input);
            const columns: Column[] = [];
            let root: IonType | null = reader.next();
            if (reader.annotations().indexOf("type") < 0) {
                throw new Error("Not an ION schema definition");
            }
            while (root) {
                if (root.isContainer) {
                    reader.stepIn();

                    reader.next(); // should be name
                    const entityTypeName = reader.value() as string;

                    let item = reader.next(); // should be type
                    const typeObj = this.parseDataType(reader, item);

                    if (typeObj.type != "struct") {
                        const basicCol: Column = {
                            name: entityTypeName,
                            type: typeObj.type,
                            samples: typeObj.samples,
                        };
                        columns.push(basicCol);
                    }

                    item = reader.next(); // if it's a composite type, get the fields
                    while (item && reader.fieldName() != "fields") {
                        item = reader.next();
                    }
                    if (item) {
                        // found the fields
                        reader.stepIn();
                        let field = reader.next();
                        while (field) {
                            const fieldName = `${entityTypeName}.${reader.fieldName()}`;
                            if (field.isContainer) {
                                reader.stepIn();
                                const innerItem = reader.next();
                                const typeObj = this.parseDataType(reader, innerItem);
                                const col: Column = {
                                    name: fieldName,
                                    type: typeObj.type,
                                    samples: typeObj.samples,
                                };
                                columns.push(col);
                                reader.stepOut();
                            } else {
                                const typeObj = this.parseDataType(reader, item);
                                const col: Column = {
                                    name: fieldName,
                                    type: typeObj.type,
                                    samples: typeObj.samples,
                                };
                                columns.push(col);
                            }
                            field = reader.next();
                        }
                        reader.stepOut();
                    }

                    reader.stepOut();
                }
                root = reader.next();
            }

            const tableSchema: InternalSchema = {
                name: "", // overall name (e.g. "Table" is not specified
                source: this.getSource(),
                schema: columns,
            };
            return [tableSchema];
        }
    },

    class DynamoSchemaImporter extends Importer {
        public hasDocLink(): boolean {
            return true;
        }

        public getDocLink(): string {
            return "https://docs.aws.amazon.com/cli/latest/reference/dynamodb/describe-table.html";
        }

        public getSamples(): string[] {
            return [
                `{
    "Table": {
        "AttributeDefinitions": [
            {
                "AttributeName": "Artist",
                "AttributeType": "S"
            },
            {
                "AttributeName": "SongTitle",
                "AttributeType": "S"
            }
        ],
        "ProvisionedThroughput": {
            "NumberOfDecreasesToday": 0,
            "WriteCapacityUnits": 5,
            "ReadCapacityUnits": 5
        },
        "TableSizeBytes": 0,
        "TableName": "MusicCollection",
        "TableStatus": "ACTIVE",
        "KeySchema": [
            {
                "KeyType": "HASH",
                "AttributeName": "Artist"
            },
            {
                "KeyType": "RANGE",
                "AttributeName": "SongTitle"
            }
        ],
        "ItemCount": 0,
        "CreationDateTime": 1421866952.062
    }
}`,
            ];
        }

        public getSource(): string {
            return "DynamoDB";
        }

        public import(input: string): InternalSchema[] {
            const json = JSON.parse(input);
            // need to parse and pretty print before knowing number of lines
            this.numLines = JSON.stringify(json, null, 2).split("\n").length;
            const table = json["Table"];
            const columns = table["AttributeDefinitions"].map((ad: any): Column => {
                return { name: ad["AttributeName"], type: ad["AttributeType"] };
            });
            const schemas = [];
            if (columns.length > 0) {
                const tableSchema: InternalSchema = {
                    name: table["TableName"],
                    source: this.getSource(),
                    schema: columns,
                };
                schemas.push(tableSchema);
            }
            return schemas;
        }
    },

    class IonJSDataSchemaImporter extends Importer {
        public getSamples(): string[] {
            return [
                `{
    "Order": {
        "Metadata": {
            "Items": 4,
            "Shipments": 2
        },
        "Items": [
            {
                "ASIN": "0E1023",
                "Name": "BIC Mechanical Pencils (12 pack)",
                "Quantity": 3
            },
            {
                "ASIN": "8F3103",
                "Name": "Ream Paper (500 count)",
                "Quantity": 1
            }
        ],
        "CustomerId": "13403014",
        "CreationDateTime": 1421866952.062
    }
}`,
                `contribution::{
  submission_id:99999,
  customer_id:1234,
  sku:"XXX",
  version:1,
  marketplace_ids:[1],
  offer_listings:[
    {marketplace_id:1},
  ],
  product:{
    one:[
      {value:"A"},
    ],
    two:[
      {value:"A"},
      {value:"B"},
    ],
    three:[
      {value:1},
      {value:{}},
      {value:"C"},
    ],
  },
}`,
            ];
        }

        public getSource(): string {
            return "ION/JSON Data Importer";
        }

        public import(input: string): InternalSchema[] {
            this.numLines = input ? input.split("\n").length : DEFAULT_LINES;
            const reader: Reader = makeReader(input);
            const tableSchemas: InternalSchema[] = [];
            const columns: Column[] = [];
            const columnsSeen: Map<string, Set<string>> = new Map<string, Set<string>>();
            reader.next();

            // the root annotation is the type name (not provided if JSON
            const typeName = reader.annotations()[0] || "";
            if (typeName === "type") {
                throw new Error("Attempted to load a schema as ION/JSON data!");
            }
            const prefix = typeName ? `${typeName}.` : "";
            const recurse = (prefix: string, samplesForContainer: string[] = []): void => {
                reader.stepIn();
                try {
                    let element = reader.next();
                    while (element) {
                        const elementName: string = (reader.fieldName() as string) || "";
                        const elementType = element.name;
                        if (elementName) {
                            // isScalar can sometimes be a string, so don't use ===
                            const samples = element.isScalar ? [String(reader.value())] : samplesForContainer;
                            const col: Column = {
                                name: `${prefix}${elementName}`,
                                type: elementType,
                                samples: samples,
                            };
                            // if objects are heterogeneous and have the same field
                            // but with a different type, report that
                            const typesSeen: Set<string> = columnsSeen.get(col.name) || new Set<string>();
                            if (!typesSeen.has(elementType)) {
                                columns.push(col);
                                typesSeen.add(elementType);
                                columnsSeen.set(col.name, typesSeen);
                            }
                        }
                        if (element.isContainer) {
                            if (element.name === "list") {
                                recurse(`${prefix}${elementName}[]`, samplesForContainer);
                            } else if (element.isContainer) {
                                recurse(`${prefix}${elementName}.`);
                            }
                        }
                        element = reader.next();
                    }
                } finally {
                    reader.stepOut();
                }
            };
            recurse(prefix);

            const tableSchema: InternalSchema = {
                name: typeName,
                source: this.getSource(),
                schema: columns,
            };
            tableSchemas.push(tableSchema);
            return tableSchemas;
        }
    },

    // The below table is an example of the PostgreSQL and RDS output we accept from
    // the output of:    `testdb=> \d my_table`
    class RDSPostgreSQLSchemaImporter extends TabularMultiSchemaImporter {
        public hasDocLink(): boolean {
            return true;
        }

        public getDocLink(): string {
            return "https://w.amazon.com/bin/view/PrivacyEngineering/Product/GAP/#HRDS:PostgreSQL2FMySQL2FSQLServer";
        }

        public getCommand(): string {
            return `select table_schema,
       table_name,
       ordinal_position as position,
       column_name,
       data_type,
       case when character_maximum_length is not null
            then character_maximum_length
            else numeric_precision end as max_length,
       is_nullable,
       column_default as default_value
from information_schema.columns
where table_schema not in ('information_schema', 'pg_catalog')
order by table_schema,
         table_name,
         ordinal_position;`;
        }

        public getSamples(): string[] {
            return [
                `\
table_schema | table_name | position | column_name |     data_type     | max_length | is_nullable | default_value
--------------+------------+----------+-------------+-------------------+------------+-------------+---------------
public       | bar        |        1 | first_name  | character varying |            | YES         |
public       | bar        |        2 | last_name   | character varying |            | YES         |
public       | bar        |        3 | age         | integer           |         32 | YES         |
public       | foo        |        1 | c1          | integer           |         32 | YES         |
(4 rows)
`,
            ];
        }

        public getColumnKey(): string {
            return "column_name";
        }

        public getSchemaKey(): string {
            return "table_schema";
        }

        public getTableNameKey(): string {
            return "table_name";
        }

        public getTypeKey(): string {
            return "data_type";
        }

        public getSource(): string {
            return "RDS/PostgreSQL";
        }

        protected getSplitRegEx(): RegExp {
            return /\|/;
        }
    },

    class RDSMySQLSchemaImporter extends TabularMultiSchemaImporter {
        public hasDocLink(): boolean {
            return true;
        }

        public getDocLink(): string {
            return "https://w.amazon.com/bin/view/PrivacyEngineering/Product/GAP/#HRDS:PostgreSQL2FMySQL2FSQLServer";
        }

        public getSamples(): string[] {
            return [
                `\
+---------------+--------------+------------+-------------+------------------+-----------+--------------+
| TABLE_CATALOG | TABLE_SCHEMA | TABLE_NAME | COLUMN_NAME | ORDINAL_POSITION | DATA_TYPE | COLUMN_TYPE  |
+---------------+--------------+------------+-------------+------------------+-----------+--------------+
| def           | OrderService | Item       | order_id    |                1 | int       | int(11)      |
| def           | OrderService | Item       | customer_id |                2 | varchar   | varchar(256) |
| def           | OrderService | Person     | first_name  |                1 | varchar   | varchar(256) |
| def           | OrderService | Person     | last_name   |                2 | varchar   | varchar(256) |
| def           | OrderService | Person     | age         |                3 | int       | int(11)      |
+---------------+--------------+------------+-------------+------------------+-----------+--------------+`,
            ];
        }

        public getSource(): string {
            return "RDS/MySQL";
        }

        public getCommand(): string {
            return `SELECT TABLE_CATALOG,
          TABLE_SCHEMA,
          TABLE_NAME,
          COLUMN_NAME,
          ORDINAL_POSITION,
          DATA_TYPE,
          COLUMN_TYPE
        FROM information_schema.columns
        WHERE table_schema = DATABASE() ORDER BY table_name, ordinal_position;`;
        }

        protected getSplitRegEx(): RegExp {
            return /\|/;
        }

        public getSchemaKey(): string {
            return "TABLE_SCHEMA";
        }

        public getTableNameKey(): string {
            return "TABLE_NAME";
        }

        public getColumnKey(): string {
            return "COLUMN_NAME";
        }

        public getTypeKey(): string {
            return "COLUMN_TYPE";
        }

        protected getColumnLine(): number {
            return 1; // skip the first line
        }
    },

    class RedshiftSchemaImporter extends TabularMultiSchemaImporter {
        public getCommand(): string {
            return `SELECT * FROM PG_TABLE_DEF;`;
        }

        protected getSplitRegEx(): RegExp {
            return /\t/;
        }

        public hasDocLink(): boolean {
            return true;
        }

        public getDocLink(): string {
            return "https://w.amazon.com/bin/view/PrivacyEngineering/Product/GAP/#HRedshift28Tab-delimited29";
        }

        public getSamples(): string[] {
            return [
                `\
schemaname	tablename	column	type	encoding	distkey	sortkey	notnull
pg_catalog	padb_config_harvest	name	character(136)	lzo	false	0	true
pg_catalog	padb_config_harvest	harvest	character varying(32)	az64	false	0	true
pg_catalog	padb_config_harvest	archive	integer	az64	false	0	true
pg_catalog	padb_config_harvest	directory	character(500)	lzo	false	0	true
pg_catalog	pg_aggregate	aggfnoid	regproc	none	false	0	true
`,
            ];
        }

        public getSource(): string {
            return "Redshift (Tab-delimited)";
        }

        public getSchemaKey(): string {
            return "schemaname";
        }

        public getColumnKey(): string {
            return "column";
        }

        public getTypeKey(): string {
            return "type";
        }

        public getTableNameKey(): string {
            return "tablename";
        }
    },

    class RedshiftCSVSchemaImporter extends TabularMultiSchemaImporter {
        public getCommand(): string {
            return `SELECT * FROM PG_TABLE_DEF;`;
        }

        protected getSplitRegEx(): RegExp {
            // From https://stackoverflow.com/a/48806378
            return /(?:,|\n|^)("(?:(?:"")*[^"]*)*"|[^",\n]*|(?:\n|$))/;
        }

        public hasDocLink(): boolean {
            return true;
        }

        public getDocLink(): string {
            return "https://w.amazon.com/bin/view/PrivacyEngineering/Product/GAP/#HRedshift28CSVfromAWSQueryeditor29";
        }

        public getSamples(): string[] {
            return [
                `\
schemaname,tablename,column,type,encoding,distkey,sortkey,notnull
pg_catalog,padb_config_harvest,name,character(136),lzo,false,0,true
pg_catalog,padb_config_harvest,harvest,integer,az64,false,0,true
pg_catalog,padb_config_harvest,archive,integer,az64,false,0,true
pg_catalog,padb_config_harvest,directory,character(500),lzo,false,0,true
pg_catalog,pg_aggregate,aggfnoid,regproc,none,false,0,true
pg_catalog,pg_aggregate,aggtransfn,regproc,none,false,0,true
pg_catalog,pg_aggregate,aggfinalfn,regproc,none,false,0,true
pg_catalog,pg_aggregate,aggtranstype,oid,none,false,0,true
pg_catalog,pg_aggregate,agginitval,text,none,false,0,false
pg_catalog,pg_am,amname,name,none,false,0,true
pg_catalog,pg_am,amowner,integer,none,false,0,true
pg_catalog,pg_am,amstrategies,smallint,none,false,0,true
pg_catalog,pg_am,amsupport,smallint,none,false,0,true
pg_catalog,pg_am,amorderstrategy,smallint,none,false,0,true
pg_catalog,pg_am,amcanunique,boolean,none,false,0,true`,
            ];
        }

        public getSource(): string {
            return "Redshift (CSV from AWS Query editor)";
        }

        public getSchemaKey(): string {
            return "schemaname";
        }

        public getColumnKey(): string {
            return "column";
        }

        public getTypeKey(): string {
            return "type";
        }

        public getTableNameKey(): string {
            return "tablename";
        }
    },

    // output from "EXEC SP_COLUMNS fyi_links_top" or "EXEC SP_COLUMNS dbo"
    class RDSSQLServerSchemaImporter extends TabularMultiSchemaImporter {
        public hasDocLink(): boolean {
            return true;
        }

        public getDocLink(): string {
            return "https://w.amazon.com/bin/view/PrivacyEngineering/Product/GAP/#HRDS:PostgreSQL2FMySQL2FSQLServer";
        }

        public getSamples(): string[] {
            return [
                `\
TABLE_OWNER TABLE_NAME    COLUMN_NAME TYPE_NAME LENGTH
----------- ------------- ----------- --------- ------
dbo         fyi_links_top id          int       4
dbo         fyi_links_top counts      int       4
dbo         fyi_links_top url         varchar   80`,
            ];
        }

        public getSource(): string {
            return "RDS/SQL Server";
        }

        public getSchemaKey(): string {
            return "TABLE_OWNER";
        }

        public getColumnKey(): string {
            return "COLUMN_NAME";
        }

        public getTypeKey(): string {
            return "TYPE_NAME";
        }

        public getTableNameKey(): string {
            return "TABLE_NAME";
        }

        public getCommand(): string {
            return `EXEC sp_columns @table_name = '%', @table_owner = '%';`;
        }
    },
];

export class IntelligentImporter {
    private importers: Importer[];

    private activeImporter: Importer;

    public getSupportedImporters(): Importer[] {
        return this.importers;
    }

    public constructor() {
        this.importers = importers.map((imp): Importer => new imp());
        this.activeImporter = this.importers[0];
    }

    public import(input: string): InternalSchema[] {
        for (this.activeImporter of this.importers) {
            try {
                const imported = this.activeImporter.import(input).filter(isValid);
                if (imported && imported.length > 0) {
                    return imported;
                }
            } catch (e) {
                // next one can succeed, so do nothing
            }
        }
        return [];
    }

    public getNumLines(): number {
        return this.activeImporter.getNumLines();
    }
}
