import { AppInfoResponse } from "src/components/survey/SurveyFormModel";
import React from "react";
import { appName } from "src/components/survey/ApplicationIdentity/model/controls/appName";
import { auditHistory } from "src/components/survey/ApplicationIdentity/model/controls/auditHistory";
import { createdOn } from "src/components/survey/ApplicationIdentity/model/controls/createdOn";
import { controlBindle } from "src/components/survey/ApplicationIdentity/model/controls/controlBindle";
import { submittingTeam } from "src/components/survey/ApplicationIdentity/model/controls/submittingTeam";
import { respondentAlias } from "src/components/survey/ApplicationIdentity/model/controls/respondentAlias";
import { lastModified } from "src/components/survey/ApplicationIdentity/model/controls/lastModified";
import { reviewerGroupName } from "src/components/survey/ApplicationIdentity/model/controls/reviewerGroupName";
import { approvedBy } from "src/components/survey/ApplicationIdentity/model/controls/approvedBy";
import { approvedOn } from "src/components/survey/ApplicationIdentity/model/controls/approvedOn";
import { FetchBindlesResponse } from "src/components/survey/hooks/useFetchBindles";
import { legalStatus } from "src/components/survey/ApplicationIdentity/model/controls/legalStatus";
import { accountingStatus } from "src/components/survey/ApplicationIdentity/model/controls/accountingStatus";
import { fraudStatus } from "src/components/survey/ApplicationIdentity/model/controls/fraudStatus";

export interface Model {
    application: AppInfoResponse;
    bindles: FetchBindlesResponse;
}

export interface Pair {
    label: string;
    info?: React.ReactNode;
    value: React.ReactNode;
    testId?: string;
}

export enum IdentityPair {
    APP_NAME,
    AUDIT_HISTORY,
    CREATED_ON,
    LAST_MODIFIED,
    CONTROL_BINDLE,
    SUBMITTING_TEAM,
    SURVEY_RESPONDENT_ALIAS,
    REVIEWER_GROUP_NAME,
    APPROVER_ALIAS,
    APPROVED_ON,
    LEGAL_STATUS,
    ACCOUNTING_STATUS,
    FRAUD_STATUS,
}

export function factory(model: Model, key: IdentityPair): Pair {
    switch (key) {
        case IdentityPair.APP_NAME:
            return appName(model);
        case IdentityPair.AUDIT_HISTORY:
            return auditHistory(model);
        case IdentityPair.CREATED_ON:
            return createdOn(model);
        case IdentityPair.LAST_MODIFIED:
            return lastModified(model);
        case IdentityPair.CONTROL_BINDLE:
            return controlBindle(model);
        case IdentityPair.SUBMITTING_TEAM:
            return submittingTeam(model);
        case IdentityPair.SURVEY_RESPONDENT_ALIAS:
            return respondentAlias(model);
        case IdentityPair.REVIEWER_GROUP_NAME:
            return reviewerGroupName(model);
        case IdentityPair.APPROVER_ALIAS:
            return approvedBy(model);
        case IdentityPair.APPROVED_ON:
            return approvedOn(model);
        case IdentityPair.LEGAL_STATUS:
            return legalStatus(model);
        case IdentityPair.ACCOUNTING_STATUS:
            return accountingStatus(model);
        case IdentityPair.FRAUD_STATUS:
            return fraudStatus(model);
    }
}

export function getPairs(model: Model): Pair[] {
    return [
        factory(model, IdentityPair.LEGAL_STATUS),
        factory(model, IdentityPair.ACCOUNTING_STATUS),
        factory(model, IdentityPair.FRAUD_STATUS),
        factory(model, IdentityPair.APP_NAME),
        factory(model, IdentityPair.AUDIT_HISTORY),
        factory(model, IdentityPair.CREATED_ON),
        factory(model, IdentityPair.LAST_MODIFIED),
        factory(model, IdentityPair.CONTROL_BINDLE),
        factory(model, IdentityPair.SUBMITTING_TEAM),
        factory(model, IdentityPair.SURVEY_RESPONDENT_ALIAS),
        factory(model, IdentityPair.REVIEWER_GROUP_NAME),
        factory(model, IdentityPair.APPROVER_ALIAS),
        factory(model, IdentityPair.APPROVED_ON),
    ];
}

export enum DisplayLabels {
    AppName = "Application Name",
    ApprovedBy = "Approved By",
    ApprovedOn = "Approved On",
    AuditHistory = "Audit History",
    ControlBindle = "Access Control Bindle",
    CreatedOn = "Created On",
    LastModified = "Last Modified",
    RespondentAlias = "Survey Respondent Alias",
    ReviewerGroupName = "Reviewer Group Name",
    SubmittingTeam = "Submitting Team",
    PrivacyStatus = "Privacy Status",
    AccountingStatus = "Accounting Status",
    FraudStatus = "CTPS Status",
}
