import React from "react";
import { Button, Popover, SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react-v3";

export interface CopyToClipboardButtonProps {
    "data-testid": string;
    successMessage: string;
    children: React.ReactNode;
    onClick: () => Promise<void>;
}

/**
 * Renders an inline icon button of the copy icon
 */
const CopyToClipboardButton = (props: CopyToClipboardButtonProps): JSX.Element => {
    return (
        <SpaceBetween direction="horizontal" size="xxs">
            <Popover
                size="small"
                position="top"
                triggerType="custom"
                dismissButton={false}
                content={<StatusIndicator type="success">{props.successMessage}</StatusIndicator>}
            >
                <Button
                    data-testid={props["data-testid"]}
                    variant={"inline-icon"}
                    iconName="copy"
                    onClick={props.onClick}
                />
            </Popover>
            {props.children}
        </SpaceBetween>
    );
};

export default CopyToClipboardButton;
