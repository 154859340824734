import { default as React } from "react";
import { Box, Icon } from "@amzn/awsui-components-react-v3";
import Model from "src/components/constants/Constants";

interface DeleteNoticeProps {
    appName: string;
}
export const DeleteNotice = React.memo(({ appName }: DeleteNoticeProps): JSX.Element => {
    return (
        <div>
            <Box variant="p">
                <b>
                    <Icon name="status-warning" size="normal" variant="error" /> NOTE:
                </b>{" "}
                {Model.getData("deleteCampaignWarning")}
                <br />
                <br />
                {Model.getData("deleteAnvilWarning")}
            </Box>
            <br />
            <Box variant="p">{`Are you sure you want to delete the application "${appName}"?`}</Box>
        </div>
    );
});
