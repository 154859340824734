// Custom hook to encapsulate stateful logic of fetching table details on component mount
import { QuestionChoice, TableDetails } from "src/services/KaleTablesService";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { formatPageError } from "src/components/TableDetails/utils";
import { FETCH_DATA_ELEMENTS_ERROR, FETCH_TABLE_ERROR } from "src/components/TableDetails/constants";
import { useTableIdentifiersFromRoute } from "src/components/TableDetails/TableDetailsPage/hooks";
import { UserRole } from "src/permissions";
import { DataElementSchema } from "src/services/KaleApplicationService";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";

type SetTableDetailsCb = (tableDetails: TableDetails | null) => void;
type SetUserRoleCb = (userRole: UserRole | null) => void;
type SetDerUuidChoicesCb = (derUuidChoices: QuestionChoice[]) => void;

/**
 * Fetches resources that the page requires before the UI should tear down the initial page wide Kale-loading-spinner
 * @return a boolean value to indicate whether or not the page still fetching essential resources
 */
export const useFetchPageResources = (
    setDerUuidChoicesCb: SetDerUuidChoicesCb,
    setUserRole: SetUserRoleCb,
    setTableDetailsCb: SetTableDetailsCb,
    displayMessage: DisplayMessageCb
): { isFetchingPageResources: boolean; isValidTable: boolean } => {
    // Fetch data elements
    const { isFetchingDerUuidChoices } = useFetchDerUuidChoicesOnMount(setDerUuidChoicesCb, displayMessage);
    // Fetch user role
    const { isFetchingUserRole } = useFetchApplicationUserRole(setUserRole, displayMessage);
    // Fetch table details
    const { isFetchingNewTableDetails } = useFetchTableOnTableRouteChange(setTableDetailsCb, displayMessage);
    // Validate table details belong to this app revision
    const { isValidTable } = useValidateTableEntities(displayMessage);

    return {
        isFetchingPageResources: isFetchingNewTableDetails || isFetchingUserRole || isFetchingDerUuidChoices,
        isValidTable: isValidTable,
    };
};

const useFetchDerUuidChoicesOnMount = (
    setDerUUidChoicesCb: SetDerUuidChoicesCb,
    displayMessage: DisplayMessageCb
): {
    isFetchingDerUuidChoices: boolean;
} => {
    // Fetch data elements and then map them to a choices list for the derUuid field question.

    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const [isFetchingDerUuidChoices, setIsFetchingDataElements] = useState<boolean>(true);

    useEffect((): void => {
        const makeChoicesFromDataElements = (dataElements: DataElementSchema[]): QuestionChoice[] => {
            return dataElements.map(
                ({ description, name: label, id: value }): QuestionChoice => ({ description, label, value })
            );
        };
        kaleAppService
            .fetchDataElements()
            .then((dataElements): void => {
                const questionChoices = makeChoicesFromDataElements(dataElements);
                setDerUUidChoicesCb(questionChoices);
                setIsFetchingDataElements(false);
            })
            .catch((err): void => {
                const fetchDataElementsError = formatPageError(FETCH_DATA_ELEMENTS_ERROR, err.message);
                displayMessage(MessageType.error, fetchDataElementsError);
                setIsFetchingDataElements(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isFetchingDerUuidChoices };
};

const useFetchApplicationUserRole = (
    setUserRoleCb: SetUserRoleCb,
    displayMessage: DisplayMessageCb
): {
    isFetchingUserRole: boolean;
} => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const { applicationName, reviewId: selectedReviewId } = useTableIdentifiersFromRoute();

    const deps = { kaleAppService, displayMessage, setUserRoleCb };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isFetchingUserRole, setIsFetchingUserRole] = useState<boolean>(true);

    /**
     * Determines the appropriate userRole to assign to a user. If a user is viewing a previous revision,
     * they are assigned a readOnly role.
     */
    useEffect((): void => {
        const fetchUserRole = async (): Promise<void> => {
            setIsFetchingUserRole(true);
            const { kaleAppService, displayMessage, setUserRoleCb } = dependenciesRef.current!;
            try {
                const [userRole, latestReviewId] = await Promise.all([
                    kaleAppService.fetchUserRole(applicationName),
                    kaleAppService.getLatestReviewID(applicationName),
                ]);
                if (`${latestReviewId}` !== selectedReviewId) {
                    // user is viewing a previous revision
                    setUserRoleCb(UserRole.readOnly);
                } else {
                    setUserRoleCb(userRole as UserRole);
                }
            } catch (err) {
                setUserRoleCb(UserRole.readOnly);
                const fetchTableError = formatPageError(FETCH_TABLE_ERROR, (err as Error).message);
                displayMessage(MessageType.error, fetchTableError);
            } finally {
                setIsFetchingUserRole(false);
            }
        };
        fetchUserRole();
    }, [applicationName, selectedReviewId]);

    return { isFetchingUserRole };
};

const useValidateTableEntities = (displayMessage: DisplayMessageCb): { isValidTable: boolean } => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const { applicationName, reviewId: selectedReviewId, dataStoreId, tableId } = useTableIdentifiersFromRoute();

    const deps = { kaleAppService, displayMessage };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isValidTable, setIsValidTable] = useState<boolean>(true);

    useEffect((): void => {
        const validateTableEntities = async (): Promise<void> => {
            const { kaleAppService, displayMessage } = dependenciesRef.current!;
            try {
                const appRevision = await kaleAppService.fetchAppByReviewId(applicationName, selectedReviewId);
                // Check to make sure review ID matches datastore and table ID, that is:
                // The datastore and table we're trying to look at in the UI actually belong
                // to the revision we're currently trying to view. If not, users can get
                // edit access to old datastores/tables by just changing the review ID to
                // be the latest one
                const valid = checkIDsAreValid(appRevision as SurveyResponse, dataStoreId, tableId);
                setIsValidTable(valid);
            } catch (err) {
                setIsValidTable(false);
                displayMessage(MessageType.error, (err as Error).message);
            }
        };
        validateTableEntities();
    }, [applicationName, selectedReviewId, dataStoreId, tableId]);

    return { isValidTable };
};

/**
 When an application gets a new revision, the previous revision is considered archived. All of the previous revision's
 table and field entities are cloned and given new ids that are assigned to the new revision. This function only returns
 true if the specified datastore and table id belong to the specified app revision
 */
const checkIDsAreValid = (appRevision: SurveyResponse, dataStoreId: string, tableId: string): boolean => {
    const dataStores = appRevision.appInfo.review.dataStores;
    for (const dataStore of dataStores) {
        const tables = dataStore.tables;
        for (const table of tables) {
            if (`${dataStore.id}` === dataStoreId && `${table.id}` === tableId) {
                // This Data Store and Table ID "match" the current revision
                return true;
            }
        }
    }
    return false;
};

const useFetchTableOnTableRouteChange = (
    setTableDetailsCb: SetTableDetailsCb,
    displayMessage: DisplayMessageCb
): {
    isFetchingNewTableDetails: boolean;
} => {
    const {
        service: { kaleTablesService },
    } = useContext(KaleContext);

    const tableIdentifiers = useTableIdentifiersFromRoute();

    const deps = { kaleTablesService, displayMessage, setTableDetailsCb };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isFetchingNewTableDetails, setIsFetchingNewTableDetails] = useState<boolean>(true);

    useEffect((): void => {
        setIsFetchingNewTableDetails(true);
        const { kaleTablesService, displayMessage, setTableDetailsCb } = dependenciesRef.current!;
        kaleTablesService
            .fetchTable(tableIdentifiers)
            .then((tableDetails): void => {
                setTableDetailsCb(tableDetails);
                setIsFetchingNewTableDetails(false);
            })
            .catch((err): void => {
                const fetchTableError = formatPageError(FETCH_TABLE_ERROR, err.message);
                displayMessage(MessageType.error, fetchTableError);
                setTableDetailsCb(null);
                setIsFetchingNewTableDetails(false);
            });
    }, [tableIdentifiers]);

    return { isFetchingNewTableDetails };
};
