import React, { useCallback, useState } from "react";
import Container from "@amzn/awsui-components-react-v3/polaris/container";
import { AppLayout, Box } from "@amzn/awsui-components-react-v3";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import Loader from "src/components/fields/Loader";
import { useFetchApplicationOnMount } from "src/components/survey/AccessControl/hooks/useFetchApplicationOnMount";
import { AccessControlWizard } from "src/components/survey/AccessControl/AccessControlWizard";
import ApprovalStatuses from "src/components/ApprovalStatuses/ApprovalStatuses";
import {
    selectAccountingApprovalStatus,
    selectFraudApprovalStatus,
    selectLegalApprovalStatus,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import SpaceBetween from "@amzn/awsui-components-react-v3/polaris/space-between";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import { AccessControlContextProvider } from "src/components/survey/AccessControl/AccessControlContext";
import { isFormReadOnly } from "src/components/fields/hoc/withReadonly";
import { useLocation } from "react-router-dom";

interface AccessControlPageState {
    isRedirect?: boolean;
}

export const AccessControlPage = (): JSX.Element => {
    const { state: { isRedirect = false } = { isRedirect: false } } = useLocation<AccessControlPageState>();
    const { application, userRole, isLoading } = useFetchApplicationOnMount();

    const [helpPanelContent, setHelpPanelContent] = useState<React.ReactNode>(null);
    const [isHelpPanelOpen, setIsHelpPanelOpen] = useState<boolean>(false);

    const BreadCrumbs = (props: { application: SurveyResponse }): JSX.Element => {
        const { applicationName, review } = props.application.appInfo;
        const { id } = review;
        const legalSurveyRecord = BreadcrumbItems.editKaleRecord(applicationName, `${id}`);
        const accessControlRecord = BreadcrumbItems.editKaleAccessControlRecord(applicationName, `${id}`);
        const items = [BreadcrumbItems.KALE_LANDING_PAGE, legalSurveyRecord];
        if (!isRedirect) {
            items.push(accessControlRecord);
        }
        return <KaleBreadcrumbGroup ariaLabel="Breadcrumbs" items={items} />;
    };

    // Memoize Content so that wizard doesn't re-load when help panel is opened/closed
    const Content = useCallback(
        (props: { application: SurveyResponse }): JSX.Element => {
            const { application } = props;
            return (
                <SpaceBetween size={"s"} direction={"vertical"}>
                    {isRedirect && (
                        <Container>
                            <Box variant={"p"}>
                                Please reconfirm your application&lsquo;s access controls before proceeding to your
                                legal survey attestation
                            </Box>
                        </Container>
                    )}
                    <Container header={<Header variant={"h2"}>Application Information</Header>}>
                        <ApprovalStatuses
                            privacyStatus={selectLegalApprovalStatus(application.appInfo).status}
                            accountingStatus={selectAccountingApprovalStatus(application.appInfo).status}
                            fraudStatus={selectFraudApprovalStatus(application.appInfo).status}
                        />
                    </Container>
                    <AccessControlWizard />
                </SpaceBetween>
            );
        },
        [isRedirect]
    );

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            {application && (
                <AccessControlContextProvider
                    value={{
                        application,
                        setHelpPanelContent,
                        setIsHelpPanelOpen,
                        isReadOnly: isFormReadOnly(
                            userRole,
                            () => selectLegalApprovalStatus(application.appInfo).status
                        ),
                    }}
                >
                    <AppLayout
                        breadcrumbs={<BreadCrumbs application={application} />}
                        navigationHide={true}
                        tools={helpPanelContent}
                        toolsOpen={helpPanelContent !== null && isHelpPanelOpen}
                        onToolsChange={(event) => setIsHelpPanelOpen(event.detail.open)}
                        headerSelector="#top-nav"
                        ariaLabels={{
                            tools: "Help panel",
                            toolsClose: "Close help panel",
                            toolsToggle: "Open help panel",
                        }}
                        content={<Content application={application} />}
                    />
                </AccessControlContextProvider>
            )}
        </React.Fragment>
    );
};
