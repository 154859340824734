// Using 2 as the default to ensure that this is never mistaken
// as a single line text input on initial load when empty
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import ResizeObserver from "resize-observer-polyfill";

/**
 * Grow or shrink the height of a textarea element to match the height of its
 * content.
 * @param textareaEl the textarea element to resize
 * @param minRows the minimum number of total rows to collapse to as content is deleted
 */
const fitTextareaHeightToContent = (textareaEl: HTMLTextAreaElement, minRows: number): void => {
    /*
    We use scrollHeight to get the current height of the textarea element.

    Per the spec, the scrollHeight value will be equal to the MINIMUM height the element would require in order to fit
    all the content in its viewport without using a vertical scrollbar. HOWEVER, IF the element height is already tall
    enough to fit its content without requiring a vertical scrollbar, then its scrollHeight will be 0 instead of the
    height of the content.

    Because our element is styled with `box-sizing: border-box`, all of the heights here-in will be relative to the
    element's border box
    */

    // Temporarily set the the height of the element to 0px in order to ensure we can measure a non-zero scroll height
    textareaEl.style.height = "0px";
    const contentSizedBorderBoxHeight = textareaEl.scrollHeight;

    // Our minimum border box height is dependant on the element's line height, padding height, and border height
    const { lineHeight, borderTopWidth, borderBottomWidth, paddingTop, paddingBottom } = getComputedStyle(textareaEl);
    const minRowsHeight = parseFloat(lineHeight) * minRows;
    const minBorderBoxHeight =
        minRowsHeight +
        parseFloat(borderTopWidth) +
        parseFloat(borderBottomWidth) +
        parseFloat(paddingTop) +
        parseFloat(paddingBottom);

    // Set the new border box height
    textareaEl.style.height = Math.max(contentSizedBorderBoxHeight, minBorderBoxHeight) + "px";
};

/**
 * Custom hook to create a Singleton ResizeObserver which will notify width changes for a single textarea element
 * @param currentWidth the current width of the element under observation
 * @param onTextareaWidthResizeCb a callback method to pass the new element width when a width resize occurs
 */
const useSingletonWidthResizeObserver = (
    currentWidth: number | null,
    onTextareaWidthResizeCb: (newWidth: number) => void
): ResizeObserver => {
    // Update width and callback references to latest values on every render
    const deps = { currentWidth, onTextareaWidthResizeCb };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    // Static instance of resize handling function. Only created during initial render.
    const onTextareaResize = useRef((entries: ResizeObserverEntry[]): void => {
        const { currentWidth: previousWidth, onTextareaWidthResizeCb } = dependenciesRef.current;
        if (previousWidth !== null) {
            // We only ever observe 1 element at a time. Destructure the first item
            // from the entries array to get the new size of the textarea element.
            const [
                {
                    contentRect: { width: newWidth },
                },
            ] = entries;
            if (previousWidth !== newWidth) {
                onTextareaWidthResizeCb(newWidth);
            }
        }
    }).current;

    // Static instance of debounced resize handling function. Only created during initial render.
    const debouncedOnTextAreaResize = useRef(debounce(onTextareaResize, 50, { maxWait: 100 })).current;

    // Return a static instance of Mutation Observer. Only created during initial render.
    return React.useRef<ResizeObserver>(new ResizeObserver(debouncedOnTextAreaResize)).current;
};

type AutoSizingTextareaElementRefCb = (nextTextareaEl: HTMLTextAreaElement | null) => void;
/**
 * Creates a callback ref for a React TextAreaElement element which will manage resizing the element's height
 * automatically to match the height of its content
 * @param textareaContent the string representing the textarea's value attribute
 * @param minRows the minimum number of rows to collapse the height of the textarea to when there is little to no
 * content
 */
const useAutoSizingTextareaElementRef = (textareaContent: string, minRows: number): AutoSizingTextareaElementRefCb => {
    const [textareaEl, setTextareaEl] = useState<HTMLTextAreaElement | null>(null);
    const [currentWidth, recordCurrentWidth] = useState<number | null>(null);

    const widthResizeObserver = useSingletonWidthResizeObserver(currentWidth, recordCurrentWidth);

    // React will call our ref callback whenever it mounts a new textarea element or unmounts the previous one.
    // When called during unmounting, the nextTextareaEl parameter is null
    const textAreaElementRefCb = useCallback<AutoSizingTextareaElementRefCb>(
        (nextTextareaEl: HTMLTextAreaElement | null): void => {
            const prevTextareaEl = textareaEl;
            if (prevTextareaEl) {
                // If we were already storing a textareaEl, unobserve it
                widthResizeObserver.unobserve(prevTextareaEl);
            }
            setTextareaEl(nextTextareaEl);
            if (nextTextareaEl !== null) {
                const initialWidth = parseFloat(getComputedStyle(nextTextareaEl).width);
                recordCurrentWidth(initialWidth);
                widthResizeObserver.observe(nextTextareaEl);
            }
        },
        [textareaEl, widthResizeObserver]
    );

    useLayoutEffect((): void => {
        // Resize the height of the textarea element to fit its content height
        if (textareaEl) {
            fitTextareaHeightToContent(textareaEl, minRows);
        }
    }, [textareaContent, textareaEl, currentWidth, minRows]);

    return textAreaElementRefCb;
};

export { useAutoSizingTextareaElementRef };
