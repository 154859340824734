import React, { useState, useCallback } from "react";
import { NonCancelableCustomEvent, Select, SelectProps } from "@amzn/awsui-components-react-v3/polaris";
import { v4 as uuidv4 } from "uuid";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions";
import styled from "styled-components";
import { classPrefixSelector } from "shared/util/selectors";

type ExtendAndOverride<Extendable, Overrides> = Omit<Extendable, keyof Overrides> & Overrides;
export type ClearableSelectChangeDetail = ExtendAndOverride<
    SelectProps.ChangeDetail,
    // Override the following properties of base type "SelectProps.ChangeDetail" to also accept
    // null. This is not possible with the interface "extends" keyword because "extends"
    // in typescript only lets you narrow the set of types a property can be assigned.
    // It won't let you broaden or replace the type(s) that a property can be assigned.
    {
        selectedOption: SelectProps.Option | null;
    }
>;

export type ClearableSelectOption = SelectProps.Option;
export type ClearableSelectChangeEvent = NonCancelableCustomEvent<ClearableSelectChangeDetail>;
export type ClearableSelectChangeHandler = (event: ClearableSelectChangeEvent) => void;
export interface ClearableSelectColorOverrides {
    color?: string;
    background?: string;
}
export interface ClearableSelectProps extends SelectProps, FormQuestionForwardingProps {
    onChange?: ClearableSelectChangeHandler;
    isClearable?: boolean;
    colors?: ClearableSelectColorOverrides;
}

export const CLEAR_OPTION_VALUE = uuidv4();
export const CLEAR_OPTION_LABEL = "-";
const CLEAR_OPTION: SelectProps.Option = {
    value: CLEAR_OPTION_VALUE,
    label: CLEAR_OPTION_LABEL,
};

export const makeSelectionClearedEvent = (
    evt: NonCancelableCustomEvent<SelectProps.ChangeDetail>
): ClearableSelectChangeEvent => ({
    // Takes an input event object and returns a copy event object with the selectedObject
    // detail properties to signify that the selection was cleared
    ...evt,
    detail: {
        ...evt.detail,
        selectedOption: null,
    },
});

/**
 * A thin wrapper component around the Polaris <Select> component which injects an additional "-" (clear) option to the
 * front of the <Select> component's dropdown options. When a user selects that option this component clears the
 * <Select> component's selected option and re-displays any initial placeholder text.
 */
export const ClearableSelect = (props: ClearableSelectProps): JSX.Element => {
    const { options, onChange, selectedOption, isClearable = true } = props;

    // Clearing the selectedOption of the underlying <Select> component only works if we pass it a
    // selectedOption prop of null, not undefined. If our selectedOption prop is undefined, seed our
    // state with null instead.
    const [syntheticSelectedOption, updateSyntheticSelectedOption] = useState(selectedOption ?? null);

    // There is a bug in polaris d.ts that erroneously indicates this can also be a null or undefined property
    // https://issues.amazon.com/issues/AWSUI-9674 but providing either of those values will throw a runtime error.
    // Coerce a null or undefined value into an empty array.
    const safeOptions = options || [];

    // Position an additional clear_option at the front of a non-empty options list if the isClearable prop is true.
    const enhancedOptions = safeOptions.length && isClearable ? [CLEAR_OPTION, ...safeOptions] : safeOptions;

    const enhancedOnChange = useCallback(
        (evt: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
            // If our clear_option is selected, we need to synthesize a null selectedOption prop to the underlying
            // <Select> component to cause it to clear its selection and re-display its placeholder text.
            // Additionally we need to synthesize a new selectionEvent to pass to the onChange callback prop.
            const isSelectionCleared = CLEAR_OPTION_VALUE === evt.detail.selectedOption.value;
            const syntheticSelectionEvent = isSelectionCleared ? makeSelectionClearedEvent(evt) : evt;
            const newSelectedOption = syntheticSelectionEvent.detail.selectedOption;

            updateSyntheticSelectedOption(newSelectedOption);
            onChange && onChange(syntheticSelectionEvent);
        },
        [onChange, updateSyntheticSelectedOption]
    );

    const enhancedProps: SelectProps = {
        ...props,
        selectedOption: syntheticSelectedOption,
        options: enhancedOptions,
        onChange: enhancedOnChange,
        expandToViewport: true,
    };

    return <Select {...enhancedProps} />;
};

// Polaris 3 is using scoped CSS, meaning that their class names are randomly generated every time the polaris package
// is rebuilt. Currently Polaris class names keep a consistent prefix followed by a random string of characters.
// We can override styles, out of necessity,  based on class name prefix for now.
const POLARIS_CLASSNAME_PREFIXES = {
    CLOSED_SELECT_ELEMENT: "_button-trigger_",
};

const StyledClearableSelect = styled(ClearableSelect)<ClearableSelectProps>(
    ({ colors }): string => `
   ${classPrefixSelector(POLARIS_CLASSNAME_PREFIXES.CLOSED_SELECT_ELEMENT)} {
            ${colors?.background && `background-color: ${colors.background} !important`};
            ${colors?.color && `color: ${colors.color} !important`};
        }
    `
);
StyledClearableSelect.displayName = "ClearableSelect";

export default StyledClearableSelect;
