import {
    QuestionType,
    QuestionTag,
    QuestionApplicabilityTag,
    QuestionColorOverrides,
} from "src/services/dynamic-questions";
import { AnswerStateEntityId, ContentKey } from "src/answers_legacy";
export { QuestionType, QuestionTag, AnswerContentKey as ContentKey } from "src/services/dynamic-questions";

export type NonJsonContentType = string | string[] | null;
export type ContentValue = RetentionJsonContentType | NonJsonContentType;

export interface QuestionChoice {
    value: string;
    label?: string;
    infoContent?: string;
    description?: string;
    colors?: QuestionColorOverrides;
    isExclusive?: boolean;
    privacyAlertContent?: string;
}

export enum ParentLookupKeys {
    id = "id",
    shortId = "shortId",
}

export enum ConstraintLookupKeys {
    parentId = "parentId",
    parentShortId = "parentShortId",
}

interface ConstraintBase {
    parentId?: number;
    parentEntityId?: AnswerStateEntityId;
    parentShortId?: string;
    // NOTE: Constraint handlers are to infer json content question parentChoices as list of json content objects
    parentChoices?: Array<string | RetentionJsonContentType>;
}

interface ParentBase {
    id: number;
    shortId: string;
}

export interface ParentConstraintBase {
    parentId?: number;
    parentChoices?: string[] | RetentionJsonContentType[];
}

export interface QuestionBase extends ParentBase, ConstraintBase {
    title: string;
    content: string;
    subtext: string;
    type: QuestionType;
    choices: QuestionChoice[];
    tags: (QuestionTag | QuestionApplicabilityTag)[];
    groupId: string;
}

export enum RetentionInputType {
    retentionCheckbox = "retentionCheckbox",
    retentionText = "retentionText",
    retentionSingleSelect = "retentionSingleSelect",
}

export interface RetentionJsonContentType {
    [RetentionInputType.retentionCheckbox]: string;
    [RetentionInputType.retentionText]: string;
    [RetentionInputType.retentionSingleSelect]: string;
}

export interface AnswerBase {
    questionId: number;
    questionShortId: string;
    [ContentKey.textContent]?: string;
    [ContentKey.arrayContent]?: string[];
    [ContentKey.dateContent]?: string;
    [ContentKey.jsonContent]?: RetentionJsonContentType;
}

export interface AnswerWithQuestion<QType = QuestionBase, AType = AnswerBase> {
    answer: AType;
    question: QType;
}
