import { Popover, PopoverProps } from "@amzn/awsui-components-react-v3";
import { ItemDescriptor } from "src/services/summaries/model/content-descriptors";
import React from "react";
import { ContentPane } from "src/components/survey/ApplicationOverview/ContentPane/ContentPane";
import { TEST_IDS } from "shared/content-panes";

const { POPOVER_CONTENT, POPOVER_TRIGGER } = TEST_IDS.CONTENT_PANE.ITEM;

type ItemPopoverProps = Pick<PopoverProps, "children"> & Pick<ItemDescriptor, "descriptors">;
export const ItemPopover = ({ descriptors, children: item }: ItemPopoverProps): JSX.Element => {
    const popoverContent = <ContentPane data-testid={POPOVER_CONTENT} descriptors={descriptors} />;

    return (
        <Popover content={popoverContent} triggerType={"text"}>
            <span data-testid={POPOVER_TRIGGER}>{item}</span>
        </Popover>
    );
};
