import { useEffect, useState } from "react";

interface Dimension {
    width: number;
    height: number;
}

const getWindowDimensions = (): { width: number; height: number } => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

export const useWindowDimensions = (): Dimension => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect((): (() => void) => {
        const handleResize = (): void => {
            setWindowDimensions(getWindowDimensions());
        };

        // eslint-disable-next-line
        window.addEventListener("resize", handleResize);
        return (): void => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
