import usePrevious from "@rooks/use-previous";
import { useEffect, useState } from "react";
import {
    DataStoresAccessor,
    DataStoresAccessorReadonly,
    readonlyDataStoreAccessors,
    useDataStores,
} from "src/answers_legacy";

export type SetStashCb = (stash: DataStoresAccessor[] | null) => void;
export type UseGetAccessorStashTuple = [DataStoresAccessorReadonly[] | null, SetStashCb];
export const useGetAccessorStash = (): UseGetAccessorStashTuple => {
    const [accessors] = useDataStores();
    const prevAccessors = usePrevious(accessors);
    const [accessorsStash, setAccessorsStash] = useState<DataStoresAccessorReadonly[] | null>(null);

    useEffect((): void => {
        // Resets stash on first load and when handling a new remote call response
        if (!accessorsStash && prevAccessors !== accessors) {
            setStash(accessors);
            return;
        }
        // Handle resolving accessor stash when adding and removing data stores
        if (prevAccessors) {
            if (prevAccessors.length !== accessors.length) {
                setStash(accessors.filter((accessor): boolean => !prevAccessors.includes(accessor)));
            }
        }
    }, [accessors, accessorsStash, prevAccessors]);

    const setStash = (value: DataStoresAccessor[] | null): void => {
        if (!value) {
            setAccessorsStash(value);
            return;
        }
        const accessors = readonlyDataStoreAccessors(value);
        setAccessorsStash(JSON.parse(JSON.stringify(accessors)));
    };

    return [accessorsStash, setStash];
};
