import { TEST_IDS } from "./test-ids";
import { tidSelector } from "../util/selectors";

const {
    CAMPAIGN_FORM: { SAVE_BUTTON, ROOT: CAMPAIGN_FORM_ROOT },
} = TEST_IDS;

export const SELECTORS = {
    CAMPAIGN_FORM: {
        ROOT: tidSelector(CAMPAIGN_FORM_ROOT),
        SAVE_BUTTON: tidSelector(SAVE_BUTTON),
    },
};
