import { CampaignWorkflow, EMPTY_PERMISSION_ROLE_MAPPING, PermissionMapping, UserAction, UserRole } from "../constants";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";

export const TAF_CAN_PERMISSION_MAPPING: PermissionMapping = {
    [UserAction.editApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [
            ApplicationStatus.inProgress,
            ApplicationStatus.inReview,
            ApplicationStatus.approved,
            ApplicationStatus.notApplicable,
        ],
        [UserRole.serviceOwner]: [ApplicationStatus.inProgress],
        [UserRole.accountingReviewer]: [],
        [UserRole.fraudReviewer]: [],
    },
    [UserAction.submitApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inProgress],
        [UserRole.serviceOwner]: [ApplicationStatus.inProgress],
        [UserRole.accountingReviewer]: [],
        [UserRole.fraudReviewer]: [],
    },
    [UserAction.deleteApplication]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
    },
    [UserAction.viewRestrictedFields]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.approveApplication]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.rejectApplication]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.recallApplication]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.reviewRestrictedFields]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.canOverrideStatus]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.canOverrideRole]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.viewAdminOnlyFields]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.viewReviewerOnlyFields]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.editCampaignApplication]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.bulkEditTableAndFields]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [UserAction.submitFinancialFeedback]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inReview],
        [UserRole.accountingReviewer]: [ApplicationStatus.inReview],
        [UserRole.fraudReviewer]: [ApplicationStatus.inReview],
    },
    [UserAction.rejectFinancialApp]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.accountingReviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.fraudReviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.recallFinancialApp]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.serviceOwner]: [ApplicationStatus.inReview, ApplicationStatus.approved],
    },
    [UserAction.editDecisions]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.accountingReviewer]: [ApplicationStatus.inReview],
        [UserRole.fraudReviewer]: [ApplicationStatus.inReview],
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved, ApplicationStatus.notApplicable],
    },
    [UserAction.viewDecisions]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.accountingReviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.fraudReviewer]: [ApplicationStatus.inReview, ApplicationStatus.approved],
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved, ApplicationStatus.notApplicable],
    },
    [UserAction.viewFinalDecisions]: {
        ...EMPTY_PERMISSION_ROLE_MAPPING,
        [UserRole.readOnly]: [ApplicationStatus.approved],
        [UserRole.serviceOwner]: [ApplicationStatus.approved],
        // Legal Reviewers can also view the final decisions, even though legal reviewers don't review TAF.
        [UserRole.reviewer]: [ApplicationStatus.approved],
        [UserRole.accountingReviewer]: [ApplicationStatus.approved],
        [UserRole.fraudReviewer]: [ApplicationStatus.approved],
        [UserRole.admin]: [ApplicationStatus.inReview, ApplicationStatus.approved, ApplicationStatus.notApplicable],
    },
    [CampaignWorkflow.AccessControl]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
    [CampaignWorkflow.Reaffirm]: { ...EMPTY_PERMISSION_ROLE_MAPPING },
};
