import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Link,
    Multiselect,
    MultiselectProps,
    NonCancelableCustomEvent,
    Select,
    SelectProps,
    Spinner,
} from "@amzn/awsui-components-react-v3";
import React, { useContext, useEffect, useState } from "react";
import { ConfirmActionModal } from "src/components/ConfirmActions";
import KaleContext from "src/components/KaleContext";
import { useFetchDataStores } from "src/components/schema_table/transfer_tables/hooks";
import { TransferTablesCb, TransferTablesDSProps } from "src/components/survey/DataStoreInfo";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { TableRecord, TransferTableRequest } from "src/services/KaleTablesService";
import { kaleUrls } from "src/util/Urls";
import { TEST_IDS as SURVEY_TEST_IDS } from "shared/survey";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";

const { TRANSFER_TABLES } = SURVEY_TEST_IDS.DATA_STORE_INFO.SCHEMA_TABLE.ACTIONS;

export const TEST_IDS = {
    SPINNER: "transfer-tables-spinner",
    TABLE_CHOICES: "transfer-tables-table-choices",
    APP_CHOICE: "transfer-tables-app-choice",
    DS_CHOICE: "transfer-tables-ds-choice",
};

interface Props {
    currentAppName: string;
    appNames: string[];
    currentDSId: number;
    currentDSName: string;
    isAndesTechnology: boolean;
    tables: TableRecord[];
    isReadOnly: boolean;
    onTransferTablesCb: TransferTablesCb;
    displayMessage: DisplayMessageCb;
}

const TransferTables = (props: Props): JSX.Element => {
    const {
        currentAppName,
        appNames,
        currentDSId,
        currentDSName,
        isAndesTechnology,
        tables: tablesProps,
        isReadOnly,
        onTransferTablesCb,
        displayMessage,
    } = props;
    const { transferTables } = useContext(KaleContext).service.kaleTablesService;

    const { reviewId } = useAppParamsFromRoute();

    const [selectedTables, setSelectedTables] = useState<MultiselectProps.Options>([]);
    const tables: MultiselectProps.Options = tablesProps.map((table): MultiselectProps.Option => {
        return { label: table.name, value: table.id.toString() };
    });
    const [selectedAppName, setSelectedAppName] = useState<SelectProps.Option | null>({
        label: "",
        value: "",
    });
    const [selectedDataStore, setSelectedDataStore] = useState<SelectProps.Option | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { isLoadingDataStores, dataStores, fetchDataStoresError } = useFetchDataStores(
        selectedAppName?.value ?? "",
        isAndesTechnology,
        currentDSId
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onTablesSelectionChange = (
        event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>
    ): void => {
        setSelectedTables(event.detail.selectedOptions);
    };
    const onAppSelectionChange = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
        setSelectedAppName(event.detail.selectedOption);
        setSelectedDataStore(null);
    };
    const onDataStoreSelectionChange = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
        setSelectedDataStore(event.detail.selectedOption);
    };
    const clearSelection = (): void => {
        setSelectedDataStore(null);
        setIsModalVisible(false);
        setSelectedAppName({
            label: "",
            value: "",
        });
        setSelectedTables([]);
    };
    const onTransferClickEvent = async (): Promise<void> => {
        const dataStoreId = Number(selectedDataStore?.value ?? 0);
        if (dataStoreId === 0) {
            displayMessage(MessageType.error, "Invalid data store ID");
        }
        setIsLoading(true);
        const request: TransferTableRequest = {
            tableIds: selectedTables.map((table: MultiselectProps.Option): number => Number(table.value ?? 0)),
            applicationName: selectedAppName?.value ?? "",
            dataStoreId,
        };
        return transferTables(currentAppName, currentDSId, request)
            .then((): void => {
                const items: TransferTablesDSProps[] = selectedTables.map(
                    (table: MultiselectProps.Option): TransferTablesDSProps => {
                        return {
                            sourceDSId: currentDSId,
                            destDSId: dataStoreId,
                            tableId: Number(table.value),
                        };
                    }
                );
                onTransferTablesCb(items);
                const content: JSX.Element = (
                    <React.Fragment>
                        {`${selectedTables.length} table${selectedTables.length === 1 ? "" : "s"} `}
                        {`${selectedTables.length === 1 ? "has" : "have"} been transferred to `}
                        {`"${selectedDataStore?.label}" in `}
                        <Link
                            href={kaleUrls.editKaleRecordUrl(selectedAppName?.value ?? "", reviewId)}
                            external={true}
                            color={"inverted"}
                        >
                            {selectedAppName?.value}
                        </Link>
                    </React.Fragment>
                );
                displayMessage(MessageType.success, content);
            })
            .catch((err: Error): void => {
                displayMessage(MessageType.error, err.message);
            })
            .then((): void => {
                setIsLoading(false);
                clearSelection();
            });
    };

    useEffect((): void => {
        setIsLoading(isLoadingDataStores);
    }, [isLoadingDataStores]);

    const renderContent = (): JSX.Element => {
        return (
            <ColumnLayout>
                {isLoading && (
                    <Box textAlign="center">
                        <Spinner data-testid={TEST_IDS.SPINNER} size="normal" />
                    </Box>
                )}
                <FormField
                    label={
                        "Select table(s) to transfer to another data store " +
                        "within this application or to another application"
                    }
                >
                    <Multiselect
                        data-testid={TEST_IDS.TABLE_CHOICES}
                        selectedOptions={selectedTables}
                        placeholder="Select one or more tables"
                        empty="No tables found."
                        disabled={!Boolean(tables.length)}
                        options={tables}
                        filteringType={"auto"}
                        onChange={onTablesSelectionChange}
                    />
                </FormField>
                <FormField label={"Select application to transfer to"}>
                    <Select
                        data-testid={TEST_IDS.APP_CHOICE}
                        options={appNames.map((name): SelectProps.Option => ({ value: name, label: name }))}
                        selectedOption={selectedAppName}
                        disabled={!Boolean(appNames.length)}
                        placeholder={"Select the app name"}
                        empty={"No apps found"}
                        filteringType={"auto"}
                        onChange={onAppSelectionChange}
                    />
                </FormField>
                <FormField label={"Select data store to transfer to"} errorText={fetchDataStoresError}>
                    <Select
                        data-testid={TEST_IDS.DS_CHOICE}
                        options={dataStores}
                        selectedOption={selectedDataStore}
                        disabled={!Boolean(appNames.length)}
                        placeholder={"Select the data store"}
                        empty={"No data stores found"}
                        filteringType={"auto"}
                        onChange={onDataStoreSelectionChange}
                    />
                </FormField>
            </ColumnLayout>
        );
    };

    return (
        <React.Fragment>
            <Button
                data-testid={TRANSFER_TABLES.MODAL_TRIGGER_BUTTON}
                variant={"normal"}
                disabled={isReadOnly}
                onClick={(): void => {
                    setIsModalVisible(true);
                    setSelectedAppName({ label: currentAppName, value: currentAppName });
                }}
            >
                Transfer Tables
            </Button>
            <ConfirmActionModal
                testIds={{
                    cancelButton: TRANSFER_TABLES.MODAL_CANCEL_BUTTON,
                    confirmButton: TRANSFER_TABLES.MODAL_CONFIRM_BUTTON,
                    modalRoot: TRANSFER_TABLES.MODAL_ROOT,
                }}
                header={`Transfer Tables from "${currentDSName}"`}
                confirmButtonOverrides={{
                    disabled: !Boolean(selectedTables.length) || !Boolean(selectedDataStore),
                    label: "Transfer",
                }}
                size={"large"}
                visible={isModalVisible}
                onConfirm={onTransferClickEvent}
                onCancel={clearSelection}
            >
                {renderContent()}
            </ConfirmActionModal>
        </React.Fragment>
    );
};

export { TransferTables };
