import * as React from "react";
import { useCallback, useState } from "react";
import {
    Button,
    FormField,
    Grid,
    Input,
    InputProps,
    TokenGroupProps,
    NonCancelableCustomEvent,
} from "@amzn/awsui-components-react-v3";
import { Tags } from "src/components/fields/Tags";
import styled from "styled-components";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";

export const TEXTBOX_WITH_TAGS_BUTTON_TESTID = "textbox-with-tags-button";
export const TEXTBOX_WITH_TAGS_INPUT_TESTID = "textbox-with-tags-input";
export const TEXTBOX_WITH_TAGS_FORMFIELD_TESTID = "textbox-with-tags-input";

const RowContainer = styled.div`
    margin-top: 0;
`;

const ControlContainer = styled.div`
    width: 35vw;
`;

const InputContainer = styled.div`
    display: inline-block;
    width: 32vw;
`;

const ButtonContainer = styled.div`
    display: inline-block;
`;

export interface TextBoxWithTagsProps extends FunctionalSurveyItemProps<string[]> {
    readonly placeholder?: string;
}

const TextBoxWithTags = (props: TextBoxWithTagsProps): JSX.Element => {
    const {
        id,
        isReadonly = false,
        isFormInReadonlyMode = false,
        isRequired,
        placeholder = "",
        expectedAnswer = [],
        onChangeCallback,
    } = props;
    const isDisabled = isReadonly || isFormInReadonlyMode;

    const [inputValue, setInputValue] = useState<string>("");

    const handleOnAdd = useCallback((): void => {
        if (inputValue && !expectedAnswer.includes(inputValue)) {
            const tags = [...expectedAnswer, inputValue];
            onChangeCallback({
                id: id,
                response: tags,
            });
        }
        setInputValue("");
    }, [expectedAnswer, id, inputValue, onChangeCallback]);

    const handleOnRemove = useCallback(
        (items: string[]): void => {
            onChangeCallback({
                id: id,
                response: items,
            });
        },
        [id, onChangeCallback]
    );

    const onValueChange = useCallback((event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
        setInputValue(event.detail.value);
    }, []);

    return (
        <FormField
            data-testid={TEXTBOX_WITH_TAGS_FORMFIELD_TESTID}
            stretch={true}
            errorText={isRequired && !Boolean(expectedAnswer.length) ? "Required" : ""}
        >
            <Grid gridDefinition={[{ colspan: { default: 8 } }, { colspan: { default: 8 } }]}>
                <ControlContainer>
                    <InputContainer>
                        <Input
                            id={id}
                            data-testid={TEXTBOX_WITH_TAGS_INPUT_TESTID}
                            value={inputValue}
                            disabled={isDisabled}
                            onChange={onValueChange}
                            placeholder={placeholder}
                        />
                    </InputContainer>
                    <ButtonContainer>
                        <Button
                            data-testid={TEXTBOX_WITH_TAGS_BUTTON_TESTID}
                            iconName={"add-plus"}
                            variant={"icon"}
                            onClick={handleOnAdd}
                            disabled={isDisabled}
                        />
                    </ButtonContainer>
                </ControlContainer>
                <RowContainer>
                    <Tags
                        id={"data-storage-tags"}
                        items={expectedAnswer.map((item: string): TokenGroupProps.Item => {
                            return { label: item, disabled: isDisabled };
                        })}
                        onRemoveCallback={handleOnRemove}
                    />
                </RowContainer>
            </Grid>
        </FormField>
    );
};

export default withFunctionalReadonly(TextBoxWithTags);
