import React from "react";
import { Box, Button, Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";

const StyledBox = styled(Box)`
    margin-top: 2em;
`;

export class PageNotFound extends React.Component {
    public render(): JSX.Element {
        return (
            <StyledBox textAlign="center">
                <SpaceBetween id="fourohfour" size="xs">
                    <h1>Not Found.</h1>
                    <h3>This page doesn&apos;t exist.</h3>
                    <Link href="/">
                        <Button id="returnButton" variant="primary">
                            Head Back to Safety
                        </Button>
                    </Link>
                </SpaceBetween>
            </StyledBox>
        );
    }
}

export default PageNotFound;
