import React, { ReactNode } from "react";
import styled from "styled-components";
import { Button, ButtonProps, Icon, Popover, PopoverProps } from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens/polaris.js";

const Wrapper = styled.span`
    padding: 5px;
    padding-left: 10px;
`;

const StyledPopover = styled(Popover)`
    margin-right: 5px !important;
`;

const DisabledIcon = styled(Icon)`
    cursor: pointer !important;
    color: ${awsui.colorTextStatusInfo} !important;
    opacity: 75% !important;
`;

// AccessibleButtonProps is an intersection of the Polaris <Button> and <Popover> props,
// except we remove <Popover> "trigger" and "triggerType" props, and make the <Popover>
// "content" prop required.
export type PopoverForwardingProps = Omit<PopoverProps, "trigger" | "triggerType" | "content"> & {
    content: ReactNode;
};
// 'text' is currently an optional key on Button.Props, but it is deprecated.
// We still want it for <AccessibleButton> so preserve it in our typings.
export type ButtonForwardingProps = ButtonProps & {
    text?: string;
};
export interface AccessibleButtonProps {
    buttonProps: ButtonForwardingProps;
    popoverProps: PopoverForwardingProps;
}

/**
 * A component which pairs a Polaris Button with a Polaris popover and adds logic to display a popover trigger icon near
 * the button whenever the button is disabled to give extra context to a user via the message contained in the popover.
 * This is only an initial spike on the idea of accessibility and not necessarily the final solution. The historical
 * approach of displaying a tooltip when the disabled button receives a mouse hover event, would be less accessible.
 */
const AccessibleButton = (props: AccessibleButtonProps): JSX.Element => {
    const { buttonProps, popoverProps } = props;
    const { text, ...restButtonProps } = buttonProps;
    const { disabled } = restButtonProps;
    return (
        <Wrapper>
            {disabled && (
                <StyledPopover {...popoverProps} triggerType="custom">
                    <DisabledIcon name="status-info" alt="status-info" />
                </StyledPopover>
            )}
            <Button {...restButtonProps}>{text}</Button>
        </Wrapper>
    );
};

export default AccessibleButton;
