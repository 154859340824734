/* eslint-disable max-len */
import { useMemo } from "react";
import { findDataStoreAccessor } from "src/answers_legacy";
import { DataStoreHookAnswer, DataStoresAccessor, useDataStores } from "src/answers_legacy/hooks/dataStores";
import {
    generateCollectionsFromQuestionsData,
    QuestionCollections,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import {
    makeDataStoreInfoStep,
    makeDynamicQuestionSteps,
    makeDataStoreSummaryAndReviewStep,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/hooks/useMakeWizardSteps/make-wizard-steps";
import { DataStoreWizardStep, MakeWizardStepsOptions } from "./types";
import { WizardProps } from "@amzn/awsui-components-react-v3";
/* eslint-enable max-len */

export type IsStepApplicableCb = (stepIndex: number) => boolean | undefined;
export type UseMakeWizardStepsTuple = [WizardProps.Step[], IsStepApplicableCb];
export const useMakeWizardSteps = (options: MakeWizardStepsOptions, hasTafLayout: boolean): UseMakeWizardStepsTuple => {
    const { showModal, activeDataStore } = options;
    const [accessors] = useDataStores();
    const activeDataStoreAccessor = findDataStoreAccessor(accessors, activeDataStore.id, activeDataStore.unsavedUUID);

    // Populate data structures from questions data.
    const collections = useMemo((): QuestionCollections<DataStoreHookAnswer> => {
        return generateCollectionsFromQuestionsData<DataStoresAccessor, DataStoreHookAnswer>(activeDataStoreAccessor);
    }, [activeDataStoreAccessor]);

    const isStepApplicableCb: IsStepApplicableCb = (stepIndex): boolean | undefined => steps[stepIndex]?.shouldRender;

    const steps: DataStoreWizardStep[] =
        // Build steps for the Wizard when the its containing Modal is shown.
        showModal
            ? [
                  // The DataStoreInfo step goes first.
                  // If this is for TAF survey, we want to exclude the DataStoreInfo step
                  ...(hasTafLayout
                      ? []
                      : [makeDataStoreInfoStep(options, collections, activeDataStoreAccessor ?? null)]),
                  // Next add N dynamic steps for dynamic questions
                  ...makeDynamicQuestionSteps(options, collections, hasTafLayout),
                  // Summary review step goes last
                  makeDataStoreSummaryAndReviewStep(
                      options,
                      collections,
                      activeDataStoreAccessor ?? null,
                      hasTafLayout
                  ),
              ]
            : [];

    return [steps, isStepApplicableCb];
};
