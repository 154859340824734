import { FormField, Textarea } from "@amzn/awsui-components-react-v3";
import { useEffect, useState } from "react";
import * as React from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import Model from "src/components/constants/Constants";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";
import { KaleFormField } from "src/components/fields/KaleFormField";

interface Props extends FunctionalSurveyItemProps<string> {
    rows?: number;
    verticalLayout?: boolean;
}

const TextArea = (props: Props): JSX.Element => {
    const {
        id,
        isReadonly = false,
        isFormInReadonlyMode = false,
        isRequired = false,
        verticalLayout = false,
        expectedAnswer = "",
        placeholder = "",
        errorMsg = "",
        rows,
        onChangeCallback,
    } = props;
    const isDisabled = isReadonly || isFormInReadonlyMode;
    const defaultErrorMsg = !Boolean(expectedAnswer) ? "Required" : "";

    const [localValue, setLocalValue] = useState<string>(expectedAnswer);

    useEffect((): void => {
        setLocalValue(expectedAnswer);
    }, [expectedAnswer]);

    const data = Model.getData(id);
    const label = data.name;
    const description = applyTemplateToText(data.question, data.templateValues);
    const errorText = isRequired && (errorMsg || defaultErrorMsg);

    const content = (
        <Textarea
            id={id}
            value={localValue}
            rows={rows}
            disabled={isDisabled}
            readOnly={isDisabled}
            placeholder={placeholder}
            onChange={(e): void => setLocalValue(e.detail.value)}
            onBlur={(): void => {
                if (localValue !== expectedAnswer) {
                    onChangeCallback({
                        id: id,
                        response: localValue,
                    });
                }
            }}
        />
    );

    if (verticalLayout) {
        // The verticalLayout prop currently indicates that the FormField for this TextArea should use regular
        // FormField layout allowing its field body, the Textarea input itself, to take up the full horizontal space of
        // the containing element, rather than be a horizontal Field that is split across 3 columns

        return (
            <React.Fragment>
                <p>{description}</p>
                <FormField data-testid={`${id}-content`} stretch={true} errorText={errorText}>
                    {content}
                </FormField>
            </React.Fragment>
        );
    } else {
        return (
            <KaleFormField label={label} description={description} errorText={errorText} stretch={false}>
                {content}
            </KaleFormField>
        );
    }
};

export default withFunctionalReadonly(TextArea);
