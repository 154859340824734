import { PDCWarningModalAction, PDCWarningModalShown } from "src/services/RumService/rum-events";
import { PDCWarningModalProps } from "./types";
import { ModalAction } from "./constants";
import { useKaleServices } from "src/components/KaleContext";
import { useKaleApplicationEventMetadata } from "src/components/survey/KaleApplication/useKaleApplicationEventMetadata";

type ActionEventData = PDCWarningModalAction.Options["data"];
type UseEventCallbacksProps = Pick<PDCWarningModalProps, "actions">;
/**
 * Custom hook is responsible for returning event callbacks for the PDCWarningModal
 */
export const useEventCallbacks = ({ actions }: UseEventCallbacksProps): PDCWarningModalEventCallbacks => {
    const { rumService } = useKaleServices();
    const metadata = useKaleApplicationEventMetadata();

    return {
        onContinue(): void {
            const data: ActionEventData = { action: ModalAction.Continue };
            const event = PDCWarningModalAction.event({ data, metadata });
            rumService?.recordEvent(event);
            actions.onContinue();
        },
        onDismiss(): void {
            const data: ActionEventData = { action: ModalAction.Dismiss };
            const event = PDCWarningModalAction.event({ data, metadata });
            rumService?.recordEvent(event);
            actions.onDismiss();
        },
        onGoBack(): void {
            const data: ActionEventData = { action: ModalAction.GoBack };
            const event = PDCWarningModalAction.event({ data, metadata });
            rumService?.recordEvent(event);
            actions.onGoBack();
        },
        onShow(): void {
            const event = PDCWarningModalShown.event({ metadata });
            rumService?.recordEvent(event);
        },
    };
};

type PDCWarningModalEventCallbacks = PDCWarningModalProps["actions"] & { onShow: () => void };
