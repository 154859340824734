import { Link } from "@amzn/awsui-components-react-v3";
import React from "react";

interface UnRetrievableErrorMessageProps {
    pathname: string;
}
const UnRetrievableErrorMessage = ({ pathname }: UnRetrievableErrorMessageProps): JSX.Element => (
    <React.Fragment>
        <span>
            Unable to retrieve the requested application. Possible reasons for this include:
            <ul>
                <li>
                    You do not have permission to access this application. Please review this{" "}
                    <Link href="https://w.amazon.com/bin/view/Kale/ApplicationPermissions/" external>
                        guide
                    </Link>{" "}
                    for information on how to gain access.
                </li>
                <li>
                    The URL path name <code>{` ${pathname} `}</code> is invalid. Please verify that you are using the
                    correct URL.
                </li>
                <li>The application was deleted by a member of the application&apos;s owning team.</li>
            </ul>
        </span>
    </React.Fragment>
);

export default UnRetrievableErrorMessage;
