import { Link } from "@amzn/awsui-components-react-v3";
import React from "react";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { TEST_IDS } from "shared/survey";

export function submittingTeam(model: Model): Pair {
    const linkPrefix = "https://permissions.amazon.com/a/team/";
    let team = model.bindles.bindleInfo?.bindleToOwningTeam[model.application.controlBindle[0]];
    team = team ?? { id: "", name: "" };
    return {
        label: DisplayLabels.SubmittingTeam,
        value: (
            <Link
                data-testid={TEST_IDS.APPLICATION_IDENTITY.SUBMITTING_TEAM}
                external={true}
                href={linkPrefix + team.id}
            >
                {team.name}
            </Link>
        ),
    };
}
