import React from "react";
import { SpaceBetween, Alert } from "@amzn/awsui-components-react-v3";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import { getColumnDefinitions } from "./table-config";
import { usePDCFilters } from "./usePDCFilters";
import { PDCFindingsFromServer } from "src/components/survey/PersonalDataWizard/steps/personalDataClassifier/types";
import { PDCFilterName } from "src/components/survey/PersonalDataWizard/steps/personalDataClassifier/PDCStepContent";

const { PDC_STEP } = TEST_IDS.WIZARD;

export interface PDCStepContentProps {
    pdcFindingsFromServer: PDCFindingsFromServer;
    isLoading: boolean;
    personalDataClassifierHost: string;
    isShowingReaffirmCampaign: boolean;
}
export const PDCStepContent = ({
    pdcFindingsFromServer,
    isLoading,
    personalDataClassifierHost,
    isShowingReaffirmCampaign,
}: PDCStepContentProps): JSX.Element => {
    const initialFilter = isShowingReaffirmCampaign
        ? PDCFilterName.Confidence_91_Or_Greater
        : PDCFilterName.AllFindings;

    const { filterControls, filteredPDCFindings, entityFilterFn } = usePDCFilters(pdcFindingsFromServer, initialFilter);

    const columnDefinitions = getColumnDefinitions(entityFilterFn, personalDataClassifierHost);

    return (
        <SpaceBetween data-testid={PDC_STEP.CONTENT_ROOT} direction="vertical" size="l">
            {Boolean(pdcFindingsFromServer?.length) && (
                <Alert type="warning">
                    Based on the resources associated to your Bindle(s), we have detected the following instances of
                    personal data in your application.
                </Alert>
            )}
            <DASTable
                data-testid={PDC_STEP.PDC_TABLE.ROOT}
                id={"pdc-findings-table"}
                tableName={"Connected resources that process personal data"}
                isLoading={isLoading}
                hidePreferences={true}
                columnDefinitions={columnDefinitions}
                rowItems={filteredPDCFindings}
                emptyTextPrefix={"No resources"}
                headerReactNode={filterControls}
                filterProps={{ type: DASTableFilterType.externalCustomFilter }}
            />
        </SpaceBetween>
    );
};
