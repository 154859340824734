import { AnswerBase, HookAnswer, QuestionBase, QuestionChoice } from "src/answers_legacy";
import { useCallback, useMemo, useRef, useState } from "react";
import { makeOptionFromChoice, QuestionOption } from "src/services/dynamic-questions";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";

export type isExlusiveChangeCallBack = ({ response }: FunctionalSurveyItemResult<string[]>) => void;

/**
 * @param item - The multiselect item that will have is exlusive behavior attached to it
 * @return.options - the list of options after applying the logic of whether an exclusive option was selected.
 *                           If an exclusive option was selected, all other options are disabled.
 * @return.selectedOptions - the list of currently selected options.
 * @return.onChangeCallBack - an `onChange` function which processes the selected options after a user changes
 *                            their selection and produces an enhanced set of selectedOptions supporting
 *                            the isExclusiveBehavior.
 * @summary This function is a hook which uses the available options and the values of the selected ones to determine
 *          whether the exclusive option was selected. Then it defines the actions needed to be taken depending on that.
 *          This specific hook is meant only for with tags multi selects no the native polaris component.
 */

export const useIsExclusive = (
    item: HookAnswer<QuestionBase, AnswerBase>
): {
    onChangeCallBack: isExlusiveChangeCallBack;
    options: QuestionOption[];
    selectedOptions: string[];
} => {
    const { choices } = item.question;

    const findExclusiveOption = useFindExclusiveOptionCb(choices);
    const selectedOptions = (item.value as string[]) || [];

    const [selectedExclusiveOption, setSelectedExclusiveOption] = useState<string | undefined>((): string | undefined =>
        // Lazy init function
        findExclusiveOption(selectedOptions)
    );

    const deps = { choices };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const options = useMemo<QuestionOption[]>((): QuestionOption[] => {
        // Determine which options should appear as disabled in the dropdown menu
        const { choices } = dependenciesRef.current;
        return choices.map((choice): QuestionOption => {
            return {
                ...makeOptionFromChoice(choice),
                // While a choice marked `isExclusive: true` is selected, disable every other
                // choice in the dropdown
                disabled: Boolean(selectedExclusiveOption) && selectedExclusiveOption !== choice.value,
            };
        });
    }, [selectedExclusiveOption]);

    return {
        onChangeCallBack: ({ response: selectedOptionsFromCaller }: FunctionalSurveyItemResult<string[]>): void => {
            // Detect if a choice that had the "isExclusive" property was chosen. Upon detection,
            // unselect all other choice besides the exclusive one.
            const selectedExclusiveOption = findExclusiveOption(selectedOptionsFromCaller);
            const selectedOptions = selectedExclusiveOption ? [selectedExclusiveOption] : selectedOptionsFromCaller;

            // Update states
            item.setValue(selectedOptions);
            setSelectedExclusiveOption(selectedExclusiveOption);
        },
        options,
        selectedOptions,
    };
};

export type FindExclusiveOption = (options: string[]) => string | undefined;
/**
 * @param questionChoices - The list of QuestionChoice[] belonging to the question.
 * @return a callback function to look through a list of option values and return the first value that maps
 * to a QuestionChoice that is configured with the `isExclusive` property set to true.
 */
const useFindExclusiveOptionCb = (questionChoices: QuestionChoice[]): FindExclusiveOption => {
    // Static state value created only during initial render
    const [exclusiveOptionsMap] = useState<ExclusiveOptionsRecord>(
        // Lazy init function
        (): ExclusiveOptionsRecord => makeExclusiveOptionsMap(questionChoices)
    );

    return useCallback<FindExclusiveOption>(
        (options: string[]): string | undefined => options.find((value): boolean => exclusiveOptionsMap.has(value)),
        [exclusiveOptionsMap]
    );
};

export type ExclusiveOptionsRecord = Map<string, boolean>;
/**
 * @return a map to perform quick lookup about whether a choice is configured with the
 * isExclusive property set to true.
 */
const makeExclusiveOptionsMap = (choices: QuestionChoice[]): Map<string, boolean> =>
    choices.reduce<ExclusiveOptionsRecord>((exclusiveOptions, choice): ExclusiveOptionsRecord => {
        if (choice.isExclusive) {
            exclusiveOptions.set(choice.value, true);
        }
        return exclusiveOptions;
    }, new Map());
