import React from "react";
import ReactDOM from "react-dom";
import fetchIntercept from "fetch-intercept";
import { Provider } from "react-redux";

import App from "./components/App";
import { getStageConfig, StageConfig } from "src/Config";
import store from "src/redux/store";
import "./index.scss";
// Importing global-styles to offer full cloudscape styles support (styles, fonts, utils, etc)
// https://cloudscape.aws.dev/get-started/integration/global-styles/
import "@amzn/awsui-global-styles/polaris.css";

const container = document.getElementById("root");

// @ts-ignore
// Pass the bundled allowlist nonce ID to be passed to libraries that generate style/script injections
__webpack_nonce__ = process.env.KALE_CSP_NONCE_ID;

// In order to connect to the backend locally (physically running on your laptop)
// we need to provide the authorizer-email header so the backend has access to
// the users alias. This is typically handled by API gateway, but when working
// locally there is no interaction with API gateway.
//
// By using the fetch interceptor package we can easily attach this header
// onto all our uses of fetch without having to go through each fetch and
// adding it there manually.
getStageConfig()
    .then((stageConfig: StageConfig): void => {
        if (stageConfig.userName) {
            fetchIntercept.register({
                request(request: any, config: any): Promise<any[]> | any[] {
                    // requests being made to our backend come in as a string url
                    // but there is no guarantee for other requests that this is true
                    // so adding a check here to only do this when the request is a string
                    if (typeof request === "string" && request.includes("localhost:8080/api")) {
                        config.headers = {
                            ...config.headers,
                            "x-amzn-authorizer-email": stageConfig.userName,
                        };
                    }
                    return [request, config];
                },
            });
        }
    })
    .catch((err): void => console.error(err));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    container
);
