import { PDCFindingsFromServer } from "../../types";
import React, { useState } from "react";
import { PDCFilters } from "./PDCFilters";
import { PDCFinding } from "src/services/NodeKaleApplicationService";
import { usePDCFilterGroups } from "./usePDCFilterGroups";
import { PDCFilterName } from "../constants";
import { EntityFilterFn, entityFilters } from "./entity-filters";

export type PDCFiltersResult = {
    /** UI controls to filter the PDCFindings */
    filterControls: JSX.Element | null;
    /** The list of PDCFindings returned by the current filter. */
    filteredPDCFindings: PDCFinding[];
    /**
     * An entityType filter function corresponding to the current selected PDC filter. Use this when rendering
     * FoundEntityTypes belonging to a PDCFinding in order to determine which EntityTypes meet the filter criteria
     */
    entityFilterFn: EntityFilterFn;
};

export const usePDCFilters = (pdcFindings: PDCFindingsFromServer, initialFilter: PDCFilterName): PDCFiltersResult => {
    const filterGroups = usePDCFilterGroups(pdcFindings);

    const [selectedFilter, setSelectedFilter] = useState<PDCFilterName>(initialFilter);

    const filterControls = pdcFindings ? (
        <PDCFilters filterGroups={filterGroups} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
    ) : null;

    const filteredPDCFindings = filterGroups[selectedFilter];

    const entityFilterFn = entityFilters[selectedFilter];

    return { filterControls, filteredPDCFindings, entityFilterFn };
};
