import React, { useState } from "react";
import { PersonalDataWizardProps } from "src/components/survey/PersonalDataWizard/types";
import { PDCWarningModal } from "src/components/survey/PersonalDataWizard/usePDCWarningModal/PDCWarningModal";

export const usePDCWarningModal = ({
    setIsPDWComplete,
    setPdwStepIndex,
    questionStepIndex,
}: UsePDCWarningModalProps): PDCWarningModalResult => {
    const [isPDCWarningModalVisible, setIsPDCWarningModalVisible] = useState(false);

    const modalActions = {
        onContinue: () => {
            setIsPDWComplete(true);
            setIsPDCWarningModalVisible(false);
        },
        onGoBack: () => {
            setPdwStepIndex(questionStepIndex);
            setIsPDCWarningModalVisible(false);
        },
        onDismiss: () => {
            setIsPDCWarningModalVisible(false);
        },
    };

    const showPDCWarningModalCb = () => setIsPDCWarningModalVisible(true);

    return {
        pdcWarningModal: <PDCWarningModal actions={modalActions} visible={isPDCWarningModalVisible} />,
        showPDCWarningModalCb,
    };
};

type UsePDCWarningModalProps = Pick<PersonalDataWizardProps, "setIsPDWComplete" | "setPdwStepIndex"> & {
    /** The index of the PersonalDataWizard's Personal-Data-Statement Question step */
    questionStepIndex: number;
};
interface PDCWarningModalResult {
    pdcWarningModal: JSX.Element;
    /** A callback function that will trigger the Modal to show  */
    showPDCWarningModalCb: () => void;
}
