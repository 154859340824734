import React, { useState } from "react";
import {
    Box,
    CollectionPreferences,
    CollectionPreferencesProps,
    Grid,
    Header,
    Pagination,
    PropertyFilter,
    Table,
    TableProps,
    TextFilter,
} from "@amzn/awsui-components-react-v3";
import { useDASTableCollection } from "./useDASTableCollection";
import { getNodeText } from "./utils";
import {
    DASTableFilterType,
    DASTableNativeFilterProps,
    DASTableProps,
} from "src/components/fields/table/DASTable/types";

export const TEST_IDS = {
    TABLE_PREFERENCES: "das-table-preferences",
    TABLE_DESCRIPTION: "das-table-description",
};

const DEFAULT_PAGE_SIZE = 10;

const PAGE_SIZES = [DEFAULT_PAGE_SIZE, 25, 50, 100, 500];

const DEFAULT_FILTERING_PLACEHOLDER = "Search";

const headerLabel = (title: React.ReactNode, sorted: boolean, descending: boolean): string => {
    return `${title}, ${sorted ? `sorted ${descending ? "descending" : "ascending"}` : "not sorted"}.`;
};

function getEmptyState<T>({ emptyTextPrefix, emptyTemplateElement }: DASTableProps<T>): JSX.Element {
    const text = emptyTextPrefix ?? "No items";
    return (
        <Box textAlign="center" color="inherit">
            <b>{text}</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                {text} to display.
            </Box>
            <Box textAlign="center" color="inherit">
                {emptyTemplateElement && <div>{emptyTemplateElement}</div>}
            </Box>
        </Box>
    );
}

/**
 * Adds an ariaLabel property to each columnDefinition in the list. The aria label assigned to each column will be a
 * reflection of that column's present "sorted state".
 * @param columns - the list of column definitions
 */
function addColumnSortLabels<T>(columns: TableProps.ColumnDefinition<T>[]): TableProps.ColumnDefinition<T>[] {
    return columns.map(
        (col: TableProps.ColumnDefinition<T>): TableProps.ColumnDefinition<T> => ({
            ariaLabel: col.sortingField
                ? (sortState: TableProps.LabelData): string =>
                      headerLabel(col.header, sortState.sorted, sortState.descending)
                : undefined,
            ...col,
        })
    );
}

function getHeader<T>(
    { tableName, tableDescription, renderActionStripe: renderActionStripeProp, headerReactNode }: DASTableProps<T>,
    itemsCount: number,
    filteredItemsCount?: number,
    selectedItemsCount?: number
): JSX.Element {
    return (
        <div>
            <Grid gridDefinition={[{ colspan: { default: 9 } }, { colspan: { default: 3 } }]}>
                <div>
                    <Header
                        variant="h2"
                        counter={
                            selectedItemsCount
                                ? `(${selectedItemsCount}/${filteredItemsCount})`
                                : `(${filteredItemsCount || itemsCount})`
                        }
                        description={
                            <React.Fragment>
                                {headerReactNode}
                                {tableDescription}
                            </React.Fragment>
                        }
                    >
                        {tableName}
                    </Header>
                </div>
                <div style={{ float: "right" }}>{renderActionStripe("header", true, renderActionStripeProp)}</div>
            </Grid>
        </div>
    );
}

const renderActionStripe = (
    id: string,
    hasDelete?: boolean,
    renderActionStripeProp?: (id: string, hasDelete?: boolean | undefined) => JSX.Element
): JSX.Element | null => {
    if (renderActionStripeProp) {
        return renderActionStripeProp(id, hasDelete);
    }
    return null;
};

const pageSizeOptions = PAGE_SIZES.map((count: number): CollectionPreferencesProps.PageSizeOption => {
    return { value: count, label: `${count} items` };
});

// Renders the Polaris table and handles filtering, pagination, collection sorting, and visual treatments for labels
// and empty states for the table and owns preferences modal.
function DASTable<T>(props: DASTableProps<T>): JSX.Element {
    const {
        "data-testid": testId,
        columnDefinitions: columnDefinitionsProp,
        emptyTableMessage,
        filterProps,
        hideContentSelector,
        hidePreferences,
        isLoading,
        isResizable,
        isStickyHeader,
        onSelectionChange: onSelectionChangeProp,
        stripedRows,
        rowItems,
        selectionType,
        trackBy,
        ...rest
    } = props;

    const columnDefinitions = addColumnSortLabels(columnDefinitionsProp);

    const visibleContent = columnDefinitions.map((column: TableProps.ColumnDefinition<T>): string => column.id ?? "");

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: DEFAULT_PAGE_SIZE,
        visibleContent,
        wrapLines: true,
    });

    const {
        items,
        filteredItemsCount,
        textFilterComponentProps,
        collectionProps,
        propertyFilterComponentProps,
        paginationProps,
    } = useDASTableCollection<T>({
        columnDefinitions,
        rowItems,
        preferences,
        filterProps,
        trackBy,
    });

    // header in columnDefinition for dynamic questions can be a JSX element
    // and in such case we should extract the string content of header from
    // the server response. However, for static questions we know for sure that
    // header is of a simple string type. That's the reason we get values
    // from both column definition and visibleContentPreference.
    const contentSelector = [
        {
            label: "Visible content",
            options: columnDefinitions.map(
                (column: TableProps.ColumnDefinition<T>): CollectionPreferencesProps.VisibleContentOption => {
                    return { id: `${column.id}`, label: `${getNodeText(column.header as React.ReactChild)}` };
                }
            ),
        },
    ];

    const filteringPlaceholder =
        (filterProps as DASTableNativeFilterProps<unknown>)?.placeholder ?? DEFAULT_FILTERING_PLACEHOLDER;

    return (
        <Table
            {...rest}
            {...collectionProps}
            data-testid={testId}
            filter={
                filterProps?.type === DASTableFilterType.propertyFilter ? (
                    <PropertyFilter
                        {...propertyFilterComponentProps}
                        filteringOptions={
                            filterProps?.internalOverrides?.filteringOptions ||
                            propertyFilterComponentProps.filteringOptions
                        }
                        virtualScroll={true}
                        tokenLimit={5}
                        i18nStrings={{
                            filteringAriaLabel: "your choice",
                            dismissAriaLabel: "Dismiss",
                            filteringPlaceholder,
                            groupValuesText: "Values",
                            groupPropertiesText: "Properties",
                            operatorsText: "Operators",
                            operationAndText: "and",
                            operationOrText: "or",
                            operatorLessText: "Less than",
                            operatorLessOrEqualText: "Less than or equal",
                            operatorGreaterText: "Greater than",
                            operatorGreaterOrEqualText: "Greater than or equal",
                            operatorContainsText: "Contains",
                            operatorDoesNotContainText: "Does not contain",
                            operatorEqualsText: "Equals",
                            operatorDoesNotEqualText: "Does not equal",
                            editTokenHeader: "Edit filter",
                            propertyText: "Property",
                            operatorText: "Operator",
                            valueText: "Value",
                            cancelActionText: "Cancel",
                            applyActionText: "Apply",
                            allPropertiesLabel: "All properties",
                            tokenLimitShowMore: "Show more",
                            tokenLimitShowFewer: "Show fewer",
                            clearFiltersText: "Clear filters",
                            removeTokenButtonAriaLabel: (): string => "Remove token",
                            enteredTextLabel: (text): string => `Use: "${text}"`,
                        }}
                    />
                ) : filterProps?.type === DASTableFilterType.textFilter ? (
                    <TextFilter {...textFilterComponentProps} filteringPlaceholder={filteringPlaceholder} />
                ) : null
            }
            pagination={<Pagination {...paginationProps} />}
            loading={isLoading}
            loadingText="Loading resources"
            columnDefinitions={columnDefinitions}
            visibleColumns={preferences.visibleContent}
            items={items}
            stripedRows={stripedRows || false}
            resizableColumns={isResizable || false}
            wrapLines={preferences.wrapLines}
            stickyHeader={isStickyHeader || false}
            header={getHeader(props, items.length, filteredItemsCount, collectionProps.selectedItems?.length)}
            trackBy={collectionProps.trackBy}
            selectedItems={collectionProps.selectedItems}
            onSelectionChange={(e): void => {
                collectionProps.onSelectionChange?.(e);
                onSelectionChangeProp?.(e);
            }}
            empty={items.length === 0 && emptyTableMessage ? emptyTableMessage : getEmptyState(props)}
            selectionType={selectionType}
            preferences={
                !hidePreferences && (
                    <CollectionPreferences
                        title={"Preferences"}
                        confirmLabel={"Confirm"}
                        cancelLabel={"Cancel"}
                        preferences={preferences}
                        onConfirm={({ detail }): void => setPreferences(detail)}
                        pageSizePreference={{
                            title: "Page size",
                            options: pageSizeOptions,
                        }}
                        wrapLinesPreference={{
                            label: "Wrap lines",
                            description: "Enable to wrap table cell content, disable to truncate text.",
                        }}
                        visibleContentPreference={
                            !hideContentSelector
                                ? {
                                      title: "Select visible columns",
                                      options: contentSelector,
                                  }
                                : undefined
                        }
                    />
                )
            }
        />
    );
}

export default DASTable;
