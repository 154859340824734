import React, { useCallback, useMemo, useState } from "react";
import {
    AppLayout,
    Box,
    BreadcrumbGroupProps,
    Button,
    ButtonProps,
    Container,
    Grid,
    Header,
    Link,
    SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import { FETCH_TAF_ERROR, ReviewAttributeShortId } from "src/components/TAF/TAFDetails/constants";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";
import { DataStoreWizard } from "src/components/survey/DataStoreInfo/DataStoreWizard";
import { INITIAL_WIZARD_ACTIVE_STEP_INDEX } from "src/components/survey/DataStoreInfo/contants";
import { TAFDataStores } from "src/components/TAF/TAFDetails/TAFDataStores";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { TAFDecisionTable } from "src/components/TAF/TAFDetails/TAFDecisionTable";
import { DATA_USAGE_LEGAL_POLICY, TAFFinalDecisionTable } from "src/components/TAF/TAFDetails/TAFFinalDecisionTable";
import { Can, selectResponseApplicationStatusesByRole, UserAction, UserRole } from "src/permissions";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import {
    ApplicationStatus,
    Flag,
    ReviewResponse,
    selectAccountingApprovalStatus,
    selectFraudApprovalStatus,
    selectLegalApprovalStatus,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import { useDataStoresStash, useSaveCallbacks, useTAFDetailsSelectors } from "src/components/TAF/TAFDetails/hooks";
import { CombinedAccessorUnionType, DataStoreAnswerWithQuestion, useDataStores, useReview } from "src/answers_legacy";
import styled from "styled-components";
import { selectReadonlyAccessorByLookupId } from "src/answers_legacy/selectors";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { KaleQuestion, ReviewGroupsMap } from "src/services/KaleApplicationService";
import { AnswerInput, StartAssessmentIntro } from "src/components/TAF/TAFDetails/components";
import { DataStoreDecisions } from "src/components/TAF/TAFDetails/components/DataStoreDecisions";
import AppNameTextBox from "src/components/fields/textbox/AppNameTextBox";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import AccessibleButton from "src/components/buttons/AccesibleButton";
import { LineageFindings } from "src/components/TAF/FinancialGRE/components/LineageFindings";
import { useFetchBindles } from "src/components/survey/hooks/useFetchBindles";
import { useDispatch } from "react-redux";
import { ActionType } from "src/components/survey/state/actions";
import { useFetchAAAIds } from "src/components/survey/hooks/useFetchAAAIds";
import ApprovalStatuses from "src/components/ApprovalStatuses/ApprovalStatuses";
import { formatPageError } from "src/components/TAF/TAFDetails/utils";

const renderBreadcrumbs = (applicationName: string, reviewId: string): JSX.Element => {
    const items: BreadcrumbGroupProps.Item[] = [
        BreadcrumbItems.KALE_LANDING_PAGE,
        BreadcrumbItems.editKaleRecord(applicationName, reviewId),
        BreadcrumbItems.editKaleTafRecord(applicationName, reviewId),
    ];

    return <KaleBreadcrumbGroup ariaLabel="Breadcrumbs" items={items} />;
};

const DescriptionLabel = styled.div`
    text-align: right;
`;

const FormActions = styled.div`
    text-align: right;
    & > * {
        margin-right: 10px;
    }
`;

const ButtonWithReadOnly = withFunctionalReadonly(
    (props: ButtonProps & FunctionalSurveyItemProps<string[]>): JSX.Element => {
        return (
            <Button {...props} disabled={props.isFormInReadonlyMode}>
                {props.children}
            </Button>
        );
    }
);

const selectApprovalStatuses = (response: SurveyResponse): { [k: string]: ApplicationStatus } => {
    const { appInfo } = response;

    const legalStatus = selectLegalApprovalStatus(appInfo).status;
    const accountingStatus = selectAccountingApprovalStatus(appInfo).status;
    const fraudStatus = selectFraudApprovalStatus(appInfo).status;

    return {
        legalStatus,
        accountingStatus,
        fraudStatus,
    };
};

const renderApplicationStatuses = (response: SurveyResponse): JSX.Element => {
    const { legalStatus, accountingStatus, fraudStatus } = selectApprovalStatuses(response);
    return (
        <ApprovalStatuses privacyStatus={legalStatus} accountingStatus={accountingStatus} fraudStatus={fraudStatus} />
    );
};

export const TAFAppLayout = (props: {
    applicationName: string;
    userRole: UserRole;
    detectedTafData: boolean;
    displayMessage: DisplayMessageCb;
    isTafReviewer: boolean;
    kaleQuestion: KaleQuestion;
    retentionRecommended: boolean;
    reviewGroupsMap: ReviewGroupsMap;
    tafDetailsFromServer: SurveyResponse;
    originalDataStoreDecisions: DataStoreResponse[];
    originalAssignedReviewGroups: string[];
    setTAFDetailsFromServer: (tafDetails: SurveyResponse | null) => void;
}): JSX.Element | null => {
    const {
        applicationName,
        userRole,
        detectedTafData,
        displayMessage,
        isTafReviewer,
        kaleQuestion,
        retentionRecommended,
        reviewGroupsMap,
        originalDataStoreDecisions,
        originalAssignedReviewGroups,
        tafDetailsFromServer,
        setTAFDetailsFromServer,
    } = props;

    const { historicalFinancialDecisions = [] } = tafDetailsFromServer.appInfo.review;

    // Selecting data store is accomplished by stashing the data store's lookup id (uuid or id)
    // which then is used to select the matching accessor in global state
    const [selectedDataStoreLookupId, setSelectedDataStoreLookupId] = useState<number | string>(0);

    const [dataStoreDrawerOpen, setDataStoreDrawerOpen] = useState(false);
    const [wizardVisible, setWizardVisible] = useState<boolean>(false);

    const [reviewAccessor] = useReview();
    const [dataStoreAccessors, { updateAnswers: updateDataStoreAnswers }] = useDataStores();

    const {
        stash: dataStoreAccessorsStash,
        setStash,
        deletionObligationQuestions,
    } = useDataStoresStash({
        tafDetailsFromServer,
        dataStoreAccessors,
    });

    // Get the bindle/aaa information from the current application and update the redux store

    const bindles = useFetchBindles(
        tafDetailsFromServer.appInfo.controlBindle,
        tafDetailsFromServer.appInfo.review.relatedBindles
    );
    const { aaaIdsOptions } = useFetchAAAIds(bindles.controlBindle, bindles.relatedBindles);

    const dispatch = useDispatch();
    dispatch({
        type: ActionType.ApplicationInfoUpdate,
        payload: {
            control_bindle: bindles.controlBindle,
            related_bindles: bindles.relatedBindles,
            aaaIds: aaaIdsOptions,
        },
    });

    const [deletionObligationQuestion] = deletionObligationQuestions;

    const {
        selectReview,
        selectDataStoreResponseByHookAnswer,
        selectHasApplicationQuestions,
        selectApplicableDataStores,
        selectAssignedReviewerQuestion,
    } = useTAFDetailsSelectors({
        tafDetailsFromServer,
        dataStoreAccessors,
    });

    const handleTAFDecisionChange = useCallback(
        (dataStore: DataStoreResponse, surveyResponse: SurveyResponse | null = tafDetailsFromServer): void => {
            const dataStoreIndex =
                surveyResponse?.appInfo.review.dataStores.findIndex((serverDataStore): boolean => {
                    return serverDataStore.id === dataStore.id;
                }) ?? -1;
            if (surveyResponse === null || dataStoreIndex < 0) {
                return;
            }
            const dataStores = surveyResponse ? surveyResponse.appInfo.review.dataStores.slice() : [];
            dataStores[dataStoreIndex] = dataStore;
            setTAFDetailsFromServer({
                ...surveyResponse,
                appInfo: {
                    ...surveyResponse.appInfo,
                    review: {
                        ...surveyResponse.appInfo.review,
                        dataStores,
                    },
                },
            });
        },
        [setTAFDetailsFromServer, tafDetailsFromServer]
    );

    const {
        showErrors,
        saveSurvey,
        rejectSurvey,
        recallSurvey,
        saveFeedback,
        saveAssignedReviewGroups,
        isSaveRequesting,
        isSubmitRequesting,
        isRejectRequesting,
        isRecallRequesting,
        isAssignedReviewGroupsRequesting,
        isSubmitFeedbackRequesting,
    } = useSaveCallbacks({
        userRole,
        dataStoreAccessors,
        displayMessage,
        reviewAccessor,
        tafDetailsFromServer,
        originalDataStoreDecisions,
        originalAssignedReviewGroups,
        reviewGroupsMap,
        kaleQuestion,
        handleTAFDecisionChange,
        setTAFDetailsFromServer,
    });

    const applicableDataStores = selectApplicableDataStores();

    const review = selectReview();

    const isTafApproved = useMemo((): boolean => {
        if (!tafDetailsFromServer) {
            return false;
        }
        return selectResponseApplicationStatusesByRole(tafDetailsFromServer, userRole).includes(
            ApplicationStatus.approved
        );
    }, [tafDetailsFromServer, userRole]);

    const isTafReadOnly = useMemo((): boolean => {
        return (!isTafReviewer || isTafApproved) && userRole !== UserRole.admin;
    }, [userRole, isTafApproved, isTafReviewer]);

    const renderAssignReviewGroups = (): JSX.Element | null => {
        const answer = selectAssignedReviewerQuestion(reviewAccessor);

        if (!answer) {
            return null;
        }

        return (
            <React.Fragment>
                <Container
                    header={
                        <Header variant="h2" description={applyTemplateToText(answer.question.subtext)}>
                            <span>{answer.question.content}</span>
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="xl">
                        <AnswerInput
                            answer={answer}
                            isReadOnly={isTafReadOnly}
                            isTafReviewer={isTafReviewer}
                            showErrors={showErrors}
                        />
                        <ButtonWithReadOnly
                            id={"taf-notify-review-groups"}
                            isReadonly={isTafReadOnly}
                            onChangeCallback={(): void => {}}
                            loading={isAssignedReviewGroupsRequesting}
                            variant="primary"
                            formAction={"none"}
                            onClick={async (): Promise<void> => await saveAssignedReviewGroups()}
                        >
                            Notify Teams
                        </ButtonWithReadOnly>
                    </SpaceBetween>
                </Container>
            </React.Fragment>
        );
    };

    const saveButton: JSX.Element = (
        <ButtonWithReadOnly
            id={"taf-save-draft"}
            onChangeCallback={(): void => {}}
            loading={isSaveRequesting}
            variant="normal"
            formAction={"none"}
            onClick={async (): Promise<void> => await saveSurvey()}
        >
            Save Draft
        </ButtonWithReadOnly>
    );

    const submitButton: JSX.Element = ((): JSX.Element => {
        const { legalStatus } = selectApprovalStatuses(tafDetailsFromServer);

        const shouldDisable: boolean = legalStatus !== ApplicationStatus.approved;

        const submitLabel = "Submit for Financial Review";
        return shouldDisable ? (
            <AccessibleButton
                buttonProps={{
                    text: submitLabel,
                    variant: "primary",
                    disabled: true,
                }}
                popoverProps={{
                    position: "top",
                    size: "large",
                    dismissButton: false,
                    content: <p>{`Your legal review must be approved before you can submit for Financial Review.`}</p>,
                }}
            />
        ) : (
            <ButtonWithReadOnly
                id={"taf-submit-review"}
                onChangeCallback={(): void => {}}
                loading={isSubmitRequesting}
                variant="primary"
                formAction={"none"}
                onClick={async (): Promise<void> => await saveSurvey(true)}
            >
                {submitLabel}
            </ButtonWithReadOnly>
        );
    })();

    const recallButton: JSX.Element = (
        <ButtonWithReadOnly
            id={"taf-recall"}
            isReadonly={false}
            onChangeCallback={(): void => {}}
            loading={isRecallRequesting}
            variant="normal"
            formAction={"none"}
            onClick={async (): Promise<void> => await recallSurvey()}
        >
            Recall
        </ButtonWithReadOnly>
    );

    const historicalDataStoreDecisionsButton: JSX.Element = (
        <ButtonWithReadOnly
            id={"taf-historical-datastore-decisions"}
            isReadonly={false}
            onChangeCallback={(): void => {}}
            onClick={(): void => setDataStoreDrawerOpen(true)}
        >
            Historical Data Store Decisions
        </ButtonWithReadOnly>
    );

    const rejectButton: JSX.Element = (
        <ButtonWithReadOnly
            id={"taf-reject"}
            isReadonly={false}
            onChangeCallback={(): void => {}}
            loading={isRejectRequesting}
            variant="normal"
            formAction={"none"}
            onClick={async (): Promise<void> => await rejectSurvey()}
        >
            Reject
        </ButtonWithReadOnly>
    );

    const submitFeedbackButton: JSX.Element = (
        <Button
            id={"taf-submit-feedback"}
            loading={isSubmitFeedbackRequesting}
            variant="primary"
            formAction={"none"}
            onClick={async (): Promise<void> => await saveFeedback()}
        >
            Submit feedback
        </Button>
    );

    const renderRetention = (review: ReviewResponse | undefined): JSX.Element | null => {
        const retentionPeriods: JSX.Element[] = [];
        if (review?.flags?.includes(Flag.accounting)) {
            retentionPeriods.push(
                <div>
                    <Box variant="p">
                        <b>
                            Data used to perform tax and accounting activities, meet requirements which includes
                            requirements related to customer activities that are necessary necessary to meet obligations
                            to sellers, vendors, etc.
                        </b>
                    </Box>
                    <Box variant="p">
                        10 years following the tax period (or, if separate, accounting period) (e.g., year) to which the
                        data relates.
                    </Box>
                    <Box variant="p">
                        <em>
                            Note: For any non-transactional information used for tax or accounting audits (e.g.,
                            customer name, customer billing address, and delivery address), 10 years following receipt
                            of ACDD Request.
                        </em>
                    </Box>
                </div>
            );
        }
        if (review?.flags?.includes(Flag.ctps)) {
            retentionPeriods.push(
                <div>
                    <Box variant="p">
                        <b>
                            Data used to prevent fraud or evaluate credit risks: Data associated with active customer
                            accounts, where data is expected to be useful to detect or prevent future misconduct as
                            determined by the business line lawyer.
                        </b>
                    </Box>
                    <Box variant="p">2 years following Deletion Request.</Box>
                    <Box variant="p">
                        <b>
                            Data used to prevent fraud or evaluate credit risks: Any data associated with a customer
                            account where Amazon has determined: (i) the account has been used for fraud or abuse, or
                            (ii) the account has a high risk for future abuse, and iii) the data is useful to detect or
                            prevent future misconduct
                        </b>
                    </Box>
                    <Box variant="p">
                        No maximum use period if data is likely to assist detection or prevention of future fraud and
                        abuse. Data should be deleted when not realistically contributing to this use case.
                    </Box>
                </div>
            );
        }
        retentionPeriods.push(
            <em>
                These usages were taken from the{" "}
                <Link external href={DATA_USAGE_LEGAL_POLICY}>
                    Data Use Standard
                </Link>
                . See our{" "}
                <Link
                    external
                    href={"https://w.amazon.com/bin/view/CDOPrivacy/DeletionGuidance/PrivacyDeveloperGuidance/"}
                >
                    Privacy Developer Guidance
                </Link>{" "}
                for best practices to address deletion requirements.
            </em>
        );
        return <React.Fragment>{retentionPeriods}</React.Fragment>;
    };

    // Opens edit modal of a given field row
    const openWizard = (lookupId: number): void => {
        setSelectedDataStoreLookupId(lookupId);
        setWizardVisible(true);
    };

    // Closes edit modal and handles resetting to original answers on cancel
    const closeWizard = (hasCancel = false): void => {
        if (hasCancel) {
            if (dataStoreAccessorsStash) {
                const stashedDataStore = selectReadonlyAccessorByLookupId(
                    // NOTE: Re-visit data stores accessor typing in CombinedAccessorUnionType
                    dataStoreAccessorsStash as CombinedAccessorUnionType[],
                    selectedDataStoreLookupId
                );

                if (stashedDataStore) {
                    updateDataStoreAnswers(
                        stashedDataStore.answers.map((answer): DataStoreAnswerWithQuestion => answer.answerWithQuestion)
                    );
                }
            }
        }

        setSelectedDataStoreLookupId(0);
        setWizardVisible(false);
    };

    const saveSelectedAccessor = (): void => {
        setStash(dataStoreAccessors);
        closeWizard();
    };

    // In the case that review ID comes back as null, we'll display an error message
    if (!review?.id) {
        const fetchTAFError = formatPageError(FETCH_TAF_ERROR, "ID undefined for review");
        displayMessage(MessageType.error, fetchTAFError);
        return null;
    }

    return (
        <AppLayout
            breadcrumbs={renderBreadcrumbs(applicationName, `${review.id}`)}
            navigationHide={true}
            toolsHide={!dataStoreDrawerOpen}
            toolsOpen={dataStoreDrawerOpen}
            toolsWidth={800}
            tools={<DataStoreDecisions decisions={historicalFinancialDecisions} />}
            onToolsChange={(event): void => setDataStoreDrawerOpen(event.detail.open)}
            headerSelector="#top-nav"
            content={
                <SpaceBetween direction="vertical" size="xl">
                    <StartAssessmentIntro review={review} detectedTafData={detectedTafData} />

                    {detectedTafData && <LineageFindings />}
                    {detectedTafData && (
                        <Container
                            header={
                                <Header variant="h2">
                                    <span>Application Information</span>
                                </Header>
                            }
                        >
                            {renderApplicationStatuses(tafDetailsFromServer)}
                            <AppNameTextBox
                                id={"applicationName"}
                                isRequired={false}
                                isReadonly={true}
                                errorMsg={""}
                                expectedAnswer={tafDetailsFromServer.appInfo.applicationName}
                                onChangeCallback={(): void => {}}
                            />

                            {reviewAccessor.answers
                                .filter(
                                    (answer): boolean =>
                                        answer.question.shortId === ReviewAttributeShortId.applicationDescription
                                )
                                .map((answer, answerIndex): JSX.Element | null => {
                                    const key = `${answerIndex}-${answer.question.shortId}`;

                                    return (
                                        <Grid
                                            key={key}
                                            gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 6 }]}
                                        >
                                            <DescriptionLabel>{answer.question.content}</DescriptionLabel>
                                            <div>
                                                <AnswerInput
                                                    answer={answer}
                                                    isTafReviewer={isTafReviewer}
                                                    showErrors={showErrors}
                                                />
                                            </div>
                                            <div>
                                                Provide a detailed description of the application to help reviewers
                                                understand the context and purpose of the application. Include, at a
                                                minimum, details like what the application is, what it does and its
                                                purpose, who it is for and what kinds of data it collects. The more
                                                helpful context and background information you can provide here, the
                                                fewer follow-up questions you are likely to receive.
                                            </div>
                                        </Grid>
                                    );
                                })}
                        </Container>
                    )}
                    {detectedTafData && (
                        <React.Fragment>
                            {retentionRecommended && (
                                <Container
                                    header={
                                        <Header variant="h2">
                                            <span>
                                                Based on your responses, we recommend you apply the following retention.
                                            </span>
                                        </Header>
                                    }
                                >
                                    <SpaceBetween direction="vertical" size="xs">
                                        {renderRetention(review)}
                                    </SpaceBetween>
                                </Container>
                            )}
                            {deletionObligationQuestion && (
                                <Container
                                    header={
                                        <Header variant="h2" description={deletionObligationQuestion.question.subtext}>
                                            <span>{deletionObligationQuestion.question.title}</span>
                                        </Header>
                                    }
                                >
                                    {deletionObligationQuestions.map(
                                        (deletionObligationQuestion, index): JSX.Element => {
                                            const { name = "", technology = "" } =
                                                selectDataStoreResponseByHookAnswer(deletionObligationQuestion) ?? {};

                                            return (
                                                <Grid
                                                    key={`${index}-${name}`}
                                                    gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}
                                                >
                                                    <span>
                                                        <b>{`${name} (${technology})`}</b>
                                                    </span>
                                                    <span>
                                                        <AnswerInput
                                                            answer={deletionObligationQuestion}
                                                            isTafReviewer={isTafReviewer}
                                                            showErrors={showErrors}
                                                        />
                                                    </span>
                                                </Grid>
                                            );
                                        }
                                    )}
                                </Container>
                            )}

                            {selectHasApplicationQuestions() && (
                                <Container
                                    header={
                                        <Header variant="h2">
                                            <span>Application Questions</span>
                                        </Header>
                                    }
                                >
                                    <SpaceBetween direction="vertical" size="xl">
                                        {reviewAccessor.answers
                                            .filter(
                                                (answer): boolean =>
                                                    answer.question.shortId !==
                                                        ReviewAttributeShortId.assignedReviewGroups &&
                                                    answer.question.shortId !==
                                                        ReviewAttributeShortId.applicationDescription
                                            )
                                            .map((answer, answerIndex): JSX.Element | null => {
                                                const key = `${answerIndex}-${answer.question.shortId}`;

                                                // Do not render inapplicable questions and do not
                                                // render child questions at top-level
                                                if (!answer.isApplicable || answer.question.parentChoices) {
                                                    return null;
                                                }

                                                return (
                                                    <Container
                                                        key={key}
                                                        header={
                                                            <Header
                                                                variant="h2"
                                                                description={applyTemplateToText(
                                                                    answer.question.subtext
                                                                )}
                                                            >
                                                                <span>{answer.question.content}</span>
                                                            </Header>
                                                        }
                                                    >
                                                        <AnswerInput
                                                            answer={answer}
                                                            answers={reviewAccessor.answers}
                                                            isTafReviewer={isTafReviewer}
                                                            showErrors={showErrors}
                                                        />
                                                    </Container>
                                                );
                                            })}
                                    </SpaceBetween>
                                </Container>
                            )}
                            <DataStoreWizard
                                hasTafLayout={true}
                                questions={[]}
                                questionGroupInfoMap={{}}
                                activeStepIndex={INITIAL_WIZARD_ACTIVE_STEP_INDEX}
                                showModal={wizardVisible}
                                committedDataStores={review?.dataStores ?? []}
                                activeDataStoreIndex={((): number => {
                                    return (
                                        [...(review?.dataStores ?? [])].findIndex(
                                            (dataStore): boolean =>
                                                dataStore.id === (selectedDataStoreLookupId as number)
                                        ) ?? 0
                                    );
                                })()}
                                onSubmit={(): void => saveSelectedAccessor()}
                                onClose={(): void => closeWizard(true)}
                            />
                            {applicableDataStores.length > 0 && (
                                <TAFDataStores
                                    isLoading={false}
                                    openWizard={openWizard}
                                    dataStores={applicableDataStores}
                                    dataStoreAccessors={dataStoreAccessorsStash}
                                />
                            )}
                            {
                                <React.Fragment>
                                    {renderAssignReviewGroups()}
                                    <TAFDecisionTable
                                        userRole={userRole}
                                        tafDetailsFromServer={tafDetailsFromServer}
                                        applicableDataStores={applicableDataStores}
                                        onChange={(dataStore: DataStoreResponse): void => {
                                            handleTAFDecisionChange(dataStore);
                                        }}
                                    />
                                    <TAFFinalDecisionTable tafDetailsFromServer={tafDetailsFromServer} />
                                    <Can
                                        perform={UserAction.editDecisions}
                                        yes={(): JSX.Element => historicalDataStoreDecisionsButton}
                                    />
                                </React.Fragment>
                            }
                            <FormActions>
                                <Can perform={UserAction.editApplication} yes={(): JSX.Element => saveButton} />
                                <Can perform={UserAction.submitApplication} yes={(): JSX.Element => submitButton} />
                                <Can perform={UserAction.recallFinancialApp} yes={(): JSX.Element => recallButton} />
                                <Can perform={UserAction.rejectFinancialApp} yes={(): JSX.Element => rejectButton} />
                                <Can
                                    perform={UserAction.submitFinancialFeedback}
                                    yes={(): JSX.Element => submitFeedbackButton}
                                />
                            </FormActions>
                        </React.Fragment>
                    )}
                </SpaceBetween>
            }
        />
    );
};
