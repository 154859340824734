import { useRef, useState } from "react";

interface ContainerExpandedState {
    isContainerExpanded: boolean;
    hasContainerEverExpanded: boolean;
    toggleIsExpanded: () => void;
}

/**
 * Custom hook responsible for managing ContainerExpandedState of the ApplicationOverview Container
 */
export const useContainerExpandedState = (): ContainerExpandedState => {
    const [isContainerExpanded, setIsContainerExpanded] = useState<boolean>(false);
    const toggleIsExpanded = (): void => setIsContainerExpanded((isExpanded): boolean => !isExpanded);

    // Use a reference to keep track of if the container was ever expanded
    const hasContainerEverExpandedRef = useRef<boolean>(isContainerExpanded);
    if (isContainerExpanded) {
        hasContainerEverExpandedRef.current = true;
    }

    return {
        isContainerExpanded,
        toggleIsExpanded,
        hasContainerEverExpanded: hasContainerEverExpandedRef.current,
    };
};
