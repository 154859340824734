import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AnvilIdStatus } from "src/components/survey/ApplicationPermissions/utils";
import KaleContext from "src/components/KaleContext";
import { AnvilApplication, KaleApplicationService, TaskIdResponse } from "src/services/KaleApplicationService";
import { SelectProps } from "@amzn/awsui-components-react-v3/polaris";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";

export interface AnvilDetails {
    taskId: string;
    appOwner: string;
    applicationName: string;
}

export interface FetchAnvilResponse {
    anvilIdOptions: SelectProps.Option[];
    anvilIdStatus: AnvilIdStatus;
    anvilDetails: AnvilDetails;
    anvilIdError: React.ReactNode;
}

/**
 * Instead of receiving entire app info we will receive only targeted fields.
 * This is because the previous assumption is all changes will be saved back to app and hence the useEffect would
 * listen for changes in app info object. However, that is not the case with new access control page.
 * We will use incremental save option which will save each field when they navigate to next step and app info is
 * used as a read-only field. Hence, the useEffect() in this class will never be called if we use the old way.
 */
interface Props {
    anvilId: string;
    taskId: string;
    applicationOwner: string;
    legalApprovalStatus: ApplicationStatus;
}

export const useAnvilApplications = (props: Props): FetchAnvilResponse => {
    const { anvilId, taskId, applicationOwner, legalApprovalStatus } = props;
    const kaleContext = useContext(KaleContext);
    const { kaleAppService } = kaleContext.service;
    const kaleAppServiceRef = useRef<KaleApplicationService>(kaleAppService);
    kaleAppServiceRef.current = kaleAppService;

    const [anvilIdOptions, setAnvilIdOptions] = useState<SelectProps.Option[]>([]);
    const [anvilIdStatus, setAnvilIdStatus] = useState<AnvilIdStatus>(AnvilIdStatus.na);
    const [anvilDetails, setAnvilDetails] = useState<AnvilDetails>({ taskId: "", applicationName: "", appOwner: "" });
    const [anvilIdError, setAnvilIdError] = useState<React.ReactNode>(null);

    const fetchAnvilApplications = useCallback((): Promise<SelectProps.Option[]> => {
        const { fetchAnvilApplications } = kaleAppServiceRef.current;
        setAnvilIdError(null);
        return fetchAnvilApplications()
            .then((anvilApps: AnvilApplication[]): Promise<SelectProps.Option[]> => {
                const options = anvilApps.map((app: AnvilApplication): SelectProps.Option => {
                    return {
                        value: app.anvilId,
                        label: app.anvilId,
                        description: app.anvilAppName,
                    };
                });
                return Promise.resolve(options);
            })
            .catch((err: Error): Promise<SelectProps.Option[]> => {
                console.log(err);
                setAnvilIdError(err.message);
                return Promise.resolve([]);
            });
    }, []);

    useEffect((): void => {
        setAnvilIdStatus(AnvilIdStatus.loading);
        if (!Boolean(anvilId)) {
            setAnvilDetails({ taskId: "", applicationName: "", appOwner: "" });
            setAnvilIdStatus(AnvilIdStatus.na);
            return;
        }
        const { fetchOwner, fetchTaskId } = kaleAppServiceRef.current;
        const fetchOwnerPromise = fetchOwner(anvilId);
        const fetchTaskIdPromise = fetchTaskId(anvilId);
        Promise.all([fetchOwnerPromise, fetchTaskIdPromise])
            .then(([owner, task]: [string, TaskIdResponse]): void => {
                const newState = { appOwner: owner, taskId: task.taskId, applicationName: task.anvilAppName };
                // Because we're showing / saving taskID and owner,
                // we want to lock them down if it's not in progress
                if (legalApprovalStatus != ApplicationStatus.inProgress) {
                    newState.taskId = taskId;
                    newState.appOwner = applicationOwner;
                    // we're not setting anvil App name because it's not stored anywhere on the application
                }
                setAnvilDetails(newState);
                setAnvilIdStatus(task.taskId !== "" ? AnvilIdStatus.linked : AnvilIdStatus.valid);
            })
            .catch((err: Error): void => {
                setAnvilIdError(err.message);
                setAnvilIdStatus(AnvilIdStatus.invalid);
            });
    }, [anvilId, applicationOwner, legalApprovalStatus, taskId]);

    useEffect((): void => {
        fetchAnvilApplications().then((anvilIdOptions): void => {
            if (anvilIdOptions.length > 0) {
                setAnvilIdOptions(anvilIdOptions);
            }
        });
    }, [fetchAnvilApplications]);

    return {
        anvilIdOptions,
        anvilIdStatus,
        anvilDetails,
        anvilIdError,
    };
};
