import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import Modal from "@amzn/awsui-components-react-v3/polaris/modal";
import RecallImage from "src/images/RecallImage.svg";
import { useSubscribeToRecallMessage } from "src/websocket/message/Recall";
import { ApplicationStatus, selectLegalApprovalStatus, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import KaleContext from "src/components/KaleContext";
import Loader from "src/components/fields/Loader";
import { TEST_IDS } from "shared/survey";
import { kaleUrls } from "src/util/Urls";
import { useWaitForUberCacheUpdate } from "src/hooks/useWaitForUberCacheUpdate";

interface RecallModalProps {
    application: SurveyResponse;
    displayMessage: DisplayMessageCb;
}

export const REVIEW_NOT_FOUND_MESSAGE = "Could not find latest review. Please refresh the page.";
export const RECALL_COMPLETED_MESSAGE = "Application has been successfully recalled.";

/**
 * Recall Modal blocks the UI when recalling an application is in progress.
 * This information is passed via websocket messages and so any user viewing
 * this application should have same experience. When user refresh the page
 * while recalling we give user's an option to view the application in
 * read-only mode.
 */
export const RecallModal = ({ application: { appInfo }, displayMessage }: RecallModalProps): JSX.Element => {
    const history = useHistory();
    const kaleContext = useContext(KaleContext);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRecallInProgress, setIsRecallInProgress] = useState<boolean>(false);
    const [completedProcess, setCompletedProcess] = useState<number>(0);
    const [totalProcess, setTotalProcess] = useState<number>(0);
    const { getLatestReviewID } = kaleContext.service.kaleAppService;
    const {
        applicationName,
        review: { id: reviewId },
    } = appInfo;
    const { status } = selectLegalApprovalStatus(appInfo);

    const onCacheUpdateCriteriaMet = async () => {
        try {
            // Navigate to the newest revision URL once the recall is complete.
            const latestReviewId = await getLatestReviewID(applicationName);
            history.push(kaleUrls.editKaleRecordUrl(applicationName, latestReviewId.toString()));
            displayMessage(MessageType.success, RECALL_COMPLETED_MESSAGE);
        } catch (error) {
            console.error(error);
            displayMessage(MessageType.error, REVIEW_NOT_FOUND_MESSAGE);
            setIsVisible(false);
            setIsRecallInProgress(false);
            setIsLoading(false);
        }
    };
    const waitForUberCacheUpdate = useWaitForUberCacheUpdate({
        onCacheUpdateCriteriaMet,
        updateCriteria: {
            applicationName,
            // After recall completes, a new review is created for the recalled application.
            // We want to wait for the UbergetCacheUpdate event that is fired for the new review.
            // We don't know what the new reviewId will be, but we know it will be numerically higher
            // than the current reviewId for this application
            minimumReviewId: Number(reviewId) + 1,
        },
    });

    const deps = {
        displayMessage,
        waitForUberCacheUpdate,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect((): void => {
        setIsRecallInProgress(status === ApplicationStatus.recallInProgress);
    }, [status]);

    useSubscribeToRecallMessage(
        useCallback(
            async (message): Promise<void> => {
                if (message.payload.applicationName !== applicationName) {
                    return;
                }
                const { displayMessage, waitForUberCacheUpdate } = depsRef.current;

                switch (message.payload.status) {
                    case "started":
                        setIsVisible(true);
                        setTotalProcess(message.payload.stepsCount);
                        break;
                    case "in progress":
                        setTotalProcess(message.payload.stepsCount);
                        setCompletedProcess(message.payload.stepsCompleted);
                        break;
                    case "completed":
                        setIsLoading(true);
                        if (message.payload.error) {
                            displayMessage(
                                MessageType.error,
                                `${message.payload.requestId}: ${message.payload.error.message}`
                            );
                            setIsLoading(false);
                            setIsRecallInProgress(false);
                            break;
                        }
                        waitForUberCacheUpdate();
                }
            },
            [applicationName]
        )
    );

    const onOpenInReadOnly = useCallback((): void => {
        setIsRecallInProgress(false);
    }, []);

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <Modal
                visible={isRecallInProgress || isVisible}
                size={"medium"}
                header={"Recalling Application"}
                footer={
                    isRecallInProgress && (
                        <Box float={"right"}>
                            {" "}
                            <Button
                                data-testid={TEST_IDS.RECALL_IN_PROGRESS.OPEN_READ_ONLY_BUTTON}
                                variant={"primary"}
                                onClick={onOpenInReadOnly}
                            >
                                Open In Read Only
                            </Button>
                        </Box>
                    )
                }
            >
                <Box textAlign="center" color="inherit">
                    {isRecallInProgress && (
                        <Box variant={"p"}>
                            <Box variant="strong">{applicationName}</Box> is locked for application recall by &quot;
                            <Box variant="strong">another user</Box>&quot;. Please wait until the user is finished or
                            open the application in &quot;Read Only&quot;
                        </Box>
                    )}
                    <img id={"recall-app"} src={RecallImage} height={150} alt="Recalling Application" />
                    <Box data-testid={TEST_IDS.RECALL_IN_PROGRESS.MESSAGE} variant={"p"}>
                        {/* eslint-disable-next-line max-len */}
                        <Box variant="strong">{completedProcess}</Box> of <Box variant="strong">{totalProcess}</Box>{" "}
                        processes complete
                    </Box>
                    <Box variant={"p"}>The recall process may take 1-2 minutes, so please be patient.</Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
};
