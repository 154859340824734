import { useDataService } from "src/components/survey/ApplicationOverview/hooks/useDataService";
import { useEffect, useRef } from "react";

/**
 * Custom hook responsible for loading all SummaryCards data during component Mount
 * @param applicationName - the name of the application to load the data for
 */
export const useLoadCardsDataOnMount = (applicationName: string): void => {
    const { loadSummaryCards } = useDataService(applicationName);
    const deps = { loadSummaryCards };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect(function loadDataOnMount(): void {
        const { loadSummaryCards } = dependenciesRef.current;
        loadSummaryCards();
    }, []);
};
