import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Alert, Box, Button, Header, SpaceBetween, Spinner, Tabs } from "@amzn/awsui-components-react-v3";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { DisplayMessageCb, MessageType } from "src/components/survey/KaleRoutes";
import { useMakeQuestionTabs } from "src/components/survey/BulkEdit/hooks/useMakeQuestionTabs";
import SidebarLayout from "src/components/layout/SidebarLayout";
import { useBulkEditKaleTable, useBulkEditKaleTablePayload } from "src/answers_legacy/hooks/bulkEditKaleTable";
import { FormQuestionsPane } from "src/components/TableDetails/FormQuestionsPane/FormQuestionsPane";
import { useBulkEditKaleTableFieldsPayloads } from "src/answers_legacy/hooks/bulkEditKaleTableFields";
import KaleContext from "src/components/KaleContext";
import { BulkEditAnswer, TableRecord, TableStatus } from "src/services/KaleTablesService";
import { ConfirmActionModalButton } from "src/components/ConfirmActions";
import pluralize from "pluralize";
import { SyntheticTableIds } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";
import { BulkEditProgressBar } from "src/components/survey/BulkEdit/BulkEditProgressBar";
import { TEST_IDS } from "shared/survey";

const { BULK_EDIT } = TEST_IDS.DATA_STORE_INFO.SCHEMA_TABLE.ACTIONS;

interface Props {
    appName: string;
    tableIds: number[];
    dataStore: DataStoreResponse | null;
    onCompletedCb: (dataStore: DataStoreResponse) => void;
    onCloseCb: () => void;
    displayMessage: DisplayMessageCb;
}

export const BulkEditPanel = (props: Props): JSX.Element => {
    const { appName, tableIds, dataStore, onCompletedCb, onCloseCb, displayMessage } = props;
    const { kaleTablesService } = useContext(KaleContext).service;

    const tablePayload = useBulkEditKaleTablePayload();
    const fieldPayload = useBulkEditKaleTableFieldsPayloads();

    const deps = {
        kaleTablesService,
        tablePayload,
        fieldPayload,
        dataStore,
        onCompletedCb,
        onCloseCb,
        displayMessage,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const [tableAccessors] = useBulkEditKaleTable();
    const syntheticQuestions = tableAccessors.answers.filter((answer): boolean => {
        return answer.question.id === -1;
    });
    const { isLoading: isLoadingTabs, tabs, error: tabsError } = useMakeQuestionTabs({ dataStore });

    const [activeTabId, setActiveTabId] = useState<string>("");
    const [requestId, setRequestId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect((): void => {
        setIsLoading(isLoadingTabs);
    }, [isLoadingTabs]);

    useEffect((): void => {
        const { displayMessage } = depsRef.current;
        if (!Boolean(tabsError)) {
            return;
        }
        displayMessage(MessageType.error, tabsError);
    }, [tabsError]);

    useEffect((): void => {
        if (tabs.length > 0 && activeTabId === "") {
            setActiveTabId(tabs[0]?.id);
        }
    }, [activeTabId, tabs]);

    const onCompleted = useCallback(
        (didSucceed: boolean): void => {
            const { tablePayload, dataStore, onCompletedCb, onCloseCb } = depsRef.current;
            setIsSaving(false);
            setRequestId("");

            if (!didSucceed) {
                return;
            }
            onCloseCb();
            const tableStatus =
                tablePayload.find((answer): boolean => {
                    return answer.questionShortId === SyntheticTableIds.TableStatus;
                })?.textContent ?? "";
            const tableHasPersonalData =
                tablePayload.find((answer): boolean => {
                    return answer.questionShortId === SyntheticTableIds.TableHasPersonalData;
                })?.textContent ?? "";

            // Return early if the bulk edit payload had no tables or if the status nor has personal data was changed
            if (!dataStore?.tables || (!tableStatus && !tableHasPersonalData)) {
                return;
            }
            const modifiedTables = dataStore.tables.map((table): TableRecord => {
                if (tableIds.includes(table.id)) {
                    const tableRecord = { ...table };
                    if (tableStatus) {
                        tableRecord.status = tableStatus as TableStatus;
                    }
                    if (tableHasPersonalData) {
                        tableRecord.hasPersonalData = tableHasPersonalData;
                    }
                    return tableRecord;
                }
                return table;
            });
            onCompletedCb({ ...dataStore, tables: modifiedTables });
        },
        [tableIds]
    );

    const onCancel = (): void => {
        onCloseCb();
    };

    const onSave = useCallback(async (): Promise<void> => {
        const { kaleTablesService, tablePayload, fieldPayload } = depsRef.current;
        const { saveBulkEditAnswers } = kaleTablesService;

        setIsSaving(true);
        const answers: BulkEditAnswer[] = tablePayload
            .filter((answer): boolean => answer.questionId !== -1)
            .map((answer): BulkEditAnswer => {
                const { tableId, complianceType = "", groupType = "", ...remainingProps } = answer;
                return { ...remainingProps, complianceType, groupType, tableId };
            });
        answers.push(
            ...fieldPayload.map((answer): BulkEditAnswer => {
                const { fieldId, complianceType = "", groupType = "", ...remainingProps } = answer;
                return { ...remainingProps, complianceType, groupType, fieldId };
            })
        );

        const id = await saveBulkEditAnswers(appName, {
            tableIds,
            answers,
            tableStatus:
                tablePayload.find((answer): boolean => {
                    return answer.questionShortId === SyntheticTableIds.TableStatus;
                })?.textContent ?? "",
            tableHasPersonalData:
                tablePayload.find((answer): boolean => {
                    return answer.questionShortId === SyntheticTableIds.TableHasPersonalData;
                })?.textContent ?? "",
        });
        setRequestId(id);
    }, [appName, tableIds]);

    return (
        <SidebarLayout
            header={
                <Header variant="h1" description={`Modifying ${pluralize("table", tableIds.length, true)}`}>
                    {`Bulk Edit - ${dataStore?.name}`}
                </Header>
            }
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button loading={isSaving} onClick={onCancel}>
                            Cancel
                        </Button>
                        <ConfirmActionModalButton
                            testIds={{
                                cancelButton: BULK_EDIT.MODAL_CANCEL_BUTTON,
                                confirmButton: BULK_EDIT.MODAL_CONFIRM_BUTTON,
                                triggerButton: BULK_EDIT.MODAL_TRIGGER_BUTTON,
                                modalRoot: BULK_EDIT.MODAL_ROOT,
                            }}
                            actionLabel={"Save Changes"}
                            loading={isSaving}
                            modalProps={{
                                header: "Confirm bulk table edits",
                                content: (
                                    <SpaceBetween size={"xs"}>
                                        <Alert type="warning">
                                            By applying any non-blank edits, you will override any existing table or
                                            field answers for the selected tables. Click &quot;Save Changes&quot; if you
                                            would like to proceed.
                                        </Alert>
                                    </SpaceBetween>
                                ),
                                onConfirm: onSave,
                                size: "medium",
                            }}
                        />
                    </SpaceBetween>
                </Box>
            }
            itemKey={null}
        >
            <React.Fragment>
                {isLoading && (
                    <Box textAlign={"center"}>
                        <Spinner size={"large"} />
                    </Box>
                )}
                <BulkEditProgressBar
                    isSaving={isSaving}
                    requestId={requestId}
                    displayMessage={displayMessage}
                    onCompleted={onCompleted}
                />
                {!isLoading && (
                    <SpaceBetween size={"xl"}>
                        <FormQuestionsPane questionAnswers={syntheticQuestions} />
                        <Tabs
                            tabs={tabs}
                            activeTabId={activeTabId}
                            variant={"container"}
                            onChange={(e): void => {
                                setActiveTabId(e.detail.activeTabId);
                            }}
                        />
                    </SpaceBetween>
                )}
            </React.Fragment>
        </SidebarLayout>
    );
};
