import { NonCancelableCustomEvent, WizardProps } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRelatedBindleStep } from "src/components/survey/AccessControl/steps/RelatedBindlesStep";
import { useAAAApplicationsStep } from "src/components/survey/AccessControl/steps/AAAApplicationsStep";
import { useAnvilIdStep } from "src/components/survey/AccessControl/steps/AnvilIdStep";
import { useExplanationStep } from "src/components/survey/AccessControl/steps/ExplanationStep";
import { formatIncrementalSaveDate, ValueDictionary } from "src/services/IncrementalSave";
import KaleContext from "src/components/KaleContext";
import Container from "@amzn/awsui-components-react-v3/polaris/container";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import { AccessControlContext, AccessControlStep } from "src/components/survey/AccessControl/AccessControlContext";
import { useHistory } from "react-router-dom";
import { kaleUrls } from "src/util/Urls";
import KaleInlineWizard from "src/components/InlineWizard";
import { TEST_IDS } from "shared/survey/accessControlWizrad";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { MessageType } from "src/components/survey/KaleRoutes";
import { ERROR_MESSAGES } from "src/components/survey/hooks/useApplicationValidation";
import { useWaitForUberCacheUpdate } from "src/hooks/useWaitForUberCacheUpdate";

export interface AccessControlWizardStep {
    valueDictionary: ValueDictionary;
    step: WizardProps.Step;
    isLoading: boolean;
    isValid?: boolean;
    helpPanelContent: React.ReactNode;
}

const REVIEW_ACCESS_CONTROL_SHOWN_AT = "Review-d-ReviewAccessControlShownAt";

export const AccessControlWizard = (): JSX.Element => {
    const {
        service: {
            kaleAppService: { incrementalSave },
        },
        features: { isAnvilDeprecated },
    } = useContext(KaleContext);
    const { application, isReadOnly, setHelpPanelContent } = useContext(AccessControlContext);
    const history = useHistory();
    const { displayMessage } = useMessageBannerActions();
    const {
        appInfo: {
            applicationName,
            review: { id: reviewId },
        },
    } = application;
    const onCacheUpdateCriteriaMet = async () => {
        setIsLoading(false);
        history.push(kaleUrls.editKaleRecordUrl(applicationName, reviewId.toString()));
    };
    const waitForUberCacheUpdate = useWaitForUberCacheUpdate({
        onCacheUpdateCriteriaMet,
        updateCriteria: {
            applicationName,
            minimumReviewId: Number(reviewId),
        },
    });

    const deps = {
        history,
        incrementalSave,
        application,
        setHelpPanelContent,
        displayMessage,
        waitForUberCacheUpdate,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const [activeStepIndex, setActiveStepIndex] = useState<AccessControlStep>(AccessControlStep.RELATED_BINDLE);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const relatedBindleStep = useRelatedBindleStep();
    const aaaStep = useAAAApplicationsStep({
        activeStepIndex,
        getCurrentTitle: () => steps[activeStepIndex].step.title,
    });
    const anvilStep = useAnvilIdStep();
    const explanationStep = useExplanationStep();

    const steps: AccessControlWizardStep[] = useMemo(() => {
        const stepsMap: Record<AccessControlStep, AccessControlWizardStep> = {
            [AccessControlStep.RELATED_BINDLE]: relatedBindleStep,
            [AccessControlStep.AAA_ID]: aaaStep,
            [AccessControlStep.Anvil]: anvilStep,
            [AccessControlStep.Explanation]: explanationStep,
        };
        return Object.entries(stepsMap)
            .sort()
            .filter(([key]) => !(isAnvilDeprecated && key == AccessControlStep.Anvil.toString()))
            .map(([, value]) => value);
    }, [aaaStep, anvilStep, explanationStep, isAnvilDeprecated, relatedBindleStep]);

    useEffect(() => {
        const { setHelpPanelContent } = depsRef.current;
        const helpPanelContent = steps[activeStepIndex].helpPanelContent;
        setHelpPanelContent(helpPanelContent);
    }, [activeStepIndex, steps]);

    useEffect(() => {
        setIsLoading(relatedBindleStep.isLoading || aaaStep.isLoading || anvilStep.isLoading);
    }, [aaaStep.isLoading, relatedBindleStep.isLoading, anvilStep.isLoading]);

    const onNavigateCb = useCallback(
        async (event: NonCancelableCustomEvent<WizardProps.NavigateDetail>): Promise<void> => {
            const { incrementalSave, displayMessage } = depsRef.current;

            if (!isReadOnly) {
                try {
                    setIsLoading(true);
                    await incrementalSave({
                        appName: application.appInfo.applicationName,
                        reviewID: application.appInfo.review.id.toString(),
                        valueDictionary: steps[activeStepIndex].valueDictionary,
                    });
                } catch (e) {
                    console.error(e);
                    displayMessage(MessageType.error, (e as Error).message);
                } finally {
                    setIsLoading(false);
                }
            }
            setActiveStepIndex(event.detail.requestedStepIndex);
        },
        [activeStepIndex, application.appInfo.applicationName, application.appInfo.review.id, isReadOnly, steps]
    );
    const onSubmitCb = useCallback(async () => {
        const { incrementalSave, displayMessage, waitForUberCacheUpdate } = depsRef.current;
        const isValidForSubmit = steps.some((step) => step.isValid ?? false);
        if (!isAnvilDeprecated && !isValidForSubmit) {
            displayMessage(MessageType.error, ERROR_MESSAGES.ANVIL_ID_EXPLANATION_MISSING);
            return;
        }
        if (!isReadOnly) {
            try {
                setIsLoading(true);
                await incrementalSave({
                    appName: application.appInfo.applicationName,
                    reviewID: application.appInfo.review.id.toString(),
                    valueDictionary: {
                        ...steps[activeStepIndex].valueDictionary,
                        [REVIEW_ACCESS_CONTROL_SHOWN_AT]: formatIncrementalSaveDate(),
                    },
                });
                waitForUberCacheUpdate();
            } catch (e) {
                console.error(e);
                displayMessage(MessageType.error, (e as Error).message);
                setIsLoading(false);
                return;
            }
        }
    }, [
        activeStepIndex,
        application.appInfo.applicationName,
        application.appInfo.review.id,
        isAnvilDeprecated,
        isReadOnly,
        steps,
    ]);

    return (
        <Container header={<Header variant={"h2"}>Access Control Wizard</Header>}>
            <KaleInlineWizard
                data-testid={TEST_IDS.WIZARD.ROOT}
                /* This wizard has a workflow constraint between the Related
                Bindles step, and the AAA Ids step. Whenever the Related
                Bindles step is updated by a user, a user must then revisit the
                subsequent AAA ids step. We are disabling the side navigation
                in this wizard to prevent users from editing their Related
                Bindles answer and then being able to skip past the AAAids step */
                disableSideNavigation={true}
                steps={steps.map((wizardStep) => wizardStep.step)}
                isLoadingNextStep={isLoading}
                i18nStrings={{
                    stepNumberLabel: (stepNumber: number): string => {
                        return `Step ${stepNumber}`;
                    },
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: isReadOnly ? "Continue" : "Confirm and Continue",
                    optional: "optional",
                }}
                activeStepIndex={activeStepIndex}
                onNavigate={onNavigateCb}
                onSubmit={onSubmitCb}
            />
        </Container>
    );
};
