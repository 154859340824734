import { KaleApplicationEvent } from "src/services/RumService/rum-events/base-events";
import { RumEventName } from "src/services/RumService/rum-events/constants";
import { FilterDetails } from "src/services/RumService/rum-events/pdc-filter-events/types";

interface PDCFiltersLoadedEvent extends KaleApplicationEvent {
    name: RumEventName.Kale_PDC_Filters_Loaded;
    data: EventData;
}

interface EventData {
    initialFilter: string;
    filtersData: FiltersData;
}

type FiltersData = {
    [filterName: string]: FilterDetails;
};

const eventName = RumEventName.Kale_PDC_Filters_Loaded;

type PDCFiltersLoadedOptions = Pick<PDCFiltersLoadedEvent, "data" | "metadata">;
const pdcFiltersLoaded = ({ data, metadata }: PDCFiltersLoadedOptions): PDCFiltersLoadedEvent => ({
    name: eventName,
    metadata,
    data,
});

export const event = pdcFiltersLoaded;
export const name = eventName;
export type Options = PDCFiltersLoadedOptions;
export type Event = PDCFiltersLoadedEvent;
