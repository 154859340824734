import * as React from "react";
import { useCallback } from "react";
import { DatePicker, FormField } from "@amzn/awsui-components-react-v3";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";

type Props = FunctionalSurveyItemProps<string>;

const NewDatePicker = (props: Props): JSX.Element => {
    const { id, isRequired, isFormInReadonlyMode = false, expectedAnswer = "", onChangeCallback } = props;

    return (
        <FormField stretch={true} errorText={isRequired && !Boolean(expectedAnswer) ? "Required" : ""}>
            <div>
                <DatePicker
                    value={expectedAnswer}
                    disabled={isFormInReadonlyMode}
                    onChange={useCallback(
                        (event): void => {
                            const date = event.detail.value;
                            onChangeCallback?.call(null, {
                                id: id,
                                response: date,
                            });
                        },
                        [id, onChangeCallback]
                    )}
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                />
            </div>
        </FormField>
    );
};

export default withFunctionalReadonly(NewDatePicker);
