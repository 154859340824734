import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReviewGroupsMap } from "src/services/KaleApplicationService";
import Loader from "src/components/fields/Loader";

import { DisplayMessageCb } from "src/components/survey/KaleRoutes";
import {
    ApplicationStatus,
    DEFAULT_VALIDATION,
    selectLegalApprovalStatus,
    SurveyProvider,
} from "src/components/survey/SurveyFormModel";

import { UserRole } from "src/permissions";
import { useFetchPageResources } from "src/components/TAF/TAFDetails/hooks/useFetchPageResources";
import { useTAFIdentifiersFromRoute } from "src/components/TAF/TAFDetails/hooks/useTAFIdentifiersFromRoute";
import { TAFAppLayout } from "src/components/TAF/TAFDetails/components";
import { TEST_IDS } from "shared/taf-view";
import { useHistory } from "react-router-dom";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useInitializeTAFSurveyGSMOnce } from "src/components/TAF/TAFDetails/hooks/useInitializeTAFSurveyGSMOnce";
import { kaleUrls } from "src/util/Urls";

export interface TAFDetailsPageProps {
    displayMessage: DisplayMessageCb;
}

export interface TAFIdentifiers {
    applicationName: string;
    reviewId: string;
}

const {
    TAF_DETAILS_PAGE: { ROOT, LOADING_SPINNER, LOADED_CONTENT },
} = TEST_IDS;

const TAFDetailsPage = (props: TAFDetailsPageProps): JSX.Element => {
    const history = useHistory();
    const { applicationName } = useTAFIdentifiersFromRoute();
    const { displayMessage } = props;
    const { isFetchingPageResources, kaleQuestion, userRole, setTAFDetailsFromServer, tafDetailsFromServer } =
        useFetchPageResources();

    const [reviewGroupsMap, setReviewGroupsMap] = useState<ReviewGroupsMap>({});
    const [originalAssignedReviewGroups, setOriginalAssignedReviewGroups] = useState<string[]>([]);
    const [originalDataStoreDecisions, setOriginalDataStoreDecisions] = useState<DataStoreResponse[]>([]);
    const [detectedTafData, setDetectedTafData] = React.useState<boolean>(false);
    const [retentionRecommended, setRetentionRecommended] = React.useState<boolean>(false);

    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    useEffect(
        function signalInitialPageLoadComplete(): void {
            if (!isFetchingPageResources) {
                setIsInitialPageLoad(false);
            }
        },
        [isFetchingPageResources]
    );

    const deps = { history, tafDetailsFromServer };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(
        function redirectToLegalSurvey(): void {
            const { history, tafDetailsFromServer } = depsRef.current;
            // Wait for the page to finish loading, then determine if we should redirect the user away from TAF
            if (!isInitialPageLoad) {
                if (tafDetailsFromServer) {
                    const legalStatus = selectLegalApprovalStatus(tafDetailsFromServer.appInfo);
                    if (legalStatus.status === ApplicationStatus.inProgress) {
                        const appName = tafDetailsFromServer.appInfo.applicationName;
                        const reviewId = tafDetailsFromServer.appInfo.review.id;
                        history.push(kaleUrls.editKaleRecordUrl(appName, `${reviewId}`));
                    }
                }
            }
        },
        [isInitialPageLoad]
    );

    const isTafReviewer = useMemo(
        (): boolean =>
            [UserRole.accountingReviewer, UserRole.fraudReviewer, UserRole.admin].includes(
                userRole ?? UserRole.readOnly
            ),
        [userRole]
    );

    useInitializeTAFSurveyGSMOnce({
        setOriginalDataStoreDecisions,
        setOriginalAssignedReviewGroups,
        setDetectedTafData,
        setRetentionRecommended,
        setReviewGroupsMap,
        tafDetailsFromServer,
        isTafReviewer,
        kaleQuestion,
        userRole,
    });

    return (
        <div data-testid={ROOT}>
            {isInitialPageLoad ? (
                <Loader data-testid={LOADING_SPINNER} />
            ) : tafDetailsFromServer && userRole ? (
                <SurveyProvider
                    value={{
                        application: tafDetailsFromServer,
                        role: userRole,
                        updateBulkEditPanel: (): void => {},
                        controlBindle: null,
                        setControlBindle: (): void => {},
                        saveDraft: (): any => {},
                        validation: DEFAULT_VALIDATION,
                    }}
                >
                    <div data-testid={LOADED_CONTENT}>
                        <TAFAppLayout
                            applicationName={applicationName}
                            userRole={userRole}
                            detectedTafData={detectedTafData}
                            displayMessage={displayMessage}
                            isTafReviewer={isTafReviewer}
                            kaleQuestion={kaleQuestion}
                            retentionRecommended={retentionRecommended}
                            reviewGroupsMap={reviewGroupsMap}
                            tafDetailsFromServer={tafDetailsFromServer}
                            originalDataStoreDecisions={originalDataStoreDecisions}
                            originalAssignedReviewGroups={originalAssignedReviewGroups}
                            setTAFDetailsFromServer={setTAFDetailsFromServer}
                        />
                    </div>
                </SurveyProvider>
            ) : null}
        </div>
    );
};

export default TAFDetailsPage;
