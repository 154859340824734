import { WebSocketMessageBase } from "src/websocket/useOnWebSocketMessage";
import { useContext, useEffect } from "react";
import { WebSocketContext } from "src/websocket/WebSocketContext";

export type RecallStatus = "started" | "in progress" | "completed";

interface Payload {
    requestId: string;
    applicationName: string;
    status: RecallStatus;
    stepsCount: number;
    stepsCompleted: number;
    response: string;
    error: Error | null;
}
export interface RecallMessage extends WebSocketMessageBase {
    payload: Payload;
}
export type RecallMessageHandler = (message: RecallMessage) => void;

export const useSubscribeToRecallMessage = (callback: RecallMessageHandler): void => {
    const { subscribeToRecallMessage, unsubscribeToRecallMessage } = useContext(WebSocketContext);

    useEffect((): (() => void) => {
        subscribeToRecallMessage(callback);
        return (): void => unsubscribeToRecallMessage(callback);
    }, [callback, subscribeToRecallMessage, unsubscribeToRecallMessage]);
};
