import { Link, TableProps } from "@amzn/awsui-components-react-v3";
import { PDCFinding, PDCPlatformType } from "src/services/NodeKaleApplicationService";
import React from "react";
import { PDCEntityTypes } from "./PDCEntityTypes";
import { EntityFilterFn } from "./usePDCFilters";

export const getColumnDefinitions = (
    entityFilterFn: EntityFilterFn,
    personalDataClassifierHost: string
): TableProps.ColumnDefinition<PDCFinding>[] => [
    { id: "platformType", header: "Platform Type", cell: (e: PDCFinding): string => e.platformType },
    {
        id: "resourceGroupId",
        header: "Resource Group ID",
        cell: (e: PDCFinding): React.ReactNode => {
            const resourceGroupIdLink =
                e.platformType === PDCPlatformType.Aws
                    ? `https://conduit.security.a2z.com/accounts/aws/${e.resourceGroupId}/attributes`
                    : `https://datacentral.a2z.com/hoot/providers/${e.resourceGroupId}`;

            return (
                <Link external={true} href={resourceGroupIdLink}>
                    {e.resourceGroupId}
                </Link>
            );
        },
    },
    {
        id: "storageResourceName",
        header: "Storage Resource Name",
        cell: (e: PDCFinding): React.ReactNode => {
            const tableVersion = e.storageResourceConfiguration.andesTableConfiguration?.tableVersion;
            const version = Boolean(tableVersion) ? `v${tableVersion}` : "";
            const andesTableLink =
                `https://datacentral.a2z.com/hoot/providers/${e.resourceGroupId}` +
                `/tables/${e.storageResourceName}/versions/${tableVersion}`;
            const storageResourceDetails = `${e.storageResourceName} ${version}`;

            return e.platformType === PDCPlatformType.Andes ? (
                <Link external={true} href={andesTableLink}>
                    {storageResourceDetails}
                </Link>
            ) : (
                e.storageResourceName
            );
        },
    },
    {
        id: "type",
        header: "Type",
        cell: (e: PDCFinding): string => (e.platformType === PDCPlatformType.Aws ? e.storageSystem : "Andes Table"),
    },
    {
        id: "foundEntityTypes",
        header: "Found Entity Types",
        cell: (e: PDCFinding): React.ReactNode => (
            <PDCEntityTypes entityTypes={e.foundEntityTypes} entityFilter={entityFilterFn} />
        ),
    },
    {
        id: "view",
        header: "Review / Provide Feedback",
        cell: (e: PDCFinding): React.ReactNode => {
            const url = new URL(`${personalDataClassifierHost}/consolidated/view`);
            url.searchParams.append("resourceGroupId", e.resourceGroupId);
            url.searchParams.append("platformType", e.platformType);
            url.searchParams.append("region", e.region);
            url.searchParams.append("storageResourceName", e.storageResourceName);
            url.searchParams.append("storageSystem", e.storageSystem);
            if (e.platformType === PDCPlatformType.Andes) {
                url.searchParams.append(
                    "tableVersion",
                    e.storageResourceConfiguration.andesTableConfiguration?.tableVersion?.toString() ?? ""
                );
            }
            return (
                <Link external={true} href={url.href}>
                    View
                </Link>
            );
        },
    },
];
