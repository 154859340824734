import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import KaleContext from "src/components/KaleContext";
import { LineageGlossary, LineageNode } from "src/services/external-lineage/types";

export const USE_CONTEXT_UNDEF_ERR =
    "useLineagePredictionsContext must be used within a LineagePredictionsContextProvider";

export interface LineagePredictionsState {
    // Return from GetNodesById API
    nodes: LineageNode[] | null;
    // Return from LineageGlossary API
    glossary: LineageGlossary | null;
    // Indicates the GetNodesById API Call has not been made OR is in progress OR will not be made at all
    // (due to app not being created yet)
    isLoading: boolean;
    // This will contain the API error message in the event that an error occurred
    error: string;
}

/**
 * useFetchLineagePredictionsInfoOnMount calls the API that backs the Predictions Table in Kale.
 * It is calculated within the context and hooks can be used to retrieve the data if your component is covered by
 * the associated Provider.
 *
 * fetchNodesById is the API being called.
 *
 * @param nodeIds IDs of the control bindle, related bindles, and related AAA applications in the current attestation.
 * The NodesById API expects a comma seperated string of all Ids to search. Eg: "testId1,secondId2,idNumber3"
 */
const useFetchLineagePredictionsInfoOnMount = (nodeIds = ""): LineagePredictionsState => {
    const [nodes, setNodes] = useState<LineagePredictionsState["nodes"]>(null);
    const [glossary, setGlossary] = useState<LineagePredictionsState["glossary"]>(null);
    const [isLoading, setLoading] = useState<LineagePredictionsState["isLoading"]>(true);
    const [error, setError] = useState<LineagePredictionsState["error"]>("");

    const {
        service: { kaleAppService },
    } = useContext(KaleContext);
    const dependencies = { kaleAppService, nodeIds };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    useEffect(function loadNodesById(): void {
        // https://developer.mozilla.org/en-US/docs/Glossary/IIFE
        (async function IIFE(): Promise<void> {
            setLoading(true);
            const { kaleAppService, nodeIds } = depsRef.current;
            try {
                // Note on Glossary: Currently there is only 1 user of the glossary data. If more get implemented
                // then the glossary should be promoted to have its own stand-alone context or whatever the current
                // alternative is.

                // Promise.allSettled returns either "fulfilled" indicating a successful response or "rejected"
                // indicating a failed response
                const [glossaryResults, nodesByIdResults] = await Promise.allSettled([
                    kaleAppService.fetchLineageGlossary(),
                    kaleAppService.fetchNodesById([nodeIds]),
                ]);
                // If the glossary API fails to return, the table can handle the glossary missing so change the state
                // just to know that the API request is finished
                if (glossaryResults.status === "fulfilled") {
                    setGlossary(glossaryResults.value);
                } else {
                    setGlossary({});
                }
                if (nodesByIdResults.status === "fulfilled") {
                    setNodes(nodesByIdResults.value);
                    setError("");
                } else {
                    // Set this to empty array, so we can leverage the table's empty prop to display the error
                    setNodes([]);
                    // Throwing the error from fetchNodesById that was captured by Promise.allSettled
                    setError(nodesByIdResults.reason);
                }
            } catch (err) {
                setError(err as LineagePredictionsState["error"]);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return { nodes, glossary, isLoading, error };
};

const LineagePredictionsContext = createContext<undefined | LineagePredictionsState>(undefined);

interface LineagePredictionsContextProviderProps {
    children: React.ReactNode;
    nodeIds?: string;
}

/**
 * Component is responsible for fetching node data for the Ids that it receives and
 * will then publish that data to its children via a Context object. Callers should use this component
 * to fetch and provide node data to their react subtree if their subtree does not already have
 * this component as an ancestor.
 * @param nodeIds Ids of submitted bindles/related AAA applications
 * @param children components to render within the Provider
 * @constructor
 */
export const LineagePredictionsContextProvider = ({
    nodeIds,
    children,
}: LineagePredictionsContextProviderProps): JSX.Element => {
    const { nodes, glossary, isLoading, error } = useFetchLineagePredictionsInfoOnMount(nodeIds);
    const { Provider } = LineagePredictionsContext;
    return (
        <Provider
            value={{
                nodes: nodes,
                glossary: glossary,
                isLoading: isLoading,
                error: error,
            }}
        >
            {children}
        </Provider>
    );
};

/**
 * A custom hook that reads from the Lineage Predictions Context object and returns the data to the calling
 * component.
 *
 * Callers should use this hook to receive the node data into their components
 */
export const useLineagePredictionsContext = (): LineagePredictionsState => {
    const lineagePredictionsContext = useContext(LineagePredictionsContext);
    if (lineagePredictionsContext === undefined) {
        throw new Error(USE_CONTEXT_UNDEF_ERR);
    }

    return lineagePredictionsContext;
};
