import { Button, Form } from "@amzn/awsui-components-react-v3";
import React, { useEffect, useRef, useState } from "react";
import Loader from "src/components/fields/Loader";
import { ReviewQuestionBuilder } from "src/components/survey/campaign/ReviewQuestionBuilder";
import { DisplayMessageCb, KaleRoutesProps, MessageType } from "src/components/survey/KaleRoutes";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import { useParams } from "react-router-dom";
import { KaleApplicationRouteParams } from "src/components/survey/KaleApplication/KaleApplicationRoutes";
import { useFetchCampaignOnMount } from "src/components/survey/campaign/hooks/useFetchCampaignOnMount";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { AnswerActions, ResetAnswersActionCallback, ReviewAnswer, ReviewQuestion, useReview } from "src/answers_legacy";
import { useSaveCampaign } from "src/components/survey/campaign/hooks/useSaveCampaign";
import { TEST_IDS } from "shared/campaign";
import { useFetchApplicationMetadata } from "src/components/survey/hooks/useFetchApplicationMetadata";
import { UserRole } from "src/permissions";
import {
    accessorsToValueDictionary,
    campaignToAnswers,
    campaignToQuestions,
    findChangedAnswers,
} from "src/components/survey/campaign/utils";
import { getReviewIdFromValueDictionary } from "src/services/IncrementalSave";

export interface CampaignRouteParams extends KaleApplicationRouteParams {
    campaignType: string;
}

export const CampaignSurveyForm = (props: KaleRoutesProps): JSX.Element => {
    const { displayMessage } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [changesDetected, setChangesDetected] = useState<boolean>(true);
    const { campaignType } = useParams<CampaignRouteParams>();
    const { applicationName, reviewId } = useAppParamsFromRoute();
    const { campaign, isLoadingCampaign, campaignError } = useFetchCampaignOnMount({
        appName: applicationName,
        type: campaignType,
    });
    const [accessors, reviewActions] = useReview();
    const { userRole, metadataError } = useFetchApplicationMetadata();
    const { isSaving, save } = useSaveCampaign();

    const reviewActionsRef = useRef<AnswerActions<ReviewQuestion, ReviewAnswer>>(reviewActions);
    reviewActionsRef.current = reviewActions;
    const displayMessageRef = useRef<DisplayMessageCb>(displayMessage);
    displayMessageRef.current = displayMessage;

    useEffect((): ResetAnswersActionCallback | void => {
        const { resetAndAddAnswers, resetAnswers } = reviewActionsRef.current;
        resetAndAddAnswers(campaignToAnswers(campaign), campaignToQuestions(campaign));
        return (): void => resetAnswers();
    }, [campaign]);

    useEffect((): void => {
        if (!Boolean(campaignError)) {
            return;
        }
        displayMessageRef.current(MessageType.error, campaignError);
    }, [campaignError]);

    useEffect((): void => {
        if (!Boolean(metadataError)) {
            return;
        }
        displayMessageRef.current(MessageType.error, metadataError);
    }, [metadataError]);

    useEffect((): void => {
        setIsLoading(isLoadingCampaign);
    }, [isLoadingCampaign]);

    useEffect((): void => {
        const valDict = accessorsToValueDictionary(accessors);
        setChangesDetected(
            !Object.entries(valDict).every(([key, value]): boolean => value == campaign.valueDictionary[key])
        );
    }, [accessors, campaign]);

    const onSaveClick = async (): Promise<void> => {
        await save({
            appName: applicationName,
            reviewID: getReviewIdFromValueDictionary(campaign.valueDictionary),
            valueDictionary: findChangedAnswers(accessorsToValueDictionary(accessors), campaign),
        }).catch((saveErr: Error): void => {
            displayMessageRef.current(MessageType.error, saveErr);
        });
    };

    const canEdit: boolean = userRole === UserRole.admin || userRole === UserRole.serviceOwner;

    return (
        <React.Fragment>
            {!isLoading && BreadCrumbs(applicationName, reviewId, campaignType)}
            {isLoading && <Loader />}
            {
                <Form
                    data-testid={TEST_IDS.CAMPAIGN_FORM.ROOT}
                    actions={
                        canEdit ? (
                            <Button
                                data-testid={TEST_IDS.CAMPAIGN_FORM.SAVE_BUTTON}
                                variant="primary"
                                onClick={onSaveClick}
                                loading={isSaving}
                                disabled={!changesDetected}
                            >
                                Save Answers
                            </Button>
                        ) : null
                    }
                >
                    {accessors.answers.map((item): React.ReactNode => {
                        return (
                            <ReviewQuestionBuilder
                                key={item.question.shortId}
                                item={item}
                                isReadonly={!canEdit}
                                isRequired={true}
                            />
                        );
                    })}
                </Form>
            }
        </React.Fragment>
    );
};

const CampaignMap: Record<string, string> = {
    DMA_LINE_OF_BUSINESS: "DMA Data Sharing",
};

const BreadCrumbs = (applicationName: string, reviewId: string, campaignType: string): JSX.Element => {
    const items = [
        BreadcrumbItems.KALE_LANDING_PAGE,
        BreadcrumbItems.editKaleRecord(applicationName, reviewId),
        BreadcrumbItems.editKaleCampaignRecord(applicationName, reviewId, CampaignMap[campaignType]),
    ];
    return <KaleBreadcrumbGroup items={items} />;
};
