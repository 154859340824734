import { TokenGetter } from "src/services/CognitoService";
import { KaleConfig } from "src/Config";
import { appendRequestID } from "src/services/AppendRequestID";

export enum LogItemKey {
    logType = "logType",
    entityType = "entityType",
    fieldName = "fieldName",
    oldValue = "oldValue",
    newValue = "newValue",
    isFinanciallyRelevant = "isFinanciallyRelevant",
    updatedAt = "updatedAt",
    updatedBy = "updatedBy",
}

export interface LogItem {
    [LogItemKey.logType]: string;
    [LogItemKey.entityType]: string;
    [LogItemKey.fieldName]: string;
    [LogItemKey.oldValue]: string;
    [LogItemKey.newValue]: string;
    [LogItemKey.isFinanciallyRelevant]: string;
    [LogItemKey.updatedAt]: string;
    [LogItemKey.updatedBy]: string;
}

export interface AuditLogResponse {
    logs: LogItem[];
    entityType: string;
    entityId: string;
}

export interface AuditLogParams {
    from?: Date;
    to?: Date;
    recursive?: boolean;
}

type AuditLogQueryParams = Record<"recursive" | "from" | "to", string>;

const API_URLS = {
    GET_ENTITY: (name: string, id: string): string => `/audit-log/${name}/${id}`,
};

export const IS_FINANCIALLY_RELEVANT = "Yes";
export const IS_NOT_FINANCIALLY_RELEVANT = "No";

export const auditLogItemsReducer = (items: LogItem[]): LogItem[] =>
    items.map((item): LogItem => {
        const isFinanciallyRelevant = item[LogItemKey.isFinanciallyRelevant]
            ? IS_FINANCIALLY_RELEVANT
            : IS_NOT_FINANCIALLY_RELEVANT;
        return {
            ...item,
            isFinanciallyRelevant,
        };
    });

const getAuditLog = async (
    apiEndpoint: string,
    accessTokenGetter: TokenGetter,
    url: string
): Promise<AuditLogResponse> => {
    const fetchMessagePrefix = "Fetching Audit Log";

    console.info(fetchMessagePrefix);

    const accessToken = await accessTokenGetter();
    const resp = await fetch(url.toString(), {
        mode: "cors",
        headers: {
            accept: "application/json",
            "content-type": "application/json",
            authorization: accessToken,
        },
        method: "GET",
    });
    if (resp.ok) {
        console.info(`${fetchMessagePrefix}: Success`);

        const result = (await resp.json()) as unknown as AuditLogResponse;

        return {
            ...result,
            logs: auditLogItemsReducer(result.logs),
        };
    } else {
        const errorMsg = appendRequestID(resp.statusText, resp);

        console.error(`${fetchMessagePrefix}: Success`, errorMsg);

        throw Error(`${fetchMessagePrefix}: Failed. ${errorMsg}`);
    }
};

const getAuditLogEntity = async (
    apiEndpoint: string,
    accessTokenGetter: TokenGetter,
    entityName: string,
    entityId: string,
    params: AuditLogParams
): Promise<AuditLogResponse> => {
    const url = new URL(`${apiEndpoint}${API_URLS.GET_ENTITY(entityName, entityId)}`);
    const queryParams: AuditLogQueryParams = {
        from: params.from?.toISOString() || "",
        to: params.to?.toISOString() || "",
        recursive: params.recursive?.toString() || "",
    };
    // eslint-disable-next-line
    url.search = new URLSearchParams(queryParams).toString();

    return getAuditLog(apiEndpoint, accessTokenGetter, url.toString());
};

class AuditLogService {
    private apiEndpoint: string;
    private accessTokenGetter: () => Promise<string>;

    public constructor(stageConfig: KaleConfig, accessTokenGetter: TokenGetter) {
        this.apiEndpoint = stageConfig.apiEndpoint;
        this.accessTokenGetter = accessTokenGetter;
    }

    public getEntity(entityName: string, entityId: string, params: AuditLogParams): Promise<AuditLogResponse> {
        return getAuditLogEntity(this.apiEndpoint, this.accessTokenGetter, entityName, entityId, params);
    }
}

export { AuditLogService };
