import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { QuestionTag } from "src/services/dynamic-questions";
import { DEFAULT_KALE_QUESTIONS, KaleQuestion } from "src/services/KaleApplicationService";

export interface FetchQuestionsResultState {
    isLoadingQuestions: boolean;
    questions: KaleQuestion;
    questionsError: React.ReactNode;
}

/**
 * Fetches all LegalSurvey GSM Questions/Answers (e.g. Review/DataStore questions)
 */
export const useFetchQuestionsOnMount = (): FetchQuestionsResultState => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const deps = {
        kaleAppService,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    const [isLoadingQuestions, setIsLoadingQuestions] = useState<boolean>(false);
    const [questions, setQuestions] = useState<KaleQuestion>(DEFAULT_KALE_QUESTIONS);
    const [questionsError, setQuestionsError] = useState<React.ReactNode>(null);

    useEffect((): void => {
        const { kaleAppService } = dependenciesRef.current;
        const { fetchQuestions } = kaleAppService;
        setIsLoadingQuestions(true);
        fetchQuestions(QuestionTag.primaryView)
            .then((questions): void => {
                setQuestions(questions);
            })
            .catch((err: Error): void => {
                setQuestionsError(err.message);
            })
            .then((): void => {
                setIsLoadingQuestions(false);
            });
    }, []);

    return { isLoadingQuestions, questions, questionsError };
};
