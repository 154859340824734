import { useEffect, useState } from "react";
import {
    cloneAccessors,
    DataStoreHookAnswer,
    DataStoresAccessor,
    DataStoresAccessorReadonly,
} from "src/answers_legacy";
import usePrevious from "@rooks/use-previous";
import { difference } from "lodash";
import { useTAFDetailsSelectors } from "src/components/TAF/TAFDetails/hooks";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";

interface UseDataStoresStash {
    deletionObligationQuestions: DataStoreHookAnswer[];
    stash: DataStoresAccessorReadonly[] | null;
    setStash: (value: DataStoresAccessor[]) => void;
}

export const useDataStoresStash = (props: {
    tafDetailsFromServer: SurveyResponse | null;
    dataStoreAccessors: DataStoresAccessor[];
}): UseDataStoresStash => {
    const { tafDetailsFromServer, dataStoreAccessors } = props;
    const prevDataStoreAccessors = usePrevious(dataStoreAccessors);

    // Stashing accessors to persist a data stores table without changes made in wizard
    const [stash, setStash] = useState<DataStoresAccessorReadonly[] | null>(null);

    const setter = (value: DataStoresAccessor[] | null): void => {
        if (!value) {
            setStash(value);
            return;
        }
        const accessors = cloneAccessors<DataStoresAccessorReadonly>(value);
        setStash(accessors);
    };

    const { selectDeletionObligationQuestions } = useTAFDetailsSelectors({ tafDetailsFromServer, dataStoreAccessors });

    const deletionObligationQuestions = selectDeletionObligationQuestions(dataStoreAccessors);
    const prevDeletionObligationQuestions = usePrevious(deletionObligationQuestions);

    useEffect((): void => {
        // Resets stash on first load and when handling a new remote call response
        if (!stash && prevDataStoreAccessors !== dataStoreAccessors) {
            setStash(dataStoreAccessors);
            return;
        }
        if (
            difference(
                deletionObligationQuestions.map((question): string => question.userLastUpdated),
                prevDeletionObligationQuestions?.map((question): string => question.userLastUpdated) ?? []
            ).length > 0
        ) {
            setStash(dataStoreAccessors);
            return;
        }
        // Handle resolving accessor stash when adding and removing data store rows
        if (prevDataStoreAccessors) {
            if (
                prevDataStoreAccessors.length > dataStoreAccessors.length ||
                prevDataStoreAccessors.length < dataStoreAccessors.length
            ) {
                setStash(dataStoreAccessors.filter((accessor): boolean => !prevDataStoreAccessors.includes(accessor)));
            }
        }
    }, [
        stash,
        dataStoreAccessors,
        prevDataStoreAccessors,
        deletionObligationQuestions,
        prevDeletionObligationQuestions,
        setStash,
    ]);

    return {
        deletionObligationQuestions,
        stash,
        setStash: setter,
    };
};
