import AbstractKaleService, { OperationResult } from "src/services/AbstractKaleService";
import { FeatureToggleConfig, KaleConfig } from "src/Config";
import { TokenGetter } from "src/services/CognitoService";
import { appendRequestID } from "src/services/AppendRequestID";
import { groupBy } from "lodash";

export interface CommentFromServer {
    id: number;
    applicationName: string;
    message: string;
    alias: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    parentId: number | null;
}

/** This is a FrontEnd only type that we transform the PDCFindingResponse types into */
export interface PDCFinding extends BasePDCFinding {
    /**
     * highestClassificationScoreAcrossAllEntities represents the highest classification score to be found
     * from among all of a PDCFinding's individual `foundEntityTypes`. This field is not sent by PDC nor the
     * Kale backend, it's derived locally by our front end service layer before the PDCFinding payload is
     * released to the rest of the UI.
     */
    highestClassificationScoreAcrossAllEntities: ClassificationScore;
}

interface BasePDCFinding extends StorageResource {
    platformType: string;
}

export interface PDCFindingResponse {
    platformType: string;
    storageResources: StorageResource[];
}

export enum PDCPlatformType {
    Aws = "AwsAccount",
    Andes = "Andes",
}

// The Kale Backend PDC API Endpoint is mainly a proxy to the PDC Service's API.
// If you're curious what these fields mean, you can read the PDC team's docs directly:
// https://w.amazon.com/bin/view/PrivacyEngineering/Product/DataClassifier/APIDocs
interface StorageResource {
    foundEntityTypes: FoundEntityType[];
    region: string;
    resourceGroupId: string;
    storageResourceConfiguration: StorageResourceConfiguration;
    storageResourceName: string;
    storageSystem: string;
}

/*
 ClassificationScore is expected to be a floating point number (upto 16 digits after decimal point) in the range
 [0-1], inclusive. This is the raw value that we get from the PDC Response.
 */
export type ClassificationScore = number;

export interface FoundEntityType {
    entityTypeIdentifier: EntityTypeIdentifier;
    highestClassificationScore: ClassificationScore;
}

interface EntityTypeIdentifier {
    entityType: string;
    externalID: string;
    derName: string;
}

interface StorageResourceConfiguration {
    andesTableConfiguration: AndesTableConfiguration | null;
}

interface AndesTableConfiguration {
    tableVersion: number;
}

interface PDCFindingRequest {
    appName: string;
    bindleIDs: string[];
}

export type SaveCommentRequestBody = Omit<CommentFromServer, "id" | "createdAt">;

export class NodeKaleApplicationService extends AbstractKaleService {
    public constructor(kaleConfig: KaleConfig & FeatureToggleConfig, accessTokenGetter: TokenGetter) {
        super(kaleConfig, accessTokenGetter);

        this.fetchComments = this.fetchComments.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.fetchPDCFindings = this.fetchPDCFindings.bind(this);
    }

    public fetchComments(appName: string): Promise<CommentFromServer[]> {
        console.info(`Fetching comments for application: ${appName}`);
        const endpoint = `${this.baseNodeApiEndpoint}/applications/${appName}/comment`;
        return this.signedFetch("GET", endpoint).then((response: Response): Promise<CommentFromServer[]> => {
            if (response.status != 200) {
                const err =
                    `Error fetching comments for application "${appName}". \n\n` +
                    `Status Code: ${response.status} ${response.statusText}`;
                console.error(err);
                throw Error(appendRequestID(err, response));
            }
            return response.json().then((comments: CommentFromServer[]): CommentFromServer[] => {
                return comments;
            }, this.buildDefaultRejection());
        }, this.buildDefaultRejection());
    }

    public saveComment(body: SaveCommentRequestBody): Promise<CommentFromServer> {
        const { applicationName, message } = body;
        console.info(`Saving comment: ${applicationName}`);
        const endpoint = `${this.baseNodeApiEndpoint}/applications/comment`;
        return this.signedFetch("POST", endpoint, JSON.stringify(body)).then(
            (response: Response): Promise<CommentFromServer> => {
                if (response.status != 201) {
                    const err =
                        `Error saving comment: "${message}"` +
                        "\n" +
                        `Status code: ${response.status} ${response.statusText}`;
                    console.error(err);
                    throw Error(appendRequestID(err, response));
                }
                return response.json().then((jsonData: CommentFromServer): CommentFromServer => {
                    return jsonData;
                }, this.buildDefaultRejection());
            },
            this.buildDefaultRejection()
        );
    }

    /**
     * Server data sent would group several platformType resources into single row with relation of 1:N.
     * Each platformType can have N resources and there can be N platformType sent. i.e. array of 1:N ( [1:N] )
     * These rows has to be flattened so that PDC table can iterate over it.
     *
     * Please reference the Comments on sortAndGroupPDCFindings() for sorting/grouping details of the findings.
     *
     * Example Server data:
     * [
     *     {platformType: "type1", storageResources: [{...}, {...}]},
     *     {platformType: "type2", storageResources: [{...}]},
     * ]
     * Frontend format:
     * [{platformType: "type1", ...}, {platformType: "type1", ...}, {platformType: "type2", ...}]
     */
    public fetchPDCFindings(appName: string, bindleIds: string[]): Promise<PDCFinding[]> {
        const endpoint = `${this.baseNodeApiEndpoint}/applications/pdc/findings`;
        const body: PDCFindingRequest = { appName: appName, bindleIDs: bindleIds };
        return this.signedFetch("POST", endpoint, JSON.stringify(body)).then(
            (response: Response): Promise<PDCFinding[]> => {
                if (response.status != 201) {
                    const err = `Error fetching PDC data: status code: ${response.status} ${response.statusText}`;
                    console.error(err);
                    throw Error(appendRequestID(err, response));
                }
                return response.json().then((jsonData: OperationResult<PDCFindingResponse[]>): PDCFinding[] => {
                    const unsortedResults = jsonData.result
                        .map((item): BasePDCFinding[] => {
                            return item.storageResources.map(
                                (row): BasePDCFinding => ({
                                    ...row,
                                    platformType: item.platformType,
                                })
                            );
                        })
                        .flat();
                    return this.sortAndGroupPDCFindings(unsortedResults);
                }, this.buildDefaultRejection());
            },
            this.buildDefaultRejection()
        );
    }

    /**
     * This function sorts and groups the PDC Findings according to requirements so that actionable findings are
     * surfaced to users at the top, and that grouping is maintained as much as possible despite the sorting, in
     * order to maintain readability.
     *
     * Two requirements:
     * 1. Present the Findings with the highest confidence percentages at the top
     * 2. If multiple findings have the same confidence percentage, group findings based on "resourceGroupId"
     * 3. Entities within a finding are sorted by their confidence percentage (descending)
     *
     * Note: Since each finding can have multiple Entities (each entity has it's own confidence percentage), each
     * finding can have up to N confidence percentages. We use the highest confidence percentage. So if a finding has 3
     * entities attached, at 30%, 40% and 99% respectively. The entire finding is treated as a 99% confidence in terms
     * of sorting.
     *
     * Please refer to the Unit test for more examples.
     *
     * @param pdcFindings the PDC Findings to sort and group
     */
    public sortAndGroupPDCFindings(pdcFindings: BasePDCFinding[]): PDCFinding[] {
        // Each PDC Finding is guaranteed to have one entity type attached, but handling edge cases regardless
        const pdcFindingsWithConfidence = pdcFindings.map((pdcFinding): PDCFinding => {
            pdcFinding.foundEntityTypes?.sort((left, right): ClassificationScore => {
                return right.highestClassificationScore - left.highestClassificationScore;
            });

            const INITIAL_HIGH_SCORE = 0;
            const highestClassificationScoreAcrossAllEntities: ClassificationScore =
                pdcFinding.foundEntityTypes?.reduce<ClassificationScore>((currentHighScore, entity) => {
                    const { highestClassificationScore: entityClassificationScore } = entity;
                    return entityClassificationScore > currentHighScore ? entityClassificationScore : currentHighScore;
                }, INITIAL_HIGH_SCORE);

            return { ...pdcFinding, highestClassificationScoreAcrossAllEntities };
        });

        const highScoreToPDCFindings = groupBy<PDCFinding>(
            pdcFindingsWithConfidence,
            "highestClassificationScoreAcrossAllEntities"
        );

        const allUniqueHighScores = Object.keys(highScoreToPDCFindings);
        allUniqueHighScores.sort((left, right): number => {
            // Since we know all the high scores are unique, left and right won't be equal
            return Number(left) > Number(right) ? -1 : 1;
        });

        const finalResults: PDCFinding[] = [];
        allUniqueHighScores.forEach((highScore): void => {
            const pdcFindingsForScore = highScoreToPDCFindings[highScore];
            const resourceGroupIDToPDCFindings = groupBy<PDCFinding>(pdcFindingsForScore, "resourceGroupId");
            for (const [, resGroupPDCFindings] of Object.entries(resourceGroupIDToPDCFindings)) {
                finalResults.push(...resGroupPDCFindings);
            }
        });

        return finalResults;
    }
}
