import * as React from "react";
import { Box, Modal, SpaceBetween, Button } from "@amzn/awsui-components-react-v3";

export default (props: { fieldName: string; show: boolean; onClose: any; onConfirm: any }): JSX.Element => {
    const { fieldName, show, onClose, onConfirm } = props;
    return (
        <Modal
            onDismiss={(): void => onClose()}
            visible={show}
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={(): void => onClose()}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={(): void => onConfirm()}>
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Delete field"
        >
            Are you sure you want to delete the field {fieldName}?
        </Modal>
    );
};
