import { ApplicationInfoState } from "src/components/survey/state/model";
import { ActionType, ApplicationInfoAction } from "src/components/survey/state/actions";

const DEFAULT_STATE: ApplicationInfoState = {
    control_bindle: null,
    related_bindles: [],
    aaaIds: [],
};

const reducer = (
    prevState: ApplicationInfoState = DEFAULT_STATE,
    action: ApplicationInfoAction
): ApplicationInfoState => {
    switch (action.type) {
        case ActionType.ApplicationInfoUpdate: {
            const { control_bindle, related_bindles, aaaIds } = action.payload;
            return {
                control_bindle: control_bindle,
                related_bindles: related_bindles,
                aaaIds: aaaIds,
            };
        }
        default: {
            return prevState;
        }
    }
};

export default reducer;
