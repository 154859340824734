import { QuestionChoice, QuestionType, ReviewAccessor, ReviewAnswer, ReviewQuestion } from "src/answers_legacy";
import { IncrementalSaveResponse, DisplayType, ValueDictionary } from "src/services/IncrementalSave";

/*
 * Finds any answers that are different from the original IncrementalSave response.
 */
export const findChangedAnswers = (
    valueDict: ValueDictionary,
    saveFacadeRes: IncrementalSaveResponse
): ValueDictionary => {
    const difference: ValueDictionary = {};
    for (const [key, value] of Object.entries(valueDict)) {
        if (saveFacadeRes.valueDictionary[key] !== value) {
            difference[key] = value;
        }
    }
    return difference;
};
/*
 * Converts the IncrementalSave response from the backend to a format supported by the GSM.
 */
export const campaignToAnswers = (campaign: IncrementalSaveResponse): ReviewAnswer[] => {
    const answers: ReviewAnswer[] = [];

    for (const field of campaign.fields) {
        let textContent: string | undefined = undefined;
        let arrayContent: string[] | undefined = undefined;
        const value = campaign.valueDictionary[field.key];
        switch (field.displayType) {
            case DisplayType.radiogroup:
                textContent = value as string;
                break;
            case DisplayType.multiselect:
                arrayContent = value as string[];
                break;
        }

        answers.push({
            arrayContent: arrayContent,
            questionId: 0,
            questionShortId: field.key,
            reviewId: 1,
            textContent: textContent,
        });
    }

    return answers;
};
/*
 * Converts the IncrementalSave response into the questions supported by the GSM
 */
export const campaignToQuestions = (campaign: IncrementalSaveResponse): ReviewQuestion[] => {
    return campaign.fields?.map((item): ReviewQuestion => {
        return {
            choices: item.displayOptions.choices.map((choice): QuestionChoice => {
                return {
                    description: choice.description,
                    isExclusive: choice.isExclusive,
                    label: choice.label,
                    value: choice.id,
                };
            }),
            content: item.label ?? "",
            groupId: "",
            id: 0,
            shortId: item.key,
            subtext: item.description ?? "",
            tags: [],
            title: "",
            type: item.displayType as QuestionType,
        };
    });
};
/*
 This converts from the GSM format the the format expected by the backend when saving.
 */
export const accessorsToValueDictionary = (accessors: ReviewAccessor): ValueDictionary => {
    const newValueDictionary: ValueDictionary = {};
    for (const accessor of accessors.answers) {
        const {
            answerWithQuestion: {
                question: { shortId },
            },
            value,
        } = accessor;
        newValueDictionary[shortId] = typeof value === "string" ? (value as string) : (value as string[]);
    }
    return newValueDictionary;
};
