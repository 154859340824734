import { useMemo } from "react";
import { PDCFinding } from "src/services/NodeKaleApplicationService";
import { PDCFilterName } from "../constants";
import {
    selectIsEntityConfidence_51_Through_75,
    selectIsEntityConfidence_76_Through_90,
    selectIsEntityConfidence_91_Or_Greater,
} from "./entity-filters";
import { PDCFindingsFromServer } from "src/components/survey/PersonalDataWizard/steps/personalDataClassifier/types";

export type PDCFilterGroups = Record<PDCFilterName, PDCFinding[]>;
export const usePDCFilterGroups = (pdcFindings: PDCFindingsFromServer): PDCFilterGroups => {
    return useMemo(
        function makeFilterGroups(): PDCFilterGroups {
            // Each PDCFinding contains 1 to many FoundEntityType objects. Each FoundEntityType has a confidence score,
            // aka a "highestClassificationScore". This function places each PDCFinding into one or more filter groups.
            // Every PDCFinding will be contained in the "AllFindings" filter group and then may additionally appear
            // in any/all the other 3 filter groups based on the confidence scores belonging each of the PDCFinding's
            // individual FoundEntityTypes.

            const filterGroups: PDCFilterGroups = {
                [PDCFilterName.AllFindings]: pdcFindings ?? [],
                [PDCFilterName.Confidence_51_Through_75]: [],
                [PDCFilterName.Confidence_76_Through_90]: [],
                [PDCFilterName.Confidence_91_Or_Greater]: [],
            };

            filterGroups.AllFindings.forEach((currentFinding) => {
                let isFindingAlreadyIn_51_Through_75_Group = false;
                let isFindingAlreadyIn_76_Through_90_Group = false;
                let isFindingAlreadyIn_91_Or_Greater_Group = false;

                currentFinding.foundEntityTypes.forEach((entityType) => {
                    // If an entityType's confidence score falls within one of the designated filter group ranges,
                    // add the PDCFinding that owns that entityType into the associated filter group for that
                    // confidence range. A single PDCFinding may be placed into more than 1 filter group but no
                    // PDCFinding should appear in the same filter group more than once
                    if (selectIsEntityConfidence_51_Through_75(entityType)) {
                        if (!isFindingAlreadyIn_51_Through_75_Group) {
                            filterGroups.Confidence_51_Through_75.push(currentFinding);
                            isFindingAlreadyIn_51_Through_75_Group = true;
                        }
                    } else if (selectIsEntityConfidence_76_Through_90(entityType)) {
                        if (!isFindingAlreadyIn_76_Through_90_Group) {
                            filterGroups.Confidence_76_Through_90.push(currentFinding);
                            isFindingAlreadyIn_76_Through_90_Group = true;
                        }
                    } else if (selectIsEntityConfidence_91_Or_Greater(entityType)) {
                        if (!isFindingAlreadyIn_91_Or_Greater_Group) {
                            filterGroups.Confidence_91_Or_Greater.push(currentFinding);
                            isFindingAlreadyIn_91_Or_Greater_Group = true;
                        }
                    }
                });
            });

            return filterGroups;
        },
        [pdcFindings]
    );
};
