import { remove } from "lodash";
import { useCallback, useRef } from "react";

/**
 * This hook is used as pub-sub mechanism for components to listen on web socket messages
 */
const useSubscription = <E, T extends (event: E) => any>(): {
    publish: (event: E) => Promise<void[]>;
    subscribe: (handler: T) => void;
    unsubscribe: (handler: T) => void;
} => {
    const handlers = useRef<T[]>([]);

    const publish = useCallback((event: E): Promise<void[]> => {
        const promises = handlers.current.map((handler): Promise<void> => {
            return new Promise<void>((resolve): void => {
                if (handler) {
                    handler(event);
                }
                resolve();
            });
        });
        return Promise.all(promises);
    }, []);

    const subscribe = useCallback((handler: T): void => {
        handlers.current.push(handler);
    }, []);

    const unsubscribe = useCallback((handler: T): void => {
        remove(handlers.current, (h): boolean => h === handler);
    }, []);

    return {
        publish,
        subscribe,
        unsubscribe,
    };
};

export default useSubscription;
