import React, { useMemo } from "react";
import { FrontEndComment } from "src/components/CommentsView/state/model";
import { selectCommentByFrontEndId } from "src/components/CommentsView/state/selectors";
import { TEST_IDS } from "shared/comments-view";
import { useKaleSelector } from "src/redux/hooks";
import styled from "styled-components";
import CommentBody from "src/components/CommentsView/Comment/CommentBody";
import CommentHeader, { HeadCommentFragments } from "src/components/CommentsView/Comment/CommentHeader";
import { ChevronIcon } from "../styled";

const {
    COMMENT: { ROOT },
} = TEST_IDS.COMMENTS_VIEW;

interface HeadCommentProps {
    isThreadExpanded: boolean;
}

export interface CommentProps {
    frontEndId: number;
    // This should be defined if the comment is the head comment in a comment thread
    headCommentProps?: null | HeadCommentProps;
}

interface MessagePreviewProps {
    isVisible: boolean;
}
const MessagePreview = styled.div`
    margin-top: -5px;
    transition: opacity 0.2s ease;
    transition-delay: 50ms;
    opacity: ${({ isVisible }: MessagePreviewProps): string => (isVisible ? ".7" : "0")};
`;

/**
 * Custom hook responsible for rendering a few additional JSX fragments for Head Comments
 * @param headCommentProps - We expect that this will only be defined if the comment is the head comment in a comment
 * thread
 * @param commentMessage
 */
const useRenderHeadCommentFragments = (
    headCommentProps: HeadCommentProps | null,
    commentMessage: string
): HeadCommentFragments | null => {
    return useMemo((): HeadCommentFragments | null => {
        if (headCommentProps) {
            const { isThreadExpanded } = headCommentProps;

            const iconName = isThreadExpanded ? "angle-down" : "angle-right";
            const chevronIcon = <ChevronIcon variant={"normal"} name={iconName} size={"small"} />;

            const messagePreview = <MessagePreview isVisible={!isThreadExpanded}>{commentMessage}</MessagePreview>;

            return { messagePreview, chevronIcon };
        } else {
            // If this comment is not a head comment, don't need to render additional fragments
            return null;
        }
    }, [headCommentProps, commentMessage]);
};

/**
 * Represents a single comment
 */
const Comment = React.memo(({ frontEndId, headCommentProps = null }: CommentProps): JSX.Element => {
    const commentData = useKaleSelector((state): FrontEndComment => selectCommentByFrontEndId(state, frontEndId));

    // If this is the head comment in a thread, we will need to render a few additional UI fragments
    const headCommentFragments: null | HeadCommentFragments = useRenderHeadCommentFragments(
        headCommentProps,
        commentData.message
    );

    return (
        <div data-testid={ROOT}>
            <CommentHeader commentData={commentData} headCommentFragments={headCommentFragments} />
            <CommentBody commentData={commentData} />
        </div>
    );
});

export default Comment;
