import { TEST_IDS } from "./test-ids";
import { tidSelector } from "../util/selectors";

const {
    COMMENTS_VIEW: {
        COMMENT_THREAD,
        CREATE_COMMENT_THREAD_BUTTON,
        ROOT: COMMENTS_VIEW_ROOT,
        COMMENT,
        COMMENT_THREAD_GROUP,
    },
    COMMENTS_VIEW_SIDEBAR,
} = TEST_IDS;

export const SELECTORS = {
    COMMENTS_VIEW_SIDEBAR: {
        ROOT: tidSelector(COMMENTS_VIEW_SIDEBAR.ROOT),
    },
    COMMENTS_VIEW: {
        ROOT: tidSelector(COMMENTS_VIEW_ROOT),
        CREATE_COMMENT_THREAD_BUTTON: tidSelector(CREATE_COMMENT_THREAD_BUTTON),
        COMMENT: { ROOT: tidSelector(COMMENT.ROOT) },
        COMMENT_THREAD: {
            ROOT: tidSelector(COMMENT_THREAD.ROOT),
            EXPANDED_THREAD_CONTAINER: tidSelector(COMMENT_THREAD.EXPANDED_THREAD_CONTAINER),
            COLLAPSED_THREAD_CONTAINER: tidSelector(COMMENT_THREAD.COLLAPSED_THREAD_CONTAINER),
            EXPAND_COLLAPSE_TRIGGER: tidSelector(COMMENT_THREAD.EXPAND_COLLAPSE_TRIGGER),
            REPLY_BUTTON: tidSelector(COMMENT_THREAD.REPLY_BUTTON),
            SAVE_BUTTON: tidSelector(COMMENT_THREAD.SAVE_BUTTON),
            CANCEL_BUTTON: tidSelector(COMMENT_THREAD.CANCEL_BUTTON),
        },
        COMMENT_THREAD_GROUP: { ROOT: tidSelector(COMMENT_THREAD_GROUP.ROOT) },
    },
};
