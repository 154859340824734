import * as React from "react";
import { useCallback } from "react";
import { Autosuggest, AutosuggestProps, FormField, NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";

interface Props extends FunctionalSurveyItemProps<string> {
    options: string[];
}

const NewAutosuggest = (props: Props): JSX.Element => {
    const { id, isRequired, isFormInReadonlyMode = false, options, expectedAnswer = "", onChangeCallback } = props;

    const handleOnChange = useCallback(
        (event: NonCancelableCustomEvent<AutosuggestProps.ChangeDetail>): void => {
            const value = event.detail.value;
            onChangeCallback?.call(null, {
                id: id,
                response: value,
            });
        },
        [id, onChangeCallback]
    );

    return (
        <FormField stretch={true} errorText={isRequired && !Boolean(expectedAnswer) ? "Required" : ""}>
            <div>
                <Autosuggest
                    disabled={isFormInReadonlyMode}
                    options={options.map((option): AutosuggestProps.Option => {
                        return { value: option };
                    })}
                    empty={"Enter custom value"}
                    placeholder={"Choose an option or enter custom value"}
                    value={expectedAnswer}
                    onChange={handleOnChange}
                    enteredTextLabel={(value: string): string => value}
                />
            </div>
        </FormField>
    );
};

export default withFunctionalReadonly(NewAutosuggest);
