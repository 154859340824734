import { Button } from "@amzn/awsui-components-react-v3";
import React, { useContext, useState } from "react";
import {
    connectDraftToLiveComment,
    DraftReplyCommentOptions,
    exportCommentForSaveOperation,
} from "src/components/CommentsView/state/front-end-comments";
import { addingNewReplyComment, commentCancelled, commentSaved } from "src/components/CommentsView/state/actions";
import {
    selectApplicationName,
    selectCommentByFrontEndId,
    selectUser,
} from "src/components/CommentsView/state/selectors";
import { TEST_IDS } from "shared/comments-view";
import {
    ConnectedComment,
    DraftComment,
    FrontEndComment,
    FrontEndCommentType,
} from "src/components/CommentsView/state/model";
import KaleContext from "src/components/KaleContext";
import { MessageType } from "src/components/survey/KaleRoutes";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { isStringNullOrTrimEmpty } from "src/util/StringUtil";
import Error from "src/util/Error";
import styled from "styled-components";
import { useKaleDispatch, useKaleSelector } from "src/redux/hooks";

const {
    COMMENT_THREAD: { REPLY_BUTTON, CANCEL_BUTTON, SAVE_BUTTON },
} = TEST_IDS.COMMENTS_VIEW;

interface CommentThreadActionsProps {
    headCommentId: number;
    tailCommentId: number;
}

const EMPTY_COMMENT_ERROR = "Message cannot be empty";

const isValidCommentMessage = (message: string): boolean => !isStringNullOrTrimEmpty(message);

const StyledButton = styled(Button)`
    margin-left: 10px;
`;

const StyledContainer = styled.div`
    text-align: right;
`;

const CommentThreadActions = React.memo(({ headCommentId, tailCommentId }: CommentThreadActionsProps): JSX.Element => {
    const applicationName = useKaleSelector(selectApplicationName);
    const user = useKaleSelector(selectUser);

    const headComment = useKaleSelector((state): FrontEndComment => selectCommentByFrontEndId(state, headCommentId));
    const tailComment = useKaleSelector((state): FrontEndComment => selectCommentByFrontEndId(state, tailCommentId));

    const [validationError, setValidationError] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const dispatch = useKaleDispatch();

    const {
        service: { nodeKaleAppService },
    } = useContext(KaleContext);

    const { displayMessage } = useMessageBannerActions();

    // There can only ever be at most one draft comment in a thread at a
    // time and it can only be the last comment in the thread
    const doesThreadContainDraftComment = tailComment.commentType === FrontEndCommentType.DraftComment;

    const handleReplyButtonClick = (): void => {
        const addReplyOptions: DraftReplyCommentOptions = {
            frontEndParentId: headCommentId,
            user,
            applicationName,
        };
        dispatch(addingNewReplyComment(addReplyOptions));
    };

    const handleCancelButtonClick = (): void => {
        setValidationError("");
        dispatch(commentCancelled(tailComment.frontEndId));
    };

    const handleSaveButtonClick = async (): Promise<void> => {
        if (isValidCommentMessage(tailComment.message)) {
            setValidationError("");
            setIsSaving(true);

            // Since there is no support to edit existing comments, we assume
            // that only the tail comment in a comment thread is the one that
            // can be in draft. If there is only one comment in the thread, it
            // means we are saving the head comment
            const draftComment = tailComment as DraftComment;
            const isHeadCommentBeingSaved = draftComment === headComment;
            const backEndParentId = isHeadCommentBeingSaved
                ? null /* headComment should not have any parent */
                : (headComment as ConnectedComment).backEndId;

            const saveCommentRequestBody = exportCommentForSaveOperation(tailComment as DraftComment, backEndParentId);
            try {
                const response = await nodeKaleAppService.saveComment(saveCommentRequestBody);
                const liveComment = (response as any).result || response;
                const connectedComment = connectDraftToLiveComment(draftComment, liveComment);
                dispatch(commentSaved(connectedComment));
            } catch (e) {
                displayMessage(MessageType.error, (e as Error).message);
            } finally {
                setIsSaving(false);
            }
        } else {
            setValidationError(EMPTY_COMMENT_ERROR);
        }
    };

    return (
        <StyledContainer>
            {doesThreadContainDraftComment ? (
                <StyledContainer>
                    {validationError && <Error text={validationError} />}
                    <StyledButton
                        loading={isSaving}
                        data-testid={SAVE_BUTTON}
                        variant={"primary"}
                        onClick={(): Promise<void> => handleSaveButtonClick()}
                    >
                        Save
                    </StyledButton>
                    <StyledButton
                        loading={isSaving}
                        data-testid={CANCEL_BUTTON}
                        onClick={(): void => handleCancelButtonClick()}
                    >
                        Cancel
                    </StyledButton>
                </StyledContainer>
            ) : (
                <Button data-testid={REPLY_BUTTON} variant={"primary"} onClick={(): void => handleReplyButtonClick()}>
                    Reply
                </Button>
            )}
        </StyledContainer>
    );
});

export default CommentThreadActions;
