import { Button, Form, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "src/components/fields/Loader";
import KaleContext from "src/components/KaleContext";
import { AndesMetadata } from "src/components/schema_table/importers/andes_importer/AndesImporter";
import { BumblebeeMetadata } from "src/components/schema_table/importers/bumblebee_importer/BumblebeeImporter";
import { TableDefinition } from "src/components/schema_table/SchemaTable";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { MessageType, KaleRoutesProps } from "src/components/survey/KaleRoutes";
import { CreateTableRequest, FieldBase, TableSources, TableStatus } from "src/services/KaleTablesService";
import { flatten } from "src/util/KaleUtil";
import { KaleApplicationRouteParams } from "src/components/survey/KaleApplication/KaleApplicationRoutes";
import { chunk } from "lodash";
import { kaleUrls } from "src/util/Urls";

export const TEST_IDS = {
    SPINNER: "schema-table-import-form-spinner",
    IMPORT_BUTTON: "schema-table-import-form-import-button",
    CANCEL_BUTTON: "schema-table-import-form-cancel-button",
};

interface Props {
    tableDefinitions: TableDefinition[];
    dataStore: DataStoreResponse;
    source: TableSources;
    andesSourceMetadata?: AndesMetadata;
    bumblebeeSourceMetadata?: BumblebeeMetadata[];
    isDisabled: boolean;
    isMultiTable?: boolean;
    header?: React.ReactNode;
    children: React.ReactNode;
}

export interface ImporterRouteParams extends KaleApplicationRouteParams {
    dataStoreId: string;
}

const ImportForm = (props: Props & KaleRoutesProps): JSX.Element => {
    const {
        tableDefinitions,
        dataStore,
        source,
        andesSourceMetadata,
        bumblebeeSourceMetadata,
        header = "",
        isDisabled,
        isMultiTable,
        children,
        displayMessage,
    } = props;
    const { createTable, createTables } = useContext(KaleContext).service.kaleTablesService;
    const history = useHistory();
    const params = useParams<ImporterRouteParams>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onCancel = useCallback((): void => {
        history.push({
            pathname: kaleUrls.editKaleRecordUrl(params.applicationName, params.reviewId),
        });
    }, [history, params.applicationName, params.reviewId]);

    /**
     * Currently we are having a hard limit of 100 tables per API call in backend.
     * When we get more than 100 tables we need to call the API in batches (100 each).
     * As of now we are not making this request in parallel but in a synchronous manner.
     */
    const handleMultiTable = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        const chunks = chunk<TableDefinition>(tableDefinitions, 100);
        try {
            for (const c of chunks) {
                const request = c.map((td): CreateTableRequest => {
                    const fields = td.schema.map((schema): FieldBase => {
                        return {
                            name: schema.name,
                            description: schema.description ?? "",
                            type: schema.type,
                            sampleValues: schema.samples?.join(", ") ?? "",
                            createdAt: new Date().toISOString(),
                            updatedAt: new Date().toISOString(),
                        };
                    });
                    const metaData = bumblebeeSourceMetadata?.find(
                        (metadata): boolean => td.name === metadata.tableName
                    );
                    return {
                        table: {
                            dataStoreName: dataStore.name,
                            description: "",
                            hasPersonalData: "",
                            dataStoreId: Number(params.dataStoreId) ?? 0,
                            dataStoreTech: dataStore.technology,
                            name: metaData?.tableName ?? td.name ?? "name_not_found",
                            fieldCount: fields.length,
                            status: TableStatus.IdentifyDataset,
                            source: source,
                            sourceMetadata: metaData,
                        },
                        fields: fields,
                    };
                });
                await createTables(params.applicationName, Number(params.dataStoreId), request).catch(
                    (err: Error): void => {
                        displayMessage?.call(null, MessageType.error, err.message);
                    }
                );
            }
            setIsLoading(false);
            history.push({
                pathname: kaleUrls.editKaleRecordUrl(params.applicationName, params.reviewId),
            });
        } catch (err) {
            displayMessage?.call(null, MessageType.error, (err as Error).message);
            setIsLoading(false);
        }
    }, [
        bumblebeeSourceMetadata,
        createTables,
        dataStore.name,
        dataStore.technology,
        displayMessage,
        history,
        params.dataStoreId,
        params.applicationName,
        params.reviewId,
        source,
        tableDefinitions,
    ]);
    const handleSingleTable = useCallback((): void => {
        const fields = flatten(
            tableDefinitions.map((td): FieldBase[] => {
                return td.schema.map((schema): FieldBase => {
                    return {
                        name: schema.name,
                        description: schema.description ?? "",
                        type: schema.type,
                        sampleValues: schema.samples?.join(", ") ?? "",
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                    };
                });
            })
        );
        const request: CreateTableRequest = {
            table: {
                dataStoreName: dataStore.name,
                description: "",
                hasPersonalData: "",
                dataStoreId: Number(params.dataStoreId) ?? 0,
                dataStoreTech: dataStore.technology,
                name: tableDefinitions[0].name,
                fieldCount: fields.length,
                status: TableStatus.IdentifyDataset,
                source: source,
                sourceMetadata: andesSourceMetadata,
            },
            fields: fields,
        };
        createTable(params.applicationName, Number(params.dataStoreId), request)
            .then((): void => {
                setIsLoading(false);
                history.push({
                    pathname: kaleUrls.editKaleRecordUrl(params.applicationName, params.reviewId),
                });
            })
            .catch((err: Error): void => {
                displayMessage?.call(null, MessageType.error, err.message);
                setIsLoading(false);
            });
    }, [
        createTable,
        dataStore.name,
        dataStore.technology,
        displayMessage,
        history,
        params.dataStoreId,
        params.applicationName,
        params.reviewId,
        source,
        andesSourceMetadata,
        tableDefinitions,
    ]);

    const renderActionButtons = (): JSX.Element => {
        return (
            <SpaceBetween size={"xs"} direction={"horizontal"}>
                <Button data-testid={TEST_IDS.CANCEL_BUTTON} onClick={onCancel}>
                    Cancel
                </Button>

                <Button
                    data-testid={TEST_IDS.IMPORT_BUTTON}
                    variant="primary"
                    formAction="none"
                    disabled={isDisabled}
                    onClick={isMultiTable ? handleMultiTable : handleSingleTable}
                >
                    Import Schema
                </Button>
            </SpaceBetween>
        );
    };

    return (
        <React.Fragment>
            {isLoading && <Loader data-testid={TEST_IDS.SPINNER} />}
            <Form header={header} actions={renderActionButtons()}>
                {children}
            </Form>
        </React.Fragment>
    );
};

export default ImportForm;
