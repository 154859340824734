import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { DescbribeResourceResponse } from "src/services/KaleApplicationService";

export interface ControlBindleInfoContext {
    controlBindle?: DescbribeResourceResponse;
    errorMsg?: string;
    isLoading: boolean;
}

const useFetchControlBindleInfo = (bindleID?: string): ControlBindleInfoContext => {
    const [controlBindle, setBindle] = useState<ControlBindleInfoContext["controlBindle"]>(undefined);
    const [isLoading, setIsLoading] = useState<ControlBindleInfoContext["isLoading"]>(false);
    const [errorMsg, setErrorMsg] = useState<ControlBindleInfoContext["errorMsg"]>("");

    const {
        service: { kaleAppService },
    } = useContext(KaleContext);
    const dependencies = { kaleAppService };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    useEffect((): void => {
        // https://developer.mozilla.org/en-US/docs/Glossary/IIFE
        (async function IIFE(): Promise<void> {
            setIsLoading(true);
            const { kaleAppService } = depsRef.current;
            try {
                if (bindleID === undefined) {
                    return;
                }
                const controlBindle = await kaleAppService.describeResourceById(bindleID);
                setBindle(controlBindle);
                setErrorMsg("");
            } catch (err) {
                console.log(err);
                setErrorMsg((err as Error).message);
                setBindle(undefined);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [bindleID]);

    return { controlBindle, errorMsg, isLoading };
};

const ControlBindleInfoContext = createContext<undefined | ControlBindleInfoContext>(undefined);

interface ControlBindleInfoProviderProps {
    children: React.ReactNode;
    controlBindleID?: string;
}

/**
 * Component is responsible for fetching a controlBindle resource for the controlBindleID prop that it receives and will
 * then publish that controlBindle data to its children via a Context object. Callers should use this component to
 * fetch and provide ControlBindle data to their react subtree if their subtree does not already have
 * this component as an ancestor.
 */
const ControlBindleInfoProvider = ({ controlBindleID, children }: ControlBindleInfoProviderProps): JSX.Element => {
    const fetchControlBindleState = useFetchControlBindleInfo(controlBindleID);

    const { Provider } = ControlBindleInfoContext;
    return <Provider value={fetchControlBindleState}>{children}</Provider>;
};

/**
 * A custom hook that reads from the ControlBindle Context object and returns the data to the calling component.
 * Callers should use this hook to receive the ControlBindle data into their components.
 *
 * You MUST have ControlBindleInfoProvider used in the component tree above wherever this function is called.
 */
const useControlBindleInfoContext = (): ControlBindleInfoContext => {
    const controlBindleContext = useContext(ControlBindleInfoContext);
    if (controlBindleContext === undefined) {
        throw new Error("useControlBindleInfoContext must be used within a ControlBindleInfoProvider");
    }
    return controlBindleContext;
};

export { ControlBindleInfoProvider, useControlBindleInfoContext };
