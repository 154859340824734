import React, { useContext } from "react";
import { ConfirmActionModalButton } from "src/components/ConfirmActions";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { ActionsDropdownPadding, DeleteModalButton } from "src/components/survey/DataStoreInfo/DataStoreTable/styled";
import { Button, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/survey";
import {
    ApplicationStatus,
    ApprovalStatus,
    ApprovalType,
    DSARDeletionApprovedStatus,
    SurveyContext,
} from "src/components/survey/SurveyFormModel";
import { UserRole } from "src/permissions";
// eslint-disable-next-line max-len
import ComplianceToolsDropdown from "src/components/survey/DataStoreInfo/ComplianceToolsDropdown/ComplianceToolsDropdown";

const { ROOT, ADD_DATA_STORE_BUTTON, DELETE_DATA_STORE, EDIT_DATA_STORE_BUTTON } =
    TEST_IDS.DATA_STORE_INFO.DATA_STORE_TABLE.ACTIONS;

interface DataStoreTableActionStripeProps {
    onAddDataStoreClick: () => void;
    onEditDataStoreClick: () => void;
    onDeleteDataStoreClick: () => void;
    isReadOnly: boolean;
    responses: DataStoreResponse[];
    selectedItem: DataStoreResponse | null;
}

export const complianceToolDropdownIsDisabled = (
    statuses: ApprovalStatus[],
    userRole: UserRole,
    deletionOnboardingApproved: DSARDeletionApprovedStatus,
    dsarOnboardingApproved: DSARDeletionApprovedStatus
): boolean => {
    const appStatusIsValid = statuses.some(
        (status): boolean =>
            status.type === ApprovalType.PrivacyApproval && status.status === ApplicationStatus.approved
    );

    // The dropdown should be clickable if either onboarding is approved
    // granular disabling will be handled by the dropdown component itself
    const onboardingApproved =
        deletionOnboardingApproved === DSARDeletionApprovedStatus.Yes ||
        dsarOnboardingApproved === DSARDeletionApprovedStatus.Yes;

    const isAValidRole = [UserRole.admin, UserRole.serviceOwner].includes(userRole);

    return !appStatusIsValid || !isAValidRole || !onboardingApproved;
};

const DataStoreTableActionStripe = (props: DataStoreTableActionStripeProps): JSX.Element => {
    const { onAddDataStoreClick, onEditDataStoreClick, onDeleteDataStoreClick, selectedItem, isReadOnly } = props;
    const survey = useContext(SurveyContext);
    const { approvalStatuses, deletion_onboarding_approved, dsar_onboarding_approved } =
        survey.application.appInfo.review;

    return (
        <SpaceBetween data-testid={ROOT} size={"xs"} direction={"horizontal"}>
            <ActionsDropdownPadding>
                <ComplianceToolsDropdown
                    disabled={complianceToolDropdownIsDisabled(
                        approvalStatuses,
                        survey.role,
                        deletion_onboarding_approved,
                        dsar_onboarding_approved
                    )}
                    deletionOnboardingApproved={deletion_onboarding_approved}
                />
            </ActionsDropdownPadding>
            {selectedItem && (
                <DeleteModalButton>
                    <ConfirmActionModalButton
                        testIds={{
                            cancelButton: DELETE_DATA_STORE.MODAL_CANCEL_BUTTON,
                            confirmButton: DELETE_DATA_STORE.MODAL_CONFIRM_BUTTON,
                            triggerButton: DELETE_DATA_STORE.MODAL_TRIGGER_BUTTON,
                            modalRoot: DELETE_DATA_STORE.MODAL_ROOT,
                        }}
                        actionLabel={"Delete"}
                        triggerButtonOverrides={{
                            variant: "normal",
                            disabled: isReadOnly,
                        }}
                        modalProps={{
                            header: "Delete data store",
                            content:
                                `Are you sure you want to delete the data store ` +
                                `"${(selectedItem as DataStoreResponse).name}"`,
                            onConfirm: onDeleteDataStoreClick,
                        }}
                    />
                </DeleteModalButton>
            )}
            {selectedItem && (
                <Button
                    data-testid={EDIT_DATA_STORE_BUTTON}
                    disabled={false}
                    onClick={(event): void => {
                        event.stopPropagation();
                        onEditDataStoreClick();
                    }}
                >
                    {isReadOnly ? "View" : "Edit"}
                </Button>
            )}
            <Button
                data-testid={ADD_DATA_STORE_BUTTON}
                disabled={isReadOnly}
                onClick={(event): void => {
                    event.stopPropagation();
                    onAddDataStoreClick();
                }}
            >
                Add data store
            </Button>
        </SpaceBetween>
    );
};

export default DataStoreTableActionStripe;
