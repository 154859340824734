import { ControlBindleInfoContext } from "src/components/controlBindleInfoContext";

export const generateNewSandfireOnboardingLinkString = (
    applicationName: string,
    controlBindleState: ControlBindleInfoContext,
    sandfireBaseURL: string | undefined
): string => {
    if (sandfireBaseURL === undefined) {
        throw new Error("sandfireBaseURL was not properly set in the stages.json file");
    }
    const linkURL = new URL(`${sandfireBaseURL}/dso/wizard/step/collect-data?`);
    linkURL.searchParams.append("kaleAttestationIds", applicationName);
    if (controlBindleState.controlBindle?.cti) {
        const [category, type, item] = controlBindleState.controlBindle.cti.split("/");
        linkURL.searchParams.append("category", category);
        linkURL.searchParams.append("type", type);
        linkURL.searchParams.append("item", item);
    }
    if (controlBindleState.controlBindle?.contactEmail) {
        linkURL.searchParams.append("contact", controlBindleState.controlBindle?.contactEmail);
    }
    if (controlBindleState.controlBindle?.owningTeam) {
        linkURL.searchParams.append("team", controlBindleState.controlBindle.owningTeam.teamId);
    }
    return linkURL.toString();
};
