import { WizardProps } from "src/components/InlineWizard/interfaces";

export * from "./personalDataExplanation";
export * from "./lineage";
export * from "./personalDataClassifier";
export * from "./personalDataQuestion";
export * from "./summary";

export interface PersonalDataWizardStep {
    isLoading: boolean;
    step: WizardProps.Step;
    error: string;
}

export enum PDWStepName {
    personalDataExplanation = "personalDataExplanation",
    lineage = "lineage",
    personalDataClassifier = "personalDataClassifier",
    personalDataQuestion = "personalDataQuestion",
    summary = "summary",
}

export const PDWStepToIndexMap: Record<PDWStepName, number> = {
    personalDataExplanation: 0,
    lineage: 1,
    personalDataClassifier: 2,
    personalDataQuestion: 3,
    summary: 4,
};
