import React from "react";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { DisplayLabels } from "src/components/survey/AccessControl/section/util";
import { Box, TokenGroup, TokenGroupProps } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/fields";

export const aaaIds = (application: SurveyResponse): Pair => {
    const items = application.appInfo.review.aaaIdentifier.map((aaa): TokenGroupProps.Item => {
        return { label: aaa, disabled: true };
    });
    return {
        label: DisplayLabels.AAA_APP_IDS,
        testId: TEST_IDS.ACCESS_CONTROL_SECTION.AAA_APP_IDS,
        value:
            items.length > 0 ? (
                <TokenGroup
                    limit={2}
                    items={items}
                    i18nStrings={{ limitShowMore: "Show more", limitShowFewer: "Show less" }}
                />
            ) : (
                <Box variant="span">&mdash;</Box>
            ),
    };
};
