import React, { useState } from "react";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import Loader from "src/components/fields/Loader";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import NewApplicationForm from "src/components/NewApplication/NewApplicationForm";
import useFetchBindlesOnMount from "src/components/NewApplication/useFetchBindlesOnMount";
import { useLocationState } from "src/hooks/useLocationState";

const breadCrumbItems = [BreadcrumbItems.KALE_LANDING_PAGE, BreadcrumbItems.CREATE_KALE_RECORD];

// For complete understanding of the frontend + backend workflow for /new page, see sequence diagrams below
// Non-Anvil Flow: https://tiny.amazon.com/10v1bd7md/plancorpamazplanformencohtml
// Anvil Flow: https://tiny.amazon.com/1jo89m452/plancorpamazplanformencohtml
const NewApplicationPage = (): JSX.Element => {
    const { bindlesState } = useFetchBindlesOnMount();
    const [isCreatingApp, setIsCreatingApp] = useState<boolean>(false);
    const { anvilProps } = useLocationState();

    return (
        <React.Fragment>
            {bindlesState.isLoading || (isCreatingApp && <Loader />)}
            <AppLayout
                breadcrumbs={<KaleBreadcrumbGroup ariaLabel="Breadcrumbs" items={breadCrumbItems} />}
                navigationHide={true}
                toolsHide={true}
                stickyNotifications={true}
                headerSelector="#top-nav"
                content={
                    <NewApplicationForm
                        bindlesState={bindlesState}
                        setIsCreatingApp={setIsCreatingApp}
                        anvilProps={anvilProps}
                    />
                }
            />
        </React.Fragment>
    );
};

export default React.memo(NewApplicationPage);
