import React, { useState } from "react";
import { HeaderOverlayButton } from "../styled";
import AnimateHeight from "react-animate-height";
import Comment, { CommentProps } from "src/components/CommentsView/Comment";
import { Container, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import {
    selectCommentByFrontEndId,
    selectCommentThreadByHeadCommentId,
} from "src/components/CommentsView/state/selectors";
import { TEST_IDS } from "shared/comments-view";
import CommentThreadActions from "src/components/CommentsView/CommentThread/CommentThreadActions";
import {
    CommentThread as CommentThreadData,
    FrontEndComment,
    FrontEndCommentType,
} from "src/components/CommentsView/state/model";
import { useKaleSelector } from "src/redux/hooks";
import styled from "styled-components";
import { COMMENT_HEADER_PADDING_BOX_HEIGHT } from "src/components/CommentsView/constants";

const {
    COMMENT_THREAD: { ROOT, COLLAPSED_THREAD_CONTAINER, EXPANDED_THREAD_CONTAINER, EXPAND_COLLAPSE_TRIGGER },
} = TEST_IDS.COMMENTS_VIEW;

interface CommentThreadProps {
    headCommentId: number;
}

const ThreadHeights = {
    // When a comment thread is collapsed, only the comment header of the
    // thread's head-comment should be visible. e.g. restrict the height of the
    // thread container to only show the header of the head-comment
    collapsed: COMMENT_HEADER_PADDING_BOX_HEIGHT,
    expanded: "auto",
};

const CommentThreadLiner = styled.div`
    position: relative;
    padding-bottom: 20px;
    padding-right: 20px;
    overflow-x: visible;
`;

/**
 * Responsible for rendering a Comment Thread as part of the CommentsView feature
 */
const CommentThread = React.memo(({ headCommentId }: CommentThreadProps): JSX.Element => {
    const { commentIds } = useKaleSelector(
        (state): CommentThreadData => selectCommentThreadByHeadCommentId(state, headCommentId)
    );

    const headComment = useKaleSelector((state): FrontEndComment => selectCommentByFrontEndId(state, headCommentId));
    const isBrandNewCommentThread = headComment.commentType === FrontEndCommentType.DraftComment;
    const [isExpanded, setIsExpanded] = useState<boolean>(isBrandNewCommentThread);

    const toggleExpandedState = (): void => setIsExpanded(!isExpanded);

    const threadHeight = isExpanded ? ThreadHeights.expanded : ThreadHeights.collapsed;

    const headCommentProps: CommentProps["headCommentProps"] = {
        isThreadExpanded: isExpanded,
    };

    const tailCommentId = commentIds[commentIds.length - 1];
    const containerTestId = isExpanded ? EXPANDED_THREAD_CONTAINER : COLLAPSED_THREAD_CONTAINER;

    return (
        <div data-testid={ROOT}>
            <Container data-testid={containerTestId} disableContentPaddings={true}>
                <AnimateHeight height={threadHeight}>
                    <CommentThreadLiner>
                        <HeaderOverlayButton
                            data-testid={EXPAND_COLLAPSE_TRIGGER}
                            onClick={(): void => toggleExpandedState()}
                        />
                        <SpaceBetween direction={"vertical"} size={"xxl"}>
                            {commentIds.map((frontEndId): JSX.Element => {
                                return frontEndId === headCommentId ? (
                                    <Comment
                                        key={frontEndId}
                                        frontEndId={frontEndId}
                                        headCommentProps={headCommentProps}
                                    />
                                ) : (
                                    <Comment key={frontEndId} frontEndId={frontEndId} />
                                );
                            })}
                            <CommentThreadActions headCommentId={headCommentId} tailCommentId={tailCommentId} />
                        </SpaceBetween>
                    </CommentThreadLiner>
                </AnimateHeight>
            </Container>
        </div>
    );
});

export default CommentThread;
