import { DatePicker, DatePickerProps, NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/index";
import { AnswerBase, HookAnswer, QuestionBase } from "src/answers_legacy";
import React, { useContext } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
export type DateChangeEvent = NonCancelableCustomEvent<DatePickerProps.ChangeDetail>;

type DateQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
} & FormQuestionForwardingProps;

export const DateQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    props: DateQuestionProps<QType, AType>
): JSX.Element => {
    const {
        "data-testid": dataTestId,
        questionAnswer: { value, setValue },
    } = props;

    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;
    const date = value as string;

    return (
        <DatePicker
            data-selectid={dataTestId}
            value={date}
            disabled={isReadonly}
            placeholder="YYYY/MM/DD"
            todayAriaLabel="Today"
            nextMonthAriaLabel="Next month"
            previousMonthAriaLabel="Previous month"
            onChange={(e: DateChangeEvent): void => setValue(e.detail.value)}
        />
    );
};
