import { Tiles, TilesProps } from "@amzn/awsui-components-react-v3";
import React from "react";
import { PDCFilterName } from "../../constants";
import { useRecordFiltersLoadedEvent } from "./useRecordFiltersLoadedEvent";
import { useRecordFilterChangedEventCb } from "./useRecordFilterChangedEventCb";
import { PDCFiltersProps } from "./types";

export const PDCFilters = (props: PDCFiltersProps): JSX.Element => {
    const { filterGroups, selectedFilter, setSelectedFilter } = props;

    useRecordFiltersLoadedEvent({ filterGroups, selectedFilter });

    const recordFilterChangedEvent = useRecordFilterChangedEventCb();

    const handleFilterChange: TilesProps["onChange"] = ({ detail }) => {
        const newSelectedFilter = detail.value as PDCFilterName;
        setSelectedFilter(newSelectedFilter);

        const newSelectedFilterItemsCount = filterGroups[newSelectedFilter].length;
        recordFilterChangedEvent({
            filterName: newSelectedFilter,
            filteredItemsCount: newSelectedFilterItemsCount,
        });
    };

    return (
        <Tiles
            columns={4}
            onChange={handleFilterChange}
            value={selectedFilter}
            items={[
                {
                    value: PDCFilterName.AllFindings,
                    label: `All Findings (${filterGroups.AllFindings.length})`,
                },
                {
                    value: PDCFilterName.Confidence_91_Or_Greater,
                    label: `91-100% Confidence (${filterGroups.Confidence_91_Or_Greater.length})`,
                },
                {
                    value: PDCFilterName.Confidence_76_Through_90,
                    label: `76-90% Confidence (${filterGroups.Confidence_76_Through_90.length})`,
                },
                {
                    value: PDCFilterName.Confidence_51_Through_75,
                    label: `51-75% Confidence (${filterGroups.Confidence_51_Through_75.length})`,
                },
            ]}
        />
    );
};
