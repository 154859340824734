export enum AccessControlPair {
    RELATED_BINDLES,
    AAA_APP_IDS,
    ANVIL_ID,
    TASK_ID,
    APP_OWNER,
    EXPLANATION,
}

export enum DisplayLabels {
    RELATED_BINDLES = "Related Bindles",
    AAA_APP_IDS = "AAA Application Ids",
    ANVIL_ID = "Anvil Application Id",
    TASK_ID = "Anvil Task Id",
    APP_OWNER = "Anvil Application Owner",
    EXPLANATION = "Explanation",
}
