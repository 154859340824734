import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { StyledSpan } from "src/components/ApprovalStatuses/utils";
import { HelpIcon } from "src/components/HelpIcon";
import React, { useContext } from "react";
import KaleContext from "src/components/KaleContext";

export interface DescriptionWithInfoLinkProps {
    displayText: React.ReactNode;
    content: React.ReactNode;
}

export const DescriptionWithInfoLink = (props: DescriptionWithInfoLinkProps): JSX.Element => {
    const {
        features: { isAccessControlPageLive },
    } = useContext(KaleContext);
    return (
        <SpaceBetween direction={"horizontal"} size={"xxs"}>
            <StyledSpan>{props.displayText}</StyledSpan>
            {!isAccessControlPageLive && <HelpIcon content={props.content}></HelpIcon>}
        </SpaceBetween>
    );
};
