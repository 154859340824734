import React from "react";

/**
 * Helper function to extract text from ReactChild
 * @param node
 */
export const getNodeText = (node: React.ReactChild): string | undefined => {
    if (["string", "number"].includes(typeof node)) {
        return node as string;
    }

    if ((node as unknown as React.ReactChild[]) instanceof Array) {
        return (node as unknown as React.ReactChild[]).map(getNodeText).join(" ");
    }

    if (typeof node === "object" && node) {
        return getNodeText(node.props.children);
    }
};
