import React, { useMemo } from "react";
import {
    ContentDescriptor,
    isWrappedItemDescriptor,
    isWrappedListDescriptor,
} from "src/services/summaries/model/content-descriptors";
import { ItemList } from "src/components/survey/ApplicationOverview/ContentPane/ItemList";
import { Item } from "src/components/survey/ApplicationOverview/ContentPane/Item";
import { TEST_IDS } from "shared/content-panes";

const { CONTENT_PANE } = TEST_IDS;

const _renderDescriptor = (content: ContentDescriptor): React.ReactNode => {
    if (isWrappedItemDescriptor(content)) {
        const { ItemDescriptor } = content;
        return <Item key={ItemDescriptor.id} item={ItemDescriptor} />;
    } else if (isWrappedListDescriptor(content)) {
        const { ListDescriptor } = content;
        return <ItemList key={ListDescriptor.id} list={ListDescriptor} />;
    } else {
        const unknownDescriptorError = `Encountered unknown content descriptor: \n ${JSON.stringify(content)}`;
        throw new Error(unknownDescriptorError);
    }
};

const renderDescriptors = (descriptors: ContentDescriptor[]): React.ReactNode => {
    return descriptors.map(_renderDescriptor);
};

export interface ContentPaneProps {
    descriptors: ContentDescriptor[];
    "data-testid": string;
}

/**
 * Use this component to render heterogeneous lists of any ContentDescriptors,
 */
export const ContentPane = ({ descriptors, "data-testid": testId }: ContentPaneProps): JSX.Element => {
    const renderedContent = useMemo((): React.ReactNode => {
        if (descriptors.length) {
            return <div data-testid={CONTENT_PANE.RENDERED_CONTENT}>{renderDescriptors(descriptors)}</div>;
        } else {
            return null;
        }
    }, [descriptors]);

    return <div data-testid={testId}>{renderedContent}</div>;
};
