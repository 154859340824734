import React from "react";
import WizardForm from "./wizard-form";
import WizardNavigation, { NAV_MARGIN, NAV_WIDTH } from "./wizard-navigation";
import { InlineWizardProps, WizardProps } from "./interfaces";
import { fireNonCancelableEvent } from "./DEPRECATED_polarisEvents";

import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
`;

const Content = styled.div`
    width: calc(100% - ${NAV_WIDTH} - ${NAV_MARGIN}) !important;
`;

export type { InlineWizardProps };
// TODO - clean up to use props instead of destructuring
export default function KaleInlineWizard({
    steps,
    activeStepIndex,
    hideSubmitButton,
    i18nStrings,
    isLoadingNextStep = false,
    disableSideNavigation,
    onSubmit,
    onNavigate,
    farthestStepIndex = activeStepIndex,
    ...rest
}: InlineWizardProps): JSX.Element {
    const baseProps = rest;

    const navigationEvent = (requestedStepIndex: number, reason: WizardProps.NavigationReason) => {
        fireNonCancelableEvent(onNavigate, { requestedStepIndex, reason });
    };

    const isLastStep = activeStepIndex >= steps.length - 1;
    return (
        <div data-testid={"inline-wizard"} {...baseProps}>
            <Wrapper>
                <WizardNavigation
                    steps={steps}
                    disableSideNavigation={disableSideNavigation}
                    activeStepIndex={activeStepIndex}
                    i18nStrings={i18nStrings}
                    farthestStepIndex={farthestStepIndex}
                    onStepClick={(stepIndex) => navigationEvent(stepIndex, "step")}
                />
                <Content>
                    <WizardForm
                        steps={steps}
                        i18nStrings={i18nStrings}
                        activeStepIndex={activeStepIndex}
                        hideSubmitButton={hideSubmitButton}
                        isPrimaryLoading={isLoadingNextStep}
                        onPreviousClick={() => navigationEvent(activeStepIndex - 1, "previous")}
                        onPrimaryClick={
                            isLastStep
                                ? () => fireNonCancelableEvent(onSubmit, {})
                                : () => navigationEvent(activeStepIndex + 1, "next")
                        }
                    />
                </Content>
            </Wrapper>
        </div>
    );
}
