import { KaleRootState } from "src/redux/store";
import { CardNames, SummaryCard, SummaryCardsSlice } from "src/components/survey/ApplicationOverview/state/model";
import { createSelector } from "reselect";
import {
    ContentDescriptor,
    isWrappedItemDescriptor,
    isWrappedListDescriptor,
} from "src/services/summaries/model/content-descriptors";

export const selectSummaryCardsSlice = (state: KaleRootState): SummaryCardsSlice =>
    state.applicationOverview.summaryCards;

export const selectCardsRecord = createSelector(
    [selectSummaryCardsSlice],
    (cardsSlice): SummaryCardsSlice["byCardName"] => cardsSlice.byCardName
);

export const selectCardsDisplayOrder = createSelector(
    [selectSummaryCardsSlice],
    (cardsSlice): SummaryCardsSlice["displayOrder"] => cardsSlice.displayOrder
);

export const selectSummaryCardByName = createSelector(
    [selectCardsRecord, (state: KaleRootState, cardName: CardNames): CardNames => cardName],
    (cardsByName, cardName): SummaryCard => cardsByName[cardName]
);

export const selectSummaryCardItemsCount = createSelector([selectSummaryCardByName], ({ response }): number => {
    if (response) {
        const { content } = response.summary;
        return countTopLevelItems(content);
    } else {
        return 0;
    }
});

/**
 * Function to iterate through a list of ContentDescriptor to derive the real count of the top level items contained
 * within. Because `content` is defined as a list of ContentDescriptor (a heterogeneous type), rather than a list of
 * ItemDescriptor, we cannot rely on the `length` property of the list to get a real count the top level items and must
 * inspect each content descriptor to find the real top level item count of each. Here "top level item" refers to an
 * ItemDescriptor that has no other ItemDescriptor ancestors within a ContentDescriptor subtree.
 * @param content - list of ContentDescriptor
 */
export function countTopLevelItems(content: ContentDescriptor[]): number {
    const seedCount = 0;
    return content.reduce<number>(function addToContentCount(currCount, nextContent): number {
        if (isWrappedItemDescriptor(nextContent)) {
            // A top level ItemDescriptor simply counts as one item in the content.
            return currCount + 1;
        } else if (isWrappedListDescriptor(nextContent)) {
            const { ListDescriptor } = nextContent;
            // A top level ListDescriptor is an abstraction over 0 or more items. We should use the length of
            // the ListDescriptor's items list to account for the top level items that it represents
            return currCount + ListDescriptor.items.length;
        } else {
            const unknownDescriptorError = `Encountered unknown content descriptor: \n ${JSON.stringify(nextContent)}`;
            throw new Error(unknownDescriptorError);
        }
    }, seedCount);
}
