import React, { ComponentClass, ComponentType } from "react";
import { UserAction, UserRole, isAuthorized } from "src/permissions";
import { ApplicationStatus, selectLegalApprovalStatus, SurveyConsumer } from "src/components/survey/SurveyFormModel";
import { SurveyItemProps } from "src/components/survey/SurveyItem";

export interface ApplicationStatusCallback {
    (searchAnyStatus?: ApplicationStatus[]): ApplicationStatus | undefined;
}

export function isFormReadOnly(role: UserRole, statusCallback: ApplicationStatusCallback): boolean {
    const canEditApplication = isAuthorized(role, UserAction.editApplication, statusCallback);
    return !canEditApplication;
}

export function withReadonly<P extends SurveyItemProps<any, any>>(
    WrappedComponent: ComponentType<P>
): ComponentClass<P> {
    return class ReadonlyHOC extends React.PureComponent<P> {
        public render(): JSX.Element {
            return (
                <SurveyConsumer>
                    {({ application, role }): JSX.Element => {
                        const legalStatus = selectLegalApprovalStatus(application.appInfo);
                        return (
                            <WrappedComponent
                                {...this.props}
                                isFormInReadonlyMode={isFormReadOnly(role, (): ApplicationStatus => legalStatus.status)}
                            />
                        );
                    }}
                </SurveyConsumer>
            );
        }
    };
}
