import { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";
import { PDCFindingsFromServer } from "./types";

export const PDC_FINDINGS_NOT_YET_FETCHED = null;

export type FetchPDCFindingsResult = {
    pdcFindingsFromServer: PDCFindingsFromServer;
    isLoading: boolean;
    error: string;
};
export const useFetchPDCFindings = (requestBindles: string[]): FetchPDCFindingsResult => {
    const kaleContext = useContext(KaleContext);
    const {
        service: {
            nodeKaleAppService: { fetchPDCFindings },
        },
    } = kaleContext;

    const { applicationName } = useAppParamsFromRoute();

    const [pdcFindingsFromServer, setPdcFindingsFromServer] =
        useState<PDCFindingsFromServer>(PDC_FINDINGS_NOT_YET_FETCHED);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const deps = { fetchPDCFindings };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { fetchPDCFindings } = dependenciesRef.current;

        if (!requestBindles.length) {
            return;
        }

        (async function IIFE(): Promise<void> {
            setIsLoading(true);
            try {
                const pdcFindings = await fetchPDCFindings(applicationName, requestBindles);
                setPdcFindingsFromServer(pdcFindings);
                setError("");
            } catch (e) {
                setError((e as Error).message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [applicationName, requestBindles]);

    return { pdcFindingsFromServer, error, isLoading };
};
