import {
    CombinedAccessorReadonlyUnionType,
    CombinedAccessorUnionType,
    TableAnswerKey,
    TableFieldAnswerKey,
} from "src/answers_legacy";

export const selectAccessorLookupId = (accessor: CombinedAccessorUnionType): number | string | undefined => {
    const answer = accessor.answers[0].answerWithQuestion.answer;

    return (
        answer[TableAnswerKey.userTableId] ||
        answer[TableFieldAnswerKey.userFieldId] ||
        answer[TableFieldAnswerKey.fieldId] ||
        answer[TableAnswerKey.tableId]
    );
};

export const selectReadonlyAccessorLookupId = (
    accessor: CombinedAccessorReadonlyUnionType
): number | string | undefined => {
    const answer = accessor.answers[0].answerWithQuestion.answer;

    return (
        answer[TableAnswerKey.userTableId] ||
        answer[TableFieldAnswerKey.userFieldId] ||
        answer[TableFieldAnswerKey.fieldId] ||
        answer[TableAnswerKey.tableId]
    );
};

export const selectAccessorByLookupId = (
    accessors: CombinedAccessorUnionType[],
    lookupId: number | string | undefined
): CombinedAccessorUnionType | undefined =>
    accessors.find((accessor): boolean => lookupId === selectAccessorLookupId(accessor));

export const selectReadonlyAccessorByLookupId = (
    accessors: CombinedAccessorReadonlyUnionType[],
    lookupId: number | string | undefined
): CombinedAccessorReadonlyUnionType | undefined =>
    accessors.find((accessor): boolean => lookupId === selectReadonlyAccessorLookupId(accessor));
