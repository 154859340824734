import { DataStoreHookAnswer, QuestionTag } from "src/answers_legacy";
import React, { useEffect, useRef, useState } from "react";
import { AnswerContentKey } from "src/services/dynamic-questions";
import SandfireCheckList from "src/components/survey/DataStoreInfo/DataStoreWizard/SandfireCheckList";
import AEDUPreqs from "src/components/survey/DataStoreInfo/DataStoreWizard/AEDUPreqs";

/**
 *  This component is responsible for conditionally rendering an information blurb section
 *  after any question that has QuestionTag.sandfireInfo or QuestionTag.aeduInfo.
 *  Currently these questions are any questions where a user notifies us that they are or have already
 *  onboarded to Sandfire or AEDU. This was done because our current dynamic questions implementation
 *  does not give us a good way to add a non question component in the exact spot that we need it per UI specifications.
 *  Due to tight deadlines, this seemed like the quickest implementation that had the smallest surface area.
 */
const ConditionalSectionFooter = ({
    sectionQuestions,
}: {
    sectionQuestions: DataStoreHookAnswer[];
}): JSX.Element | null => {
    const [showSandfireInfo, setShowSandfireInfo] = useState(false);
    const [showAEDUInfo, setShowAEDUInfo] = useState(false);

    const deps = { questionHasSandfireOrAEDUAnswer };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    const setSandfireAndAEDUInfoBlurbVisibility = (): void => {
        const { questionHasSandfireOrAEDUAnswer } = depsRef.current;
        for (const q of sectionQuestions) {
            if (
                [QuestionTag.sandfireInfo, QuestionTag.aeduInfo].some((tag): boolean => q.question.tags.includes(tag))
            ) {
                const [hasSandfireAnswer, hasAEDUAnswer] = questionHasSandfireOrAEDUAnswer(
                    sectionQuestions,
                    q.question.shortId
                );
                setShowSandfireInfo(hasSandfireAnswer);
                setShowAEDUInfo(hasAEDUAnswer);
                return;
            }
        }
        setShowSandfireInfo(false);
        setShowAEDUInfo(false);
    };

    useEffect(setSandfireAndAEDUInfoBlurbVisibility, [sectionQuestions]);

    if (showSandfireInfo) {
        return <SandfireCheckList />;
    } else if (showAEDUInfo) {
        return <AEDUPreqs />;
    } else {
        return null;
    }
};

const questionHasSandfireOrAEDUAnswer = (
    dataStoreAnswers: DataStoreHookAnswer[],
    qShortID: string
): [boolean, boolean] => {
    let foundSandfireAnswer = false;
    let foundAEDUAnswer = false;
    for (const answer of dataStoreAnswers) {
        if (answer.answerWithQuestion.question.shortId === qShortID) {
            foundSandfireAnswer = answer.answerWithQuestion.answer[AnswerContentKey.textContent] === "Sandfire";
            foundAEDUAnswer = answer.answerWithQuestion.answer[AnswerContentKey.textContent] === "AEDU";
        }
    }
    return [foundSandfireAnswer, foundAEDUAnswer];
};

export default ConditionalSectionFooter;
