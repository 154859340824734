import React, { useContext, useEffect, useState } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { Textarea } from "@amzn/awsui-components-react-v3";
import { FieldCellState } from "src/components/TableDetails/FieldsTable/types";
import styled from "styled-components";

const ComponentWrapper = styled.span``;
const StyledTextarea = styled(Textarea)`
    & textarea {
        // Prevent manual horizontal resize of the textarea element as it causes wnky behavior in the example values
        // cell. Insted users should resize the width of the entire column and the text area will grow/shrink in width
        // to match
        resize: vertical !important;
    }
`;

export interface TextareaQuestionProps extends FieldCellState {
    className?: string; // Supports styled-components re-styling of this component
}
export const TextareaQuestion = (props: TextareaQuestionProps): JSX.Element => {
    const { value: originalValue, setValue, isValid, isApplicable, className } = props;

    const value = originalValue as string;
    const [localValue, setLocalValue] = useState<string>(value);
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly || !isApplicable;
    useEffect((): void => {
        // Always overwrite local state when prop changes.
        setLocalValue(value);
    }, [value]);

    return (
        // Throttle how often we update global state by only calling the setter from the onBlur handler
        <ComponentWrapper className={className} title={value}>
            <StyledTextarea
                disabled={isReadonly}
                value={localValue}
                onChange={(e): void => setLocalValue(e.detail.value)}
                onBlur={(): void => {
                    if (value !== localValue) {
                        // Only trigger an expensive global state update
                        // if the user changed the value
                        setValue(localValue);
                    }
                }}
                rows={1}
                invalid={!isValid}
            />
        </ComponentWrapper>
    );
};
