import { AppInfoResponse } from "src/components/survey/SurveyFormModel";
import TextArea from "src/components/fields/textbox/TextArea";
import Model from "src/components/constants/Constants";
import React from "react";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";

export interface ApplicationDescriptionProperties {
    shouldShowErrors: boolean;
    onChangeCallback: OnChangeCb;
    response: AppInfoResponse;
}

export const ApplicationDescription = (props: ApplicationDescriptionProperties): JSX.Element => {
    const onChange = (event: any): void => {
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                applicationDescription: event.response,
            },
        });
    };

    return (
        <TextArea
            id={"applicationDescription"}
            rows={4}
            placeholder={Model.getData("applicationDescription").example}
            isRequired={props.shouldShowErrors}
            expectedAnswer={props.response.review.applicationDescription}
            onChangeCallback={onChange}
        />
    );
};
