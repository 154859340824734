import { CommentFromServer, SaveCommentRequestBody } from "src/services/NodeKaleApplicationService";
import {
    ConnectedComment,
    DraftComment,
    FrontEndCommentType,
    HeadComment,
    ReplyComment,
} from "src/components/CommentsView/state/model";
import { UserProps } from "src/components/KaleContext";

interface FrontEndIdGenerator {
    next: () => number;
}

/**
 * Singleton object manages the creation of comment frontEndId values to ensure
 * uniqueness across all FrontEndComment objects
 */
const FrontEndIdGenerator = ((): FrontEndIdGenerator => {
    let frontEndIdSeed = 0;

    return {
        next: (): number => frontEndIdSeed++,
    };
})();

/**
 * Convert commentsFromServer into ConnectedComment proxy shape.
 * @param commentsFromServer - the comments for a Kale application that were
 * fetched from the server. We expect these to be pre sorted in ascending order
 * based on each comment's `createdAt` property.
 */
const importCommentsFromServer = (commentsFromServer: CommentFromServer[]): ConnectedComment[] => {
    // For mapping backendIds to frontEndIds
    const mapToFrontEndIds: { [backendId: number]: number } = {};

    return commentsFromServer.reduce<ConnectedComment[]>((frontEndComments, commentFromServer): ConnectedComment[] => {
        // Because commentsFromServer are pre sorted in ascending order, we will always encounter the comments
        // representing the head node of a comment thread before we encounter any of the comments representing replies
        // to that same comment thread. This heuristic, along with the `mapToFrontEndIds` object we will build out
        // dynamically, allows us to convert every CommentFromServer object to a FrontEndComment object with correctly
        // mapped frontEndId and frontEndParentId properties in just a single array traversal.

        const { id: backEndId, parentId: backEndParentId, ...rest } = commentFromServer;

        // Generate a unique frontEndId
        const frontEndId = FrontEndIdGenerator.next();

        // Add a map entry for each comment's `backendId` -> `frontEndId` so that any upcoming reply-comments in the
        // list can use their `backEndParentId` property to lookup and assign the correct value for their
        // `frontEndParentId` property
        mapToFrontEndIds[backEndId] = frontEndId;
        const frontEndParentId = backEndParentId === null ? null : mapToFrontEndIds[backEndParentId];

        const commentType = FrontEndCommentType.ConnectedComment;

        const frontEndComment: ConnectedComment = {
            ...rest,
            backEndId,
            backEndParentId,
            frontEndId,
            frontEndParentId,
            commentType,
        };
        frontEndComments.push(frontEndComment);

        return frontEndComments;
    }, []);
};

export const exportCommentForSaveOperation = (
    { message, firstName, lastName, alias, applicationName }: DraftComment,
    backEndParentId: SaveCommentRequestBody["parentId"]
): SaveCommentRequestBody => {
    return {
        message,
        firstName,
        lastName,
        alias,
        applicationName,
        parentId: backEndParentId,
    };
};

export interface DraftHeadCommentOptions {
    user: UserProps;
    applicationName: string;
    message?: string;
}

/**
 * Creates a Draft HeadComment object
 */
export const createDraftHeadComment = ({
    applicationName,
    user: { userId: alias, firstName, lastName },
}: DraftHeadCommentOptions): HeadComment & DraftComment => ({
    // HeadComment frontEndParentId is always null
    frontEndParentId: null,
    applicationName,
    alias,
    firstName: firstName,
    lastName: lastName,
    frontEndId: FrontEndIdGenerator.next(),
    message: "",
    createdAt: new Date().toISOString(),
    commentType: FrontEndCommentType.DraftComment,
});

export interface DraftReplyCommentOptions extends DraftHeadCommentOptions {
    frontEndParentId: number;
}

/**
 * Creates a Draft ReplyComment object
 * @param frontEndParentId - this should be the `frontEndId` of the HeadComment
 * in the CommentThread that this comment is meant to be a reply of.
 */
export const createDraftReplyComment = ({
    frontEndParentId,
    ...rest
}: DraftReplyCommentOptions): ReplyComment & DraftComment => ({
    ...createDraftHeadComment(rest),
    // Mixin the frontEndParentId to transform this from a Comment into a ReplyComment
    frontEndParentId,
});

/**
 * Connects a DraftComment to a live CommentFromServer, and returns the resultant ConnectedComment type
 */
const connectDraftToLiveComment = (
    draftComment: DraftComment,
    commentFromServer: CommentFromServer
): ConnectedComment => {
    const { id: backEndId, parentId: backEndParentId, ...rest } = commentFromServer;
    const { frontEndId, frontEndParentId } = draftComment;
    return {
        ...rest,
        backEndId,
        backEndParentId,
        frontEndId,
        frontEndParentId,
        commentType: FrontEndCommentType.ConnectedComment,
    };
};

export { importCommentsFromServer, connectDraftToLiveComment };
