import React from "react";
import { Box, HelpPanel, Link } from "@amzn/awsui-components-react-v3";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react-v3/polaris/space-between";
import { LINKS } from "src/components/ExternalLinks";

export const RelatedBindleHelpContent = () => (
    <HelpPanel
        footer={
            <SpaceBetween size={"s"}>
                <Link external={true} href={LINKS.PRIVACY_WIKI}>
                    Kale Wiki
                </Link>
            </SpaceBetween>
        }
        header={<Header variant={"h2"}>Related Bindles</Header>}
    >
        <React.Fragment>
            <Header variant={"h3"}>I&lsquo;m not seeing any bindle(s) in the dropdown</Header>
            <Box variant={"p"}>
                If no bindles are present, ensure all of the following requirements are met:
                <ul>
                    <li>
                        You have at least one bindle in{" "}
                        <Link external={true} href={"https://bindles.amazon.com/"}>
                            bindles.amazon.com
                        </Link>
                        , or you are a member of a team that owns a bindle, and
                    </li>
                    <li>You are using a supported browser (FireFox and Chrome), and</li>
                    <li>Your browser is not blocking third-party cookies and site data.</li>
                </ul>
            </Box>
            <Header variant={"h3"}>Why are only certain bindles auto-selected?</Header>
            <Box variant={"p"}>
                <ul>
                    <li>
                        Based on your selected control bindle, Kale will auto-select certain related bindles{" "}
                        <i>(This selection can be modified if needed)</i>
                    </li>
                    <li>
                        These bindles can be identified using the <b>&quot;Recommended&quot;</b> tag in dropdown options
                    </li>
                </ul>
            </Box>
            <Header variant={"h3"}>How does Kale consider a bindle to be related to my application?</Header>
            <Box variant={"p"}>
                <ul>
                    <li>
                        Kale will automatically fetch the corresponding ASR/Anvil application for your selected control
                        bindle
                    </li>
                    <li>
                        It then retrieves all the bindles linked to the control bindle, as part of the{" "}
                        <b>&quot;Resources&quot;</b> tab and auto-selects them
                    </li>
                </ul>
            </Box>
        </React.Fragment>
    </HelpPanel>
);
