import { TEST_IDS } from "shared/survey/personalDataWizard";
import { Box, Button, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import Modal from "@amzn/awsui-components-react-v3/polaris/modal";
import React from "react";
import { PDCWarningModalProps } from "./types";
import { useEventCallbacks } from "./useEventCallbacks";
import { useHandleShowEvent } from "./useHandleShowEvent";

const { PDC_WARNING_MODAL } = TEST_IDS.WIZARD;

export const PDCWarningModal = ({ visible, actions }: PDCWarningModalProps) => {
    const { onShow, onDismiss, onGoBack, onContinue } = useEventCallbacks({ actions });
    useHandleShowEvent({ visible, onShow });

    const footer = (
        <Box float={"right"}>
            <SpaceBetween size={"s"} direction={"horizontal"}>
                <Button data-testid={PDC_WARNING_MODAL.GO_BACK_BUTTON} variant={"primary"} onClick={onGoBack}>
                    Go Back and change answer
                </Button>
                <Button data-testid={PDC_WARNING_MODAL.CONTINUE_BUTTON} onClick={onContinue}>
                    Continue anyway
                </Button>
            </SpaceBetween>
        </Box>
    );

    const header = <Header>Strong indication of Personal Data </Header>;

    return (
        <Modal
            data-testid={PDC_WARNING_MODAL.ROOT}
            visible={visible}
            footer={footer}
            onDismiss={onDismiss}
            header={header}
        >
            Personal Data Classifier findings for your application suggest that you may have personal data. Are you sure
            you do not process or store personal data of any kind, and any data that is processed is fully anonymized?
        </Modal>
    );
};
