import { Box, Link, SelectProps } from "@amzn/awsui-components-react-v3";
import { PersonalDataWizardStep } from "src/components/survey/PersonalDataWizard/steps";
import { WizardProps } from "src/components/InlineWizard/interfaces";
import React, { useContext, useMemo } from "react";
import { PDCFinding } from "src/services/NodeKaleApplicationService";
import KaleContext from "src/components/KaleContext";
import { PERSONAL_DATA_CLASSIFIER_FINDINGS_TITLE } from "../../content";
import { PDCStepContent } from "src/components/survey/PersonalDataWizard/steps/personalDataClassifier/PDCStepContent";
import { useFetchPDCFindings } from "./useFetchPDCFindings";
import { useCheckForHighConfidenceFindings } from "./useCheckForHighConfidenceFindings";

const DEFAULT_PDC_FINDINGS: PDCFinding[] = [];

interface Props {
    /**
     * List representing the combined set of the applications control bindle and all related bindles.
     * [{label: <bindle name>, value: <bindle id (from fetchBindleByOwner() API)>}].
     * PDC API would require bindle ids as input, so we use "value" from this props
     */
    requestBindles: SelectProps.Option[];
    /** If the reaffirm campaign is active, modify the default PDCFilterName */
    isShowingReaffirmCampaign: boolean;
}

interface Response extends PersonalDataWizardStep {
    pdcFindings: PDCFinding[];
    /** Indicates whether the PDCFindings contain at least one FoundEntityType with a High Confidence score */
    isHighConfidence: boolean;
}

export const usePersonalDataClassifierStep = ({ requestBindles, isShowingReaffirmCampaign }: Props): Response => {
    const kaleContext = useContext(KaleContext);
    const {
        config: { personalDataClassifierHost },
    } = kaleContext;

    const bindleValues = useMemo(
        () =>
            requestBindles.reduce<string[]>((values, { value: optionValue }) => {
                if (optionValue) {
                    values.push(optionValue);
                }
                return values;
            }, []),
        [requestBindles]
    );

    const { pdcFindingsFromServer, error, isLoading } = useFetchPDCFindings(bindleValues);

    const pdcStepContent = (
        <PDCStepContent
            pdcFindingsFromServer={pdcFindingsFromServer}
            personalDataClassifierHost={personalDataClassifierHost}
            isLoading={isLoading}
            isShowingReaffirmCampaign={isShowingReaffirmCampaign}
        />
    );

    const pdcFindings = pdcFindingsFromServer ?? DEFAULT_PDC_FINDINGS;

    const isHighConfidence = useCheckForHighConfidenceFindings(pdcFindings);

    const step: WizardProps.Step = {
        title: PERSONAL_DATA_CLASSIFIER_FINDINGS_TITLE,
        infoCount: pdcFindings.length,
        description: (
            <Box variant={"small"}>
                Data store technologies are periodically scanned to identify instances of personal data. These results
                indicate that the resources used by your application may process personal data. Learn more about our
                Personal Data Classifier technology by visiting our{" "}
                <Link
                    variant={"info"}
                    external={true}
                    href="https://w.amazon.com/bin/view/PrivacyEngineering/Product/DataClassifier"
                >
                    wiki.
                </Link>{" "}
                To view additional details of the findings or to provide feedback, please click on the links below to be
                directed to the Personal Data Classifier Portal.
            </Box>
        ),
        content: pdcStepContent,
    };

    return { isLoading, step, pdcFindings, error, isHighConfidence };
};
