import { Link } from "@amzn/awsui-components-react-v3";
import React, { useContext } from "react";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import KaleContext from "src/components/KaleContext";

export const ControlBindleLink = (model: Model): JSX.Element => {
    const context = useContext(KaleContext);
    return (
        <Link
            external={true}
            href={`${context.config.bindleEndpoint}/software_app/${model.application.controlBindle[0]}`}
        >
            {model.application.controlBindle}
        </Link>
    );
};

export function controlBindle(model: Model): Pair {
    return {
        label: DisplayLabels.ControlBindle,
        value: <ControlBindleLink bindles={model.bindles} application={model.application}></ControlBindleLink>,
        info: (
            <div>
                <h3>Change Access Control Bindle?</h3>
                <ul>
                    <li>
                        Navigate to your{" "}
                        <Link target="_blank" href={""}>
                            Current Access Control Bindle.
                        </Link>
                    </li>
                    <li>
                        Under Resources, expand the Data Handling Records section and click on your Kale application.
                    </li>
                    <li>
                        Click on the Actions drop-down (top-right of page) and select &ldquo;Transfer to another
                        Software Application&rdquo; (If this doesn&apos;t appear, you need to be added as a member to
                        the team).
                    </li>
                    <li>Enter the new Bindle for the Software Application field.</li>
                    <li>Kale will automatically reflect the new value within the next 24 hours.</li>
                </ul>
            </div>
        ),
    };
}
