import AbstractKaleService from "src/services/AbstractKaleService";
import { TokenGetter } from "src/services/CognitoService";
import { FeatureToggleConfig, KaleConfig } from "src/Config";
import { appendRequestID } from "src/services/AppendRequestID";

export interface JobView {
    jobID: number;
    applicationName: string;
    correlationID: string;
    type: string;
    name: string;
    status: string;
    timeStamp: string;
}

export class JobService extends AbstractKaleService {
    public constructor(kaleConfig: KaleConfig & FeatureToggleConfig, accessTokenGetter: TokenGetter) {
        super(kaleConfig, accessTokenGetter);

        this.listLatestJobViewsByAppAndType = this.listLatestJobViewsByAppAndType.bind(this);
    }

    public listLatestJobViewsByAppAndType(appName: string, jobType?: string): Promise<JobView[]> {
        const url = `${this.baseApiEndpoint}/applications/${encodeURIComponent(appName)}/jobs${
            jobType ? `?type=${jobType}` : ""
        }`;

        return this.signedFetch("GET", url).then((res: Response): any => {
            if (res.status == 200) {
                return res.json();
            } else {
                throw Error(appendRequestID(`Failed to load latest job views for ${appName}`, res));
            }
        }, this.buildDefaultRejection());
    }
}
