import { tidSelector } from "../util/selectors";
import { TEST_IDS } from "./test-ids";

const { TAF_DETAILS_PAGE, FINANCIAL_GRE, PREDICTIONS_TABLE } = TEST_IDS;

export const SELECTORS = {
    TAF_DETAILS_PAGE: {
        ROOT: tidSelector(TAF_DETAILS_PAGE.ROOT),
        LOADING_SPINNER: tidSelector(TAF_DETAILS_PAGE.LOADING_SPINNER),
        LOADED_CONTENT: tidSelector(TAF_DETAILS_PAGE.LOADED_CONTENT),
    },
    FINANCIAL_GRE: {
        ROOT: tidSelector(FINANCIAL_GRE.ROOT),
        MAIN_CONTENT: tidSelector(FINANCIAL_GRE.MAIN_CONTENT),
    },
    PREDICTIONS_TABLE: {
        TABLE: tidSelector(PREDICTIONS_TABLE.TABLE),
    },
};
