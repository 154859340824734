import { useEffect, useMemo, useRef, useState } from "react";
import { AnswerContentKey, QuestionChoice, TableDetails } from "src/services/KaleTablesService";
import { TableAnswer, TableQuestion, useKaleTable, useKaleTableFields } from "src/answers_legacy";
import { EMPTY_COLLECTION, EMPTY_RECORD } from "src/components/TableDetails/TableDetailsPage/hooks/contants";
import {
    makeSyntheticTableQuestionsAndAnswers,
    makeSyntheticFieldQuestionsAndAnswers,
} from "src/components/TableDetails/TableDetailsPage/synthetic-questions";
import {
    ANDES_PD2_BUS_USE_CASE_FIELD_SHORT_ID,
    ANDES_PD2_BUS_USE_CASE_TABLE_SHORT_ID,
    ANDES_PD2_DATE_KEY_TABLE_SHORT_ID,
    RETENTION_BUS_USE_CASE_FIELD_SHORT_ID,
    RETENTION_BUS_USE_CASE_TABLE_SHORT_ID,
} from "src/components/TableDetails/constants";
import { QuestionApplicabilityTag } from "src/services/dynamic-questions";

const useSyncTableQuestionsState = (tableDetailsFromServer: TableDetails | null): void => {
    const [, tableActions] = useKaleTable();

    const { addAnswers: addTableState, resetAnswers: resetTableState } = tableActions;

    const tableQuestionsFromServer = tableDetailsFromServer?.tableQuestions || (EMPTY_COLLECTION as TableQuestion[]);
    const tableAnswersFromServer = tableDetailsFromServer?.table.answers || (EMPTY_COLLECTION as TableAnswer[]);
    const table = tableDetailsFromServer?.table;
    const fields = tableDetailsFromServer?.fields; // to populate metadata questions' dropdowns

    // Create "Retention Date Key" question choices from field names.
    // "Retention Date Key" question is a dynamic question coming from the
    // backend, but the server expects the front end to populate the choices.
    // Backend expects the choice values to be the fieldNames
    tableQuestionsFromServer.forEach((question: TableQuestion): void => {
        if (question.shortId === ANDES_PD2_DATE_KEY_TABLE_SHORT_ID) {
            question.choices =
                tableDetailsFromServer?.fields.map((field): QuestionChoice => {
                    return { value: field.name, label: field.name };
                }) ?? [];
        }
    });

    interface TableQuestionsAndAnswers {
        tableAnswers: TableAnswer[];
        tableQuestions: TableQuestion[];
    }

    const { tableAnswers, tableQuestions } = useMemo((): TableQuestionsAndAnswers => {
        // fields are passed in so that particular dropdowns that are supposed to be a list of fields, can be filled
        const { syntheticTableAnswers, syntheticTableQuestions } = makeSyntheticTableQuestionsAndAnswers(table, fields);

        const tableAnswers = [...syntheticTableAnswers, ...tableAnswersFromServer];
        const tableQuestions = [...syntheticTableQuestions, ...tableQuestionsFromServer];
        return { tableAnswers, tableQuestions };
    }, [table, fields, tableAnswersFromServer, tableQuestionsFromServer]);

    const deps = { addTableState, resetTableState };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { addTableState, resetTableState } = dependenciesRef.current;

        resetTableState();
        addTableState(tableAnswers, tableQuestions);
    }, [tableAnswers, tableQuestions]);

    useAddTableOption(tableDetailsFromServer);
};

// useAddTableOption configures applicability(show/hide) custom logic for metadata questions (table-level, at bottom)
// Whenever the "Business Use Case" questions (field-level or table-level) are chosen, based on a map of flagged
// business use cases, one of the metadata questions would appear if a flagged business use case was selected.
// Utilizes "addOptions" from the GSM to configure applicability.
export const useAddTableOption = (tableDetailsFromServer: TableDetails | null): void => {
    const [tableState, { addOptions: addTableOptions }] = useKaleTable();
    // FieldRowsState to sync the metadata table questions based on real-time answers to particular field questions
    const [fieldRowsState] = useKaleTableFields();

    // isStateLoaded will indicate if the state is loaded. This is important when needing to calculate the applicability
    // (show/hide of questions). The metadata questions need to have the answers loaded into the state in order to
    // reliably calculate if metadata questions should be shown or not. If these calculations happen when the state is
    // partially loaded, then the question would get marked as inapplicable which will trigger GSM to delete the user
    // response (default behavior when a question is hidden). This, when the page is loading, will essentially delete
    // the response that is stored in the backend and show the question as unanswered.
    // So, we use isStateLoaded to only do these calculations when we can be sure we have the state loaded.
    const [isStateLoaded, setIsStateLoaded] = useState(false);
    useEffect((): void => {
        if (
            tableState.answers.length &&
            fieldRowsState.every((fieldRow): boolean => {
                return Boolean(fieldRow.answers.length);
            })
        ) {
            setIsStateLoaded(true);
        }
    }, [tableState, fieldRowsState]);

    const deps = { addTableOptions };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    // We start by finding the table-level business use case response
    const busUseValue = tableState.answers.find((answer): boolean => {
        return (
            answer.question.shortId === RETENTION_BUS_USE_CASE_TABLE_SHORT_ID ||
            answer.question.shortId === ANDES_PD2_BUS_USE_CASE_TABLE_SHORT_ID
        );
    });
    // Safe to cast to string[] as business use case answers are multiselect
    const standardBusUseCaseValue = (busUseValue?.value as string[]) ?? EMPTY_COLLECTION;

    // Collect all the business use cases selected, from the table-level and all field-level bus use case questions
    const uniqueChosenBusUseCases = useMemo((): Set<string> => {
        // Add the table-level business use cases to the final Set
        const uniqueChosenBusUseCases: Set<string> = new Set(standardBusUseCaseValue);

        // For every "Business Use Case" question in Fields table, add each chosen bus use case to Set
        fieldRowsState.forEach((fieldRow): void => {
            fieldRow?.answers.forEach((answer): void => {
                const answerValue = answer?.answerWithQuestion?.answer;
                if (
                    answerValue &&
                    (answerValue.questionShortId === RETENTION_BUS_USE_CASE_FIELD_SHORT_ID ||
                        answerValue.questionShortId === ANDES_PD2_BUS_USE_CASE_FIELD_SHORT_ID)
                ) {
                    // Business use case is a multiselect, therefore arrayContent is used
                    const busUseCases = answer.answerWithQuestion.answer[AnswerContentKey.arrayContent];
                    if (busUseCases) {
                        busUseCases.forEach((busUseCase): void => {
                            if (busUseCase) {
                                uniqueChosenBusUseCases.add(busUseCase);
                            }
                        });
                    }
                }
            });
        });
        return uniqueChosenBusUseCases;
    }, [fieldRowsState, standardBusUseCaseValue]);

    // Map that dictates which are the flagged business use cases for a given metadata question
    const metadataRequirementsMap: Record<string, string[]> =
        tableDetailsFromServer?.metadataRequirements ?? EMPTY_RECORD;

    useEffect((): void => {
        // Given the flagged bus use cases, and the chosen bus use cases, show question if a flagged case is selected
        // hide if none of the flagged cases are selected.
        const { addTableOptions } = dependenciesRef.current;
        addTableOptions({
            constraintHandler: ({ hasParentConstraint, isParentConstraintMet, question }): [boolean, boolean] => {
                // See comment near declaration of "isStateLoaded" for more info
                if (!isStateLoaded) {
                    return [hasParentConstraint, isParentConstraintMet];
                }

                let flaggedBusUseCases: string[] = [];

                if (question.tags.includes(QuestionApplicabilityTag.metadataCreationDate)) {
                    flaggedBusUseCases = metadataRequirementsMap[QuestionApplicabilityTag.metadataCreationDate];
                } else if (question.tags.includes(QuestionApplicabilityTag.metadataDeletionRequestDate)) {
                    flaggedBusUseCases = metadataRequirementsMap[QuestionApplicabilityTag.metadataDeletionRequestDate];
                } else if (question.tags.includes(QuestionApplicabilityTag.metadataTaxPeriodEndDate)) {
                    flaggedBusUseCases = metadataRequirementsMap[QuestionApplicabilityTag.metadataTaxPeriodEndDate];
                } else {
                    // Non-metadata question, perform default behavior
                    return [hasParentConstraint, isParentConstraintMet];
                }

                // If there are no flagged business use cases in requirements map, then perform default behavior
                if (!flaggedBusUseCases) {
                    return [hasParentConstraint, isParentConstraintMet];
                }
                let hasChosenFlaggedBusUseCase = false;
                flaggedBusUseCases.forEach((flaggedBusUseCase): void => {
                    if (uniqueChosenBusUseCases.has(flaggedBusUseCase)) {
                        hasChosenFlaggedBusUseCase = true;
                    }
                });

                let shouldShowQuestion = hasChosenFlaggedBusUseCase;
                if (hasParentConstraint) {
                    shouldShowQuestion = shouldShowQuestion && isParentConstraintMet;
                }

                return [true, shouldShowQuestion];
            },
        });
    }, [uniqueChosenBusUseCases, metadataRequirementsMap, isStateLoaded]);
};

const useSyncFieldsQuestionsState = (
    tableDetailsFromServer: TableDetails | null,
    derUuidQuestionChoices: QuestionChoice[]
): void => {
    const [, fieldActions] = useKaleTableFields();
    const { addAnswers: addFieldState, resetAnswers: resetFieldState } = fieldActions;

    const fieldQuestionsFromServer = tableDetailsFromServer?.fieldQuestions || EMPTY_COLLECTION;
    const fields = tableDetailsFromServer?.fields || EMPTY_COLLECTION;

    const deps = { addFieldState, resetFieldState };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { addFieldState, resetFieldState } = dependenciesRef.current;
        resetFieldState();
        fields.forEach((field): void => {
            const { id: fieldId, answers: fieldAnswersFromServer = EMPTY_COLLECTION } = field;
            const { syntheticFieldAnswers, syntheticFieldQuestions } = makeSyntheticFieldQuestionsAndAnswers(
                field,
                derUuidQuestionChoices
            );
            const fieldAnswers = [...syntheticFieldAnswers, ...fieldAnswersFromServer];
            const fieldQuestions = [...syntheticFieldQuestions, ...fieldQuestionsFromServer];
            addFieldState(fieldAnswers, fieldQuestions, fieldId);
        });
    }, [fields, fieldQuestionsFromServer, derUuidQuestionChoices]);
};

const useHasUnsavedChanges = (): boolean => {
    const [tableState] = useKaleTable();
    const [fieldsState] = useKaleTableFields();

    return tableState.hasChanged || fieldsState.some((accessor): boolean => accessor.hasChanged);
};

// Custom hook to encapsulate loading new Table View Questions/Answers state from the network response
// into the global state manager
export const useSyncTableViewStateWithServerData = (
    tableDetailsFromServer: TableDetails | null,
    derUuidQuestionChoices: QuestionChoice[]
): boolean => {
    useSyncTableQuestionsState(tableDetailsFromServer);
    useSyncFieldsQuestionsState(tableDetailsFromServer, derUuidQuestionChoices);

    return useHasUnsavedChanges();
};
