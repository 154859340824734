import React from "react";
import { CheckboxProps, Popover, PopoverProps, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/fields";
import { Checkbox } from "@amzn/awsui-components-react-v3/polaris";
import { InfoIcon } from "src/components/HelpIcon";

const { ROOT: KALE_CHECKBOX_ROOT, INLINE_CHECKBOX, POPOVER } = TEST_IDS.KALE_CHECKBOX;

export interface KaleCheckboxProps {
    "data-checkbox-label"?: string;
    checkboxProps: CheckboxProps;
    popoverProps?: PopoverProps;
}

/**
 * Custom component wraps a Polaris checkbox, and optionally renders a Polaris info icon and Popover next to the
 * checkbox label if the component receives the optional "popoverProps" prop.
 */
const KaleCheckbox = (props: KaleCheckboxProps): JSX.Element => {
    return (
        <div data-testid={KALE_CHECKBOX_ROOT} data-checkbox-label={props["data-checkbox-label"]}>
            <SpaceBetween direction="horizontal" size="xxs">
                <Checkbox data-testid={INLINE_CHECKBOX} {...props.checkboxProps} />
                {props.popoverProps && (
                    <Popover data-testid={POPOVER} {...props.popoverProps} triggerType="custom">
                        <InfoIcon name="status-info" alt="status-info" />
                    </Popover>
                )}
            </SpaceBetween>
        </div>
    );
};

export default KaleCheckbox;
