import { AppInfoResponse, HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";
import { OnChangeCb } from "src/components/survey/ApplicationInfo/ApplicationInfo";
import React, { useEffect, useState } from "react";
import { Container, Header } from "@amzn/awsui-components-react-v3";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import Model from "src/components/constants/Constants";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";
import { OptionModel } from "src/components/constants/SurveyConstants";
import { Choice } from "src/services/KaleApplicationService";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import { TEST_IDS } from "shared/survey";

export interface ProvideInfoProps {
    shouldShowErrors: boolean;
    skipValidation: boolean;
    response: AppInfoResponse;
    onChangeCallback: OnChangeCb;
    onClearDataStore: () => void;
    isPDWComplete: boolean;
}

export const ProvideInfo = (props: ProvideInfoProps): JSX.Element | null => {
    const [shouldShowProvideInfo, setShouldShowProvideInfo] = useState<boolean>(false);

    useEffect((): void => {
        if (props.response.review.hasPersonalData) {
            setShouldShowProvideInfo(props.response.review.hasPersonalData !== HasPersonalDataResponse.Store);
        }
    }, [props.response.review.hasPersonalData]);

    const willingToProvideInfoChange = (result: FunctionalSurveyItemResult<string>): void => {
        props.onClearDataStore();
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                willingToProvideInfo: result.response,
            },
        });
    };

    const provideInfoChoices = Model.getData("willingToProvideInfo").options.map((option: OptionModel): Choice => {
        return { value: option.name };
    });

    return !props.isPDWComplete ? null : (
        <div data-testid={TEST_IDS.APPLICATION_INFO.REVIEW_QUESTIONS_PANE.ROOT}>
            {shouldShowProvideInfo && (
                <Container
                    header={
                        <Header description={"(Optional for applications that do not contain personal data)"}>
                            {KaleHTMLParser(Model.getData("willingToProvideInfo").question)}
                        </Header>
                    }
                >
                    <KaleRadiobutton
                        id={"willingToProvideInfo"}
                        isRequired={!props.skipValidation && props.shouldShowErrors}
                        choices={provideInfoChoices}
                        expectedAnswer={props.response.review.willingToProvideInfo}
                        onChangeCallback={willingToProvideInfoChange}
                    />
                </Container>
            )}
        </div>
    );
};
