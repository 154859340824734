import { KaleEvent } from "src/services/RumService/rum-events/base-events";
import { RumEventName } from "src/services/RumService/rum-events/constants";

const eventName = RumEventName.Kale_Session_Start;

type SessionStartEvent = KaleEvent & { name: RumEventName.Kale_Session_Start };
const sessionStartEvent = (metadata: SessionStartEvent["metadata"]): SessionStartEvent => ({
    name: eventName,
    metadata,
});

export const event = sessionStartEvent;
export const name = eventName;
export type Event = SessionStartEvent;
