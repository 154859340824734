/* eslint-disable max-len */
import { TableRecord, TableSources } from "src/services/KaleTablesService";
import { useEffect, useMemo, useRef, useState } from "react";
import { AndesMetadata } from "src/components/schema_table/importers/andes_importer/AndesImporter";
import { CradleMetadata } from "src/components/TableDetails/TableDetailsForm/types";
import {
    CancellablePollingPromise,
    pollForCradleMetaData,
} from "src/components/TableDetails/TableDetailsForm/poll-for-cradle-meta-data";
import { useKaleServices } from "src/components/KaleContext";
import { CradleService, FetchCradleMetadataRequestOptions, CRADLE_POLICY_IDENTIFIER } from "src/services/CradleService";
import React from "react";
import { useHandleAndesPolicyCradleTableQuestions } from "./useHandleAndesPolicyCradleTableQuestions";
import { AndesCradleMetadataInfo, AndesPolicyCradleMetadata } from "./types";
import { AndesComplianceTypes, AndesPolicyTypes, PolicyTypesByComplianceType } from "./constants";
import { decorateErrorMessage } from "src/components/TableDetails/TableDetailsForm/hooks/useCradleMetadataForAndes/error-utils";
/* eslint-enable max-len */

const useFetchCradleMetadataForAndesPolicy = (table: TableRecord, policyType: string): AndesPolicyCradleMetadata => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cradleMetadata, setCradleMetadata] = useState<CradleMetadata | null>(null);
    const [error, setError] = useState<React.ReactNode>(null);
    const { cradleService } = useKaleServices();
    const cradleServiceRef = useRef<CradleService>(cradleService);
    cradleServiceRef.current = cradleService;

    const cancelPollingRef = useRef<CancellablePollingPromise["cancelPolling"]>();
    const cancelCurrentPolling = (): void => cancelPollingRef.current?.();

    useEffect((): (() => void) => {
        const { source, sourceMetadata } = table;
        const {
            provider: providerId,
            table: tableName,
            schemaVersion: tableVersion,
        } = (sourceMetadata as AndesMetadata) || {};

        const isAndesTable = source === TableSources.Andes;
        if (isAndesTable) {
            setIsLoading(true);

            const requestOptions: FetchCradleMetadataRequestOptions = {
                policyType,
                policyIdentifier: CRADLE_POLICY_IDENTIFIER,
                andesProviderId: providerId,
                andesTableName: tableName,
                andesTableVersion: parseInt(tableVersion, 10),
            };

            // Begin new polling for the policy type's cradle metadata
            cancelCurrentPolling();
            const { pollingPromise, cancelPolling } = pollForCradleMetaData(cradleServiceRef.current, requestOptions);
            cancelPollingRef.current = cancelPolling;
            pollingPromise
                .then((cradleMetadata): void => {
                    setError(null);
                    setIsLoading(false);
                    setCradleMetadata(cradleMetadata);
                })
                .catch((e): void => {
                    const errorMessage = decorateErrorMessage(e);
                    setError(errorMessage);
                    setIsLoading(false);
                });
        }

        // Cancel polling on unmount
        return cancelCurrentPolling;
    }, [table, policyType]);

    return useMemo(
        (): AndesPolicyCradleMetadata => ({
            isLoading,
            cradleMetadata,
            error,
        }),
        [isLoading, cradleMetadata, error]
    );
};

const useCradleMetaDataForAndesPolicy = (
    table: TableRecord,
    policyType: AndesPolicyTypes
): AndesPolicyCradleMetadata => {
    const andesPolicyCradleMetaData = useFetchCradleMetadataForAndesPolicy(table, policyType);
    useHandleAndesPolicyCradleTableQuestions(andesPolicyCradleMetaData, policyType);

    return andesPolicyCradleMetaData;
};

/**
 * Custom hook to make fetch cradle metadata requests for certain Andes Compliance types.
 * BDT team has asked us to fetch and display cradle metadata for DSAR abd OD3 policies.
 */
export const useCradleMetadataForAndes = (table: TableRecord): AndesCradleMetadataInfo => {
    const OD3Policy = PolicyTypesByComplianceType[AndesComplianceTypes.OD3];
    const OD3CradleMetadata = useCradleMetaDataForAndesPolicy(table, OD3Policy);

    const DSARPolicy = PolicyTypesByComplianceType[AndesComplianceTypes.DSAR];
    const DSARCradleMetadataRequest = useCradleMetaDataForAndesPolicy(table, DSARPolicy);

    return useMemo(
        (): AndesCradleMetadataInfo => ({
            [AndesComplianceTypes.OD3]: OD3CradleMetadata,
            [AndesComplianceTypes.DSAR]: DSARCradleMetadataRequest,
        }),
        [OD3CradleMetadata, DSARCradleMetadataRequest]
    );
};
