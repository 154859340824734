import { Select } from "@amzn/awsui-components-react-v3";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { SelectProps } from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import React, { useCallback } from "react";
import { TableLink, TableRecord, TableSources } from "src/services/KaleTablesService";
import { kaleUrls } from "src/util/Urls";
import { useHistory } from "react-router-dom";
import { AndesMetadata } from "src/components/schema_table/importers/andes_importer/AndesImporter";

export const TEST_IDS = {
    DROPDOWN: "table-details--table-navigation-dropdown",
};

export interface TableNavigationDropdownProps {
    applicationName: string;
    reviewId: string;
    kaleTable: TableRecord | undefined;
    linkedTables: TableLink[] | undefined;
}

const TableNavigationDropdown = (props: TableNavigationDropdownProps): JSX.Element => {
    const history = useHistory();
    const renderLinkedTableList = (
        linkedTables: TableLink[] | undefined,
        kaleTable: TableRecord | undefined
    ): SelectProps.Options => {
        const options: SelectProps.Option[] = [];
        if (linkedTables && kaleTable) {
            for (let i = 0; i < linkedTables.length; i++) {
                if (linkedTables[i].id !== kaleTable.id) {
                    const option: SelectProps.Option = {
                        label: `${i + 1}) ${linkedTables[i].name}`,
                        value: `${linkedTables[i].id}`,
                    };
                    const metadata = linkedTables[i].sourceMetadata;
                    if (metadata && linkedTables[i].source === TableSources.Andes) {
                        const { provider, schemaVersion } = metadata as AndesMetadata;
                        option.tags = [`Provider: ${provider}`, `Version: ${schemaVersion}`];
                    }
                    options.push(option);
                }
            }
        }
        return options;
    };

    const navigateToLinkedTable = useCallback(
        (
            changeEvent: NonCancelableCustomEvent<SelectProps.ChangeDetail>,
            applicationName: string,
            reviewId: string,
            kaleTable: TableRecord | undefined
        ): void => {
            if (changeEvent.detail.selectedOption.value && kaleTable) {
                const linkedTableUrl = kaleUrls.editKaleTableUrl(
                    applicationName,
                    reviewId,
                    kaleTable.dataStoreId,
                    changeEvent.detail.selectedOption.value
                );
                history.push(linkedTableUrl);
            }
        },
        [history]
    );

    const selectedOption = (kaleTable: TableRecord | undefined): SelectProps.Option => {
        const option: SelectProps.Option = { label: kaleTable?.name, value: `${kaleTable?.id}` };
        const metadata = kaleTable?.sourceMetadata;
        if (metadata && kaleTable?.source === TableSources.Andes) {
            const { provider, schemaVersion } = metadata as AndesMetadata;
            option.tags = [`Provider: ${provider}`, `Version: ${schemaVersion}`];
        }
        return option;
    };

    return (
        <Select
            data-testid={TEST_IDS.DROPDOWN}
            selectedOption={selectedOption(props.kaleTable)}
            options={renderLinkedTableList(props.linkedTables, props.kaleTable)}
            selectedAriaLabel="Selected"
            filteringType={"auto"}
            onChange={(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
                navigateToLinkedTable(event, props.applicationName, props.reviewId, props.kaleTable);
            }}
            triggerVariant="option"
        />
    );
};

export default TableNavigationDropdown;
