import { AnswerBase, AnswerWithQuestion, ParentConstraintBase, QuestionBase } from "src/answers_legacy";

export enum ReviewAnswerKey {
    reviewId = "reviewId",
    userReviewId = "userReviewId",
}

export type ReviewQuestion = QuestionBase & ParentConstraintBase;

export interface ReviewAnswer extends AnswerBase {
    [ReviewAnswerKey.reviewId]?: number;
    [ReviewAnswerKey.userReviewId]?: string;
}

export function DefaultReviewAnswer(
    questionId: number,
    questionShortId: string,
    reviewId?: number,
    userReviewId?: string
): ReviewAnswer {
    return {
        questionId,
        questionShortId,
        reviewId,
        userReviewId,
    };
}

export type ReviewAnswerWithQuestion = AnswerWithQuestion<ReviewQuestion, ReviewAnswer>;
