import { Container, FormField, Header } from "@amzn/awsui-components-react-v3";
import React from "react";
import { HookAnswer, QuestionTag, QuestionType, ReviewAnswer, ReviewQuestion } from "src/answers_legacy";
import KaleCheckboxGroup from "src/components/fields/select/KaleCheckboxGroup";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import TextBoxWithTags from "src/components/fields/textbox/TextBoxWithTags";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import { DropdownWithTagsFunctionalReadonly } from "src/components/fields/dropdown/DropdownWithTags";
import { useIsExclusive } from "src/components/survey/hooks/useIsExclusive";

export const NOT_YET_SUPPORTED = "The specified question type is not yet supported";

const renderRadiobutton = (shouldShowErrors: boolean, item: HookAnswer<ReviewQuestion, ReviewAnswer>): JSX.Element => {
    return (
        <KaleRadiobutton
            id={item.question.shortId}
            isRequired={shouldShowErrors && item.question.tags.includes(QuestionTag.required)}
            choices={item.question.choices}
            expectedAnswer={item.value as string}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderCheckboxGroup = (
    shouldShowErrors: boolean,
    item: HookAnswer<ReviewQuestion, ReviewAnswer>
): JSX.Element => {
    return (
        <KaleCheckboxGroup
            id={item.question.shortId}
            isRequired={shouldShowErrors && item.question.tags.includes(QuestionTag.required)}
            choices={item.question.choices}
            expectedAnswer={(item.value as string[]) ?? []}
            onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const MultiSelect = (shouldShowErrors: boolean, item: HookAnswer<ReviewQuestion, ReviewAnswer>): JSX.Element => {
    const { onChangeCallBack, options, selectedOptions } = useIsExclusive(item);
    const allowOther = !item.question.tags.includes(QuestionTag.excludeOtherOption);

    return (
        <DropdownWithTagsFunctionalReadonly
            id={item.question.shortId}
            isRequired={shouldShowErrors && item.question.tags.includes(QuestionTag.required)}
            options={options}
            expectedAnswer={selectedOptions}
            onChangeCallback={onChangeCallBack}
            allowOther={allowOther}
        />
    );
};

const renderTextTags = (shouldShowErrors: boolean, item: HookAnswer<ReviewQuestion, ReviewAnswer>): JSX.Element => {
    return (
        <TextBoxWithTags
            id={item.question.shortId}
            isRequired={shouldShowErrors && item.question.tags.includes(QuestionTag.required)}
            expectedAnswer={(item.value as string[]) ?? []}
            onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

interface Props {
    shouldShowErrors: boolean;
    item: HookAnswer<ReviewQuestion, ReviewAnswer>;
}

export const ReviewQuestionBuilder = (props: Props): JSX.Element | null => {
    const { shouldShowErrors, item } = props;
    if (!item.isApplicable) {
        return null;
    }
    let content: JSX.Element;
    switch (item.question.type) {
        case QuestionType.radio:
            content = renderRadiobutton(shouldShowErrors, item);
            break;
        case QuestionType.checkboxGroup:
            content = renderCheckboxGroup(shouldShowErrors, item);
            break;
        case QuestionType.multiSelect:
            content = MultiSelect(shouldShowErrors, item);
            break;
        case QuestionType.textTags:
            content = renderTextTags(shouldShowErrors, item);
            break;
        default:
            content = <FormField>{NOT_YET_SUPPORTED}</FormField>;
            break;
    }

    return (
        <Container
            key={item.question.shortId}
            header={
                <Header description={KaleHTMLParser(item.question.subtext)}>
                    {KaleHTMLParser(item.question.content)}
                </Header>
            }
        >
            {content}
        </Container>
    );
};
