import React, { ReactNode, useEffect, useRef } from "react";
import { Alert } from "@amzn/awsui-components-react-v3";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import {
    CollectDataStoreInfoProps,
    DataStoreInstance,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo/types";
import { TEST_IDS } from "shared/survey";
import { isAndes, isDataStoreInstanceValid } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import _ from "lodash";
import { DataStoreIdentifier } from "src/components/DataStoreIdentifiers";
import { SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";

const isDuplicateDataStoreName = (otherDataStores: DataStoreResponse[], activeDataStoreName: string): boolean => {
    const duplicateNameDataStore = otherDataStores.find((dataStore): boolean => dataStore.name === activeDataStoreName);
    return Boolean(duplicateNameDataStore);
};

/**
 * @return Returns a flattened list of all DataStoreInstances contained by the passed in list of DataStoresResponses
 * @param dataStores the list of DataStoreResponses to extract the DataStoreInstances from
 */
const flattenDataStoreInstances = (dataStores: DataStoreResponse[]): DataStoreInstance[] => {
    const instancesLists: DataStoreInstance[][] = dataStores.map(({ instances }): DataStoreInstance[] => instances);
    // Flatten the lists into a single list using a Node10 supported way.
    return ([] as DataStoreInstance[]).concat(...instancesLists);
};
/**
 * @return A single error message derived from a list of identifiers
 * @param identifiers, List of identifiers to extract the error message from
 */
const makeDuplicateIdentifiersString = (identifiers: DataStoreIdentifier[]): string => {
    const identifierStrings = identifiers.map(({ type, value }): string => `${type}: ${value}`);
    return identifierStrings.join(`, `);
};

const useIsAnswerValid = (
    props: CollectDataStoreInfoProps
): { isAnswerValid: boolean; stepValidationErrors: ReactNode } => {
    const { dataStores, activeDataStoreIndex, expectedAnswer } = props;
    let isAnswerValid = true;
    const stepValidationErrors: ReactNode[] = [];
    const { DUPLICATE_NAME_ERROR, DUPLICATE_ANDES_TECHNOLOGY_ERROR, DUPLICATE_DATA_STORE_ERROR } =
        TEST_IDS.DATA_STORE_INFO.DATA_STORE_WIZARD.COLLECT_INFO_STEP;

    const otherDataStores = dataStores
        // If we are editing a pre-existing data store, it will already be
        // contained in the dataStores list. We want to exclude it here.
        .filter((_, index): boolean => index !== activeDataStoreIndex);

    if (otherDataStores.length) {
        /* 1. Check for Duplicate data store names */
        const activeDataStoreName = expectedAnswer?.name ?? "";
        if (isDuplicateDataStoreName(otherDataStores, activeDataStoreName)) {
            isAnswerValid = false;
            // CollectDataStoreInfo's answer is considered invalid if the entered name already
            // belongs to another data store in the application. This is a constraint in the UI
            // because of how we render the data store cards in the Kale Application. A User can
            // create one or more Data stores in the application without saving a draft, so those
            // data stores won't have a real id set on them until they come back from the server
            // on the next save. Without a persistent uuid of any sort available to use at the time
            // the data store is created, we have opted to use the data store name as a unique identifier
            // for the data store, and we use those unique identifiers as the react keys in the list of
            // Data Store Cards that get rendered elsewhere in the survey. Without unique keys those
            // Cards have all typical rendering issues that are well documented when you have a list
            // of components without unique keys in React
            const errorId = DUPLICATE_NAME_ERROR;
            stepValidationErrors.push(
                <Alert data-testId={errorId} key={errorId} header={"Duplicate Data Store Name"} type={"error"}>
                    A data store named{`"${activeDataStoreName}"`}already exists. Choose a unique data store name to
                    advance.
                </Alert>
            );
        }

        /* 2. Check for Duplicate data stores */
        // If the user has entered a technology into the active data store,
        // we need to validate that they aren't trying to create a duplicate data store.
        const activeDataStoreTechnology = expectedAnswer?.technology ?? "";
        if (activeDataStoreTechnology) {
            // Find other data stores using the same technology
            const dataStoresUsingSameTechnology = otherDataStores.filter(
                ({ technology }): boolean => technology === activeDataStoreTechnology
            );
            if (dataStoresUsingSameTechnology.length) {
                if (isAndes(activeDataStoreTechnology)) {
                    // CollectDataStoreInfo's answer is considered invalid if the user tries to create a second
                    // data store with Andes technology.
                    isAnswerValid = false;
                    const errorId = DUPLICATE_ANDES_TECHNOLOGY_ERROR;
                    stepValidationErrors.push(
                        <Alert data-testId={errorId} key={errorId} header={"Duplicate Andes Data Store"} type={"error"}>
                            Only one Andes data store can be added to an application, please add all Andes tables to the
                            existing Andes data store.
                        </Alert>
                    );
                } else {
                    // Find any valid data store instances the user has entered into the active data store, and search
                    // the other data stores for duplicate instances. If the user has entered any incomplete or invalid
                    // instances, don't include them here.
                    const validActiveDataStoreInstances = (expectedAnswer?.instances ?? []).filter(
                        isDataStoreInstanceValid
                    );
                    if (validActiveDataStoreInstances.length) {
                        const otherInstances = flattenDataStoreInstances(dataStoresUsingSameTechnology);
                        const duplicateInstance = validActiveDataStoreInstances.find(
                            (activeDataStoreInstance): boolean =>
                                otherInstances.some((otherInstance): boolean =>
                                    _.isEqual(activeDataStoreInstance, otherInstance)
                                )
                        );
                        if (duplicateInstance) {
                            // CollectDataStoreInfo's answer is considered invalid if the user tries to create a data
                            // store using the same technology and the same one or more valid DataStoreInstances as
                            // another existing data store.
                            isAnswerValid = false;
                            const errorId = DUPLICATE_DATA_STORE_ERROR;
                            const duplicateIdentifiers = makeDuplicateIdentifiersString(duplicateInstance.identifiers);
                            const message =
                                `A data store with technology: "${activeDataStoreTechnology}" and identifier(s)` +
                                ` "${duplicateIdentifiers}" already exists for this application.`;
                            stepValidationErrors.push(
                                <Alert
                                    data-testId={errorId}
                                    key={errorId}
                                    header={`Duplicate ${activeDataStoreTechnology} Data Store`}
                                    type={"error"}
                                >
                                    {message}
                                </Alert>
                            );
                        }
                    }
                }
            }
        }
    }

    return { isAnswerValid, stepValidationErrors };
};

export const useCustomValidation = (props: CollectDataStoreInfoProps): ReactNode => {
    const { isAnswerValid, stepValidationErrors } = useIsAnswerValid(props);

    const setIsAnswerValidRef = useRef(props.setIsAnswerValidCallback);
    setIsAnswerValidRef.current = props.setIsAnswerValidCallback;

    useEffect((): void => {
        // Signal validity of answer back to ancestor
        setIsAnswerValidRef.current(isAnswerValid);
    }, [isAnswerValid]);

    return stepValidationErrors && <SpaceBetween size={"xxs"}>{stepValidationErrors}</SpaceBetween>;
};
