import styled from "styled-components";
import { Icon, Popover } from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens";
import React from "react";

export const InfoIcon = styled(Icon)`
    cursor: pointer !important;
    color: ${awsui.colorTextStatusInfo} !important;
`;

export interface HelpIconProps {
    content: React.ReactNode;
}

export const HelpIcon = (props: HelpIconProps): JSX.Element => {
    return (
        <Popover triggerType="custom" content={props.content}>
            <InfoIcon name="status-info" alt="status-info" />
        </Popover>
    );
};
