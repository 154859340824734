import { ActionType, CommentsViewAction } from "src/components/CommentsView/state/actions";
import { commentsLoadedReducer } from "src/components/CommentsView/state/reducer/comments-loaded";
import { FrontEndComment, CommentThread, CommentThreadGroup } from "src/components/CommentsView/state/model";
import { creatingNewCommentThreadReducer } from "src/components/CommentsView/state/reducer/creating-new-comment-thread";
import { UserProps } from "src/components/KaleContext";
import { addingNewReplyCommentReducer } from "src/components/CommentsView/state/reducer/adding-new-reply-comment";
import { commentCancelledReducer } from "src/components/CommentsView/state/reducer/comment-cancelled";
import { commentMessageChangeReducer } from "src/components/CommentsView/state/reducer/comment-message.change";
import { commentSavedReducer } from "src/components/CommentsView/state/reducer/comment-saved";

export interface CommentSlice {
    byFrontEndId: {
        [frontEndId: number]: FrontEndComment;
    };
}

export interface CommentThreadSlice {
    byHeadCommentId: {
        [headCommentFrontEndId: number]: CommentThread;
    };
    displayOrder: number[];
}

export interface CommentThreadGroupSlice {
    byGroupHeader: { [groupHeader: string]: CommentThreadGroup };
    displayOrder: string[];
}

export interface CommentsViewUISlice {
    isCommentsSidebarOpen: boolean;
}

export interface CommentsViewState {
    applicationName: string;
    user: UserProps;
    comments: CommentSlice;
    commentThreads: CommentThreadSlice;
    commentThreadGroups: CommentThreadGroupSlice;
    ui: CommentsViewUISlice;
}

export const makeInitialState = (): CommentsViewState => ({
    applicationName: "",
    user: { email: "", userId: "", firstName: "", lastName: "" },
    comments: {
        byFrontEndId: {},
    },
    commentThreads: {
        byHeadCommentId: {},
        displayOrder: [],
    },
    commentThreadGroups: {
        byGroupHeader: {},
        displayOrder: [],
    },
    ui: {
        isCommentsSidebarOpen: false,
    },
});

const INITIAL_STATE = makeInitialState();

const reducer = (prevState: CommentsViewState = INITIAL_STATE, action: CommentsViewAction): CommentsViewState => {
    switch (action.type) {
        case ActionType.CommentsLoaded: {
            return commentsLoadedReducer(prevState, action);
        }
        case ActionType.CreatingNewCommentThread: {
            return creatingNewCommentThreadReducer(prevState, action);
        }
        case ActionType.AddingNewReplyComment: {
            return addingNewReplyCommentReducer(prevState, action);
        }

        case ActionType.CommentCanceled: {
            return commentCancelledReducer(prevState, action);
        }

        case ActionType.CommentMessageChange: {
            return commentMessageChangeReducer(prevState, action);
        }

        case ActionType.CommentSaved: {
            return commentSavedReducer(prevState, action);
        }

        case ActionType.CommentViewSidebarToggled: {
            return {
                ...prevState,
                ui: {
                    ...prevState.ui,
                    isCommentsSidebarOpen: !prevState.ui.isCommentsSidebarOpen,
                },
            };
        }

        default: {
            return prevState;
        }
    }
};

export default reducer;
