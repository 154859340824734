import { useTAFDetailsSelectors } from "src/components/TAF/TAFDetails/hooks/useTAFDetailsSelectors";
import { KaleQuestion, ReviewGroupsMap } from "src/services/KaleApplicationService";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { UserRole } from "src/permissions";
import { TAFDetailsFromServer } from "src/components/TAF/TAFDetails/hooks/useFetchPageResources";
import { useHistory } from "react-router-dom";
import {
    DataStoreAnswer,
    DataStoreQuestion,
    QuestionChoice,
    QuestionTag,
    ReviewQuestion,
    useDataStores,
    useReview,
} from "src/answers_legacy";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { isAndes, isRedshift } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import KaleContext from "src/components/KaleContext";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { ReviewResponse } from "src/components/survey/SurveyFormModel";
import {
    DataStoreAttributePayload,
    DataStoreAttributeShortId,
    ReviewAttributePayload,
    ReviewAttributeShortId,
    syntheticDataStoreQuestions,
    syntheticReviewQuestions,
} from "src/components/TAF/TAFDetails/constants";
import { hasTAFUrl } from "src/util/Urls";
import { attributesToAnswers } from "src/components/TAF/TAFDetails/answerUtils";
import { MessageType } from "src/components/survey/KaleRoutes";

interface HookOptions {
    setOriginalAssignedReviewGroups: (originalAssignedGroups: string[]) => void;
    setOriginalDataStoreDecisions: (originalDataStoreDecisions: DataStoreResponse[]) => void;
    setDetectedTafData: (isDetected: boolean) => void;
    setRetentionRecommended: (isRecommended: boolean) => void;
    setReviewGroupsMap: (map: ReviewGroupsMap) => void;
    userRole: UserRole | null;
    tafDetailsFromServer: TAFDetailsFromServer;
    kaleQuestion: KaleQuestion;
    isTafReviewer: boolean;
}

/**
 * Public export for this module.
 * Handles the TAF survey GSM initialization code for loading questions and configuring any custom constraint handling
 * for question applicability and/or validation
 */
export const useInitializeTAFSurveyGSMOnce = (options: HookOptions): void => {
    // Important we call the hook to add data store options to the GSM before we call the hook that loads questions
    // into the GSM. This is to ensure that we always have the options custom constraint handler in place before ever
    // running validation on questions loaded into the GSM as the GSM would otherwise run its default constraint
    // handler (which would be the wrong set of rules) after the initial load and that could incorrectly cause it to
    // mark some datastore questions as not applicable which would immediately erase any loaded answers for those
    // questions in the local GSM state.
    useAddTAFDataStoreOptions(options);
    useLoadTAFQuestionsIntoGSMOnce(options);
};

const reviewGroupMapToChoices = (reviewGroupMap: ReviewGroupsMap): QuestionChoice[] => {
    return [
        ...Object.keys(reviewGroupMap)
            .map((reviewGroupName): QuestionChoice[] =>
                reviewGroupMap[reviewGroupName]
                    .map(
                        (value: string): QuestionChoice => ({
                            value,
                        })
                    )
                    .flat()
            )
            .flat(),
    ];
};

/**
 * Add custom constraints for certain questions that can't be expressed
 * through the current backend Dynamic Question API surface
 */
const useAddTAFDataStoreOptions = ({ tafDetailsFromServer }: Pick<HookOptions, "tafDetailsFromServer">): void => {
    const history = useHistory();

    const [dataStoreAccessors, { addOptions: addDataStoreOptions }] = useDataStores();

    const { selectDataStoreResponseByAnswer } = useTAFDetailsSelectors({
        tafDetailsFromServer,
        dataStoreAccessors,
    });

    const deps = { addDataStoreOptions, history, tafDetailsFromServer };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect((): void => {
        const { addDataStoreOptions } = depsRef.current;

        addDataStoreOptions({
            constraintHandler: ({
                currentAnswer,
                question,
                hasParentConstraint,
                isParentConstraintMet,
            }): [boolean, boolean] => {
                const dataStore = selectDataStoreResponseByAnswer(currentAnswer);

                if (question.shortId === "provider_type_andes") {
                    if (isAndes(dataStore?.technology)) {
                        return [hasParentConstraint, true];
                    }
                    return [hasParentConstraint, false];
                }

                if (question.shortId === "datanet_used_for_redshift_etl") {
                    if (isRedshift(dataStore?.technology)) {
                        return [hasParentConstraint, true];
                    }
                    return [hasParentConstraint, false];
                }

                if (question.tags.includes(QuestionTag.tafAlwaysApplicable)) {
                    return [true, true];
                }

                return [hasParentConstraint, isParentConstraintMet];
            },
        });
    }, [selectDataStoreResponseByAnswer]);
};

/**
 * Loads TAF Questions into the GSM once the relevant data is available
 */
const useLoadTAFQuestionsIntoGSMOnce = ({
    isTafReviewer,
    kaleQuestion,
    setDetectedTafData,
    setOriginalAssignedReviewGroups,
    setOriginalDataStoreDecisions,
    setRetentionRecommended,
    setReviewGroupsMap,
    tafDetailsFromServer,
    userRole,
}: HookOptions): void => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);
    const { displayMessage } = useMessageBannerActions();

    const [isGSMInitialized, setIsGSMInitialized] = useState<boolean>(false);

    const [, { resetAndAddAnswers: resetAndAddReviewAnswers }] = useReview();
    const [dataStoreAccessors, { resetAndAddAnswers: resetAndAddDataStoreAnswers }] = useDataStores();

    const { selectReview, selectAssignedReviewGroups } = useTAFDetailsSelectors({
        tafDetailsFromServer,
        dataStoreAccessors,
    });

    const insertAssignedReviewGroupsFromDecisions = useCallback(
        (review: ReviewResponse): void => {
            const reviewGroupsMap = selectAssignedReviewGroups();

            review.assignedReviewGroups = Object.keys(reviewGroupsMap)
                .map((groupType): string[] => reviewGroupsMap[groupType])
                .flat();

            setOriginalAssignedReviewGroups(review.assignedReviewGroups);
        },
        [selectAssignedReviewGroups, setOriginalAssignedReviewGroups]
    );

    const syntheticReviewQuestionsRef = useRef(syntheticReviewQuestions);

    const deps = {
        kaleAppService,
        displayMessage,
        insertAssignedReviewGroupsFromDecisions,
        isGSMInitialized,
        resetAndAddReviewAnswers,
        resetAndAddDataStoreAnswers,
        selectReview,
        setDetectedTafData,
        setOriginalDataStoreDecisions,
        setRetentionRecommended,
        setReviewGroupsMap,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(
        function loadTAFQuestionsIntoGSM(): void {
            // If we are still on the TAF page when this effect body runs, we should load TAF questions/answers
            // into the GSM. If this tries to run after the user has already left TAF, then do nothing.
            if (hasTAFUrl()) {
                (async function IIFE(): Promise<void> {
                    const {
                        displayMessage,
                        kaleAppService,
                        insertAssignedReviewGroupsFromDecisions,
                        isGSMInitialized,
                        resetAndAddReviewAnswers,
                        resetAndAddDataStoreAnswers,
                        selectReview,
                        setDetectedTafData,
                        setOriginalDataStoreDecisions,
                        setRetentionRecommended,
                        setReviewGroupsMap,
                    } = depsRef.current;

                    const review = selectReview();

                    if (!isGSMInitialized && review) {
                        try {
                            const { id: reviewId } = review;

                            setOriginalDataStoreDecisions(review.dataStores);
                            insertAssignedReviewGroupsFromDecisions(review);

                            const reviewAnswers = attributesToAnswers<ReviewAttributePayload>(
                                review,
                                "reviewId",
                                reviewId
                            );

                            if (reviewId && userRole) {
                                const reviewGroupNames = await kaleAppService.fetchReviewGroupsByRole(userRole);

                                setReviewGroupsMap(reviewGroupNames);

                                const reviewQuestions = syntheticReviewQuestionsRef.current
                                    .map((question): ReviewQuestion => {
                                        if (question.shortId === ReviewAttributeShortId.assignedReviewGroups) {
                                            question.choices = reviewGroupMapToChoices(reviewGroupNames);
                                        }
                                        return question;
                                    })
                                    .filter((question: ReviewQuestion): boolean => {
                                        // Always show assigned review groups for TAF reviewers
                                        if (question.shortId === ReviewAttributeShortId.assignedReviewGroups) {
                                            return isTafReviewer;
                                        }
                                        return true;
                                    });

                                resetAndAddReviewAnswers(reviewAnswers, reviewQuestions, reviewId);

                                const dataStoreAnswers = review.dataStores
                                    .map((dataStore): DataStoreAnswer[] => {
                                        const { dataStoreAnswers: answers, description, id, uuid } = dataStore;

                                        const descriptionAnswer = attributesToAnswers<DataStoreAttributePayload>(
                                            { description },
                                            "dataStoreId",
                                            (id || uuid) as number | string
                                        ) as unknown as DataStoreAnswer[];

                                        return [...descriptionAnswer, ...answers];
                                    })
                                    .flat();

                                resetAndAddDataStoreAnswers(dataStoreAnswers, [
                                    ...syntheticDataStoreQuestions.map((dataStoreQuestion): DataStoreQuestion => {
                                        // TODO: Remove need to override parentId
                                        return [DataStoreAttributeShortId.description].includes(
                                            (dataStoreQuestion.shortId as DataStoreAttributeShortId) ?? ""
                                        )
                                            ? {
                                                  ...dataStoreQuestion,
                                                  parentId: ((): number => {
                                                      return (
                                                          kaleQuestion.dataStores.find(
                                                              (question): boolean =>
                                                                  question.shortId === "current_retention_period"
                                                          )?.id ?? 0
                                                      );
                                                  })(),
                                                  parentChoices: [],
                                              }
                                            : dataStoreQuestion;
                                    }),
                                    ...kaleQuestion.dataStores.map((dataStoreQuestion): DataStoreQuestion => {
                                        // TODO: Implement this logic in data store wizard
                                        if (dataStoreQuestion.tags.includes(QuestionTag.tafIgnoreTitle)) {
                                            dataStoreQuestion.title = "";
                                        }

                                        // TODO: Remove need to override parentId
                                        return ["downstream_is_shared", "upstream_is_shared"].includes(
                                            dataStoreQuestion.shortId ?? ""
                                        )
                                            ? {
                                                  ...dataStoreQuestion,
                                                  parentId: ((): number => {
                                                      return (
                                                          kaleQuestion.dataStores.find(
                                                              (question): boolean => question.shortId === "data_source"
                                                          )?.id ?? 0
                                                      );
                                                  })(),
                                                  parentChoices: [],
                                              }
                                            : dataStoreQuestion;
                                    }),
                                ]);

                                //
                                setIsGSMInitialized(true);
                            }

                            setDetectedTafData(Boolean(review.flags?.length));
                            setRetentionRecommended(Boolean(review.flags?.length));
                        } catch (error) {
                            const message = error instanceof Error ? error.message : error;
                            displayMessage(MessageType.error, `${message}`);
                        }
                    }
                })();
            }
        },
        [isTafReviewer, kaleQuestion, isGSMInitialized, tafDetailsFromServer, userRole]
    );
};
