import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { Select, SelectProps } from "@amzn/awsui-components-react-v3";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TEST_IDS } from "shared/survey";
import SurveyConstants from "src/components/constants/Constants";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris/internal/events";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { KaleFormField } from "src/components/fields/KaleFormField";

interface Props extends FunctionalSurveyItemProps<string> {
    shouldShowErrors: boolean;
}

export const IsFoundationalApp = withFunctionalReadonly((props: Props): JSX.Element => {
    const { id, expectedAnswer, isFormInReadonlyMode = false, shouldShowErrors, onChangeCallback } = props;
    const data = SurveyConstants.getData("isFoundationalApp");
    const label = data.question;
    const description = data.description;
    const errorMsg = !Boolean(expectedAnswer) ? "Required" : "";
    const options = [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ];

    const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(
        expectedAnswer ? { label: expectedAnswer, value: expectedAnswer } : null
    );

    const deps = { id, onChangeCallback };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect((): void => {
        setSelectedOption(expectedAnswer ? { label: expectedAnswer, value: expectedAnswer } : null);
    }, [expectedAnswer]);

    const onSelectionChange = useCallback(({ detail }: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
        const { id, onChangeCallback } = depsRef.current;
        onChangeCallback?.({
            id: id,
            response: detail.selectedOption.label ?? "",
        });
    }, []);

    return (
        <KaleFormField
            data-testid={TEST_IDS.APPLICATION_INFO.IS_FOUNDATIONAL_APP.FORM_FIELD}
            label={label}
            description={description}
            errorText={shouldShowErrors && errorMsg}
        >
            <Select
                data-testid={TEST_IDS.APPLICATION_INFO.IS_FOUNDATIONAL_APP.SELECT}
                disabled={isFormInReadonlyMode}
                selectedOption={selectedOption}
                placeholder={"Choose an option"}
                onChange={onSelectionChange}
                options={options}
            />
        </KaleFormField>
    );
});
