import { ApprovalType, getApproval } from "src/components/survey/SurveyFormModel";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { StyledSpan } from "src/components/ApprovalStatuses/utils";
import React from "react";

export function approvedOn(model: Model): Pair {
    const approved = getApproval(model.application, ApprovalType.PrivacyApproval).approvedOn;
    return {
        label: DisplayLabels.ApprovedOn,
        value: approved.trim() === "" ? <StyledSpan>&mdash;</StyledSpan> : approved,
    };
}
