import { useSelector } from "react-redux";
import { KaleRootState } from "src/redux/store";
import { ApplicationInfoState } from "src/components/survey/state/model";

/**
 * Custom hook to fetch control bindle/related bindle/AAA ids. This abstraction was created to be easily mock-able in
 * unit tests. It grabs the Ids from the redux store and returns a comma seperated string of Ids that the
 * multiNodeConnections API consumes.
 */
export const useFetchIds = (): string => {
    const applicationInfo = useSelector((state: KaleRootState): ApplicationInfoState => state.applicationInfo);
    const relatedBindlesAndAAA = applicationInfo.related_bindles.concat(applicationInfo.aaaIds);
    // Dedupe control and related bindles
    const nodeIds = new Set<string>();
    if (applicationInfo?.control_bindle?.value) {
        nodeIds.add(applicationInfo.control_bindle.value);
    }
    for (const { value } of relatedBindlesAndAAA) {
        if (value) {
            nodeIds.add(value);
        }
    }
    return Array.from(nodeIds).join(",");
};
