import { Box, Button, Container, Grid, Header, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useRef } from "react";
import { FetchBindlesResponse } from "src/components/survey/hooks/useFetchBindles";
import {
    AppInfoResponse,
    ApplicationStatus,
    selectAccountingApprovalStatus,
    selectFraudApprovalStatus,
    selectLegalApprovalStatus,
} from "src/components/survey/SurveyFormModel";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import { useHistory } from "react-router-dom";
import { CampaignType } from "src/components/survey/campaign/types";
import { TEST_IDS } from "shared/survey";
import {
    ApplicationDescription,
    IsConfidentialFunctionalReadOnly,
    ContactListFunctionalReadOnly,
    LegalPoC,
} from "src/components/survey/ApplicationInfo/controls";
import { IsFoundationalApp } from "src/components/survey/ApplicationInfo/controls/IsFoundationalApp";
import { kaleUrls } from "src/util/Urls";

export type OnChangeCb = (response: AppInfoResponse) => void;

interface Props {
    shouldShowErrors: boolean;
    skipValidation: boolean;
    response: AppInfoResponse;
    bindles: FetchBindlesResponse;
    onChangeCallback: OnChangeCb;
    onClearDataStore: () => void;
}

export const ApplicationInfo = (props: Props): JSX.Element => {
    const responseRef = useRef<AppInfoResponse>(props.response);
    responseRef.current = props.response;
    const history = useHistory();
    const legalStatus = selectLegalApprovalStatus(props.response);
    const accountingStatus = selectAccountingApprovalStatus(props.response);
    const fraudStatus = selectFraudApprovalStatus(props.response);

    let financialReviewButtonText = "View Financial Review";
    if (
        accountingStatus.status === ApplicationStatus.inProgress ||
        fraudStatus.status === ApplicationStatus.inProgress
    ) {
        financialReviewButtonText = "Edit Financial Review";
    }

    const appName = encodeURIComponent(props.response.applicationName);
    const reviewId = `${props.response.review.id}`;

    const tafClick = (): void => {
        history.push(kaleUrls.editKaleTafRecordUrl(appName, reviewId));
    };

    const campaignCLick = (): void => {
        const campaignPath = kaleUrls.editKaleCampaignRecordUrl(appName, reviewId, CampaignType.DMA_LOB);
        history.push(campaignPath);
    };

    const onConfidentialChange = (result: FunctionalSurveyItemResult<boolean>): void => {
        props.onChangeCallback({
            ...props.response,
            isConfidential: result.response,
            review: { ...props.response.review },
        });
    };

    const onIsFoundationalAppChange = (result: FunctionalSurveyItemResult<string>): void => {
        props.onChangeCallback({
            ...props.response,
            review: { ...props.response.review, isFoundationalApp: result.response },
        });
    };

    const onContactInfoChange = (result: FunctionalSurveyItemResult<string[]>): void => {
        props.onChangeCallback({
            ...props.response,
            review: {
                ...props.response.review,
                contactList: result.response,
            },
        });
    };

    return (
        <Container
            header={
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <Box float={"left"}>
                        <Header variant={"h3"}>Application Information</Header>
                    </Box>
                    <Box float={"right"}>
                        <SpaceBetween direction="horizontal" size="xs">
                            {Boolean(props.response.review.flags?.length) && (
                                <Button onClick={tafClick} variant="primary">
                                    {financialReviewButtonText}
                                </Button>
                            )}
                            {legalStatus.status !== ApplicationStatus.inProgress && (
                                <Button
                                    data-testid={TEST_IDS.APPLICATION_OVERVIEW.RESOLVE_OPEN_QUESTIONS_BUTTON}
                                    onClick={campaignCLick}
                                    variant="primary"
                                >
                                    Resolve DMA Questions
                                </Button>
                            )}
                        </SpaceBetween>
                    </Box>
                </Grid>
            }
        >
            <SpaceBetween size={"xl"}>
                <IsConfidentialFunctionalReadOnly
                    id={"isConfidential"}
                    expectedAnswer={props.response.isConfidential}
                    onChangeCallback={onConfidentialChange}
                />
                <IsFoundationalApp
                    id={"isFoundationalApp"}
                    shouldShowErrors={props.shouldShowErrors}
                    expectedAnswer={props.response.review.isFoundationalApp}
                    onChangeCallback={onIsFoundationalAppChange}
                />
                <LegalPoC
                    response={props.response}
                    shouldShowErrors={props.shouldShowErrors}
                    skipValidation={props.skipValidation}
                    onChangeCallback={props.onChangeCallback}
                />

                <ApplicationDescription
                    onChangeCallback={props.onChangeCallback}
                    shouldShowErrors={props.shouldShowErrors}
                    response={props.response}
                />

                <ContactListFunctionalReadOnly
                    id={"contactList"}
                    isRequired={props.shouldShowErrors}
                    expectedAnswer={props.response.review.contactList}
                    applicationName={props.response.applicationName}
                    onChangeCallback={onContactInfoChange}
                />
            </SpaceBetween>
        </Container>
    );
};
