import React, { Component, ReactNode } from "react";
import HTMLReactParser, { HTMLReactParserOptions } from "html-react-parser/index";

interface ParserErrorBoundaryProps {
    children: ReactNode;
    text: string;
}
interface ParserErrorBoundaryState {
    hasError: boolean;
}

/* 
html-react-parser team believes that sanitization and incorrect tags are the
responsibility of the consumers. Therefore, we are just going to have a fallback
to just render the string if there is a text that gets confused for a tag.
(e.g. DataStoreName<Queue>)

https://github.com/remarkablemark/html-react-parser/issues/124
*/
class ParserErrorBoundary extends Component<ParserErrorBoundaryProps, ParserErrorBoundaryState> {
    constructor(props: ParserErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    // Caught when component fails (possibly due to incorrectly parsed tags)
    componentDidCatch() {
        this.setState({
            hasError: true,
        });
    }

    render() {
        // If parser hasError, just render the string directly
        if (this.state.hasError) {
            return this.props.text;
        }
        // Otherwise, just render the parsed text
        return this.props.children;
    }
}

/**
 * Parses a string as HTML/JSX and produces the corresponding JSX output.
 */
const safeHTMLReactParser = (text: string, parserOptions?: HTMLReactParserOptions): JSX.Element => (
    <ParserErrorBoundary text={text}>{HTMLReactParser(text, parserOptions)}</ParserErrorBoundary>
);

export default safeHTMLReactParser;
