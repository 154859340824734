import { Modal, Wizard, WizardProps } from "@amzn/awsui-components-react-v3";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DataStoreAnswer, ResetOptionsCallback } from "src/answers_legacy";
import { useDataStores } from "src/answers_legacy/hooks/dataStores";
import { setDataStoresConstraintHandler } from "src/components/survey/DataStore_options";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import {
    BRAND_NEW_DATA_STORE__HAS_NO_INDEX,
    INITIAL_WIZARD_ACTIVE_STEP_INDEX,
} from "src/components/survey/DataStoreInfo/contants";
import { useMakeWizardSteps, MakeWizardStepsOptions } from "src/components/survey/DataStoreInfo/DataStoreWizard/hooks";
import { DataStoreWizardProps } from "src/components/survey/DataStoreInfo/DataStoreWizard/types";
import { makeDefaultDataStoreResponse } from "src/components/survey/LegalSurveyPage";
import { useIsAuthorized, UserAction } from "src/permissions";
import { TEST_IDS } from "shared/survey";
import { useHandleOnWizardOpen } from "src/components/survey/DataStoreInfo/DataStoreWizard/hooks/useHandleOnWizardOpen";

export type { DataStoreWizardProps };

const { ROOT } = TEST_IDS.DATA_STORE_INFO.DATA_STORE_WIZARD;

const getI18nStrings = (isExistingDataStore: boolean, isDataStoreReadOnly: boolean): WizardProps.I18nStrings => {
    const closeButtonLabel = "Close Wizard";
    const submitButtonLabel = "Apply to unsaved draft";

    return {
        stepNumberLabel: (stepNumber: number): string => {
            return `Step ${stepNumber}`;
        },
        collapsedStepsLabel: (stepNumber: number, stepsCount: number): string => `Step ${stepNumber} of ${stepsCount}`,
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: isDataStoreReadOnly ? closeButtonLabel : submitButtonLabel,
        optional: "not applicable",
    };
};

interface GetActiveDataStoreConfig {
    activeDataStoreIndex: number;
    committedDataStores: DataStoreResponse[];
    isExistingDataStore: boolean;
}

const getActiveDataStore = (config: GetActiveDataStoreConfig): DataStoreResponse => {
    const { activeDataStoreIndex, committedDataStores } = config;
    return activeDataStoreIndex === BRAND_NEW_DATA_STORE__HAS_NO_INDEX
        ? makeDefaultDataStoreResponse()
        : cloneDeep(committedDataStores[activeDataStoreIndex]);
};

type SetStateFunc<T> = React.Dispatch<React.SetStateAction<T>>;
type UseStateTuple<T> = [T, SetStateFunc<T>];
type ActiveDataStoreStateTuple = UseStateTuple<DataStoreResponse>;
const useActiveDataStore = (
    props: DataStoreWizardProps,
    isExistingDataStore: boolean
): Readonly<ActiveDataStoreStateTuple> => {
    // Make local activeDataStore state and keep it up to date
    // when specific dependencies from props have changed.

    const { activeDataStoreIndex, committedDataStores } = props;

    const [activeDataStore, setActiveDataStore] = useState<DataStoreResponse>(
        (): DataStoreResponse => getActiveDataStore({ activeDataStoreIndex, committedDataStores, isExistingDataStore })
    );

    useEffect((): void => {
        setActiveDataStore(
            (): DataStoreResponse =>
                getActiveDataStore({
                    activeDataStoreIndex,
                    committedDataStores,
                    isExistingDataStore,
                })
        );
    }, [activeDataStoreIndex, isExistingDataStore, committedDataStores]);

    return [activeDataStore, setActiveDataStore] as const;
};

export const DataStoreWizard = (props: DataStoreWizardProps): JSX.Element | null => {
    const {
        committedDataStores,
        activeDataStoreIndex,
        questions,
        hasTafLayout = false,
        showModal,
        onSubmit,
        onClose,
    } = props;
    const isExistingDataStore = activeDataStoreIndex !== BRAND_NEW_DATA_STORE__HAS_NO_INDEX;
    const [, datastoreActions] = useDataStores();
    const [activeDataStore, setActiveDataStore] = useActiveDataStore(props, isExistingDataStore);
    const [activeStepIndex, setActiveStepIndex] = useState<number>(INITIAL_WIZARD_ACTIVE_STEP_INDEX);
    const [isActiveStepAnswerValid, setIsActiveStepAnswerValid] = useState<boolean>(true);
    const [shouldRenderCriticalValidationErrors, setShouldRenderCriticalValidationErrors] = useState<boolean>(false);

    const deps = {
        datastoreActions,
        activeDataStoreIndex,
        activeDataStore,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    useEffect((): void => {
        const { datastoreActions, activeDataStore } = dependenciesRef.current;
        const { addAnswers } = datastoreActions;
        if (!showModal || questions.length === 0) {
            return;
        }
        if (activeDataStoreIndex === BRAND_NEW_DATA_STORE__HAS_NO_INDEX) {
            addAnswers([], questions, activeDataStore.unsavedUUID);
        }
    }, [activeDataStoreIndex, questions, showModal]);

    useEffect((): ResetOptionsCallback | void => {
        const { datastoreActions, activeDataStoreIndex } = dependenciesRef.current;
        const { addOptions, resetOptions } = datastoreActions;

        if (!hasTafLayout) {
            const getDatastoreTechnologyCb = (answer: DataStoreAnswer): string => {
                const index = committedDataStores.findIndex(
                    (dataStore): boolean =>
                        (Boolean(dataStore.id) && dataStore.id === answer.dataStoreId) ||
                        (Boolean(dataStore.unsavedUUID) && dataStore.unsavedUUID === answer.userDataStoreId)
                );

                // If a question belongs to the datastore that is actively in view in the wizard we prefer,
                // to grab the datastore technology value from the current activeDatastore state object in
                // order to make sure we are using the latest in flight changes to that datastore when making
                // question applicability decisions
                const activeDatastoreIsNewInFlightDatastore = index === BRAND_NEW_DATA_STORE__HAS_NO_INDEX;
                const activeDatastoreIsExistingDatastoreWithInFlightEdits = index === activeDataStoreIndex;
                return activeDatastoreIsNewInFlightDatastore || activeDatastoreIsExistingDatastoreWithInFlightEdits
                    ? activeDataStore?.technology
                    : committedDataStores[index]?.technology;
            };

            setDataStoresConstraintHandler(addOptions, getDatastoreTechnologyCb);

            return (): void => resetOptions();
        }
    }, [activeDataStore, committedDataStores, hasTafLayout]);

    const onHandleClose = useCallback((): void => {
        setShouldRenderCriticalValidationErrors(false);
        onClose({ ...activeDataStore });
        setActiveDataStore(makeDefaultDataStoreResponse());
    }, [activeDataStore, onClose, setActiveDataStore]);

    const stepOptions: MakeWizardStepsOptions = {
        // Spread props and local state into options.
        ...props,
        activeDataStore,
        setActiveDataStore,
        activeStepIndex,
        setActiveStepIndex,
        setIsActiveStepAnswerValid,
        shouldRenderErrors: shouldRenderCriticalValidationErrors,
    };

    const [steps, isStepApplicableCb] = useMakeWizardSteps(stepOptions, hasTafLayout);

    const canEditDataStore = useIsAuthorized(UserAction.editApplication);
    const isDataStoreReadOnly = !canEditDataStore;

    useHandleOnWizardOpen({ showModal, isExistingDataStore, hasTafLayout, setActiveStepIndex });

    if (!showModal) {
        return null;
    }

    return (
        <Modal
            visible={showModal}
            size="max"
            header={`${isExistingDataStore ? "Viewing" : "Add"} Data Store`}
            onDismiss={onHandleClose}
        >
            <Wizard
                data-testid={ROOT}
                activeStepIndex={activeStepIndex}
                steps={steps}
                i18nStrings={getI18nStrings(isExistingDataStore, isDataStoreReadOnly)}
                onCancel={onHandleClose}
                onNavigate={(event): void => {
                    const { requestedStepIndex, reason } = event.detail;

                    if (isActiveStepAnswerValid) {
                        // Remove previous error messages
                        setShouldRenderCriticalValidationErrors(false);

                        // Perform step Navigation differently based on event 'reason'
                        switch (reason) {
                            case "previous": {
                                let index = requestedStepIndex;
                                while (isStepApplicableCb(index) === false) {
                                    index--;
                                }
                                setActiveStepIndex(Math.max(index, 0));
                                break;
                            }
                            case "next": {
                                let index = requestedStepIndex;
                                while (isStepApplicableCb(index) === false) {
                                    index++;
                                }
                                setActiveStepIndex(Math.min(index, steps.length - 1));
                                break;
                            }
                            case "step": {
                                setActiveStepIndex(requestedStepIndex);
                                break;
                            }
                        }
                    } else {
                        // If the current step is reporting a custom validation error we will display those and won't
                        // shouldn't navigation until the errors are fixed. This is a core behavior for our collect
                        // data store info step to make sure a user isn't able to advance the wizard if they have
                        // entered information that would cause their data store to be considered a duplicate of
                        // another existing datastore within their Kale application
                        setShouldRenderCriticalValidationErrors(true);
                    }
                }}
                onSubmit={(): void => {
                    if (isActiveStepAnswerValid) {
                        setShouldRenderCriticalValidationErrors(false);
                        setActiveStepIndex(0);
                        // all newly saved data stores should have the data elements considered already "migrated"
                        onSubmit({ ...activeDataStore });
                        setActiveDataStore(makeDefaultDataStoreResponse());
                    } else {
                        setShouldRenderCriticalValidationErrors(true);
                    }
                }}
            />
        </Modal>
    );
};
