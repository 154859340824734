import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import KaleContext from "src/components/KaleContext";
import { IncrementalSaveInput } from "src/services/IncrementalSave";
import { kaleUrls } from "src/util/Urls";
import { IncrementalSaveThrownError } from "src/services/KaleApplicationService";

type SaveCampaign = (input: IncrementalSaveInput) => Promise<void>;

export const useSaveCampaign = (): {
    isSaving: boolean;
    save: SaveCampaign;
} => {
    const kaleContext = useContext(KaleContext);
    const { incrementalSave } = kaleContext.service.kaleAppService;
    const history = useHistory();

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const save = async (input: IncrementalSaveInput): Promise<void> => {
        setIsSaving(true);
        try {
            await incrementalSave(input);
            history.push(kaleUrls.editKaleRecordUrl(input.appName ?? "", input.reviewID ?? ""));
        } catch (error) {
            const errMsg =
                `FAILED TO SAVE APPLICATION INFORMATION. ` +
                `Please try again. Failed reason: ${(error as IncrementalSaveThrownError).message}`;
            return Promise.reject(new Error(errMsg));
        } finally {
            setIsSaving(false);
        }
    };

    return { isSaving, save };
};
