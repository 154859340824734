import { useContext, useMemo, useRef } from "react";
import { answerActionsContext, Answers, AnswerState, answerStateContext, Response, Validation } from "../context";
import { AnswerActions, StateKey } from "../actions";
import {
    DefaultTableFieldAnswer,
    TableFieldAnswer,
    TableFieldAnswerWithQuestion,
    TableFieldQuestion,
} from "../models/services";
import { HookAnswer, getAnswerResponseState } from "./common";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";

export type TableFieldSidebarHookAnswer = HookAnswer<TableFieldQuestion, TableFieldAnswer>;
export interface KaleTableFieldSidebarAccessor extends Validation {
    answers: TableFieldSidebarHookAnswer[];
}

export function getKaleTableFieldSidebar(
    state: AnswerState,
    fieldId?: number,
    userFieldId?: string
): Answers<TableFieldAnswerWithQuestion> | undefined {
    return fieldId || userFieldId
        ? state[StateKey.kaleTableFieldSidebar].find(
              (item): boolean =>
                  item.value[0] &&
                  (fieldId
                      ? item.value[0].value.answer.fieldId === fieldId
                      : item.value[0].value.answer.userFieldId === userFieldId)
          )
        : state[StateKey.kaleTableFieldSidebar][0];
}

export type TableFieldSidebarAnswerActions = AnswerActions<TableFieldQuestion, TableFieldAnswer>;

export function getKaleTableFieldSidebarAnswerById(
    state: AnswerState,
    actions: TableFieldSidebarAnswerActions,
    questionShortId?: string,
    fieldId?: number,
    userFieldId?: string
): HookAnswer<TableFieldQuestion, TableFieldAnswer> | void {
    const kaleTableFieldState = getKaleTableFieldSidebar(state, fieldId, userFieldId);

    const questionWithAnswer = kaleTableFieldState?.value.find(
        (answer: Response<TableFieldAnswerWithQuestion>): boolean => {
            const id = answer.value.answer.questionShortId;
            return id === questionShortId;
        }
    );

    if (!questionWithAnswer) {
        console.error(`Invalid requested field answer by shortid "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<TableFieldQuestion, TableFieldAnswer>(questionWithAnswer, actions);
}

export const useKaleTableFieldSidebar = (): [KaleTableFieldSidebarAccessor, TableFieldSidebarAnswerActions] => {
    const actions = useContext(answerActionsContext)<TableFieldQuestion, TableFieldAnswer>(
        StateKey.kaleTableFieldSidebar
    );
    const state = useContext(answerStateContext);
    const sidebarState = state[StateKey.kaleTableFieldSidebar];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [KaleTableFieldSidebarAccessor, TableFieldSidebarAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            sidebarState.map((tableField): KaleTableFieldSidebarAccessor => {
                return {
                    answers: tableField.value
                        .map(
                            (
                                questionWithAnswer: Response<TableFieldAnswerWithQuestion>
                            ): HookAnswer<TableFieldQuestion, TableFieldAnswer> | void => {
                                const { fieldId, questionShortId, userFieldId } = questionWithAnswer.value.answer;
                                return getKaleTableFieldSidebarAnswerById(
                                    state,
                                    actions,
                                    questionShortId,
                                    fieldId,
                                    userFieldId
                                );
                            }
                        )
                        .filter((v): boolean => !!v) as HookAnswer<TableFieldQuestion, TableFieldAnswer>[],
                    isValid: tableField.isValid,
                    isValidSave: tableField.isValidSave,
                    isValidSubmit: tableField.isValidSubmit,
                    hasChanged: tableField.hasChanged,
                    userLastUpdated: tableField.userLastUpdated,
                };
            })[0], // Return first field only, more not needed
            actions,
        ];
    }, [sidebarState]);
};

interface ComplianceTypeMap {
    [k: string]: string;
}

export const useKaleTableFieldSidebarPayload = (): TableFieldAnswer[] => {
    const state = useContext(answerStateContext);
    const sidebarState = state[StateKey.kaleTableFieldSidebar];

    return useMemo((): TableFieldAnswer[] => {
        const complianceTypeMap: ComplianceTypeMap = {};
        return sidebarState
            .map((tableField): TableFieldAnswer[] =>
                tableField.value.map((questionWithAnswer): TableFieldAnswer => {
                    complianceTypeMap[questionWithAnswer.value.question.id] =
                        questionWithAnswer.value.question.complianceType;
                    return questionWithAnswer.value.answer;
                })
            )[0]
            .map((answer): TableFieldAnswer => {
                const base = DefaultTableFieldAnswer(
                    answer.questionId,
                    answer.questionShortId,
                    answer.fieldId,
                    answer.userFieldId
                );

                return {
                    ...base,
                    ...answer,
                    complianceType: complianceTypeMap[answer.questionId],
                };
            })
            .filter((answer): boolean => meetsServerAnswerConstraint(answer));
    }, [sidebarState]); // Return first field only, more not needed
};
