import React, { createContext, useEffect, useState } from "react";
import { useAnswersReducer } from "./reducers";
import {
    AnswerActions,
    RESET_ANSWERS,
    UPDATE_ANSWERS,
    useAnswersActions,
    StateKey,
    ADD_ANSWERS,
    REMOVE_ANSWERS,
    RESET_AND_ADD_ANSWERS,
    ADD_OPTIONS,
    RESET_OPTIONS,
    AnswersActionsCallback,
    StateOptions,
    RESET_CHANGE_DETECTION,
} from "./actions";
import {
    TableAnswerWithQuestion,
    TableFieldAnswerWithQuestion,
    DataStoreAnswerWithQuestion,
    TableQuestion,
    TableAnswer,
    TableFieldQuestion,
    TableFieldAnswer,
    DataStoreQuestion,
    DataStoreAnswer,
    ReviewAnswerWithQuestion,
    ReviewQuestion,
    ReviewAnswer,
} from "./models/services";
import { AnswerBase, QuestionBase } from "src/answers_legacy/models/common";

export interface ParentConstraint {
    isApplicable: boolean;
}

export interface Validation {
    isValid: boolean;
    isValidSave: boolean;
    isValidSubmit: boolean;
    hasChanged: boolean;
    userLastUpdated: string;
}

export interface Response<T> extends Validation, ParentConstraint {
    value: T;
}

export interface Answers<T> extends Validation {
    value: (Response<T> | never)[];
}

export enum AnswerStateEntityId {
    review = "review",
    dataStores = "dataStores",
    kaleTable = "kaleTable",
    kaleTableFields = "kaleTableFields",
    // When copying table fields to sidebar slice, remember to update the entity id to this one.
    kaleTableFieldSidebar = "kaleTableFieldSidebar",
}

export interface AnswerState {
    review: Answers<ReviewAnswerWithQuestion>[];
    reviewOptions?: StateOptions<ReviewQuestion, ReviewAnswer>;
    dataStores: Answers<DataStoreAnswerWithQuestion>[];
    dataStoresOptions?: StateOptions<DataStoreQuestion, DataStoreAnswer>;
    kaleTable: Answers<TableAnswerWithQuestion>[];
    kaleTableOptions?: StateOptions<TableQuestion, TableAnswer>;
    kaleTableFields: Answers<TableFieldAnswerWithQuestion>[];
    kaleTableFieldsOptions?: StateOptions<TableFieldQuestion, TableFieldAnswer>;
    bulkEditKaleTable: Answers<TableAnswerWithQuestion>[];
    bulkEditKaleTableOptions?: StateOptions<TableQuestion, TableAnswer>;
    bulkEditKaleTableFields: Answers<TableFieldAnswerWithQuestion>[];
    bulkEditKaleTableFieldsOptions?: StateOptions<TableFieldQuestion, TableFieldAnswer>;
    kaleTableFieldSidebar: Answers<TableFieldAnswerWithQuestion>[];
    kaleTableFieldSidebarOptions?: StateOptions<TableFieldQuestion, TableFieldAnswer>;
}

export const initialState: AnswerState = {
    [StateKey.review]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
    [StateKey.dataStores]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
    [StateKey.kaleTableFieldSidebar]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
    [StateKey.kaleTableFields]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
    [StateKey.kaleTable]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
    [StateKey.bulkEditKaleTableFields]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
    [StateKey.bulkEditKaleTable]: [
        {
            value: [],
            isValid: false,
            isValidSave: false,
            isValidSubmit: false,
            hasChanged: false,
            userLastUpdated: "",
        },
    ],
};

export interface AnswerActionsCallback<QType extends QuestionBase, AType extends AnswerBase> {
    (stateKey: StateKey, id?: string): AnswerActions<QType, AType>;
}

export const initialDispatch: AnswerActionsCallback<any, any> = (): AnswerActions<any, any> => ({
    [ADD_ANSWERS]: (): void => {},
    [UPDATE_ANSWERS]: (): void => {},
    [REMOVE_ANSWERS]: (): void => {},
    [RESET_ANSWERS]: (): void => {},
    [RESET_AND_ADD_ANSWERS]: (): void => {},
    [ADD_OPTIONS]: (): void => {},
    [RESET_OPTIONS]: (): void => {},
    [RESET_CHANGE_DETECTION]: (): void => {},
});

const answerStateContext = createContext<AnswerState>(initialState);
const { Consumer: AnswerStateConsumer, Provider: AnswerStateProvider } = answerStateContext;

const answerActionsContext =
    createContext<
        <QType extends QuestionBase, AType extends AnswerBase>(stateKey: StateKey) => AnswerActions<QType, AType>
    >(initialDispatch);
const { Consumer: AnswerActionConsumer, Provider: AnswerActionsProvider } = answerActionsContext;

interface AnswerProviderProps {
    children: React.ReactNode;
}

const withDevTools =
    process.env.NODE_ENV === "development" &&
    typeof window !== "undefined" &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__;

let devTools;
let unsubscribe: () => void;

const AnswerCombinedProvider = ({ children }: AnswerProviderProps): JSX.Element => {
    const [state, dispatch] = useAnswersReducer();
    const actions = useAnswersActions(dispatch) as AnswersActionsCallback<any, any>;
    const [, setDevToolsState] = useState({});

    useEffect((): (() => void) => {
        if (withDevTools) {
            // @ts-ignore
            // eslint-disable-next-line
            devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect();
            unsubscribe = devTools.subscribe(
                (message: { type: string; payload: { type: string }; state: React.SetStateAction<object> }): void => {
                    // Implement monitors actions.
                    // For example time traveling:
                    if (message.type === "DISPATCH" && message.payload.type === "JUMP_TO_STATE") {
                        setDevToolsState(message.state);
                    }
                }
            );
            devTools.send("@@INIT_ANSWERS_REACT_CONTEXT", state);
            // @ts-ignore
            window.__KALE_ANSWERS_CONTEXT_DEVTOOLS__ = devTools;
        }

        return (): void => {
            if (withDevTools) {
                unsubscribe(); // Use if you have other subscribers from other components.
                // @ts-ignore
                window.__REDUX_DEVTOOLS_EXTENSION__.disconnect(); // If there aren't other subscribers.
            }
        };
        // Suppressing state dependency so provider component doesn't reinitialize devtools on every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AnswerStateProvider value={state}>
            <AnswerActionsProvider value={actions}>{children}</AnswerActionsProvider>
        </AnswerStateProvider>
    );
};

export {
    answerStateContext,
    answerActionsContext,
    AnswerStateConsumer,
    AnswerActionConsumer,
    AnswerActionsProvider,
    AnswerCombinedProvider,
};
