/* eslint-disable max-len */
import { useKaleTable } from "src/answers_legacy";
import { useEffect, useRef, useState } from "react";
import { AndesPolicyCradleMetadata } from "./types";
import { AndesPolicyTypes, CRADLE_QUESTION_SHORT_IDS_BY_POLICY } from "./constants";
/* eslint-enable max-len */

/**
 * Custom hook used to determine when Table questions have been loaded into the GSM.
 */
const useHasTableQuestionsStateLoaded = (): boolean => {
    const [{ answers: managedTableQuestions }] = useKaleTable();
    const [hasQuestionsStateLoaded, setHasQuestionsStateLoaded] = useState<boolean>(
        Boolean(managedTableQuestions.length)
    );

    const hasStateLoadedRef = useRef(hasQuestionsStateLoaded);
    hasStateLoadedRef.current = hasQuestionsStateLoaded;

    useEffect((): void => {
        if (!hasStateLoadedRef.current) {
            setHasQuestionsStateLoaded(true);
        }
    }, [managedTableQuestions.length, hasStateLoadedRef]);

    return hasQuestionsStateLoaded;
};

/**
 * This custom hook will update respective cradleAccountId and cradleProfileId questions
 * for the given Policy/Compliance Type in the GSM. We don't expect the user to manually
 * answer these questions, but we do want to store the data returned from the Cradle
 * Metadata api in our own backend db. Longer term, it seems we should move this
 * work to store the answers into the backend proxy API that we use to fetch the
 * cradle metadata in the first place.
 * @param andesPolicyCradleMetaData
 * @param policyType
 */
export const useHandleAndesPolicyCradleTableQuestions = (
    andesPolicyCradleMetaData: AndesPolicyCradleMetadata,
    policyType: AndesPolicyTypes
): void => {
    const [{ answers: tableQuestionsState }] = useKaleTable();
    const hasTableQuestionsStateLoaded = useHasTableQuestionsStateLoaded();

    {
        const { cradleMetadata } = andesPolicyCradleMetaData;
        const { cradleProfileId, cradleAccount } = cradleMetadata || {};

        const deps = {
            policyType,
            CRADLE_QUESTION_SHORT_IDS_BY_POLICY,
            tableQuestionsState,
        };
        const dependenciesRef = useRef(deps);
        dependenciesRef.current = deps;

        useEffect((): void => {
            if (cradleAccount && cradleProfileId && hasTableQuestionsStateLoaded) {
                const { CRADLE_QUESTION_SHORT_IDS_BY_POLICY, tableQuestionsState, policyType } =
                    dependenciesRef.current;
                const { profileIdShortId, accountIdShortId } = CRADLE_QUESTION_SHORT_IDS_BY_POLICY[policyType] ?? {};

                // Select the 2 Cradle Related Table Questions for this Policy Type
                const cradleQuestions = tableQuestionsState.filter(({ question: { shortId } }): boolean =>
                    [profileIdShortId, accountIdShortId].includes(shortId)
                );
                const profileIdQuestion = cradleQuestions.find(
                    ({ question: { shortId } }): boolean => shortId === profileIdShortId
                );
                const accountIdQuestion = cradleQuestions.find(
                    ({ question: { shortId } }): boolean => shortId === accountIdShortId
                );

                // Update each TableQuestion Cradle Answers with the respective cradle metadata values
                if (profileIdQuestion && profileIdQuestion.value !== cradleProfileId) {
                    profileIdQuestion.setValue(cradleProfileId);
                }
                if (accountIdQuestion && accountIdQuestion.value !== cradleAccount) {
                    accountIdQuestion.setValue(cradleAccount);
                }
            }
        }, [hasTableQuestionsStateLoaded, cradleProfileId, cradleAccount]);
    }
};
