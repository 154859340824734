import { WebSocketHandler } from "src/services/WebSocketService";
import useSubscription from "src/websocket/useSubscription";
import { PongMessage, PongMessageHandler } from "src/websocket/message/Pong";
import { useCallback } from "react";
import { BulkEditMessage, BulkEditMessageHandler } from "src/websocket/message/BulkEdit";
import { RecallMessage, RecallMessageHandler } from "src/websocket/message/Recall";
import { SubmitMessage, SubmitMessageHandler } from "src/websocket/message/Submit";
import {
    UbergetCacheUpdatedMessage,
    UbergetCacheUpdatedMessageHandler,
} from "src/websocket/message/UbergetCacheUpdated";

export enum ActionType {
    PONG = "pong",
    BULK_EDIT = "bulkEdit",
    RECALL = "recall",
    SUBMIT = "submit",
    UBERGET_CACHE_UPDATED = "uberGetCacheUpdated",
}

export interface WebSocketMessageBase {
    connectionId: string;
    type: ActionType;
}

export const useOnWebSocketMessage = (): {
    onWebSocketMessage: WebSocketHandler;
    subscribeToPongMessage: (handler: PongMessageHandler) => void;
    unsubscribeToPongMessage: (handler: PongMessageHandler) => void;
    subscribeToBulkEditMessage: (handler: BulkEditMessageHandler) => void;
    unsubscribeToBulkEditMessage: (handler: BulkEditMessageHandler) => void;
    subscribeToRecallMessage: (handler: RecallMessageHandler) => void;
    unsubscribeToRecallMessage: (handler: RecallMessageHandler) => void;
    subscribeToSubmitMessage: (handler: SubmitMessageHandler) => void;
    unsubscribeToSubmitMessage: (handler: SubmitMessageHandler) => void;
    subscribeToUbergetCacheUpdatedMessage: (handler: UbergetCacheUpdatedMessageHandler) => void;
    unsubscribeToUbergetCacheUpdatedMessage: (handler: UbergetCacheUpdatedMessageHandler) => void;
} => {
    const {
        subscribe: subscribeToPongMessage,
        unsubscribe: unsubscribeToPongMessage,
        publish: publishPongMessage,
    } = useSubscription<PongMessage, PongMessageHandler>();
    const {
        subscribe: subscribeToBulkEditMessage,
        unsubscribe: unsubscribeToBulkEditMessage,
        publish: publishBulkEditMessage,
    } = useSubscription<BulkEditMessage, BulkEditMessageHandler>();
    const {
        subscribe: subscribeToRecallMessage,
        unsubscribe: unsubscribeToRecallMessage,
        publish: publishRecallMessage,
    } = useSubscription<RecallMessage, RecallMessageHandler>();
    const {
        subscribe: subscribeToSubmitMessage,
        unsubscribe: unsubscribeToSubmitMessage,
        publish: publishSubmitMessage,
    } = useSubscription<SubmitMessage, SubmitMessageHandler>();
    const {
        subscribe: subscribeToUbergetCacheUpdatedMessage,
        unsubscribe: unsubscribeToUbergetCacheUpdatedMessage,
        publish: publishUbergetCachedUpdatedMessage,
    } = useSubscription<UbergetCacheUpdatedMessage, UbergetCacheUpdatedMessageHandler>();

    const onWebSocketMessage = useCallback(
        async (event: MessageEvent): Promise<void> => {
            const parsedData: WebSocketMessageBase = JSON.parse(event.data);
            switch (parsedData.type) {
                case ActionType.PONG:
                    await publishPongMessage(parsedData as PongMessage);
                    break;
                case ActionType.BULK_EDIT:
                    await publishBulkEditMessage(parsedData as BulkEditMessage);
                    break;
                case ActionType.RECALL:
                    await publishRecallMessage(parsedData as RecallMessage);
                    break;
                case ActionType.SUBMIT:
                    await publishSubmitMessage(parsedData as SubmitMessage);
                    break;
                case ActionType.UBERGET_CACHE_UPDATED:
                    await publishUbergetCachedUpdatedMessage(parsedData as UbergetCacheUpdatedMessage);
                    break;
                default:
                    console.error("unknown message type");
            }
        },
        [
            publishBulkEditMessage,
            publishPongMessage,
            publishRecallMessage,
            publishSubmitMessage,
            publishUbergetCachedUpdatedMessage,
        ]
    );

    return {
        onWebSocketMessage,
        subscribeToPongMessage,
        unsubscribeToPongMessage,
        subscribeToBulkEditMessage,
        unsubscribeToBulkEditMessage,
        subscribeToRecallMessage,
        unsubscribeToRecallMessage,
        subscribeToSubmitMessage,
        unsubscribeToSubmitMessage,
        subscribeToUbergetCacheUpdatedMessage,
        unsubscribeToUbergetCacheUpdatedMessage,
    };
};
