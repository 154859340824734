export enum QuestionType {
    text = "text",
    textTags = "textTags",
    textArea = "textArea",
    radio = "radio",
    checkbox = "checkbox",
    checkboxGroup = "checkboxGroup",
    date = "date",
    autoSuggest = "autoSuggest",
    singleSelect = "singleSelect",
    multiSelect = "multiSelect",
    retention = "retention",
}

export enum QuestionSetType {
    table = "Table",
    andesTable = "AndesTable",
    field = "Field",
    andesField = "AndesField",
}

export enum QuestionTag {
    required = "required",
    requiredForDraft = "requiredForDraft",
    readOnly = "readOnly",
    // primaryView and tafView are NOT mutually exclusive as we'll have questions that are on both
    primaryView = "primaryView",
    tafView = "tafView",
    disabledForAndes = "disabledForAndes",
    ignoredForAndes = "ignoredForAndes",
    requiredForAndes = "requiredForAndes",
    selectIsNotClearable = "selectIsNotClearable",
    // this can be used to help render questions that should
    // live under the parent question rather than inline
    cellChild = "cellChild",
    // this is used to hide the prefilled answer from users
    hiddenPrefilledAnswer = "hiddenPrefilledAnswer",
    // This is used to hide some data store question(s) are no longer applicable in the latest versions of kale.
    // However, the question(s) still exist on the backend in order to keep the historical answer values
    // intact, so we just don't display them in the front end.
    legacyHidden = "legacyHidden",
    // This is intended for questions that should not be shown outside of campaign pages. Use this without also
    // setting the required tag to avoid having issues with validation when users submit.
    // At the time of creating this tag I will only be hiding questions with this tag in the review section
    // Update this comment if you add support elsewhere
    campaignOnly = "campaignOnly",
    // this means that the question is always applicable for taf questions
    tafAlwaysApplicable = "tafAlwaysApplicable",
    // this means that the service layer or equivalent should ignore the title for taf questions
    tafIgnoreTitle = "tafIgnoreTitle",
    // use this to replace the first question in a wizard step questions list
    wizardStepOrderFirst = "wizardStepOrderFirst",
    // it is used to determine validity for B2B, PDP and TAFS questions based on response of "containsPersonalData"
    storePersonalData = "store_personal_data",
    processPersonalData = "process_personal_data",

    colspan33 = "colspan33",
    colspan50 = "colspan50",
    colspan66 = "colspan66",
    colspan100 = "colspan100",
    newGroupSection = "newGroupSection",

    coppaQuestion = "coppaQuestion",
    useAnyParentGSMConstraint = "useAnyParentGSMConstraint",

    bulkEditable = "bulkEditable",

    excludeOtherOption = "excludeOtherOption",

    // Please refer to ConditionalSectionFooter.tsx for a comment explaining
    // the purpose of these two question tags.
    sandfireInfo = "sandfireInfo",
    aeduInfo = "aeduInfo",
}

export const PrefilledAnswerValue = {
    NA: "N/A",
};

// Question Tags used to control custom applicability behavior
export enum QuestionApplicabilityTag {
    // Not in Camel Case as these will directly map to business use case mappings coming from backend
    metadataCreationDate = "creation_date",
    metadataDeletionRequestDate = "deletion_request_date",
    metadataTaxPeriodEndDate = "tax_period_end_date",
}

export enum AnswerContentKey {
    textContent = "textContent",
    arrayContent = "arrayContent",
    dateContent = "dateContent",
    jsonContent = "jsonContent",
}

export const ContentKeyMap: Record<QuestionType, AnswerContentKey> = {
    [QuestionType.text]: AnswerContentKey.textContent,
    [QuestionType.textTags]: AnswerContentKey.arrayContent,
    [QuestionType.textArea]: AnswerContentKey.textContent,
    [QuestionType.radio]: AnswerContentKey.textContent,
    [QuestionType.checkbox]: AnswerContentKey.textContent,
    [QuestionType.checkboxGroup]: AnswerContentKey.arrayContent,
    [QuestionType.autoSuggest]: AnswerContentKey.textContent,
    [QuestionType.singleSelect]: AnswerContentKey.textContent,
    [QuestionType.date]: AnswerContentKey.dateContent,
    [QuestionType.multiSelect]: AnswerContentKey.arrayContent,
    [QuestionType.retention]: AnswerContentKey.jsonContent,
};
