export interface DataStoreIdentifier {
    type: string;
    value: string;
}

export function generateIdentifiers(dataStoreTech: string): DataStoreIdentifier[] {
    switch (dataStoreTech) {
        case "Athena":
        case "Aurora":
        case "DynamoDB":
        case "RDS":
        case "Redshift":
        case "S3":
        case "CloudDirectory":
        case "CloudWatch Logs":
        case "DocumentDB":
        case "EC2 Instance (Run-Your-Own)":
        case "ECS (Run-Your-Own)":
        case "ElastiCache":
        case "Elasticsearch":
        case "EMR":
        case "Fargate (Run-Your-Own)":
        case "Glacier":
        case "Kinesis":
        case "Managed Blockchain":
        case "Neptune":
        case "QLDB":
        case "Timestream":
        case "SQS":
        case "Apache Cassandra Service":
        case "Hoverboard":
            return [{ type: "ARN", value: "" }];

        case "ColdTier":
            return [{ type: "Scope", value: "" }];

        case "DataWave":
            return [{ type: "DataSet", value: "" }];

        case "Embu":
            return [{ type: "Domain", value: "" }];

        case "Sable":
            return [
                { type: "Scope", value: "" },
                { type: "Entity", value: "" },
            ];

        case "Clickstream":
            return [
                { type: "Feed", value: "" },
                { type: "Dataset", value: "" },
            ];

        case "Andes":
            return [];

        case "G2S2":
        case "MatrixStorage":
        case "Platform Search":
        case "Safe Dynamic Config":
        case "Sembu":
        case "SimpleDB":
        case "Tokenator":
        case "Tortuga":
        case "Whispersync":
        case "Alexandria":
        case "Beejak":
        case "IPDocumentDataStorageService":
        case "EDX":
        case "ORCA":
        case "Timber":
        default:
            return [{ type: "Identifier", value: "" }];
    }
}
