import { SelectProps } from "@amzn/awsui-components-react-v3";

export const ANVIL_OPTION = {
    NA: {
        value: "na",
        label: "N/A",
        description: "No related Anvil Application",
    },
    OTHER: {
        value: "other",
        label: "Other",
        description: "Custom Anvil Application ID",
    },
};

export const defaultAnvilOptions: SelectProps.Option[] = [ANVIL_OPTION.NA, ANVIL_OPTION.OTHER];

export enum AnvilIdStatus {
    na = "na",
    invalid = "invalid",
    loading = "loading",
    linked = "linked",
    valid = "valid",
}
