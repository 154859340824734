import React from "react";
import { QuestionTag, QuestionType, ReviewHookAnswer } from "src/answers_legacy";
import NewDropdown from "src/components/fields/dropdown/NewDropdown";
import { FunctionalSurveyItemProps, FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import { FormField, Multiselect, MultiselectProps, SelectProps, SpaceBetween } from "@amzn/awsui-components-react-v3";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";
import { ReviewAttributeShortId } from "src/components/TAF/TAFDetails/constants";
import NewTextArea from "src/components/fields/textbox/NewTextArea";
import NewTextInput from "src/components/fields/textbox/NewTextInput";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { makeOptionFromChoice } from "src/services/dynamic-questions";

const MultiSelectWithReadOnly = withFunctionalReadonly(
    (props: MultiselectProps & FunctionalSurveyItemProps<string[]>): JSX.Element => {
        return <Multiselect {...props} disabled={props.isFormInReadonlyMode} />;
    }
);

export const AnswerInput = (props: {
    answer?: ReviewHookAnswer;
    answers?: ReviewHookAnswer[];
    isReadOnly?: boolean;
    isTafReviewer?: boolean;
    showErrors?: boolean;
}): JSX.Element | null => {
    const { answer, answers, isReadOnly, isTafReviewer, showErrors } = props;

    const renderInput = (answer?: ReviewHookAnswer, answers?: ReviewHookAnswer[]): JSX.Element | null => {
        let result: JSX.Element;

        if (!answer || !answer?.isApplicable) {
            return null;
        }

        const showValidationError = Boolean(answer.userLastUpdated || showErrors);

        switch (answer.question.type) {
            case QuestionType.singleSelect: {
                result = (
                    <NewDropdown
                        id={answer.answerWithQuestion.question.shortId}
                        isReadonly={isReadOnly}
                        isRequired={answer.answerWithQuestion.question.tags.includes(QuestionTag.required)}
                        options={answer.question.choices.map(makeOptionFromChoice)}
                        expectedAnswer={(answer.value as string) ?? ""}
                        onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                            answer.setValue(result.response);
                        }}
                        showErrors={showValidationError}
                    />
                );
                break;
            }
            case QuestionType.radio: {
                result = (
                    <SpaceBetween size={"xs"}>
                        <KaleRadiobutton
                            id={answer.answerWithQuestion.question.shortId}
                            isReadonly={isReadOnly}
                            isRequired={answer.answerWithQuestion.question.tags.includes(QuestionTag.required)}
                            choices={answer.question.choices?.map((choice): any => ({
                                label: choice.value,
                                value: choice.value,
                                disabled: !answer.isApplicable,
                            }))}
                            expectedAnswer={answer.value as string}
                            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                                answer.setValue(result.response);
                            }}
                            showErrors={showValidationError}
                        />
                        {answers?.map((childAnswer, index): JSX.Element | null => {
                            if (
                                childAnswer.isApplicable &&
                                childAnswer.question.parentShortId === answer.question.shortId
                            ) {
                                return (
                                    <FormField
                                        key={`child-answer-question-${index}`}
                                        label={childAnswer.question.title}
                                        info={childAnswer.question.subtext}
                                        description={childAnswer.question.content}
                                    >
                                        {renderInput(childAnswer)}
                                    </FormField>
                                );
                            }
                            return null;
                        })}
                    </SpaceBetween>
                );
                break;
            }
            case QuestionType.multiSelect: {
                result = (
                    <MultiSelectWithReadOnly
                        id={answer.answerWithQuestion.question.shortId}
                        selectedOptions={((): SelectProps.Option[] => {
                            const values = (answer.value as string[] | null) ?? [];

                            return values.map(
                                (value: string): SelectProps.Option => ({
                                    value,
                                    label: value,
                                    // May disable choices made by users with options the current user cannot change
                                    disabled: !answer.answerWithQuestion.question.choices
                                        .map((choice): string => choice.value)
                                        .includes(value),
                                })
                            );
                        })()}
                        isReadonly={
                            isReadOnly ||
                            (isTafReviewer &&
                            answer.answerWithQuestion.question.shortId === ReviewAttributeShortId.assignedReviewGroups
                                ? false // Always enable assignedReviewGroups question for TAF reviewers
                                : undefined) // Default read-only handling
                        }
                        placeholder={
                            answer.answerWithQuestion.question.shortId === ReviewAttributeShortId.assignedReviewGroups
                                ? "Select one or more teams"
                                : undefined
                        }
                        options={((): SelectProps.Option[] => {
                            const values = (answer.value as string[] | null) ?? [];
                            const readOnlyChoices = values
                                .filter(
                                    (choice): boolean =>
                                        !answer.answerWithQuestion.question.choices
                                            .map((choice): string => choice.value)
                                            .includes(choice)
                                )
                                .map(
                                    (choice): SelectProps.Option => ({
                                        value: choice,
                                        label: choice,
                                        disabled: true,
                                    })
                                );

                            return answer.answerWithQuestion.question.choices
                                .map(
                                    (choice): SelectProps.Option => ({
                                        value: choice.value,
                                        label: choice.label,
                                    })
                                )
                                .concat(readOnlyChoices);
                        })()}
                        filteringType={"auto"}
                        onChangeCallback={(): void => {}}
                        onChange={(event): void => {
                            answer?.setValue(
                                event.detail.selectedOptions?.map((selectedOption): string => {
                                    return selectedOption.value ?? "";
                                }) ?? []
                            );
                        }}
                    />
                );
                break;
            }
            default: {
                result = (
                    <React.Fragment>
                        {answer.question.shortId === ReviewAttributeShortId.applicationDescription ? (
                            <NewTextArea
                                id={answer.answerWithQuestion.question.shortId}
                                isReadonly={isReadOnly}
                                isRequired={answer.answerWithQuestion.question.tags.includes(QuestionTag.required)}
                                expectedAnswer={(answer.value as string) ?? ""}
                                onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                                    answer.setValue(result.response);
                                }}
                                showErrors={showValidationError}
                            />
                        ) : (
                            <NewTextInput
                                id={answer.answerWithQuestion.question.shortId}
                                isReadonly={isReadOnly}
                                isRequired={answer.answerWithQuestion.question.tags.includes(QuestionTag.required)}
                                expectedAnswer={(answer.value as string) ?? ""}
                                onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                                    answer.setValue(result.response);
                                }}
                                showErrors={showValidationError}
                            />
                        )}
                    </React.Fragment>
                );
            }
        }

        return result;
    };

    return renderInput(answer, answers);
};
