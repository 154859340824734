import { default as React, useCallback, useMemo, useRef, useState } from "react";
import { AppLayoutProps } from "@amzn/awsui-components-react-v3";
import * as awsui from "@amzn/awsui-design-tokens/polaris.js";
import { SurveyResponse, UpdateBulkEditPanelCb } from "src/components/survey/SurveyFormModel";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useWindowDimensions } from "src/components/survey/hooks/useWindowDimensions";
import { BulkEditPanel } from "src/components/survey/BulkEdit/BulkEditPanel";
import { DisplayMessageCb } from "src/components/survey/KaleRoutes";
import styled from "styled-components";
import { ApplicationFromServer } from "src/components/survey/hooks/useFetchApplicationOnMount";

/**
 * We need to disable the legal survey form entirely when the table bulk edit
 * sidebar panel is open. This overlay sits on top of the LegalSurvey but
 * underneath the bulk edit sidebar as well as Kale's header and footer elements.
 */
const ScreenOverlay = styled.div`
    position: fixed;
    z-index: 801;
    height: 100%;
    width: 100%;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: ${awsui.colorBackgroundLayoutMain};
`;

type ToolsProps = Pick<
    AppLayoutProps,
    "tools" | "toolsHide" | "toolsOpen" | "navigationHide" | "toolsWidth" | "onToolsChange"
>;

interface Props {
    application: ApplicationFromServer;
    setApplication: (application: SurveyResponse) => void;
    displayMessage: DisplayMessageCb;
}
/**
 * Custom hook responsible for furnishing the necessary props, callbacks and JSX elements required to
 * fully support the TableBulkEditPanel in the LegalSurvey
 */
export const useBulkEditPanel = (
    props: Props
): {
    bulkEditPanelToolsProps: ToolsProps;
    onUpdateBulkEditPanel: UpdateBulkEditPanelCb;
    onCompleted: (modifiedDataStore: DataStoreResponse) => void;
    screenOverlay: JSX.Element;
} => {
    const { application, setApplication, displayMessage } = props;
    const displayMessageRef = useRef<DisplayMessageCb>(displayMessage);
    const { width } = useWindowDimensions();

    const [isBulkEditPanelOpen, setIsBulkEditPanelOpen] = useState<boolean>(false);
    const [bulkEditDataStore, setBulkEditDataStore] = useState<DataStoreResponse | null>(null);
    const [tableIds, setTableIds] = useState<number[]>([]);

    const onUpdateBulkEditPanel = useCallback((tableIds: number[], dataStore: DataStoreResponse): void => {
        setIsBulkEditPanelOpen(true);
        setBulkEditDataStore(dataStore);
        setTableIds(tableIds);
    }, []);

    const onCloseBulkEditPanel = useCallback((): void => {
        setIsBulkEditPanelOpen(false);
    }, []);

    /**
     * Data stores are copied and then edits are applied in order to avoid direct mutation on source data stores
     */
    const onCompleted = useCallback((modifiedDataStore: DataStoreResponse): void => {
        const { application, setApplication } = depsRef.current;
        if (!application) {
            return;
        }
        const dataStoreIndex = application?.appInfo.review.dataStores.findIndex((dataStore): boolean => {
            return dataStore.id === modifiedDataStore.id;
        });
        const modifiedDataStores = [
            ...application.appInfo.review.dataStores.slice(0, dataStoreIndex),
            modifiedDataStore,
            ...application.appInfo.review.dataStores.slice(dataStoreIndex + 1),
        ];
        setApplication({
            ...application,
            appInfo: {
                ...application.appInfo,
                review: { ...application.appInfo.review, dataStores: modifiedDataStores },
            },
        });
    }, []);

    const deps = {
        application,
        bulkEditDataStore,
        setApplication,
        onCompleted,
        onCloseBulkEditPanel,
        tableIds,
    };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    /**
     * A partial props object defining all tools props pertaining to an <AppLayout>'s tools drawer
     */
    const toolsProps = useMemo((): ToolsProps => {
        const { application, bulkEditDataStore, onCompleted, onCloseBulkEditPanel, tableIds } = depsRef.current;
        return {
            toolsHide: !isBulkEditPanelOpen,
            navigationHide: true,
            toolsOpen: isBulkEditPanelOpen,
            toolsWidth: width * 0.5,
            tools: (
                <BulkEditPanel
                    appName={application?.appInfo.applicationName ?? ""}
                    tableIds={tableIds}
                    dataStore={bulkEditDataStore}
                    onCompletedCb={onCompleted}
                    onCloseCb={onCloseBulkEditPanel}
                    displayMessage={displayMessageRef.current}
                />
            ),
            onToolsChange: (): void => {
                if (isBulkEditPanelOpen) {
                    setIsBulkEditPanelOpen(false);
                }
            },
        };
    }, [isBulkEditPanelOpen, width]);

    const screenOverlay = useMemo((): JSX.Element => <ScreenOverlay />, []);

    return { bulkEditPanelToolsProps: toolsProps, onUpdateBulkEditPanel, onCompleted, screenOverlay };
};
