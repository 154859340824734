import { useLocation } from "react-router-dom";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { AnvilProps } from "src/components/survey/LegalSurveyPage";

interface LocationState {
    anvilProps?: AnvilProps;
    dataStore?: DataStoreResponse;
}

export const useLocationState = (): LocationState => {
    const { state } = useLocation<LocationState>();
    return state || {};
};
