import { ApplicationOverviewState } from "src/components/survey/ApplicationOverview/state/model";
import { ActionType, ApplicationOverviewAction } from "src/components/survey/ApplicationOverview/state/actions";
import { makeInitialState } from "src/components/survey/ApplicationOverview/state/reducer/utils";

const INITIAL_STATE = makeInitialState();
const reducer = (
    prevState: ApplicationOverviewState = INITIAL_STATE,
    action: ApplicationOverviewAction
): ApplicationOverviewState => {
    switch (action.type) {
        case ActionType.SummaryCardRequestStarted: {
            const { cardName } = action.payload;
            return {
                ...prevState,
                summaryCards: {
                    ...prevState.summaryCards,
                    byCardName: {
                        ...prevState.summaryCards.byCardName,
                        [cardName]: {
                            ...prevState.summaryCards.byCardName[cardName],
                            ui: {
                                ...prevState.summaryCards.byCardName[cardName].ui,
                                isLoading: true,
                            },
                        },
                    },
                },
            };
        }
        case ActionType.SummaryCardRequestFailed: {
            const { cardName, message } = action.payload;
            return {
                ...prevState,
                summaryCards: {
                    ...prevState.summaryCards,
                    byCardName: {
                        ...prevState.summaryCards.byCardName,
                        [cardName]: {
                            ...prevState.summaryCards.byCardName[cardName],
                            ui: {
                                ...prevState.summaryCards.byCardName[cardName].ui,
                                isLoading: false,
                                fetchError: message,
                            },
                        },
                    },
                },
            };
        }
        case ActionType.SummaryCardRequestSucceeded: {
            const { cardName, response } = action.payload;
            return {
                ...prevState,
                summaryCards: {
                    ...prevState.summaryCards,
                    byCardName: {
                        ...prevState.summaryCards.byCardName,
                        [cardName]: {
                            ...prevState.summaryCards.byCardName[cardName],
                            ui: {
                                ...prevState.summaryCards.byCardName[cardName].ui,
                                isLoading: false,
                                fetchError: "",
                            },
                            response,
                        },
                    },
                },
            };
        }
        default: {
            return prevState;
        }
    }
};

export default reducer;
