/* istanbul ignore file */
// Skipping this file from code coverage of unit-test (jest).

import stages from "shared/stages.json";
import { FeatureToggles } from "shared/types/FeatureToggles";
import { StageConfig, StageConfigs } from "shared/types/StageConfig";
export * from "shared/types/StageConfig";

const DASStageConfigs: StageConfigs = {
    ...stages,
};

interface Settings {
    stageName: string;
}
const DEFAULT_SETTINGS: Settings = { stageName: "dev" };
let cachedSettingsEnvVar: Settings | undefined;

function fetchSettingsEnvVar(): Promise<Settings> {
    if (cachedSettingsEnvVar) {
        return Promise.resolve(cachedSettingsEnvVar);
    }

    return (
        fetch("/settings.json", { method: "GET" })
            .then((res: Response): Promise<Settings> => {
                if (res.status !== 200) {
                    return Promise.reject("settings.json not found");
                }
                return res.json();
            })
            // Cache the result so we don't have to re fetch it later. The value should never change
            .then(
                (settings: Settings): Settings => {
                    cachedSettingsEnvVar = settings;
                    return cachedSettingsEnvVar;
                },
                (error: any): Settings => {
                    console.info("Could not fetch /settings.json:", error);
                    cachedSettingsEnvVar = DEFAULT_SETTINGS;
                    return cachedSettingsEnvVar;
                }
            )
    );
}

export async function setConfigFeatureToggles(features: FeatureToggles, config: StageConfig): Promise<StageConfig> {
    config.features = {
        ...features,
    };
    return config;
}

export function getStageConfig(): Promise<StageConfig> {
    return fetchSettingsEnvVar().then((settings: Settings): StageConfig => {
        return DASStageConfigs[settings.stageName];
    });
}
