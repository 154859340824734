import { Container, Header } from "@amzn/awsui-components-react-v3";
import { DataStoreAnswer, DataStoreQuestion, HookAnswer, QuestionTag } from "src/answers_legacy";
import React from "react";
import { DropdownWithTagsFunctionalReadonly, DropdownWithTags } from "src/components/fields/dropdown/DropdownWithTags";
import { TEST_IDS } from "shared/survey";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import { useIsExclusive } from "src/components/survey/hooks/useIsExclusive";

const {
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: {
            DYNAMIC_QUESTION_STEP: { MULTISELECT_QUESTION },
        },
    },
} = TEST_IDS;

/**
 * Custom Component representing the DataStore MultiSelectQuestion. Wraps a <DropdownWithTags> component and adds
 * custom behaviors involving any QuestionChoice objects configures with the `isExclusive` property as well custom
 * behaviors driven by the inclusion of the QuestionTag.excludeOtherOption tag.
 */
const MultiSelectQuestion = ({ item }: { item: HookAnswer<DataStoreQuestion, DataStoreAnswer> }): JSX.Element => {
    const { shortId, tags } = item.question;

    const { onChangeCallBack, options, selectedOptions } = useIsExclusive(item);

    const allowOther = !tags.includes(QuestionTag.excludeOtherOption);

    return (
        <div data-testid={MULTISELECT_QUESTION}>
            <DropdownWithTagsFunctionalReadonly
                id={shortId}
                filteringType={"auto"}
                isRequired={tags.includes(QuestionTag.required)}
                options={options}
                expectedAnswer={selectedOptions}
                onChangeCallback={onChangeCallBack}
                allowOther={allowOther}
            />
        </div>
    );
};

/**
 * Custom Component representing the MultiSelectQuestion used in the campaign page. Wraps a <DropdownWithTagsNormal>
 * component and adds custom behaviors involving any QuestionChoice objects configures with the
 * `isExclusive` property as well custom behaviors driven by the inclusion of the QuestionTag.excludeOtherOption tag.
 */
export const MultiSelectQuestionForCampaign = ({
    item,
    isReadonly,
}: {
    item: HookAnswer<DataStoreQuestion, DataStoreAnswer>;
    isReadonly: boolean;
}): JSX.Element => {
    const { shortId, tags } = item.question;

    const { onChangeCallBack, options, selectedOptions } = useIsExclusive(item);

    return (
        <Container
            key={item.question.shortId}
            header={
                <Header description={KaleHTMLParser(item.question.subtext)}>
                    {KaleHTMLParser(item.question.content)}
                </Header>
            }
        >
            <div data-testid={MULTISELECT_QUESTION}>
                <DropdownWithTags
                    id={shortId}
                    isReadonly={isReadonly}
                    filteringType={"auto"}
                    isRequired={tags.includes(QuestionTag.required)}
                    options={options}
                    expectedAnswer={selectedOptions}
                    onChangeCallback={onChangeCallBack}
                    allowOther={false}
                />
            </div>
        </Container>
    );
};

export default MultiSelectQuestion;
