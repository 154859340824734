import { Box, Icon } from "@amzn/awsui-components-react-v3";
import { ORGANIZATION_GUIDANCE_INSTRUCTION } from "src/components/survey/LegalSurveyActionButtons";
import { default as React } from "react";

export const PreSubmitNotice = (): JSX.Element => {
    return (
        <div>
            <Box variant="p" margin={{ top: "xl" }}>
                <b>
                    <Icon name="status-warning" size="normal" variant="error" /> IMPORTANT:
                </b>{" "}
                {ORGANIZATION_GUIDANCE_INSTRUCTION} Once you select &quot;Submit for Privacy Review&quot;, we will
                automatically send the responses you have provided so far to your organization&apos;s Reviewer Group for
                a privacy assessment.
            </Box>
            <Box variant="p" margin={{ bottom: "xxl" }}>
                Next, you may need to go through the Tax, Accounting, and Consumer Trust & Partner Support (CTPS) review
                process. If, based on your responses, we detect that your application may have data relevant to these
                teams, you will be asked to answer additional questions on the next page. Please click on &quot;Submit
                for Privacy Review&quot; to continue
            </Box>
        </div>
    );
};
