import { AnswerContentKey, ContentKeyMap, QuestionSetType, QuestionType } from "./constants";
import {
    GroupedQuestionOptions,
    QuestionChoice,
    QuestionOption,
    QuestionOptionGroup,
} from "src/services/dynamic-questions/types";

export const getTableQuestionSetType = (isAndes: boolean): QuestionSetType =>
    isAndes ? QuestionSetType.andesTable : QuestionSetType.table;

export const getFieldQuestionSetType = (isAndes: boolean): QuestionSetType =>
    isAndes ? QuestionSetType.andesField : QuestionSetType.field;

export const mapQuestionTypeToAnswerContentKey = (type: QuestionType): AnswerContentKey => ContentKeyMap[type];

/**
 * Shared method for converting a QuestionChoice from the Server into a ui specific QuestionOption shape that is
 * compatible with Polaris 3 Multiselect component. Additionally, the QuestionOption shapes allows us to store other
 * important properties from the QuestionChoice type on the Option so that the UI can continue to process those
 * properties in later stages of the dynamic questions rendering pipeline.
 * @param QuestionChoice - the QuestionChoice object from which to make the corresponding QuestionOption
 * @return the new QuestionOption created from the QuestionChoice input parameter.
 */
export const makeOptionFromChoice = ({
    value: optionValue,
    description,
    label,
    ...serverAttributes
}: QuestionChoice): QuestionOption => ({
    // Superset of Polaris v3 option shape.
    value: optionValue,
    // re-use the choice value as the option label if no choice label was set
    label: label ?? optionValue,
    description,
    ...serverAttributes,
});

/**
 * Process a list of QuestionOptions and compile them into a list of stand alone QuestionOptions intermixed with
 * QuestionOptionGroups.
 * Options are put into groups based on the value of their optionGroup property. Options that have no optionGroup
 * property will still be present in the new list as stand alone options
 * @param options - the flat list of QuestionOptions that we want to assemble into groups.
 * @return a heterogeneous list of stand alone QuestionOptions intermixed with QuestionOptionGroups
 */
export const groupQuestionOptions = (options: QuestionOption[]): GroupedQuestionOptions => {
    const groupedQuestionOptions: GroupedQuestionOptions = [];
    const groupsByGroupName: { [optionGroupName: string]: QuestionOptionGroup } = {};

    options.forEach((option): void => {
        const { optionGroup: optionGroupName } = option;

        if (optionGroupName) {
            // The option is registered to an option group.

            // If we haven't yet already created that specific option group, do so now.
            if (!groupsByGroupName[optionGroupName]) {
                const newOptionGroup: QuestionOptionGroup = {
                    label: optionGroupName,
                    options: [],
                };
                // Append the Option Group into the groups-map and the list of frontEndChoices
                groupsByGroupName[optionGroupName] = newOptionGroup;
                groupedQuestionOptions.push(newOptionGroup);
            }

            // Append the choice to the list of options in the option group
            const optionGroup = groupsByGroupName[optionGroupName];
            optionGroup.options.push(option);
        } else {
            // The choice is not registered to an option group, simply append it to the list and move on
            groupedQuestionOptions.push(option);
        }
    });

    return groupedQuestionOptions;
};
