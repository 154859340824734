import React from "react";
import { Button, SpaceBetween } from "@amzn/awsui-components-react-v3";

interface WizardActionsProps {
    hideSubmitButton?: boolean;
    primaryButtonText: string;
    previousButtonText: string;
    onPreviousClick: () => void;
    onPrimaryClick: () => void;
    showPrevious: boolean;
    isPrimaryLoading: boolean;
    isLastStep: boolean;
}

const WizardActions = (props: WizardActionsProps): JSX.Element => {
    const nextButton = !props.isLastStep;
    const submitButton = props.isLastStep && !props.hideSubmitButton;
    const primaryDataTestId = nextButton ? "inline-wizard-next-button" : "inline-wizard-submit-button";

    return (
        <SpaceBetween direction="horizontal" size="xs">
            {props.showPrevious && (
                <Button onClick={props.onPreviousClick} formAction="none">
                    {props.previousButtonText}
                </Button>
            )}
            {nextButton || submitButton ? (
                <Button
                    data-testid={primaryDataTestId}
                    variant="primary"
                    formAction="none"
                    onClick={props.onPrimaryClick}
                    loading={props.isPrimaryLoading}
                >
                    {props.primaryButtonText}
                </Button>
            ) : null}
        </SpaceBetween>
    );
};

export default WizardActions;
