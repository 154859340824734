import { PDWStepToIndexMap, PersonalDataWizardStep } from "src/components/survey/PersonalDataWizard/steps";
import {
    LINEAGE_FINDINGS_TITLE,
    PERSONAL_DATA_STATEMENT_TITLE,
    renderLineageLearnMore,
    SUMMARY_OF_FINDINGS_TITLE,
} from "src/components/survey/PersonalDataWizard/content";
import { Box, Button, Header, Icon, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import {
    AppInfoResponse,
    ApplicationStatus,
    HasPersonalDataResponse,
    selectLegalApprovalStatus,
} from "src/components/survey/SurveyFormModel";
import { Can, UserAction } from "src/permissions";
import Model from "src/components/constants/Constants";
import { OptionModel } from "src/components/constants/SurveyConstants";
import styled from "styled-components";
import { WizardProps } from "src/components/InlineWizard/interfaces";
import { PDCFinding } from "src/services/NodeKaleApplicationService";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import { BindleConnection } from "src/services/external-lineage/types";
import { AnalyzerSummary } from "src/components/survey/PersonalDataWizard/steps/summary/AnalyzerSummary";

const { SUMMARY_STEP } = TEST_IDS.WIZARD;

const ReviewSection = styled.div`
    border-bottom: var(--border-divider-list-width, 1px) solid var(--color-border-layout, #d5dbdb);
`;

interface UseSummaryStepProps {
    lineageFindings: BindleConnection[];
    pdcFindings: PDCFinding[];
    isPDCHighConfidence: boolean;
    response: AppInfoResponse;
    isPDWComplete: boolean;
    setPdwStepIndex: Dispatch<SetStateAction<number>>;
}

export const useSummaryStep = ({
    lineageFindings,
    pdcFindings,
    isPDCHighConfidence,
    response,
    isPDWComplete,
    setPdwStepIndex,
}: UseSummaryStepProps): PersonalDataWizardStep => {
    const legalStatus = selectLegalApprovalStatus(response);
    const hasDetectedPersonalData = useMemo(
        (): boolean =>
            lineageFindings.length > 0 ||
            pdcFindings.length > 0 ||
            response.review.hasPersonalData === HasPersonalDataResponse.Process ||
            response.review.hasPersonalData === HasPersonalDataResponse.Store,
        [lineageFindings.length, pdcFindings.length, response.review.hasPersonalData]
    );

    const step: WizardProps.Step = {
        title: SUMMARY_OF_FINDINGS_TITLE,
        content: (
            <SpaceBetween direction="vertical" size="xxl">
                <div>
                    <Header variant="h2">
                        {hasDetectedPersonalData ? (
                            <React.Fragment>
                                {/* eslint-disable-next-line max-len */}
                                <Icon name="status-warning" size="normal" variant="error" />{" "}
                                <span data-testid={SUMMARY_STEP.RESULT}>Personal data was found</span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Icon name="status-positive" size="normal" variant="success" />{" "}
                                <span data-testid={SUMMARY_STEP.RESULT}>No personal data was found</span>
                            </React.Fragment>
                        )}
                    </Header>
                    <div>
                        Based on your answers, and our findings, your application{" "}
                        <b>{hasDetectedPersonalData ? "does" : "does not"}</b> contain personal data.{" "}
                        {!isPDWComplete && (
                            <span>
                                Please confirm your answers below, and click &quot;Confirm and continue&quot; to
                                complete your privacy review, providing at a minimum datastore-level attestation.
                            </span>
                        )}
                    </div>
                </div>
                <ReviewSection />
                <div>
                    <Header variant="h3">{LINEAGE_FINDINGS_TITLE}</Header>
                    <SpaceBetween direction="vertical" size="s">
                        <Box variant="small">
                            These data lineage results show the systems, tools, or resources connected to yours, that
                            have attested to storing personal data in Kale. {renderLineageLearnMore()}
                        </Box>

                        <AnalyzerSummary
                            alertType={lineageFindings.length > 0 ? "warning" : "info"}
                            analyzerName={"lineage"}
                            numFindings={lineageFindings.length}
                            data-testid={SUMMARY_STEP.ANALYZER_SUMMARIES.LINEAGE_FINDINGS}
                        />
                    </SpaceBetween>
                </div>
                <ReviewSection />
                <div>
                    <Header variant="h3">PDC findings</Header>
                    <SpaceBetween direction="vertical" size="s">
                        <Box variant="small">
                            These PDC results show the systems, tools, or resources connected to yours, that have
                            attested to storing personal data in Kale.
                        </Box>

                        <AnalyzerSummary
                            alertType={isPDCHighConfidence ? "warning" : "info"}
                            analyzerName={"PDC"}
                            numFindings={pdcFindings.length}
                            data-testid={SUMMARY_STEP.ANALYZER_SUMMARIES.PDC_FINDINGS}
                        />
                    </SpaceBetween>
                </div>
                <ReviewSection />
                <div>
                    <Header
                        variant="h3"
                        actions={
                            legalStatus.status === ApplicationStatus.inProgress && (
                                <Can
                                    perform={UserAction.editApplication}
                                    yes={(): JSX.Element => (
                                        <Button
                                            data-testid={SUMMARY_STEP.PERSONAL_DATA_QUESTION_EDIT_BUTTON}
                                            variant="normal"
                                            onClick={(): void => {
                                                setPdwStepIndex(PDWStepToIndexMap.personalDataQuestion);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                />
                            )
                        }
                    >
                        Does your application process any personal data?
                    </Header>
                    <Box fontWeight="bold">{PERSONAL_DATA_STATEMENT_TITLE}</Box>
                    <div>
                        {Model.getData("hasPersonalData").options.find(
                            (option: OptionModel): boolean => option.name === response.review.hasPersonalData
                        )?.displayName ?? "Not answered"}
                    </div>
                </div>
            </SpaceBetween>
        ),
    };

    return { isLoading: false, step, error: "" };
};
