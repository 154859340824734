import { Link } from "@amzn/awsui-components-react-v3";
import React from "react";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";

export function respondentAlias(model: Model): Pair {
    const respondentAlias = model.application.review.respondentAlias;
    return {
        label: DisplayLabels.RespondentAlias,
        value: (
            <Link external={true} href={`https://phonetool.amazon.com/users/${respondentAlias}`}>
                {respondentAlias}
            </Link>
        ),
    };
}
