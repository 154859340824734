import { Box, Multiselect, MultiselectProps, NonCancelableCustomEvent, Spinner } from "@amzn/awsui-components-react-v3";
import React, { useEffect, useCallback, useRef } from "react";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import Model from "src/components/constants/Constants";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";
import { DescriptionWithInfoLink } from "src/components/survey/ApplicationPermissions/DescriptionWithInfoLink";
import { KaleFormField } from "src/components/fields/KaleFormField";
import { TEST_IDS as WIZARD_TEST_IDS } from "shared/survey/accessControlWizrad";

export const TEST_IDS = {
    LOADING_SPINNER: "aaa-ids-loading-spinner",
};

export type AAAIdsInputProps = FunctionalSurveyItemProps<string[]> & {
    readonly options: MultiselectProps.Option[];
    readonly status: MultiselectProps["statusType"];
    readonly removedBindleNames: string[];
};

const AAAIdsInput = (props: AAAIdsInputProps): JSX.Element => {
    const {
        id,
        options,
        status,
        expectedAnswer = [],
        isFormInReadonlyMode = false,
        isLoading = false,
        onChangeCallback,
        onRemoveCallback,
        removedBindleNames,
    } = props;
    const data = Model.getData(id);

    const validOptionsFromResponse: MultiselectProps.Option[] = options.filter(
        (option: MultiselectProps.Option): boolean => {
            return option.label ? expectedAnswer?.includes(option.label) : false;
        }
    );
    const invalidOptionsFromResponse: string[] = expectedAnswer.filter((answer): boolean => {
        return !validOptionsFromResponse.find((option): boolean => answer === option.label);
    });

    const selectedOptions: MultiselectProps.Option[] = [
        ...validOptionsFromResponse,
        ...invalidOptionsFromResponse.map((invalidAnswer): MultiselectProps.Option => {
            return { value: invalidAnswer, label: invalidAnswer };
        }),
    ];
    const dependenciesRef = useRef({ onChangeCallback, selectedOptions, id });
    dependenciesRef.current = { onChangeCallback, selectedOptions, id };
    useEffect((): void => {
        const { onChangeCallback, selectedOptions, id } = dependenciesRef.current;
        // Removing any selected AAA ids if user removed the corresponding control/related bindle
        const newSelectedOptions = selectedOptions.filter(
            (selectedOption): boolean => !removedBindleNames.includes(selectedOption.description ?? "")
        );
        const isOptionRemoved = newSelectedOptions.length !== selectedOptions.length;
        if (isOptionRemoved) {
            onChangeCallback({
                id,
                response: newSelectedOptions.map(
                    (selectedOption: MultiselectProps.Option): string => selectedOption.value ?? ""
                ),
            });
        }
    }, [removedBindleNames]);

    useEffect((): (() => void) => {
        return (): void => {
            onRemoveCallback?.call(null, props.id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = useCallback(
        (event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>): void => {
            onChangeCallback({
                id: id,
                response: event.detail.selectedOptions.map(
                    (selectedOption: MultiselectProps.Option): string => selectedOption.value ?? ""
                ),
            });
        },
        [id, onChangeCallback]
    );

    const label = data.name;
    const infoLink = (
        <DescriptionWithInfoLink
            displayText={applyTemplateToText(data.question, data.templateValues)}
            content={
                <div>
                    <Box variant="p">If no AAA Id(s) are present, ensure all of the following are correct.</Box>
                    <ul>
                        <li>You selected a control bindle or related bindles in the dropdowns above, and</li>
                        <li>You are a member of a team that owns a bindle, and</li>
                        <li>You are using a supported browser (FireFox and Chrome), and</li>
                        <li>Your browser is not blocking third-party cookies and site data.</li>
                    </ul>
                </div>
            }
        ></DescriptionWithInfoLink>
    );

    return (
        <KaleFormField description={infoLink} label={label} stretch={false}>
            {/* polaris3 multiselect will only show loading message inside the dropdown */}
            {/* and users can still operate the dropdown when it is loading, */}
            {/* so we disable the multiselect and show extra spinner during loading */}
            <Multiselect
                id={id}
                data-testid={WIZARD_TEST_IDS.WIZARD.STEPS.AAA_ID.ROOT}
                statusType={status}
                errorText="Error fetching AAA ids."
                placeholder="Choose options"
                options={options}
                selectedOptions={selectedOptions}
                disabled={isFormInReadonlyMode || isLoading}
                loadingText={isLoading ? "loading" : ""}
                onChange={handleOnChange}
                filteringType={"auto"}
            />
            {isLoading && (
                <Box variant="p" data-testid={TEST_IDS.LOADING_SPINNER}>
                    (Bindles update detected. Updating AAA ids
                    <Spinner />)
                </Box>
            )}
        </KaleFormField>
    );
};

export const AAAIdsInputWithFunctionalReadOnly = withFunctionalReadonly(AAAIdsInput);
