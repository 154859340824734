import React, { useContext } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { Checkbox, NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3";
import { CheckboxProps } from "@amzn/awsui-components-react-v3/polaris/checkbox/interfaces";
import { FieldCellState } from "src/components/TableDetails/FieldsTable/types";

export const CheckboxQuestion = (props: FieldCellState): JSX.Element => {
    const { value: originalValue, setValue, isApplicable } = props;
    const value = originalValue as string;
    const checked = Boolean(value);
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly || !isApplicable;
    return (
        <Checkbox
            disabled={isReadonly}
            checked={checked}
            onChange={(e: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>): void =>
                // store true value as a non empty string, false value as an empty string
                setValue(e.detail.checked ? "true" : "")
            }
        />
    );
};
