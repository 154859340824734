import styled from "styled-components";
import { COMMENT_HEADER_PADDING_BOX_HEIGHT } from "src/components/CommentsView/constants";
import { Icon } from "@amzn/awsui-components-react-v3";

export const ChevronIcon = styled(Icon)``;

export const HeaderOverlayButton = styled.div`
    /* 
     Style this transparent element such that it will completely overlay the 
     first Comment Header in a Comment Thread. 
     This overlay element will own the behavior for expanding/collapsing the 
     comment thread when clicked, but to the user it should appear that the 
     entire comment header of the first comment in a thread is the safe click 
     target to expand or collapse the comment thread. 
    */
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: ${COMMENT_HEADER_PADDING_BOX_HEIGHT}px;
    z-index: 100;

    // It should indicate that it is a click target
    cursor: pointer;

    // Fill the adjacent chevron svg icon in the first Comment Header of a
    // comment thread when this overlay button is hovered. The Chevron icon
    // will exist in a sibling element subtree, so the CSS selector required to
    // target it and style the fill is more complex than usual. These
    // hover fill styles are especially important for communicating to a user
    // that they are hovering a safe click target to collapse the thread while
    // the thread is expanded. The current ux design docs don't call for any
    // explicit visual boundaries separating the comment header from the comment
    // body, so we want to fill the chevron icon as soon as the user is hovering
    // this header overlay button, which will will be perfectly overlayed on top
    // of the first comment's header region
    &:hover + * ${ChevronIcon} svg {
        fill: currentColor !important;
        opacity: 0.65 !important;
    }
`;
