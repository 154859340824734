import { CommentSlice, CommentThreadGroupSlice, CommentThreadSlice } from "src/components/CommentsView/state/reducer";
import { CommentThread, CommentThreadGroup, FrontEndComment } from "src/components/CommentsView/state/model";
import { createSelector } from "reselect";
import { UserProps } from "src/components/KaleContext";
import { KaleRootState } from "src/redux/store";

export const selectUser = (state: KaleRootState): UserProps => state.commentsView.user;
export const selectApplicationName = (state: KaleRootState): string => state.commentsView.applicationName;
export const selectCommentSlice = (state: KaleRootState): CommentSlice => state.commentsView.comments;
export const selectCommentThreadSlice = (state: KaleRootState): CommentThreadSlice => state.commentsView.commentThreads;
export const selectCommentThreadGroupSlice = (state: KaleRootState): CommentThreadGroupSlice =>
    state.commentsView.commentThreadGroups;
export const selectIsCommentsViewSidebarOpen = (state: KaleRootState): boolean =>
    state.commentsView.ui.isCommentsSidebarOpen;

export const selectGroupsDisplayOrder = createSelector(
    [selectCommentThreadGroupSlice],
    (groups): CommentThreadGroupSlice["displayOrder"] => groups.displayOrder
);

export const selectCommentThreadGroupByGroupHeader = createSelector(
    [selectCommentThreadGroupSlice, (state: KaleRootState, groupHeader: string): string => groupHeader],
    (groupSlice, groupHeader): CommentThreadGroup => groupSlice.byGroupHeader[groupHeader]
);

export const selectCommentThreadByHeadCommentId = createSelector(
    [selectCommentThreadSlice, (state: KaleRootState, headCommentId: number): number => headCommentId],
    (threadSlice, headCommentId): CommentThread => threadSlice.byHeadCommentId[headCommentId]
);

export const selectCommentByFrontEndId = createSelector(
    [selectCommentSlice, (state: KaleRootState, frontEndId: number): number => frontEndId],
    (commentSlice, frontEndId): FrontEndComment => commentSlice.byFrontEndId[frontEndId]
);
