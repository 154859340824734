import React from "react";
import { AnswerBase, AnswerWithQuestion, QuestionBase } from "./models/common";

export const UPDATE_ANSWERS = "updateAnswers";
export const RESET_ANSWERS = "resetAnswers";
export const REMOVE_ANSWERS = "removeAnswers";
export const ADD_ANSWERS = "addAnswers";
export const RESET_AND_ADD_ANSWERS = "resetAndAddAnswers";
export const ADD_OPTIONS = "addOptions";
export const RESET_OPTIONS = "resetOptions";
export const RESET_CHANGE_DETECTION = "resetChangeDetection";

export interface CreateAnswersActionCallback<QType, AType> {
    (answers: AType[], questions: QType[], lookupId?: number | string, parentLookupId?: number | string): void;
}

export interface UpdateAnswersActionCallback<QType, AType> {
    (answers: Partial<AnswerWithQuestion<QType, AType>>[]): void;
}

export interface ResetAnswersActionCallback {
    (): void;
}

export interface CreateOptionsCallback<QType, AType> {
    (options: StateOptions<QType, AType>): void;
}
export interface ResetOptionsCallback {
    (): void;
}
export interface ResetChangeDetectionCallback {
    (): void;
}

export interface AnswerActions<QType, AType> {
    [ADD_ANSWERS]: CreateAnswersActionCallback<QType, AType>;
    [UPDATE_ANSWERS]: UpdateAnswersActionCallback<QType, AType>;
    [REMOVE_ANSWERS]: UpdateAnswersActionCallback<QType, AType>;
    [RESET_ANSWERS]: ResetAnswersActionCallback;
    [RESET_AND_ADD_ANSWERS]: CreateAnswersActionCallback<QType, AType>;
    [ADD_OPTIONS]: CreateOptionsCallback<QType, AType>;
    [RESET_OPTIONS]: ResetOptionsCallback;
    [RESET_CHANGE_DETECTION]: ResetChangeDetectionCallback;
}

export interface UpdateAnswersAction {
    id?: number;
    uuid?: string;
    type: string;
    answers: Partial<AnswerWithQuestion>[];
    stateKey: StateKey;
}

export interface CreateAnswersAction<QType = QuestionBase, AType = AnswerBase> {
    id?: number;
    uuid?: string;
    parentRecordId?: number;
    parentRecordUuid?: string;
    type: string;
    answers: AType[];
    questions: QType[];
    stateKey: StateKey;
}

export interface ResetAnswersAction {
    type: string;
    stateKey: StateKey;
}

export interface AddOptionsAction<QType, AType> {
    type: string;
    options: StateOptions<QType, AType>;
    stateKey: StateKey;
}

export interface ResetOptionsAction {
    type: string;
    stateKey: StateKey;
}

export interface ResetChangeDetectionAction {
    type: string;
    stateKey: StateKey;
}

export enum StateKey {
    review = "review",
    dataStores = "dataStores",
    kaleTable = "kaleTable",
    kaleTableFields = "kaleTableFields",
    bulkEditKaleTable = "bulkEditKaleTable",
    bulkEditKaleTableFields = "bulkEditKaleTableFields",
    kaleTableFieldSidebar = "kaleTableFieldSidebar",
}

export enum StateOptionsKey {
    review = "reviewOptions",
    dataStores = "dataStoresOptions",
    kaleTable = "kaleTableOptions",
    kaleTableFields = "kaleTableFieldsOptions",
    bulkEditKaleTable = "kaleTableOptions",
    bulkEditKaleTableFields = "kaleTableFieldsOptions",
    kaleTableFieldSidebar = "kaleTableFieldSidebarOptions",
}

function makeIdAndUuid(lookupId: number | string | undefined): { id: number | undefined; uuid: string | undefined } {
    const id = typeof lookupId === "number" ? lookupId : undefined;
    const uuid = typeof lookupId === "string" ? lookupId : undefined;

    return {
        id,
        uuid,
    };
}

export interface AnswersActionsCallback<QType, AType> {
    (stateKey: StateKey): AnswerActions<QType, AType>;
}

interface ConstraintResponse<QType, AType> {
    hasParentConstraint: boolean;
    isParentConstraintMet: boolean;
    parentQuestion: QType | undefined;
    parentAnswer: AType | undefined;
    currentAnswer: AType;
    answers: AType[];
    question: QType;
}

interface OptionsConstraintHandler<QType, AType> {
    (response: ConstraintResponse<QType, AType>): [boolean, boolean];
}

export interface StateOptions<QType, AType> {
    constraintHandler: OptionsConstraintHandler<QType, AType>;
}

export const useAnswersActions =
    <QType extends QuestionBase, AType extends AnswerBase>(
        dispatch: React.Dispatch<
            | CreateAnswersAction<QType, AType>
            | UpdateAnswersAction
            | ResetAnswersAction
            | AddOptionsAction<QType, AType>
            | ResetOptionsAction
        >
    ): AnswersActionsCallback<QType, AType> =>
    (stateKey: StateKey): AnswerActions<QType, AType> => ({
        [ADD_ANSWERS]: (
            answers: AType[],
            questions: QType[],
            lookupId?: number | string,
            parentLookupId?: number | string
        ): void => {
            const { id, uuid } = makeIdAndUuid(lookupId);
            const { id: parentRecordId, uuid: parentRecordUuid } = makeIdAndUuid(parentLookupId);

            dispatch({
                type: ADD_ANSWERS,
                id,
                uuid,
                parentRecordId,
                parentRecordUuid,
                answers,
                questions,
                stateKey,
            });
        },
        [UPDATE_ANSWERS]: <T>(answers: Partial<T>[]): void => {
            dispatch({
                type: UPDATE_ANSWERS,
                answers,
                stateKey,
            });
        },
        [REMOVE_ANSWERS]: <T>(answers: Partial<T>[]): void => {
            dispatch({
                type: REMOVE_ANSWERS,
                answers,
                stateKey,
            });
        },
        [RESET_ANSWERS]: (): void => {
            dispatch({
                type: RESET_ANSWERS,
                stateKey,
            });
        },
        [RESET_AND_ADD_ANSWERS]: (
            answers: AType[],
            questions: QType[],
            lookupId?: number | string,
            parentLookupId?: number | string
        ): void => {
            const { id, uuid } = makeIdAndUuid(lookupId);
            const { id: parentRecordId, uuid: parentRecordUuid } = makeIdAndUuid(parentLookupId);

            dispatch({
                type: RESET_AND_ADD_ANSWERS,
                id,
                uuid,
                parentRecordId,
                parentRecordUuid,
                answers,
                questions,
                stateKey,
            });
        },
        [ADD_OPTIONS]: (options: StateOptions<QType, AType>): void => {
            dispatch({
                type: ADD_OPTIONS,
                options,
                stateKey,
            });
        },
        [RESET_OPTIONS]: (): void => {
            dispatch({
                type: RESET_OPTIONS,
                stateKey,
            });
        },
        [RESET_CHANGE_DETECTION]: (): void => {
            dispatch({
                type: RESET_CHANGE_DETECTION,
                stateKey,
            });
        },
    });
