import { default as React } from "react";
import { Box, Alert } from "@amzn/awsui-components-react-v3";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";

interface RecallNoticeProps {
    privacyStatus: ApplicationStatus;
}
export const RecallNotice = React.memo(({ privacyStatus }: RecallNoticeProps): JSX.Element => {
    return (
        <div>
            {privacyStatus === ApplicationStatus.approved && (
                <Alert type={"warning"} header={"Recall Notice"}>
                    If your Privacy, Accounting, and/or CTPS Reviews are Approved for this application, this Recall will
                    revert those back to <b> In Progress </b> and will require resubmission and re-review.
                </Alert>
            )}
            <Box variant="p" margin={{ top: "xs" }}>
                <b>Note:</b> If you have submitted additional information to Tax, Accounting, and Consumer Trust &amp;
                Partner Support (CTPS) for their review cycle, recalling your application will stop the Tax, Accounting,
                and CTPS review until you resubmit your Kale Application and Financial Review again. Recalling your
                application does not delete any data on either page of the Kale Application.
            </Box>
            <Box variant="p">The recall process may take 1-2 minutes, so please be patient.</Box>
        </div>
    );
});
