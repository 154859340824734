import React, { useContext, useState } from "react";
import {
    ApplicationStatus,
    selectLegalApprovalStatus,
    SurveyContext,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import { ColumnLayout, ExpandableSection, Icon, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { AccessControlPair } from "src/components/survey/AccessControl/section/util";
import { Pair } from "src/components/survey/ApplicationIdentity/model/utils";
import { relatedBindle } from "src/components/survey/AccessControl/section/pairs/RelatedBindle";
import { aaaIds } from "src/components/survey/AccessControl/section/pairs/AAAIds";
import { anvilId } from "src/components/survey/AccessControl/section/pairs/AnvilId";
import { taskId } from "src/components/survey/AccessControl/section/pairs/TaskId";
import { appOwner } from "src/components/survey/AccessControl/section/pairs/AppOwner";
import { explanation } from "src/components/survey/AccessControl/section/pairs/Explanation";
import { Button } from "@amzn/awsui-components-react-v3/polaris";
import { KeyValuePair } from "src/components/survey/ApplicationIdentity/KeyValuePair";
import { kaleUrls } from "src/util/Urls";
import KaleContext from "src/components/KaleContext";
import { TEST_IDS } from "shared/survey/accessControlWizrad";

export interface AccessControlSectionProps {
    application: SurveyResponse;
    shouldShowErrors: boolean;
}
export const AccessControlSection = ({ application, shouldShowErrors }: AccessControlSectionProps): JSX.Element => {
    const {
        features: { isAnvilDeprecated },
    } = useContext(KaleContext);
    const { applicationPermissionsSectionErrorMsg } = useContext(SurveyContext).validation;
    const legalStatus = selectLegalApprovalStatus(application.appInfo).status;
    const redirectURL = kaleUrls.editKaleAccessControlRecordUrl(
        application.appInfo.applicationName,
        application.appInfo.review.id.toString()
    );

    const [isContainerExpanded, setIsContainerExpanded] = useState<boolean>(true);

    const accessControlFields: Pair[] = React.useMemo(() => {
        const map: Record<AccessControlPair, Pair> = {
            [AccessControlPair.RELATED_BINDLES]: relatedBindle(application),
            [AccessControlPair.AAA_APP_IDS]: aaaIds(application),
            [AccessControlPair.ANVIL_ID]: anvilId(application),
            [AccessControlPair.TASK_ID]: taskId(application),
            [AccessControlPair.APP_OWNER]: appOwner(application),
            [AccessControlPair.EXPLANATION]: explanation(application),
        };
        const anvilRelatedFields = [
            `${AccessControlPair.ANVIL_ID}`,
            `${AccessControlPair.TASK_ID}`,
            `${AccessControlPair.APP_OWNER}`,
        ];

        return Object.entries(map)
            .sort()
            .filter(([key]) => !(isAnvilDeprecated && anvilRelatedFields.includes(key)))
            .map(([, value]) => value);
    }, [application, isAnvilDeprecated]);

    const shouldShowError = !!(shouldShowErrors && applicationPermissionsSectionErrorMsg);

    return (
        <ExpandableSection
            variant="container"
            headerActions={
                legalStatus === ApplicationStatus.inProgress && (
                    <Button data-testid={TEST_IDS.WIZARD.EDIT_BUTTON} href={redirectURL}>
                        <SpaceBetween direction="horizontal" size="xs">
                            {shouldShowError && <Icon name="status-warning" variant="error" size="inherit" />}
                            Edit
                        </SpaceBetween>
                    </Button>
                )
            }
            headerText="Application Permissions & Access Controls"
            expanded={isContainerExpanded}
            onChange={() => setIsContainerExpanded(!isContainerExpanded)}
        >
            <ColumnLayout variant={"text-grid"} columns={3}>
                {accessControlFields.map(({ label, value, testId }): JSX.Element => {
                    return <KeyValuePair key={label} label={label} value={value} testId={testId} />;
                })}
            </ColumnLayout>
        </ExpandableSection>
    );
};
