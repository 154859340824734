import { tidSelector } from "../util/selectors";

export const TEST_IDS = {
    CONTENT_PANE: {
        RENDERED_CONTENT: "content-pane--rendered-content",
        ITEM: {
            ROOT: "content-pane--item--root",
            POPOVER_TRIGGER: "content-pane--item--popover-trigger",
            POPOVER_CONTENT: "content-pane--item--popover-content",
        },
        ORDERED_LIST: "content-pane--ordered-list",
        UN_ORDERED_LIST: "content-pane--un-ordered-list",
    },
};

export const SELECTORS = {
    CONTENT_PANE: {
        RENDERED_CONTENT: tidSelector(TEST_IDS.CONTENT_PANE.RENDERED_CONTENT),
    },
    ITEM: {
        ROOT: tidSelector(TEST_IDS.CONTENT_PANE.ITEM.ROOT),
        POPOVER_TRIGGER: tidSelector(TEST_IDS.CONTENT_PANE.ITEM.POPOVER_TRIGGER),
        POPOVER_CONTENT: tidSelector(TEST_IDS.CONTENT_PANE.ITEM.POPOVER_CONTENT),
    },
    ORDERED_LIST: tidSelector(TEST_IDS.CONTENT_PANE.ORDERED_LIST),
    UN_ORDERED_LIST: tidSelector(TEST_IDS.CONTENT_PANE.UN_ORDERED_LIST),
};
