import React from "react";
import { Box, Link } from "@amzn/awsui-components-react-v3";
import { Container, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { ReviewResponse } from "src/components/survey/SurveyFormModel";
import styled from "styled-components";

const Underlined = styled.span`
    text-decoration: underline;
`;

export const StartAssessmentIntro = (props: {
    review: ReviewResponse | undefined;
    detectedTafData: boolean;
}): JSX.Element => {
    const { review, detectedTafData } = props;

    return (
        <SpaceBetween direction="vertical" size="xl">
            <Container>
                <SpaceBetween direction="vertical" size="s">
                    {detectedTafData ? (
                        <Box variant="p">
                            {/*eslint-disable-next-line max-len*/}
                            Thanks for submitting your application! Based on the information you provided, we detected{" "}
                            <Underlined>you may have</Underlined> data relevant to a finanical event. As next steps, you
                            are required to complete a financial review to determine data retention requirements.
                        </Box>
                    ) : (
                        <Box variant="p">
                            Thanks for submitting your application! We didn&apos;t detect any data relevant to
                            Accounting and Consumer Trust & Partner Support (CTPS) on your application at this time.
                        </Box>
                    )}
                </SpaceBetween>

                <Box variant="p">
                    For an update on the status of your legal review, please review the{" "}
                    <Link external href={"https://w.amazon.com/bin/view/Kale/OrgSpecificGuidance/"}>
                        Org Specific Guidance
                    </Link>{" "}
                    or contact the members of the{" "}
                    <Link external href={`https://permissions.amazon.com/a/team/${review?.reviewGroup}`}>
                        {review?.reviewGroup}
                    </Link>{" "}
                    group directly.
                </Box>
                {detectedTafData && (
                    <Box variant="p">
                        <b>Financial Reviews and the Anvil Security Review.</b> If you submitted this application as
                        part of an Anvil security review, your certification will not be blocked by a financial review —
                        only privacy approval is required to complete the privacy task in your Anvil Application.
                        However, a financial review must be approved before you onboard to a service that vends deletion
                        signals (e.g., Sandfire).
                    </Box>
                )}
                <Box variant="p">
                    Click{" "}
                    <Link external href={"https://w.amazon.com/bin/view/CDOPrivacy/Privacy_Reviews/"}>
                        here
                    </Link>{" "}
                    to learn more about the Kale Approval Process.
                </Box>
            </Container>
        </SpaceBetween>
    );
};
