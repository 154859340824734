import { TAFIdentifiers } from "src/components/TAF/TAFDetails/TAFDetailsPage";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export const useTAFIdentifiersFromRoute = (): TAFIdentifiers => {
    const { applicationName, reviewId } = useParams<TAFIdentifiers>();

    return useMemo(
        (): TAFIdentifiers => ({
            applicationName,
            reviewId,
        }),
        [applicationName, reviewId]
    );
};
