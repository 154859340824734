import { SurveyResponse } from "src/components/survey/SurveyFormModel";
import { useEffect, useRef, useState } from "react";
import { useSyncGSMWithServerDataCb } from "src/components/survey/hooks/useSyncGSMWithServerDataCb";
import {
    DataStoreAnswer,
    useDataStores,
    useReview,
    ResetOptionsCallback,
    ResetAnswersActionCallback,
} from "src/answers_legacy";
import { GetTechnologyCb, setDataStoresConstraintHandler } from "src/components/survey/DataStore_options";
import { KaleQuestion } from "src/services/KaleApplicationService";
import ReactDOM from "react-dom";
import { ApplicationFromServer } from "src/components/survey/hooks/useFetchApplicationOnMount";

const getTechnologyCb = (response: SurveyResponse): GetTechnologyCb => {
    return (answer: DataStoreAnswer): string => {
        const index = response.appInfo.review.dataStores.findIndex(
            (dataStore): boolean => dataStore.id === answer.dataStoreId
        );
        return response.appInfo.review.dataStores?.[index]?.technology;
    };
};

/**
 * Custom Hook that is responsible for
 *  1. Configuring a GSM custom constraint handler for DataStore Questions.
 *  2. Initializing the GSM question/answers for the LegalSurvey after on page load, but only after making sure that
 *  all of the pre-requisite resources have been fetched to allow us to initialize GSM answers correctly and avoid
 *  negative side effects.=
 */

export const useInitializeLegalSurveyGSM = (application: ApplicationFromServer, questions: KaleQuestion): void => {
    const [isGSMInitialized, setIsGSMInitialized] = useState<boolean>(false);

    const initializeGSMWithServerData = useSyncGSMWithServerDataCb();
    const [, dataStoreActions] = useDataStores();
    const [, reviewActions] = useReview();

    const deps = {
        reviewActions,
        dataStoreActions,
        syncGSMWithServerData: initializeGSMWithServerData,
        isGSMInitialized,
    };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    // Place this effect before the effect that loads the GSM questions to ensure that we always have the custom
    // constraint handler in place before ever running the GSM as the GSM would otherwise run its default
    // constraint handler which would incorrectly cause it to mark some datastore questions as not applicable which
    // would erase any GSM state answers for those questions

    useEffect((): ResetOptionsCallback | void => {
        if (application) {
            const { dataStoreActions } = dependenciesRef.current;
            const { addOptions: addDataStoreOptions } = dataStoreActions;
            setDataStoresConstraintHandler(addDataStoreOptions, getTechnologyCb(application));
            return (): void => dataStoreActions.resetOptions();
        }
    }, [application]);

    // Handles initialization of the LegalSurvey GSM questions/answers only one time after the initial page load.
    // Once the GSM has been initialized, this effect becomes a noop.
    useEffect((): void => {
        const { syncGSMWithServerData, isGSMInitialized } = dependenciesRef.current;
        // Only run this initialization effect once, and only after all the required resources are in place!!
        // Trying to initialize the GSM too early can result in questions incorrectly getting marked non-applicable
        // and having their answers be wiped
        const hasRequiredResources = Boolean(questions.dataStores && questions.dataStores.length && application);
        if (!isGSMInitialized && hasRequiredResources) {
            ReactDOM.unstable_batchedUpdates((): void => {
                syncGSMWithServerData(application!, questions);
            });
            setIsGSMInitialized(true);
        }
    }, [questions, application]);

    // Reset GSM Review Answers only when the effect unmounts. This is to ensure
    // That the TAF page which reuses the `Review` GSM slice will not display
    // the wrong data.
    useEffect((): ResetAnswersActionCallback => {
        return (): void => {
            const { reviewActions } = dependenciesRef.current;
            reviewActions.resetAnswers();
        };
        // The empty dependency array ensures that hook will never rerender and
        // therefore the cleanup function will only get invoked when the effect
        // unmounts
    }, []);
};
