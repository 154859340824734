import { Link, MultiselectProps, WizardProps } from "@amzn/awsui-components-react-v3";
import { AAAIdsInputWithFunctionalReadOnly } from "src/components/survey/ApplicationPermissions";
import React, { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { ValueDictionary } from "src/services/IncrementalSave";
import { AccessControlWizardStep } from "src/components/survey/AccessControl/AccessControlWizard";
import { AccessControlContext } from "src/components/survey/AccessControl/AccessControlContext";
import { AAAApplicationHelpContent } from "src/components/survey/AccessControl/helpPanel/AAAApplicationHelpContent";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { MessageType } from "src/components/survey/KaleRoutes";
import { REVIEW } from "src/components/DataDictionary";

export const TITLE = "Confirm your AAA applications";

interface Props {
    activeStepIndex: number;
    getCurrentTitle: () => string;
}

export const useAAAApplicationsStep = (props: Props): AccessControlWizardStep => {
    const {
        service: {
            kaleAppService: { listAAAApplications },
        },
    } = useContext(KaleContext);
    const { application, isReadOnly, setIsHelpPanelOpen } = useContext(AccessControlContext);
    const { displayMessage } = useMessageBannerActions();
    const { getCurrentTitle, activeStepIndex } = props;
    const helpPanelContent = React.useMemo(() => <AAAApplicationHelpContent />, []);

    const [aaaApps, setAaaApps] = useState<MultiselectProps.Option[]>([]);
    const [selectedAAAApps, setSelectedAAAApps] = useState<string[]>([]);
    const [aaaIdsStatus, setAaaIdsStatus] = useState<MultiselectProps["statusType"]>("pending");
    const [valueDictionary, setValueDictionary] = useState<ValueDictionary>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const deps = { listAAAApplications, getCurrentTitle, application, displayMessage, aaaApps };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(() => {
        const { listAAAApplications, getCurrentTitle, application, displayMessage } = depsRef.current;
        // We want to load AAA only when user lands in this step because it is dependant on related bindle selection
        if (getCurrentTitle() != TITLE) {
            return;
        }

        (async function IIFE(): Promise<void> {
            try {
                setIsLoading(true);
                setAaaIdsStatus("loading");
                const appName = decodeURIComponent(application.appInfo.applicationName);
                const aaaApps = await listAAAApplications(appName);
                const options = aaaApps.map(({ aaaAppName, aaaAppId, recommended }): MultiselectProps.Option => {
                    const selected = application.appInfo.review.aaaIdentifier.includes(aaaAppId);
                    const tags = [];

                    if (recommended) tags.push("Recommended");
                    if (selected) tags.push("Previously selected");

                    return {
                        value: aaaAppId,
                        label: aaaAppId,
                        description: aaaAppName,
                        tags: tags,
                        disabled: recommended,
                    };
                });
                setAaaApps(options);
                const recommendedFromVeritas = aaaApps
                    .filter((aaaApp): boolean => aaaApp.recommended)
                    .map((aaaApp): string => aaaApp.aaaAppId);
                setSelectedAAAApps(recommendedFromVeritas);
            } catch (e) {
                console.error(e);
                displayMessage(MessageType.error, (e as Error).message);
                setAaaIdsStatus("error");
            } finally {
                setIsLoading(false);
                setAaaIdsStatus("finished");
            }
        })();
    }, [activeStepIndex]);

    useEffect(() => {
        setValueDictionary({ [REVIEW.AAA_IDENTIFIER]: selectedAAAApps });
    }, [selectedAAAApps]);

    const logMetrics = (userSelectedAAAIds: string[]): void => {
        const removed: string[] = [];
        aaaApps.forEach((bindle): void => {
            if (bindle.label && bindle.tags?.includes("Recommended") && !userSelectedAAAIds.includes(bindle.label)) {
                removed.push(bindle.label);
            }
        });
        const appName = application.appInfo.applicationName;
        if (removed.length > 0) {
            console.info(`Removed bindle(s) that are recommended by Veritas for ${appName}: ${removed}`);
        }
    };

    const step: WizardProps.Step = {
        title: TITLE,
        info: <Link onFollow={() => setIsHelpPanelOpen(true)}>info</Link>,
        content: (
            <AAAIdsInputWithFunctionalReadOnly
                id={"aaaIdentifiers"}
                isReadonly={isReadOnly}
                status={aaaIdsStatus}
                options={aaaApps}
                expectedAnswer={selectedAAAApps}
                removedBindleNames={[]}
                isLoading={isLoading}
                onChangeCallback={(result) => {
                    setSelectedAAAApps(result.response);
                    logMetrics(result.response);
                }}
            />
        ),
    };

    return { step, isLoading, valueDictionary, helpPanelContent };
};
