import React, { useCallback, useMemo, useState } from "react";
import {
    Button,
    Input,
    InputProps,
    AttributeEditor,
    AttributeEditorProps,
    Modal,
    NonCancelableCustomEvent,
    SpaceBetween,
    Box,
} from "@amzn/awsui-components-react-v3";
import { isStringNullOrTrimEmpty } from "src/util/StringUtil";
import { NewField } from "src/services/KaleTablesService";

type EditKeys = "name" | "type";

const makePrototype = (tableId: number): NewField => ({
    name: "",
    type: "",
    description: "",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    tableId,
});
const makeInitialFields = (tableId: number): NewField[] => [makePrototype(tableId)];

export const EMPTY_STRING_ERROR = "Cannot be empty";

// Field name cannot be empty or all white space
const isValidFieldName = (name: string): boolean => !isStringNullOrTrimEmpty(name);

interface AddNewTableFieldsProps {
    tableId: number;
    onClose: () => void;
    onSubmit: (newFields: NewField[]) => Promise<void>;
    isSaving: boolean;
    isVisible: boolean;
}
const AddNewTableFields = (props: AddNewTableFieldsProps): JSX.Element => {
    const { isSaving, isVisible, onClose: onCloseCb, onSubmit: onSubmitCb, tableId } = props;
    const makeInitialTableFields = useCallback((): NewField[] => {
        return makeInitialFields(tableId);
    }, [tableId]);
    const [fields, setFields] = useState<NewField[]>(makeInitialTableFields);

    // Block Add Field confirmation if any field has an invalid name
    const isSubmitDisabled = useMemo(
        (): boolean => fields.some(({ name }): boolean => !isValidFieldName(name)),
        [fields]
    );

    const resetFields = (): void => {
        setFields(makeInitialTableFields);
    };

    const onClose = (): void => {
        onCloseCb();
        resetFields();
    };
    const onSubmit = async (): Promise<void> => {
        await onSubmitCb(fields);
        onClose();
    };
    const onAdd = (): void => {
        setFields([...fields, makePrototype(tableId)]);
    };
    const onRemove = (evt: NonCancelableCustomEvent<AttributeEditorProps.RemoveButtonClickDetail>): void => {
        const removeIndex = evt.detail.itemIndex;
        setFields((fields): NewField[] => fields.filter((_, i): boolean => removeIndex !== i));
    };

    type OnChangeCb = (e: NonCancelableCustomEvent<InputProps.ChangeDetail>) => void;
    const makeOnChangeCb = (key: EditKeys, updateIndex: number): OnChangeCb => {
        return ({ detail: { value } }): void => {
            setFields((fields): NewField[] =>
                fields.map((field, index): NewField => {
                    if (updateIndex === index) {
                        return {
                            ...field,
                            ...{ [key]: value },
                        };
                    } else {
                        return field;
                    }
                })
            );
        };
    };

    return (
        <Modal
            visible={isVisible}
            header="Add new fields"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onSubmit} disabled={isSubmitDisabled} loading={isSaving}>
                            Add fields
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={onClose}
        >
            <AttributeEditor
                id="newFieldsEditor"
                addButtonText="Add another field"
                removeButtonText="Remove"
                items={fields}
                definition={[
                    {
                        label: "Field",
                        control: (field: NewField, index: number): JSX.Element => (
                            <Input
                                value={field.name}
                                placeholder="Enter field name"
                                onChange={makeOnChangeCb("name", index)}
                            />
                        ),
                        errorText: ({ name }: NewField): string | null =>
                            isValidFieldName(name) ? null : EMPTY_STRING_ERROR,
                    },
                    {
                        label: "Field Type",
                        control: (field: NewField, index: number): JSX.Element => (
                            <Input
                                value={field.type}
                                placeholder="Enter field type"
                                onChange={makeOnChangeCb("type", index)}
                            />
                        ),
                    },
                ]}
                onAddButtonClick={onAdd}
                onRemoveButtonClick={onRemove}
            />
        </Modal>
    );
};

export default AddNewTableFields;
