import {
    FieldRecord,
    QuestionChoice,
    QuestionTag,
    QuestionType,
    TableAnswer,
    TableQuestion,
    TableRecord,
    TableSources,
    TableStatus,
} from "src/services/KaleTablesService";
import { SYNTH_METADATA_COMPLIANCE_TYPE, SYNTH_UNIVERSAL_COMPLIANCE_TYPE } from "src/components/TableDetails/constants";
import { QuestionApplicabilityTag } from "src/services/dynamic-questions";
import { EMPTY_COLLECTION } from "src/components/TableDetails/TableDetailsPage/hooks/contants";

export enum SyntheticTableIds {
    TableName = "Table_Name_Synthetic_Id",
    TableDescription = "Table_Description_Synthetic_Id",
    TableStatus = "Table_Status_Synthetic_Id",
    TableHasPersonalData = "Table_Has_Personal_Data_Synthetic_Id",
    // Metadata questions appear at the bottom of the page, under fields table
    TableMetadataCreationDate = "Table_Metadata_Creation_Synthetic_Id",
    TableMetadataDeletionRequestDate = "Table_Metadata_Deletion_Request_Synthetic_Id",
    TableMetadataTaxPeriodEndDate = "Table_Metadata_Tax_End_Synthetic_Id",
}

// Using this to ensure an exhaustive switch statement in our reduce operation.
const SYNTHETIC_TABLE_ID_RECORD: Record<SyntheticTableIds, boolean> = {
    [SyntheticTableIds.TableName]: true,
    [SyntheticTableIds.TableDescription]: true,
    [SyntheticTableIds.TableStatus]: true,
    [SyntheticTableIds.TableHasPersonalData]: true,
    [SyntheticTableIds.TableMetadataCreationDate]: true,
    [SyntheticTableIds.TableMetadataDeletionRequestDate]: true,
    [SyntheticTableIds.TableMetadataTaxPeriodEndDate]: true,
};

// Applicability tags control questions being shown/hiden. This map contains the source of truth on which tags are for
// which question
const synthTableIdToQuestionTag: Record<string, QuestionApplicabilityTag> = {
    [SyntheticTableIds.TableMetadataCreationDate]: QuestionApplicabilityTag.metadataCreationDate,
    [SyntheticTableIds.TableMetadataDeletionRequestDate]: QuestionApplicabilityTag.metadataDeletionRequestDate,
    [SyntheticTableIds.TableMetadataTaxPeriodEndDate]: QuestionApplicabilityTag.metadataTaxPeriodEndDate,
};

type SyntheticTableRecord = Pick<
    TableRecord,
    "answers" | "name" | "description" | "status" | "metadataAnswers" | "hasPersonalData"
>;
const reduceTableAnswers = (tableAnswers: TableAnswer[]): SyntheticTableRecord => {
    // Reduce a list of TableAnswers into a SyntheticTableRecord.

    const initialState: SyntheticTableRecord = {
        answers: [],
        name: "",
        description: "",
        status: "",
        metadataAnswers: {},
        hasPersonalData: "",
    };

    return tableAnswers.reduce<SyntheticTableRecord>((tableRecord, answer): SyntheticTableRecord => {
        // Extract any Synthetic Table answers contained in the tableAnswers argument and map their value to the
        // corresponding property name on the resultant table record.
        // Place every organic TableAnswer into the table record "answers" list.
        const { questionShortId: shortId } = answer;
        const isSyntheticId = Boolean(SYNTHETIC_TABLE_ID_RECORD[shortId as SyntheticTableIds]);

        if (isSyntheticId) {
            switch (shortId) {
                case SyntheticTableIds.TableName: {
                    tableRecord.name = answer.textContent ?? "";
                    break;
                }
                case SyntheticTableIds.TableDescription: {
                    tableRecord.description = answer.textContent ?? "";
                    break;
                }
                case SyntheticTableIds.TableStatus: {
                    tableRecord.status = (answer.textContent as TableStatus) ?? "";
                    break;
                }
                case SyntheticTableIds.TableHasPersonalData: {
                    tableRecord.hasPersonalData = answer.textContent ?? "";
                    break;
                }
                case SyntheticTableIds.TableMetadataCreationDate: {
                    if (!answer.textContent) {
                        break;
                    }

                    const questionTag = synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataCreationDate];
                    // textContent is a positive whole number (field Id)
                    tableRecord.metadataAnswers[questionTag] = Number(answer.textContent);
                    break;
                }
                case SyntheticTableIds.TableMetadataDeletionRequestDate: {
                    if (!answer.textContent) {
                        break;
                    }

                    const questionTag = synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataDeletionRequestDate];
                    // textContent is a positive whole number (field Id)
                    tableRecord.metadataAnswers[questionTag] = Number(answer.textContent);
                    break;
                }
                case SyntheticTableIds.TableMetadataTaxPeriodEndDate: {
                    if (!answer.textContent) {
                        break;
                    }

                    const questionTag = synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataTaxPeriodEndDate];
                    // textContent is a positive whole number (field Id)
                    tableRecord.metadataAnswers[questionTag] = Number(answer.textContent);
                    break;
                }
                default: {
                    throw Error(`Missing case for Synthetic Table Id: ${shortId}`);
                }
            }
        } else {
            // Not a Synthetic TableAnswer.. Append to the list of Organic TableAnswers
            tableRecord.answers.push(answer);
        }
        return tableRecord;
    }, initialState);
};

const TABLE_STATUS_CHOICES = [
    { label: TableStatus.IdentifyDataset, value: TableStatus.IdentifyDataset },
    { label: TableStatus.DocumentAndAnalyze, value: TableStatus.DocumentAndAnalyze },
    { label: TableStatus.ReadyForPreReview, value: TableStatus.ReadyForPreReview },
    { label: TableStatus.PreReviewStarted, value: TableStatus.PreReviewStarted },
    { label: TableStatus.PreReviewComplete, value: TableStatus.PreReviewComplete },
    { label: TableStatus.ApprovalStarted, value: TableStatus.ApprovalStarted },
    { label: TableStatus.ApprovalCompleteWithActions, value: TableStatus.ApprovalCompleteWithActions },
    { label: TableStatus.ApprovalComplete, value: TableStatus.ApprovalComplete },
    { label: TableStatus.PostApprovalChanges, value: TableStatus.PostApprovalChanges },
];

const TABLE_HAS_PERSONAL_DATA_CHOICES = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
];

const VESTIGIAL_ID = -1; // Assign to required numeric ids that Synthetic questions don't care about.

type SyntheticTableQuestion = Omit<TableQuestion, "parentId"> & { parentShortId?: string };
const makeSyntheticTableQuestions = (
    table: TableRecord | null,
    metadataDropdownChoices: QuestionChoice[]
): SyntheticTableQuestion[] => {
    // content, shortId and tags to be populated by individual questions
    const sharedMetadataQuestionConfig: Omit<SyntheticTableQuestion, "content" | "shortId" | "tags"> = {
        type: QuestionType.singleSelect,
        title: "",
        subtext: "",
        groupId: "",
        complianceType: SYNTH_METADATA_COMPLIANCE_TYPE,
        complianceTypeId: VESTIGIAL_ID,
        id: VESTIGIAL_ID,
        choices: metadataDropdownChoices,
        parentChoices: [],
    };

    const SYNTHETIC_TABLE_QUESTIONS: Record<SyntheticTableIds, SyntheticTableQuestion> = {
        [SyntheticTableIds.TableName]: {
            shortId: SyntheticTableIds.TableName,
            type: QuestionType.text,
            content: "Table Name",
            title: "",
            subtext: "",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [],
            parentChoices: [],
            tags:
                table?.source === TableSources.Andes
                    ? [QuestionTag.requiredForDraft, QuestionTag.disabledForAndes]
                    : [QuestionTag.requiredForDraft],
        },
        [SyntheticTableIds.TableDescription]: {
            shortId: SyntheticTableIds.TableDescription,
            type: QuestionType.textArea,
            content: "Table Description",
            title: "",
            subtext:
                "Provide a detailed description of the table to help reviewers understand the business context " +
                "and purpose of the table.",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: [],
            parentChoices: [],
            tags: [],
        },
        [SyntheticTableIds.TableStatus]: {
            shortId: SyntheticTableIds.TableStatus,
            type: QuestionType.singleSelect,
            content: "Status",
            title: "",
            subtext: "Statuses can help you keep track of where you are in the review, but they are optional.",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: TABLE_STATUS_CHOICES,
            parentChoices: [],
            tags: [QuestionTag.bulkEditable],
        },
        [SyntheticTableIds.TableHasPersonalData]: {
            shortId: SyntheticTableIds.TableHasPersonalData,
            type: QuestionType.singleSelect,
            content: "Does this table have fields capturing personal data?",
            title: "",
            subtext:
                "For more information about personal data, " +
                "<a href='https://w.amazon.com/bin/view/CDOPrivacy/FAQs'>click here</a>.",
            groupId: "",
            complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
            complianceTypeId: VESTIGIAL_ID,
            id: VESTIGIAL_ID,
            choices: TABLE_HAS_PERSONAL_DATA_CHOICES,
            parentChoices: [],
            tags: [QuestionTag.bulkEditable],
        },
        [SyntheticTableIds.TableMetadataCreationDate]: {
            ...sharedMetadataQuestionConfig,
            shortId: SyntheticTableIds.TableMetadataCreationDate,
            content: "Which field contains the Creation Date for a given row/record of data?",
            tags: [synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataCreationDate]],
        },
        [SyntheticTableIds.TableMetadataDeletionRequestDate]: {
            ...sharedMetadataQuestionConfig,
            shortId: SyntheticTableIds.TableMetadataDeletionRequestDate,
            content: "Which field contains the Deletion Request Date for a given row/record of data?",
            tags: [synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataDeletionRequestDate]],
        },
        [SyntheticTableIds.TableMetadataTaxPeriodEndDate]: {
            ...sharedMetadataQuestionConfig,
            shortId: SyntheticTableIds.TableMetadataTaxPeriodEndDate,
            content: "Which field contains the Tax Period End Date for a given row/record of data?",
            tags: [synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataTaxPeriodEndDate]],
        },
    };
    return Object.values(SYNTHETIC_TABLE_QUESTIONS);
};

type SyntheticTableAnswer = Omit<TableAnswer, "tableAnswerId">;
const makeSyntheticTableAnswers = ({
    id: tableId,
    name: tableNameValue,
    description: tableDescriptionValue,
    status: tableStatusValue,
    hasPersonalData: tableHasPersonalDataValue,
    metadataAnswers: tableMetadataAnswers,
}: TableRecord): SyntheticTableAnswer[] => {
    const universalComplianceSharedConfig: Pick<SyntheticTableAnswer, "tableId" | "questionId" | "complianceType"> = {
        tableId,
        questionId: VESTIGIAL_ID,
        complianceType: SYNTH_UNIVERSAL_COMPLIANCE_TYPE,
    };
    const metadataComplianceSharedConfig: Pick<SyntheticTableAnswer, "tableId" | "questionId" | "complianceType"> = {
        tableId,
        questionId: VESTIGIAL_ID,
        complianceType: SYNTH_METADATA_COMPLIANCE_TYPE,
    };

    // Whole numbers(field IDs) stored as strings, to show as textContent, for metadata questions
    let creationDateFieldId = "";
    let deletionRequestDateFieldId = "";
    let taxPeriodEndDateFieldId = "";
    if (tableMetadataAnswers) {
        const creationQuestionTag = synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataCreationDate];
        // tableMetadataAnswers is a map from questionTag (metadata_id in backend) to fieldId (question's answer)
        if (tableMetadataAnswers[creationQuestionTag]) {
            creationDateFieldId = String(tableMetadataAnswers[creationQuestionTag]);
        }
        const deletionRequestQuestionTag =
            synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataDeletionRequestDate];
        if (tableMetadataAnswers[deletionRequestQuestionTag]) {
            deletionRequestDateFieldId = String(tableMetadataAnswers[deletionRequestQuestionTag]);
        }
        const taxPeriodEndQuestionTag = synthTableIdToQuestionTag[SyntheticTableIds.TableMetadataTaxPeriodEndDate];
        if (tableMetadataAnswers[taxPeriodEndQuestionTag]) {
            taxPeriodEndDateFieldId = String(tableMetadataAnswers[taxPeriodEndQuestionTag]);
        }
    }

    const syntheticTableAnswers: Record<SyntheticTableIds, SyntheticTableAnswer> = {
        [SyntheticTableIds.TableName]: {
            ...{ textContent: tableNameValue, questionShortId: SyntheticTableIds.TableName },
            ...universalComplianceSharedConfig,
        },
        [SyntheticTableIds.TableDescription]: {
            ...{ textContent: tableDescriptionValue, questionShortId: SyntheticTableIds.TableDescription },
            ...universalComplianceSharedConfig,
        },
        [SyntheticTableIds.TableStatus]: {
            ...{
                textContent: tableStatusValue,
                questionShortId: SyntheticTableIds.TableStatus,
            },
            ...universalComplianceSharedConfig,
        },
        [SyntheticTableIds.TableHasPersonalData]: {
            ...{
                textContent: tableHasPersonalDataValue,
                questionShortId: SyntheticTableIds.TableHasPersonalData,
            },
            ...universalComplianceSharedConfig,
        },
        [SyntheticTableIds.TableMetadataCreationDate]: {
            ...{ textContent: creationDateFieldId, questionShortId: SyntheticTableIds.TableMetadataCreationDate },
            ...metadataComplianceSharedConfig,
        },
        [SyntheticTableIds.TableMetadataDeletionRequestDate]: {
            ...{
                textContent: deletionRequestDateFieldId,
                questionShortId: SyntheticTableIds.TableMetadataDeletionRequestDate,
            },
            ...metadataComplianceSharedConfig,
        },
        [SyntheticTableIds.TableMetadataTaxPeriodEndDate]: {
            ...{
                textContent: taxPeriodEndDateFieldId,
                questionShortId: SyntheticTableIds.TableMetadataTaxPeriodEndDate,
            },
            ...metadataComplianceSharedConfig,
        },
    };

    return Object.values(syntheticTableAnswers);
};

interface SyntheticTableQuestionsAndAnswers {
    syntheticTableQuestions: TableQuestion[];
    syntheticTableAnswers: TableAnswer[];
}

// Convert specific TableRecord properties (and selected Field info.) into Synthetic TableQuestions and TableAnswers
const makeSyntheticTableQuestionsAndAnswers = (
    table: TableRecord | undefined,
    fields: FieldRecord[] | undefined
): SyntheticTableQuestionsAndAnswers => {
    // We have a handful of synthetic table "metadata" questions whose question choices need to
    // be generated from the Field Names of the table. The backend wants the value of the choice
    // to be the fieldId.
    // Doing this here means that if a user changes the name of a field and does not save the table
    // then it will not update the dropdown. (Brent-accepted tradeoff to make impl. simpler)
    const metadataDropdownChoices: QuestionChoice[] =
        fields?.map((field): QuestionChoice => {
            // fields are coming in from the "tableDetailsFromServer" so the field ids are always defined
            return { value: String(field.id), label: field.name };
        }) ?? EMPTY_COLLECTION;

    let syntheticTableAnswers: SyntheticTableAnswer[] = [];
    let syntheticTableQuestions: SyntheticTableQuestion[] = [];
    if (table) {
        syntheticTableQuestions = makeSyntheticTableQuestions(table, metadataDropdownChoices);
        syntheticTableAnswers = makeSyntheticTableAnswers(table);
    }
    return { syntheticTableQuestions, syntheticTableAnswers };
};

export type { SyntheticTableRecord, SyntheticTableQuestionsAndAnswers };
export { reduceTableAnswers, makeSyntheticTableQuestionsAndAnswers, makeSyntheticTableQuestions };
