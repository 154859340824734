import { ClassificationScore, FoundEntityType } from "src/services/NodeKaleApplicationService";
import { Popover, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React from "react";
import { TEST_IDS } from "shared/survey/personalDataWizard";
import { EntityFilterFn } from "./usePDCFilters";

const { ENTITY_TYPE } = TEST_IDS.WIZARD.PDC_STEP.PDC_TABLE;

type PDCEntityTypesProps = {
    entityTypes: FoundEntityType[];
    /**
     * A function to determine if an entity type meets the filter criteria of the currently applied table filter.
     * Only the EntityTypes that meet the filter criteria will be rendered
     */
    entityFilter: EntityFilterFn;
};

/** Component is responsible for rendering a list of FoundEntityTypes */
export const PDCEntityTypes = ({ entityTypes, entityFilter }: PDCEntityTypesProps): JSX.Element => {
    return (
        <SpaceBetween size={"xxs"} direction={"vertical"}>
            {entityTypes.reduce<JSX.Element[]>(function renderEntityTypes(renderList, entity, index): JSX.Element[] {
                const confidenceScore = entity.highestClassificationScore;
                const shouldRender = entityFilter(entity);

                if (shouldRender) {
                    renderList.push(
                        <EntityType
                            key={index}
                            entityTypeDerName={entity.entityTypeIdentifier.derName}
                            confidenceScore={confidenceScore}
                        />
                    );
                }
                return renderList;
            }, [])}
        </SpaceBetween>
    );
};

const confidenceScoreToFixedPercentage = (confidenceScore: ClassificationScore, fractionDigits?: number): string => {
    const fixedPercentage = (confidenceScore * 100).toFixed(fractionDigits);
    return `${fixedPercentage}%`;
};

interface EntityTypeProps {
    entityTypeDerName: string;
    confidenceScore: ClassificationScore;
}
const EntityType = ({ entityTypeDerName, confidenceScore }: EntityTypeProps): JSX.Element => {
    return (
        <span data-testid={ENTITY_TYPE.ROOT} data-entity-dername={entityTypeDerName}>
            <Popover
                size="large"
                position="top"
                triggerType="text"
                dismissButton={false}
                content={`Confidence: ${confidenceScoreToFixedPercentage(confidenceScore, 2)}`}
            >
                <SpaceBetween data-testid={ENTITY_TYPE.LABEL} size={"xxs"} direction={"horizontal"}>
                    {entityTypeDerName} {confidenceScoreToFixedPercentage(confidenceScore)}
                </SpaceBetween>
            </Popover>
        </span>
    );
};
