import { useContext, useMemo, useRef } from "react";
import { answerActionsContext, Answers, AnswerState, answerStateContext, Response } from "../context";
import { StateKey } from "../actions";
import { DefaultTableAnswer, TableAnswer, TableAnswerWithQuestion, TableQuestion } from "../models/services";
import { getAnswerResponseState, HookAnswer } from "./common";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";
import { KaleTablePayload, KaleTablesAccessor, TableAnswerActions } from "src/answers_legacy";

export function getBulkEditKaleTable(
    state: AnswerState,
    tableId?: number
): Answers<TableAnswerWithQuestion> | undefined {
    return tableId
        ? state[StateKey.bulkEditKaleTable].find(
              (item): boolean => item.value[0] && item.value[0].value.answer.tableId === tableId
          )
        : state[StateKey.bulkEditKaleTable][0];
}

export function getBulkEditKaleTableAnswerById(
    state: AnswerState,
    actions: TableAnswerActions,
    questionShortId?: number | string,
    tableId?: number
): HookAnswer<TableQuestion, TableAnswer> | void {
    const kaleTableState = getBulkEditKaleTable(state, tableId);

    const questionWithAnswer = kaleTableState?.value.find((answer: Response<TableAnswerWithQuestion>): boolean => {
        const id = answer.value.answer.questionShortId;
        return id === questionShortId;
    });

    if (!questionWithAnswer) {
        console.error(`Invalid requested table answer by short id "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<TableQuestion, TableAnswer>(questionWithAnswer, actions);
}

export const useBulkEditKaleTable = (): [KaleTablesAccessor, TableAnswerActions] => {
    const actions = useContext(answerActionsContext)<TableQuestion, TableAnswer>(StateKey.bulkEditKaleTable);
    const state = useContext(answerStateContext);
    const tableState = state[StateKey.bulkEditKaleTable];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [KaleTablesAccessor, TableAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            tableState.map((table): KaleTablesAccessor => {
                return {
                    answers: table.value
                        .map((questionWithAnswer): HookAnswer<TableQuestion, TableAnswer> | void =>
                            getBulkEditKaleTableAnswerById(
                                state,
                                actions,
                                questionWithAnswer.value.answer.questionShortId
                            )
                        )
                        .filter((v): boolean => !!v) as HookAnswer<TableQuestion, TableAnswer>[],
                    isValid: table.isValid,
                    isValidSave: table.isValidSave,
                    isValidSubmit: table.isValidSubmit,
                    hasChanged: table.hasChanged,
                    userLastUpdated: table.userLastUpdated,
                };
            })[0], // Return first table only, more not needed
            actions,
        ];
    }, [tableState]);
};

export const useBulkEditKaleTablePayload = (): KaleTablePayload => {
    const state = useContext(answerStateContext);
    const tableState = state[StateKey.bulkEditKaleTable];

    return useMemo(
        (): KaleTablePayload =>
            tableState
                .map((table): TableAnswer[] =>
                    table.value.map(
                        (questionWithAnswer): TableAnswer => ({
                            ...questionWithAnswer.value.answer,
                            groupType: questionWithAnswer.value.question?.groupType,
                        })
                    )
                )[0] // Return first table only, more not needed
                .map((answer): TableAnswer => {
                    const base = DefaultTableAnswer(
                        answer.questionId,
                        answer.questionShortId,
                        answer.tableId,
                        answer.userTableId
                    );

                    return { ...base, ...answer };
                })
                .filter((answer): boolean => meetsServerAnswerConstraint(answer)),
        [tableState]
    );
};
