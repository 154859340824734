import {
    Alert,
    Box,
    Container,
    Header,
    Button,
    ColumnLayout,
    FormField,
    SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import React from "react";
import { isEmpty } from "lodash";
import { DataStoreAnswerType } from "src/answers_legacy/hooks/dataStores";
import { AlertContainer, InstanceValue } from "src/components/fields/styled";
import { isAndes, isDataStoreInstancesValid } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { IntKeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { QuestionTag, QuestionType } from "src/services/dynamic-questions";
import { INCOMPLETE_WIZARD_FIELD_ERROR } from "src/components/survey/DataStoreInfo/DataStoreWizard/constants";
import { useIsAuthorized, UserAction } from "src/permissions";
import { TEST_IDS } from "shared/survey";
import { NonJsonContentType, QuestionChoice, RetentionInputType, RetentionJsonContentType } from "src/answers_legacy";
import { getDefaultContentValue } from "src/answers_legacy/reducers/helpers";
import { RetentionCheckboxLabel } from "./WizardStepQuestionsBuilder/WizardStepQuestionsBuilder";
import safeHTMLReactParser from "src/util/safeHTMLReactParser";

interface Props {
    stepQuestions: DataStoreAnswerType[];
    response: DataStoreResponse;
    questionsIdMap: IntKeyValuePairs<DataStoreAnswerType>;
    childIdMap: IntKeyValuePairs<number[]>;
    isValidSubmit: boolean;
    onEditButtonClicked: (index: number) => void;
    hasTafLayout: boolean;
}

const { SUMMARY_REVIEW_STEP } = TEST_IDS.DATA_STORE_INFO.DATA_STORE_WIZARD;

export const ALERT_HEADER = "Incomplete Data Store";
export const PRIVACY_ALERT_HEADER = "Privacy Alert";

const SummaryBuilder = (props: Props): JSX.Element => {
    const { stepQuestions, isValidSubmit, response, questionsIdMap, childIdMap, onEditButtonClicked, hasTafLayout } =
        props;
    const { technology, name, description, instances } = response;

    const canEditDataStore = useIsAuthorized(UserAction.editApplication);

    const renderSummaryBlock = (
        title: string,
        index: number,
        content: JSX.Element,
        canEditDataStore: boolean
    ): JSX.Element => {
        return (
            <Container
                key={`${title}-${index}`}
                header={
                    <Header
                        actions={
                            <Button
                                data-testid={`${title}-${index}`}
                                onClick={(): void => {
                                    onEditButtonClicked(index);
                                }}
                            >
                                {canEditDataStore ? "Edit" : "View"}
                            </Button>
                        }
                    >{`Step ${index + 1}: ${title}`}</Header>
                }
            >
                {content}
            </Container>
        );
    };

    const renderDataStorageSummary = (): JSX.Element => {
        return (
            <ColumnLayout columns={2}>
                <SpaceBetween size="m">
                    <div>
                        <Box variant="strong">Data store technology</Box>
                        <FormField
                            data-testid={SUMMARY_REVIEW_STEP.TECHNOLOGY}
                            errorText={technology ? "" : INCOMPLETE_WIZARD_FIELD_ERROR}
                        >
                            {technology}
                        </FormField>
                    </div>
                    {!isAndes(technology) && (
                        <div>
                            <Box variant="strong">Data store instances</Box>
                            <FormField
                                data-testid={SUMMARY_REVIEW_STEP.INSTANCES}
                                errorText={isDataStoreInstancesValid(instances) ? "" : INCOMPLETE_WIZARD_FIELD_ERROR}
                            >
                                {instances.map((instance): JSX.Element => {
                                    const idValue = instance.identifiers
                                        .map((id: any): string => [id.type, id.value].join(" = "))
                                        .join(", ");

                                    return <InstanceValue key={idValue}>{idValue}</InstanceValue>;
                                })}
                            </FormField>
                        </div>
                    )}
                    <div>
                        <Box variant="strong">Data store name</Box>
                        <FormField
                            data-testid={SUMMARY_REVIEW_STEP.NAME}
                            errorText={name ? "" : INCOMPLETE_WIZARD_FIELD_ERROR}
                        >
                            {name}
                        </FormField>
                    </div>
                    <div>
                        <Box variant="strong">Data store description</Box>
                        <FormField data-testid={SUMMARY_REVIEW_STEP.DESCRIPTION}>{description}</FormField>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        );
    };

    const renderSummary = (item: DataStoreAnswerType): JSX.Element[] => {
        const summary: JSX.Element[] = [];
        const question = item.answerWithQuestion.question;
        if (item.isApplicable && !item.answerWithQuestion.question.tags.includes(QuestionTag.legacyHidden)) {
            const hasAnswer = !isEmpty(item.value);
            let content: JSX.Element;
            switch (question.type) {
                case QuestionType.retention: {
                    const answer =
                        (item.value as unknown as RetentionJsonContentType) ?? getDefaultContentValue(question);
                    const retentionCheckboxAnswer = answer[RetentionInputType.retentionCheckbox];
                    const retentionTextAnswer =
                        (item.value as RetentionJsonContentType)[RetentionInputType.retentionText] ?? "";
                    const retentionSingleSelectAnswer = answer[RetentionInputType.retentionSingleSelect] ?? "";
                    content = (
                        <React.Fragment>
                            {retentionCheckboxAnswer && <div>{RetentionCheckboxLabel(question)}</div>}
                            {retentionTextAnswer && (
                                <div>
                                    {retentionTextAnswer} {retentionSingleSelectAnswer}
                                </div>
                            )}
                        </React.Fragment>
                    );
                    break;
                }
                default: {
                    let answer = (item.value as unknown as NonJsonContentType) ?? "";
                    if (typeof answer !== "string" && answer?.length > 0) {
                        answer = answer.join("; ");
                    }
                    content = <React.Fragment>{answer}</React.Fragment>;
                    const questionAnswerFlaggedForPrivacyAlert = question.choices?.filter(
                        (choice: QuestionChoice): boolean =>
                            choice.privacyAlertContent ? answer?.indexOf(choice.value) !== -1 : false
                    );
                    if (questionAnswerFlaggedForPrivacyAlert?.length > 0) {
                        const privacyRiskAlert: JSX.Element = (
                            <AlertContainer>
                                <Alert
                                    key={`privacy-alert-${question.id}`}
                                    data-testid={SUMMARY_REVIEW_STEP.PRIVACY_ALERT_CONTAINER}
                                    type={"warning"}
                                    header={PRIVACY_ALERT_HEADER}
                                >
                                    {questionAnswerFlaggedForPrivacyAlert?.map(
                                        (choice: QuestionChoice, index): JSX.Element => (
                                            <React.Fragment key={`question-flagged-privacy-${index}`}>
                                                {choice.privacyAlertContent &&
                                                    safeHTMLReactParser(choice.privacyAlertContent)}
                                            </React.Fragment>
                                        )
                                    )}
                                </Alert>
                            </AlertContainer>
                        );
                        content = (
                            <React.Fragment>
                                {answer}
                                {privacyRiskAlert}
                            </React.Fragment>
                        );
                    }
                }
            }
            summary.push(
                <React.Fragment key={`summary-${question.id}`}>
                    <Box variant="strong">{safeHTMLReactParser(question.content)}</Box>
                    <FormField
                        data-testid={`${SUMMARY_REVIEW_STEP.QUESTION_BLOCK}-${question.id}`}
                        errorText={
                            question.tags.includes(QuestionTag.required) && !hasAnswer
                                ? INCOMPLETE_WIZARD_FIELD_ERROR
                                : ""
                        }
                    >
                        {content}
                    </FormField>
                    <br />
                </React.Fragment>
            );
        }

        const childQuestionsIds = childIdMap[question.id] ?? [];
        childQuestionsIds.forEach((questionId): void => {
            if (questionsIdMap[questionId].answerWithQuestion.question.title.length === 0) {
                const subQuestionContent = renderSummary(questionsIdMap[questionId]);
                if (subQuestionContent) {
                    summary.push(...subQuestionContent);
                }
            }
        });

        return summary;
    };

    const summaries: JSX.Element[] = [];
    if (!isValidSubmit) {
        summaries.push(
            <AlertContainer key={"incomplete-data-store-message"}>
                <Alert data-testid={SUMMARY_REVIEW_STEP.ALERT_CONTAINER} header={ALERT_HEADER} type={"warning"}>
                    Unless all required questions are answered, your application can not be submitted for review.
                    {"\n"}
                    Your Data store and application can continue to be saved as a draft.
                </Alert>
            </AlertContainer>
        );
    }

    let stepQuestionsOffset = 0;

    if (!hasTafLayout) {
        summaries.push(
            renderSummaryBlock(
                "Data store information",
                stepQuestionsOffset,
                renderDataStorageSummary(),
                canEditDataStore
            )
        );
        stepQuestionsOffset = 1;
    }

    stepQuestions.forEach((item, index): void => {
        const question = item.answerWithQuestion.question;
        if (item.isApplicable) {
            const summary = (
                <React.Fragment key={index}>
                    {renderSummary(item).map((content): JSX.Element => content)}
                </React.Fragment>
            );
            summaries.push(renderSummaryBlock(question.title, index + stepQuestionsOffset, summary, canEditDataStore));
        }
    });

    return <div data-testid={SUMMARY_REVIEW_STEP.ROOT}>{summaries.map((summary): JSX.Element => summary)}</div>;
};

export default SummaryBuilder;
