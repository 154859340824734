import React from "react";
import { Box, ExpandableSection, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react-v3";
import { TEST_IDS } from "shared/taf-view";
import TwoStageRender from "src/components/decorators/TwoStageRender";
import { useFetchIds } from "../hooks";
import { LineagePredictionsContextProvider, LineagePredictionsTable } from "../components";
import { useContainerExpandedState } from "src/components/survey/ApplicationOverview/hooks";
const { FINANCIAL_GRE } = TEST_IDS;

/**
 * Primary Container Component for the "Guided Financial Reviewer Experience" feature. It is responsible for rendering
 * the predictions table when expanded and provides the bindle/aaa Ids to the ConnectedFinancialSystemsContext hook.
 **/
export const LineageFindings = React.memo((): JSX.Element => {
    const { isContainerExpanded, hasContainerEverExpanded, toggleIsExpanded } = useContainerExpandedState();
    const nodeIds = useFetchIds();

    return (
        <div data-testid={FINANCIAL_GRE.ROOT}>
            <ExpandableSection
                expanded={isContainerExpanded}
                variant={"container"}
                header={<Header variant={"h2"}>Financial Relevance Lineage Findings</Header>}
                onChange={toggleIsExpanded}
            >
                {
                    // Delay rendering contents until the first time the container is expanded in order to save
                    // on overall render time in the UI thread during page load
                    hasContainerEverExpanded && (
                        <TwoStageRender
                            renderInProgress={(): React.ReactNode => (
                                <Box variant={"div"} textAlign={"center"}>
                                    <Spinner size={"big"} />
                                </Box>
                            )}
                            renderComplete={(): React.ReactNode => (
                                <LineagePredictionsContextProvider nodeIds={nodeIds}>
                                    <div data-testid={FINANCIAL_GRE.MAIN_CONTENT}>
                                        <SpaceBetween size={"l"}>
                                            <LineagePredictionsTable />
                                        </SpaceBetween>
                                    </div>
                                </LineagePredictionsContextProvider>
                            )}
                        />
                    )
                }
            </ExpandableSection>
        </div>
    );
});
