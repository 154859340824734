import React from "react";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import { DataStoreAnswerKey, DataStoresAccessorReadonly } from "src/answers_legacy";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { Button, FormField, Grid } from "@amzn/awsui-components-react-v3/polaris";
import { HeaderItem } from "src/components/survey/DataStoreInfo/DataStoreTable/styled";
import { useIsAuthorized, UserAction } from "src/permissions";
import { DELETION_OBLIGATIONS_SHORT_ID } from "src/components/TAF/TAFDetails/constants";
import { TableProps } from "@amzn/awsui-components-react-v3";
import { chooseColumnWidthByTitle } from "src/components/survey/DataStoreInfo/DataStoreTable/table-utils";

interface TAFDataStoresProps {
    isLoading: boolean;
    openWizard: (lookupId: number) => void;
    dataStores: DataStoreResponse[];
    dataStoreAccessors: DataStoresAccessorReadonly[] | null;
}

interface DataStoreItem {
    dataStoreId: number;
    [k: string]: string | number | undefined | null;
}

function TAFDataStores(props: TAFDataStoresProps): JSX.Element | null {
    const { dataStores, dataStoreAccessors, isLoading, openWizard } = props;
    const canEditDataStore = useIsAuthorized(UserAction.editApplication);

    if (!dataStoreAccessors) {
        return null;
    }

    const selectDataStoreResponseByAccessor = (accessor: DataStoresAccessorReadonly): DataStoreResponse | undefined => {
        const [answer] = accessor.answers;
        return dataStores.find(
            (dataStore): boolean => dataStore.id === answer.answerWithQuestion.answer[DataStoreAnswerKey.dataStoreId]
        );
    };

    const rowItems = dataStoreAccessors.reduce<DataStoreItem[]>((rowItems, accessor): DataStoreItem[] => {
        const dataStoreResponse = selectDataStoreResponseByAccessor(accessor);

        if (dataStoreResponse) {
            const item: DataStoreItem = {
                dataStoreId: dataStoreResponse.id as number,
                name: dataStoreResponse?.name,
            };

            accessor.answers.forEach((answer): void => {
                item[answer.question.shortId] = answer.value && answer.value?.toString();
            });

            rowItems.push(item);
        }

        return rowItems;
    }, []);

    const actionDefinitions: TableProps.ColumnDefinition<DataStoreItem>[] = [
        {
            id: "edit",
            minWidth: 150,
            header: <span />,
            cell: (item): JSX.Element => (
                <Grid>
                    <Button onClick={(): void => openWizard(item.dataStoreId)}>
                        {canEditDataStore ? "Edit" : "View"}
                    </Button>
                    <FormField
                        errorText={
                            !dataStoreAccessors.find(
                                (dataStoreAccessor): boolean =>
                                    dataStoreAccessor.answers[0].answerWithQuestion.answer[
                                        DataStoreAnswerKey.dataStoreId
                                    ] === item.dataStoreId
                            )?.isValid
                        }
                    />
                </Grid>
            ),
        },
    ];

    const dataStoreResponseDefinitions: TableProps.ColumnDefinition<DataStoreItem>[] = [
        {
            id: "name",
            header: "Name",
            cell: (e): string => e.name as string,
        },
    ];

    const columnDefinitions: TableProps.ColumnDefinition<DataStoreItem>[] = [
        ...actionDefinitions,
        ...dataStoreResponseDefinitions,
        ...dataStoreAccessors[0].answers
            .filter((answer): boolean => answer.question.shortId !== DELETION_OBLIGATIONS_SHORT_ID)
            .map((answer): TableProps.ColumnDefinition<DataStoreItem> => {
                const title = answer.question.content;
                return {
                    id: answer.question.shortId,
                    width: chooseColumnWidthByTitle(title),
                    header: (
                        <HeaderItem title={title}>
                            <b>{title}</b>
                        </HeaderItem>
                    ),
                    cell: (e: DataStoreItem): string => e[answer.question.shortId] as string,
                };
            }),
    ];

    return (
        <DASTable<DataStoreItem>
            id={"taf-data-stores-table"}
            tableName={"Data Stores in TAF Review"}
            filterProps={{
                type: DASTableFilterType.propertyFilter,
                filterKeys: ["name"],
                placeholder: "Filter data stores",
            }}
            isLoading={isLoading}
            isResizable={true}
            isStickyHeader={true}
            columnDefinitions={columnDefinitions}
            emptyTextPrefix={"No data stores"}
            rowItems={rowItems}
        />
    );
}

export { TAFDataStores };
