import { ApplicationFromServer } from "src/components/survey/hooks/useFetchApplicationOnMount";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { useParams } from "react-router-dom";
import { kaleUrls } from "src/util/Urls";
import { UserRole } from "src/permissions";
import { useMessageBannerActions } from "src/components/MessageBannerActions";
import { MessageType } from "src/components/survey/KaleRoutes";
import { Box, Link } from "@amzn/awsui-components-react-v3";

interface AccessControlProps {
    applicationName: string;
    reviewId: string;
}

export interface AccessControlFetchResources {
    application: ApplicationFromServer;
    userRole: UserRole;
    isLoading: boolean;
}

export const useFetchApplicationOnMount = (): AccessControlFetchResources => {
    const {
        service: {
            kaleAppService: { getLatestReviewID, fetchAppByReviewId, fetchUserRole },
        },
    } = useContext(KaleContext);
    const { displayMessage } = useMessageBannerActions();
    const params = useParams<AccessControlProps>();
    const appName = decodeURIComponent(params.applicationName);
    const reviewIdFromURL = params.reviewId;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [application, setApplication] = useState<ApplicationFromServer>(null);
    const [userRole, setUserRole] = useState<UserRole>(UserRole.readOnly);

    const getRedirectMessage = useCallback(
        (latestReviewId: string | number) => {
            const url = kaleUrls.editKaleAccessControlRecordUrl(appName, `${latestReviewId}`);
            return (
                <Box variant={"p"} color="inherit">
                    You are viewing an archived revision of this review.{" "}
                    <Link href={url} variant={"primary"} color={"inverted"}>
                        Click here
                    </Link>{" "}
                    to view this page for the latest revision of this review
                </Box>
            );
        },
        [appName]
    );

    const deps = { getLatestReviewID, fetchAppByReviewId, fetchUserRole, appName, displayMessage, getRedirectMessage };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(() => {
        (async function IIFE(): Promise<void> {
            const {
                getLatestReviewID,
                fetchAppByReviewId,
                fetchUserRole,
                appName,
                displayMessage,
                getRedirectMessage,
            } = depsRef.current;
            try {
                const reviewPromise = getLatestReviewID(appName);
                const userRolePromise = fetchUserRole(appName);
                const [latestReviewId, userRole] = await Promise.all([reviewPromise, userRolePromise]);

                if (latestReviewId !== parseInt(reviewIdFromURL)) {
                    displayMessage(MessageType.warning, getRedirectMessage(latestReviewId));
                }
                setApplication(await fetchAppByReviewId(appName, reviewIdFromURL));
                setUserRole(userRole);
            } catch (e) {
                console.error(e);
                displayMessage(MessageType.error, (e as Error).message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [reviewIdFromURL]);

    return { application, userRole, isLoading };
};
