import React from "react";
import { Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { TestIds } from "shared/dsar-deletion-hardcoded-info-blurbs";

const AEDUPreqs = (): JSX.Element => {
    return (
        <React.Fragment>
            <h2>Start planning your onboarding</h2>
            <SpaceBetween size={"m"}>
                <body>
                    Given the pre-requisite checks, required profiles creation, implementation/validation for your team,
                    and UAT validations with AEDU, please expect <u>up to one month for onboarding</u> to production
                    from the time of initiation. Note that if your datastore supports the China marketplace, you will
                    need to onboard to the China-specific AEDU Stack.
                </body>
                <Link
                    external
                    href={"https://w.amazon.com/bin/view/CDOPrivacy/Onboarding/DSAR/AEDU#"}
                    data-testid={TestIds.AEDU_ONBOARD_PRE_REQUS_LINK}
                >
                    View AEDU onboarding prerequisites
                </Link>
            </SpaceBetween>
        </React.Fragment>
    );
};

export default AEDUPreqs;
