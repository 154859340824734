/* eslint-disable max-len*/
import { Multiselect } from "@amzn/awsui-components-react-v3/polaris";
import React, { useContext, useMemo } from "react";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/index";
import { AnswerBase, HookAnswer, QuestionBase } from "src/answers_legacy";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { PLACEHOLDER_TEXT } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/constants";
import { useMultiSelectExclusiveOptionBehaviors } from "src/components/TableDetails/CommonQuestionHooks/useMultiSelectExclusiveOptionBehaviors";
import {
    GroupedQuestionOptions,
    groupQuestionOptions,
    makeOptionFromChoice,
    QuestionOption,
} from "src/services/dynamic-questions";
/* eslint-enable max-len*/

export const TEST_IDS = {
    MULTISELECT: "table-details--form-questions-pane--form-questions--multiselect",
};

type MultiselectQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
} & FormQuestionForwardingProps;

/**
 * Custom Component representing the Table Form MultiSelectQuestion. Adds custom behaviors involving any QuestionChoice
 * object configured with the `isExclusive` property.
 */
export const MultiselectQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    props: MultiselectQuestionProps<QType, AType>
): JSX.Element => {
    const {
        questionAnswer: {
            value,
            setValue: setValues,
            question: { choices },
        },
    } = props;

    const values = (value as string[]) ?? [];
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;

    const options = useMemo((): QuestionOption[] => choices.map(makeOptionFromChoice), [choices]);
    const { enhancedOptions, onChange } = useMultiSelectExclusiveOptionBehaviors(options, values);
    const selectedOptions = enhancedOptions.filter((option: any): boolean => values?.includes(option.value!) ?? false);
    const groupedOptions = useMemo(
        (): GroupedQuestionOptions => groupQuestionOptions(enhancedOptions),
        [enhancedOptions]
    );

    return (
        <Multiselect
            data-testid={TEST_IDS.MULTISELECT}
            disabled={isReadonly}
            selectedOptions={selectedOptions}
            options={groupedOptions}
            placeholder={PLACEHOLDER_TEXT}
            onChange={({ detail: { selectedOptions } }): void => {
                // Getting the modified selected options from the onChange output of custom hook
                const enhancedSelectedOptions = onChange(selectedOptions);
                // Update states
                setValues(enhancedSelectedOptions);
            }}
            filteringType={"auto"}
            keepOpen={true}
        />
    );
};
