import React, { ReactElement } from "react";
import { Box, Popover, PopoverProps, Link } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";
import safeHTMLReactParser from "src/util/safeHTMLReactParser";

const StyledPopoverText = styled.span`
    font-size: initial;
`;

function getTextElement(
    text: string,
    isLink: boolean,
    isBold: boolean,
    isHelptext: boolean,
    templateValue?: string,
    cssClass?: string
): JSX.Element {
    const parsedHTML = safeHTMLReactParser(text);

    return (
        <span key={`${text}-${Math.random()}`} className={cssClass}>
            {!isLink && !isBold && isHelptext && (
                <Popover
                    size="large"
                    position="top"
                    triggerType="text"
                    dismissButton={false}
                    content={<span>{safeHTMLReactParser(templateValue ?? "")}</span>}
                >
                    <StyledPopoverText>{parsedHTML}</StyledPopoverText>
                </Popover>
            )}
            {isLink && !isBold && !isHelptext && (
                <Link target={"_blank"} href={templateValue}>
                    {parsedHTML}
                </Link>
            )}
            {!isLink && isBold && !isHelptext && <b>{parsedHTML}</b>}
            {!isLink && !isBold && !isHelptext && parsedHTML}
        </span>
    );
}

function getAllMatches(source: string, regex: RegExp): string[] {
    const matches: string[] = [];
    source.replace(regex, function (...rest): string {
        matches.push(rest[1]);
        return rest[0];
    });
    return matches;
}

// This function will add new lines if \n is present in the string or will make any words inside <link> and </link>
// into HTML link, or words inside <bold> and </bold> into bold text. For links, the link has to provided in the same
// order as they appear in the input string. In case if you want to apply any additional style to <span> then it can be
// applied through cssClass props. Please note: To ensure that redirectLinksOrHelptexts line up with their expected
// <link> you may need to add "" to the array of links if a <bold> element is in between two <link> elements
export function applyTemplateToText(text?: string, templateValues?: string[], cssClass?: string): JSX.Element {
    let iteratorIndex = -1;
    const element = text?.split("\n").map((line: string): JSX.Element[] => {
        const matchingRegexPattern = new RegExp(
            "<link>(.*?)</link>|<bold>(.*?)</bold>|<helptext>(.*?)</helptext>",
            "ig"
        );
        const linkRegex = new RegExp("<link>(.*?)</link>", "ig");
        const links = getAllMatches(line, linkRegex);
        const words = line.split(matchingRegexPattern);
        const boldRegex = new RegExp("<bold>(.*?)</bold>", "ig");
        const bolds = getAllMatches(line, boldRegex);
        const helptextRegex = new RegExp("<helptext>(.*?)</helptext>", "ig");
        const helptexts = getAllMatches(line, helptextRegex);

        const spanList = words.map((word = ""): JSX.Element => {
            if (links.includes(word) && templateValues) {
                iteratorIndex++;
                return getTextElement(word, true, false, false, templateValues[iteratorIndex], cssClass);
            }
            if (bolds.includes(word)) {
                iteratorIndex++;
                return getTextElement(word, false, true, false, "", cssClass);
            }
            if (helptexts.includes(word) && templateValues) {
                iteratorIndex++;
                return getTextElement(word, false, false, true, templateValues[iteratorIndex], cssClass);
            }
            return getTextElement(word, false, false, false, "", cssClass);
        });
        return [
            <React.Fragment key={`${line}-${Math.random()}`}>
                {spanList}
                <br />
            </React.Fragment>,
        ];
    });

    if (cssClass) {
        return (
            <Box variant="p" className={cssClass}>
                {element}
            </Box>
        );
    }

    return <div>{element}</div>;
}

export const KaleHTMLParser = (content: string): React.ReactNode => {
    return safeHTMLReactParser(content, {
        replace: (domNode): JSX.Element | void => {
            if (domNode.name === "popover" && domNode.attribs) {
                return (
                    <Popover
                        size={domNode.attribs.size as PopoverProps.Size}
                        position={domNode.attribs.position as PopoverProps.Position}
                        triggerType={domNode.attribs.triggertype as PopoverProps.TriggerType}
                        dismissButton={Boolean(domNode.attribs.dismissbutton)}
                        content={safeHTMLReactParser(domNode.attribs.content)}
                    >
                        {domNode.children?.map(
                            (children): React.ReactNode => (
                                <StyledPopoverText key={Math.random()}>{children.data}</StyledPopoverText>
                            )
                        )}
                    </Popover>
                );
            }
        },
    });
};

export const getTextContent = (node: React.ReactNode): string => {
    if (node instanceof Array) {
        return node.map(getTextContent).join("");
    }
    switch (typeof node) {
        case "string":
            return node;
        case "boolean":
        case "number":
        case "bigint":
            return `${node}`;
        case "object":
            return getTextContent((node as ReactElement).props.children);
        default:
            return "";
    }
};
