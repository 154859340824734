import React from "react";
import { Alert, Icon, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import {
    TableFieldAnswer,
    TableFieldAnswerWithQuestion,
    TableFieldQuestion,
    useKaleTableFields,
    useKaleTableFieldSidebar,
    useKaleTableFieldSidebarPayload,
} from "src/answers_legacy";
import { useKaleTableFieldsNav } from "src/answers_legacy/hooks/helpers";
import { FieldAnswer } from "src/services/KaleTablesService";
import SidebarContent from "src/components/TableDetails/TableFieldSidebar/SidebarContent";
import SidebarLayout from "src/components/layout/SidebarLayout";
import {
    ArrowButton,
    FieldHeader,
    HeaderItem,
    ActionStripe,
    FooterActions,
    StyledButton,
    TableHeader,
} from "src/components/TableDetails/TableFieldSidebar/styled";
import { ProvideMultiPurposeModal } from "src/components/TableDetails/MultiPurposeModal";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";

interface CancelCallback {
    (): void;
}

interface SaveCallback {
    (answers: TableFieldAnswer[]): void;
}

export interface TableFieldSidebarProps {
    tableName: string;
    fieldId: number | null;
    fieldName: string;
    loading: boolean;
    error: boolean;
    complianceTypeVisibility: ComplianceTypeVisibility;
    autoSizeTextareas: {
        toggle: React.ReactNode;
        shouldAutoSizeTextareas: boolean;
    };
    onSave: SaveCallback;
    onCancel: CancelCallback;
}

const TableFieldSidebar = (props: TableFieldSidebarProps): JSX.Element => {
    const [, { resetAndAddAnswers }] = useKaleTableFieldSidebar();
    const resultPayload = useKaleTableFieldSidebarPayload();

    const {
        fieldId,
        loading,
        error,
        complianceTypeVisibility,
        onSave,
        onCancel,
        tableName,
        fieldName,
        autoSizeTextareas: { toggle, shouldAutoSizeTextareas },
    } = props;

    const [fields] = useKaleTableFields();
    const { getNext, getPrevious, hasNext, hasPrevious, isLast } = useKaleTableFieldsNav(fields);

    const currentTableField = fields.find(
        (field): boolean => (field.answers[0].answerWithQuestion.answer as FieldAnswer).fieldId === fieldId
    );

    const loadNewSidebarFieldState = (accessors: TableFieldAnswerWithQuestion[]): void => {
        const answers = accessors?.map((accessor: any): TableFieldAnswer => accessor.answer);
        const questions = accessors?.map((accessor: any): TableFieldQuestion => accessor.question);

        if (answers && questions) {
            resetAndAddAnswers(answers, questions, answers[0].fieldId);
        }
    };

    const lastItem = isLast(currentTableField);

    const header = (
        <SpaceBetween size={"xs"}>
            <HeaderItem>
                <ArrowButton
                    visible={hasPrevious(currentTableField)}
                    direction="previous"
                    onClick={(): void => {
                        const accessors = getPrevious(currentTableField)?.answers.map(
                            (accessor: any): TableFieldAnswerWithQuestion => accessor.answerWithQuestion
                        );
                        if (accessors) {
                            loadNewSidebarFieldState(accessors);
                        }
                    }}
                >
                    <Icon name="angle-up" />
                </ArrowButton>
                <FieldHeader>{`Field: ${fieldName}`}</FieldHeader>
                <ArrowButton
                    visible={hasNext(currentTableField)}
                    direction="next"
                    onClick={(): void => {
                        const accessors = getNext(currentTableField)?.answers.map(
                            (accessor: any): TableFieldAnswerWithQuestion => accessor.answerWithQuestion
                        );
                        if (accessors) {
                            loadNewSidebarFieldState(accessors);
                        }
                    }}
                >
                    <Icon name="angle-down" />
                </ArrowButton>
            </HeaderItem>
            <HeaderItem>
                <TableHeader>{`Table: ${tableName}`}</TableHeader>
            </HeaderItem>
            <HeaderItem>{toggle}</HeaderItem>
        </SpaceBetween>
    );

    const footer = (
        <ActionStripe>
            <FooterActions direction={"horizontal"} size={"s"}>
                <StyledButton variant={"link"} onClick={(): void => onCancel()}>
                    Cancel
                </StyledButton>
                <StyledButton
                    variant={"primary"}
                    loading={loading}
                    onClick={(): void => {
                        onSave(resultPayload);
                        onCancel();
                    }}
                >
                    Save &amp; close
                </StyledButton>
                <StyledButton
                    variant={"primary"}
                    disabled={lastItem}
                    loading={loading}
                    onClick={(): void => {
                        onSave(resultPayload);
                        const accessors = getNext(currentTableField)?.answers.map(
                            (accessor: any): TableFieldAnswerWithQuestion => accessor.answerWithQuestion
                        );
                        if (accessors) {
                            loadNewSidebarFieldState(accessors);
                        }
                    }}
                >
                    Save &amp; go to next field
                </StyledButton>
            </FooterActions>
        </ActionStripe>
    );

    return (
        <SidebarLayout itemKey={fieldId} header={header} footer={footer}>
            <React.Fragment>
                {error && <Alert type={"error"}>An unexpected error occurred with your request.</Alert>}
                <ProvideMultiPurposeModal>
                    {fieldId && (
                        <SidebarContent
                            key={fieldId}
                            complianceTypeVisibility={complianceTypeVisibility}
                            shouldAutoSizeTextareas={shouldAutoSizeTextareas}
                        />
                    )}
                </ProvideMultiPurposeModal>
            </React.Fragment>
        </SidebarLayout>
    );
};

export default TableFieldSidebar;
