import { MultiselectProps, SelectProps } from "@amzn/awsui-components-react-v3";
import { useContext, useEffect, useRef, useState } from "react";
import KaleContext from "src/components/KaleContext";
import { AAAResource, KaleApplicationService } from "src/services/KaleApplicationService";
import { usePrevious } from "src/util/usePrevious";
import { sortBy, isEqual } from "lodash";

interface FetchAaaResponse {
    isLoadingAAAIds: boolean;
    aaaIdsOptions: MultiselectProps.Option[];
    aaaIdsStatus: MultiselectProps["statusType"];
    removedBindleNames: string[];
}

export const useFetchAAAIds = (
    controlBindle: SelectProps.Option | null,
    relatedBindles: SelectProps.Option[]
): FetchAaaResponse => {
    const kaleContext = useContext(KaleContext);
    const { kaleAppService } = kaleContext.service;
    const kaleAppServiceRef = useRef<KaleApplicationService>(kaleAppService);
    kaleAppServiceRef.current = kaleAppService;

    const [isLoadingAAAIds, setIsLoadingAAAIds] = useState<boolean>(false);
    const [aaaIdsOptions, setAaaIdsOptions] = useState<MultiselectProps.Option[]>([]);
    const [aaaIdsStatus, setAaaIdsStatus] = useState<MultiselectProps["statusType"]>("pending");
    const [removedBindleNames, setRemovedBindleNames] = useState<string[]>([]);

    const prevControlBindle = usePrevious<SelectProps.Option | null>(controlBindle);
    const prevRelatedBindles = usePrevious<SelectProps.Option[]>(relatedBindles);

    useEffect((): void => {
        // Removing any selected AAA ids if user removed the corresponding control/related bindle
        const newBindleNames = [
            ...(Boolean(controlBindle?.label) ? [controlBindle?.label as string] : []),
            ...(relatedBindles
                .filter((bindle): boolean => bindle.label !== "")
                .map((bindle): string => bindle.label ?? "") ?? []),
        ];
        const prevBindleNames = [
            ...(Boolean(prevControlBindle?.label) ? [prevControlBindle?.label as string] : []),
            ...(prevRelatedBindles
                ?.filter((bindle): boolean => bindle.label !== "")
                .map((bindle): string => bindle.label ?? "") ?? []),
        ];
        if (isEqual(sortBy(newBindleNames), sortBy(prevBindleNames))) {
            return;
        }

        setIsLoadingAAAIds(true);
        setAaaIdsStatus("loading");
        const removedBindleNames: string[] = prevBindleNames.filter((oldName: string): boolean => {
            return !newBindleNames.includes(oldName);
        });
        setRemovedBindleNames(removedBindleNames);

        if (!Boolean(controlBindle) && !Boolean(relatedBindles.length)) {
            setIsLoadingAAAIds(false);
            setAaaIdsStatus("finished");
            return;
        }
        const { fetchAAAIds } = kaleAppServiceRef.current;
        const uniqueBindleNames: Set<string> = new Set(newBindleNames);
        fetchAAAIds(uniqueBindleNames)
            .then((responses: AAAResource[]): void => {
                const aaaIdsOptions = responses.map((app: AAAResource): MultiselectProps.Option => {
                    return {
                        value: app.resourceId,
                        label: app.resourceId,
                        description: app.resourceName,
                    };
                });
                setAaaIdsOptions(aaaIdsOptions);
                setAaaIdsStatus("finished");
            })
            .catch((error: Error): void => {
                console.error(error);
                setAaaIdsStatus("error");
            })
            .then((): void => {
                setIsLoadingAAAIds(false);
            });
    }, [controlBindle, prevControlBindle, prevRelatedBindles, relatedBindles]);

    return { isLoadingAAAIds, aaaIdsOptions, aaaIdsStatus, removedBindleNames };
};
