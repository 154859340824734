import { Button, FormField, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useMemo } from "react";
import { AnswerBase, HookAnswer, QuestionBase } from "src/answers_legacy";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/types";
import { TextareaQuestion } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/TextareaQuestion";
import { ShowModalOptions, useMultiPurposeModal } from "src/components/TableDetails/MultiPurposeModal";
import {
    lookupExampleValuesContentTypeAnswer,
    FormattedExampleValues,
    formatExampleValuesAsContentType,
} from "src/components/TableDetails/TableDetailsPage/synthetic-questions/example-values-question-helpers";
import { REQUIRED_MESSAGE } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/constants";
import { ErrorText } from "src/util/Error";

type ExampleValuesQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
    siblingQuestionAnswers: HookAnswer<QType, AType>[];
    shouldFitHeightToContent: boolean;
} & FormQuestionForwardingProps;

export const ExampleValuesQuestion = ({
    questionAnswer,
    siblingQuestionAnswers,
    shouldFitHeightToContent,
}: ExampleValuesQuestionProps<any, any>): JSX.Element => {
    const {
        isValid: isValidProp,
        question: { content, subtext },
    } = questionAnswer;
    const exampleValuesAnswer = questionAnswer.value as string;
    const { showModal } = useMultiPurposeModal();

    const contentTypeAnswer = lookupExampleValuesContentTypeAnswer(siblingQuestionAnswers);
    const { formattedValues, formatError } = useMemo(
        (): FormattedExampleValues => formatExampleValuesAsContentType(exampleValuesAnswer, contentTypeAnswer),
        [exampleValuesAnswer, contentTypeAnswer]
    );

    const showModalCb = (): void => {
        const modalOptions: ShowModalOptions = {
            header: contentTypeAnswer,
            body: formatError || formattedValues,
        };
        showModal(modalOptions);
    };

    const isContentValid = isValidProp && !formatError;

    return (
        <FormField label={content} description={subtext} errorText={!isValidProp && REQUIRED_MESSAGE}>
            <SpaceBetween size={"xxxs"} direction={"vertical"}>
                <TextareaQuestion
                    shouldFitHeightToContent={shouldFitHeightToContent}
                    questionAnswer={{
                        ...questionAnswer,
                        isValid: isContentValid,
                    }}
                />
                {formatError ? (
                    <ErrorText>
                        <Button iconName={"status-negative"} onClick={showModalCb}>
                            Error Detail
                        </Button>
                    </ErrorText>
                ) : (
                    <Button iconName={"expand"} onClick={showModalCb}>
                        View
                    </Button>
                )}
            </SpaceBetween>
        </FormField>
    );
};
