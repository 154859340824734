import { IncrementalSaveResponse } from "src/services/IncrementalSave/types";

export const DEFAULT_INCREMENT_SAVE: IncrementalSaveResponse = {
    valueDictionary: {},
    fields: [],
};

export const DEFAULT_INCREMENTAL_SAVE_REVIEW_ID_BODY = "0";
export const DEFAULT_CAMPAIGN_SAVE_REVIEW_ID_SLUG = 0;
export const UNSUPPORTED_DISPLAY_TYPE = "Unable to deserialize null to empty due to unsupported display type.";
// eslint-disable-next-line max-len
export const VALUE_DICTIONARY_NULL_VALUE_WITH_NO_FIELD =
    "ValueDictionary values with null that also do not have the same key within `fields` has yet to be accounted for.";
export const ERROR_SAVING_VALUE_DICTIONARY = "Error saving value dictionary:";
