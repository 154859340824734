import { Link } from "@amzn/awsui-components-react-v3";
import React from "react";
import { useHistory } from "react-router-dom";
import { kaleUrls } from "src/util/Urls";
import { useAppParamsFromRoute } from "src/components/survey/hooks/useAppParamsFromRoute";

interface Props {
    appName: string;
    "data-testid"?: string;
}

export const AuditHistoryLink = (props: Props): JSX.Element => {
    const { appName, "data-testid": dataTestId } = props;
    const { reviewId } = useAppParamsFromRoute();
    const history = useHistory();

    return (
        <Link
            data-testid={dataTestId}
            onFollow={(event): void => {
                event.preventDefault();
                const encodedAppName = encodeURIComponent(appName);
                const path = kaleUrls.auditHistoryUrl(encodedAppName, reviewId);
                const queryParams = `?entityName=application&entityId=${encodedAppName}`;
                history.push(path + queryParams);
            }}
        >
            {"View log"}
        </Link>
    );
};
