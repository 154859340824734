import { Input } from "@amzn/awsui-components-react-v3";
import React, { useState } from "react";
import styled from "styled-components";
import { BaseInputProps } from "@amzn/awsui-components-react-v3/uxdg/input/interfaces";

interface DataStoreInputProps extends Omit<BaseInputProps, "onBlur"> {
    onBlur: (newValue: string) => void;
    value: string;
    disabled?: boolean;
}

const InstanceInput = styled(Input)`
    margin-bottom: 5px !important;
`;

const DataStoreInstance = (props: DataStoreInputProps): JSX.Element => {
    const { value: initialValue, disabled = false, onBlur: onBlurCb, ...rest } = props;

    // The current local value of the data store instance name input.
    // The data store instance input updates parent components with its local value during its Blur event.
    const [localDataStoreInstanceValue, setLocalDataStoreInstanceValue] = useState<string>(initialValue);

    return (
        <InstanceInput
            {...rest}
            // Always valid treatment to support multiple instance inputs (required indicator persists).
            invalid={false}
            value={localDataStoreInstanceValue}
            onBlur={(): void => onBlurCb(localDataStoreInstanceValue)}
            disabled={disabled}
            onChange={(e): void => {
                setLocalDataStoreInstanceValue(e.detail.value);
            }}
        />
    );
};

export default DataStoreInstance;
