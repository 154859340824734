import React from "react";
import { DefaultSurveyResponse, SurveyResponse } from "src/components/survey/SurveyFormModel";

export interface AccessControlContextProps {
    application: SurveyResponse;
    isReadOnly: boolean;
    setIsHelpPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setHelpPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export const AccessControlContext = React.createContext<AccessControlContextProps>({
    application: DefaultSurveyResponse(),
    isReadOnly: false,
    setIsHelpPanelOpen: () => {},
    setHelpPanelContent: () => {},
});

export const AccessControlContextProvider = AccessControlContext.Provider;

// Order of the steps are determined by this enum value.
// Adding a step in between or changing the order can be done by modifying these enum value
export enum AccessControlStep {
    RELATED_BINDLE = 0,
    AAA_ID = 1,
    Anvil = 2,
    Explanation = 3,
}
