import { CommentFromServer } from "src/services/NodeKaleApplicationService";

export enum FrontEndCommentType {
    ConnectedComment = "ConnectedComment",
    DraftComment = "DraftComment",
}

export type BaseFrontEndComment = Omit<CommentFromServer, "id" | "parentId"> & {
    commentType: FrontEndCommentType;
    frontEndId: number;
    frontEndParentId: number | null;
};

// Represents a comment data object that was created and rendered locally to the frontend but does not yet have
// persistence in the backend
export type DraftComment = BaseFrontEndComment & {
    commentType: FrontEndCommentType.DraftComment;
};

// Represents a Proxy comment data object that serves as the bridge to connect a frontend comment to a backend comment
export type ConnectedComment = BaseFrontEndComment & {
    commentType: FrontEndCommentType.ConnectedComment;
    backEndId: number;
    backEndParentId: number | null;
};

// Union type representing the comment data shapes we store and render in the UI
export type FrontEndComment = ConnectedComment | DraftComment;

// A FrontEndComment that will DEFINITELY have a numeric `frontEndParentId`
// defined. This type is used to represent any comment in a CommentThread that
// is not the HeadComment of that parent thread
export type ReplyComment = FrontEndComment & { frontEndParentId: number };

// A FrontEndComment that will DEFINITELY have a null `frontEndParentId`.
// It may also have a `backEndParentId` defined, however if so, it is guaranteed
// to be null since this is a HeadComment. This type is used to represent the
// HeadComment in a CommentThread
export type HeadComment = FrontEndComment & { frontEndParentId: null; backEndParentId?: null };

export interface CommentThread {
    headCommentId: number; // The frontEndId of the CommentThread's head comment
    commentIds: number[];
    lastUpdated: string;
}

export interface CommentThreadGroup {
    groupHeader: string;
    month: number;
    year: number;
    // Collection of each CommentThread's head comment's frontEndId.
    // Each headComment will belong to a different CommentThread in the group.
    headCommentIds: number[];
}
