import { UserRole, UserAction } from "src/permissions";
import { TAF_CAN_PERMISSION_MAPPING } from "./TAF/permissions";
import {
    ApplicationStatus,
    selectAccountingApprovalStatus,
    selectFraudApprovalStatus,
    selectLegalApprovalStatus,
    SurveyResponse,
} from "src/components/survey/SurveyFormModel";
import { hasTAFUrl } from "src/util/Urls";
import { LEGAL_CAN_PERMISSION_MAPPING } from "src/permissions/Legal/permissions";

const selectStatusByFirstMatchedStatus = (
    applicationStatuses: ApplicationStatus[],
    anyStatusQuery: ApplicationStatus[]
): ApplicationStatus | undefined => {
    return applicationStatuses.find(
        (applicationStatus): boolean => anyStatusQuery?.includes(applicationStatus) || false
    );
};

const defaultApplicationStatus = ApplicationStatus.notApplicable;

export enum SurveyType {
    Legal,
    TAF,
}

export const selectSurveyType = (): SurveyType => {
    const isTAF = hasTAFUrl();

    return !isTAF ? SurveyType.Legal : SurveyType.TAF;
};

export const selectResponseApplicationStatusesByRole = (
    response: SurveyResponse,
    role: UserRole
): ApplicationStatus[] => {
    const surveyType = selectSurveyType();

    let applicationStatuses: ApplicationStatus[] = [defaultApplicationStatus];

    switch (surveyType) {
        case SurveyType.Legal: {
            const { status: legalStatus } = selectLegalApprovalStatus(response.appInfo);
            applicationStatuses = [legalStatus];
            break;
        }
        case SurveyType.TAF: {
            const { status: accountingStatus } = selectAccountingApprovalStatus(response.appInfo);
            const { status: fraudStatus } = selectFraudApprovalStatus(response.appInfo);

            switch (role) {
                case UserRole.fraudReviewer: {
                    applicationStatuses = [fraudStatus];
                    break;
                }
                case UserRole.accountingReviewer: {
                    applicationStatuses = [accountingStatus];
                    break;
                }
                case UserRole.admin:
                case UserRole.reviewer:
                case UserRole.serviceOwner: {
                    applicationStatuses = [fraudStatus, accountingStatus];
                    break;
                }
                default: {
                    applicationStatuses = [defaultApplicationStatus];
                }
            }
            break;
        }
    }

    return applicationStatuses;
};

export const selectResponseApplicationStatusByAction = (
    response: SurveyResponse,
    role: UserRole,
    userAction: UserAction
): ApplicationStatus | undefined => {
    const surveyType = selectSurveyType();

    const applicationStatuses: ApplicationStatus[] = selectResponseApplicationStatusesByRole(response, role);

    let anyStatusQuery: ApplicationStatus[] = [defaultApplicationStatus];

    switch (surveyType) {
        case SurveyType.Legal: {
            anyStatusQuery = LEGAL_CAN_PERMISSION_MAPPING[userAction][role];
            break;
        }
        case SurveyType.TAF: {
            anyStatusQuery = TAF_CAN_PERMISSION_MAPPING[userAction][role];
            break;
        }
    }

    return selectStatusByFirstMatchedStatus(applicationStatuses, anyStatusQuery);
};

export const selectApplicationStatusByUserAction = (
    userAction: UserAction,
    surveyResponse?: SurveyResponse,
    userRole?: UserRole
): ApplicationStatus | undefined => {
    if (surveyResponse && userRole) {
        return selectResponseApplicationStatusByAction(surveyResponse, userRole, userAction);
    }

    return undefined;
};
