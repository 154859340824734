import { Response } from "src/answers_legacy/context";
import { ContentValue } from "src/answers_legacy/models/common";

export const isGenericResponseValid = (value: ContentValue | number, required = false): boolean => {
    // test for truthy array, number, and string
    const result =
        typeof value !== "object" || (!!value && value.constructor === Array) || (!!value && typeof value === "object");
    return required
        ? result &&
              ((typeof value === "string" && !!value) ||
                  (!!value && value.constructor === Array && !!value[0]) ||
                  (typeof value === "object" && value !== null && Object.keys(value).length > 0) ||
                  typeof value === "number")
        : result;
};

export const answerIsValid = <T>(responses: Response<T>[]): boolean =>
    responses.length > 0 && responses.every((response): boolean => response.isValid);

export const answerIsValidSave = <T>(responses: Response<T>[]): boolean =>
    responses.length > 0 && responses.every((response): boolean => response.isValidSave);

export const answerIsValidSubmit = <T>(responses: Response<T>[]): boolean =>
    responses.length > 0 && responses.every((response): boolean => response.isValidSubmit);
