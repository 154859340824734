import uuid from "uuid";
import Flashbar, { FlashbarProps } from "@amzn/awsui-components-react-v3/polaris/flashbar";
import React, { useEffect, useState } from "react";
import KaleRoutes, { MessageType } from "src/components/survey/KaleRoutes";
import ScrollToTop from "src/components/survey/ScrollToTop";
import "./Survey.scss";
import styled from "styled-components";
import { WebSocketConnection } from "src/websocket/WebSocketConnection";
import { MessageBannerActionsProvider } from "src/components/MessageBannerActions";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";
import { useLocation } from "react-router-dom";

export const TEST_IDS = {
    FLASH_BAR: "survey-flash-bar",
};

const StyledFlashBar = styled.div`
    width: 100%;
    z-index: 100000;
    position: fixed;
    top: 0px;
`;

const FlashBarMessage = styled.span`
    // Recognize and support newline characters in message text
    white-space: pre-wrap;
`;

const Kale = (): JSX.Element => {
    const location = useLocation();
    const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    const displayMessage = (type: MessageType, message: React.ReactNode): void => {
        // Error messages (string) from backend can have newline or HTML tags which needs to be parsed before rendering
        if (typeof message === "string") {
            message = applyTemplateToText(message);
        }
        const messageId = uuid.v4();
        setItems((prevItems) => [
            ...prevItems,
            {
                type,
                content: <FlashBarMessage>{message}</FlashBarMessage>,
                dismissible: true,
                id: messageId,
                onDismiss: () => setItems((items) => items.filter((item) => item.id !== messageId)),
            },
        ]);
    };

    // Reset Flashbar notifications on page changes (as per Polaris guidelines)
    useEffect(() => {
        setItems([]);
    }, [location.pathname]);

    return (
        <div id={"legalSurvey"}>
            <StyledFlashBar>
                <Flashbar data-testid={TEST_IDS.FLASH_BAR} items={items} />
            </StyledFlashBar>
            <ScrollToTop />
            <WebSocketConnection>
                <MessageBannerActionsProvider value={{ displayMessage }}>
                    <KaleRoutes displayMessage={displayMessage} />
                </MessageBannerActionsProvider>
            </WebSocketConnection>
        </div>
    );
};

export default Kale;
