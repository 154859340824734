import {
    DataStoreAnswer,
    DataStoreAnswerKey,
    DataStoreHookAnswer,
    DataStoresAccessor,
    ReviewAccessor,
    ReviewHookAnswer,
} from "src/answers_legacy";
import { DELETION_OBLIGATIONS_SHORT_ID, ReviewAttributeShortId } from "src/components/TAF/TAFDetails/constants";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { useCallback } from "react";
import { uniq } from "lodash";
import { ReviewResponse, SurveyResponse } from "src/components/survey/SurveyFormModel";
import { ReviewGroupsMap } from "src/services/KaleApplicationService";

export interface TAFDetailsSelectors {
    selectApplicableDataStores: () => DataStoreResponse[];
    selectDataStoreResponseByHookAnswer: (answer: DataStoreHookAnswer) => DataStoreResponse | undefined;
    selectDataStoreResponseByAnswer: (answer: DataStoreAnswer) => DataStoreResponse | undefined;
    selectAssignedReviewerQuestion: (reviewAccessor: ReviewAccessor) => ReviewHookAnswer | undefined;
    selectReview: () => ReviewResponse | undefined;
    selectDeletionObligationQuestions: (dataStoreAccessors: DataStoresAccessor[]) => DataStoreHookAnswer[];
    selectDeletionObligationQuestion: (dataStoreAccessor: DataStoresAccessor) => DataStoreHookAnswer | null;
    selectHasApplicationQuestions: () => boolean;
    selectAssignedReviewGroups: () => ReviewGroupsMap;
}

export const useTAFDetailsSelectors = (props: {
    tafDetailsFromServer: SurveyResponse | null;
    dataStoreAccessors: DataStoresAccessor[];
}): TAFDetailsSelectors => {
    const { tafDetailsFromServer, dataStoreAccessors } = props;

    const selectAssignedReviewerQuestion = (reviewAccessor: ReviewAccessor): ReviewHookAnswer | undefined => {
        return reviewAccessor.answers.find(
            (answer): boolean => answer.question.shortId === ReviewAttributeShortId.assignedReviewGroups
        );
    };

    const selectDeletionObligationQuestions = (dataStoreAccessors: DataStoresAccessor[]): DataStoreHookAnswer[] => {
        return dataStoreAccessors
            .map((dataStoreAccessor): DataStoreHookAnswer[] => {
                return dataStoreAccessor.answers.filter(
                    (answer): boolean => answer.question.shortId === DELETION_OBLIGATIONS_SHORT_ID
                );
            })
            .flat();
    };

    const selectDeletionObligationQuestion = (dataStoreAccessor: DataStoresAccessor): DataStoreHookAnswer | null => {
        return (
            dataStoreAccessor.answers.find((answer): boolean => {
                return answer.question.shortId === DELETION_OBLIGATIONS_SHORT_ID;
            }) ?? null
        );
    };

    const selectApplicableDataStores = (): DataStoreResponse[] => {
        return selectDeletionObligationQuestions(dataStoreAccessors)
            .filter((answer): boolean => {
                const [, , GET_TAF_ADVICE] = answer.question.choices;
                return answer.value === GET_TAF_ADVICE.value;
            })
            .map((answer): DataStoreResponse | undefined => selectDataStoreResponseByHookAnswer(answer))
            .filter((dataStoreResponse): boolean => !!dataStoreResponse) as DataStoreResponse[];
    };

    const selectReview = useCallback((): ReviewResponse | undefined => {
        const { review } = tafDetailsFromServer?.appInfo ?? {};

        return review;
    }, [tafDetailsFromServer]);

    const selectDataStoreResponseByHookAnswer = (answer: DataStoreHookAnswer): DataStoreResponse | undefined => {
        const review = selectReview();
        return review?.dataStores.find(
            (dataStore): boolean => dataStore.id === answer.answerWithQuestion.answer[DataStoreAnswerKey.dataStoreId]
        );
    };

    const selectDataStoreResponseByAnswer = useCallback(
        (answer: DataStoreAnswer): DataStoreResponse | undefined => {
            const review = selectReview();
            return review?.dataStores.find(
                (dataStore): boolean => dataStore.id === answer[DataStoreAnswerKey.dataStoreId]
            );
        },
        [selectReview]
    );

    const selectHasApplicationQuestions = (): boolean => {
        return selectDeletionObligationQuestions(dataStoreAccessors).some((answer): boolean => {
            const [, , GET_TAF_ADVICE] = answer.question.choices;
            return answer.value === GET_TAF_ADVICE.value;
        });
    };

    const selectAssignedReviewGroups = (): ReviewGroupsMap => {
        const review = selectReview();
        const assignedReviewGroups: ReviewGroupsMap = {};

        review?.dataStores.forEach((dataStore): void => {
            dataStore.decisions?.forEach((decision): void => {
                assignedReviewGroups[decision.reviewGroupType] = assignedReviewGroups[decision.reviewGroupType] ?? [];
                assignedReviewGroups[decision.reviewGroupType].push(decision.reviewGroupName);
                assignedReviewGroups[decision.reviewGroupType] = uniq(assignedReviewGroups[decision.reviewGroupType]);
            });
        });

        return assignedReviewGroups;
    };

    return {
        selectAssignedReviewerQuestion,
        selectApplicableDataStores,
        selectDataStoreResponseByAnswer,
        selectDataStoreResponseByHookAnswer,
        selectDeletionObligationQuestions,
        selectDeletionObligationQuestion,
        selectReview,
        selectHasApplicationQuestions,
        selectAssignedReviewGroups,
    };
};
