import {
    ApplicationOverviewState,
    CardNames,
    SummaryCard,
} from "src/components/survey/ApplicationOverview/state/model";

type SummaryCardConfig = SummaryCard["ui"]["icon"];
const PRIVACY_ALERTS_SUMMARY: SummaryCardConfig = {
    name: "status-warning",
    variant: "error",
};

const PERSONAL_DATA_SUMMARY: SummaryCardConfig = {
    name: "unlocked",
    variant: "link",
};

const CARDS_DISPLAY_ORDER: CardNames[] = [CardNames.personalDataSummary, CardNames.privacyAlertsSummary];

const makeInitialSummaryCard = (config: SummaryCardConfig): SummaryCard => ({
    response: null,
    ui: {
        isLoading: false,
        fetchError: "",
        icon: config,
    },
});

export const makeInitialState = (): ApplicationOverviewState => ({
    summaryCards: {
        byCardName: {
            [CardNames.personalDataSummary]: makeInitialSummaryCard(PERSONAL_DATA_SUMMARY),
            [CardNames.privacyAlertsSummary]: makeInitialSummaryCard(PRIVACY_ALERTS_SUMMARY),
        },
        displayOrder: CARDS_DISPLAY_ORDER,
    },
});
