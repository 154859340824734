import { TableIdentifiers } from "src/services/KaleTablesService";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useTableIdentifiersFromRoute = (): TableIdentifiers => {
    const { applicationName: originalName = "", reviewId, dataStoreId, tableId } = useParams<TableIdentifiers>();

    const applicationName = decodeURIComponent(originalName);

    // Just return the same object if the string values haven't changed.
    return useMemo(
        (): TableIdentifiers => ({
            applicationName,
            reviewId,
            dataStoreId,
            tableId,
        }),
        [applicationName, reviewId, dataStoreId, tableId]
    );
};
