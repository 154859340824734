import { Link } from "@amzn/awsui-components-react-v3";
import React from "react";
import { DisplayLabels, Model, Pair } from "src/components/survey/ApplicationIdentity/model/utils";

export function reviewerGroupName(model: Model): Pair {
    const reviewerGroup = model.application.review.reviewGroup;
    return {
        label: DisplayLabels.ReviewerGroupName,
        value: (
            <Link external={true} href={`https://permissions.amazon.com/a/team/${reviewerGroup}`}>
                {reviewerGroup}
            </Link>
        ),
    };
}
