import React, { useContext, useEffect, useRef, useState } from "react";
import {
    BulkEditQuestions,
    DEFAULT_TABLE_FIELD_QUESTION,
    KaleTablesService,
    QuestionTag,
} from "src/services/KaleTablesService";
import KaleContext from "src/components/KaleContext";
import { useBulkEditKaleTable } from "src/answers_legacy/hooks/bulkEditKaleTable";
import { useBulkEditKaleTableFields } from "src/answers_legacy/hooks/bulkEditKaleTableFields";
import { TableAnswerActions, TableFieldsAnswerActions } from "src/answers_legacy";
import uuid from "uuid";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { isAndes } from "src/components/survey/DataStoreInfo/DataStoreWizard/DataStoreUtils";
import { makeSyntheticTableQuestions } from "src/components/TableDetails/TableDetailsPage/synthetic-questions";

/**
 * This hook fetches table and field questions from backend and loads into GSM
 * @param dataStore used to determine if the datastore is of Andes technology
 */
export const useFetchQuestionsOnMount = (
    dataStore: DataStoreResponse | null
): {
    isLoading: boolean;
    questions: BulkEditQuestions;
    error: React.ReactNode;
} => {
    const { kaleTablesService } = useContext(KaleContext).service;
    const kaleTablesServiceRef = useRef<KaleTablesService>(kaleTablesService);
    kaleTablesServiceRef.current = kaleTablesService;

    const [, tableActions] = useBulkEditKaleTable();
    const tableActionsRef = useRef<TableAnswerActions>(tableActions);
    tableActionsRef.current = tableActions;

    const [, fieldActions] = useBulkEditKaleTableFields();
    const fieldActionsRef = useRef<TableFieldsAnswerActions>(fieldActions);
    fieldActionsRef.current = fieldActions;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [questions, setQuestions] = useState<BulkEditQuestions>(DEFAULT_TABLE_FIELD_QUESTION);
    const [error, setError] = useState<React.ReactNode>(null);

    useEffect((): (() => void) => {
        const { fetchBulkEditQuestions } = kaleTablesServiceRef.current;
        const { resetAndAddAnswers: resetAndAddTableAnswers, resetAnswers: resetTableAnswers } =
            tableActionsRef.current;
        const { resetAndAddAnswers: resetAndAddFieldAnswers, resetAnswers: resetFieldAnswers } =
            fieldActionsRef.current;
        setIsLoading(true);
        fetchBulkEditQuestions(isAndes(dataStore?.technology))
            .then((res): void => {
                const syntheticTableQuestions = makeSyntheticTableQuestions(null, []).filter((question): boolean => {
                    return question.tags.includes(QuestionTag.bulkEditable);
                });
                const tableQuestions = [...res.table, ...syntheticTableQuestions];
                setQuestions({
                    table: tableQuestions,
                    field: res.field,
                });
                resetAndAddTableAnswers([], tableQuestions, uuid.v4());
                resetAndAddFieldAnswers([], res.field, uuid.v4());
            })
            .catch((err: Error): void => setError(err.message))
            .finally((): void => setIsLoading(false));
        return (): void => {
            resetTableAnswers();
            resetFieldAnswers();
        };
    }, [dataStore?.technology]);

    return { isLoading, questions, error };
};
