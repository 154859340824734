import { Container, FormField, Header } from "@amzn/awsui-components-react-v3";
import React from "react";
import { HookAnswer, QuestionTag, QuestionType, ReviewAnswer, ReviewQuestion } from "src/answers_legacy";
import KaleCheckboxGroup from "src/components/fields/select/KaleCheckboxGroup";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import TextBoxWithTags from "src/components/fields/textbox/TextBoxWithTags";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
// eslint-disable-next-line max-len
import { MultiSelectQuestionForCampaign } from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/MultiSelectQuestion";

export const NOT_YET_SUPPORTED = "The specified question type is not yet supported";

const renderRadiobutton = (props: Props): JSX.Element => {
    const { isRequired, isReadonly, item } = props;
    return (
        <Container
            key={item.question.shortId}
            header={
                <Header description={KaleHTMLParser(item.question.subtext)}>
                    {KaleHTMLParser(item.question.content)}
                </Header>
            }
        >
            <KaleRadiobutton
                id={item.question.shortId}
                isRequired={isRequired && item.question.tags.includes(QuestionTag.required)}
                isReadonly={isReadonly}
                choices={item.question.choices}
                expectedAnswer={item.value as string}
                onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                    item.setValue(result.response);
                }}
            />
        </Container>
    );
};

const renderCheckboxGroup = (props: Props): JSX.Element => {
    const { isRequired, item } = props;
    return (
        <Container
            key={item.question.shortId}
            header={
                <Header description={KaleHTMLParser(item.question.subtext)}>
                    {KaleHTMLParser(item.question.content)}
                </Header>
            }
        >
            <KaleCheckboxGroup
                id={item.question.shortId}
                isRequired={isRequired && item.question.tags.includes(QuestionTag.required)}
                isReadonly={false}
                choices={item.question.choices}
                expectedAnswer={(item.value as string[]) ?? []}
                onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                    item.setValue(result.response);
                }}
            />
        </Container>
    );
};

const renderMultiSelect = (props: Props): JSX.Element => {
    return <MultiSelectQuestionForCampaign item={props.item} isReadonly={props.isReadonly} />;
};

const renderTextTags = (props: Props): JSX.Element => {
    const { isRequired, isReadonly, item } = props;
    return (
        <Container
            key={item.question.shortId}
            header={
                <Header description={KaleHTMLParser(item.question.subtext)}>
                    {KaleHTMLParser(item.question.content)}
                </Header>
            }
        >
            <TextBoxWithTags
                id={item.question.shortId}
                isRequired={isRequired && item.question.tags.includes(QuestionTag.required)}
                isReadonly={isReadonly}
                expectedAnswer={(item.value as string[]) ?? []}
                onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                    item.setValue(result.response);
                }}
            />
        </Container>
    );
};

interface Props {
    isRequired: boolean;
    isReadonly: boolean;
    item: HookAnswer<ReviewQuestion, ReviewAnswer>;
}

export const ReviewQuestionBuilder = (props: Props): JSX.Element | null => {
    const { item } = props;
    if (!item.isApplicable) {
        return null;
    }
    let content: JSX.Element;
    switch (item.question.type) {
        case QuestionType.radio:
            content = renderRadiobutton(props);
            break;
        case QuestionType.checkboxGroup:
            content = renderCheckboxGroup(props);
            break;
        case QuestionType.multiSelect:
            content = renderMultiSelect(props);
            break;
        case QuestionType.textTags:
            content = renderTextTags(props);
            break;
        default:
            content = <FormField>{NOT_YET_SUPPORTED}</FormField>;
            break;
    }

    return content;
};
