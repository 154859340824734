import React from "react";
import { MemoizedTableCellProps } from "src/components/TableDetails/FieldsTable/TableCell/types";
import Error from "src/util/Error";
import { REQUIRED_ERROR } from "src/components/TableDetails/FieldsTable/TableCell/constants";
import { FieldCellState, QuestionType } from "src/components/TableDetails/FieldsTable/types";
import {
    CheckboxQuestion,
    MultiselectQuestion,
    SingleselectQuestion,
    TextQuestion,
    TextareaQuestion,
} from "src/components/TableDetails/FieldsTable/TableCell/TableCellQuestions";

const renderQuestion = (props: FieldCellState): JSX.Element | null => {
    const {
        question: { type: questionType },
    } = props;

    switch (questionType) {
        case QuestionType.textArea:
            return <TextareaQuestion {...props} />;
        case QuestionType.text: {
            return <TextQuestion {...props} />;
        }
        case QuestionType.checkbox: {
            return <CheckboxQuestion {...props} />;
        }
        case QuestionType.singleSelect: {
            return <SingleselectQuestion {...props} />;
        }
        case QuestionType.multiSelect: {
            return <MultiselectQuestion {...props} />;
        }
        default: {
            throw new TypeError(`Unsupported question type: "${questionType}"`);
        }
    }
};

// Important for perf to not re-render every cell in the table whenever a single cell is updated.
export const MemoizedTableCell = React.memo<MemoizedTableCellProps>(
    (props: MemoizedTableCellProps): JSX.Element => {
        const { isValid } = props;
        const cellQuestion = renderQuestion(props);

        return (
            <span
                onScroll={(e): void => {
                    // Issue: https://i.amazon.com/ACAT-12049
                    // Polaris V3 Table container resets its horizontal scroll whenever the dropdown menu of a
                    // ...Polaris Select/Multiselect control within a Table cell is scrolled
                    // Temp Fix: Prevent cell scroll event propagation from bubbling up to the Table component
                    e.stopPropagation();
                }}
            >
                {cellQuestion}
                {!isValid && <Error text={REQUIRED_ERROR} />}
            </span>
        );
    },
    // Only re-render a cell if its value has changed or its validity has changed.
    (prevCell, nextCell): boolean => {
        return prevCell.value === nextCell.value && prevCell.isValid === nextCell.isValid;
    }
);
