import { FormField, NonCancelableCustomEvent, RadioGroup } from "@amzn/awsui-components-react-v3";
import { RadioGroupProps } from "@amzn/awsui-components-react-v3/polaris/radio-group/interfaces";
import * as React from "react";
import { useCallback } from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { Choice } from "src/services/KaleApplicationService";
import styled from "styled-components";
import { QuestionColorOverrides } from "src/services/dynamic-questions";

interface ColoredTextProps {
    colors?: QuestionColorOverrides;
}

const ColoredText = styled.span<ColoredTextProps>(
    ({ colors }): string => `
         ${colors?.color && `color: ${colors?.color} !important`};
     `
);

export const COLORED_TEXT_TESTID = "new-radio-button-colored-text";

const RADIO_OPTION_ID = "radio-button-option";

interface KaleRadioButtonProps extends FunctionalSurveyItemProps<string> {
    choices: Choice[];
    showErrors?: boolean;
    formFieldDataTestId?: string;
}

const KaleRadiobutton = ({
    id,
    isRequired,
    isFormInReadonlyMode = false,
    formFieldDataTestId,
    choices,
    expectedAnswer = "",
    onChangeCallback,
    showErrors,
}: KaleRadioButtonProps): JSX.Element | null => {
    const handleOnChange = useCallback(
        ({ detail }: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>): void => {
            const option = detail.value;
            onChangeCallback?.call(null, {
                id,
                response: option,
            });
        },
        [id, onChangeCallback]
    );

    // Show error only when last updated timestamp is set. Backwards compatible.
    const hasChanged = showErrors === undefined || showErrors;

    const items = React.useMemo(
        () =>
            choices.map((choice) => ({
                label: (
                    <ColoredText
                        colors={choice.colors}
                        data-testid={COLORED_TEXT_TESTID}
                        data-color={choice.colors?.color ?? ""}
                    >
                        {choice.label ?? choice.value}
                    </ColoredText>
                ),
                value: choice.value,
                disabled: isFormInReadonlyMode,
                controlId: expectedAnswer === choice.value ? RADIO_OPTION_ID : undefined,
            })),
        [choices, expectedAnswer, isFormInReadonlyMode]
    );

    return (
        <FormField
            data-testid={formFieldDataTestId}
            stretch={true}
            errorText={hasChanged && isRequired && !Boolean(expectedAnswer) ? "Required" : ""}
        >
            <RadioGroup id={id} items={items} value={expectedAnswer} onChange={handleOnChange} />
        </FormField>
    );
};

export default withFunctionalReadonly(KaleRadiobutton);
