import { Input, FormField } from "@amzn/awsui-components-react-v3/polaris";
import * as React from "react";
import { useEffect, useState } from "react";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import Model from "src/components/constants/Constants";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";

const TextBox = (props: FunctionalSurveyItemProps<string>): JSX.Element => {
    const {
        id,
        isReadonly = false,
        isFormInReadonlyMode = false,
        isRequired = false,
        expectedAnswer = "",
        placeholder = "",
        errorMsg = "",
        onChangeCallback,
    } = props;
    const isDisabled = isReadonly || isFormInReadonlyMode;
    const defaultErrorMsg = !Boolean(expectedAnswer) ? "Required" : "";

    const [localValue, setLocalValue] = useState<string>(expectedAnswer);

    useEffect((): void => {
        setLocalValue(expectedAnswer);
    }, [expectedAnswer]);

    const data = Model.getData(id);
    const label = data.name;
    const description = applyTemplateToText(data.question, data.templateValues);

    return (
        <FormField
            description={description}
            label={label}
            stretch={false}
            errorText={isRequired && (errorMsg || defaultErrorMsg)}
        >
            <Input
                id={id}
                value={localValue}
                disabled={isDisabled}
                readOnly={isDisabled}
                placeholder={placeholder}
                onChange={(e): void => setLocalValue(e.detail.value)}
                onBlur={(): void => {
                    if (localValue !== expectedAnswer) {
                        onChangeCallback({
                            id: id,
                            response: localValue,
                        });
                    }
                }}
            />
        </FormField>
    );
};

export default withFunctionalReadonly(TextBox);
