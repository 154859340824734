import React from "react";
import { Link, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { TestIds } from "shared/dsar-deletion-hardcoded-info-blurbs";

const SandfireCheckList = (): JSX.Element => {
    return (
        <React.Fragment>
            <h2>Start planning your onboarding</h2>
            <SpaceBetween size={"m"}>
                <body>
                    Follow the steps in the link below in order to successfully onboard to Sandfire. Note that red text
                    indicates a dependency outside of your team, and green text indicates optional steps for alternate
                    deletion identifiers apart from CustomerID. Also note that if your datastore supports the China
                    marketplace, you will need to onboard to the China-specific Sandfire Portal.
                </body>
                <Link
                    external
                    href={"https://w.amazon.com/bin/view/CDOPrivacy/Onboarding/OD3/Sandfire/DDR"}
                    data-testid={TestIds.SANDFIRE_CHECKLIST_LINK}
                >
                    View Sandfire checklist
                </Link>
            </SpaceBetween>
        </React.Fragment>
    );
};

export default SandfireCheckList;
