import { ParentConstraint, Response, Validation } from "../context";
import { AnswerActions } from "../actions";
import { AnswerBase, AnswerWithQuestion, ContentKey, ContentValue, QuestionBase, QuestionType } from "../models/common";
import {
    DataStoreAnswer,
    DataStoreQuestion,
    DataStoresAccessor,
    KaleTableFieldsAccessor,
    KaleTablesAccessor,
    ReviewAnswer,
    ReviewQuestion,
    TableAnswer,
    TableFieldAnswer,
    TableFieldQuestion,
    TableQuestion,
} from "src/answers_legacy";
import { omit } from "lodash";
import { getDefaultContentValue } from "src/answers_legacy/reducers/helpers";

interface UseAnswerSetCallback {
    (content: ContentValue): void;
}

export interface AnswerGetter<QType extends QuestionBase, AType extends AnswerBase> extends ParentConstraint {
    value: ContentValue;
    question: QType;
    isValid: boolean;
    answerWithQuestion: AnswerWithQuestion<QType, AType>;
    hasChanged: boolean;
    userLastUpdated: string;
}

export interface HookAnswer<QType extends QuestionBase, AType extends AnswerBase> extends AnswerGetter<QType, AType> {
    setValue: UseAnswerSetCallback;
}

interface SetValueCallback {
    (content: ContentValue): void;
}

export const makeSetValue = <QType extends QuestionBase, AType extends AnswerBase>(
    answerWithQuestion: Response<AnswerWithQuestion<QType, AType>>,
    actions: AnswerActions<QType, AType>
): SetValueCallback => {
    const { updateAnswers } = actions;
    const question = answerWithQuestion.value.question;

    return (content: ContentValue): void => {
        let contentKey: ContentKey;
        let error: boolean;
        switch (question.type) {
            case QuestionType.multiSelect:
            case QuestionType.checkboxGroup:
            case QuestionType.textTags: {
                contentKey = ContentKey.arrayContent;
                error = typeof content === "string";
                break;
            }
            case QuestionType.date: {
                contentKey = ContentKey.dateContent;
                error = typeof content !== "string";
                break;
            }
            case QuestionType.retention: {
                contentKey = ContentKey.jsonContent;
                error = typeof content !== "object";
                break;
            }
            default: {
                contentKey = ContentKey.textContent;
                error = typeof content !== "string";
            }
        }
        if (error) {
            throw new Error(`content type does not match expected ${contentKey}`);
        }
        const answer: AnswerWithQuestion<QType, AType> = {
            ...answerWithQuestion.value,
            answer: {
                ...answerWithQuestion.value.answer,
                [contentKey]: content,
            },
        };
        updateAnswers([answer]);
    };
};

export function getAnswerResponseState<QType extends QuestionBase, AType extends AnswerBase>(
    answerWithQuestion: Response<AnswerWithQuestion<QType, AType>>,
    actions: AnswerActions<QType, AType>
): HookAnswer<QType, AType> {
    const setValue = makeSetValue(answerWithQuestion, actions);
    const question = answerWithQuestion.value.question;
    const answer = answerWithQuestion.value.answer;
    const defaultValue = getDefaultContentValue(question);

    let value: ContentValue;
    switch (question.type) {
        case QuestionType.multiSelect:
        case QuestionType.checkboxGroup:
        case QuestionType.textTags: {
            value = answer[ContentKey.arrayContent] ?? null;
            break;
        }
        case QuestionType.date: {
            value = answer[ContentKey.dateContent] ?? defaultValue;
            break;
        }
        case QuestionType.retention: {
            value = answer[ContentKey.jsonContent] ?? defaultValue;
            break;
        }
        default: {
            value = answer[ContentKey.textContent] ?? defaultValue;
        }
    }

    return {
        value,
        setValue,
        question: question as unknown as QType,
        isValid: answerWithQuestion.isValid || false,
        isApplicable: answerWithQuestion.isApplicable,
        answerWithQuestion: answerWithQuestion.value,
        hasChanged: answerWithQuestion.hasChanged,
        userLastUpdated: answerWithQuestion.userLastUpdated,
    };
}

export interface KaleTableFieldsAccessorReadonly extends Validation {
    answers: AnswerGetter<TableFieldQuestion, TableFieldAnswer>[];
}

export interface KaleTablesAccessorReadonly extends Validation {
    answers: AnswerGetter<TableQuestion, TableAnswer>[];
}

export interface DataStoresAccessorReadonly extends Validation {
    answers: AnswerGetter<DataStoreQuestion, DataStoreAnswer>[];
}

export type CombinedAccessorType = KaleTableFieldsAccessor | KaleTablesAccessor | DataStoresAccessor;

export type CombinedAccessorUnionType = KaleTableFieldsAccessor & KaleTablesAccessor & DataStoresAccessor;

export type CombinedAccessorReadonlyType =
    | KaleTableFieldsAccessorReadonly
    | KaleTablesAccessorReadonly
    | DataStoresAccessorReadonly;

export type CombinedAccessorReadonlyUnionType = KaleTableFieldsAccessorReadonly &
    KaleTablesAccessorReadonly &
    DataStoresAccessorReadonly;

type CombinedQuestionType = TableFieldQuestion | TableQuestion | DataStoreQuestion | ReviewQuestion;

type CombinedAnswerType = TableFieldAnswer | TableAnswer | DataStoreAnswer | ReviewAnswer;

export const cloneAccessors = <AccessorReadonlyType>(accessors: CombinedAccessorType[]): AccessorReadonlyType[] => {
    const result: CombinedAccessorReadonlyType[] = [];

    accessors.forEach((accessor: CombinedAccessorType): void => {
        const newAccessor = {
            ...accessor,
            answers: [] as AnswerGetter<CombinedQuestionType, CombinedAnswerType>[],
        };
        accessor.answers.forEach((answer: HookAnswer<CombinedQuestionType, CombinedAnswerType>): void => {
            const newAnswer = omit(answer, ["setValue"]) as AnswerGetter<CombinedQuestionType, CombinedAnswerType>;

            newAccessor.answers.push(newAnswer);
        });
        result.push(newAccessor as unknown as CombinedAccessorReadonlyType);
    });

    return JSON.parse(JSON.stringify(result)) as AccessorReadonlyType[];
};
