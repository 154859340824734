import { Input, SpaceBetween, FormField } from "@amzn/awsui-components-react-v3/polaris";
import * as React from "react";
import CopyToClipboardButton from "src/components/buttons/CopyToClipboardButton";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { copyToClipboard } from "src/util/clipboard";
import { AuditHistoryLink } from "src/components/fields/textbox/AuditHistoryLink";
import styled from "styled-components";
import Model from "src/components/constants/Constants";
import { applyTemplateToText } from "src/components/fields/textbox/TextBoxHelper";
import { useEffect, useState } from "react";
import { TEST_IDS } from "shared/fields";

type Props = FunctionalSurveyItemProps<string>;

const { APP_NAME, AUDIT_HISTORY_LINK } = TEST_IDS;

const TextFieldLiner = styled.div`
    // Pushes our text content down to better line up with the label rendered by the HorizontalFormField
    margin-top: 4px;
`;

/**
 * Component responsible for taking user input for the Application Name when the field is editable.
 * When the field is not editable, the component renders the chosen application name as a "copy to clipboard" button
 * as well as a separate link to the Application's audit log.
 */
const AppNameTextBox = (props: Props): JSX.Element => {
    const {
        id,
        isReadonly = false,
        isFormInReadonlyMode = false,
        isRequired = false,
        expectedAnswer = "",
        placeholder = "",
        errorMsg = "",
        onChangeCallback,
    } = props;
    const isDisabled = isReadonly || isFormInReadonlyMode;
    const defaultErrorMsg = !Boolean(expectedAnswer) ? "Required" : "";

    const [localValue, setLocalValue] = useState<string>(expectedAnswer);

    useEffect((): void => {
        setLocalValue(expectedAnswer);
    }, [expectedAnswer]);

    const data = Model.getData(id);
    const label = data.name;
    const description = applyTemplateToText(data.question, data.templateValues);

    const fieldBody = isDisabled ? (
        <TextFieldLiner>
            <SpaceBetween size={"l"} direction={"vertical"}>
                <CopyToClipboardButton
                    data-testid={APP_NAME.COPY_CLIPBOARD_BUTTON}
                    successMessage={"copied"}
                    onClick={async (): Promise<void> => {
                        await copyToClipboard(expectedAnswer);
                    }}
                >
                    <span data-testid={APP_NAME.STATIC_TEXT}>{expectedAnswer}</span>
                </CopyToClipboardButton>
                <AuditHistoryLink data-testid={AUDIT_HISTORY_LINK} appName={expectedAnswer} />
            </SpaceBetween>
        </TextFieldLiner>
    ) : (
        <Input
            data-testid={APP_NAME.TEXT_INPUT}
            id={id}
            value={localValue}
            disabled={isDisabled}
            readOnly={isDisabled}
            placeholder={placeholder}
            onChange={(e): void => setLocalValue(e.detail.value)}
            onBlur={(): void => {
                if (localValue !== expectedAnswer) {
                    onChangeCallback({
                        id: id,
                        response: localValue,
                    });
                }
            }}
        />
    );

    return (
        <FormField label={label} description={description} errorText={isRequired && (errorMsg || defaultErrorMsg)}>
            {fieldBody}
        </FormField>
    );
};

export default withFunctionalReadonly(AppNameTextBox);
