import React, { useMemo, useState } from "react";
import { SpaceBetween, Tabs, TabsProps } from "@amzn/awsui-components-react-v3/polaris";
import { FormQuestionsPane } from "src/components/TableDetails/FormQuestionsPane/FormQuestionsPane";
import { useKaleTable, HookAnswer, TableQuestion, TableAnswer } from "src/answers_legacy";
import { SYNTH_METADATA_COMPLIANCE_TYPE, SYNTH_UNIVERSAL_COMPLIANCE_TYPE } from "src/components/TableDetails/constants";
import { AndesCradleMetadataInfo } from "src/components/TableDetails/TableDetailsForm/hooks";
import AndesCradleMetadata from "src/components/TableDetails/QuestionsPanel/AndesCradleMetadata";
import { ComplianceTypeVisibility } from "src/components/TableDetails/TableDetailsPage/types";

type GroupedTableAnswers = Map<string, HookAnswer<TableQuestion, TableAnswer>[]>;
const groupTableAnswersByComplianceType = (answers: HookAnswer<TableQuestion, TableAnswer>[]): GroupedTableAnswers => {
    // Separate answers into distinct lists of answers that are grouped by the complianceType of each question
    const groupedAnswers = new Map<string, typeof answers>();
    answers.forEach((answer): void => {
        const { complianceType } = answer.question;
        const group = groupedAnswers.get(complianceType) ?? [];
        group.push(answer);
        groupedAnswers.set(complianceType, group);
    });
    return groupedAnswers;
};

interface TableQuestionsPanelProps {
    isAndesTable: boolean;
    controlPanel: React.ReactNode;
    andesCradleMetadataInfo: AndesCradleMetadataInfo;
    children: React.ReactNode;
    complianceTypeVisibility: ComplianceTypeVisibility;
}

interface MakeVisibleTabsOptions {
    isAndesTable: boolean;
    complianceTypeVisibility: ComplianceTypeVisibility;
    groupedTableQuestionData: GroupedTableAnswers;
    andesCradleMetadataInfo: AndesCradleMetadataInfo;
}
const useMakeVisibleTabs = ({
    isAndesTable,
    complianceTypeVisibility,
    groupedTableQuestionData,
    andesCradleMetadataInfo,
}: MakeVisibleTabsOptions): TabsProps.Tab[] => {
    return useMemo((): TabsProps.Tab[] => {
        const complianceTypes = Object.keys(complianceTypeVisibility);
        const visibleComplianceTypes = complianceTypes.filter((type: string): boolean =>
            Boolean(complianceTypeVisibility[type])
        );

        // Build and return the visible tabs
        return visibleComplianceTypes.map((complianceType: string): TabsProps.Tab => {
            const cradleMetadataInfo = andesCradleMetadataInfo[complianceType];

            const answerGroup = groupedTableQuestionData.get(complianceType) || [];
            return {
                label: complianceType,
                id: complianceType,
                content: (
                    <SpaceBetween size={"xl"} direction={"vertical"}>
                        {isAndesTable && cradleMetadataInfo && <AndesCradleMetadata {...cradleMetadataInfo} />}
                        <FormQuestionsPane<TableQuestion, TableAnswer> questionAnswers={answerGroup} />
                    </SpaceBetween>
                ),
            };
        });
    }, [complianceTypeVisibility, groupedTableQuestionData, isAndesTable, andesCradleMetadataInfo]);
};

/**
 * This component to group dynamic table questions by compliance type and render each group under its own tab.
 * This component is also responsible for filtering out Tab groups that should not be visible based on props.
 * The fieldsTable(prop) is also rendered, which has all the fields. And finally, metadata table questions at the bottom
 */
const QuestionsPanel = (props: TableQuestionsPanelProps): JSX.Element => {
    const { complianceTypeVisibility, controlPanel, children, andesCradleMetadataInfo, isAndesTable } = props;
    const [{ answers: TableQuestionData }] = useKaleTable();
    const [activeTabId, setActiveTabId] = useState<string>("");

    const groupedTableQuestionData = useMemo(
        (): GroupedTableAnswers => groupTableAnswersByComplianceType(TableQuestionData),
        [TableQuestionData]
    );

    const universalComplianceGroup = groupedTableQuestionData.get(SYNTH_UNIVERSAL_COMPLIANCE_TYPE) || [];
    const metadataSynthComplianceGroup = groupedTableQuestionData.get(SYNTH_METADATA_COMPLIANCE_TYPE) || [];

    const visibleTabs = useMakeVisibleTabs({
        isAndesTable,
        complianceTypeVisibility,
        groupedTableQuestionData,
        andesCradleMetadataInfo: andesCradleMetadataInfo,
    });

    const activeTab = visibleTabs.find((tab): boolean => tab.id === activeTabId);
    if (!activeTab && visibleTabs.length > 0) {
        let nextAvailableTabId = "";
        visibleTabs.forEach((tab): void => {
            if (!nextAvailableTabId) {
                nextAvailableTabId = tab.id;
            }
        });
        setActiveTabId(nextAvailableTabId);
    }

    return (
        <SpaceBetween size={"xxl"}>
            <FormQuestionsPane<TableQuestion, TableAnswer> questionAnswers={universalComplianceGroup} />
            {controlPanel}
            {visibleTabs.length ? (
                <Tabs
                    tabs={visibleTabs}
                    activeTabId={activeTabId}
                    variant={"container"}
                    onChange={(e): void => {
                        setActiveTabId(e.detail.activeTabId);
                    }}
                />
            ) : null}
            {children}
            <FormQuestionsPane<TableQuestion, TableAnswer> questionAnswers={metadataSynthComplianceGroup} />
        </SpaceBetween>
    );
};

const MemoizedQuestionsPanel = React.memo(QuestionsPanel);
export default MemoizedQuestionsPanel;
