import {
    TableFieldAnswer,
    TableFieldQuestion,
    UpdateAnswersActionCallback,
    useKaleTableFields,
} from "src/answers_legacy";
import { usePrevious } from "src/util/usePrevious";
import { useEffect, useRef } from "react";
import { OnSaveCb } from "src/components/TableDetails/TableDetailsForm/types";

export const useDeleteField = (onSave: OnSaveCb): UpdateAnswersActionCallback<TableFieldQuestion, TableFieldAnswer> => {
    const onSaveRef = useRef(onSave);
    onSaveRef.current = onSave;

    const [fields, { removeAnswers: removeTableFieldsAnswers }] = useKaleTableFields();
    const prevFields = usePrevious(fields);
    const removedFieldCount = (prevFields?.length || fields.length) - fields.length;

    useEffect((): void => {
        if (removedFieldCount > 0) {
            onSaveRef.current().catch((e): void => console.error(e));
        }
    }, [removedFieldCount]);

    return removeTableFieldsAnswers;
};
