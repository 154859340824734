import SurveyConstants from "src/components/constants/SurveyConstants";

export default class Constants {
    private static data: any = {};
    public static getData(id: string): any {
        if (Object.entries(this.data).length === 0) {
            this.data = Object.assign(this.data, SurveyConstants.data);
        }
        if (id) {
            if (!Object.prototype.hasOwnProperty.call(this.data, id)) {
                return null;
            }
            return this.data[id];
        }

        return this.data;
    }
}
