import pluralize from "pluralize";
import { Alert, AlertProps, Box } from "@amzn/awsui-components-react-v3";
import React from "react";
import { TEST_IDS } from "shared/survey/personalDataWizard";

const { ANALYZER_SUMMARIES } = TEST_IDS.WIZARD.SUMMARY_STEP;

interface AnalyzerSummaryProps {
    alertType: AlertProps["type"];
    analyzerName: string;
    numFindings: number;
    "data-testid": string;
}
export const AnalyzerSummary = (props: AnalyzerSummaryProps): JSX.Element => {
    const { analyzerName, numFindings, alertType, "data-testid": testId } = props;

    const findingsCount = (
        <Box variant={"span"} fontWeight={"bold"} data-testid={ANALYZER_SUMMARIES.FINDINGS_COUNT}>
            {numFindings}
        </Box>
    );

    const findings = pluralize("findings", numFindings);

    return (
        <Alert data-testId={testId} type={alertType}>
            Kale has detected {findingsCount} {analyzerName} {findings} containing personal data.
        </Alert>
    );
};
