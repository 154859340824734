import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loader from "src/components/fields/Loader";
import { KaleContextProps, KaleProvider } from "src/components/KaleContext";
import { getStageConfig, setConfigFeatureToggles, StageConfig } from "src/Config";
import { CloudWatchLogger } from "src/services/CloudWatchLogger";
import { KaleApplicationService } from "src/services/KaleApplicationService";
import { KaleTablesService } from "src/services/KaleTablesService";
import { CradleService } from "src/services/CradleService";
import { TokenGetter, CognitoService, User } from "../services/CognitoService";
import Layout from "./layout/Layout";
import { BrandleService } from "src/services/BrandleService";
import { AuditLogService } from "src/services/AuditLogService";
import { FeatureToggles, getFeatureToggles } from "src/services/FeatureToggleService";
import { WebSocketService } from "src/services/WebSocketService";
import { NodeKaleApplicationService } from "src/services/NodeKaleApplicationService";
import { JobService } from "src/services/JobService";
import { RumService } from "src/services/RumService";
import { RumSession } from "src/components/RumSession";
import { Alert } from "@amzn/awsui-components-react-v3";

interface AppState {
    isLoadError: boolean;
    isReady: boolean;
    userEmail: string;
    userId: string;
    userFirstName: string;
    userLastName: string;
    stageConfig?: StageConfig;
    accessTokenGetter?: TokenGetter;
}

interface Contenthash {
    bundleContenthash: string;
    alias: string;
}

export const selectContenthash = (userAlias: string): Contenthash => {
    return {
        bundleContenthash: global.__BUNDLE_CONTENTHASH__,
        alias: userAlias,
    };
};

export default class App extends React.Component<object, AppState> {
    public readonly state: AppState = {
        isLoadError: false,
        isReady: false,
        userEmail: "",
        userId: "",
        userFirstName: "",
        userLastName: "",
    };

    private handleError(): void {
        this.setState({
            isLoadError: true,
            isReady: true,
        });
    }

    public componentDidMount(): void {
        getStageConfig()
            .then((config: StageConfig): void => {
                const cognitoService = new CognitoService(config);
                cognitoService
                    .fetchUser()
                    .then(async (user: User): Promise<void> => {
                        const cloudWatchLogger = new CloudWatchLogger(config, cognitoService.getAccessToken, user.id);
                        const cloudWatchSetup = cloudWatchLogger.setup();

                        let features: FeatureToggles;
                        try {
                            features = await getFeatureToggles(config.apiEndpoint, cognitoService.getAccessToken);
                            await setConfigFeatureToggles(features, config);
                        } catch (e) {
                            console.error(e);
                            return this.handleError();
                        }

                        this.setState(
                            {
                                stageConfig: {
                                    ...config,
                                },
                                isReady: true,
                                accessTokenGetter: cognitoService.getAccessToken,
                                userEmail: user.email,
                                userId: user.id,
                                userFirstName: user.firstName,
                                userLastName: user.lastName,
                            },
                            (): void => {
                                cloudWatchSetup.then((): void => {
                                    const contentHash = selectContenthash(user.id);
                                    console.info(`Kale loaded successfully: ${JSON.stringify(contentHash)}`);
                                });
                            }
                        );
                    })
                    .catch((): void => this.handleError());
            })
            .catch((): void => this.handleError());
    }

    public renderError(): JSX.Element | null {
        return (
            <Alert type="error" data-testid={"app-error"}>
                Error loading application.
            </Alert>
        );
    }

    public render(): JSX.Element | null {
        if (this.state.isReady && this.state.stageConfig && this.state.accessTokenGetter) {
            const userValues: KaleContextProps = {
                user: {
                    email: this.state.userEmail,
                    userId: this.state.userId,
                    firstName: this.state.userFirstName,
                    lastName: this.state.userLastName,
                },
                service: {
                    brandleService: new BrandleService(this.state.stageConfig, this.state.accessTokenGetter),
                    kaleAppService: new KaleApplicationService(this.state.stageConfig, this.state.accessTokenGetter),
                    nodeKaleAppService: new NodeKaleApplicationService(
                        this.state.stageConfig,
                        this.state.accessTokenGetter
                    ),
                    kaleTablesService: new KaleTablesService(this.state.stageConfig, this.state.accessTokenGetter),
                    cradleService: new CradleService(this.state.stageConfig, this.state.accessTokenGetter),
                    auditLogService: new AuditLogService(this.state.stageConfig, this.state.accessTokenGetter),
                    webSocketService: new WebSocketService(this.state.stageConfig, this.state.accessTokenGetter),
                    jobService: new JobService(this.state.stageConfig, this.state.accessTokenGetter),
                    rumService: new RumService(this.state.stageConfig.cloudWatchRum),
                },
                config: {
                    bindleEndpoint: this.state.stageConfig.bindleEndpoint,
                    isItDownUrl: this.state.stageConfig.isItDownUrl,
                    sandfireBaseURL: this.state.stageConfig.sandfireBaseURL,
                    personalDataClassifierHost: this.state.stageConfig.personalDataClassifierHost,
                    anvilFlowAppCreationTimeoutInMS: this.state.stageConfig.anvilFlowAppCreationTimeoutInMS,
                },
                features: this.state.stageConfig.features,
            };

            return (
                <KaleProvider value={userValues}>
                    <BrowserRouter>
                        <RumSession />
                        <Switch>
                            <Route path="/">
                                <div id={"kale"}>
                                    <Layout key={"survey_layout"} />
                                </div>
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </KaleProvider>
            );
        } else {
            return (
                <div id={"kale"}>
                    {this.state.isLoadError && this.renderError()}
                    <Loader hasError={this.state.isLoadError} />
                </div>
            );
        }
    }
}
