export const TEST_IDS = {
    APPLICATION_OVERVIEW: {
        ROOT: "application-overview--root",
        MAIN_CONTENT: "application-overview--main-content",
        PRIVACY_ALERTS_BANNER: {
            ROOT: "application-overview--privacy-alerts-banner--root",
            ALERTS_COUNT: "application-overview--privacy-alerts-banner--alerts-count",
        },
        SUMMARY_CARDS: {
            CARD_BODY: {
                ROOT: "summary-cards--card-body--root",
                NO_SUMMARY_ITEMS_MESSAGE: "summary-cards--card-body--no-summary-items-message",
                FETCH_ERROR_MESSAGE: "summary-cards--card-body--fetch-error-message",
                SUMMARY_ITEMS_CONTENT: "summary-cards--card-body--summary-items-content",
            },
        },
        RESOLVE_OPEN_QUESTIONS_BUTTON: "application-overview--resolve-open-questions-button",
    },
    APPLICATION_IDENTITY: {
        APPLICATION_NAME: "application-info--applicationName",
        APPROVED_BY: "application-info--approvedBy",
        APPROVED_ON: "application-info--approvedOn",
        AUDIT_HISTORY: "application-info--auditHistory",
        CREATED_ON: "application-info--createdOn",
        LAST_MODIFIED: "application-info--lastModified",
        RESPONDENT_ALIAS: "application-info--respondentAlias",
        REVIEWER_GROUP: "application-info--reviewerGroup",
        SUBMITTING_TEAM: "application-info--submittingTeam",
    },
    APPLICATION_INFO: {
        REVIEW_QUESTIONS_PANE: {
            ROOT: "application-info--root",
        },
        IS_FOUNDATIONAL_APP: {
            FORM_FIELD: "application-info--isFoundationalApp-form-field",
            SELECT: "application-info--isFoundationalApp-select",
        },
    },
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: {
            ROOT: "data-store-wizard--root",
            COLLECT_INFO_STEP: {
                ROOT: "collect-data-store-info--root",
                NAME_INPUT: "collect-data-store-info--name-input",
                UUID_COPY_CLIPBOARD_BUTTON: "collect-data-store-info--uuid-copy",
                UUID_TEXT: "collect-data-store-info--uuid-text",
                DESCRIPTION_INPUT: "collect-data-store-info--description-input",
                IDENTIFIERS_FIELD: "collect-data-store-info--identifiers-field",
                TECHNOLOGY_FIELD: "collect-data-store-info--technology-field",
                ADD_INSTANCE_BUTTON: "collect-data-store-info--add-instance-button",
                DUPLICATE_NAME_ERROR: "collect-data-store-info--duplicate-name-error",
                DUPLICATE_ANDES_TECHNOLOGY_ERROR: "collect-data-store-info--duplicate-andes-technology-error",
                DUPLICATE_DATA_STORE_ERROR: "collect-data-store-info--duplicate-data-store-error",
            },
            CHILD_DATA_DECORATOR: "data-store-wizard--child-data--decorator",
            DYNAMIC_QUESTION_STEP: {
                ROOT: "data-store-wizard--dynamic-question-step--root",
                MULTISELECT_QUESTION: "data-store-wizard--multiselect-question",
                STAND_ALONE_QUESTION: "data-store-wizard--stand-alone-question",
                QUESTION_GROUP: {
                    ROOT: "data-store-wizard--question-group--root",
                    CONTENT: "data-store-wizard--question-group--content",
                    SEPARATOR: "data-store-wizard--question-group--separator",
                    GROUP_SECTION: {
                        ROOT: "data-store-wizard--group-section--root",
                        GROUP_QUESTION: "data-store-wizard--group-section--question",
                    },
                },
                CHILD_DATA_GATING_QUESTION_GROUP: {
                    ROOT: "data-store-wizard--child-data-gating-question-group--root",
                    HEADER: "data-store-wizard--child-data-gating-question-group--header",
                    CONTENT: "data-store-wizard--child-data-gating-question-group--content",
                },
            },
            SUMMARY_REVIEW_STEP: {
                ROOT: "summary-builder--container",
                NAME: "summary-builder--name",
                DESCRIPTION: "summary-builder--description",
                TECHNOLOGY: "summary-builder--technology",
                INSTANCES: "summary-builder--instances",
                QUESTION_BLOCK: "summary-builder--question",
                ALERT_CONTAINER: "summary-builder--alert-container",
                PRIVACY_ALERT_CONTAINER: "summary-builder--privacy-alert-container",
            },
        },

        DATA_STORE_TABLE: {
            ACTIONS: {
                ROOT: "data-store-table--actions-root",
                ADD_DATA_STORE_BUTTON: "data-store-table-action--stripe-add-button",
                EDIT_DATA_STORE_BUTTON: "data-store-table-action--stripe-edit-button",
                DELETE_DATA_STORE: {
                    MODAL_TRIGGER_BUTTON: "data-store-table--delete-data-store--modal-trigger-button",
                    MODAL_CONFIRM_BUTTON: "data-store-table--delete-data-store--modal-confirm-button",
                    MODAL_CANCEL_BUTTON: "data-store-table--delete-data-store--modal-cancel-button",
                    MODAL_ROOT: "data-store-table--delete-data-store--modal-root",
                },
            },
        },

        SCHEMA_TABLE: {
            ACTIONS: {
                DELETE_TABLES: {
                    MODAL_TRIGGER_BUTTON: "schema-table--delete-tables--modal-trigger-button",
                    MODAL_CONFIRM_BUTTON: "schema-table--delete-tables--modal-confirm-button",
                    MODAL_CANCEL_BUTTON: "schema-table--delete-tables--modal-cancel-button",
                    MODAL_ROOT: "schema-table--delete-tables--modal-root",
                },
                BULK_EDIT: {
                    MODAL_TRIGGER_BUTTON: "schema-table--bulk-edit--modal-trigger-button",
                    MODAL_CONFIRM_BUTTON: "schema-table--bulk-edit--modal-confirm-button",
                    MODAL_CANCEL_BUTTON: "schema-table--bulk-edit--modal-cancel-button",
                    MODAL_ROOT: "schema-table--bulk-edit--modal-root",
                },
                TRANSFER_TABLES: {
                    MODAL_TRIGGER_BUTTON: "schema-table--transfer-tables--modal-trigger-button",
                    MODAL_CANCEL_BUTTON: "schema-table--transfer-tables--modal-cancel-button",
                    MODAL_CONFIRM_BUTTON: "schema-table--transfer-tables--modal-confirm-button",
                    MODAL_ROOT: "schema-table--transfer-tables--modal-root",
                },
                CSV: {
                    EXPORT_TABLES: {
                        MODAL_CONFIRM_BUTTON: "schema-table--csv-export-tables--modal-confirm-button",
                        MODAL_CANCEL_BUTTON: "schema-table--csv-export-tables--modal-cancel-button",
                        MODAL_ROOT: "schema-table--csv-export-tables--modal-root",
                    },
                    IMPORT_TABLES: {
                        MODAL_CANCEL_BUTTON: "schema-table--csv-import-tables--modal-cancel-button",
                        MODAL_CONFIRM_BUTTON: "schema-table--csv-import-tables--modal-confirm-button",
                        MODAL_ROOT: "schema-table--csv-import-tables--modal-root",
                    },
                },
                ADD_TABLE: {
                    CREATE_TABLE: {
                        MODAL_CANCEL_BUTTON: "schema-table--create-table--modal-cancel-button",
                        MODAL_CONFIRM_BUTTON: "schema-table--create-table--modal-confirm-button",
                        MODAL_ROOT: "schema-table--create-table--modal-root",
                    },
                },
            },
        },
    },

    RECALL_IN_PROGRESS: {
        MESSAGE: "recall-modal-msg",
        OPEN_READ_ONLY_BUTTON: "recall-model-open-read-only-btn",
    },

    SUBMIT_IN_PROGRESS: {
        MODAL: "submit-modal",
        MESSAGE: "submit-modal-msg",
    },

    FORM_ACTIONS: {
        RECALL: {
            MODAL_TRIGGER_BUTTON: "privacy-survey-actions--recall--modal-trigger-button",
            MODAL_CONFIRM_BUTTON: "privacy-survey-actions--recall--modal-confirm-button",
            MODAL_CANCEL_BUTTON: "privacy-survey-actions--recall--modal-cancel-button",
            MODAL_ROOT: "privacy-survey-actions--recall--modal-root",
        },
        SUBMIT: {
            MODAL_TRIGGER_BUTTON: "privacy-survey-actions--submit--modal-trigger-button",
            MODAL_CONFIRM_BUTTON: "privacy-survey-actions--submit--modal-confirm-button",
            MODAL_CANCEL_BUTTON: "privacy-survey-actions--submit--modal-cancel-button",
            MODAL_ROOT: "privacy-survey-actions--submit--modal-root",
        },
        APPROVE: {
            MODAL_TRIGGER_BUTTON: "privacy-survey-actions--approve--modal-trigger-button",
            MODAL_CONFIRM_BUTTON: "privacy-survey-actions--approve--modal-confirm-button",
            MODAL_CANCEL_BUTTON: "privacy-survey-actions--approve--modal-cancel-button",
            MODAL_ROOT: "privacy-survey-actions--approve--modal-root",
        },
        DELETE: {
            MODAL_TRIGGER_BUTTON: "privacy-survey-actions--delete--modal-trigger-button",
            MODAL_CONFIRM_BUTTON: "privacy-survey-actions--delete--modal-confirm-button",
            MODAL_CANCEL_BUTTON: "privacy-survey-actions--delete--modal-cancel-button",
            MODAL_ROOT: "privacy-survey-actions--delete--modal-root",
        },
    },

    AEDU_SANDFIRE: {
        TABLE: "aedu-sandfire-table",
        ERROR: "aedu-sandfire-error",
    },
};
