import React, { useContext } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { PLACEHOLDER_TEXT } from "src/components/TableDetails/FieldsTable/TableCell/constants";
import { QuestionChoice, QuestionTag } from "src/services/KaleTablesService";
import ClearableSelect, {
    ClearableSelectOption as FieldSelectQuestionOption,
} from "src/components/fields/select/ClearableSelect";
import { FieldCellState } from "src/components/TableDetails/FieldsTable/types";

type FieldSelectQuestionOptionWithServerAttributes = FieldSelectQuestionOption & Pick<QuestionChoice, "colors">;

export const SingleselectQuestion = (props: FieldCellState): JSX.Element => {
    const {
        value,
        setValue,
        isValid,
        question: { choices, tags },
        isApplicable,
    } = props;
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly || !isApplicable;
    const makeSelectOptionFromChoice = ({
        value: optionValue,
        description,
        label,
        ...serverAttributes
    }: QuestionChoice): FieldSelectQuestionOptionWithServerAttributes => ({
        // Superset of Polaris v3 option shape.
        value: optionValue,
        label: label || optionValue,
        description,
        ...serverAttributes,
    });

    const selectedChoice = choices.find((choice): boolean => choice.value === value) ?? null;
    const selectedOption = selectedChoice ? makeSelectOptionFromChoice(selectedChoice) : null;
    const options: FieldSelectQuestionOption[] = choices.map(makeSelectOptionFromChoice);

    const isClearable = !tags.includes(QuestionTag.selectIsNotClearable);

    return (
        <span title={selectedOption?.label}>
            <ClearableSelect
                colors={selectedOption?.colors}
                selectedOption={selectedOption}
                options={options}
                isClearable={isClearable}
                placeholder={PLACEHOLDER_TEXT}
                onChange={({ detail: { selectedOption } }): void => {
                    // The event will contain null selectedOption if selection was cleared by the user.
                    // Translate a null selectedOption to an empty string value before calling setValue
                    setValue(selectedOption?.value ?? "");
                }}
                invalid={!isValid}
                disabled={isReadonly}
                filteringType={"auto"}
            />
        </span>
    );
};
