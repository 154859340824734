import * as React from "react";
import { NonCancelableCustomEvent, TokenGroup, TokenGroupProps } from "@amzn/awsui-components-react-v3/polaris";

export const TAGS_TOKEN_GROUP_TESTID = "tags-token-group";

interface Props {
    id: string;
    items: TokenGroupProps.Item[];
    onRemoveCallback?: (items: string[]) => void;
}

export const Tags = (props: Props): JSX.Element => {
    const { id, items, onRemoveCallback } = props;
    const onDismiss = (event: NonCancelableCustomEvent<TokenGroupProps.DismissDetail>): void => {
        const result = [...items.slice(0, event.detail.itemIndex), ...items.slice(event.detail.itemIndex + 1)];
        if (onRemoveCallback) {
            onRemoveCallback(result.map((x): string => x.label ?? ""));
        }
    };

    return <TokenGroup data-testid={TAGS_TOKEN_GROUP_TESTID} id={id} items={items} onDismiss={onDismiss} />;
};
