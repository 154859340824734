import { CommentsViewState } from "src/components/CommentsView/state/reducer/reducer";
import { CommentMessageChangeAction } from "src/components/CommentsView/state/actions";

export const commentMessageChangeReducer = (
    prevState: CommentsViewState,
    action: CommentMessageChangeAction
): CommentsViewState => {
    const { frontEndId, message } = action.payload;
    return {
        ...prevState,
        comments: {
            ...prevState.comments,
            byFrontEndId: {
                ...prevState.comments.byFrontEndId,
                [frontEndId]: {
                    // Mixin the new comment message
                    ...prevState.comments.byFrontEndId[frontEndId],
                    message,
                },
            },
        },
    };
};
