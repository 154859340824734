export const TEST_IDS = {
    COMMENTS_VIEW_SIDEBAR: {
        ROOT: "comments-view-sidebar--root",
    },
    COMMENTS_VIEW: {
        ROOT: "comments-view--root",
        CREATE_COMMENT_THREAD_BUTTON: "comments-view--create-comment-button",
        COMMENT: {
            ROOT: "comments-view--comment",
            COMMENT_HEADER: "comments-view--comment-header",
            COMMENT_BODY: "comments-view--comment-body",
        },
        COMMENT_THREAD: {
            ROOT: "comments-view--comment-thread--root",
            EXPANDED_THREAD_CONTAINER: "comments-view--comment-thread--expanded-container",
            COLLAPSED_THREAD_CONTAINER: "comments-view--comment-thread--collapsed-container",
            EXPAND_COLLAPSE_TRIGGER: "comments-view--comment-thread--expand-collapse-trigger",
            REPLY_BUTTON: "comments-view--comment-thread--reply-button",
            SAVE_BUTTON: "comments-view--comment-thread--save-button",
            CANCEL_BUTTON: "comments-view--comment-thread--cancel-button",
        },
        COMMENT_THREAD_GROUP: { ROOT: "comments-view--comment-thread-group" },
    },
};
