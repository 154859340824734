import {
    CommentSlice,
    CommentsViewState,
    CommentThreadGroupSlice,
    CommentThreadSlice,
} from "src/components/CommentsView/state/reducer/reducer";
import { CreatingNewCommentThreadAction } from "src/components/CommentsView/state/actions";
import {
    createCommentThreadGroup,
    createGroupHeader,
    initializeNewCommentThread,
} from "src/components/CommentsView/state/reducer/utils";
import { CommentThreadGroup } from "src/components/CommentsView/state/model";

export const creatingNewCommentThreadReducer = (
    prevState: CommentsViewState,
    action: CreatingNewCommentThreadAction
): CommentsViewState => {
    const { headComment } = action.payload;
    const {
        comments: prevCommentSlice,
        commentThreads: prevThreadSlice,
        commentThreadGroups: prevGroupSlice,
    } = prevState;

    // Add the comment to the CommentSlice
    const newCommentSlice: CommentSlice = {
        ...prevCommentSlice,
        byFrontEndId: {
            ...prevCommentSlice.byFrontEndId,
            [headComment.frontEndId]: headComment,
        },
    };

    // Comment is the head comment of a new thread.
    // Create the new CommentThread for this comment
    const newThread = initializeNewCommentThread(headComment);
    const newThreadSlice: CommentThreadSlice = {
        ...prevThreadSlice,
        // Add an entry for the thread into our map
        byHeadCommentId: {
            ...prevThreadSlice.byHeadCommentId,
            [newThread.headCommentId]: newThread,
        },
        // Brand new threads created by the user are always inserted at the front of the list
        displayOrder: [newThread.headCommentId, ...prevThreadSlice.displayOrder],
    };

    // Find or Create the appropriate CommentThreadGroup to host the new CommentThread
    const commentGroupKey = createGroupHeader(headComment.createdAt);
    const existingGroup: undefined | CommentThreadGroup = prevGroupSlice.byGroupHeader[commentGroupKey];
    const compatibleGroup = existingGroup ?? createCommentThreadGroup(headComment.createdAt);

    // Add the headComment id to the compatible group
    const newGroupSlice: CommentThreadGroupSlice = {
        ...prevGroupSlice,
        byGroupHeader: {
            ...prevGroupSlice.byGroupHeader,
            [commentGroupKey]: {
                // compatibleGroup is either the group that already existed in the map at this groupKey, or
                // a brand new group where no group previously existed in the map at this groupKey. Spread
                // operator works for correct immutable update in either scenario.
                ...compatibleGroup,
                // Always display newly created comment threads at the front of the group
                headCommentIds: [headComment.frontEndId, ...compatibleGroup.headCommentIds],
            },
        },
        displayOrder: Boolean(existingGroup)
            ? prevGroupSlice.displayOrder
            : [compatibleGroup.groupHeader, ...prevGroupSlice.displayOrder],
    };

    return {
        ...prevState,
        comments: newCommentSlice,
        commentThreads: newThreadSlice,
        commentThreadGroups: newGroupSlice,
    };
};
