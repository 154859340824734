import { CommentsViewState } from "src/components/CommentsView/state/reducer/reducer";
import { AddingNewReplyCommentAction } from "src/components/CommentsView/state/actions";

export const addingNewReplyCommentReducer = (
    prevState: CommentsViewState,
    action: AddingNewReplyCommentAction
): CommentsViewState => {
    const { replyComment } = action.payload;

    const newState: CommentsViewState = {
        ...prevState,
        // Add an entry for replyComment into the byFrontEndId map
        comments: {
            ...prevState.comments,
            byFrontEndId: {
                ...prevState.comments.byFrontEndId,
                [replyComment.frontEndId]: replyComment,
            },
        },
        // Update the CommentThread containing the replyComment's parentId.
        commentThreads: {
            ...prevState.commentThreads,
            byHeadCommentId: {
                ...prevState.commentThreads.byHeadCommentId,
                [replyComment.frontEndParentId]: {
                    ...prevState.commentThreads.byHeadCommentId[replyComment.frontEndParentId],
                    // The CommentThread's `lastUpdated` property is updated to be the replyComment's
                    // `createdAt` value
                    lastUpdated: replyComment.createdAt,
                    // register reply comment to the end of the Thread's list of comments
                    commentIds: [
                        ...prevState.commentThreads.byHeadCommentId[replyComment.frontEndParentId].commentIds,
                        replyComment.frontEndId,
                    ],
                },
            },
        },
        // CommentTheadGroups should not receive any updates for this action type
    };

    return newState;
};
