/* Represents the response shape of the Two new Summary APIs */
import { ContentDescriptor } from "src/services/summaries/model/content-descriptors";

export const PERSONAL_DATA_SUMMARY_NAME = "Personal Data Summary";
export const PRIVACY_ALERTS_SUMMARY_NAME = "Privacy Alerts Summary";

export interface SummaryResponse {
    /* A timestamp indicating when the backing projection file was created.
     * The UI will use this to tell user how old the current summary data is
     */
    createdAt: string;
    /* A boolean to represent whether the cached summary data is being returned is
     * based on the latest application state. This value should be false if a new
     * projection file is being written/calculated at the time that this API was
     * called. The UI will use this value to tell the user when the summary data
     * is being recalculated
     */
    isCurrent: boolean;
    /* The main summary data */
    summary: Summary;
}

/* Represents the data required to render a summary card */
export interface Summary {
    /** Used in UI as the card title */
    title: string;

    content: ContentDescriptor[];
}

export interface FetchSummaryResponseOptions {
    resourceName: string;
    appName: string;
    apiEndpoint: string;
}
