import {
    DataStoreAnswerGetter,
    DataStoreHookAnswer,
    DataStoreQuestion,
    DataStoresAccessor,
    DataStoresAccessorReadonly,
    QuestionTag,
    QuestionType,
    RetentionInputType,
} from "src/answers_legacy";
import { DataStoreInstance } from "src/components/survey/DataStoreInfo/DataStoreWizard/CollectDataStoreInfo";
import { DataStoreResponse } from "src/components/survey/DataStoreInfo";
import { IntKeyValuePairs } from "src/components/survey/LegalSurveyPage";
import { Answer } from "src/services/KaleApplicationService";
import { hasTAFUrl } from "src/util/Urls";
import { DELETION_OBLIGATIONS_SHORT_ID } from "src/components/TAF/TAFDetails/constants";

// Backend will use 0 to indicate a parentId does not exist
const NO_PARENT_ID = 0;

export interface QuestionCollections<AccessorAnswerType extends DataStoreAnswerGetter | DataStoreHookAnswer> {
    questionsIdMap: IntKeyValuePairs<AccessorAnswerType>;
    childrenIdMap: IntKeyValuePairs<number[]>;
    titledQuestions: AccessorAnswerType[];
}

export const isAndes = (technology?: string): boolean => {
    return technology === "Andes";
};

export const isRedshift = (technology?: string): boolean => {
    return technology === "Redshift";
};

export const isDataStoreInstanceValid = (instance: DataStoreInstance): boolean =>
    instance.identifiers.every((identifier): boolean => Boolean(identifier.value));

export const isDataStoreInstancesValid = (instances: DataStoreInstance[]): boolean => {
    if (instances.length === 0) {
        return false;
    }
    return instances.every(isDataStoreInstanceValid);
};

export const getAnswerAsString = (question: DataStoreQuestion, answers: Answer[]): string | null => {
    switch (question.type) {
        case QuestionType.radio:
        case QuestionType.autoSuggest:
        case QuestionType.text:
        case QuestionType.singleSelect:
        case QuestionType.checkbox:
        case QuestionType.textArea:
            return (
                answers.find(
                    (answer): boolean =>
                        answer.questionShortId === question.shortId || answer.questionId === question.id
                )?.textContent ?? null
            );
        case QuestionType.date:
            return (
                answers.find(
                    (answer): boolean =>
                        answer.questionShortId === question.shortId || answer.questionId === question.id
                )?.dateContent ?? null
            );
        case QuestionType.retention: {
            const answer = answers.find(
                (answer): boolean => answer.questionShortId === question.shortId || answer.questionId === question.id
            )?.jsonContent;
            const checkboxAnswer = answer?.[RetentionInputType.retentionCheckbox];
            const textAnswer = answer?.[RetentionInputType.retentionText];
            const singleSelectAnswer = answer?.[RetentionInputType.retentionSingleSelect];

            const displayText = [];

            if (checkboxAnswer) {
                displayText.push(checkboxAnswer);
            }

            if (textAnswer) {
                displayText.push(`${textAnswer} ${singleSelectAnswer}`);
            }

            return displayText.join("; ");
        }
        case QuestionType.multiSelect:
        case QuestionType.textTags:
        case QuestionType.checkboxGroup:
            return (
                answers
                    .find(
                        (answer): boolean =>
                            answer.questionShortId === question.shortId || answer.questionId === question.id
                    )
                    ?.arrayContent?.join(",") ?? null
            );
        default:
            return null;
    }
};

export const isDataStoresInfoStepValid = (responses: DataStoreResponse[]): boolean => {
    return responses.every((response): boolean => {
        return (
            Boolean(response.technology) &&
            Boolean(response.name) &&
            (isAndes(response.technology) || isDataStoreInstancesValid(response.instances))
        );
    });
};

export const generateCollectionsFromQuestionsData = <
    AccessorType extends DataStoresAccessor | DataStoresAccessorReadonly,
    AccessorAnswerType extends DataStoreAnswerGetter | DataStoreHookAnswer
>(
    activeDataStoreAccessor?: AccessorType | null
): QuestionCollections<AccessorAnswerType> => {
    const questionsIdMap: IntKeyValuePairs<AccessorAnswerType> = {};
    const childrenIdMap: IntKeyValuePairs<number[]> = {};
    const titledQuestions: AccessorAnswerType[] = [];

    const hasTaf = hasTAFUrl();

    activeDataStoreAccessor?.answers
        .filter((item): boolean => {
            // Hide deletion obligation question because it is handled outside of the wizard
            return !(hasTaf && item.question.shortId === DELETION_OBLIGATIONS_SHORT_ID);
        })
        .forEach((managedDataStoreQuestion): void => {
            const question = managedDataStoreQuestion.answerWithQuestion.question;
            const { id: questionId, parentId = NO_PARENT_ID, title } = question;

            if (!(questionId in childrenIdMap)) {
                // Each question will have an entry, even if its only an empty list
                childrenIdMap[questionId] = [];
            }
            const isChildQuestion = parentId !== NO_PARENT_ID;
            if (isChildQuestion) {
                if (!(parentId in childrenIdMap)) {
                    childrenIdMap[parentId] = [];
                }

                childrenIdMap[parentId].push(questionId);
            }

            questionsIdMap[questionId] = managedDataStoreQuestion as AccessorAnswerType;

            if (hasTaf && !question.tags.includes(QuestionTag.tafIgnoreTitle) && Boolean(title)) {
                titledQuestions.push(managedDataStoreQuestion as AccessorAnswerType);
            } else if (Boolean(title)) {
                titledQuestions.push(managedDataStoreQuestion as AccessorAnswerType);
            }
        });

    return {
        questionsIdMap,
        childrenIdMap,
        titledQuestions,
    };
};

/**
 * Takes a list of Datastore questions returned from the server, sorts them into pre-traversal order of their family
 * tree, based on the parent/child relationships of every question. This is the same question order used by the data
 * store wizard
 */
export const getQuestionsInStepOrder = (questions: DataStoreQuestion[]): DataStoreQuestion[] => {
    const steps = new Array<DataStoreQuestion>();
    const childIdMap: IntKeyValuePairs<number[]> = {};
    const questionsIdMap: IntKeyValuePairs<DataStoreQuestion> = {};
    questions.forEach((question): void => {
        const { parentId = NO_PARENT_ID } = question;
        if (!(question.id in childIdMap)) {
            childIdMap[question.id] = [];
        }
        if (question.parentId !== 0) {
            if (parentId in childIdMap) {
                childIdMap[parentId].push(question.id);
            } else {
                childIdMap[parentId] = [question.id];
            }
        }
        questionsIdMap[question.id] = question;
        if (Boolean(question.title)) {
            steps.push(question);
        }
    });
    const result = new Array<DataStoreQuestion>();
    steps.forEach((step): void => {
        result.push(...getSubQuestions(step, childIdMap, questionsIdMap));
    });

    return result;
};

const getSubQuestions = (
    question: DataStoreQuestion,
    childIdMap: IntKeyValuePairs<number[]>,
    questionsIdMap: IntKeyValuePairs<DataStoreQuestion>
): DataStoreQuestion[] => {
    const result = new Array<DataStoreQuestion>();
    result.push(question);
    const subQuestionsIds = childIdMap[question.id] ?? [];
    subQuestionsIds.forEach((questionId): void => {
        if (!Boolean(questionsIdMap[questionId].title)) {
            const subQuestions = getSubQuestions(questionsIdMap[questionId], childIdMap, questionsIdMap);
            if (Boolean(subQuestions.length)) {
                result.push(...subQuestions);
            }
        }
    });

    return result;
};
