import styled from "styled-components";
import { Badge } from "@amzn/awsui-components-react-v3";
import { ApplicationStatus } from "src/components/survey/SurveyFormModel";

export const StyledBadge = styled(Badge)`
    cursor: pointer !important;
`;

export const StyledSpan = styled.span`
    cursor: pointer !important;
`;

export const colorMap = new Map<ApplicationStatus, "blue" | "grey" | "green" | "red">([
    [ApplicationStatus.inProgress, "grey"],
    [ApplicationStatus.inReview, "blue"],
    [ApplicationStatus.approved, "green"],
    [ApplicationStatus.archived, "green"],
    [ApplicationStatus.recallInProgress, "blue"],
    [ApplicationStatus.calculating, "blue"],
    [ApplicationStatus.notAnalyzed, "blue"],
    [ApplicationStatus.notApplicable, "blue"],
]);
