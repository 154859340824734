/* eslint-disable max-len */
import {
    DataStoreAnswer,
    DataStoreHookAnswer,
    DataStoreQuestion,
    HookAnswer,
    QuestionTag,
    QuestionType,
    RetentionInputType,
    RetentionJsonContentType,
} from "src/answers_legacy";
import { FormField, Grid } from "@amzn/awsui-components-react-v3";
import React from "react";
import { makeOptionFromChoice, PrefilledAnswerValue } from "src/services/dynamic-questions";
import KaleRadiobutton from "src/components/fields/select/KaleRadiobutton";
import { FunctionalSurveyItemResult } from "src/components/survey/SurveyItem";
import NewAutosuggest from "src/components/fields/textbox/NewAutosuggest";
import FnDatePicker from "src/components/fields/textbox/NewDatePicker";
import NewDropdown from "src/components/fields/dropdown/NewDropdown";
import TextBoxWithTags from "src/components/fields/textbox/TextBoxWithTags";
import NewTextArea from "src/components/fields/textbox/NewTextArea";
import NewTextInput from "src/components/fields/textbox/NewTextInput";
import KaleCheckboxGroup from "src/components/fields/select/KaleCheckboxGroup";
import {
    NOT_YET_SUPPORTED,
    RetentionCheckboxLabel,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/WizardStepQuestionsBuilder";
import MultiSelectQuestion from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/MultiSelectQuestion";
/* eslint-enable max-len */

const renderRadiobutton = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    // hide the prefilled choice and value from users
    const choices = item.question.tags.includes(QuestionTag.hiddenPrefilledAnswer)
        ? item.question.choices.filter((value): boolean => {
              return value.value !== PrefilledAnswerValue.NA;
          })
        : item.question.choices;
    let answer = (item.value as string) ?? "";
    if (item.question.tags.includes(QuestionTag.hiddenPrefilledAnswer) && answer === PrefilledAnswerValue.NA) {
        answer = "";
    }

    return (
        <KaleRadiobutton
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            choices={choices}
            expectedAnswer={answer}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderAutoSuggest = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    const options = item.question.choices.map((choice): string => choice.value);

    return (
        <NewAutosuggest
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            options={options}
            expectedAnswer={(item.value as string) ?? ""}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderDatePicker = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    return (
        <FnDatePicker
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            expectedAnswer={(item.value as string) ?? ""}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderSingleSelect = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    const teams = item.question.choices.map(makeOptionFromChoice);

    return (
        <NewDropdown
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            options={teams}
            expectedAnswer={(item.value as string) ?? ""}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderTextTags = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    return (
        <TextBoxWithTags
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            expectedAnswer={(item.value as string[]) ?? []}
            onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderTextArea = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    return (
        <NewTextArea
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            expectedAnswer={(item.value as string) ?? ""}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderTextInput = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    return (
        <NewTextInput
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            expectedAnswer={(item.value as string) ?? ""}
            onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderCheckboxGroup = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    return (
        <KaleCheckboxGroup
            id={item.question.shortId}
            isRequired={item.question.tags.includes(QuestionTag.required)}
            choices={item.question.choices}
            expectedAnswer={(item.value as string[]) ?? []}
            onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                item.setValue(result.response);
            }}
        />
    );
};

const renderRetentionInput = (item: HookAnswer<DataStoreQuestion, DataStoreAnswer>): JSX.Element => {
    const retentionSingleSelectOptions = item.question.choices.map(makeOptionFromChoice);
    const retentionCheckboxAnswer = (item.value as RetentionJsonContentType)[RetentionInputType.retentionCheckbox];
    const retentionTextAnswer = (item.value as RetentionJsonContentType)[RetentionInputType.retentionText] ?? "";
    const retentionSingleSelectAnswer =
        (item.value as RetentionJsonContentType)[RetentionInputType.retentionSingleSelect] ?? "";

    const isValidRetentionText = !retentionCheckboxAnswer ? /^-?\d+$/.test(retentionTextAnswer) : true;

    return (
        <Grid gridDefinition={[{ colspan: 12 }, { colspan: 6 }, { colspan: 6 }]}>
            <KaleCheckboxGroup
                id={`${item.question.shortId}_checkbox`}
                isRequired={item.question.tags.includes(QuestionTag.required)}
                choices={[{ value: RetentionCheckboxLabel(item.question) }]}
                expectedAnswer={retentionCheckboxAnswer ? [RetentionCheckboxLabel(item.question)] : []}
                onChangeCallback={(result: FunctionalSurveyItemResult<string[]>): void => {
                    const retentionCheckboxAnswer = result.response[0] ?? "";
                    item.setValue({
                        [RetentionInputType.retentionText]: !retentionCheckboxAnswer ? retentionTextAnswer : "",
                        [RetentionInputType.retentionSingleSelect]: !retentionCheckboxAnswer
                            ? retentionSingleSelectAnswer
                            : "",
                        [RetentionInputType.retentionCheckbox]: retentionCheckboxAnswer,
                    });
                }}
            />
            <NewTextInput
                id={`${item.question.shortId}_text`}
                placeholder="Number"
                isReadonly={Boolean(retentionCheckboxAnswer)}
                isRequired={item.question.tags.includes(QuestionTag.required)}
                isValid={isValidRetentionText}
                validationMsg={"Please enter a valid number"}
                expectedAnswer={retentionTextAnswer}
                onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                    item.setValue({
                        [RetentionInputType.retentionCheckbox]: retentionCheckboxAnswer,
                        [RetentionInputType.retentionSingleSelect]: retentionSingleSelectAnswer,
                        [RetentionInputType.retentionText]: result.response,
                    });
                }}
            />
            <NewDropdown
                id={`${item.question.shortId}_singleSelect`}
                placeholder="Unit of measurement"
                isReadonly={Boolean(retentionCheckboxAnswer)}
                isRequired={item.question.tags.includes(QuestionTag.required)}
                options={retentionSingleSelectOptions}
                expectedAnswer={retentionSingleSelectAnswer}
                onChangeCallback={(result: FunctionalSurveyItemResult<string>): void => {
                    item.setValue({
                        [RetentionInputType.retentionCheckbox]: retentionCheckboxAnswer,
                        [RetentionInputType.retentionText]: retentionTextAnswer,
                        [RetentionInputType.retentionSingleSelect]: result.response,
                    });
                }}
            />
        </Grid>
    );
};

const renderQuestionContentByType = (item: DataStoreHookAnswer): JSX.Element => {
    const question = item.question;

    switch (question.type) {
        case QuestionType.radio:
            return renderRadiobutton(item);
        case QuestionType.autoSuggest:
            return renderAutoSuggest(item);
        case QuestionType.date:
            return renderDatePicker(item);
        case QuestionType.singleSelect: {
            return renderSingleSelect(item);
        }
        case QuestionType.multiSelect:
            return <MultiSelectQuestion item={item} />;
        case QuestionType.textTags:
            return renderTextTags(item);
        case QuestionType.textArea:
            return renderTextArea(item);
        case QuestionType.text:
            return renderTextInput(item);
        case QuestionType.checkboxGroup:
            return renderCheckboxGroup(item);
        case QuestionType.retention:
            return renderRetentionInput(item);
        default:
            return <FormField>{NOT_YET_SUPPORTED}</FormField>;
    }
};

export default renderQuestionContentByType;
