// For universal questions such as table name, field name, etc that are synthetic
export const SYNTH_UNIVERSAL_COMPLIANCE_TYPE = "";
export const SYNTH_METADATA_COMPLIANCE_TYPE = "Synthetic_Metadata_Questions";

export const FETCH_DATA_ELEMENTS_ERROR = "Error fetching data elements, please refresh the page.";
export const FETCH_TABLE_ERROR = "Error fetching table details, please refresh the page.";
export const UPDATE_TABLE_ERROR =
    "An error occurred while trying to save your changes, please retry the action once more.";

export const RETENTION_BUS_USE_CASE_TABLE_SHORT_ID = "retention_table_business_use_case";
export const ANDES_PD2_BUS_USE_CASE_TABLE_SHORT_ID = "andes_pd2_table_business_use_case";
export const RETENTION_BUS_USE_CASE_FIELD_SHORT_ID = "retention_field_business_use_case";
export const ANDES_PD2_BUS_USE_CASE_FIELD_SHORT_ID = "andes_pd2_field_business_use_case";
export const ANDES_PD2_DATE_KEY_TABLE_SHORT_ID = "andes_pd2_table_date_key";
