export const TEST_IDS = {
    TABLE_DETAILS_PAGE: {
        APP_LAYOUT: "table-details-page--app-layout",
        ROOT: "table-details-page--root",
        LOADING_SPINNER: "table-details-page--loading-spinner",
        LOADED_CONTENT: "table-details-page--content",
        TABLE_DETAILS_FORM: {
            ROOT: "table-details-form--root",
            FORM_TITLE: "table-details-form--title",
            CANCEL_BUTTON: "table-details-form--cancel-button",
            SAVE_BUTTON: "table-details-form--save-button",
            TABLE_QUESTIONS_PANEL: {
                ANDES_CRADLE_METADATA: {
                    LOADING_SPINNER: "andes-cradle-metadata--loading-spinner",
                    ERROR_MESSAGE: "andes-cradle-metadata--error-message",
                    LOADED_CONTENT: "andes-cradle-metadata--loaded-content",
                },
            },
        },
    },
};
