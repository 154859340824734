import { useContext, useMemo, useRef } from "react";
import { answerActionsContext, Answers, AnswerState, answerStateContext, Response, Validation } from "../context";
import { AnswerActions, StateKey } from "../actions";
import {
    DefaultReviewAnswer,
    ReviewAnswer,
    ReviewAnswerKey,
    ReviewAnswerWithQuestion,
    ReviewQuestion,
} from "../models/services";
import { getAnswerResponseState, HookAnswer } from "./common";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";

export type ReviewHookAnswer = HookAnswer<ReviewQuestion, ReviewAnswer>;
export interface ReviewAccessor extends Validation {
    answers: ReviewHookAnswer[];
}

export function getReview(state: AnswerState, reviewId?: number): Answers<ReviewAnswerWithQuestion> | undefined {
    return reviewId
        ? state[StateKey.review].find(
              (item): boolean => item.value[0] && item.value[0].value.answer[ReviewAnswerKey.reviewId] === reviewId
          )
        : state[StateKey.review][0];
}

export type ReviewAnswerActions = AnswerActions<ReviewQuestion, ReviewAnswer>;

export function getReviewAnswerById(
    state: AnswerState,
    actions: ReviewAnswerActions,
    questionShortId?: number | string,
    reviewId?: number
): HookAnswer<ReviewQuestion, ReviewAnswer> | void {
    const reviewState = getReview(state, reviewId);

    const questionWithAnswer = reviewState?.value.find((answer: Response<ReviewAnswerWithQuestion>): boolean => {
        const id = answer.value.answer.questionShortId;
        return id === questionShortId;
    });

    if (!questionWithAnswer) {
        console.error(`Invalid requested review answer by short id "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<ReviewQuestion, ReviewAnswer>(questionWithAnswer, actions);
}

export const useReview = (): [ReviewAccessor, ReviewAnswerActions] => {
    const actions = useContext(answerActionsContext)<ReviewQuestion, ReviewAnswer>(StateKey.review);
    const state = useContext(answerStateContext);
    const reviewState = state[StateKey.review];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [ReviewAccessor, ReviewAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            reviewState.map((review): ReviewAccessor => {
                return {
                    answers: review.value
                        .map((questionWithAnswer): HookAnswer<ReviewQuestion, ReviewAnswer> | void =>
                            getReviewAnswerById(state, actions, questionWithAnswer.value.answer.questionShortId)
                        )
                        .filter((v): boolean => !!v) as HookAnswer<ReviewQuestion, ReviewAnswer>[],
                    isValid: review.isValid,
                    isValidSave: review.isValidSave,
                    isValidSubmit: review.isValidSubmit,
                    hasChanged: review.hasChanged,
                    userLastUpdated: review.userLastUpdated,
                };
            })[0], // Return first review only, more not needed
            actions,
        ];
    }, [reviewState]);
};

export type ReviewPayload = ReviewAnswer[];
export const useReviewPayload = (): ReviewPayload => {
    const state = useContext(answerStateContext);
    const reviewState = state[StateKey.review];

    return useMemo(
        (): ReviewPayload =>
            reviewState
                .map((review): ReviewAnswer[] =>
                    review.value.map((questionWithAnswer): ReviewAnswer => questionWithAnswer.value.answer)
                )[0] // Return first review only, more not needed
                .map((answer): ReviewAnswer => {
                    const base = DefaultReviewAnswer(
                        answer.questionId,
                        answer.questionShortId,
                        answer[ReviewAnswerKey.reviewId],
                        answer[ReviewAnswerKey.userReviewId]
                    );

                    return { ...base, ...answer };
                })
                .filter((answer): boolean => meetsServerAnswerConstraint(answer)),
        [reviewState]
    );
};
