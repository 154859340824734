import { ExpandableSection, Header } from "@amzn/awsui-components-react-v3";
import React, { useState } from "react";
import { Model } from "src/components/survey/ApplicationIdentity/model/utils";
import { KeyValuePairs } from "src/components/survey/ApplicationIdentity/KeyValuePairs";

export const ApplicationIdentity = (props: Model): JSX.Element => {
    const [isContainerExpanded, setIsContainerExpanded] = useState<boolean>(true);
    const toggleIsExpanded = (): void => setIsContainerExpanded((isExpanded): boolean => !isExpanded);

    return (
        <ExpandableSection
            expanded={isContainerExpanded}
            onChange={toggleIsExpanded}
            variant={"container"}
            header={<Header variant={"h3"}>Application Identity</Header>}
        >
            <KeyValuePairs application={props.application} bindles={props.bindles} />
        </ExpandableSection>
    );
};
