/* eslint-disable max-len */
import React, { ChangeEventHandler } from "react";
import { useAutoSizingTextareaElementRef } from "src/components/TableDetails/AutoSizingTextArea/utils";
import { AutoSizingTextareaProps, OnChangeDetail } from "src/components/TableDetails/AutoSizingTextArea/types";
import { StyledAutoSizingTextarea } from "src/components/TableDetails/AutoSizingTextArea/styled";
/* eslint-enable max-len */

const DEFAULT_MIN_ROWS = 1;
export const TEST_IDS = {
    TEXTAREA_ELEMENT: "auto-sizing-textarea--textarea",
};

const AutoSizingTextarea = (props: AutoSizingTextareaProps): JSX.Element => {
    const { value, minRows = DEFAULT_MIN_ROWS, placeholder, disabled, invalid, onChange: onChangeCb, onBlur } = props;

    const autoSizingTextAreaElementRef = useAutoSizingTextareaElementRef(value, minRows);

    const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e): void => {
        e.preventDefault();
        if (onChangeCb) {
            // Convert native HTMLTextAreaElement change event into Polaris 3 compatible change event
            const detail: OnChangeDetail = { value: e.currentTarget.value };
            onChangeCb({ detail });
        }
    };

    return (
        <StyledAutoSizingTextarea
            data-testid={TEST_IDS.TEXTAREA_ELEMENT}
            data-invalid={invalid}
            ref={autoSizingTextAreaElementRef}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            invalid={invalid}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default AutoSizingTextarea;
