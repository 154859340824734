import { useContext, useMemo, useRef } from "react";
import { answerActionsContext, Answers, AnswerState, answerStateContext, Response, Validation } from "../context";
import { AnswerActions, StateKey } from "../actions";
import {
    DefaultTableFieldAnswer,
    TableFieldAnswer,
    TableFieldAnswerWithQuestion,
    TableFieldQuestion,
} from "../models/services";
import { HookAnswer, getAnswerResponseState } from "./common";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";

export type TableFieldHookAnswer = HookAnswer<TableFieldQuestion, TableFieldAnswer>;
export interface KaleTableFieldsAccessor extends Validation {
    answers: TableFieldHookAnswer[];
}

export function getKaleTableField(
    state: AnswerState,
    fieldId?: number,
    userFieldId?: string
): Answers<TableFieldAnswerWithQuestion> | undefined {
    return fieldId || userFieldId
        ? state[StateKey.kaleTableFields].find(
              (item): boolean =>
                  item.value[0] &&
                  (fieldId
                      ? item.value[0].value.answer.fieldId === fieldId
                      : item.value[0].value.answer.userFieldId === userFieldId)
          )
        : state[StateKey.kaleTableFields][0];
}

export type TableFieldsAnswerActions = AnswerActions<TableFieldQuestion, TableFieldAnswer>;

export function getKaleTableFieldAnswerById(
    state: AnswerState,
    actions: TableFieldsAnswerActions,
    questionShortId?: string,
    fieldId?: number,
    userFieldId?: string
): HookAnswer<TableFieldQuestion, TableFieldAnswer> | void {
    const kaleTableFieldState = getKaleTableField(state, fieldId, userFieldId);

    const questionWithAnswer = kaleTableFieldState?.value.find(
        (answer: Response<TableFieldAnswerWithQuestion>): boolean => {
            const id = answer.value.answer.questionShortId;
            return id === questionShortId;
        }
    );

    if (!questionWithAnswer) {
        console.error(`Invalid requested field answer by short id "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<TableFieldQuestion, TableFieldAnswer>(questionWithAnswer, actions);
}

export const useKaleTableFields = (): [KaleTableFieldsAccessor[], TableFieldsAnswerActions] => {
    const actions = useContext(answerActionsContext)<TableFieldQuestion, TableFieldAnswer>(StateKey.kaleTableFields);
    const state = useContext(answerStateContext);
    const fieldsState = state[StateKey.kaleTableFields];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [KaleTableFieldsAccessor[], TableFieldsAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            fieldsState
                .map((tableField): KaleTableFieldsAccessor => {
                    return {
                        answers: tableField.value
                            .map(
                                (
                                    questionWithAnswer: Response<TableFieldAnswerWithQuestion>
                                ): HookAnswer<TableFieldQuestion, TableFieldAnswer> | void => {
                                    const { fieldId, questionShortId, userFieldId } = questionWithAnswer.value.answer;
                                    return getKaleTableFieldAnswerById(
                                        state,
                                        actions,
                                        questionShortId,
                                        fieldId,
                                        userFieldId
                                    );
                                }
                            )
                            .filter((v): boolean => !!v) as HookAnswer<TableFieldQuestion, TableFieldAnswer>[],
                        isValid: tableField.isValid,
                        isValidSave: tableField.isValidSave,
                        isValidSubmit: tableField.isValidSubmit,
                        hasChanged: tableField.hasChanged,
                        userLastUpdated: tableField.userLastUpdated,
                    };
                })
                .filter((accessor): boolean => accessor.answers.length > 0),
            actions,
        ];
    }, [fieldsState]);
};

interface ComplianceTypeMap {
    [k: string]: string;
}

export type KaleTableFieldPayload = TableFieldAnswer[];
export const useKaleTableFieldsPayloads = (): KaleTableFieldPayload[] => {
    const state = useContext(answerStateContext);
    const fieldsState = state[StateKey.kaleTableFields];

    return useMemo((): KaleTableFieldPayload[] => {
        const complianceTypeMap: ComplianceTypeMap = {};
        return fieldsState
            .map((tableField): TableFieldAnswer[] =>
                tableField.value.map((questionWithAnswer): TableFieldAnswer => {
                    complianceTypeMap[questionWithAnswer.value.question.id] =
                        questionWithAnswer.value.question.complianceType;
                    return questionWithAnswer.value.answer;
                })
            )
            .map((answers): TableFieldAnswer[] =>
                answers
                    .map((answer): TableFieldAnswer => {
                        const base = DefaultTableFieldAnswer(
                            answer.questionId,
                            answer.questionShortId,
                            answer.fieldId,
                            answer.userFieldId
                        );

                        return {
                            ...base,
                            ...answer,
                            complianceType: complianceTypeMap[answer.questionId],
                        };
                    })
                    .filter((answer): boolean => meetsServerAnswerConstraint(answer))
            );
    }, [fieldsState]);
};
