export interface WrappedItemDescriptor {
    ItemDescriptor: ItemDescriptor;
}
export interface WrappedListDescriptor {
    ListDescriptor: ListDescriptor;
}

// Describes renderable content of varying types
export type ContentDescriptor = WrappedItemDescriptor | WrappedListDescriptor;

interface BaseDescriptor {
    // This will be used as a react key when rendering elements
    id: number;
}

// Describes a renderable item
export interface ItemDescriptor extends BaseDescriptor {
    /** UI will render this value */
    value: string;
    // Describes additional items or lists of items related to this descriptor
    descriptors: ContentDescriptor[];
}

export enum ListStyle {
    Ordered = "Ordered",
    Unordered = "Unordered",
}

// Describes a list of renderable items and what style of list decoration to apply
export interface ListDescriptor extends BaseDescriptor {
    /** Informs how the list items will appear in the UI */
    listStyle: ListStyle;
    /** The list items to renders */
    items: ItemDescriptor[];
}

// TS Type Guards
export const isWrappedItemDescriptor = (content: ContentDescriptor): content is WrappedItemDescriptor => {
    return !!(content as WrappedItemDescriptor).ItemDescriptor;
};
export const isWrappedListDescriptor = (content: ContentDescriptor): content is WrappedListDescriptor => {
    return !!(content as WrappedListDescriptor).ListDescriptor;
};
