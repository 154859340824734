import React, { useContext, useEffect, useState } from "react";
import { Box, SpaceBetween, TableProps, Tabs } from "@amzn/awsui-components-react-v3";
import { useParams } from "react-router-dom";
import { Button } from "@amzn/awsui-components-react-v3/polaris";
import { JobView } from "src/services/JobService";
import KaleContext from "src/components/KaleContext";
import { downloadBlob } from "src/util/downloads";
import DASTable, { DASTableFilterType } from "src/components/fields/table/DASTable";
import Error from "src/util/Error";
import { KaleApplicationRouteParams } from "src/components/survey/KaleApplication/KaleApplicationRoutes";
import { BreadcrumbItems } from "src/util/Breadcrumbs";
import KaleBreadcrumbGroup from "src/components/KaleBreadcrumbGroup";
import styled from "styled-components";

export const TEST_IDS = {
    EXPORT_JOB_TABLE: "export-job-table",
    EXPORT_JOB_TABLE_DOWNLOAD: "export-job-table-download-button",
    EXPORT_JOB_TABLE_REFRESH: "export-job-table-refresh-button",

    IMPORT_JOB_TABLE: "import-job-table",
    IMPORT_JOB_TABLE_REFRESH: "import-job-table-refresh-button",

    ERR_MESSAGE_EXPORT: "error-message-export",
    ERR_MESSAGE_IMPORT: "error-message-import",
    ERR_MESSAGE_EXPORT_DOWNLOAD: "error-message-export-download",

    TABS: "import-export-tabs",
};

const ActionsContainer = styled.div`
    width: max-content;
    margin: 0 auto;
`;

export const CSVJobsActivityPage = (): JSX.Element => {
    const params = useParams<KaleApplicationRouteParams>();
    const [selectedExport, setSelectedExport] = useState<JobView>();
    const [exportJobs, setExportJobs] = useState<JobView[]>([]);
    const [importJobs, setImportJobs] = useState<JobView[]>([]);
    const [isExportLoading, setExportIsLoading] = useState<boolean>(true);
    const [isImportLoading, setImportIsLoading] = useState<boolean>(true);
    const [failedToLoadExport, setFailedToLoadExport] = useState<boolean>(false);
    const [failedToLoadImport, setFailedToLoadImport] = useState<boolean>(false);
    const [failedToDownloadFile, setFailedToDownloadFile] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { jobService, kaleTablesService } = useContext(KaleContext).service;

    useEffect((): void => {
        jobService
            .listLatestJobViewsByAppAndType(params.applicationName, "csv_export")
            .then((jobs: JobView[]): void => {
                setExportJobs(jobs);
            })
            .catch((err): void => {
                console.error(err);
                setFailedToLoadExport(true);
            })
            .finally((): void => {
                setExportIsLoading(false);
            });
        // The intention of this is to run exactly once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        jobService
            .listLatestJobViewsByAppAndType(params.applicationName, "csv_import")
            .then((jobs: JobView[]): void => {
                setImportJobs(jobs);
            })
            .catch((err): void => {
                console.error(err);
                setFailedToLoadImport(true);
            })
            .finally((): void => {
                setImportIsLoading(false);
            });
        // The intention of this is to run exactly once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickDownload = ({ jobID, name }: JobView): void => {
        setIsDownloading(true);
        setFailedToDownloadFile(false);
        kaleTablesService
            .fetchSignedS3URLTableCSVExport(jobID)
            .then(({ s3URL }): void => downloadS3File(s3URL, `${name}.csv`))
            .catch((err): void => {
                console.error(err);
                setFailedToDownloadFile(true);
            })
            .finally((): void => {
                setIsDownloading(false);
            });
    };

    const onClickExportRefresh = (): void => {
        setExportIsLoading(true);
        setFailedToLoadExport(false);
        jobService
            .listLatestJobViewsByAppAndType(params.applicationName, "csv_export")
            .then((jobs: JobView[]): void => {
                setExportJobs(jobs);
            })
            .catch((err): void => {
                console.error(err);
                setFailedToLoadExport(true);
            })
            .finally((): void => {
                setExportIsLoading(false);
            });
    };

    const onClickImportRefresh = (): void => {
        setImportIsLoading(true);
        setFailedToLoadImport(false);
        jobService
            .listLatestJobViewsByAppAndType(params.applicationName, "csv_import")
            .then((jobs: JobView[]): void => {
                setImportJobs(jobs);
            })
            .catch((err): void => {
                console.error(err);
                setFailedToLoadImport(true);
            })
            .finally((): void => {
                setImportIsLoading(false);
            });
    };

    const exportActions = (exportJobs: JobView[], selectedExport?: JobView): JSX.Element => {
        return (
            <ActionsContainer>
                <SpaceBetween size={"xs"} direction={"horizontal"}>
                    <div>
                        {failedToLoadExport && (
                            <Error
                                data-testid={TEST_IDS.ERR_MESSAGE_EXPORT}
                                text={"failed to load, please try refreshing"}
                            />
                        )}
                        {failedToDownloadFile && (
                            <Error
                                data-testid={TEST_IDS.ERR_MESSAGE_EXPORT_DOWNLOAD}
                                text={"failed to download export, please try again"}
                            />
                        )}
                    </div>
                    <Button
                        data-testid={TEST_IDS.EXPORT_JOB_TABLE_DOWNLOAD}
                        onClick={(): void => {
                            if (selectedExport) {
                                onClickDownload(selectedExport);
                            }
                        }}
                        disabled={!selectedExport || selectedExport?.status !== "ready to download"}
                        iconName={"download"}
                        loading={isDownloading}
                    >
                        Download
                    </Button>
                    <Button
                        data-testid={TEST_IDS.EXPORT_JOB_TABLE_REFRESH}
                        iconName={"refresh"}
                        loading={isExportLoading}
                        onClick={(): void => onClickExportRefresh()}
                    >
                        Refresh
                    </Button>
                </SpaceBetween>
            </ActionsContainer>
        );
    };

    const importActions = (): JSX.Element => {
        return (
            <ActionsContainer>
                <SpaceBetween size={"xs"} direction={"horizontal"}>
                    <div>
                        {failedToLoadImport && (
                            <Error
                                data-testid={TEST_IDS.ERR_MESSAGE_IMPORT}
                                text={"failed to load, please try refreshing"}
                            />
                        )}
                    </div>
                    <Button
                        data-testid={TEST_IDS.IMPORT_JOB_TABLE_REFRESH}
                        iconName={"refresh"}
                        loading={isImportLoading}
                        onClick={(): void => onClickImportRefresh()}
                    >
                        Refresh
                    </Button>
                </SpaceBetween>
            </ActionsContainer>
        );
    };

    const BreadCrumbs = (): JSX.Element => {
        const kaleRecordName = params.applicationName;
        const reviewId = params.reviewId;
        const items = [
            BreadcrumbItems.KALE_LANDING_PAGE,
            BreadcrumbItems.editKaleRecord(kaleRecordName, reviewId),
            BreadcrumbItems.csvJobStatus(kaleRecordName, reviewId),
        ];
        return <KaleBreadcrumbGroup items={items} />;
    };

    return (
        <div>
            {BreadCrumbs()}
            <Box variant="h1">{params.applicationName} Exports/Imports</Box>
            <Tabs
                data-testid={TEST_IDS.TABS}
                tabs={[
                    {
                        label: "Table Exports",
                        id: "export_tab",
                        content: (
                            <DASTable<JobView>
                                data-testid={TEST_IDS.EXPORT_JOB_TABLE}
                                id={"export-jobs-table"}
                                tableName={"Table Exports"}
                                isLoading={isExportLoading}
                                columnDefinitions={exportColDefs}
                                filterProps={{
                                    type: DASTableFilterType.propertyFilter,
                                    filterKeys: ["name", "status"],
                                }}
                                rowItems={exportJobs}
                                initialSortedColumn={"export-last-updated"}
                                sortingDescending={true}
                                selectionType={"single"}
                                onSelectionChange={({ detail }): void => {
                                    setSelectedExport(detail.selectedItems[0] as JobView);
                                }}
                                renderActionStripe={(): JSX.Element => exportActions(exportJobs, selectedExport)}
                            />
                        ),
                    },
                    {
                        label: "Table Imports",
                        id: "import_tab",
                        content: (
                            <DASTable<JobView>
                                data-testid={TEST_IDS.IMPORT_JOB_TABLE}
                                id={"import-jobs-table"}
                                tableName={"Table Imports"}
                                isLoading={isImportLoading}
                                columnDefinitions={importColDefs}
                                rowItems={importJobs}
                                initialSortedColumn={"import-last-updated"}
                                sortingDescending={true}
                                renderActionStripe={importActions}
                            />
                        ),
                    },
                ]}
            />
        </div>
    );
};

const importColDefs = [
    {
        id: "import-name",
        header: "Import Name",
        cell: (e: JobView): string => e.name,
        sortingField: "name",
    },
    {
        id: "import-status",
        header: "Status",
        cell: (e: JobView): string => e.status,
        sortingField: "status",
    },
    {
        id: "import-last-updated",
        header: "Last Updated",
        cell: (e: JobView): string => new Date(e.timeStamp).toUTCString(),
        sortingField: "timeStamp",
    },
];

const exportColDefs: TableProps.ColumnDefinition<JobView>[] = [
    {
        id: "export-name",
        header: "Export Name",
        cell: (e): string => e.name,
        sortingField: "name",
    },
    {
        id: "export-status",
        header: "Status",
        cell: (e): string => e.status,
        sortingField: "status",
    },
    {
        id: "export-last-updated",
        header: "Last Updated",
        cell: (e): string => new Date(e.timeStamp).toUTCString(),
        sortingField: "timeStamp",
    },
];

const downloadS3File = (s3URL: string, fileName: string): void => {
    fetch(s3URL, {
        method: "GET",
        headers: {},
    })
        .then((response): Promise<Blob> => response.blob())
        .then((blob): void => downloadBlob(blob, fileName));
};
