import { FoundEntityType } from "src/services/NodeKaleApplicationService";
import { PDCFilterName } from "../constants";

/**
 * Returns true if the entityType's confidence score is greater than or equal to 51% and less than 76%
 */
export const selectIsEntityConfidence_51_Through_75 = (entityType: FoundEntityType): boolean => {
    const confidenceScore = entityType.highestClassificationScore;
    return confidenceScore >= 0.51 && confidenceScore < 0.76;
};

/**
 * Returns true if the entityType's confidence score is greater than or equal to 76% and less than 91%
 */
export const selectIsEntityConfidence_76_Through_90 = (entityType: FoundEntityType): boolean => {
    const confidenceScore = entityType.highestClassificationScore;
    return confidenceScore >= 0.76 && confidenceScore < 0.91;
};

/**
 * Returns true if the entityType's confidence is greater than or equal to 91%
 */
export const selectIsEntityConfidence_91_Or_Greater = (entityType: FoundEntityType): boolean => {
    return entityType.highestClassificationScore >= 0.91;
};

export type EntityFilterFn = (entityType: FoundEntityType) => boolean;
type EntityFilterByFilterName = Record<PDCFilterName, EntityFilterFn>;
export const entityFilters: EntityFilterByFilterName = {
    [PDCFilterName.AllFindings]: () => true,
    [PDCFilterName.Confidence_51_Through_75]: selectIsEntityConfidence_51_Through_75,
    [PDCFilterName.Confidence_76_Through_90]: selectIsEntityConfidence_76_Through_90,
    [PDCFilterName.Confidence_91_Or_Greater]: selectIsEntityConfidence_91_Or_Greater,
};
