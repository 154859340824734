/* eslint-disable max-len*/
import React, { useContext, useMemo } from "react";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
import { Multiselect } from "@amzn/awsui-components-react-v3";
import { PLACEHOLDER_TEXT } from "src/components/TableDetails/FieldsTable/TableCell/constants";
import { FieldCellState } from "src/components/TableDetails/FieldsTable/types";
import { useMultiSelectExclusiveOptionBehaviors } from "src/components/TableDetails/CommonQuestionHooks/useMultiSelectExclusiveOptionBehaviors";
import {
    GroupedQuestionOptions,
    groupQuestionOptions,
    makeOptionFromChoice,
    QuestionOption,
} from "src/services/dynamic-questions";
/* eslint-enable max-len*/

export const TEST_IDS = {
    MULTISELECT: "table-details--field-table--table-cell--multiselect",
};

/**
 * Custom Component representing the TableCell MultiSelectQuestion. Adds custom behaviors involving any QuestionChoice
 * object configured with the `isExclusive` property.
 */
export const MultiselectQuestion = (props: FieldCellState): JSX.Element => {
    const {
        value,
        setValue: setValues,
        isValid,
        question: { choices },
        isApplicable,
    } = props;

    const values = (value as string[]) ?? [];
    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly || !isApplicable;

    const options = useMemo((): QuestionOption[] => choices.map(makeOptionFromChoice), [choices]);
    const { enhancedOptions, onChange } = useMultiSelectExclusiveOptionBehaviors(options, values);
    const selectedOptions = enhancedOptions.filter((option: any): boolean => values?.includes(option.value!) ?? false);
    const groupedOptions = useMemo(
        (): GroupedQuestionOptions => groupQuestionOptions(enhancedOptions),
        [enhancedOptions]
    );

    return (
        <Multiselect
            data-testid={TEST_IDS.MULTISELECT}
            selectedOptions={selectedOptions}
            options={groupedOptions}
            placeholder={PLACEHOLDER_TEXT}
            onChange={({ detail: { selectedOptions } }): void => {
                // Getting the modified selected options from the onChange output of custom hook
                const enhancedSelectedOptions = onChange(selectedOptions);
                // Update states
                setValues(enhancedSelectedOptions);
            }}
            invalid={!isValid}
            disabled={isReadonly}
            filteringType={"auto"}
            keepOpen={true}
            expandToViewport={true}
        />
    );
};
