import { BindleResource } from "src/services/KaleApplicationService";
/*
This code will be short lived. Dgr will shortly provide us with
generic property for resource names.
 */

export function findResourceName(resource: BindleResource): string {
    let nameKey = "";
    switch (resource.resourceType.toLowerCase()) {
        case "s3":
            nameKey = "bucket_name";
            break;
        case "dynamodb":
            nameKey = "table_name";
            break;
        case "rds":
        case "redshift":
            nameKey = "db_name";
            break;
        default:
            return "";
    }
    return resource?.resourceSpecificDetails[nameKey] ?? "";
}
