// This function does a deep copy of object
// If you use lodash deepCopy the types will not be copied.
export function deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}

// This function finds unique items in array of objects
// ES Set does not work with objects so had to make this.
export function findUnique(array: any[], propertyName: string): any[] {
    return array.filter(
        (curr, index): boolean => array.findIndex((a): boolean => a[propertyName] === curr[propertyName]) === index
    );
}

// Flat method on arrays requires upgrading to Node 12
// Will upgrade at a later date when we can verify
// it does not break anything in Kale
export function flatten(arr: any[]): any[] {
    return arr.reduce((acc, val): void => (Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val)), []);
}
