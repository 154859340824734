import { KaleQuestion } from "src/services/KaleApplicationService";
import { useDataStores, useReview } from "src/answers_legacy";
import { useCallback, useRef } from "react";
import { SurveyResponse } from "src/components/survey/SurveyFormModel";

/**
 * Returns a callback to reload the LegalSurvey GSM state with the latest published data from the server. Its intended
 * to be used on every LegalSurvey page load as well after every LegalSurvey save operation. The term "Legal Survey GSM
 * state" here refers to the Review/DataStore state slices.
 */
export const useSyncGSMWithServerDataCb = (): ((response: SurveyResponse, questions: KaleQuestion) => void) => {
    const [, { resetAndAddAnswers: resetAndAddReviewAnswers }] = useReview();
    const [, { addAnswers: addDataStoreAnswers, resetAnswers: resetDataStoreAnswers }] = useDataStores();
    const deps = { resetAndAddReviewAnswers, addDataStoreAnswers, resetDataStoreAnswers };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    return useCallback((response: SurveyResponse, questions: KaleQuestion): void => {
        const { resetAndAddReviewAnswers, addDataStoreAnswers, resetDataStoreAnswers } = depsRef.current;

        // Clear local GSM Review answers and load GSM with the latest Review answers from server response.
        resetAndAddReviewAnswers(response.appInfo.review.reviewAnswers, questions.reviews, response.appInfo.review.id);

        // Clear local DataStore answers from GSM.
        resetDataStoreAnswers();

        // Load latest DataStore answers from server response into GSM
        response.appInfo.review.dataStores.forEach((store): void => {
            addDataStoreAnswers(store.dataStoreAnswers, questions.dataStores, store.id);
        });
    }, []);
};
