import { HasPersonalDataResponse } from "src/components/survey/SurveyFormModel";

const isAppNameValid = (appName: string): boolean => {
    const regex = new RegExp("^[A-Za-z0-9 ._&()-]+$", "gi");
    return regex.test(appName);
};

const isWillingToProvideInfoValid = (hasPersonalData: string, willingToProvideInfo: string): boolean => {
    if (hasPersonalData === HasPersonalDataResponse.Process || hasPersonalData === HasPersonalDataResponse.No) {
        return Boolean(willingToProvideInfo);
    } else {
        return true;
    }
};

const isContactListValid = (contactList: string[]): boolean => {
    return contactList.length > 0;
};

const isExplanationValid = (anvilId: string, explanation: string): boolean => {
    // only invalid state is if both are unanswered/empty
    return anvilId !== "" || explanation !== "";
};

export { isAppNameValid, isWillingToProvideInfoValid, isContactListValid, isExplanationValid };
