import fetch from "@amzn/sentry-fetch";
import { TableAttribute } from "src/components/schema_table/SchemaTable";
import { appendRequestID } from "src/services/AppendRequestID";

// Andes Table api is still using v1 as the latest implementation
// Andes api swagger page:
// https://andes-service-iad.iad.proxy.amazon.com/explorer/andes.html#/
const ANDES_URL_V2 = "https://andes-service-iad.iad.proxy.amazon.com/v2";
const ANDES_URL_V1 = "https://andes-service-iad.iad.proxy.amazon.com/v1";

interface AndesApiResponseData {
    providerName: string;
    providerId: string;
    tableName: string;
    versionNumber: number;
    audit: {
        timestamp: string;
    };
}

interface AndesPageMetaData {
    next: string;
    nextPageMarker: string;
    limit: number;
    isTruncated: boolean;
}

export interface AndesProviderNameResponse extends BaseResponse {
    providerName: string;
    providerId: string;
}

export interface AndesApiResponse extends BaseResponse {
    data?: AndesApiResponseData[];
    pageMetadata: AndesPageMetaData;
}

interface BaseResponse {
    message: string;
    status: number;
}

const fetchAllTablesPages = (
    userAlias: string,
    prevRes: AndesApiResponse[] = [],
    nextPageURL?: string
): Promise<AndesApiResponse[]> => {
    return fetchAndesV1<AndesApiResponse>(
        `/tables?operation=consume&actorType=person&actorId=${userAlias}&limit=400`,
        nextPageURL
    ).then((res): Promise<AndesApiResponse[]> => {
        const response: AndesApiResponse[] = [...prevRes, res];
        if (res.pageMetadata.next) {
            return fetchAllTablesPages(userAlias, response, res.pageMetadata.next);
        }
        return Promise.resolve(response);
    });
};

const fetchAndesV2 = <T = AndesApiResponse>(andesPath: string, nextPageURL?: string): Promise<T> => {
    const urlToFetch: string = nextPageURL ? nextPageURL : `${ANDES_URL_V2}${andesPath}`;
    return fetch(urlToFetch, {
        mode: "cors",
        method: "GET",
    }).then((resp: Response): Promise<T> => {
        if (resp.status === undefined || resp.status !== 200) {
            // If the message has a status that's not 200, return error string
            throw new Error(appendRequestID("Error fetching from Andes.", resp, "x-request-id"));
        }
        return resp.json();
    });
};

const fetchAndesV1 = <T = AndesApiResponse>(andesPath: string, nextPageURL?: string): Promise<T> => {
    const urlToFetch: string = nextPageURL ? nextPageURL : `${ANDES_URL_V1}${andesPath}`;
    return fetch(urlToFetch, {
        mode: "cors",
        method: "GET",
    }).then((resp: Response): Promise<T> => {
        if (resp.status === undefined || resp.status !== 200) {
            // If the message has a status that's not 200, return error string
            throw new Error(appendRequestID("Error fetching from Andes.", resp, "x-request-id"));
        }
        return resp.json();
    });
};

function parseSDLString(sdlString: string): TableAttribute[] {
    // TODO (Privacy-761): use graphql to parse schema definition language
    /**
     * Returns a TableAttribute array of the imported table's Schema Attributes by parsing the SDL string
     * for `name` and `type` using regex, while ignoring `doc` so that it properly parses the string into arr elements
     *
     * @sdlString: imported table schema's sdl
     * @return: TableAttribute array, each TableAttribute element containing `name` and `type`
     */
    try {
        return Array.from(sdlString.matchAll(/\{name:([^,]*),(?:doc:"([^"]*)",)?type:\{([^}]*)\}/g)).map(
            (item): TableAttribute => ({
                name: item[1],
                type: item[item.length - 1].split(",")[0].replace("base:", ""),
            })
        );
    } catch (error) {
        console.log(error);
        throw error;
    }
}

interface AndesFetchSchemaResponse extends AndesApiResponse {
    providerId: string;
    tableName: string;
    versionNumber: number;
    schema: {
        sdl: string;
    };
}

export interface ParsedSchemaResult {
    rawSchema: string;
    parsedSchema: TableAttribute[];
}

const fetchSchema = (providerId: string, table: string, schemaVersion: string): Promise<ParsedSchemaResult> => {
    return fetchAndesV2<AndesApiResponse>(
        `/providers/${providerId}/tables/${table}/versions/${schemaVersion}/schema`
    ).then((response: AndesApiResponse): ParsedSchemaResult => {
        const sdlString = (response as AndesFetchSchemaResponse).schema.sdl;
        if (!sdlString) {
            throw new Error("Received empty SDL string during Andes schema retrieval.");
        }
        return {
            parsedSchema: parseSDLString(sdlString),
            rawSchema: sdlString,
        };
    });
};

export { fetchAndesV2, fetchAllTablesPages, fetchSchema, parseSDLString };
