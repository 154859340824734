import { Checkbox, CheckboxProps, NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3/polaris";
import React, { useContext } from "react";
import { FormQuestionForwardingProps } from "src/components/TableDetails/FormQuestionsPane/FormQuestions/index";
import { AnswerBase, HookAnswer, QuestionBase } from "src/answers_legacy";
import { TableDetailsPageContext } from "src/components/TableDetails/TableDetailsPage/TableDetailsPageContext";
export type CheckboxChangeEvent = NonCancelableCustomEvent<CheckboxProps.ChangeDetail>;

type CheckboxQuestionProps<QType extends QuestionBase, AType extends AnswerBase> = {
    questionAnswer: HookAnswer<QType, AType>;
} & FormQuestionForwardingProps;

export const CheckboxQuestion = <QType extends QuestionBase, AType extends AnswerBase>(
    props: CheckboxQuestionProps<QType, AType>
): JSX.Element => {
    const {
        "data-testid": dataTestId,
        questionAnswer: { value, setValue },
    } = props;

    const isReadonly = useContext(TableDetailsPageContext).isFormReadonly;
    const checked = Boolean(value as string);

    return (
        <Checkbox
            data-testid={dataTestId}
            disabled={isReadonly}
            checked={checked}
            onChange={(e: CheckboxChangeEvent): void => setValue(e.detail.checked ? "true" : "")}
        />
    );
};
