export enum AndesPolicyTypes {
    "DSAR" = "DSAR",
    "OD3" = "OD3",
    "PD2" = "PD2",
}

export enum AndesComplianceTypes {
    "DSAR" = "Andes DSAR",
    "OD3" = "Andes OD3",
}

export const PolicyTypesByComplianceType: Record<AndesComplianceTypes, AndesPolicyTypes> = {
    [AndesComplianceTypes.DSAR]: AndesPolicyTypes.DSAR,
    [AndesComplianceTypes.OD3]: AndesPolicyTypes.OD3,
};

// Short Ids for Andes Cradle Questions, organized by policy type
export const CRADLE_QUESTION_SHORT_IDS_BY_POLICY: Partial<
    Record<AndesPolicyTypes, { profileIdShortId: string; accountIdShortId: string }>
> = {
    [AndesPolicyTypes.OD3]: {
        profileIdShortId: "andes_od3_table_cradle_profile_id",
        accountIdShortId: "andes_od3_table_cradle_account_id",
    },
    [AndesPolicyTypes.DSAR]: {
        profileIdShortId: "andes_dsar_table_cradle_profile_id",
        accountIdShortId: "andes_dsar_table_cradle_account_id",
    },
};

// Generate flat array containing all the Andes Cradle question short ids. This
// is used by other components that would want to render TableQuestions but need
// to avoid rendering questions with these shortIds because they are handled
// programmatically instead of being rendered
export const CRADLE_QUESTION_SHORT_IDS = Object.values(CRADLE_QUESTION_SHORT_IDS_BY_POLICY).reduce<string[]>(
    (shortIds, tuple): string[] => {
        if (tuple) {
            const { accountIdShortId, profileIdShortId } = tuple;
            shortIds.push(accountIdShortId, profileIdShortId);
        }
        return shortIds;
    },
    []
);
