import { ButtonDropdown, ButtonDropdownProps } from "@amzn/awsui-components-react-v3";
import React, { useContext, useMemo, useRef } from "react";
import { ControlBindleInfoContext, useControlBindleInfoContext } from "src/components/controlBindleInfoContext";
import KaleContext from "src/components/KaleContext";
import { DSARDeletionApprovedStatus, SurveyContext } from "src/components/survey/SurveyFormModel";
import { AEDUSandfireState, useAEDUSandfireContext } from "src/components/survey/AEDUSandfireContext";
import { TEST_IDS } from "shared/ComplianceToolsDropdown";
import AccessibleButton from "src/components/buttons/AccesibleButton";
// eslint-disable-next-line max-len
import { generateNewAEDUOnboardingSIMLink } from "src/components/survey/DataStoreInfo/ComplianceToolsDropdown/AEDULinkGenerator";
// eslint-disable-next-line max-len
import { generateNewSandfireOnboardingLinkString } from "src/components/survey/DataStoreInfo/ComplianceToolsDropdown/SandfireLinkGenerator";

const SandfireIDPrefix = "sandfire-dso-";

interface Props {
    disabled: boolean;
    deletionOnboardingApproved: DSARDeletionApprovedStatus;
}

export const SANDFIRE_SERVICE_NAME = "Sandfire";
export const AEDU_SERVICE_NAME = "AEDU";
export const DISABLED_DROPDOWN_INFO_TEXT =
    // eslint-disable-next-line max-len
    "To onboard with privacy compliance tools you must first complete your legal review and any applicable financial reviews.";
export const SANDFIRE_ONBOARDING_NOT_APPROVED =
    "Your application has not been approved to onboard with Sandfire. Please refer to the FAQ for more info.";
export const SANDFIRE_GLOBAL_DROPDOWN_TEXT = "Sandfire Global Onboarding (non-China)";
export const SANDFIRE_CHINA_DROPDOWN_TEXT = "Sandfire China Onboarding";
export const AEDU_GLOBAL_DROPDOWN_TEXT = "AEDU Global Onboarding (non-China)";
export const AEDU_CHINA_DROPDOWN_TEXT = "AEDU China Onboarding";

const ComplianceToolsDropdown = ({ disabled, deletionOnboardingApproved }: Props): JSX.Element => {
    const controlBindleContext = useControlBindleInfoContext();
    const externalIDContext = useAEDUSandfireContext();
    const { id: reviewId, legalPocAlias } = useContext(SurveyContext).application.appInfo.review;
    const { sandfireBaseURL } = useContext(KaleContext).config;
    const {
        application: {
            appInfo: { applicationName },
        },
    } = useContext(SurveyContext);

    const dependencies = { sandfireBaseURL };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    const dropDownItems = useMemo((): ButtonDropdownProps.ItemOrGroup[] => {
        const items: ButtonDropdownProps.ItemOrGroup[] = [];
        const { sandfireBaseURL } = depsRef.current;

        items.push(
            ...generateAEDUItems(
                applicationName,
                reviewId,
                sandfireBaseURL,
                legalPocAlias,
                controlBindleContext,
                externalIDContext
            )
        );

        items.push(
            ...generateSandfireItems(
                applicationName,
                reviewId,
                sandfireBaseURL,
                controlBindleContext,
                externalIDContext,
                deletionOnboardingApproved
            )
        );

        return items;
    }, [applicationName, externalIDContext, controlBindleContext, deletionOnboardingApproved, legalPocAlias, reviewId]);

    const componentText = "Onboard to Privacy Tools";

    if (disabled) {
        return (
            <AccessibleButton
                buttonProps={{ disabled, text: componentText }}
                popoverProps={{
                    dismissButton: false,
                    content: DISABLED_DROPDOWN_INFO_TEXT,
                }}
            />
        );
    }

    return (
        <ButtonDropdown
            data-testid={TEST_IDS.COMPLIANCE_TOOLS_DROPDOWN}
            disabled={disabled}
            items={dropDownItems}
            loading={externalIDContext.isLoading || controlBindleContext.isLoading}
        >
            {componentText}
        </ButtonDropdown>
    );
};

const generateSandfireItems = (
    applicationName: string,
    reviewId: number,
    sandfireBaseURL: string,
    controlBindleContext: ControlBindleInfoContext,
    externalIDContext: AEDUSandfireState,
    deletion_onboarding_approved: DSARDeletionApprovedStatus
): ButtonDropdownProps.ItemOrGroup[] => {
    const existingSandfireLinks = externalIDContext.externalIDs
        ?.filter((exID): boolean => exID.serviceName === SANDFIRE_SERVICE_NAME)
        .map((exID): ButtonDropdownProps.ItemOrGroup => {
            const prefixWithID = SandfireIDPrefix + exID.serviceID;
            return {
                text: generateExistingDSODropdownText(SANDFIRE_SERVICE_NAME, exID.serviceID),
                id: prefixWithID,
                href: new URL(`${sandfireBaseURL}/dso/wizard/step/collect-data/${exID.serviceID}`).toString(),
                external: true,
            };
        });

    const onboardIsDisabled = deletion_onboarding_approved === DSARDeletionApprovedStatus.No;
    const newSandfireOnboardingLinks = [
        {
            text: SANDFIRE_GLOBAL_DROPDOWN_TEXT,
            id: "Sandfire Onboard",
            href: generateNewSandfireOnboardingLinkString(applicationName, controlBindleContext, sandfireBaseURL),
            external: true,
            disabled: onboardIsDisabled,
            disabledReason: SANDFIRE_ONBOARDING_NOT_APPROVED,
        },
        {
            text: SANDFIRE_CHINA_DROPDOWN_TEXT,
            id: "Sandfire China Onboard",
            href:
                "https://w.amazon.com/bin/view/Oblivion/Sandfire/UserGuide/GetStarted/" +
                "DSO#HAppendixA-ChinaOnboarding",
            external: true,
            disabled: onboardIsDisabled,
            disabledReason: SANDFIRE_ONBOARDING_NOT_APPROVED,
        },
    ];

    return [...newSandfireOnboardingLinks, ...(existingSandfireLinks ?? [])];
};

const generateAEDUItems = (
    applicationName: string,
    reviewId: number,
    sandfireBaseURL: string,
    legalPOCAlias: string,
    controlBindleContext: ControlBindleInfoContext,
    externalIDContext: AEDUSandfireState
): ButtonDropdownProps.ItemOrGroup[] => {
    const existingAEDULinks = externalIDContext.externalIDs
        ?.filter((exID): boolean => exID.serviceName === AEDU_SERVICE_NAME)
        .map((exID): ButtonDropdownProps.ItemOrGroup => {
            return {
                text: generateExistingDSODropdownText(AEDU_SERVICE_NAME, exID.serviceID),
                id: `${AEDU_SERVICE_NAME}-${exID.serviceID}`,
                href: new URL(`https://portal.aedu.privacy.a2z.com/data-owners/${exID.serviceID}`).toString(),
                external: true,
            };
        });

    const [globalLink, chinaLink] = [false, true].map((isChina): string =>
        generateNewAEDUOnboardingSIMLink(
            applicationName,
            reviewId,
            sandfireBaseURL,
            legalPOCAlias,
            controlBindleContext,
            externalIDContext,
            isChina
        )
    );

    const newOnboardingLinks = [
        {
            text: "AEDU Global Onboarding (non-China)",
            id: "AEDU Onboard",
            href: globalLink,
            external: true,
        },
        {
            text: "AEDU China Onboarding",
            id: "AEDU China Onboard",
            href: chinaLink,
            external: true,
        },
    ];

    return [...newOnboardingLinks, ...(existingAEDULinks ?? [])];
};

export const generateExistingDSODropdownText = (serviceName: string, serviceID: string): string => {
    return `View ${serviceName} ${serviceID}`;
};

export default ComplianceToolsDropdown;
