import { useCallback, useContext } from "react";
import KaleContext from "src/components/KaleContext";
import { useKaleDispatch } from "src/redux/hooks";
import { CardNames } from "src/components/survey/ApplicationOverview/state/model";
import { actionCreators } from "src/components/survey/ApplicationOverview/state/actions";

const { summaryCardRequestStarted, summaryCardRequestFailed, summaryCardRequestSucceeded } = actionCreators;

interface DataService {
    /** fetches data for all SummaryCards and loads them into state */
    loadSummaryCards: () => void;
}

/**
 * Provides a collection of data service methods bound to redux actions for the ApplicationOverview state slice
 * @param applicationName
 */
export const useDataService = (applicationName: string): DataService => {
    const {
        service: { kaleAppService },
    } = useContext(KaleContext);

    const dispatch = useKaleDispatch();

    const loadSummaryCards = useCallback((): void => {
        [CardNames.personalDataSummary, CardNames.privacyAlertsSummary].forEach((cardName): void => {
            // Fetch the appropriate SummaryResource and dispatch the a summaryCardRequestStarted action
            const fetchApi =
                cardName === CardNames.personalDataSummary
                    ? kaleAppService.fetchPersonalDataSummary
                    : kaleAppService.fetchPrivacyAlertsSummary;
            const cardPromise = fetchApi(applicationName);
            dispatch(summaryCardRequestStarted(cardName));

            // Use Promise.then() syntax here rather then the `await` keyword to make sure that we fetch each cards in
            // parallel within the forEach loop rather than force execution to block while awaiting each promise.
            cardPromise.then(
                function cardRequestSucceeded(response): void {
                    dispatch(summaryCardRequestSucceeded(cardName, response));
                },
                function cardRequestFailed(e: Error): void {
                    dispatch(summaryCardRequestFailed(cardName, e));
                }
            );
        });
    }, [dispatch, applicationName, kaleAppService]);

    return { loadSummaryCards };
};
