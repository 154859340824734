import React from "react";

interface TableDetailsPageContextProps {
    isFormReadonly: boolean;
}
export const TableDetailsPageContext = React.createContext<TableDetailsPageContextProps>({
    isFormReadonly: false,
});

export const TableDetailsPageProvider = TableDetailsPageContext.Provider;
