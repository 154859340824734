import { useContext, useMemo, useRef } from "react";
import { answerActionsContext, Answers, AnswerState, answerStateContext, Response, Validation } from "../context";
import { AnswerActions, StateKey } from "../actions";
import { DefaultTableAnswer, TableAnswer, TableAnswerWithQuestion, TableQuestion } from "../models/services";
import { HookAnswer, getAnswerResponseState } from "./common";
import { meetsServerAnswerConstraint } from "src/answers_legacy/hooks/helpers/payloadUtils";

export interface KaleTablesAccessor extends Validation {
    answers: HookAnswer<TableQuestion, TableAnswer>[];
}

export function getKaleTable(state: AnswerState, tableId?: number): Answers<TableAnswerWithQuestion> | undefined {
    return tableId
        ? state[StateKey.kaleTable].find(
              (item): boolean => item.value[0] && item.value[0].value.answer.tableId === tableId
          )
        : state[StateKey.kaleTable][0];
}

export type TableAnswerActions = AnswerActions<TableQuestion, TableAnswer>;

export function getKaleTableAnswerById(
    state: AnswerState,
    actions: TableAnswerActions,
    questionShortId?: number | string,
    tableId?: number
): HookAnswer<TableQuestion, TableAnswer> | void {
    const kaleTableState = getKaleTable(state, tableId);

    const questionWithAnswer = kaleTableState?.value.find((answer: Response<TableAnswerWithQuestion>): boolean => {
        const id = answer.value.answer.questionShortId;
        return id === questionShortId;
    });

    if (!questionWithAnswer) {
        console.error(`Invalid requested table answer by short id "${questionShortId}"`);
        return;
    }

    return getAnswerResponseState<TableQuestion, TableAnswer>(questionWithAnswer, actions);
}

export const useKaleTable = (): [KaleTablesAccessor, TableAnswerActions] => {
    const actions = useContext(answerActionsContext)<TableQuestion, TableAnswer>(StateKey.kaleTable);
    const state = useContext(answerStateContext);
    const tableState = state[StateKey.kaleTable];

    const deps = { actions, state };
    const dependenciesRef = useRef(deps);
    dependenciesRef.current = deps;

    return useMemo((): [KaleTablesAccessor, TableAnswerActions] => {
        const { actions, state } = dependenciesRef.current;
        return [
            tableState.map((table): KaleTablesAccessor => {
                return {
                    answers: table.value
                        .map((questionWithAnswer): HookAnswer<TableQuestion, TableAnswer> | void =>
                            getKaleTableAnswerById(state, actions, questionWithAnswer.value.answer.questionShortId)
                        )
                        .filter((v): boolean => !!v) as HookAnswer<TableQuestion, TableAnswer>[],
                    isValid: table.isValid,
                    isValidSave: table.isValidSave,
                    isValidSubmit: table.isValidSubmit,
                    hasChanged: table.hasChanged,
                    userLastUpdated: table.userLastUpdated,
                };
            })[0], // Return first table only, more not needed
            actions,
        ];
    }, [tableState]);
};

export type KaleTablePayload = TableAnswer[];
export const useKaleTablePayload = (): KaleTablePayload => {
    const state = useContext(answerStateContext);
    const tableState = state[StateKey.kaleTable];

    return useMemo(
        (): KaleTablePayload =>
            tableState
                .map((table): TableAnswer[] =>
                    table.value.map((questionWithAnswer): TableAnswer => questionWithAnswer.value.answer)
                )[0] // Return first table only, more not needed
                .map((answer): TableAnswer => {
                    const base = DefaultTableAnswer(
                        answer.questionId,
                        answer.questionShortId,
                        answer.tableId,
                        answer.userTableId
                    );

                    return { ...base, ...answer };
                })
                .filter((answer): boolean => meetsServerAnswerConstraint(answer)),
        [tableState]
    );
};
