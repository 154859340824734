import {
    CreateOptionsCallback,
    DataStoreAnswer,
    DataStoreQuestion,
    ParentConstraintBase,
    QuestionBase,
    QuestionTag,
    QuestionType,
} from "src/answers_legacy";
import {
    containsPersonalDataShortId,
    hasChildDataShortId,
    hasTeenDataShortId,
    childDataIsProfileAwareShortId,
    teenDataUsageShortId,
    childDataIsSharedShortId,
    childDataAccessControlMethodsShortId,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/ChildDataGatingQuestionGroup";

export const resolveHiddenPrefilledAnswerParentConstraint = (
    parentAnswer: DataStoreAnswer | undefined,
    parentQuestion: (QuestionBase & ParentConstraintBase) | undefined,
    question: QuestionBase & ParentConstraintBase
): boolean => {
    // If a question has a parentQuestion that contains this tag, enable "ANY"
    // constraint handling logic when determining if this question is applicable.
    if (parentQuestion?.tags.includes(QuestionTag.hiddenPrefilledAnswer)) {
        switch (parentQuestion?.type) {
            case QuestionType.radio: {
                return !!(
                    parentAnswer &&
                    parentAnswer!.textContent &&
                    // Radio question type choices should always be a list of strings
                    (question.parentChoices as string[])?.includes(parentAnswer!.textContent)
                );
            }
            case QuestionType.checkboxGroup: {
                return (
                    !!question?.parentChoices &&
                    question.parentChoices.length > 0 &&
                    // Checkbox group type choices should always be a list of strings
                    !!(question?.parentChoices as string[])?.some((v: string): boolean =>
                        Boolean(parentAnswer?.arrayContent?.includes(v))
                    )
                );
            }
            default: {
                throw Error(`hiddenPrefilledAnswerParentConstraint case for ${parentQuestion?.type} is undefined`);
            }
        }
    } else {
        return false;
    }
};

export const resolveAnyParentGSMConstraint = (
    parentAnswer: DataStoreAnswer | undefined,
    parentQuestion: (QuestionBase & ParentConstraintBase) | undefined,
    question: QuestionBase & ParentConstraintBase
): boolean => {
    // When useAnyParentGSMConstraint, parentChoices = {"A", "B"}, and users select "A" or "B" or both "A" "B", then we
    // show the child question. Normally, the default GSM shows the child question only when both "A" "B" are select.
    if (question?.tags.includes(QuestionTag.useAnyParentGSMConstraint)) {
        switch (parentQuestion?.type) {
            case QuestionType.checkboxGroup:
            case QuestionType.multiSelect: {
                return (
                    !!question?.parentChoices &&
                    question.parentChoices.length > 0 &&
                    !!(question?.parentChoices as string[])?.some(
                        (v: string): boolean => parentAnswer?.arrayContent?.includes(v) as boolean
                    )
                );
            }
            case QuestionType.radio: {
                return (
                    !!question?.parentChoices &&
                    question.parentChoices.length > 0 &&
                    (question?.parentChoices as string[]).some((v: string): boolean => parentAnswer?.textContent === v)
                );
            }
            default: {
                // Currently, we have feature flag for Child Data and when it is turned off, it can be undefined here
                console.log(`useAnyParentGSMConstraint case for ${parentQuestion?.type} is undefined`);
                return false;
            }
        }
    } else {
        return false;
    }
};

/**
 * Some child datastore questions have constraints from multiple other questions,
 * which are specially handled by this function
 * @return returns true if the current question should be rendered, else false
 */
const meetsChildDataSpecialConstraint = (
    answers: DataStoreAnswer[] | undefined,
    question: QuestionBase & ParentConstraintBase
): boolean => {
    const { shortId } = question;
    const containsPersonalData = answers?.find(
        (ans: DataStoreAnswer): boolean => ans.questionShortId === containsPersonalDataShortId
    );
    const hasChildData = answers?.find((ans: DataStoreAnswer): boolean => ans.questionShortId === hasChildDataShortId);
    const hasTeenData = answers?.find((ans: DataStoreAnswer): boolean => ans.questionShortId === hasTeenDataShortId);
    // for question has_teen_data, choice "Yes" of contains_personal_data should be selected
    if (shortId === hasTeenDataShortId) {
        return containsPersonalData?.textContent === "Yes";
    }
    // for question teen_data_usage, at least one expected answer should be selected from has_teen_data
    if (shortId === teenDataUsageShortId) {
        return (hasTeenData?.arrayContent ?? []).some((choice: string): boolean =>
            (question.parentChoices as string[])?.includes(choice)
        );
    }
    // for questions child_data_is_profile_aware, child_data_is_shared and child_data_access_control_methods,
    // at least one expected answer should be selected from has_child_data or has_teen_data
    return (hasChildData?.arrayContent ?? [])
        .concat(hasTeenData?.arrayContent ?? [])
        .some((choice: string): boolean => (question.parentChoices as string[])?.includes(choice));
};

export type GetTechnologyCb = (answer: DataStoreAnswer) => string;
export const setDataStoresConstraintHandler = (
    addDataStoreOptions: CreateOptionsCallback<DataStoreQuestion, DataStoreAnswer>,
    getDataStoreTechnologyCb: (answer: DataStoreAnswer) => string
): void => {
    addDataStoreOptions({
        constraintHandler: ({
            hasParentConstraint,
            isParentConstraintMet,
            parentAnswer,
            parentQuestion,
            question,
            currentAnswer,
            answers,
        }): [boolean, boolean] => {
            const dataStoreTechnology = getDataStoreTechnologyCb(currentAnswer);
            if (dataStoreTechnology === "Andes") {
                if (question.tags.includes(QuestionTag.ignoredForAndes)) {
                    return [hasParentConstraint, false];
                }
                if (question.tags.includes(QuestionTag.requiredForAndes)) {
                    return [hasParentConstraint, true];
                }
            }

            if (
                [
                    hasTeenDataShortId,
                    childDataIsProfileAwareShortId,
                    teenDataUsageShortId,
                    childDataIsSharedShortId,
                    childDataAccessControlMethodsShortId,
                ].includes(question.shortId)
            ) {
                return [true, meetsChildDataSpecialConstraint(answers, question)];
            }

            if (resolveHiddenPrefilledAnswerParentConstraint(parentAnswer, parentQuestion, question)) {
                return [hasParentConstraint, true];
            }

            if (resolveAnyParentGSMConstraint(parentAnswer, parentQuestion, question)) {
                return [hasParentConstraint, true];
            }

            return [hasParentConstraint, isParentConstraintMet];
        },
    });
};
