/* eslint-disable max-len */
import { FormField, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { Box } from "@amzn/awsui-components-react-v3";
import React from "react";
import { DataStoreAnswer, DataStoreQuestion, HookAnswer, QuestionTag } from "src/answers_legacy";
import { DataStoreHookAnswer } from "src/answers_legacy/hooks/dataStores";
import { TEST_IDS } from "shared/survey";
import StandAloneQuestion from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/StandAloneQuestion";
import QuestionGroup, {
    QuestionGroupProps,
} from "src/components/survey/DataStoreInfo/DataStoreWizard/WizardStepQuestionsBuilder/QuestionGroup";
import { QuestionGroupInfoMap } from "src/services/KaleApplicationService";

const {
    DATA_STORE_INFO: {
        DATA_STORE_WIZARD: { DYNAMIC_QUESTION_STEP },
    },
} = TEST_IDS;

export interface WizardQuestionsBuilderProps {
    technology: string;
    description: JSX.Element | string;
    questionsList: HookAnswer<DataStoreQuestion, DataStoreAnswer>[];
    questionGroupInfoMap: QuestionGroupInfoMap;
}

export const RETENTION_PLANNED_CHECKBOX_TEXT = "Data in this datastore is only deleted upon customer request";
export const RETENTION_CURRENT_CHECKBOX_TEXT = "Data is stored indefinitely";

export const RetentionCheckboxLabel = (question: DataStoreQuestion): string => {
    return question.shortId === "deletion_current_pd2_period"
        ? RETENTION_CURRENT_CHECKBOX_TEXT
        : RETENTION_PLANNED_CHECKBOX_TEXT;
};
export const NOT_APPLICABLE_TEXT_WITH_NO_PERSONAL_DATA_OR_ANDES =
    "Not Applicable: This step only applies to non-Andes data stores that contain personal data.";
export const NOT_APPLICABLE_TEXT_WITH_NO_PERSONAL_DATA =
    "Not Applicable: This step only applies to data stores that contain personal data.";
export const NOT_YET_SUPPORTED = "The specified question type is not yet supported";

/**
 * Groups EVERY question into a list of QuestionGroupProps types. Questions whose groupId appears in the
 * questionGroupInfoMap will be placed into the same group as every other question with the same groupId.
 * Each question who groupId does not have an entry in the questionGroupInfo map will be placed into a stand
 * alone group all by themselves.
 *
 * This code assumes that questions belonging to the same group will be in contiguous order within the questionsList
 * param
 *
 * @param questionsList - The list of DataStore Questions from the GSM, maintained in their original order from the
 * server
 *
 * @param questionGroupInfoMap - Object used to map question's groupId to all any information about the group
 * that question should be placed in
 *
 * @return - A list of QuestionGroupProps objects.
 */
const makeQuestionGroups = (
    questionsList: DataStoreHookAnswer[],
    questionGroupInfoMap: QuestionGroupInfoMap
): QuestionGroupProps[] => {
    const appendItemAsNewGroup = (
        groupsList: QuestionGroupProps[],
        item: DataStoreHookAnswer,
        groupTitle?: string,
        groupDescription?: string
    ): void => {
        // Put the question into a new group and append that group to the groups list.
        const newGroup: QuestionGroupProps = {
            groupId: item.question.groupId,
            groupDescription,
            groupTitle,
            groupQuestions: [item],
        };
        groupsList.push(newGroup);
    };
    const addItemToLatestGroup = (groupsList: QuestionGroupProps[], item: DataStoreHookAnswer): void => {
        // The question should be a continuation of the latest group
        const latestGroup = groupsList[groupsList.length - 1];
        latestGroup.groupQuestions.push(item);
    };

    // Loop through every question in the list and for each question determine if it should be placed into the most
    // recently used group, or if it should be placed as the first question into a new group.
    return questionsList.reduce<QuestionGroupProps[]>((groupsList, currItem): QuestionGroupProps[] => {
        // Does the current question have a corresponding question group that it belongs to?
        const currGroupInfo = questionGroupInfoMap[currItem.question.groupId];
        if (currGroupInfo) {
            // Determine if it should be placed into the latest group, or a brand new group
            const prevGroup = groupsList[groupsList.length - 1];
            if (Boolean(prevGroup) && prevGroup.groupId === currGroupInfo.groupId) {
                // The current question is a continuation of the previous group
                addItemToLatestGroup(groupsList, currItem);
            } else {
                // Put the current question into a new group and add it to the groups list.
                const { title, description } = currGroupInfo;
                appendItemAsNewGroup(groupsList, currItem, title, description);
            }
        } else {
            // Current question is not associated with any group.
            // Put the current question into its own group and add it to the groups list.
            appendItemAsNewGroup(groupsList, currItem);
        }
        return groupsList;
    }, []);
};

/**
 * Method responsible for rendering all applicable questions
 */
const renderStepQuestions = (
    questionsList: DataStoreHookAnswer[],
    questionGroupInfoMap: QuestionGroupInfoMap
): JSX.Element[] => {
    // Group all questions by groupId
    const questionGroups = makeQuestionGroups(questionsList, questionGroupInfoMap);

    // Only want to render groups which contain at least 1 applicable question
    const applicableGroups = questionGroups.filter((group): boolean =>
        group.groupQuestions.some((item): boolean => item.isApplicable)
    );

    // Render applicable groups
    return applicableGroups.reduce<JSX.Element[]>((renderedQuestions, currentGroup): JSX.Element[] => {
        const { groupId, groupQuestions } = currentGroup;
        const isStandAloneQuestion = !Boolean(groupId);
        if (isStandAloneQuestion) {
            // Render as a StandAloneQuestion
            const item = groupQuestions[0];
            renderedQuestions.push(<StandAloneQuestion item={item} />);
        } else {
            // Render as QuestionGroup
            renderedQuestions.push(<QuestionGroup {...currentGroup} />);
        }

        return renderedQuestions;
    }, []);
};

/**
 * Component receives props containing flattened list of questions and child questions.
 * Is responsible for rendering all questions within a single wizard step
 */
const WizardStepQuestionsBuilder = (props: WizardQuestionsBuilderProps): JSX.Element => {
    const { questionsList, questionGroupInfoMap = {}, description } = props;

    const questionsContent = renderStepQuestions(questionsList, questionGroupInfoMap);
    /**
     * The current state of this wizard has !questionsContent when personalData is false
     * However, we have questions that are required when personalData is true
     * AND questions that require personal Data to be true AND the DS is not an Andes table
     * This logic will allow us to add the extra text content to explain that this question
     * is not relevant to Andes tables.
     */
    const isIgnoredForAndes = questionsList.some(({ question: { tags } }) =>
        tags?.includes(QuestionTag.ignoredForAndes)
    );

    return (
        <div data-testid={DYNAMIC_QUESTION_STEP.ROOT}>
            {!Boolean(questionsContent.length) ? (
                <FormField>
                    {isIgnoredForAndes
                        ? NOT_APPLICABLE_TEXT_WITH_NO_PERSONAL_DATA_OR_ANDES
                        : NOT_APPLICABLE_TEXT_WITH_NO_PERSONAL_DATA}
                </FormField>
            ) : (
                <SpaceBetween size={"s"}>
                    {description && <Box variant="p">{description}</Box>}
                    {questionsContent}
                </SpaceBetween>
            )}
        </div>
    );
};

export default WizardStepQuestionsBuilder;
