import { DASTableProps } from "src/components/fields/table/DASTable";
import { TableRecord } from "src/services/KaleTablesService";
import { useCallback, useState } from "react";
import { NonCancelableCustomEvent, TableProps } from "@amzn/awsui-components-react-v3";
import uuid from "uuid";

type SynchronizedTableItemSelectionProps = Pick<DASTableProps<TableRecord>, "onSelectionChange"> & {
    tableKey: string;
    selectedItems: TableRecord[];
    onAfterDeleteCb: () => void;
};
export const useSynchronizedTableItemSelection = (): SynchronizedTableItemSelectionProps => {
    /**
     * SchemaTable needs local state to keep track of selected items via the DasTable event handler prop
     * "onSelectionChange", in order to know when to show/hide certain buttons in the Table Action Stripe. However,
     * the underlying Polaris table only fires that event when a selection change is made by the user clicking the
     * Polaris Table items directly. In the case where a user Deletes 1 or more rows of the SchemaTable items through
     * our Table Actions [Delete] button, our PolarisTable will remove the rows from its Table Body UI but it won't
     * update its internally managed state for selectedItems to account for those items having been deleted.
     *
     * To counteract this, when we successfully complete a delete operation we should (A) reset our own local
     * selectedItems state and (B) force the PolarisTable to reset its state by giving the table a new react key.
     *
     * The consequence of not resetting our local selectedItems state would result in our table-header action
     * buttons thinking that there are still items selected in the table after those items has just been deleted from
     * the table. Net/net we don't want to continue displaying [Delete] and [Bulk Edit] buttons immediately after a
     * successful delete operation since the Table Body would not be rendering any selected items and those items
     * don't exist in the data anymore.
     *
     * The consequence of not forcing the Polaris Table to reset its internal selectedItems state after a successful
     * delete operation would be that the Table header count of selected items would indicate that some number of items
     * were still selected, even after those items had just been deleted and removed from the Table Body UI.
     *
     * This solution to synchronize the selectedItems state is a hacky workaround. The correct way to solve this is
     * to use Polaris Table Selection as a controlled component, and always control the selection state ourselves.
     * However, DASTable is only wired to use Polaris Table Selection as an uncontrolled component via tight coupling
     * between DASTable and its use of the Polaris "useCollection" hook to manage item selection for us. Furthermore,
     * SchemaTable relies entirely on DASTable to render the Polaris table In the future we will need to move
     * SchemaTable off of its reliance of DASTable to render the PolarisTable for SchemaTable or we will need to update
     * DASTable to support Table Selection as either controlled or unControlled, based on props passed into DASTable
     * itself.
     *
     * */

    const [selectedItems, setSelectedItems] = useState<TableRecord[]>([]);

    const onSelectionChange = useCallback(
        (event: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<TableRecord>>): void => {
            setSelectedItems(event.detail.selectedItems);
        },
        []
    );

    const [tableKey, setTableKey] = useState(uuid.v4());

    const onAfterDeleteCb = useCallback((): void => {
        setSelectedItems([]);
        setTableKey(uuid.v4());
    }, []);

    return { selectedItems, onSelectionChange, tableKey, onAfterDeleteCb };
};
