import { ApplicationStatus } from "src/components/survey/SurveyFormModel";
import { CampaignWorkflow, UserRole } from "src/permissions";
import { LEGAL_CAN_PERMISSION_MAPPING } from "src/permissions/Legal/permissions";
import { SingleInputValue } from "src/services/IncrementalSave";

interface ShouldShowCampaignConfig {
    dateShownAt: SingleInputValue;
    dateThresholdMonths: number;
    legalStatus: ApplicationStatus;
    userRole: UserRole;
}
/**
 * This utility checks whether or not to show a specific campaign. At the moment,
 * it compares the last time the campagin was shown and whether or not it has met the threshold to show it again.
 * Also, it checks for the proper Legal attestation status and the user roles that is allowed to complete the campaign.
 * NOT compatible with TAF campaigns yet.
 */
export const shouldShowCampaign = (
    campaign: CampaignWorkflow,
    { dateShownAt, dateThresholdMonths, legalStatus, userRole }: ShouldShowCampaignConfig
) => {
    const isOlderThanThreshold = isDateOlderThanThreshold(dateShownAt, dateThresholdMonths);
    const canPerformAction = LEGAL_CAN_PERMISSION_MAPPING[campaign][userRole].includes(legalStatus);

    return isOlderThanThreshold && canPerformAction;
};

/** Checks if lastShownDate is older than a specific date threshold (based on number of months ago) */
const isDateOlderThanThreshold = (lastShownDate: string, dateThresholdMonths: number) => {
    if (lastShownDate === "") {
        return true;
    }

    const today = new Date();
    const thresholdDate = new Date(today.setMonth(today.getMonth() - dateThresholdMonths));
    return thresholdDate.getTime() > new Date(lastShownDate).getTime();
};
