export const TEST_IDS = {
    KALE_CHECKBOX_GROUP: "kale-checkbox-group--root",
    KALE_CHECKBOX: {
        ROOT: "kale-checkbox--root",
        INLINE_CHECKBOX: "kale-checkbox--inline-checkbox",
        POPOVER: "kale-checkbox--popover",
    },
    DROPDOWN_WITH_TAGS: {
        FORMFIELD: "dropdown-with-tags-formfield",
        BUTTON: "dropdown-with-tags-button",
    },
    CONTACT_LIST: "contact-list",
    ANVIL_ID: {
        LINKED_TESTID: "anvil-id-linked",
        LOADING_TESTID: "anvil-id-loading",
        VALID_TESTID: "anvil-id-valid",
        INPUT_TESTID: "anvil-id-input",
    },
    BINDLE: {
        CONTROL_LINK: "ControlBindle-bindleLink",
        CONTROL_NO_POPOVER: "ControlBindle-noBindlesPopover",
        CONTROL_REFRESH_BUTTON: "ControlBindle-refreshButton",
        RELATED_NO_POPOVER: "RelatedBindle-noBindlesPopover",
        RELATED_REFRESH_BUTTON: "RelatedBindle-refreshButton",
    },
    APP_NAME: {
        COPY_CLIPBOARD_BUTTON: "app-name--copy-button",
        STATIC_TEXT: "app-name--static-text",
        TEXT_INPUT: "app-name--text-input",
    },
    ACCESS_CONTROL_SECTION: {
        RELATED_BINDLES: "access-control-section-related-bindles",
        AAA_APP_IDS: "access-control-section-aaa-id",
        ANVIL_ID: "access-control-section-anvil-id",
        TASK_ID: "access-control-section-task-id",
        APP_OWNER: "access-control-section-app-owner",
        EXPLANATION: "access-control-section-explanation",
    },

    AUDIT_HISTORY_LINK: "app-name--audit-history-link",
};
