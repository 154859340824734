import React, { useCallback } from "react";
import { Input, InputProps, Select, SelectProps, NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3";
import styled from "styled-components";

const CustomTextField = styled.div`
    padding-top: 10px;
`;

export const TEST_IDS = {
    SELECT: "dropdown-select",
    INPUT: "dropdown-text-box",
};

interface Props {
    options: string[];
    onChange: (value: string) => void;
    isDisabled?: boolean;
    expectedAnswer?: string;
    customDropdownValue?: string;
}

const Dropdown = (props: Props): JSX.Element => {
    const { options, isDisabled = false, expectedAnswer = "", customDropdownValue = "", onChange } = props;
    const isCustomValue =
        Boolean(expectedAnswer) &&
        !options.find((option): boolean => option !== customDropdownValue && option === expectedAnswer);
    const dropdownValue = isCustomValue ? customDropdownValue : expectedAnswer;
    const textBoxValue = isCustomValue ? (expectedAnswer === customDropdownValue ? "" : expectedAnswer) : "";

    const handleDropdownChange = useCallback(
        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>): void => {
            onChange(event.detail.selectedOption.value ?? "");
        },
        [onChange]
    );

    const handleTextChange = useCallback(
        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>): void => {
            onChange(event.detail.value);
        },
        [onChange]
    );

    return (
        <React.Fragment>
            <Select
                data-testid={TEST_IDS.SELECT}
                disabled={isDisabled}
                filteringType={"auto"}
                placeholder="Choose an option"
                selectedOption={dropdownValue ? { value: dropdownValue, label: dropdownValue } : null}
                options={options.map((option): SelectProps.Option => {
                    return { value: option, label: option };
                })}
                onChange={handleDropdownChange}
            />
            {isCustomValue && (
                <CustomTextField>
                    <Input
                        data-testid={TEST_IDS.INPUT}
                        disabled={isDisabled}
                        value={textBoxValue}
                        placeholder={"Enter custom value"}
                        onChange={handleTextChange}
                    />
                </CustomTextField>
            )}
        </React.Fragment>
    );
};

export default Dropdown;
