import AbstractKaleService from "src/services/AbstractKaleService";
import { FeatureToggleConfig, KaleConfig } from "src/Config";
import { TokenGetter } from "src/services/CognitoService";
import { appendRequestID } from "src/services/AppendRequestID";

export enum CradleMetadataStatus {
    NotStarted = "NOT_STARTED",
    Started = "STARTED",
    CradleAccountCreated = "CRADLE_ACCOUNT_CREATED",
    CradleProfileCreated = "CRADLE_PROFILE_CREATED",
    Completed = "COMPLETED",
    Failed = "FAILED",
}

export interface FetchCradleMetadataRequestOptions {
    policyType: string;
    policyIdentifier: string;
    andesProviderId: string;
    andesTableName: string;
    andesTableVersion: number;
}

type CreateCradleMetadataRequestBody = FetchCradleMetadataRequestOptions;

interface CreateCradleMetadataResponse {
    status: CradleMetadataStatus;
}

export interface FetchCradleMetadataResponse extends CreateCradleMetadataResponse {
    cradleAccount: string;
    cradleProfileId: string;
    cradleProfileUrl: string;
}

const CRADLE_API_ENDPOINT = "external/cradle/metadata";

export const CRADLE_POLICY_IDENTIFIER = "customerId";

export class CradleService extends AbstractKaleService {
    public constructor(kaleConfig: KaleConfig & FeatureToggleConfig, accessTokenGetter: TokenGetter) {
        super(kaleConfig, accessTokenGetter);

        this.fetchCradleMetadata = this.fetchCradleMetadata.bind(this);
        this.createCradleMetadata = this.createCradleMetadata.bind(this);
    }

    public fetchCradleMetadata({
        policyType,
        policyIdentifier,
        andesProviderId,
        andesTableName,
        andesTableVersion,
    }: FetchCradleMetadataRequestOptions): Promise<FetchCradleMetadataResponse> {
        const metadataIdentifiers =
            `${policyType}/${policyIdentifier}/${andesProviderId}` + `/${andesTableName}/${andesTableVersion}`;
        const cradleMetadataUrl = `${CRADLE_API_ENDPOINT}/${metadataIdentifiers}`;
        const url =
            `${this.baseApiEndpoint}/${CRADLE_API_ENDPOINT}/` +
            `${policyType}/${policyIdentifier}/${andesProviderId}/${andesTableName}/${andesTableVersion}`;

        const fetchMessage = `Fetching Cradle Metadata ${cradleMetadataUrl}`;
        console.info(fetchMessage);
        return this.signedFetch("GET", url).then((resp): Promise<FetchCradleMetadataResponse> => {
            if (resp.ok) {
                return resp.json() as unknown as Promise<FetchCradleMetadataResponse>;
            } else {
                const errorMessage = `Error ${fetchMessage}, ERROR: ${resp.statusText}`;
                console.error(errorMessage);
                throw Error(appendRequestID(errorMessage, resp));
            }
        }, this.buildDefaultRejection());
    }

    public createCradleMetadata(requestBody: CreateCradleMetadataRequestBody): Promise<CreateCradleMetadataResponse> {
        const url = `${this.baseApiEndpoint}/${CRADLE_API_ENDPOINT}/`;

        const createMessage = `Creating Cradle Metadata with request body ${JSON.stringify(requestBody)}`;
        console.info(createMessage);
        return this.signedFetch("POST", url, JSON.stringify(requestBody)).then(
            (resp): Promise<CreateCradleMetadataResponse> => {
                if (resp.ok) {
                    return resp.json() as unknown as Promise<CreateCradleMetadataResponse>;
                } else {
                    const errorMessage = `Error ${createMessage}, ERROR: ${resp.statusText}`;
                    console.error(errorMessage);
                    throw Error(appendRequestID(errorMessage, resp));
                }
            },
            this.buildDefaultRejection()
        );
    }
}
