import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./root-reducer";

const STORE_NAME = "KaleUI";

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production" && {
        name: STORE_NAME,
    },
});

export type KaleRootState = ReturnType<typeof store.getState>;
export type KaleRootDispatch = typeof store.dispatch;
export default store;
