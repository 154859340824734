import { CommentsViewState } from "src/components/CommentsView/state/reducer/reducer";
import { CommentSavedAction } from "src/components/CommentsView/state/actions";

export const commentSavedReducer = (prevState: CommentsViewState, action: CommentSavedAction): CommentsViewState => {
    const { savedComment } = action.payload;
    return {
        ...prevState,
        comments: {
            ...prevState.comments,
            byFrontEndId: {
                ...prevState.comments.byFrontEndId,
                [savedComment.frontEndId]: savedComment,
            },
        },
    };
};
