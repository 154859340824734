import { useLocation } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import KaleContext from "src/components/KaleContext";
import { SessionStart } from "src/services/RumService/rum-events";

export const RumSession = (): null => {
    const { pathname } = useLocation();
    const {
        service: { rumService },
        user: { userId },
    } = useContext(KaleContext);

    const deps = { rumService, userId };
    const depsRef = useRef(deps);
    depsRef.current = deps;

    useEffect(function recordSessionStart(): void {
        const { rumService, userId } = depsRef.current;
        rumService?.recordEvent(SessionStart.event({ userId }));
    }, []);

    useEffect(
        function recordSessionPageViews(): void {
            const { rumService } = depsRef.current;
            rumService?.recordPageView(pathname);
        },
        [pathname]
    );

    return null;
};
