import { tidSelector } from "../util/selectors";
import { TEST_IDS } from "./test-ids";

const { CSV_DROPDOWN, TABLE_CREATION_DROPDOWN, TABLE_SELECT } = TEST_IDS;

export const SELECTORS = {
    CSV_DROPDOWN: tidSelector(CSV_DROPDOWN),
    TABLE_CREATION_DROPDOWN: tidSelector(TABLE_CREATION_DROPDOWN),
    TABLE_SELECT: tidSelector(TABLE_SELECT),
};
