import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { RumServiceProps } from "src/services/RumService/types";

export type RumHandle = AwsRum | undefined;

const RUM_ENDPOINT = "https://dataplane.rum.us-east-1.amazonaws.com";
const APPLICATION_VERSION = "1.0.0";
const APPLICATION_REGION = "us-east-1";

/**
 * Utility function to initialize a RumHandle to the underlying aws web client
 */
export const initializeRumHandle = (options: RumServiceProps): RumHandle => {
    try {
        const config: AwsRumConfig = {
            sessionSampleRate: 1,
            sessionEventLimit: options.sessionEventLimit,
            guestRoleArn: options.guestRoleArn,
            identityPoolId: options.identityPoolId,
            endpoint: RUM_ENDPOINT,
            telemetries: ["errors", "performance", "http"],
            allowCookies: true,
            enableXRay: false,
        };

        return new AwsRum(options.applicationId, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
        console.error("Error during CloudWatch RUM web client initialization:", error);
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
};
