import CloudWatchLogs from "aws-sdk/clients/cloudwatchlogs";
import * as uuid from "uuid";

import { KaleConfig } from "src/Config";
import { TokenGetter } from "src/services/CognitoService";

interface LogConfig {
    // AWS access key ID
    readonly accessKeyId: string;

    // AWS secret access key
    readonly secretAccessKey: string;

    // A security or session token to use with these credentials. Usually
    // present for temporary credentials.
    readonly sessionToken: string;

    // Date when these credentials will no longer be accepted.
    readonly expiration: Date;

    // Which CloudWatch Log Group to create new Log Streams in.
    readonly logGroup: string;

    // Which log levels to upload to CloudWatch
    readonly logLevels: string[];
}

interface LogStatement {
    // This should be one of: error, warn, info, debug
    level: string;
    message: string;
    // Milliseconds since epoch
    timestamp: number;
}

const BATCH_SIZE = 10;
// Max number of seconds to wait
const MAX_BATCH_WAIT_SEC = 60;

export class CloudWatchLogger {
    private apiEndpoint: string;
    private accessTokenGetter: TokenGetter;
    private region: string;

    private cachedLogConfig: LogConfig | undefined;
    private logStream: string;
    private logQueue: LogStatement[];
    private lastPushTime: Date;
    private nextSequenceToken: string | undefined;

    // This is needed if we want to cancel the interval
    private pushInterval: number;

    public constructor({ apiEndpoint }: KaleConfig, accessTokenGetter: TokenGetter, user: string) {
        this.apiEndpoint = apiEndpoint;
        this.accessTokenGetter = accessTokenGetter;

        const matches = apiEndpoint.match(/execute-api.(.*).amazonaws.com/);
        this.region = matches ? matches[1] : "us-east-1";

        // e.g. geoff/237bd978-86e5-4f65-91ea-17fe698da85e
        this.logStream = `${user}/${uuid.v4()}`;
        this.logQueue = [];

        // Set the last push date to the epoch since haven't pushed a log yet
        this.lastPushTime = new Date(0);

        // Set up an interval to check every MAX_BATCH_WAIT_SEC for logs to push
        this.checkQueue = this.checkQueue.bind(this);
        // eslint-disable-next-line
        this.pushInterval = window.setInterval(this.checkQueue, MAX_BATCH_WAIT_SEC * 1000);

        // Send logs on before unload event
        // eslint-disable-next-line
        window.addEventListener("beforeunload", (): null => {
            this.checkQueue();

            return null;
        });
    }

    private getConfig(): Promise<LogConfig> {
        // Keep using the cached log config until within one minute of expiration (in milliseconds)
        if (this.cachedLogConfig && this.cachedLogConfig.expiration.getTime() - new Date().getTime() > 60 * 1000) {
            return Promise.resolve(this.cachedLogConfig);
        }

        return this.refreshLogConfig();
    }

    private refreshLogConfig(): Promise<LogConfig> {
        return this.accessTokenGetter()
            .then((accessToken: string): Promise<Response> => {
                return fetch(`${this.apiEndpoint}/browser-logs-config`, {
                    mode: "cors",
                    method: "get",
                    headers: {
                        accept: "application/json",
                        "content-type": "application/json",
                        authorization: accessToken,
                    },
                });
            })
            .then((response: Response): Promise<LogConfig> => {
                if (response.status !== 200) {
                    return Promise.reject("Non-200 response for config");
                }
                return response.json().then((response: LogConfig): LogConfig => {
                    this.cachedLogConfig = {
                        ...response,
                        // The expiration comes in as an ISO-8601 string and we need it as a Date object
                        expiration: new Date(response.expiration as any as string),
                    };
                    return this.cachedLogConfig;
                });
            });
    }

    private getClient(config: LogConfig): CloudWatchLogs {
        return new CloudWatchLogs({
            region: this.region,
            credentials: config,
        });
    }

    public setup(): Promise<void> {
        return this.getConfig()
            .then((config: LogConfig): Promise<void> => {
                return this.getClient(config)
                    .createLogStream({
                        logGroupName: config.logGroup,
                        logStreamName: this.logStream,
                    })
                    .promise()
                    .then((): void => {
                        this.swizzleConsoleMethods(config);
                    })
                    .catch((reason: any): void => {
                        console.error(`Unable to create log stream '${this.logStream}': ${reason}`);
                    });
            })
            .then((): void => {
                console.info(`Logs now being sent to CloudWatch stream: '${this.logStream}'`);
                // eslint-disable-next-line
                window.addEventListener("error", (event): void => {
                    console.error(
                        `${event.error?.message ?? event.message ?? "Unknown Error"}: ${
                            event.error?.stack ?? "Unknown Stack Trace"
                        }`
                    );
                });
                // eslint-disable-next-line
                window.addEventListener("securitypolicyviolation", (event) => {
                    console.error(`CSP blocked URI: ${event.blockedURI} at blocked location: ${event.documentURI}`);
                });
            });
    }

    private swizzleConsoleMethods(config: LogConfig): void {
        config.logLevels.forEach((level: string): void => {
            const originalFn: (message?: any, ...optionalParams: any[]) => void = (console as any)[level];

            // Important: This will cause us to lose the ability to pass in multiple arguments
            // However, with format strings this shouldn't really be a problem
            (console as any)[level] = (message: string): void => {
                originalFn(message);
                this.logQueue.push({
                    level,
                    message,
                    timestamp: new Date().getTime(),
                });
            };
        });
    }

    // checkQueue will push logs if any of these conditions are met:
    //   - An error has been logged
    //   - At least BATCH_SIZE log statements have been logged
    //   - More than BATCH_MAX_WAIT seconds have passed since the last push
    private checkQueue(): Promise<void> {
        if (this.logQueue.length == 0) {
            return Promise.resolve();
        }

        const hasWaitElapsed: boolean = new Date().getTime() - this.lastPushTime.getTime() >= MAX_BATCH_WAIT_SEC * 1000;
        const haveEnoughLogs: boolean = this.logQueue.length >= BATCH_SIZE;
        const haveErrorLog: boolean = this.logQueue.some((statement): boolean => statement.level === "error");

        if (!(hasWaitElapsed || haveEnoughLogs || haveErrorLog)) {
            return Promise.resolve();
        }

        const events = this.logQueue.map((statement: LogStatement): CloudWatchLogs.InputLogEvent => {
            return {
                timestamp: statement.timestamp,
                message: `[${statement.level.toUpperCase()}] ${statement.message}`,
            };
        });

        return this.getConfig().then((config: LogConfig): Promise<void> => {
            return this.getClient(config)
                .putLogEvents({
                    logEvents: events,
                    logGroupName: config.logGroup,
                    logStreamName: this.logStream,
                    sequenceToken: this.nextSequenceToken,
                })
                .promise()
                .then((response: CloudWatchLogs.PutLogEventsResponse): void => {
                    this.logQueue = [];
                    this.lastPushTime = new Date();
                    this.nextSequenceToken = response.nextSequenceToken;
                })
                .catch((reason: any): void => {
                    if (reason.code === "ExpiredTokenException") {
                        this.refreshLogConfig();
                    }
                    console.log(`Unable to upload logs: ${reason}`);
                });
        });
    }
}
