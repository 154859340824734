import React, { useEffect, useRef, useState } from "react";
// Polaris Components
import Container from "@amzn/awsui-components-react-v3/polaris/container";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react-v3/polaris/space-between";
import ApplicationNameField from "src/components/NewApplication/input-fields/ApplicationNameField";
import IsConfidentialField from "src/components/NewApplication/input-fields/IsConfidentialField";
import { FetchedBindlesState } from "src/components/NewApplication/useFetchBindlesOnMount";
import ControlBindleField from "src/components/NewApplication/input-fields/ControlBindleField";
import { AnvilProps } from "src/components/survey/LegalSurveyPage";

export interface FieldValuesState {
    applicationName: string;
    isConfidential: boolean;
    controlBindleName: string;
}

interface NewApplicationFieldsPanelProps {
    onFieldValueChange: (newState: FieldValuesState) => void; // Communicate fields changes to parent components
    bindlesState: FetchedBindlesState;
    anvilProps?: AnvilProps;
}
const NewApplicationFieldsPanel = ({
    onFieldValueChange,
    bindlesState,
    anvilProps,
}: NewApplicationFieldsPanelProps): JSX.Element => {
    const [applicationName, setApplicationName] = useState<FieldValuesState["applicationName"]>("");
    const [isConfidential, setIsConfidential] = useState<FieldValuesState["isConfidential"]>(false);
    const [controlBindleName, setControlBindleName] = useState<FieldValuesState["controlBindleName"]>("");

    const dependencies = { onFieldValueChange };
    const depsRef = useRef(dependencies);
    depsRef.current = dependencies;

    useEffect((): void => {
        const { onFieldValueChange } = depsRef.current;
        onFieldValueChange({
            applicationName,
            isConfidential,
            controlBindleName,
        });
    }, [applicationName, isConfidential, controlBindleName]);

    useEffect((): void => {
        if (anvilProps?.params.applicationName) {
            setApplicationName(anvilProps.params.applicationName);
        }
    }, [anvilProps]);

    return (
        <Container header={<Header variant="h2">Create new attestation</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <ApplicationNameField value={applicationName} onChange={setApplicationName} />
                <IsConfidentialField value={isConfidential} onChange={setIsConfidential} />
                <ControlBindleField
                    value={controlBindleName}
                    onChange={setControlBindleName}
                    bindles={bindlesState.bindles}
                />
            </SpaceBetween>
        </Container>
    );
};

export default React.memo(NewApplicationFieldsPanel);
