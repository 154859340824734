import { CheckboxProps, FormField, NonCancelableCustomEvent } from "@amzn/awsui-components-react-v3";
import React from "react";
import { QuestionChoice } from "src/answers_legacy";
import { withFunctionalReadonly } from "src/components/fields/hoc/withFunctionalReadonly";
import KaleCheckbox, { KaleCheckboxProps } from "src/components/fields/select/KaleCheckbox";
import { KaleHTMLParser } from "src/components/fields/textbox/TextBoxHelper";
import { FunctionalSurveyItemProps } from "src/components/survey/SurveyItem";
import { TEST_IDS } from "shared/fields";

const { KALE_CHECKBOX_GROUP } = TEST_IDS;

const CHECKBOX_OPTION_ID = "checkbox-option";

interface Props extends FunctionalSurveyItemProps<string[]> {
    choices: QuestionChoice[];
}

/**
 * Legacy Kale UI Component representing 1 or more checkboxes.
 */
const KaleCheckboxGroup = (props: Props): JSX.Element => {
    const { id, choices, isFormInReadonlyMode = false, isRequired, expectedAnswer = [], onChangeCallback } = props;
    const exclusiveOptions = choices
        .filter((choice): boolean => !!choice.isExclusive)
        .map((choice): string => choice.value);
    const isExclusiveOptionChecked = expectedAnswer.some((value): boolean => exclusiveOptions.includes(value));

    const handleCheckboxChange = (choice: QuestionChoice, isChecked: boolean): void => {
        let newValues: string[];
        if (isChecked) {
            // Add choice to the list of selected choices.
            // if an exclusive choice is becoming checked, we have to also uncheck all previous selections
            newValues = choice.isExclusive ? [choice.value] : expectedAnswer.concat(choice.value);
        } else {
            // Remove choice from the list of selected choices
            newValues = expectedAnswer.filter((value): boolean => choice.value !== value);
        }
        onChangeCallback?.({
            id: id,
            response: newValues,
        });
    };

    return (
        <div data-testid={KALE_CHECKBOX_GROUP}>
            <FormField stretch={true} errorText={isRequired && !Boolean(expectedAnswer.length) ? "Required" : ""}>
                {choices.map((choice): JSX.Element => {
                    const isChecked = expectedAnswer.includes(choice.value);
                    const isDisabled = isFormInReadonlyMode || (isExclusiveOptionChecked && !isChecked);
                    const label = choice.label || choice.value;

                    const checkboxProps: KaleCheckboxProps["checkboxProps"] = {
                        id,
                        checked: isChecked,
                        onChange: (event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>): void => {
                            handleCheckboxChange(choice, event.detail.checked);
                        },
                        disabled: isDisabled,
                        children: label,
                        controlId: expectedAnswer.includes(choice.value) ? `${CHECKBOX_OPTION_ID}_${id}` : undefined,
                    };

                    const popoverProps: KaleCheckboxProps["popoverProps"] = !choice.infoContent
                        ? undefined
                        : {
                              position: "top",
                              size: "large",
                              dismissButton: false,
                              content: KaleHTMLParser(choice.infoContent),
                          };

                    const props: KaleCheckboxProps = { checkboxProps, popoverProps };

                    return <KaleCheckbox key={choice.value} data-checkbox-label={label} {...props} />;
                })}
            </FormField>
        </div>
    );
};

const KaleCheckboxWithReadOnly = withFunctionalReadonly(KaleCheckboxGroup);
export default KaleCheckboxWithReadOnly;
